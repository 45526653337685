import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useRequest from "hooks/useRequest";

import i18n from "i18n";
import { ProviderService } from "services/ProviderService";
import { PublicService } from "services/PublicService";

import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";

import Panel from "components/MainApp/organisms/TogglePanel";
import Toggle from "components/MainApp/atoms/Forms/Toggle";

import "./styles.scss";

const ProviderDetail = (props) => {
  const history = useHistory();
  const [deleteErrorModal, setdeleteErrorModal] = useState(false);
  const [deleteErrorMsg, setdeleteErrorMsg] = useState("");
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (!props.match.params.key) return;
    const id = props.match.params.key;
    beforeSubmit();
    ProviderService.provider(id).then((provider) => {
      let countries;
      let regions;
      let states;
      let localities;

      let countriesPromises = PublicService.countries();
      let regionsPromises = PublicService.regions(provider.country);
      let statesPromises = PublicService.states(
        provider.country,
        provider.region
      );
      let citiesPromises = PublicService.cities(
        provider.country,
        provider.region,
        provider.state
      );

      Promise.all([
        countriesPromises,
        regionsPromises,
        statesPromises,
        citiesPromises,
      ]).then((responses) => {
        afterSubmit();

        countries = responses[0];
        regions = responses[1];
        states = responses[2];
        localities = responses[3];

        provider.country = countries.find((c) => c.value === provider.country);
        provider.locality = localities.find(
          (c) => c.value === provider.locality
        );
        provider.region = regions.find((c) => c.value === provider.region);
        provider.state = states.find((c) => c.value === provider.state);

        setProvider(provider);
      });
    });
  }, []);

  const deleteProvider = () => {
    beforeSubmit();
    ProviderService.deleteProvider(provider.id)
      .then((rta) => {
        history.push(`/admin/providers`);
        afterSubmit();
      })
      .catch((error) => {
        afterSubmit();
        setdeleteErrorModal(true);
        setdeleteErrorMsg(i18n.t("No se puede eliminar el proveedor"));
      });
  };

  const handleChangeEnabled = (e) => {
    e.preventDefault();
    beforeSubmit();

    const data = { enabled: !provider.enabled };
    ProviderService.updateProvider(props.match.params.key, data)
      .then((provider) => {
        afterSubmit();
        setProvider(provider);
        EventRepository.notificationSend({
          label: i18n.t(
            `Tu proveedor se ${
              provider.enabled ? "habilitó" : "deshabilitó"
            } de manera exitosa.`
          ),
          type: "success",
        });
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }
  };

  const headerOptions = [
    {
      icon: "icon-iconos_editar",
      name: "editar",
      handler: () => {
        history.push({
          pathname: `/admin/providers/edit/${provider.id}`,
          state: {
            type: "provider",
          },
        });
      },
      tooltip: i18n.t("Editar Proveedor"),
    },
  ];

  const handleGoBack = () => {
    history.push(`/admin/providers`);
  };

  const content = (
    <div className={"provider-detail-content-wrapper"}>
      <div className={"edit-provider-toggle"}>
        <p>
          Proveedor{" "}
          <span>{provider?.enabled ? "habilitado" : "deshabilitado"}</span> para
          realizar operaciones.
        </p>
        <Toggle
          checked={provider && provider.enabled}
          handleChange={handleChangeEnabled}
          name="enabled"
        />
      </div>

      {provider && (
        <div className={"provider-detail-panels"}>
          <Panel
            fields={provider && provider.getDescriptionPanelView()}
            title={i18n.t("Descripción")}
          />
          <Panel
            fields={provider && provider.getContactPanelView()}
            title={i18n.t("Contacto")}
          />
        </div>
      )}
    </div>
  );
  return (
    <div className="provider-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Detalle de proveedor")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      <GenericErrorModal
        open={deleteErrorModal}
        handleClose={() => setdeleteErrorModal(false)}
        error={deleteErrorMsg}
      ></GenericErrorModal>

      {loading && <Loader />}
    </div>
  );
};

export default ProviderDetail;
