import React from "react";
import Step from "components/MainApp/atoms/Wizard/Step";

import "./styles.scss";

export class AddBusinessNodeStepThree extends React.Component {
  render() {
    const {
      description,
      contact,
      goBack,
      handleFinish,
    } = this.props;

    const stepContent = (
      <form onSubmit={handleFinish}>
        <div className="add-client-wizard-step-three-content">
          <div className="add-client-wizard-step-three-title">Descripcion</div>
          {description.map((item, index) => {
            return (
              <div key={index} className={"add-client-wizard-step-three-item"}>
                <div className={"add-client-wizard-step-three-title"}>
                  {item.title}
                </div>
                <div className={"add-client-wizard-step-three-value"}>
                  {item.value}
                </div>
              </div>
            );
          })}

        <div className="add-client-wizard-step-three-title">Contacto</div>

          {contact.map((item, index) => {
            return (
              <div key={index} className={"add-client-wizard-step-three-item"}>
                <div className={"add-client-wizard-step-three-title"}>
                  {item.title}
                </div>
                <div className={"add-client-wizard-step-three-value"}>
                  {item.value}
                </div>
              </div>
            );
          })}

          <div className={"add-client-wizard-step-three-action-wrapper"}>
            <button
              type="submit"
              className="add-client-wizard-step-three-action-button"
            >
              FINALIZAR
            </button>
          </div>
        </div>
      </form>
    );

    return (
      <div className="add-client-wizard-step-three-container">
        <Step
          title="Confirmacion"
          content={stepContent}
          goBackFunc={goBack}
        ></Step>
      </div>
    );
  }
}

export default AddBusinessNodeStepThree;
