import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";
import DescriptionForm from "../../../components/DescriptionForm"

import "./styles.scss";

export class AddClientStepOne extends React.Component {
  render() {
    const { client, handleChange, handleSelect, handleContinue, countries,
            regions, provinces, cities, enableContinue, error } = this.props;

    const stepContent = (
      <form onSubmit={handleContinue}>
      <div className="add-client-wizard-step-one-content">
        <DescriptionForm client={client} handleChange={handleChange} handleSelect={handleSelect} countries={countries} regions={regions} provinces={provinces} cities={cities} error={error}/>
        <div className={"add-client-wizard-step-one-action-wrapper"}>
          <button type="submit" className={`add-client-wizard-step-one-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-client-wizard-step-one-container">
        <Step title="" content={stepContent}></Step>
      </div>
    );
  }
}

export default AddClientStepOne;
