import React from "react";

import "./styles.scss";

export class CategoryTableItem extends React.Component {
  render() {
    const { category, readOnly, handleClick } = this.props;
    return (
      <div
        className="category-table-item-container"
        data-automation-id="category-item"
        onClick={() => handleClick(category)}
      >
        <div className="category-table-item-name"> {category.name} </div>

        {!readOnly && (
          <React.Fragment>
            <div
              className="category-table-item-button"
              data-automation-id="edit-category-button"
              onClick={() => this.props.handleEdit(category.id)}
            >
              <i className="icon-iconos_editar"></i>
            </div>
            <div
              className="category-table-item-button"
              data-automation-id="delete-category-button"
              onClick={() => this.props.handleDelete(category)}
            >
              <i className="icon-iconos_eliminar"></i>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

CategoryTableItem.propTypes = {};

export default CategoryTableItem;
