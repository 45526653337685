import React, {useState} from "react";
import {isMobile} from "../../../../helpers/Mobile"
import "./styles.scss";


export const EditPanel = ({fields, title="Descripción", handleEdit}) => {
    // const [ toggleOpen, setToggleOpen ] = useState(false)
 
    return (
        <div className="edit-panel-container">

            <div className={`edit-panel-title`}>
            {title}
            <i name="icon-iconos_editar" className={`icon-iconos_editar edit-panel-icon`} onClick={handleEdit}></i>
            </div>

                {<React.Fragment>
                    {fields.map( item => {
                        return (<div className="edit-panel-section" key={item.title}>
                                    <div className="edit-panel-section-title">{item.title}</div>
                                    <div className="edit-panel-section-content">{item.value}</div>
                                </div>)
                    })}
                </React.Fragment>}
            
        </div>
    );
  
}

export default EditPanel;