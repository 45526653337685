import React from "react";
// import i18n from "i18n";
import "./styles.scss";

export const BlueSummaryItem = ({number,label}) => {
    return(
        <div className="blue-summary-item-container">
            <div className="blue-summary-item-number">{number}</div>
            <div className="blue-summary-item-label">{label}</div>
        </div>
    )
}

export default BlueSummaryItem;