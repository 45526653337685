import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import Icon from "components/MainApp/atoms/Icon/icon"

import "./styles.scss";

export class AddConfigTagStepFour extends React.Component {
  
  render() {
    const { configTagInfo, handleFinish } = this.props;

    const stepContent = (
      <form onSubmit={handleFinish}>

      <div className="add-config-tag-wizard-step-four-content">
        <h1 className="add-config-tag-confirmation-main-title">Verifica los Datos</h1>
        {configTagInfo && configTagInfo.map((info, i) => {
          return <div className="config-tag-confirmation-wrapper" key={i}>
            <div className="config-tag-confirmation-header">
              <h2 className="config-tag-confirmation-title">{info.step}</h2>
               <div onClick={info.handleClick}><Icon name="edit2" height={20} width={20} fill={'#3C7AF5'}/></div>
            </div>
            <div className="config-tag-confirmation-content">
                {info.data.map((d, i) => {
                   return <div key={i}>
                      {d.title && <div key={i} className="config-tag-confirmation-key">{d.title}</div>}
                      <div key={i} className="config-tag-confirmation-item">{d.value}</div>
                     </div>
                })}
            </div>
           </div>
        })}

        
        <div className={"add-config-tag-wizard-step-four-action-wrapper"}>
          <button type="submit" className="add-config-tag-wizard-step-four-action-button">
            CONFIRMAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-config-tag-wizard-step-four-container">
        <Step content={stepContent}></Step>
      </div>
    );
  }
}

export default AddConfigTagStepFour;
