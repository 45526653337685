import React from "react";
import MainAppGenericModal from "../../../../atoms/Modals/GenericModal";

import "./styles.scss";

export class SingleLoadExitModal extends React.Component {
  render() {
    const { 
      open,
      handleClose
    } = this.props;

    const content = (<div className="single-load-exit-modal-wrapper">
      <div className="single-load-exit-modal-title">¿Está seguro que desea salir?</div>
      <div className="single-load-exit-modal-text">Al salir, se cancelará la carga de los productos.</div>
      <div className="single-load-exit-modal-buttons">
        <div className="single-load-exit-modal-button" onClick={this.props.cancel} data-automation-id="modal-cancel">QUEDARME</div>
        <div className="single-load-exit-modal-button" onClick={this.props.confirm} data-automation-id="modal-confirm">SÍ, SALIR</div>
      </div>
    </div>)
    return (
      <div className="single-load-exit-modal-container">
          <MainAppGenericModal open={open} content={content} handleClose={handleClose}></MainAppGenericModal>
      </div>
    );
  }
}

SingleLoadExitModal.propTypes = {
};

export default SingleLoadExitModal;
