import React from "react";
import DateInput from "components/MainApp/atoms/Forms/DateInput";
import { formatDateTodmy } from "helpers/Parsers";

const DateInputFilter = ({handleSelection, selectedOptions, label, name}) => {
  const _handleChange = (selectedDates) => {
    const _option = {
      id: '',
      label: `${selectedDates[0] ? formatDateTodmy(selectedDates[0]) : ''} - ${selectedDates[1] ? formatDateTodmy(selectedDates[1]) : ''}` ,
      selected: true,
      type: name,
      selectedDates: selectedDates
    }
    handleSelection(_option, 'replace', label)
  }

  return <DateInput handleChange={_handleChange} label={label} selectedDates={selectedOptions && selectedOptions[0] && selectedOptions[0].selectedDates} />
};

export default DateInputFilter;