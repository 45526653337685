import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Paper } from "@material-ui/core";
import styles from "./autocomplete.module.scss";

const CustomPaper = props => {
  return (
    <Paper
      elevation={8}
      style={{ marginInline: "-2px", scrollbarWidth: "8px" }}
      {...props}
    />
  );
};

const SelectAutocomplete = ({
  id,
  name = "",
  label = "",
  width = "100%",
  options = [],
  selected = null,
  multiple = false,
  required = false,
  disabled = false,
  onChange,
  error
}) => {
  const handleChange = (target, newValue) => {
    if (onChange) onChange(id, newValue, target);
  };

  return (
    <div className={styles["autocomplete-container"]}>
      <Autocomplete
        id={id}
        name={name || id}
        multiple={multiple}
        disabled={disabled}
        options={options || []}
        value={selected || null}
        onChange={handleChange}
        getOptionLabel={option => option.label || ""}
        noOptionsText="Sin opciones"
        sx={{
          width: width,
          border: "1px solid #858BA5",
          borderRadius: "6px",
          background: "white !important",
          overflow: "hidden",
          "& .MuiFilledInput-root": {
            background: "transparent",
            "&.Mui-disabled": {
              background: "#EDEFF5"
            }
          },
          "& fieldset": {
            display: "none"
          },
          "& label": {
            position: "absolute",
            top: "0px !important",
            color: "#A9B1D1 !important",
            fontFamily: "Ubuntu-Light !important",
            fontSize: "16px",
            marginLeft: "2px",
            "&.MuiInputLabel-shrink": {
              top: "0px !important"
            }
          },
          "& .MuiAutocomplete-popper": {
            width: "300px"
          }
        }}
        disableClearable
        PaperComponent={CustomPaper}
        renderInput={params => (
          <TextField
            {...params}
            label={label + (required ? "*" : "")}
            variant="filled"
            disabled={disabled}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true
            }}
          />
        )}
      />
      <div className={styles["autocomplete-error"]}>{error}</div>
    </div>
  );
};

export default SelectAutocomplete;
