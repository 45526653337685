export const steps = [
    {
        id: '0',
        name: 'Descripcion'
    },
    {
        id: '1',
        name: 'Especificaciones'
    },
    {
        id: '2',
        name: 'Confirmacion'
    }
]