import { Subject } from 'rxjs';


const notificationSubject = new Subject(null);  // dont receive initial value, only when do next()

export const EventRepository = {
    notificationObservable: notificationSubject.asObservable(),
    notificationSend: (data) => {
    	notificationSubject.next(data);
    }

}