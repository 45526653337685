const columns = [
    {
      name: 'name',
      pill: false,
      title: 'NOMBRE',
      mobileTag: false,
    },
    {
      name: 'document',
      pill: false,
      title: 'DOCUMENTO',
      mobileTag: 'Documento:',

    },
    {
      name: 'country',
      pill: false,
      title: 'PAIS',
      mobileTag: 'Pais:',

    },
    {
      name: 'region',
      pill: false,
      title: 'COMUNIDAD',
      mobileTag: 'Comunidad:',

    },

    {
      name: 'state',
      pill: false,
      title: 'PROVINCIA',
      mobileTag: 'Provincia:',

    },
    {
      name: 'locality',
      pill: false,
      title: 'MUNICIPIO',
      mobileTag: 'Municipio:',

    },
  ];


  export default columns;