import { useEffect, useState } from "react";

const useAddItems = (changeList, currentPage, pageSize) => {
  const [productResultList, setproductResultList] = useState([]); //resultList
  const [selectedToAddProductList, setselectedToAddProductList] = useState([]); //selectedList
  const [addedList, setaddedList] = useState([]); //addedList
  useEffect(() => {
    let productsSelected = productResultList.filter((item) => item.extra?.length > 0);
    setselectedToAddProductList(productsSelected);
  },[productResultList])

  const handleSelectToAddProduct = (product) => {
    //selectItem
    let selectedToAddProductListNew = [...selectedToAddProductList];
    let ind = selectedToAddProductListNew.findIndex((p) => {
      return p.id === product;
    });
    if (ind !== -1) {
      selectedToAddProductListNew = selectedToAddProductListNew.filter((p) => {
        return p.id !== product;
      });
    } else {
      let newProduct = productResultList.find((p) => {
        return p.id === product;
      });
      newProduct.selected = true;
      selectedToAddProductListNew.push(newProduct);
    }

    setselectedToAddProductList(selectedToAddProductListNew);
  };

  const handleSelectedProductQuantityChange = (productId, number) => {
    //selectedItemChangeQuantity
    let productResultListNew = [...productResultList];
    let productNew = productResultListNew.find((p) => p.id === productId);

    productNew.units = number;
    setproductResultList(productResultListNew);
  };

  const handleChangedLocations = (productId, locations) => {
    //selectedItemChangeQuantity
    let productResultListNew = [...productResultList];
    let productNew = productResultListNew.find((p) => p.id === productId);

    productNew.extra = locations.filter((location) => location.unitsSelected > 0);

    setproductResultList(productResultListNew);
  };

  const handleAdd = () => {
    //addItems
    let addedListNew = [...addedList];
    selectedToAddProductList.forEach((product) => {
      let addedInd = addedList.findIndex((p) => {
        return p.id === product.id;
      });
      if (addedInd === -1) {
        addedListNew.push({ ...product });
      } else {
        let productSelected = {
          ...productResultList.find((p) => p.id === product.id),
        };
        let productAddedIndex = addedListNew.findIndex(
          (p) => p.id === product.id
        );
        addedListNew[productAddedIndex].units =
          Number(addedListNew[productAddedIndex].units) +
          Number(productSelected.units);
      }

      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
    });

    let productResultListNew = productResultList.map((p) => {
      p.units = 1;
      return p;
    });

    setproductResultList(productResultListNew);
    setselectedToAddProductList([]);
  };

  const handleAddExtra = (selectedList = []) => {
    let productList = selectedToAddProductList;
    if (selectedList.length > 0) {
      productList = selectedList;
    }
    //addItems
    let addedListNew = [...addedList];
    productList.forEach((product) => {
      let addedInd = addedListNew.findIndex((p) => {
        return p.id === product.id;
      });
      product.extra = product.extra.filter((l) => l.unitsSelected > 0);

      if (addedInd === -1) {
        // Si no hay extras no se agrega
        if (product.extra.length !== 0) {
          let new_product = { ...product };
          new_product.units = product.extra.reduce((a, b) => {
            return a + b.unitsSelected;
          }, 0);
          new_product.extra = product.extra;
          addedListNew.push({ ...new_product });
        }
      } else {
        if (product.extra.length === 0) {
          // Se elimina si no hay extras
          addedListNew.splice(addedInd, 1);
        } else {
          addedListNew[addedInd].units = product.extra.reduce((a, b) => {
            return a + b.unitsSelected;
          }, 0);
          addedListNew[addedInd].extra = product.extra;
        }
      }

      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
    });

    // No se requiere resetear porque la idea es que modifique lo que hay
    // let productResultListNew = productResultList.map((p) => {
    //   // p.extra = []; No resetear
    //   return p;
    // });
    // setproductResultList(productResultListNew);

    // Reseteamos los seleccionados
    setselectedToAddProductList([]);
  };

  const handleAddProduct = (productId, number) => {
    //addedItemChangeQuantity
    number = number === 0 ? 1 : number;

    let addedListNew = [...addedList];
    let productAddedIndex = addedListNew.findIndex((p) => p.id === productId);
    addedListNew[productAddedIndex].units = Number(number);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  const handleDeleteAddedProduct = (productId) => {
    //addedItemDelete
    let addedListNew = [...addedList];

    addedListNew = addedListNew.filter((p) => p.id !== productId);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  const handleDeleteAddedProductLocations = (productId, location) => {
    //addedItemDelete
    let productResultListNew = [...productResultList];

    let product = productResultListNew.find((p) => p.id === productId);

    if (!product) {
      return;
    }
    const __locations = [...product.extra];
    const locationIndex = __locations.findIndex(
      (loc) => loc.location_id === location.location_id
    );
    __locations[locationIndex].unitsSelected = 0;

    // Actualiza la lista del search
    handleChangedLocations(product.id, __locations);
    // Actualiza la lista de actuales
    product.extra = __locations;
    handleAddExtra([product]);
  };

  return [
    productResultList,
    setproductResultList,
    selectedToAddProductList,
    setselectedToAddProductList,
    addedList,
    setaddedList,
    handleSelectToAddProduct,
    handleSelectedProductQuantityChange,
    handleAdd,
    handleAddProduct,
    handleDeleteAddedProduct,
    handleAddExtra,
    handleChangedLocations,
    handleDeleteAddedProductLocations,
  ];
};

export default useAddItems;
