import React, { useEffect, useState } from "react";
import { ProductService } from "services/ProductService";
import "./styles.scss";
import imageFile from "../../../assets/images/default_product.png";

export const LocalizationProductItem = (itemId) => {

    const [product, setProduct] = useState(null);
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        if (itemId.itemId) {
            ProductService.product(itemId.itemId).then((response) => {
                setProduct(response);
            },
            (error) => {
                setProduct(null);
            });
        } else {
            setProduct(null);
        }
    }, [itemId]);


    return (
        <>
            {product && (
                <div className="localization-product-item">
                    <img src={!product?.image || imageError ? imageFile : product.image}
                        onError={() => { setImageError(true) }} />
                    <div className="localization-product-data">
                        <div>
                            {product?.name}
                        </div>
                        <div className="localization-product-sku">
                            sku {product?.sku}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LocalizationProductItem;