import React from 'react';

import ProductSummaryItem from '../ProductSummaryItem';
import NumberInput from '../../../../atoms/Forms/NumberInput';
import './styles.scss'
const ProductResultSearch = ({product, units, disabled, handleChange, handleClick, label}) => {
    return(
        <div className="product-result-search-item-container">
            <div className="product-result-search-item-wrapper">
                <div onClick={handleClick} className="product-result-search-item">
                    <ProductSummaryItem product={product} units={units} stock={product.stock} add={true}/>
                </div>
                <div className="product-result-search-item-right-col">
                    <div className={`product-result-search-item-stock ${units > 0 ? '' : 'disabled'}`}>
                        {!label && (product.stock == 0  ? 'Sin stock' : 'Stock' + ' ' + product.stock)}
                        {label && label}
                    </div>
                    <div className={'product-result-search-item-number-input'}>
                        <NumberInput value={units} name="quantity" id={product.id} disabled={!product.stock || product.stock == 0} handleChange={handleChange} ></NumberInput>
                    </div>
                </div>
            </div>
            <div className="product-result-search-item-divider"></div>
        </div>
    )
}

export default ProductResultSearch;