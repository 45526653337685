import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";
import DragAndDropImages from "components/MainApp/atoms/Forms/DragAndDropImages";


import "./styles.scss";

export class AddDeviceStepTwo extends React.Component {
  render() {
    const { device, handleChange, handleSelect, handleContinue, nodes,
            zones, sectors, enableContinue, error, goBack, handleChangeImage, handleDeleteImage, files} = this.props;

    const stepContent = (
      <form onSubmit={handleContinue}>
      <div className="add-device-wizard-step-two-content">
        <div className='main-container'>
          <div>
            <div className="add-device-wizard-step-two-field" data-automation-id="input-serial_number">
              <MainInput
                label={"Número de serial"}
                required={true}
                id={"device-serial_number"}
                name="serial_number"
                value={device ? device.serial_number : ''}
                handleChange={handleChange}
                maxLength={100}
                error={error.serial_number && error.serial_number}
              />
            </div>
            <div className="add-device-wizard-step-two-field" data-automation-id="input-name">
              <MainInput
                label={"Nombre"}
                required={true}
                id={"device-name"}
                name="name"
                value={device ? device.name : ''}
                handleChange={handleChange}
                maxLength={100}
                error={error.name && error.name}
              />
            </div>
            <div className="add-device-wizard-step-two-field" data-automation-id="input-node">
              <MainDropdownInput
                required={true}
                id={"device-node"}
                name="node"
                selectedOption={device ? device.node : ''}
                handleSelection={(selectedOption) => handleSelect(selectedOption, 'node')}
                maxLength={50}
                options={nodes}
                placeholder={'Sucursal'}
                label={"Sucursal"}
                error={error.node && error.node}
              />
            </div>
            <div className="add-device-wizard-step-two-field" data-automation-id="input-zone">
              <MainDropdownInput
                id={"device-zone"}
                name="zone"
                selectedOption={device ? device.zone : ''}
                handleSelection={(selectedOption) => { handleSelect(selectedOption, 'zone')}}
                maxLength={50}
                options={zones}
                placeholder={'Zona'}
                label={'Zona'}
                disabled={!device || !device.node || device.node === ''}
                error={error.zone && error.zone}
              />
            </div>
            <div className="add-device-wizard-step-two-field" data-automation-id="input-sectors">
              <MainDropdownInput
                id={"device-sectors"}
                name="sectors"
                selectedOption={device ? device.area : ''}
                handleSelection={(selectedOption) => { handleSelect(selectedOption, 'sector')}}
                maxLength={50}
                options={sectors}
                placeholder={'Sector'}
                label={"Sector"}
                disabled={!device || !device.zone || device.zone === ''}
                error={error.area && error.area}
              />
            </div>
          </div>
          {device && device?.device_type?.label == 'LIFT' &&
            <div className='position-image'>
              <div className="add-device-wizard-step-two-field" data-automation-id="input-image">
                <DragAndDropImages
                  name="image"
                  handleChange={handleChangeImage}
                  handleDelete={handleDeleteImage}
                  files={files}
                  msg={"Arrastra el vídeo aquí"}
                  showStar={false}
                  showSelectorFiles={true}
                  fileTypeAccepted={"video/mp4"}
                />
              </div>
            </div>
          }
        </div>
        <div className={"add-device-wizard-step-two-action-wrapper"}>
          <button type="submit" className={`add-device-wizard-step-two-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-device-wizard-step-two-container">
        <Step title="Ingresa información del dispositivo" goBackFunc={goBack} content={stepContent}></Step>
      </div>
    );
  }
}

export default AddDeviceStepTwo;
