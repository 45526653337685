import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";

import { ClientService } from "services/ClientService";
import { PublicService } from "services/PublicService";

import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import Toggle from "components/MainApp/atoms/Forms/Toggle";

import DescriptionForm from "../components/DescriptionForm";
import ContactForm from "../components/ContactForm";

import { Client } from "models/Persons";
import "./styles.scss";
import EditExitModal from "../components/ExitEditModal";

const initialClient = {
  name: "",
  business_name: "",
  nif: "",
  country: "",
  region: "",
  state: "",
  locality: "",
  date: "",
  address: "",
  phone: "",
  email: "",
};

const EditClientView = (props) => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [client, setClient] = useState(initialClient);

  const [countries, setcountries] = useState([]);
  const [regions, setregions] = useState([]);
  const [provinces, setprovinces] = useState([]);
  const [cities, setcities] = useState([]);

  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    if (props.match.params.key) {
      beforeSubmit();

      let countriesAux = [];
      PublicService.countries().then((response) => {
        countriesAux = response;
        setcountries(response);
      });

      const id = props.match.params.key;
      ClientService.client(id)
        .then((client) => {
          afterSubmit();
          let regions;
          let states;
          let localities;

          let regionsPromises = PublicService.regions(client.country);
          let statesPromises = PublicService.states(
            client.country,
            client.region
          );
          let citiesPromises = PublicService.cities(
            client.country,
            client.region,
            client.state
          );

          Promise.all([regionsPromises, statesPromises, citiesPromises]).then(
            (responses) => {
              regions = responses[0];
              setregions(regions);

              states = responses[1];
              setprovinces(states);

              localities = responses[2];
              setcities(localities);

              client.country = countriesAux.find(
                (c) => c.value === client.country
              );
              client.locality = localities.find(
                (c) => c.value === client.locality
              );
              client.region = regions.find((c) => c.value === client.region);
              client.state = states.find((c) => c.value === client.state);

              client.entry_date = client.entry_date.substring(0, 10);
              setClient(client);
              afterSubmit();
            }
          );
        })
        .catch((error) => {
          afterSubmit();
        });
    }
  }, []);

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    beforeSubmit();

    let clientAux = { ...client };
    clientAux.country = clientAux.country?.value;
    clientAux.region = clientAux.region?.value;
    clientAux.state = clientAux.state?.value;
    clientAux.locality = clientAux.locality?.value;
    clientAux.entry_date += " 12:00:00";

    if (!validateContinue()) {
      afterSubmit();
      return;
    }

    ClientService.updateClient(props.match.params.key, clientAux)
      .then((response) => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("Tu cliente se actualizó de manera exitosa."),
          type: "success",
        });
        history.push(`/admin/clients`);
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const handleGoBack = () => {
    if (edited) {
      setExitModalOpen(true);
    } else {
      history.push(`/admin/clients`);
    }
  };

  const handleCloseModal = () => {
    setExitModalOpen(false);
    history.push(`/admin/clients`);
  };

  const handleConfirmModal = (e) => {
    setExitModalOpen(false);
    if (edited) {
      handleCreate(e);
    } else {
      history.push(`/admin/clients`);
    }
  };

  const handleChange = (e) => {
    !edited && setEdited(true);
    const name = e.target.name;
    const value = e.target.value;
    let clientAux = { ...client };
    clientAux[name] = value;

    setClient(new Client(clientAux));
  };

  const handleChangeEnabled = (e) => {
    client.enabled = !client.enabled;

    e.preventDefault();
    beforeSubmit();

    const data = { enabled: client.enabled };

    ClientService.updateClient(props.match.params.key, data)
      .then((response) => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t(
            `Tu cliente se ${
              client.enabled ? "habilitó" : "deshabilitó"
            } de manera exitosa.`
          ),
          type: "success",
        });
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const handleSelect = (selectedOption, type) => {
    let clientAux = { ...client };
    !edited && setEdited(true);

    switch (type) {
      case "country":
        clientAux.country = selectedOption;
        clientAux.region = "";
        clientAux.state = "";
        clientAux.locality = "";
        PublicService.regions(clientAux.country.value).then((response) => {
          setregions(response);
        });
        setClient(new Client(clientAux));

        break;
      case "region":
        clientAux.region = selectedOption;
        clientAux.state = "";
        clientAux.locality = "";
        PublicService.states(
          clientAux.country.value,
          clientAux.region.value
        ).then((response) => {
          setprovinces(response);
        });
        setClient(new Client(clientAux));

        break;
      case "state":
        clientAux.state = selectedOption;
        clientAux.locality = "";
        PublicService.cities(
          clientAux.country.value,
          clientAux.region.value,
          clientAux.state.value
        ).then((response) => {
          setcities(response);
        });
        setClient(new Client(clientAux));

        break;
      case "city":
        clientAux.locality = selectedOption;
        setClient(new Client(clientAux));

        break;

      default:
        break;
    }
  };

  const validateContinue = () => {
    if (client) {
      if(client.country?.value === 'ES'){
        return (
          client.name && client.name.trim() !== "" &&
          client.business_name && client.business_name.trim() !== "" &&
          client.document && client.document.trim() !== "" &&
          client.country && client.country.value !== "" &&
          client.region && client.region.value !== "" &&
          client.state && client.state.value !== "" &&
          client.locality && client.locality.value !== "" &&
          client.phone && client.phone.trim() !== "" &&
          client.entry_date && client.entry_date !== ""
        );
      }
      return (
        client.name && client.name.trim() !== "" &&
        client.business_name && client.business_name.trim() !== "" &&
        client.document && client.document.trim() !== "" &&
        client.country && client.country.value !== "" &&
        client.phone && client.phone.trim() !== "" &&
        client.entry_date && client.entry_date !== ""
      );
    } else {
      return false;
    }
  };

  const headerOptions = [];
  const content = (
    <div className={"edit-client-content-wrapper"}>
      <div className={"edit-client-inner"}>
        <div className={"edit-client-toggle"}>
          <p>
            Cliente{" "}
            <span>{client.enabled ? "habilitado" : "deshabilitado"}</span> para
            realizar operaciones.
          </p>
          <Toggle checked={client.enabled} handleChange={handleChangeEnabled} />
        </div>
        <form>
          <div className={"edit-client-form"}>
            <h2 className={"edit-client-title"}>{i18n.t("Descripcion")}</h2>
            <DescriptionForm
              client={client}
              handleChange={handleChange}
              handleSelect={handleSelect}
              error={errors}
              countries={countries}
              regions={regions}
              provinces={provinces}
              cities={cities}
              // disabledFields={disabledFields[type]}
            />
          </div>
          <div className={"edit-client-form"}>
            <h2 className={"edit-client-title"}>{i18n.t("Contacto")}</h2>

            <ContactForm
              client={client}
              handleChange={handleChange}
              error={errors}
              // disabledFields={disabledFields[type]}
            />
          </div>
          <div className={"edit-client-button-wrapper"}>
            <button
              type="submit"
              className={`edit-client-button ${!validateContinue() &&
                "disabled"}`}
              onClick={handleCreate}
            >
              {i18n.t("GUARDAR")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  return (
    <div className="edit-client-container">
      <AdminLayout
        headerTitle={i18n.t("Editar cliente")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
      <EditExitModal
        open={exitModalOpen}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
      />
    </div>
  );
};

export default EditClientView;
