import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GenericFormContainer from '../../../helpers/GenericFormContainer';
import {UserService} from '../../../services/UserService';

import './styles.scss';
import AuthGeneric from '../../../components/Auth/layouts/AuthGeneric'
import RoundedButton from '../../../components/Auth/atoms/buttons/RoundedButton'
import ModalButton from '../../../components/Auth/atoms/modals/ModalButton'

import Logo from '../../../components/Auth/atoms/images/Logo'

export class EmailVerificationView extends GenericFormContainer {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            type: 'register', // register, forgot

            sent: false,
        }
       
    }

    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.email){
            this.props.history.push('/');
        }

        this.setState({
            email: this.props.location.state.email,
            type: this.props.location.state.type
        })
    }

    resetSent = () => {
        this.setState({
            sent: false
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.beforeSubmit();
        const data = {
            email: this.state.email,
        }
        if (this.state.type === 'register') {
            UserService.resendMail(data
            ).then(data => {
                this.afterSubmit();
                this.setState({
                    sent: true
                })
                this.timeoutSent = setTimeout(() => {
                    this.resetSent()
                }, 4000);
            }).catch((error) => {
                this.afterSubmit();
                this.dealWithError(error, 'Hubo un error');
            });
        } else {
            UserService.resetPassword(data
            ).then(data => {
                this.afterSubmit();
                this.setState({
                    sent: true
                })
                this.timeoutSent = setTimeout(() => {
                    this.resetSent()
                }, 4000);
            }).catch((error) => {
                this.afterSubmit();
                this.dealWithError(error, 'Hubo un error');
            });
        }
    };

    render() {
        const title = this.state.type === 'register' ? 'Confirmar cuenta' : 'Olvidé mi contraseña';

        const content = <div className='email-verification'>
            <Logo></Logo>
            <i className='icon icon-reenviar-email'></i>
            <p className='title'>Revisa tu mail</p>
            <p className={'subtitle'}>
                Te hemos enviado un email a<br></br>
                <span className='bold'>{this.props.location.state.email}</span>
                {this.state.type === 'forgot' ? ' para que puedas reestablecer la contraseña.' : ''}
            </p>
            
            <div className='question-wrapper'>
                <p className="question">¿No has recibido el email?</p>
                <p className="answer">Recuerda revisar la bandeja de Spam</p>
            </div>
            <div className="rounded-button-wrapper">
                <RoundedButton legend={this.state.sent ? 'Enviado' :'Reenviar email'} handleSubmit={this.handleSubmit} state={this.state.submitted ? 'waiting' : (this.state.sent ? 'temporary' : 'enabled')} handleClick={this.handleSubmit} ></RoundedButton>
            </div>
            <div className="modal-button-wrapper">
                <Link to="/create" className="link"><ModalButton label="CAMBIAR EMAIL"></ModalButton></Link>
            </div>
        </div>

        return (
            <div className='email-verification-container'>
                <AuthGeneric title={title} version={'header'} content={content} ></AuthGeneric>
            </div>
        )
    }

}


EmailVerificationView.propTypes = {
    handleChange: PropTypes.func,
    label: PropTypes.string
};

export default EmailVerificationView;