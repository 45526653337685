import React from "react";
import "./styles.scss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const getOptions = (series, colors) => {
  return {
    chart: {
      renderTo: "container",
      type: "pie",
      height: '250px'
    },
    title: {
      text: null,
    },
    plotOptions: {
      pie: {
        shadow: false,
      },
    },
    tooltip: {
      formatter: function() {
        return "<b>" + this.point.name + "</b>: " + this.y ;
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Browsers",
        data: series,
        colors: colors,
        size: "100%",
        innerSize: "85%",
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };
};
const DonutChart = ({
  series = [
    ["Completadas", 812],
    ["En Proceso", 490],
    ["Pendientes", 303],
  ],
  colors = ["#62D862", "#3C7AF5", "#F54B5A"],
  totalNumber=1605,
  itemName = 'Ordenes'
}) => {
  return (
    <div className={"donut-chart-container"}>
      <div style={{width: '250px'}} >
        <div style={{position: 'relative'}}>
          <HighchartsReact
            highcharts={Highcharts}
            options={getOptions(series, colors)}
          />
          <div className={'inner-legend'}>
  <div className={'inner-legend-number'}>{totalNumber}</div>
              <div className={'inner-legend-title'}>{itemName} totales</div>
          </div>
        </div>
      </div>

      <div className={"donut-chart-legend"}>
        {series.map((s, i) => {
          return (
            <div
              key={s[0]}
              className={`${
                i === 0 ? "donut-legend-item-first" : "donut-legend-item"
              }`}
            >
              <div className={"donut-legend-left"}>
                <div
                  className={"donut-legend-circle"}
                  style={{ borderColor: `${colors[i]}` }}
                >
                  {" "}
                </div>
                <div
                  className={`${
                    i === 0 ? "donut-legend-name-first" : "donut-legend-name"
                  }`}
                >
                  {" "}
                  {s[0]}
                </div>
              </div>

              <div
                className={`${
                  i === 0 ? "donut-legend-number-first" : "donut-legend-number"
                }`}
                style={{ color: `${i === 0 ? colors[i] : "#858BA5"}` }}
              >
                {" "}
                {s[1]}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DonutChart;
