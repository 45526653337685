import React from "react";

import Step from "components/MainApp/atoms/Wizard/Step";
import ProductSummary from "components/MainApp/organisms/Orders/RequestOrders/ProductSummary";
import Icon from "components/MainApp/atoms/Icon/icon";
import "./styles.scss";

export const RequestOrderStep3 = ({
  products,
  handleEditDest,
  handleEditProducts,
  order,
}) => {
  const stepContent = (
    <div className="request-order-step-three-content">
      <div
        className="request-order-step-three-field"
        data-automation-id="input-search"
      >
        <div className="request-order-step-three-first-row">
          <div className={`request-order-step-title`}>
            <div className={`request-order-step-title-wrapper`}> MOTIVO </div>
            <div
              className="request-order-step-three-icon-wrapper"
              onClick={handleEditDest}
            >
              <Icon name="edit2" width={20} height={20} fill={"#3C7AF5"} />
            </div>
          </div>
          <div className={`request-order-step-dest`}>{order.klass}</div>
          {order.customer && (
            <>
              <div className={`request-order-step-title`}>
                <div className={`request-order-step-title-wrapper`}>
                  {" "}
                  CLIENTE{" "}
                </div>
              </div>
              <div className={`request-order-step-dest`}>
                {order.customer?.first_name}
              </div>
            </>
          )}
          {order.owner && (
            <>
              <div className={`request-order-step-title`}>
                <div className={`request-order-step-title-wrapper`}>
                  {" "}
                  RESPONSABLE{" "}
                </div>
              </div>
              <div className={`request-order-step-dest`}>
                {order.owner?.first_name}
              </div>{" "}
            </>
          )}
          {order.notes && (
            <>
              <div className={`request-order-step-title`}>
                <div className={`request-order-step-title-wrapper`}>
                  {" "}
                  DESCRIPCION{" "}
                </div>
              </div>
              <div className={`request-order-step-dest`}>
                {order.notes && order.notes}
              </div>
            </>
          )}
        </div>

        <div className="request-order-step-three-second-row">
          <ProductSummary
            products={products}
            edit={true}
            handleEdit={handleEditProducts}
          />
        </div>
      </div>
    </div>
  );
  return (
    <div className="request-order-step-three-container">
      <Step title="Verifica los datos" content={stepContent}></Step>
    </div>
  );
};

export default RequestOrderStep3;
