import React from "react";
import onClickOutside from "react-onclickoutside";
import ProductResultSearch from "../../../organisms/Orders/RequestOrders/ProductResultSearch";
import Checkbox from "../../../../Auth/atoms/forms/CheckboxCostum";

import "./styles.scss";
import { de } from "date-fns/locale";
export class SearchWithComponentsNoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: this.props.value ? this.props.value : "",
      submitted: false,
      filteredResultList: []
    };
  }

  handleChange = e => {
    e.preventDefault();
    const value = e.target.value;
    let newFilteredResultList;
    let valueFilter = value.trim();
    if (valueFilter !== "") {
      newFilteredResultList = this.props.resultList.filter(w => {
        return (
          w.name.toLowerCase().includes(valueFilter.toLowerCase()) ||
          w.id.toLowerCase().includes(valueFilter.toLowerCase()) ||
          w?.sku.toLowerCase().includes(valueFilter.toLowerCase())
        );
      });
    } else {
      newFilteredResultList = [];
    }

    this.setState({
      value,
      submitted: false,
      focused: true,
      filteredResultList: newFilteredResultList.splice(0, 20)
    });
  };

  handleSelect = e => {
    const value = e.target.dataset.value;
    this.setState({
      value,
      submitted: true,
      focused: false
    });
    this.props.handleSubmit(value);
  };

  handleClickOutside() {
    this.setState({
      focused: false
    });
  }

  filterResults = () => {};

  cleanInput = () => {
    this.setState(
      {
        value: "",
        submitted: false,
        focused: false
      },
      _ => {
        this.props.handleSubmit(this.state.value);
      }
    );
  };

  handleFocus = () => {
    this.setState({
      focused: true
    });
  };

  handleBlur = () => {
    this.setState({
      focused: false
    });
  };
  handleSubmit = e => {
    this.setState({
      submitted: true,
      focused: false
    });
    if (this.props.handleSubmit == undefined) return false;
    this.props.handleSubmit(this.state.value);
  };

  handleAdd = () => {
    this.setState({
      submitted: true,
      focused: false,
      value: ""
    });
    this.props.handleAdd();
  };
  render() {
    const {
      placeholder,
      // value,
      handleSelectToAddProduct,
      handleSelectedProductQuantityChange,
      selectedProducts,
      onlyStockHidden,
      label,
      checkBoxLabel = "Solo productos sin stock",
      handleCheckbox,
      checkBoxChecked
    } = this.props;

    return (
      <div
        className={
          this.state.focused
            ? "search-with-components-container focused"
            : "search-with-components-container"
        }
      >
        <div className="search-with-components-input-wrapper">
          <div id="divForm" className="search-with-components-input-form">
            <i className="search-with-components-icon-desktop icon-iconos_buscar"></i>
            <i
              onClick={this.handleBlur}
              className={
                this.state.focused
                  ? "search-with-components-icon-mobile-focused icon-back"
                  : "search-with-components-icon-mobile icon-iconos_buscar"
              }
            ></i>
            <input
              type="text"
              className="search-with-components-input"
              placeholder={placeholder}
              value={this.state.value}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              data-automation-id="search-with-components-input"
            ></input>
            {!onlyStockHidden && (
              <>
                <div className="divider"></div>
                <div className="search-with-component-only-stock">
                  <Checkbox
                    handleChange={handleCheckbox}
                    checked={checkBoxChecked}
                  />{" "}
                  <div className="search-with-component-checkboxl-label">
                    {checkBoxLabel}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {this.state.value.length > 2 &&
          this.state.focused &&
          this.state.filteredResultList.length > 0 &&
          !this.state.submitted && (
            <div className="search-with-components-results-desktop">
              <ul
                className="search-with-components-results-desktop-inner"
                data-automation-id="search-with-components-result-list"
              >
                {this.state.filteredResultList.map(item => {
                  return (
                    <div
                      className="search-with-components-result-item"
                      key={item.id}
                    >
                      <Checkbox
                        handleChange={handleSelectToAddProduct}
                        checked={selectedProducts.includes(item.id)}
                        label={item.id}
                        hideLabel={true}
                      />
                      <ProductResultSearch
                        label={label}
                        handleClick={() => {
                          handleSelectToAddProduct(item.id);
                        }}
                        handleChange={handleSelectedProductQuantityChange}
                        key={item.code}
                        product={item}
                        units={item.units}
                        disabled={!selectedProducts.includes(item.id)}
                      ></ProductResultSearch>
                    </div>
                  );
                })}
              </ul>
              <div className={`search-with-components-results-add-wrapper`}>
                <div
                  className={`search-with-components-results-add-button`}
                  onClick={this.handleAdd}
                >
                  Agregar
                </div>
              </div>
            </div>
          )}

        {this.state.value.length > 2 &&
          this.state.focused &&
          this.state.filteredResultList.length > 0 &&
          !this.state.submitted && (
            <ul
              className="search-with-components-results-mobile"
              data-automation-id="search-with-components-result-list"
            >
              <div className="search-with-component-only-stock">
                <Checkbox
                  handleChange={handleCheckbox}
                  checked={checkBoxChecked}
                />
                <div className="search-with-component-checkboxl-label">
                  {checkBoxLabel}
                </div>
              </div>
              {this.state.filteredResultList.map(item => {
                return (
                  <div
                    className="search-with-components-result-item"
                    key={item.id}
                  >
                    {/* <Checkbox /> */}
                    {/* <ProductResultSearch key={item.code} product={item} units={item.units}></ProductResultSearch> */}
                    <div className="search-with-components-result-item-inner">
                      <Checkbox
                        handleChange={handleSelectToAddProduct}
                        checked={selectedProducts.includes(item.id)}
                        label={item.id}
                        hideLabel={true}
                      />
                      <ProductResultSearch
                        label={label}
                        handleClick={() => {
                          handleSelectToAddProduct(item.id);
                        }}
                        handleChange={handleSelectedProductQuantityChange}
                        key={item.code}
                        product={item}
                        units={item.units}
                        disabled={!selectedProducts.includes(item.id)}
                      ></ProductResultSearch>
                    </div>
                    <div className="search-with-components-result-item-divider"></div>
                  </div>
                );
              })}
              <div className={`search-with-components-results-add-wrapper`}>
                <div
                  className={`search-with-components-results-add-button`}
                  onClick={this.handleAdd}
                >
                  Agregar
                </div>
              </div>
            </ul>
          )}
        {/* <ul className="search-with-components-results">
              {this.state.filteredResultList.map(item =>{
                  return <li key={item}>{item}</li>
              })}
            </ul> */}
      </div>
    );
  }
}

export default onClickOutside(SearchWithComponentsNoForm);
