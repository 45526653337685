import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './styles.scss';
import GenericFormContainer from '../../../helpers/GenericFormContainer';
import {Validations} from '../../../helpers/validations';
import {UserService} from '../../../services/UserService';
import AuthGeneric from '../../../components/Auth/layouts/AuthGeneric'
import RoundedButton from '../../../components/Auth/atoms/buttons/RoundedButton'
import ModalButton from '../../../components/Auth/atoms/modals/ModalButton'
import CostumInput from '../../../components/Auth/atoms/forms/InputCostum'
import PasswordInput from '../../../components/Auth/atoms/forms/InputPasswordAuth'
import ExistingAccountModal from '../../../components/Auth/organisms/Modals/ExistingAccountModal';
import Logo from "../../../components/Auth/atoms/images/Logo";
import { isMobile } from 'helpers/Mobile';

export class CreateAccountView extends GenericFormContainer {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            userData: {
                user: null,
                password: null
            },
            errors:{
                username: null,
                password: null
            },
            buttonState: 'disabled',
            passwordValidation: [false, false, false],
            passwordError: false
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.beforeSubmit();
        const data = {
            username: this.state.userData.user,
            email: this.state.userData.user,
            password: this.state.userData.password
        }
        UserService.register(data
        ).then(data => {
            this.afterSubmit();
            this.props.history.push({
                pathname: '/email-verification',
                state: {
                    email: this.state.userData.user,
                    type: 'register',
                }
              });
        }).catch((error) => {
            this.afterSubmit();
            this.dealWithError(error, 'Registro invalido', _ => {
                if (error.status === 409) {
                    let error;
                    if( isMobile ) {
                       error = null
                    }else{
                        error = 'Este correo ya está registrado. Intenta con otro email o ingresa a tu cuenta.'
                    }
                    this.setState({
                        errors: {
                            'username': error
                        },
                        modalErrorOpen: true
                    });
                }
            });
        });
    };

    handleChange = (e) => {
        let id = e.target.id
        let userData = { ...this.state.userData };
        userData[id] = e.target.value;
        this.setState({
            userData: userData
        }, _ => {
            this.validateFieldsAfterChange(userData);
        })
    }

    validateFieldsAfterChange = (userData) => {
        let errors = {...this.state.errors}

        if(!Validations.email(userData.user)){
            errors.username = 'Ingresa un email válido.'
            
        }else{
            errors.username = null
        }
        let passwordValidation = Validations.password(userData.password)
        let passwordError = passwordValidation.every((v) => v === true) ? null : 'Contrasena invalida'
        //errors.password = passwordError
        this.setState({
            passwordValidation: passwordValidation,
            passwordError: passwordError,
            errors: errors
        }, _ => {
            this.updateButtonState(userData);
        })
    }
    
    updateButtonState = (userData) => {
        if (userData.user && userData.password && !this.state.errors.username && !this.state.passwordError) {
            this.setState({
                buttonState: 'enabled'
            })
        } else {
            this.setState({
                buttonState: 'disabled'
            })
        }
    }

    handleGoBack = () => {
        this.props.history.goBack()
    }

    handleCloseErrorModal = (e) => {
        e.preventDefault();
        this.setState({
            modalErrorOpen: false
        })

    }
    render() {
        const content = <div className='create-account'>
            <form onSubmit={this.handleSubmit}>
                <Logo></Logo>
                <p className='title'>Crear cuenta</p>
                <div className='form-container'>
                    <div className="inputs-wrapper">
                        <CostumInput placeholder="Email" icon="icon-mail" handleChange={this.handleChange} id={'user'} error={this.state.errors.username}></CostumInput>
                        <PasswordInput error={this.state.errors.password} validate={true} validationResults={this.state.passwordValidation} handleChange={this.handleChange} id={'password'}></PasswordInput>
                    </div>
                </div>
                <div className="rounded-button-wrapper">
                    <Link to="/create" className="link"><RoundedButton legend='Crear cuenta' handleClick={this.handleSubmit} state={this.state.buttonState}></RoundedButton></Link>
                </div>
                <div className='form-footer'>
                    <p className='footer-legend'>¿Ya tienes cuenta?</p>
                    <Link to="/login" className="link"><ModalButton label="Ingresar"></ModalButton></Link>
                </div>
            </form>
            {this.state.modalErrorOpen  &&
                <div className='modal-error-wrapper'>
                    <ExistingAccountModal open={this.state.modalErrorOpen} handleCancel={this.handleCloseErrorModal}></ExistingAccountModal>
                </div>
            }
        </div>

        return (
            <div className='sign-in-container'>
                <AuthGeneric title={'Crear cuenta'} version={'header'} content={content} handleGoBack={this.handleGoBack}></AuthGeneric>
            </div>
        )
    }

}


CreateAccountView.propTypes = {
    handleChange: PropTypes.func,
    label: PropTypes.string
};

export default CreateAccountView;