import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";

import { TagService } from "../../../../services/TagService";

import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";

import "./styles.scss";
import EditCodeTagForm from "./EditCodeTagForm";


const EditCodeTagView = (props) => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [configTag, setConfigTag] = useState();
  const [tag_code, setTagCode] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!props.match.params.key) return;
    beforeSubmit();
    const id = props.match.params.key;
    TagService.configTagInfo(id)
      .then((response) => {
        afterSubmit();
        setConfigTag(response)
        if(response?.zplFormat) setTagCode(response.zplFormat);
      })
      .catch((error) => {
        afterSubmit();
      });
  }, []);

  useEffect(() => {
    setError('');
    const fetchImage = async () => {
      if(tag_code?.trim().length === 0){
        setImageSrc('');
        return;
      }

      const protocol = window.location.protocol;
      const url = `${protocol}//api.labelary.com/v1/printers/12dpmm/labels/3.5x1.5/0/${tag_code}`;

      try {
        const response = await fetch(url);
        if (!response.ok) setError('No se ha podido obtener la imagen');

        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageSrc(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching image:', error);
      }
    };

    fetchImage();
  }, [tag_code]);


  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      beforeSubmit();
      const _configTag = { ...configTag };
      if(tag_code?.trim().length > 0) _configTag.zplFormat = tag_code;
      TagService.saveZplFormat(props.match.params.key, _configTag)
        .then((response) => {
          afterSubmit();
          EventRepository.notificationSend({
            label: i18n.t("Tag actualizado con exito"),
            type: "success",
          });
          history.push(`/admin/config_tags/detail/${props.match.params.key}`);
        })
        .catch((error) => {
          afterSubmit();
          dealWithError(error, "generic.error", (message, errors) => {
            analizeErrors(message, errors);
          });
        });
  };

  const handleGoBack = () => {
    history.push(`/admin/config_tags/detail/${props.match.params.key}`);
  };

  const handleClickVisualize = (tag_code) => {
    setTagCode(tag_code);
  };


  const handleConfigTagChange = (updatedConfigTag) => {
      setConfigTag(updatedConfigTag);
      setTagCode(updatedConfigTag?.zplFormat || '');
  };


  const headerOptions = [];
  const content = (
    <div className={"edit-code-tag-content-wrapper"}>
      <div className={"edit-code-tag-inner"}>
        <form onSubmit={handleSubmit}>
          <EditCodeTagForm
            configTag={configTag}
            handleClickVisualize={handleClickVisualize}
            onConfigTagChange={handleConfigTagChange}
            imageSrc={imageSrc}
            error={error}
          />
          <div className={"edit-code-tag-button-wrapper"}>
            <button
              type="submit"
              className={error !== '' || !tag_code || tag_code?.trim() === "" ? `edit-code-tag-button disabled` : `edit-code-tag-button`}
              onClick={handleSubmit}
              disabled={error !== '' || !tag_code || tag_code?.trim() === ""}
            >
              {"GUARDAR"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  return (
    <div className="edit-code-tag-container">
      <AdminLayout
        headerTitle={i18n.t(`Editar Plantilla`)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default EditCodeTagView;
