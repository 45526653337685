import useWindowSize from "hooks/useWindowSize";
import GuardActivityViewDesk from "./versions/desktop/GuardActivityViewDesk";
import GuardActivityViewMobile from "./versions/mobile/GuardActivityViewMobile";

const GuardActivityView = () => {
  const { isMobile } = useWindowSize();

  return isMobile ? <GuardActivityViewMobile /> : <GuardActivityViewDesk />;
};

export default GuardActivityView;
