import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";


import "./styles.scss";

const keyTitleMap = {
    name: 'Nombre',
    business_name: 'Razon Social',
    nif: 'Nif',
    sector: 'Rubro',
    country: 'Pais',
    region: 'Comunidad',
    state: 'Provincia',
    locality: 'Municipio',
    currency: 'Moneda',
    address: 'Direccion',
    phone: 'Telefono',
    email: 'Email',
}
export class AddConfigTagStepThree extends React.Component {
  render() {
    const { business, goBack, handleFinish } = this.props;

    const stepContent = (
      <form onSubmit={handleFinish}>
      <div className="add-business-wizard-step-three-content">
        <div className="section">
          <div className="section-title">Descripcion</div>
          {
            ['name', 'business_name', 'nif', 'sector', 'country', 'region', 'state', 'locality', 'currency',].map(
              key => {
                return business[key] ? <div key={key}> 
                  <div className="title"> {keyTitleMap[key]} </div>
                  <div className="value"> {business[key].label ? business[key].label : business[key]}</div>
                </div> : ''
              }
            )
          }
        </div>
        <div className="section">
          <div className="section-title">Imagen</div>
          <div className="logo">
            <img src={business.image && URL.createObjectURL(business.image)}/>
          </div>
        </div>
        <div className="section">
          <div className="section-title">Contacto</div>
          {
            ['address', 'phone', 'email'].map(
              key => {
                return business[key] ? <div key={key}> 
                  <div className="title"> {keyTitleMap[key]} </div>
                  <div className="value"> {business[key]}</div>
                </div> : ''
              }
            )
          }
        </div>
        <div className={"add-business-wizard-step-three-action-wrapper"}>
          <button type="submit" className="add-business-wizard-step-three-action-button">
            FINALIZAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-business-wizard-step-three-container">
        <Step title="Confirmación" content={stepContent} goBackFunc={goBack}></Step>
      </div>
    );
  }
}

export default AddConfigTagStepThree;
