import useWindowSize from "hooks/useWindowSize";
import FilterAlertsDesktop from "./version/desktop/FilterAlertsDesktop";
import FilterAlertsMobile from "./version/mobile/FilterAlertsMobile";

const FilterAlerts = props => {
  const { isMobile } = useWindowSize();

  return isMobile ? (
    <FilterAlertsMobile {...props} />
  ) : (
    <FilterAlertsDesktop {...props} />
  );
};

export default FilterAlerts;
