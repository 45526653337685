import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from "@mui/material";
import "./DeviceModal.scss";

const DeviceModal = ({ device, open, onClose, onSave }) => {
  const [editedDevice, setEditedDevice] = useState(device);

  useEffect(() => {
    setEditedDevice(device);
  }, [open]);

  const handleChange = e => {
    const { name, value } = e.target;
    setEditedDevice(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    if (areRequiredFieldsFilled()) {
      onSave(editedDevice);
      onClose();
    }
  };

  const areRequiredFieldsFilled = () => {
    return (
      editedDevice.id?.trim() &&
      editedDevice.name?.trim() &&
      editedDevice.url?.trim() &&
      editedDevice.urlToken?.trim() &&
      editedDevice.userEmail?.trim() &&
      editedDevice.userPass?.trim()
    );
  };

  const isFieldInvalid = (fieldName) => {
    return !editedDevice[fieldName]?.trim();
  };

  return (
    <Dialog className="dialog_device" open={open} onClose={onClose}>
      <DialogTitle>Edición Dispositivo</DialogTitle>
      <DialogContent>
        <TextField
          error={isFieldInvalid('id')}
          helperText={isFieldInvalid('id') ? 'Este campo es requerido' : ''}
          margin="dense"
          label="id"
          type="text"
          fullWidth
          variant="outlined"
          name="id"
          value={editedDevice.id || ""}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          label="ip"
          type="text"
          fullWidth
          variant="outlined"
          name="ip"
          value={editedDevice.ip || ""}
          onChange={handleChange}
        />
        <TextField
          error={isFieldInvalid('name')}
          helperText={isFieldInvalid('name') ? 'Este campo es requerido' : ''}
          margin="dense"
          label="name"
          type="text"
          fullWidth
          variant="outlined"
          name="name"
          value={editedDevice.name || ""}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          label="port"
          type="text"
          fullWidth
          variant="outlined"
          name="port"
          value={editedDevice.port || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="serialReader"
          type="text"
          fullWidth
          variant="outlined"
          name="serialReader"
          value={editedDevice.serialReader || ""}
          onChange={handleChange}
        />
        <TextField
          error={isFieldInvalid('url')}
          helperText={isFieldInvalid('url') ? 'Este campo es requerido' : ''}
          margin="dense"
          label="url"
          type="text"
          fullWidth
          variant="outlined"
          name="url"
          value={editedDevice.url || ""}
          onChange={handleChange}
          required
        />
        <TextField
          error={isFieldInvalid('urlToken')}
          helperText={isFieldInvalid('urlToken') ? 'Este campo es requerido' : ''}
          margin="dense"
          label="urlToken"
          type="text"
          fullWidth
          variant="outlined"
          name="urlToken"
          value={editedDevice.urlToken || ""}
          onChange={handleChange}
          required
        />
        <TextField
          error={isFieldInvalid('userEmail')}
          helperText={isFieldInvalid('userEmail') ? 'Este campo es requerido' : ''}
          margin="dense"
          label="userEmail"
          type="text"
          fullWidth
          variant="outlined"
          name="userEmail"
          value={editedDevice.userEmail || ""}
          onChange={handleChange}
          required
        />
        <TextField
          error={isFieldInvalid('userPass')}
          helperText={isFieldInvalid('userPass') ? 'Este campo es requerido' : ''}
          margin="dense"
          label="userPass"
          type="text"
          fullWidth
          variant="outlined"
          name="userPass"
          value={editedDevice.userPass || ""}
          onChange={handleChange}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button className="btn_cancel" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          className="btn_save"
          onClick={handleSave}
          disabled={!areRequiredFieldsFilled()}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceModal;
