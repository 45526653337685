const filters = [
  {
    label: "Sucursal",
    options: [
      {
        id: "1",
        label: "op1",
        value: "op1",
      },
      {
        id: "2",
        label: "op2",
        value: "op2",
      },
      {
        id: "3",
        label: "op3",
        value: "op3",
      },
      {
        id: "4",
        label: "op4",
        value: "op4",
      },
    ],
    selectedOption: {
      id: "1",
      label: "op1",
      value: "op1",
    },
    placeholder: "Selecciona",
  },
  {
    label: "Zona",
    selectedOptions: null,
    placeholder: "Selecciona",
  },
  {
    label: "Sector",
    selectedOptions: null,
    placeholder: "Selecciona",
  },
];

export default filters;
