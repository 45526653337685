import React from 'react';
import './styles.scss';

export class CheckboxCostumAuth extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: this.props.checked
        }
    }


    handleChange = (e) => {
        const newValue = !this.state.checked
        this.setState({
            checked: newValue
        })
        if(this.props.handleChange) this.props.handleChange(e.target.dataset.value)
    }

    render() {
        const {
            label,checked, hideLabel, disabled=false
        } = this.props;
        const icon = disabled ? 'icon-disabled icon-check-square': 'icon icon-check-square';
        return (
            <div className='checkbox-container'>
                <button type="button" className="button" data-value={label} data-checked={checked} onClick={this.handleChange} data-automation-id="checkbox">
                    {!this.props.checked && <div  data-value={label} className="unchecked"><i></i></div>}
                    {this.props.checked && <i  data-value={label} className={icon}></i>}
                    {!hideLabel && <> <label className="label"  data-value={label}> {label}</label><br></br></>}
                </button>
            </div>
        )
    }

}


CheckboxCostumAuth.propTypes = {};

export default CheckboxCostumAuth;