import Environment from "../environment";
import Api from "./ApiService";

import { Person } from "models/Persons";

export const PersonService = {
  persons: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/persons/`, "GET", params)
        .then((rta) => {
          if ("results" in rta) {
            rta.results = rta.results.map((item) => new Person(item));
          } else {
            rta = rta.map((item) => new Person(item));
          }
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
