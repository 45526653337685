import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthService } from '../../../../../services/AuthService';


class ProtectedRoute extends React.Component {

    render() {
        const {component: Component, ...rest} = this.props;
        const isAuthenticated = AuthService.currentUserValue() ? true : false;
        const hasPermissions = true;  // Analizar si tiene el permiso
        return (
            <Route
                {...rest}
                render={props =>
                    {
                        return (isAuthenticated && hasPermissions) ? (
                            <Component {...props} />
                        ) : (
                            <Redirect
                                to={{ pathname: this.props.redirectUrl ? this.props.redirectUrl : '/login' }}
                            />
                        )
                    }
                }
            />
        );
    }

    // render() {
    //     const Component = this.props.component;
    //     const isAuthenticated = AuthService.currentUserValue() ? true : false;
    //     const hasPermissions = true;  // Analizar si tiene el permiso
       
    //     return (isAuthenticated && hasPermissions) ? (
    //         <Component />
    //     ) : (
    //         <Redirect to={{ pathname: this.props.redirectUrl ? this.props.redirectUrl : '/login' }} />
    //     );
    // }
}

ProtectedRoute.propTypes = {};

export default ProtectedRoute;