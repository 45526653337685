import React, { useState, useEffect, useRef } from "react";
import useFilters from "hooks/useFilters";
import { useHistory } from "react-router-dom";
import { useStore } from "store/context";
import i18n from "i18n";
import { isMobile } from "helpers/Mobile";

import { UserService } from "services/UserService";

import "./styles.scss";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import UsersTable from "./components/Table";

import Search from "components/MainApp/atoms/Filters/Search";
import FilterPanel from "components/MainApp/organisms/Filters/FilterPanel";
import headers from "./headers";
import Pagination from "components/MainApp/atoms/Pagination";
import Loader from "components/MainApp/atoms/Loader";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";
import { NotFoundItems } from "../components/NotFoundItems";

const initialFilters = {
  page_size: 10,
  search: "",
  ordering: "-date_joined",
  is_active: undefined
};
const filterTypeMap = {
  "Estado del usuario": {
    name: "is_active",
    type: "twoOption",
    default_value: undefined
  },
  "Ordenar por": {
    name: "ordering",
    type: "twoOption",
    default_value: "-date_joined"
  }
};
const filterDataSource = [
  {
    type: "twoOption",
    options: [
      {
        selected: false,
        label: "Habilitado",
        value: true
      },
      {
        selected: false,
        label: "Inhabilitado",
        value: false
      }
    ],
    label: "Estado del usuario",
    firstOptionLabel: "Todos",
    name: "is_active"
  }
];

export const UsersView = () => {
  const [usersFilters, setUsersFilters] = useState();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [noUsers, setNoUsers] = useState(false);
  const [filterData, setFilterData] = useState(filterDataSource);
  const [isSearched, setSearched] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [submitted, setSubmitted] = useState(false);
  const myRef = useRef(0);

  let scrolling = false;

  const [
    filters,
    setFilters,
    selectedFiltersAll,
    selectedFiltersByType,
    handleApplyFilters,
    handleSearch,
    headerOrderingHandler,
    resetOrdering,
    handleFiltersChangeOrdering,
    applied,
    page,
    setPage,
    items,
    setItems,
    pager,
    setPager,
    header,
    setHeader,
    mobilePanelOpen,
    setMobilePanelOpen
  ] = useFilters(
    initialFilters,
    usersFilters,
    filterTypeMap,
    isMobile,
    headers
  );

  useEffect(() => {
    fillFilterData();
  }, []);

  useEffect(() => {
    const first_load =
      JSON.stringify(initialFilters) === JSON.stringify(filters);
    filter(first_load);
    // Acomodar orden
    const keyOrdering =
      filters.ordering.charAt(0) === "-"
        ? filters.ordering.substr(1)
        : filters.ordering;
    if (keyOrdering in headers) {
      const newHeaders = { ...header };
      newHeaders[keyOrdering]["active"] = true;
      newHeaders[keyOrdering]["direction"] =
        filters.ordering === keyOrdering ? true : false;
      setHeader(newHeaders);
    }
  }, []);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }
    filter(false);
    setUsersFilters({
      filters: filters,
      selectedFiltersAll: selectedFiltersAll,
      selectedFiltersByType: selectedFiltersByType
    });
  }, [filters, page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  const handleScroll = event => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || submitted || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const handlePagerChange = function(page) {
    setPage(page);
  };

  const filter = function(firstLoad = false) {
    setSearched(false);
    let append = isMobile;
    if (!append) {
      setItems([]);
    }

    //BEFORE SUBMIT
    document.body.style.cursor = "wait";
    setSubmitted(true);

    const data = { ...filters, page: page };
    setLoading(true);

    UserService.users(data)
      .then(response => {
        setSubmitted(false);
        document.body.style.cursor = "default";
        scrolling = false;

        if (firstLoad && response.count === 0) {
          //   history.push("/admin/users/add");
          setNoUsers(true);
          return;
        }

        const newItems = append
          ? [...items, ...response.results]
          : response.results;

        setLoading(false);
        setItems(newItems);
        setPager({
          next: response.next,
          previous: response.previous,
          count: response.count,
          countPages: Math.ceil(response.count / filters.page_size)
        });
        setSearched(true);
      })
      .catch(e => {
        setSubmitted(false);
        setSearched(true);
        document.body.style.cursor = "default";
        scrolling = false;
      });
  };

  const fillFilterData = function() {
    let filterDataAux = [...filterData];
    filterDataAux[0].options = filterDataAux[0].options.map(option => {
      option.selected = false;
      return option;
    });

    if (isMobile) {
      filterDataAux[1] = {
        type: "twoOption",
        options: [
          {
            selected: false,
            label: "Menos reciente",
            value: "date_joined"
          }
        ],
        label: "Ordenar por",
        firstOptionLabel: "Más reciente",
        value: "-date_joined"
      };
    }
    setFilterData(filterDataAux);
  };

  const goToDetailsPage = id => {
    history.push(`/admin/users/edit/${id}`);
  };

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: () => {
        history.push("/admin/users/add");
      },
      tooltip: "Agregar usuario"
    }
  ];

  const headerOptionsIcons = [
    {
      icon: "profiles",
      name: "profiles",
      handler: () => {
        history.push(`/admin/roles`);
      },
      tooltip: "Roles"
    }
  ];

  const tableContentDesktop = (
    <React.Fragment>
      <FilterPanel
        resultList={[]}
        placeholder="Busca por nombre o código"
        searchValue={filters.search}
        handleSubmitSearch={handleSearch}
        filters={filterData}
        selectedFiltersAll={selectedFiltersAll}
        selectedFiltersByType={selectedFiltersByType}
        handleApplyFilters={handleApplyFilters}
      ></FilterPanel>

      {items.length > 0 && (
        <div className="users-table-table">
          <div className="users-table-top-table">
            <div className="users-table-top-total">
              TOTAL{" "}
              <span className="users-table-top-total-bold">
                ({pager.count})
              </span>
            </div>
            <div className="users-table-top-sort">
              <div
                data-automation-id="users-table-sort"
                className={
                  filters.ordering.includes("date_joined")
                    ? "users-table-top-sort users-table-active-filter"
                    : "users-table-top-sort"
                }
                onClick={() => {
                  handleFiltersChangeOrdering(
                    "ordering",
                    filters.ordering === "-date_joined"
                      ? "date_joined"
                      : "-date_joined"
                  );
                  resetOrdering();
                }}
              >
                {filters.ordering === "date_joined" ? (
                  <React.Fragment>
                    Menos recientes{" "}
                    <i className="icon-chevron users-table-top-icon"></i>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {" "}
                    Más recientes{" "}
                    <i className="icon-chevron-down users-table-chevron-down users-table-top-icon"></i>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <UsersTable
            users={items}
            headers={header}
            headerClickHandler={headerOrderingHandler}
            handleClick={goToDetailsPage}
          />
        </div>
      )}

      {items.length === 0 && !submitted && (
        <NotFoundItems />
      )}
      {items.length > 0 && (
        <div>
          <Pagination
            pageQuantity={pager.countPages}
            currentPage={page}
            pageChangeHandler={handlePagerChange}
          />
        </div>
      )}
    </React.Fragment>
  );

  const tableContentMobile = (
    <React.Fragment>
      <div className="users-table-filter-container">
        <SimpleSearchPanel
          placeholder="Busca por nombre"
          handleSubmit={handleSearch}
        />
      </div>
      <div
        className={`users-table-mobile-panel ${
          mobilePanelOpen ? "opened" : ""
        } `}
      >
        <FilterPanel
          resultList={[]}
          placeholder="Busca por nombre o código"
          searchValue={filters.search}
          handleSubmitSearch={handleSearch}
          filters={filterData}
          selectedFiltersAll={selectedFiltersAll}
          selectedFiltersByType={selectedFiltersByType}
          handleApplyFilters={handleApplyFilters}
          closeMobilePanel={() => {
            setMobilePanelOpen(false);
          }}
        ></FilterPanel>
      </div>
      <div className="users-table-table">
        <div className="users-table-top-table">
          <div className="users-table-top-total">
            Total{" "}
            <span className="users-table-top-total-bold">({pager.count})</span>
          </div>
          <div
            className={`users-table-top-sort ${
              applied ? "users-table-active-filter" : ""
            }`}
            onClick={() => {
              setMobilePanelOpen(true);
            }}
          >
            <i className="icon-iconos_filtros users-table-top-icon"></i>
          </div>
        </div>
        <UsersTable
          users={items}
          showMessageNoneResult={false}
          headers={headers}
          handleClick={goToDetailsPage}
        />
      </div>

      {items.length === 0 && isSearched && (
        <NotFoundItems />
      )}
    </React.Fragment>
  );

  const tableContent = isMobile ? tableContentMobile : tableContentDesktop;

  const noproductcontent = (
    <div className="table-users-no-content">
      <p className="table-users-title">Aún no tienes usuarios creados.</p>
      <p className="table-users-title">¡Crea tu primer usuario!</p>

      <div
        className="table-users-button"
        onClick={() => history.push("/admin/users/add")}
      >
        CREAR
      </div>
    </div>
  );

  const content = (
    <div className="users-table-wrapper" ref={myRef}>
      <div className="users-table-inner">
        {noUsers ? noproductcontent : tableContent}
      </div>
    </div>
  );

  const goBack = () => {
    history.push(`/admin/more-options/1`);
  };

  return (
    <div className="users-container">
      <AdminLayout
        headerTitle={i18n.t("Usuarios")}
        goBackFunc={goBack}
        headerOptionsIcons={headerOptionsIcons}
        headerOptions={headerOptions}
        content={content}
        navHidden={false}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default UsersView;
