import React, { useState } from "react";
import Icon from "../../../../../../components/MainApp/atoms/Icon/icon";
import ItemTags from "components/MainApp/organisms/OrdersV3/Item-Tags";
import "./styles.scss";

export const VerifyMovementItems = ({
  originalItems,
  selectedItems,
  total,
  handleEdit,
  handleConfirm,
  handleBack,
}) => {
  return (
    <div className={"verify-movement-items-outter"}>
      <div className="verify-movement-items-wrapper">
        <div className="verify-movement-items-header">
          <div
            className="verify-movement-items-title-wrapper"
            onClick={handleBack}
          >
            <div className="verify-movement-items-title-icon">
              {" "}
              <Icon name="chevron" width={14} height={14} fill={"#62D862"} />
            </div>
            <h1 className="verify-movement-items-title">
              Verifica los productos a despachar
            </h1>
          </div>
          <div className="verify-movement-items-button-wrapper">
            <div
              className="verify-movement-items-button-confirm-inner"
              onClick={handleConfirm}
            >
              CONFIRMAR
            </div>
          </div>
        </div>

        <div className="verify-movement-items-content">
          <div className="verify-movement-items-list">
            <div className="prep-product-verify-header">
              <div className="prep-product-verify-left">
                <div className="prep-product-verify-top">
                  PRODUCTOS SOLICITADOS {total}
                </div>
                <div className="prep-product-verify-bottom">
                  PRODUCTOS CARGADOS {originalItems.length}
                </div>
              </div>
              <div
                className="prep-product-verify-rigth"
                onClick={handleEdit}
                style={{ cursor: "pointer" }}
              >
                <Icon name="edit2" width={20} height={20} fill={"#3C7AF5"} />
              </div>
            </div>

            {selectedItems.map((item) => {
              return (
                <ItemTags
                  key={item.sku}
                  item={item}
                  quantity={item.tags.length}
                  expected={item.expectedUnits}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyMovementItems;
