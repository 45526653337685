import React from "react";
import ItemStockReadingTableItem from "./ItemStock";
import Icon from 'components/MainApp/atoms/Icon/icon';
import "./styles.scss";
const ItemStockReadingTable = ({
  stock,
  headers,
  headerClickHandler,
  handleClickProduct,
  tagsInMov = [],
}) => {
  const renderHeaderItem = (key, index) => {
    return (
      <div
        key={index}
        data-automation-id={`sort-by-${key}`}
        onClick={() => {
          headerClickHandler(key);
        }}
        data-filter-name={key}
        className={`stock-reading-table-header-item stock-reading-table-header-${key}`}
      >
        {headers[key].title}
        <span className={`stock-reading-table-header-icon-span`}> </span>
      </div>
    );
  };
  const table = (
    <React.Fragment>
      <div className="stock-reading-table-desktop" data-automation-id="stock-table">
        <div className="stock-reading-table-header">
          {Object.keys(headers).map((key, index) => renderHeaderItem(key, index))}

          <div className="stock-reading-table-header-item stock-reading-table-header-button"></div>
        </div>
        <div className="stock-reading-table-content">
          {stock?.map((stockItem, index, array) => {
            let separatorText = "";
            if (stockItem.expectedUnits === 0) {
              separatorText = "Tags erróneos";
            } else if (stockItem.readItems === stockItem.expectedUnits) {
              separatorText = "Auditorías correctas";
            } else if (stockItem.readItems !== stockItem.expectedUnits) {
              separatorText = "Auditorías incorrectas";
            }

            const prevItem = array[index - 1];
            let prevSeparatorText = "";
            if (prevItem) {
              if (prevItem.expectedUnits === 0) {
                prevSeparatorText = "Tags erróneos";
              } else if (prevItem.readItems === prevItem.expectedUnits) {
                prevSeparatorText = "Auditorías correctas";
              } else if (prevItem.readItems !== prevItem.expectedUnits) {
                prevSeparatorText = "Auditorías incorrectas";
              }
            }

            const isGroupStart = index === 0 || separatorText !== prevSeparatorText;

            return (
              <React.Fragment key={stockItem.id}>
                {isGroupStart && <p className="text-separator">{separatorText}</p>}
                <div className="stock-reading-table-content-item">
                  <ItemStockReadingTableItem
                    stock={stockItem}
                    list={stockItem.tags}
                    tagsInMov={tagsInMov}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );

  const noitems = <div className={'stock-reading-table-no-items'}>
    <Icon
      name="stock"
      fill="#3C7AF5"
      width={87}
      height={65}
    ></Icon>
    <h1>Aún no tienes resultado</h1></div>

  return <div className="stock-reading-table-container">
    {stock.length > 0 ? table : noitems}
  </div>;
};

export default ItemStockReadingTable;
