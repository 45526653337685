const headers = {
  image: {
    name: 'image',
    title: ' ',
    active: false,
    direction: false
  },
  name: {
    name: 'name',
    title: 'NOMBRE',
    active: false,
    direction: false,
    value: "username",
  },
  email: {
    name: 'email',
    title: 'EMAIL',
    active: false,
    direction: false,
    value: "email",
  },
  role: {
    name: 'role',
    title: 'ROL',
    active: false,
    direction: false,
    hideOrdering: true,
  },
  status: {
    name: 'status',
    title: 'ESTADO',
    active: false,
    direction: false,
    hideOrdering: true,
  },
}

export default headers;