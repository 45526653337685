import React, { useState, useEffect, useRef } from "react";
import { LocationService } from "services/LocationService";
import { BusinessNodeService } from "services/BusinessNodeService";
import { PublicService } from "services/PublicService";

import { formatDateToYyyyMmDd } from "helpers/Parsers";

import SelectFilter from "components/MainApp/atoms/Filters/SelectFilter";
import Search from "components/MainApp/atoms/Filters/Search";
import DateInput from "components/MainApp/atoms/Forms/DateInput";
import "./styles.scss";

import filterData from "../data/filters";
import { isMobile } from "helpers/Mobile";


const FichaDeStockFilterPanel = ({ handleApply, deleteSearchFilter, opened = false }) => {
  const [searchedValue, setSearchedValue] = useState();
  const [products_name, setProducts_name] = useState();
  const [filters, setFilters] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
  const [appliedEnabled, setAppliedEnabled] = useState(false);
  const searchRef = useRef();

  useEffect(() => {
    PublicService.itemsName({ include_key: 1 }).then((response) => {
      setProducts_name(response);
    });

    BusinessNodeService.businessNodes({ no_page: 1 }).then((nodes) => {
      nodes = nodes.map((n) => {
        n.id = n.code;
        n.label = n.name;
        n.value = n.name;
        return n;
      });

      filterData[0].options = nodes;
      setFilters(filterData);
    });
  }, []);

  //Save selection
  const handleSelection = (option, label) => {
    const index = filters.findIndex((f) => f.label === label);

    if (index === 0) {
      getZones(option.code);
      const _selectedOptions = [option, {}, {}];
      setSelectedOptions(_selectedOptions);
      setAppliedEnabled(true);
    }

    if (index === 1) {
      //   getAreas(option.id);
      const _selectedOptions = [selectedOptions[0], option, {}];
      setSelectedOptions(_selectedOptions);
    }
  };

  const getZones = (node) => {
    LocationService.locationsAll({ no_parent: "true", node: node }).then(
      (locations) => {
        locations = locations.map((n) => {
          n.label = n.name;
          n.value = n.name;
          return n;
        });
        const newFilters = [...filters];

        newFilters[1].options = locations;
        newFilters[2].options = [];
        setFilters(newFilters);
      }
    );
  };

  const handleDelete = () => {
    searchRef.current.instanceRef.cleanInput();
    setSelectedOptions([]);
    setSearchedValue(null);
    setSelectedDates([new Date(), new Date()]);
    handleApply({
      node: undefined,
      location: undefined,
      date_from: undefined,
      date_to: undefined,
      item: undefined,
    });
    if (deleteSearchFilter) deleteSearchFilter();
  };

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };

  const _handleApply = () => {
    const appliedFilters = {
      node: selectedOptions[0],
      location: selectedOptions[1],
      date_from: selectedDates[0]
        ? formatDateToYyyyMmDd(selectedDates[0])
        : undefined,
      date_to: selectedDates[1]
        ? formatDateToYyyyMmDd(selectedDates[1])
        : undefined,
      item: searchedValue,
    };
    handleApply(appliedFilters);
  };

  return (
    <div className={`ficha-filter-panel-container-out`}>
      {(opened || !isMobile) && (
        <div className={`ficha-filter-panel-container`}>
          <div className="ficha-filter-panel-row">
            <Search
              placeholder="Buscar por código o nombre"
              resultList={products_name}
              value={searchedValue?.label}
              handleSubmit={() => {
                /*pass*/
              }}
              handleSubmitKey={(item) => {
                setSearchedValue(item);
              }}
              ref={searchRef}
            />
          </div>
          {searchedValue && (
            <div>
              <div className="ficha-filter-panel-row inputs">
                <div className={`ficha-filter-panel-inputs`}>
                  {filters?.map((filter, i) => {
                    if (filter.options && filter.options?.length > 0) {
                      return (
                        <SelectFilter
                          key={filter.label}
                          label={filter.label}
                          options={filter.options}
                          selectedOption={selectedOptions && selectedOptions[i]}
                          handleSelection={(e) =>
                            handleSelection(e, filter.label)
                          }
                          placeholder={filter.placeholder}
                        ></SelectFilter>
                      );
                    }
                  })}
                  <div className={"ficha-filter-panel-input"}>
                    <DateInput
                      label={"Rango de fecha"}
                      selectedDates={selectedDates}
                      handleChange={handleDateChange}
                    />
                  </div>
                </div>
              </div>


              <div className={`ficha-filter-panel-buttons`}>
                <button
                  className={`ficha-filter-panel-filter-apply ${appliedEnabled ? "" : "disabled"
                    }`}
                  disabled={!appliedEnabled}
                  onClick={_handleApply}
                >
                  APLICAR
                </button>
                <button
                  className="ficha-filter-panel-filter-delete"
                  onClick={handleDelete}
                >
                  BORRAR
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FichaDeStockFilterPanel;
