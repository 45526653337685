import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";

import { DeviceService } from "services/DeviceService";

import useRequest from "hooks/useRequest";
import { isMobile } from "helpers/Mobile";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";

import MainNotification from "components/MainApp/atoms/Notification";
import GenericTable from "components/MainApp/organisms/GenericTable";
import Pagination from "components/MainApp/atoms/Pagination";

import NodeImg from "./storefront-24px.svg";
import columns from "./columns";
import shelvingIcon from "assets/images/shelving.png";
import shelvingMIcon from "assets/images/shelvingM.png";
import guardIcon from "assets/images/guard.png";
import guardMIcon from "assets/images/guardM.png";
import contokIcon from "assets/images/contok.png";
import contokMIcon from "assets/images/contokM.png";
import iconTipshelf from "assets/images/icon-tipshelf.png";

import "./styles.scss";
import { NotFoundItems } from "../components/NotFoundItems";

let scrolling = false;

export const DevicesListView = () => {
  const history = useHistory();
  const [noDevices, setnoDevices] = useState(false);
  const [devices, setdevices] = useState([]);
  const [devicesTable, setdevicesTable] = useState([]);
  const [isSearched, setSearched] = useState(false);

  const isFirstRun = useRef(true);
  const [pager, setPager] = useState({
    next: null,
    previous: null,
    count: 0,
    countPages: 0,
  });
  const [page, setPage] = useState(1);
  let pageSize = 10;

  const { loading, beforeSubmit, afterSubmit } = useRequest();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  useEffect(() => {
    let firstRun = false;
    if (isFirstRun.current) {
      firstRun = true;
      isFirstRun.current = false;
    }

    filter({ page: page, page_size: pageSize, no_device: true }, firstRun);
  }, [page]);

  const filter = (params, first = false) => {
    beforeSubmit();
    DeviceService.devicesPaginated(params)
      .then((response) => {
        afterSubmit();

        const newDevices =
          isMobile && params.page !== 1
            ? [...devices, ...response.results]
            : response.results;

        if (first && newDevices.length < 1) setnoDevices(true);

        setdevices(newDevices);
        const table = newDevices.map((device) => device.getGenericTableView());

        setdevicesTable(table);
        setPager({
          next: response.next,
          previous: response.previous,
          count: response.count,
          countPages: Math.ceil(response.count / pageSize),
        });

        scrolling = false;
      })
      .catch((_) => {
        afterSubmit();
        scrolling = false;
      });
  };

  const handleScroll = (event) => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || loading || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const handlePagerChange = function(page) {
    setPage(page);
  };

  const handleSearch = (value) => {
    setSearched(false);
    filter({ page: 1, page_size: pageSize, no_device: true, search: value });
    setSearched(true);
  };

  const viewDevices = () => {
    history.push(`/admin/devices/activity`);
  };

  const createDevice = () => {
    history.push(`/admin/devices/add`);
  };

  const goToSelving = () => {
    history.push(`/admin/devices/shelvingactivity`);
  };

  const goToContok = () => {
    history.push(`/admin/devices/flowactivity`);
  };

  const goToTipshelf = () => {
    history.push(`/admin/products/load-product-info`);
  };

  const goToDevice = (code) => {
    history.push(`/admin/devices/detail/${code}`);
  };

  const headerOptions = [
    {
      icon: "",
      name: "shelving",
      handler: goToSelving,
      tooltip: "Estanterias",
      ic: isMobile ? shelvingMIcon : shelvingIcon,
    },
    {
      icon: "",
      name: "contok",
      handler: goToContok,
      tooltip: "Contok",
      ic: isMobile ? contokMIcon : contokIcon,
    },
    {
      icon: "",
      name: "tipshelf",
      handler: goToTipshelf,
      tooltip: "Tipshelf",
      ic: iconTipshelf,
    },
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: createDevice,
      tooltip: "Agregar dispositivos",
    },
    {
      icon: "icon-ojo",
      name: "view",
      handler: viewDevices,
      tooltip: "Ver Actividad",
    },
  ];

  const tableContent = (
    <div className={`device-list-content`}>
      {!isMobile && <SimpleSearchPanel placeholder="Buscar" handleSubmit={handleSearch} />}

      {devices.length > 0 && (
        <div className={`device-list-table`}>
          <GenericTable
            items={devicesTable}
            totalItems={pager.count}
            columns={columns}
            handleClick={goToDevice}
          />
          {!isMobile && (
            <div className="table-pagination">
              <Pagination
                pageQuantity={pager.countPages}
                currentPage={page}
                pageChangeHandler={handlePagerChange}
              />
            </div>
          )}
        </div>
      )}
      {devices.length === 0 && isSearched && (
        <NotFoundItems />
      )}
    </div>
  );

  const noDevicescontent = (
    <div className="device-no-devices-content">
      <img src={NodeImg} />
      <div className="device-no-devices-title">
        Aún no tienes dispositivos creados.
      </div>
      <div className="device-no-devices-title">
        ¡Crea tu primer dispositivo!
      </div>
      <div className="device-create-button" onClick={createDevice}>
        CREAR
      </div>
    </div>
  );
  const productsElement = noDevices ? noDevicescontent : tableContent;
  // const productsElement =  noDevicescontent;

  const content = (
    <div className="outter-wrapper">
      {isMobile && <SimpleSearchPanel placeholder="Buscar" handleSubmit={handleSearch} />}
      <div className="table-devices-wrapper">{productsElement}</div>
    </div>
  );

  return (
    <div className="device-list-container">
      <AdminLayout
        headerTitle={i18n.t("Dispositivos")}
        headerOptions={noDevices ? [] : headerOptions}
        content={content}
        navHidden={false}
      ></AdminLayout>
      {loading && <Loader />}
      <MainNotification
        label={"Tu dispositivo se actualizó de manera exitosa."}
        open={false}
      ></MainNotification>
    </div>
  );
};

export default DevicesListView;
