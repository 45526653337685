import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";

import { ProviderService } from "services/ProviderService";
import { PublicService } from "services/PublicService";

import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import Toggle from "components/MainApp/atoms/Forms/Toggle";

import DescriptionForm from "../components/DescriptionForm";
import ContactForm from "../components/ContactForm";

import { Provider } from "models/Persons";
import "./styles.scss";
import EditExitModal from "../components/ExitEditModal";

const initialProvider = {
  name: "",
  business_name: "",
  nif: "",
  country: "",
  region: "",
  state: "",
  locality: "",
  date: "",
  address: "",
  phone: "",
  email: "",
  entry_date: "",
};

const EditProviderView = (props) => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [provider, setProvider] = useState(initialProvider);

  const [countries, setcountries] = useState([]);
  const [regions, setregions] = useState([]);
  const [provinces, setprovinces] = useState([]);
  const [cities, setcities] = useState([]);

  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    if (props.match.params.key) {
      beforeSubmit();

      let countriesAux = [];
      PublicService.countries().then((response) => {
        countriesAux = response;
        setcountries(response);
      });

      const id = props.match.params.key;
      ProviderService.provider(id)
        .then((provider) => {
          afterSubmit();
          let regions;
          let states;
          let localities;

          let regionsPromises = PublicService.regions(provider.country);
          let statesPromises = PublicService.states(
            provider.country,
            provider.region
          );
          let citiesPromises = PublicService.cities(
            provider.country,
            provider.region,
            provider.state
          );

          Promise.all([regionsPromises, statesPromises, citiesPromises]).then(
            (responses) => {
              regions = responses[0];
              setregions(regions);

              states = responses[1];
              setprovinces(states);

              localities = responses[2];
              setcities(localities);

              provider.country = countriesAux.find(
                (c) => c.value === provider.country
              );
              provider.locality = localities.find(
                (c) => c.value === provider.locality
              );
              provider.region = regions.find(
                (c) => c.value === provider.region
              );
              provider.state = states.find((c) => c.value === provider.state);

              provider.entry_date = provider.entry_date.substring(0, 10);
              setProvider(provider);
              afterSubmit();
            }
          );
        })
        .catch((error) => {
          afterSubmit();
        });
    }
  }, []);

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    beforeSubmit();

    let providerAux = { ...provider };
    providerAux.country = providerAux.country
      ? providerAux.country.value
      : null;
    providerAux.region = providerAux.region ? providerAux.region.value : null;
    providerAux.state = providerAux.state ? providerAux.state.value : null;
    providerAux.locality = providerAux.locality
      ? providerAux.locality.value
      : null;
    providerAux.entry_date += " 12:00:00";

    ProviderService.updateProvider(props.match.params.key, providerAux)
      .then((response) => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("Tu proveedor se actualizó de manera exitosa."),
          type: "success",
        });
        history.push(`/admin/providers`);
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const handleGoBack = () => {
    if (edited) {
      setExitModalOpen(true);
    } else {
      history.push(`/admin/providers`);
    }
  };

  const handleCloseModal = () => {
    setExitModalOpen(false);
    history.push(`/admin/providers`);
  };

  const handleConfirmModal = (e) => {
    setExitModalOpen(false);
    if (edited) {
      handleCreate(e);
    } else {
      history.push(`/admin/providers`);
    }
  };

  const handleChange = (e) => {
    !edited && setEdited(true);
    const name = e.target.name;
    const value = e.target.value;
    let providerAux = { ...provider };
    providerAux[name] = value;

    setProvider(new Provider(providerAux));
  };

  const handleChangeEnabled = (e) => {
    provider.enabled = !provider.enabled;
    e.preventDefault();
    beforeSubmit();

    const data = { enabled: provider.enabled };

    ProviderService.updateProvider(props.match.params.key, data)
      .then((response) => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t(
            `Tu proveedor se ${
              provider.enabled ? "habilitó" : "deshabilitó"
            } de manera exitosa.`
          ),
          type: "success",
        });
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const handleSelect = (selectedOption, type) => {
    let providerAux = { ...provider };
    !edited && setEdited(true);

    switch (type) {
      case "country":
        providerAux.country = selectedOption;
        providerAux.region = "";
        providerAux.state = "";
        providerAux.locality = "";
        PublicService.regions(providerAux.country.value).then((response) => {
          setregions(response);
        });
        setProvider(new Provider(providerAux));

        break;
      case "region":
        providerAux.region = selectedOption;
        providerAux.state = "";
        providerAux.locality = "";
        PublicService.states(
          providerAux.country.value,
          providerAux.region.value
        ).then((response) => {
          setprovinces(response);
        });
        setProvider(new Provider(providerAux));

        break;
      case "state":
        providerAux.state = selectedOption;
        providerAux.locality = "";
        PublicService.cities(
          providerAux.country.value,
          providerAux.region.value,
          providerAux.state.value
        ).then((response) => {
          setcities(response);
        });
        setProvider(new Provider(providerAux));

        break;
      case "city":
        providerAux.locality = selectedOption;
        setProvider(new Provider(providerAux));

        break;

      default:
        break;
    }
  };

  const validateContinue = () => {
    if (provider) {
      if(provider.country?.value === 'ES'){
        return (
          provider.name && provider.name.trim() !== "" &&
          provider.business_name && provider.business_name.trim() !== "" &&
          provider.document && provider.document.trim() !== "" &&
          provider.country && provider.country.value !== "" &&
          provider.region && provider.region.value !== "" &&
          provider.state && provider.state.value !== "" &&
          provider.locality && provider.locality.value !== "" &&
          provider.phone && provider.phone.trim() !== "" &&
          provider.entry_date && provider.entry_date !== ""
        );
      }
      return (
        provider.name && provider.name.trim() !== "" &&
        provider.business_name && provider.business_name.trim() !== "" &&
        provider.document && provider.document.trim() !== "" &&
        provider.country && provider.country.value !== "" &&
        provider.phone && provider.phone.trim() !== "" &&
        provider.entry_date && provider.entry_date !== ""
      );
    } else {
      return false;
    }
  };

  const headerOptions = [];
  const content = (
    <div className={"edit-provider-content-wrapper"}>
      <div className={"edit-provider-inner"}>
        <div className={"edit-provider-toggle"}>
          <p>
            Proveedor{" "}
            <span>{provider.enabled ? "habilitado" : "deshabilitado"}</span>{" "}
            para realizar operaciones.
          </p>
          <Toggle
            checked={provider.enabled}
            handleChange={handleChangeEnabled}
          />
        </div>
        <form>
          <div className={"edit-provider-form"}>
            <h2 className={"edit-provider-title"}>{i18n.t("Descripcion")}</h2>
            <DescriptionForm
              provider={provider}
              handleChange={handleChange}
              handleSelect={handleSelect}
              error={errors}
              countries={countries}
              regions={regions}
              provinces={provinces}
              cities={cities}
              // disabledFields={disabledFields[type]}
            />
          </div>
          <div className={"edit-provider-form"}>
            <h2 className={"edit-provider-title"}>{i18n.t("Contacto")}</h2>

            <ContactForm
              provider={provider}
              handleChange={handleChange}
              error={errors}
              // disabledFields={disabledFields[type]}
            />
          </div>
          <div className={"edit-provider-button-wrapper"}>
            <button
              type="submit"
              className={`edit-provider-button ${!validateContinue() &&
                "disabled"}`}
              onClick={handleCreate}
            >
              {i18n.t("GUARDAR")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  return (
    <div className="edit-provider-container">
      <AdminLayout
        headerTitle={i18n.t("Editar proveedor")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
      <EditExitModal
        open={exitModalOpen}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
      />
    </div>
  );
};

export default EditProviderView;
