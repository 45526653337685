import Environment from "../environment";
import Api from "./ApiService";

import { Client } from "../models/Persons";

export const ClientService = {
  clients: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/persons/?type=CLIENT`,
        "GET",
        params
      )
        .then((rta) => {
          rta = rta.map((item) => new Client(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  clientsPaginated: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/persons/?type=CLIENT`,
        "GET",
        params
      )
        .then((rta) => {
          rta.results = rta.results.map((item) => new Client(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  client: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/persons/${id}/`, "GET")
        .then((rta) => {
          rta = new Client(rta);
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createClient: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/persons/`, "POST", data)
        .then((data) => {
          resolve(new Client(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateClient: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/persons/${id}/`, "PATCH", data)
        .then((data) => {
          resolve(new Client(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteClient: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/persons/${id}/`, "DELETE")
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
