const handleErrors = async error => {
  let msg = "No se puede eliminar la sucursal";
  if ([400, 401, 409].includes(error.status)) {
    try {
      const data = await error.json();
      if ('detail' in data) {
        msg = data['detail'];
      }
    } catch (error) {
      console.error("Error al procesar el JSON del error:", error);
    }
  }
  return msg;
};

export { handleErrors };
