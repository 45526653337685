import React from "react";
import "./styles.scss";

const PercentageBar = ({ percentage = 1, color = "#62d862", }) => {
  return (
      <div className={"percentage-bar-container"}
      >
        <div
          className={"percentage-bar-inner"}
          style={{ width: `${percentage}%`, backgroundColor: `${color}` }}
        >
          <div className={"percentage-bar-circle"} style={{ backgroundColor: `${color}` }}></div>
        </div>
      </div>
  );
};

export default PercentageBar;
