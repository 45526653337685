import Environment from "../environment";
import Api from "./ApiService";

export const StockService = {
  stock: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/items_stock/`, "GET", params)
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  localization: (epc) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/items_stock/localization/`,
        "GET",
        { epc: epc }
      )
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  localization_product: (itemId) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/items_stock/localization-product/`,
        "GET",
        { id: itemId }
      )
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  similars: (id, params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/items_stock/${id}/similars/`,
        "GET",
        params
      )
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadAuditoryReport: (location_id, items) => {
    return new Promise((resolve, reject) => {
      return Api.fetchDownload(
        `${Environment.api}api/items_stock/auditory/`,
        "POST",
        { location: location_id, items: items }
      )
        .then((blob) => {
          resolve(blob);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  rfids_in_mov_orders: (rfids) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/items_stock/rfids_in_mov_orders/`,
        "POST",
        { rfids }
      )
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

};
