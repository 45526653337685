import React, { useEffect, useState } from "react";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainTextArea from "components/MainApp/atoms/Forms/MainTextArea";
import imageFile from "assets/images/image-placeholder.png";

import "./styles.scss";

const EditCodeTagForm = ({
  configTag,
  handleClickVisualize,
  onConfigTagChange,
  imageSrc,
  error
}) => {
  const [editCondigTag, setEditConfigTag] = useState();
  const [code, setCode] = useState();
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    if (configTag) {
      const type = getTypeOrId(configTag.type);
      setEditConfigTag(configTag);
      setCode(configTag.zplFormat);
    }
  }, [configTag]);

  const getTypeOrId = type => {
    if (type?.id) {
      return type.id;
    }
    return type;
  };

  const handleVisualizeClick = () => {
    handleClickVisualize(code);
  };

  const handleInputChange = (e) => {
    configTag.zplFormat = e.target.value;
    onConfigTagChange(configTag);
    setCode(e.target.value);
};

  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div className="edit-tag-form-content">
      <div className="edit-tag-form-panel-left">
        <div className="edit-tag-form-field" data-automation-id="input-name">
          <MainInput
            label={"Nombre"}
            id={"edit-tag-name"}
            className={"edit-tag-name"}
            name="name"
            value={configTag ? configTag.name : ""}
            editable={false}
            disabled={true}
          />
        </div>
        <div className="edit-tag-form-field" data-automation-id="input-code">
          <MainTextArea
            label={"Código"}
            id={"edit-tag-code"}
            name={"code"}
            handleChange={handleInputChange}
            maxLength={5000}
            value={code || ""}
          ></MainTextArea>
        </div>
      </div>
      <div className="edit-tag-form-panel-right">
        <h4 className="subtitle">Imagen</h4>
        <div className="edit-tag-form-image">
          <img
            src={imageSrc || imageFile}
            alt="tag"
            className={isZoomed ? 'zoomed' : ''}
            onClick={handleImageClick}
          />
        </div>
        <div>
          <label className="edit-code-tag-label-error">{error}</label>
        </div>
        <div className={"edit-code-tag-button-wrapper"}>
          <button
            type="button"
            className={
              !code || code?.trim() === ""
                ? `edit-code-tag-button disabled`
                : `edit-code-tag-button`
            }
            onClick={handleVisualizeClick}
            disabled={!code || code?.trim() === ""}
          >
            {"VISUALIZAR"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCodeTagForm;
