import React from 'react'
import Icon from 'components/MainApp/atoms/Icon/icon'
import './styles.scss'

const TagAssociationSummary = ({ sku, tagsAssociatedBySKU, handleDelete }) => {
  const tags = tagsAssociatedBySKU
    .find((tag) => tag.sku === sku.sku)
    .tags.map((tag) => {
      return (
        <div className="location-summary-container-child">
          <div className="location-summary-left">
            <div className="path">{tag.epc}</div>
          </div>
          <div className="location-summary-right">
            {handleDelete && (
              <div onClick={() => handleDelete(tag)}>
                <Icon name="trash_can" width={20} height={20} fill={'#3C7AF5'} />
              </div>
            )}
          </div>
        </div>
      )
    })

  return (
    <div className="location-summary-container">
        <div className="location-summary-left">
          <div className="items">Producto: sku {sku.sku}</div>
        </div>
        <div className="location-summary-right">
        {tags}
        </div>
    </div>
  )
}

export default TagAssociationSummary
