import React, { useState, useEffect, useRef } from "react";
import useFilters from "../../../../hooks/useFilters";
import { useHistory, useLocation } from "react-router-dom";
import { useStore } from "store/context";
import i18n from "i18n";
import { isMobile } from "helpers/Mobile";

import { ContainerService } from "services/ContainerService";

import "./styles.scss";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import GenericTable from "components/MainApp/organisms/GenericTable";
import Icon from 'components/MainApp/atoms/Icon/icon';

import Search from "components/MainApp/atoms/Filters/Search";
import FilterPanel from "components/MainApp/organisms/Filters/FilterPanel";
import headers from "./headers";
import Pagination from "components/MainApp/atoms/Pagination";
import Loader from "components/MainApp/atoms/Loader";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";
import { NotFoundItems } from "views/Admin/components/NotFoundItems";


const initialFilters = {
  page_size: 10,
  search: "",
  ordering: "-created_at",
  enabled: undefined,
};
const filterTypeMap = {
   Tipos: { name: "type", type: "dropdown" },
  "Ordenar por": {
    name: "ordering",
    type: "twoOption",
    default_value: "-created_at",
  },
};
const filterDataSource = [
    {
        type: "dropdown",
        options: [
            {
              id: 'BOX',
              label: "Caja",
              selected: false,
              value: 'BOX',
            },
            {
              id: 'PALLET',
              selected: false,
              label: "Palets",
              value: 'PALLET',
            },
          ],
        label: "Tipos",
        firstOptionLabel: "Todos los tipos",
        name: "type",
      },
];

export const EmbalajesTableView = () => {
  const [{ embalajesFilters }, dispatch] = useStore();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [noEmbalajes, setNoEmbalajes] = useState(false);
  const [filterData, setFilterData] = useState(filterDataSource);

  const [submitted, setSubmitted] = useState(false);
  const myRef = useRef(0);

  let scrolling = false;

  const [
    filters,
    setFilters,
    selectedFiltersAll,
    selectedFiltersByType,
    handleApplyFilters,
    handleSearch,
    headerOrderingHandler,
    resetOrdering,
    handleFiltersChangeOrdering,
    applied,
    page,
    setPage,
    items,
    setItems,
    pager,
    setPager,
    header,
    setHeader,
    mobilePanelOpen,
    setMobilePanelOpen,
  ] = useFilters(
    initialFilters,
    embalajesFilters,
    filterTypeMap,
    isMobile,
    headers
  );

  useEffect(() => {
    fillFilterData();
  }, []);

  useEffect(() => {
    setMobilePanelOpen(false);
    const first_load =
      JSON.stringify(initialFilters) === JSON.stringify(filters);
    filter(first_load);
    // Acomodar orden
    const keyOrdering =
      filters.ordering.charAt(0) === "-"
        ? filters.ordering.substr(1)
        : filters.ordering;
    if (keyOrdering in headers) {
      const newHeaders = { ...header };
      newHeaders[keyOrdering]["active"] = true;
      newHeaders[keyOrdering]["direction"] =
        filters.ordering === keyOrdering ? true : false;
      setHeader(newHeaders);
    }
  }, []);

  useEffect(() => {
    filter(false);
    dispatch({
      type: "setEmbalajesFilters",
      payload: {
        filters: filters,
        selectedFiltersAll: selectedFiltersAll,
        selectedFiltersByType: selectedFiltersByType,
      },
    });
  }, [filters, page]);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  const handleScroll = (event) => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || submitted || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const handlePagerChange = function (page) {
    setPage(page);
  };

  const filter = function (firstLoad = false) {
    let append = isMobile;
    if (!append) {
      setItems([]);
    }

    //BEFORE SUBMIT
    document.body.style.cursor = "wait";
    setSubmitted(true);

    const data = { ...filters, page: page };
    setLoading(true);
    ContainerService.containers(data)
      .then((response) => {
        setSubmitted(false);
        document.body.style.cursor = "default";
        scrolling = false;

        if (firstLoad && response.count === 0) {
          setNoEmbalajes(true);
          return;
        }

        const newItems = append
          ? [...items, ...response.results]
          : response.results;

        setLoading(false);
        setItems(newItems);
        setPager({
          next: response.next,
          previous: response.previous,
          count: response.count,
          countPages: Math.ceil(response.count / filters.page_size),
        });
      })
      .catch((e) => {
        setSubmitted(false);
        document.body.style.cursor = "default";
        scrolling = false;
      });
  };

  const fillFilterData = function () {
    let filterDataAux = [...filterData];
    filterDataAux[0].options = filterDataAux[0].options.map((option) => {
      option.selected = false;
      return option;
    });

    if (isMobile) {
      filterDataAux[1] = {
        type: "twoOption",
        options: [
          {
            selected: false,
            label: "Menos reciente",
            value: "created_at",
          },
        ],
        label: "Ordenar por",
        firstOptionLabel: "Más reciente",
        value: "-created_at",
      };
    }
    setFilterData(filterDataAux);
  };

  const handleSelect = (id) => {
    history.push(
        `/admin/embalajes/detail/${id}`
      );

  };

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: () => {
        history.push("/admin/embalajes/add");
      },
      tooltip: "Agregar embalaje",
    },

  ];


//   const headerButtons = (
//     <div
//       className="header-button"
//       onClick={() => history.push('/admin/users')}
//       style={{
//         width: "30px",
//         cursor: "pointer",
//         display: "flex",
//         alignItems: "center",
//       }}
//     >
//       <Icon
//           name={'account'}
//           fill={isMobile ? "#0000003d" : "#0000003d"}
//           width={isMobile ? 26 : 40}
//           height={28}
//         ></Icon>
//     </div>
//   );


  const tableContentDesktop = (
    <React.Fragment>
      <FilterPanel
        resultList={[]}
        placeholder="Busca por nombre o código"
        searchValue={filters.search}
        handleSubmitSearch={handleSearch}
        filters={filterData}
        selectedFiltersAll={selectedFiltersAll}
        selectedFiltersByType={selectedFiltersByType}
        handleApplyFilters={handleApplyFilters}
      ></FilterPanel>

      {items.length > 0 && (
        <div className="embalajes-table-table">
          <div className="embalajes-table-top-table">
            <div className="embalajes-table-top-total">
              TOTAL{" "}
              <span className="embalajes-table-top-total-bold">
                ({pager.count})
              </span>
            </div>
            <div className="embalajes-table-top-sort">
            <div
                data-automation-id="embalajes-table-sort"
                className={
                  filters.ordering.includes("created_at")
                    ? "embalajes-table-top-sort embalajes-table-active-filter"
                    : "embalajes-table-top-sort"
                }
                onClick={() => {
                  handleFiltersChangeOrdering(
                    "ordering",
                    filters.ordering === "-created_at"
                      ? "created_at"
                      : "-created_at"
                  );
                  resetOrdering();
                }}
              >
                {filters.ordering === "created_at" ? (
                  <React.Fragment>
                    Menos recientes{" "}
                    <i className="icon-chevron embalajes-table-top-icon"></i>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {" "}
                    Más recientes{" "}
                    <i className="icon-chevron-down embalajes-table-chevron-down embalajes-table-top-icon"></i>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <GenericTable
            items={items.map((i) => i.getTableView())}
            columns={header}
            handleClick={handleSelect}
          />
        </div>
      )}

      {items.length === 0 && !submitted && (
        <NotFoundItems />
      )}
      {items.length > 0 && (
        <div>
          <Pagination
            pageQuantity={pager.countPages}
            currentPage={page}
            pageChangeHandler={handlePagerChange}
          />
        </div>
      )}
    </React.Fragment>
  );

  const tableContentMobile = (
    <React.Fragment>
      <div className="embalajes-table-filter-container">
        <SimpleSearchPanel
          placeholder="Busca por nombre"
          handleSubmit={handleSearch}

        />
      </div>
      <div
        className={`embalajes-table-mobile-panel ${mobilePanelOpen ? "opened" : ""
          } `}
      >
        <FilterPanel
          resultList={[]}
          placeholder="Busca por nombre o código"
          searchValue={filters.search}
          handleSubmitSearch={handleSearch}
          filters={filterData}
          selectedFiltersAll={selectedFiltersAll}
          selectedFiltersByType={selectedFiltersByType}
          handleApplyFilters={handleApplyFilters}
          closeMobilePanel={() => {
            setMobilePanelOpen(false);
          }}
        ></FilterPanel>
      </div>
      <div className="embalajes-table-table">
        <div className="embalajes-table-top-table">
          <div className="embalajes-table-top-total">
            Total{" "}
            <span className="embalajes-table-top-total-bold">
              ({pager.count})
            </span>
          </div>
          <div
              className={`embalajes-table-top-sort ${
                applied ? "embalajes-table-active-filter" : ""
              }`}
              onClick={() => {
                setMobilePanelOpen(true);
              }}
            >
              <i className="icon-iconos_filtros embalajes-table-top-icon"></i>
            </div>
        </div>
        <GenericTable
          items={items}
          showMessageNoneResult={false}
          columns={headers}
          handleClick={handleSelect}
        />
      </div>

      {items.length === 0 && !submitted && (
        <NotFoundItems />
      )}
    </React.Fragment>
  );

  const tableContent = isMobile ? tableContentMobile : tableContentDesktop;

  const noproductcontent = (
    <div className="table-embalajes-no-content">
      <p className="table-embalajes-title">
        Aún no tienes embalajes creados.
    </p>
      <p className="table-embalajes-title">
        ¡Crea tu primer embalaje!
    </p>

      <div className="table-embalajes-button" onClick={() => history.push("/admin/embalajes/add")}>CREAR</div>
    </div>
  );
  const embalajesContent = noEmbalajes ? noproductcontent : tableContent;

  const content = (
    <div className="embalajes-table-wrapper" ref={myRef}>
      <div className="embalajes-table-inner">
        {embalajesContent}
        {loading && <Loader />}
      </div>
    </div>
  );

  const handleGoBack = () => {
    history.push(`/admin/products`);
  };

  return (
    <div className="embalajes-container">
      <AdminLayout
        headerTitle={i18n.t("Embalajes")}
        goBackFunc={handleGoBack}
        headerOptions={headerOptions}
        // customButtons={headerButtons}
        content={content}
        navHidden={false}
      ></AdminLayout>
    </div>
  );
};

export default EmbalajesTableView;
