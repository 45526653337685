import Environment from "../environment"; 
import Api from './ApiService';

import { Application } from "../models/Application";


export const ApplicationService = {
	applications: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/external-apps/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Application(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
	},

    application: (id) => {
        return new Promise((resolve, reject) => {
          return Api.fetch(`${Environment.api}api/external-apps/${id}/`, "GET")
            .then((rta) => {
              rta = new Application(rta);
              resolve(rta);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },

    createApplication: (formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/external-apps/`, 'POST', formData
                ).then(data => {
                    resolve(new Application(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateApplication: (id, formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/external-apps/${id}/`, 'PATCH', formData
                ).then(data => {
                    resolve(new Application(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    deleteApplication: (id) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/external-apps/${id}/`, 'DELETE'
                ).then(data => {
                    resolve();
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

}
