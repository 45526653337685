import React from "react";

import MainDropdown from "components/MainApp/atoms/Forms/MainDropdown";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainTextArea from "components/MainApp/atoms/Forms/MainTextArea";

const renderTextInput = (data, handleChange, className) => {
  return (
    <div
      className={className}
      data-automation-id={`input-${data.name}`}
      key={data.name}
    >
      <MainInput
        label={data.label}
        required={data.required}
        id={data.id}
        name={data.name}
        value={data.value}
        handleChange={handleChange}
        maxLength={100}
        error={data.error && data.error}
      />
    </div>
  );
};

const renderTextArea = (data, handleChange, className) => {
  return (
    <div
      className={className}
      data-automation-id={`input-${data.name}`}
      key={data.name}
    >
      <MainTextArea
        label={data.label}
        required={data.required}
        id={data.id}
        name={data.name}
        value={data.value}
        handleChange={handleChange}
        maxLength={100}
        error={data.error && data.error}
      />
    </div>
  );
};

const renderDropdownInput = (data, handleSelect, className) => {
  return (
    <div
      className={className}
      data-automation-id={`input-${data.name}`}
      key={data.name}
    >
      <MainDropdown
        label={data.label}
        placeholder={data.placeholder}
        required={data.required}
        id={data.name}
        name={data.name}
        value={data.selectedOption}
        selectedOption={data.selectedOption}
        options={data.options}
        handleSelection={(selectedOption) =>
          handleSelect(selectedOption, data.name)
        }
        error={data.error && data.error}
      />
    </div>
  );
};

const renderInput = (field, handleSelect, handleChange, className) => {
  let component;
  switch (field.type) {
    case "dropdown":
      component = renderDropdownInput(field.data, handleSelect, className);
      break;

    case "text":
      component = renderTextInput(field.data, handleChange, className);
      break;

    case "textarea":
      component = renderTextArea(field.data, handleChange, className);
      break;

    default:
      break;
  }

  return component;
};

export default renderInput;
