import React, { useState, useEffect } from "react";
import { LocationService } from "services/LocationService";
import { BusinessNodeService } from "services/BusinessNodeService";
import { Select, MenuItem, InputLabel, Checkbox, ListItemText, OutlinedInput, FormControl } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';


import SelectFilter from "components/MainApp/atoms/Filters/SelectFilter";
import "./styles.scss";

import filterData from "../data/filters";
import { isMobile } from "helpers/Mobile";
import { min } from "date-fns";

let appliedEnabled = false;

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '250px',
    position: 'relative',
  },
  inputLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: 'transparent',
    border: 'none',
  },
  select: {
    '& .MuiSelect-icon': {
      transform: 'translateY(90%)',
    },
  },
  outlinedInput: {
    paddingRight: '32px',
  },
  menuItemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: isMobile ? 'calc(60% - 100px)' : '370px',
      minWidth: isMobile ? '288px' : '260px',
      maxWidth: isMobile ? '50vw' : '200px',
      transform: isMobile ? 'translateX(-2px)' :  'translateX(18px)',
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const ProductMenuProps = {
  ...MenuProps,
  PaperProps: {
    ...MenuProps.PaperProps,
    style: {
      ...MenuProps.PaperProps.style,
      maxHeight: isMobile ? 'calc(60% - 100px)' : '370px',
    },
  },
};
const AuditoriaFilterPanel = ({
  handleApplyProp,
  opened = false,
  stock=0,
  handleOpen,
  handleDeleteProp,
  handleSelectionChange,
  showFilters,
  filterCategories,
  filterProducts,
  handleSelectionChangeCategory,
  handleSelectionChangeProduct,
  selectedCategories,
  selectedProducts,
}) => {
  const [filters, setFilters] = useState();
  const [msgNotZone, setMsgNotZone] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const classes = useStyles();

  //Get Sucursales at the start
  useEffect(() => {
    BusinessNodeService.businessNodes({no_page: 1}).then(nodes => {
      nodes = nodes.map(n => {
        n.id = n.code;
        n.label = n.name;
        n.value = n.name;
        return n
      })

      filterData[0].options = nodes;
      filterData[1].options = [];
      filterData[2].options = [];
      setFilters(filterData);
    })
  }, []);


  const handleApply = (selectedOption) => {
    handleApplyProp(selectedOption);
  }

  //Save selection
  const handleSelection = (option, label) => {
    const index = filters.findIndex((f) => f.label === label);
    if(index === 0 ){
      getZones(option.code)
      const _selectedOptions = [option, {}, {}]
      setSelectedOptions(_selectedOptions)
      appliedEnabled = true;
    }

    if(index === 1 ) {
      appliedEnabled = true;
      getAreas(option.id)
      const _selectedOptions = [selectedOptions[0], option, {}]
      setSelectedOptions(_selectedOptions)
    }

    if(index === 2){
      appliedEnabled = true;
      const _selectedOptions = [...selectedOptions]
      _selectedOptions[2] = option
      setSelectedOptions(_selectedOptions)
    }
    handleSelectionChange(option);
  };

  const getZones = (node) => {
    LocationService.locationsAll({no_parent: "true", node: node}).then(locations => {
      setMsgNotZone(locations.length === 0 ? "No hay zonas para auditar en esta sucursal" : "");
      locations = locations.map(n => {
        n.label = n.name;
        n.value = n.name;
        return n
      })
      const newFilters = [...filters];

      newFilters[1].options = locations;
      newFilters[2].options = []
      setFilters(newFilters);
    })
  };
  const getAreas = (zone) => {
    LocationService.locationsAll({ parent: zone}).then(locations => {
      locations = locations.map(n => {
        n.label = n.name;
        n.value = n.name;
        return n
      })
      const newFilters = [...filters];

      newFilters[2].options = locations;
      setFilters(newFilters);
    })
  };


  const handleDelete = () => {
    setSelectedOptions([]);
    filterData[1].options = [];
    filterData[2].options = [];
    setFilters(filterData);
    appliedEnabled = false;
    handleDeleteProp();
  };

  return (
    <div className={`auditoria-filter-panel-container-out`}>
      {!opened && isMobile && (
        <div className={`auditoria-filter-panel-summary`}>
          <div className={`auditoria-filter-panel-summary-row`}>
            <div className={`auditoria-filter-panel-summary-filters`}>
              {filters?.map((filter, i) => {
                return (
                  selectedOptions[i]?.label && (
                    <div key={i}>
                      <div>
                        {filter.label} {selectedOptions[i].label}
                      </div>
                      <div className={"separator"}></div>
                    </div>
                  )
                );
              })}
            </div>
            <div
              className={`auditoria-filter-panel-summary-button`}
              onClick={handleOpen}
            >
              <i className={`icon-iconos_filtros`} />
            </div>
          </div>
          <div className={`auditoria-filter-panel-summary-row`}>
            <p className={"auditoria-filter-panel-summary-stock"}>
              Stock({stock})
            </p>
          </div>
        </div>
      )}
      {(opened || !isMobile) && (
        <div className={`auditoria-filter-panel-container`}>
          <div className={`auditoria-filter-panel-inputs`}>
            {filters?.map((filter, i) => {
              if (filter.options && filter.options?.length > 0) {
                return (
                  <SelectFilter
                    key={filter.label}
                    label={filter.label}
                    options={filter.options}
                    selectedOption={selectedOptions && selectedOptions[i]}
                    handleSelection={e => handleSelection(e, filter.label)}
                    placeholder={filter.placeholder}
                  ></SelectFilter>
                );
              }
            })}
            {msgNotZone && <div className="not-zones">{msgNotZone}</div>}
            {isMobile && (
              <div className={`auditoria-filter-panel-filtered-products`}>
                <div className={`auditoria-filter-categories-products`}>
                  {filterCategories && (
                    <div className="select-categories-filter-wrapper" >
                      <FormControl
                        variant="outlined"
                        className="formControl combo_categories"
                      >
                        <InputLabel id="categories-select-filled-label">
                          {filterCategories?.label}
                        </InputLabel>
                        <Select
                          labelId="categories-select-filled-label"
                          id="categories-select-filled"
                          className={classes.select}
                          MenuProps={MenuProps}
                          value={selectedCategories}
                          multiple={true}
                          onChange={handleSelectionChangeCategory}
                          input={
                            <OutlinedInput label={filterCategories?.label} />
                          }
                          renderValue={selected =>
                            selected?.map(item => item.label).join(", ")
                          }
                        >
                          {filterCategories?.options.map(item => (
                            <MenuItem key={item.id} value={item}>
                              <Checkbox
                                checked={selectedCategories
                                  .map(s => s.id)
                                  .includes(item.id)}
                              />
                              <ListItemText
                                primary={item.label}
                                classes={{ primary: classes.menuItemText }}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      </div>
                  )}
                  {filterProducts && (
                    <div className="select-products-filter-wrapper">
                      <FormControl variant="outlined" className="formControl combo_products">
                        <InputLabel id="products-select-filled-label">
                          Productos
                        </InputLabel>
                        <Select
                          labelId="products-select-filled-label"
                          id="products-select-filled"
                          className={classes.select}
                          MenuProps={ProductMenuProps}
                          value={selectedProducts}
                          multiple={true}
                          onChange={handleSelectionChangeProduct}
                          input={<OutlinedInput label={filterProducts.label} />}
                          renderValue={selected =>
                            selected?.map(item => item.label).join(", ")
                          }
                        >
                          {filterProducts?.map(item => (
                            <MenuItem key={item.id} value={item}>
                              <Checkbox
                                checked={selectedProducts
                                  .map(s => s.id)
                                  .includes(item.id)}
                              />
                              <ListItemText
                                primary={item.label}
                                classes={{ primary: classes.menuItemText }}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={`auditoria-filter-panel-buttons`}>
            <button
              className={`auditoria-filter-panel-filter-apply ${
                appliedEnabled ? "" : "disabled"
              }`}
              disabled={!appliedEnabled}
              onClick={() => handleApply(selectedOptions)}
            >
              APLICAR
            </button>
            <button
              className="auditoria-filter-panel-filter-delete"
              onClick={handleDelete}
            >
              BORRAR
            </button>
          </div>

          <div className={`auditoria-filter-categories-products`}>
            {!isMobile && showFilters && filterCategories && (
              <FormControl
                variant="outlined"
                className="formControl combo_categories"
              >
                <InputLabel id="categories-select-filled-label">
                  {filterCategories?.label}
                </InputLabel>
                <Select
                  labelId="categories-select-filled-label"
                  id="categories-select-filled"
                  className={classes.select}
                  MenuProps={MenuProps}
                  value={selectedCategories}
                  multiple={true}
                  onChange={handleSelectionChangeCategory}
                  input={<OutlinedInput label={filterCategories?.label} />}
                  renderValue={selected =>
                    selected?.map(item => item.label).join(", ")
                  }
                >
                  {filterCategories?.options.map(item => (
                    <MenuItem key={item.id} value={item}>
                      <Checkbox
                        checked={selectedCategories
                          .map(s => s.id)
                          .includes(item.id)}
                      />
                      <ListItemText
                        primary={item.label}
                        classes={{ primary: classes.menuItemText }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!isMobile && showFilters && filterProducts && (
              <FormControl variant="outlined" className="formControl">
                <InputLabel id="products-select-filled-label">
                  Productos
                </InputLabel>
                <Select
                  labelId="products-select-filled-label"
                  id="products-select-filled"
                  className={classes.select}
                  MenuProps={MenuProps}
                  value={selectedProducts}
                  multiple={true}
                  onChange={handleSelectionChangeProduct}
                  input={<OutlinedInput label={filterProducts.label} />}
                  renderValue={selected =>
                    selected?.map(item => item.label).join(", ")
                  }
                >
                  {filterProducts?.map(item => (
                    <MenuItem key={item.id} value={item}>
                      <Checkbox
                        checked={selectedProducts
                          .map(s => s.id)
                          .includes(item.id)}
                      />
                      <ListItemText
                        primary={item.label}
                        classes={{ primary: classes.menuItemText }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuditoriaFilterPanel;
