import React from "react";
import MainAppGenericModal from "../GenericModal";

import "./styles.scss";

export class TwoOptionModal extends React.Component {
  render() {
    const {
      open,
      title,
      subtitle,
      closeLabel,
      confirmLabel,
      handleClose,
      handleConfirm,
      modalContent,
      confirmDisabled,
      closeable = false,
      handleCloseIcon = () => {}
    } = this.props;


    const content = (

      <div className="two-option-modal-wrapper">
        {closeable &&
        <div
            className="icon-close-wrapper"
            onClick={handleCloseIcon}
            data-automation-id="search-cancel"
          >
            <i className="icon-close icon-iconos_close2"></i>
          </div>
          }
        <div className="two-option-modal-title">{title}</div>
        <div className="two-option-modal-text">{subtitle}</div>

        {modalContent &&
          modalContent
        }
        <div className="two-option-modal-buttons">
          <div
            className="two-option-modal-button"
            onClick={handleClose}
            data-automation-id="cancel"
          >
            {closeLabel}
          </div>
          <div
            className={`two-option-modal-button two-option-modal-button-confirm ${confirmDisabled ? 'disabled' : ''}`}
            onClick={confirmDisabled ? () => {return true} : handleConfirm}
            data-automation-id="confirm"
          >
            {confirmLabel}
          </div>
        </div>
      </div>
    );
    return (
      <div className="two-option-modal-container">
        {open && (
          <MainAppGenericModal
            open={open}
            content={content}
          ></MainAppGenericModal>
        )}
      </div>
    );
  }
}

export default TwoOptionModal;
