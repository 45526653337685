import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import { ContainerService } from "services/ContainerService";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";
import Panel from "components/MainApp/organisms/TogglePanel";

import "./styles.scss";

const titleMap = {
    type: 'TIPO DE EMBALAJE',
    name: 'NOMBRE',
    sku: 'CODIGO',
    measure_height: 'ALTO',
    measure_width: 'ANCHO',
    measure_depth: 'PROFUNDIDAD',
    volume: 'VOLUMEN',
    max_weight: 'PESO MAXIMO',
    material: 'MATERIAL'
}

const typeMapSingular = {
    PRODUCT: 'unidad',
    PALLET: 'caja'
}
const typeMapPrural = {
    PRODUCT: 'unidades',
    PALLET: 'cajas'
}
const EmbalajeDetailView = (props) => {
  const history = useHistory();
  const [deleteErrorModal, setdeleteErrorModal] = useState(false);
  const [deleteErrorMsg, setdeleteErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [embalaje, setEmbalaje] = useState(null);

  useEffect(() => {
    if (!props.match.params.key) return;
    const id = props.match.params.key;
    setLoading(true);
    ContainerService.container(id).then((embalaje) => {
      setEmbalaje(embalaje);
      setLoading(false);
    });
  }, []);

  const deleteContainer = () => {
    setLoading(true);
    ContainerService.deleteContainer(embalaje.id)
      .then((rta) => {
        history.push(`/admin/embalajes`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setdeleteErrorModal(true);
        setdeleteErrorMsg("No se puede eliminar el embalaje");
      });
  };

  const headerOptions = [
    // {
    //   icon: "icon-iconos_eliminar",
    //   name: "delete",
    //   handler: deleteContainer,
    //   tooltip: "Eliminar Plantilla",
    // },
    {
      icon: 'icon-iconos_editar',
      name: 'editar',
      handler: () => {history.push(
        `/admin/embalajes/edit/${embalaje.id}`
      )},
      tooltip: 'Editar Embalaje'
    },
  ];

  const handleGoBack = () => {
    history.push(`/admin/embalajes`);
  };

  //ToDo: i18n
  const getEmbalajeItemFriendlyName =(value) => {
    if(value === 'PALLET')
      return 'Palet';
    if(value === 'BOX')
      return 'Caja';
    return value;
  }

  const getDescriptionPanel = () => {
    let fields = [];
    ['type', 'name', 'sku'].map((i, key) => {
        let item = {
          title: titleMap[i],
          value: embalaje[i] ? getEmbalajeItemFriendlyName(embalaje[i]) : '-'
        }
        fields.push(item);
      }
    )
    return fields;
  }

  const getTypePanel = () => {
    let fields = [];
    ['measure_height', 'measure_width', 'measure_depth', 'volume', 'max_weight', 'material'].map((i, key) => {
      const unit = i.match("(measure_height|measure_width|measure_depth)") ? " (cm)" : i === 'max_weight' ? ' (kg)' : i === 'volume' ? ' (cm3)' : '';
        let item = {
          title: titleMap[i] + unit,
          value: embalaje[i] ? getEmbalajeItemFriendlyName(embalaje[i]) : '-'
        }
        fields.push(item);
      }
    )
    return fields;
  }

  const contentMobile = (
    <div className={"embalaje-detail-content-wrapper"}>
      {embalaje && (
        <>
        <div className="toggle-panel-title">
            
            <Panel
              fields={getDescriptionPanel()}
              title={"Descripción"}
            />
        </div>

        <div className="toggle-panel-title">
          <Panel
          fields={getTypePanel()}
          title={"Dimensiones"}
          />
      </div>
      </>
      )}
      {embalaje && (
        <div className="panel">
            <h1 className="panel-title">Productos</h1>
            {embalaje.relations.map((r, key) => {
                return <div key={key} className="relation">
                        <div className="relation-name">{r.name} <span>({r.quantity} { r.quantity > 1 ? typeMapPrural[r.type] : typeMapSingular[r.type]})</span></div>
                        <div className="relation-brand">{r.brand?.name}</div>
                        <div className="relation-sku">{r.sku}</div>
                    </div>
            })}
        </div>
      )}
    </div>
  );

  const contentDesktop = (
    <div className={"embalaje-detail-content-wrapper"}>
      {embalaje && (
        <div className="panel">
            <h1 className="panel-title">Descripción</h1>

            {['type', 'name', 'sku'].map((item, key) => {
                return <div key={key} className="panel-group">
                    <div className="panel-key">{titleMap[item]}</div>
                    <div className="panel-value">{embalaje[item] ? getEmbalajeItemFriendlyName(embalaje[item]) : '-'}</div>
                </div>
            })}
        </div>
      )}

      {embalaje && (
        <div className="panel">
            <h1 className="panel-title">Dimensiones</h1>

            {['measure_height', 'measure_width', 'measure_depth', 'volume', 'max_weight', 'material'].map((item, key) => {
              const unit = item.match("(measure_height|measure_width|measure_depth)") ? " (cm)" : item === 'max_weight' ? ' (kg)' : item === 'volume' ? ' (cm3)' : '';
                return <div key={key} className="panel-group">
                    <div className="panel-key">{titleMap[item]} {unit}</div>
                    <div className="panel-value">{embalaje[item] ? embalaje[item] : '-'}</div>
                </div>
            })}
        </div>
      )}

      {embalaje && (
        <div className="panel">
            <h1 className="panel-title">Productos</h1>
            {embalaje.relations.map((r, key) => {
                return <div key={key} className="relation">
                        <div className="relation-name">{r.name} <span>({r.quantity} { r.quantity > 1 ? typeMapPrural[r.type] : typeMapSingular[r.type]})</span></div>
                        <div className="relation-brand">{r.brand?.name}</div>
                        <div className="relation-sku">{r.sku}</div>
                    </div>
            })}
        </div>
      )}
    </div>
  );

  const content = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(
    navigator.userAgent
  )
    ? contentMobile
    : contentDesktop;
  return (
    <div className="embalaje-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Detalle del Embalaje ")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      <GenericErrorModal
        open={deleteErrorModal}
        handleClose={() => setdeleteErrorModal(false)}
        error={deleteErrorMsg}
      ></GenericErrorModal>

      {loading && <Loader />}
    </div>
  );
};

export default EmbalajeDetailView;
