class Movement {
  id = null;
  item_stock = null;
  container = null;
  tag = null;
  location = null;
  movement = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }
}

export { Movement };
