import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import GenericFormContainer from '../../../helpers/GenericFormContainer';
import { Validations } from '../../../helpers/validations';

import { UserService } from '../../../services/UserService';

import AuthGeneric from '../../../components/Auth/layouts/AuthGeneric'
import RoundedButton from '../../../components/Auth/atoms/buttons/RoundedButton'
import PasswordInput from '../../../components/Auth/atoms/forms/InputPasswordAuth'

import Logo from '../../../components/Auth/atoms/images/Logo'

export class ResetPasswordView extends GenericFormContainer {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            userData: {
                password: null
            },
            buttonState: 'disabled',
            passwordValidation: [false, false, false],
            passwordError: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.beforeSubmit();
        UserService.resetConfirm(this.state.userData.password, this.props.match.params.key
        ).then(data => {
            this.afterSubmit();
            this.props.history.push('/login');
        }).catch((error) => {
            this.afterSubmit();
            this.dealWithError(error, 'No pudimos reestablecer su contrasena');
        }
        );
    };

    handleChange = (e) => {
        let id = e.target.id
        let userData = { ...this.state.userData };
        userData[id] = e.target.value;
        this.setState({
            userData: userData
        }, _ => {
            this.validateFieldsAfterChange(userData);
        })
    }

    validateFieldsAfterChange = (userData) => {
        let passwordValidation = Validations.password(userData.password)
        let passwordError = passwordValidation.every((v) => v === true) ? null : 'Contrasena invalida'
        //errors.password = passwordError
        this.setState({
            passwordValidation: passwordValidation,
            passwordError: passwordError,
            //errors: errors
        }, _ => {
            this.updateButtonState(userData);
        })
    }

    updateButtonState = (userData) => {
        if (userData.password && !this.state.passwordError) {
            this.setState({
                buttonState: 'enabled'
            })
        } else {
            this.setState({
                buttonState: 'disabled'
            })
        }
    }


    handleGoBack = () => {
        return true
    }

    render() {
        const content = <div className='reset-password'>
            <form onSubmit={this.handleSubmit}>
                <Logo></Logo>
                <p className='title'>Reestablecer contraseña</p>
                <p className={'subtitle'}>Escoge una nueva contraseña<br></br>para ingresar a tu cuenta.</p>
                <p className={'second-subtitle'}>No debes usar contraseñas anteriores.</p>
                <div className='form-container'>
                    <div className="inputs-wrapper">
                        <PasswordInput validate={true} validationResults={this.state.passwordValidation} handleChange={this.handleChange} id={'password'} error={this.state.errors['password'] ?? this.state.message}></PasswordInput>
                    </div>
                </div>
                <div className="rounded-button-wrapper">
                    <RoundedButton legend='CREAR CONTRASEÑA' handleClick={this.handleSubmit} state={this.state.buttonState}></RoundedButton>
                </div>
            </form>
        </div>

        return (
            <div className='reset-password-container'>
                <AuthGeneric title={'Reestablecer contraseña'} version={'header'} content={content}></AuthGeneric>
            </div>
        )
    }

}


ResetPasswordView.propTypes = {
    handleChange: PropTypes.func,
    label: PropTypes.string
};

export default ResetPasswordView;