import React from "react";
import { Tooltip } from "react-tippy";
import { ParseDate, ParseDateTime } from "../../../../../../../helpers/Parsers";
import OrderPills from "../../../../../atoms/Pills/OrderPills";
import "./styles.scss";

const tooltipStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center"
};

const mapping = {
  PEN: "Pendiente",
  PRO: "En proceso",
  DISP: "Aprobada",
  REJ: "Rechazada",
  DRA: "Borrador"
};

export const RelocationOrderTableItem = ({ relocationOrder, handleClick }) => {
  return (
    <div
      className="order-relocation-table-item-container"
      data-automation-id="product-item"
      data-relocation-order-id={relocationOrder.id}
      onClick={handleClick}
    >
      <div className="table-item-code">
        <Tooltip
          html={<div style={tooltipStyle}>{relocationOrder.code}</div>}
          title={relocationOrder.code}
          followCursor={true}
          position="left"
          offset={25}
        >
          <p>{relocationOrder.code}</p>
        </Tooltip>
      </div>
      <div className="table-item-date">
        <Tooltip
          html={
            <div style={tooltipStyle}>
              {relocationOrder.created_at
                ? ParseDateTime(relocationOrder.created_at)
                : null}
            </div>
          }
          followCursor={true}
          position="right"
          offset={25}
        >
          {" "}
          <p>
            {relocationOrder.created_at
              ? ParseDate(relocationOrder.created_at)
              : "-"}
          </p>
        </Tooltip>
      </div>
      <div className="table-item-owner">
        <Tooltip
          html={
            <div style={tooltipStyle}>
              <p>
                {relocationOrder.owner
                  ? `${relocationOrder.owner.first_name} ${relocationOrder.owner.last_name}`
                  : "-"}
              </p>
            </div>
          }
          followCursor={true}
          position="right"
          offset={25}
        >
          <p>
            {relocationOrder.owner
              ? `${relocationOrder.owner.first_name} ${relocationOrder.owner.last_name}`
              : "-"}
          </p>
        </Tooltip>
      </div>
      <div className="table-item-state">
        <Tooltip
          html={
            <div style={tooltipStyle}>
              <p>{mapping[relocationOrder.status]}</p>
            </div>
          }
          followCursor={true}
          position="right"
          offset={25}
        >
          <OrderPills state={relocationOrder.status} />
        </Tooltip>
      </div>
      <div
        className="table-item-button"
        data-automation-id="select-relocationOrder"
      >
        <i className="icon-chevron-derecha"></i>
      </div>
    </div>
  );
};

export default RelocationOrderTableItem;
