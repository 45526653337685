import i18n from "i18n";

export const step1 = {
  items: [
    {
      title: i18n.t("Sucursales"),
      arrow: true,
      to: "/admin/businessnodes",
      permission: 'read_nodes',
    },
    // {
    //   title: i18n.t("Dispositivos"),
    //   arrow: true,
    //   to: "/admin/devices",
    //   permission: 'read_devices',
    // },
    {
      title: i18n.t("Clientes"),
      arrow: true,
      to: "/admin/clients",
      permission: 'read_persons',
    },
    {
      title: i18n.t("Proveedores"),
      arrow: true,
      to: "/admin/providers",
      permission: 'read_persons',
    },
    {
      title: i18n.t("Aplicaciones externas"),
      arrow: true,
      to: "/admin/applications",
      permission: 'read_apps',
    },
    {
      title: i18n.t("Empresas"),
      arrow: true,
      to: "/admin/businesses",
      permission: 'all',
    },
    {
      title: i18n.t("Usuarios"),
      arrow: true,
      to: "/admin/users",
      permission: 'read_users',
    },
    {
      title: i18n.t("Roles"),
      arrow: true,
      to: "/admin/roles",
      permission: 'read_roles',
    },
  ],
  headerTitle: "Configuraciones",
  navHidden: false,
  goBackTo: 0,
};
