import React, { useState } from "react";
import Icon from "../../../../../../components/MainApp/atoms/Icon/icon";
import InputText from "../../../../../../components/MainApp/atoms/Forms/MainInput";
import ItemTags from "components/MainApp/organisms/OrdersV3/Item-Tags";
import DeviceSelector from "components/Functionals/DeviceSelector";
import "./styles.scss";

export const ValidateMovementItems = ({
  enabledRead,
  typeRead,
  refRead,
  associateRead,
  priorityRead,
  receiveEvent,

  selectedItems = [],
  total,
  totalValidate,

  handleSearch,
  handleReject,
  handleApprove,
  goBack,
}) => {
  const [searchedValue, setsearchedValue] = useState("");

  const searchValue = (e) => {
    setsearchedValue(e.target.value);
  };

  const handleSubmit = function(e) {
    e.preventDefault();
    handleSearch(searchedValue);
    setsearchedValue("");
  };

  return (
    <div className={"validate-movement-items-outter"}>
      <div className="validate-movement-items-wrapper">
        <div className="validate-movement-items-title-wrapper" onClick={goBack}>
          <div className="validate-movement-items-title-icon">
            {" "}
            <Icon name="chevron" width={14} height={14} fill={"#62D862"} />
          </div>
          <h1 className="validate-movement-items-title">
            Supervision de productos
          </h1>
        </div>
        <div className="device-selector-wrapper">
          <DeviceSelector
            enabled={enabledRead}
            typeRead={typeRead}
            refRead={refRead}
            associateRead={associateRead}
            priorityRead={priorityRead}
            receiveEvent={receiveEvent}
            includeType={"MOB_READER"}
          />
        </div>
        <div className="validate-movement-items-content">
          <div className="validate-movement-items-input-wrapper">
            <div className="validate-movement-items-input-label">
              Ingresa el nro. o escanea TagID
            </div>
            <form
              className="validate-movement-items-input"
              onSubmit={handleSubmit}
            >
              <InputText
                label="Ingresa número"
                handleChange={searchValue}
                value={searchedValue}
              />
              <Icon name="barcode" width={30} height={25} fill={"#3C7AF5"} />
            </form>
          </div>
          <div className="validate-movement-items-count">
            {totalValidate}/{total}
          </div>
          <div className="validate-movement-items-list">
            {selectedItems.map((item) => {
              return (
                <ItemTags
                  key={item.sku}
                  item={item}
                  type="verification"
                  quantity={item.tags.filter((t) => t.selected).length}
                  expected={item.tags.length}
                />
              );
            })}
          </div>
          <div className="validate-movement-items-buttons-wrapper">
            <div
              className="validate-movement-items-button validate-movement-items-clean"
              onClick={handleReject}
            >
              {" "}
              RECHAZAR{" "}
            </div>
            <div
              className="validate-movement-items-button validate-movement-items-send"
              onClick={handleApprove}
            >
              APROBAR
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateMovementItems;
