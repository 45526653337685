import React from 'react';
import './styles.scss';


export class AccountSelction extends React.Component {
    render() {
        const {
            accountList,
            handleSelectAccount,
            handleAddEmail
        } = this.props;

        const accounts = accountList.map(account => {
            return <div className="account-container" key={account} data-automation-id="select-account" onClick={() => { handleSelectAccount(account)}}>
                <i className="icon icon-avatar"></i>
                <p className="email">{account} </p>
            </div>
        })
        return (
            <div className="acct-selection-container">
                <h1 className="title">Selecciona una cuenta</h1>
                <div className="accountList">
                    {accounts}
                <div className="account-container add-email" key='add' data-automation-id="add-email" onClick={handleAddEmail}>
                    <p className="icon-plus">+</p>
                    <p className="email">Agregar email</p>
                </div>
                </div>
                
            </div>
        )
    }

}


AccountSelction.propTypes = {};

export default AccountSelction;