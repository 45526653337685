import React, { useEffect, useState } from "react";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";
import MainTextArea from "components/MainApp/atoms/Forms/MainTextArea";

import "./styles.scss";

const EditConfigTagForm = ({
  configTag,
  handleChange,
  error,
  changeConfigTag,
}) => {

const [editCondigTag, setEditConfigTag] = useState()

  const types = [
    {
      id: 'PRODUCT',
      label: 'Producto',
      value: 'PRODUCT'
    },
    {
      id: 'BOX',
      label: 'Caja',
      value: 'BOX'
    },
    {
      id: 'PALLET',
      label: 'Palet',
      value: 'PALLET'
    }
  ]


  const options = {
    mem_epc: [
      {
        label: "96",
        value: "96",
      },
      {
        label: "128",
        value: "128",
      },
      {
        label: "240",
        value: "240",
      },
    ],
    mem_user: [
      {
        label: '32',
        value: '32'
      },
      {
        label: '64',
        value: '64'
      },
    ]
  };

  useEffect(() => {
    if(configTag){
      const type = getTypeOrId(configTag.type);
      configTag["type"] = types[type == 'PRODUCT' ? 0 : (type == 'BOX' ? 1 : 2)];
      configTag["mem_epc"] = options.mem_epc[getMemOrValue(configTag.mem_epc) == "96" ? 0 : (getMemOrValue(configTag.mem_epc) == '128' ? 1 : 2)];
      configTag["mem_user"] = options.mem_user[getMemOrValue(configTag.mem_user) == "32" ? 0 : 1];
      setEditConfigTag(configTag);
    }
  }, [configTag])


  const getTypeOrId = (type) => {
    if (type?.id) {
      return type.id;
    }
    return type;
  }

  const getMemOrValue = (mem) => {
    if (mem?.value) {
      return mem.value;
    }
    return mem;
  }


  const handleSelect = (selectedOption, type) => {
    const _configTag = { ...configTag };
    _configTag[type] = selectedOption;
    configTag = _configTag;
    changeConfigTag(configTag);
  }

  return (
    <div className="edit-config-tag-form-content">

      <div className="edit-config-tag-form-field" data-automation-id="input-type">
        <MainDropdownInput
          required={true}
          id={"type"}
          name="type"
          selectedOption={configTag ? configTag.type : ''}
          handleSelection={(selectedOption) => handleSelect(selectedOption, 'type')}
          maxLength={50}
          options={types}
          placeholder={'Tipo de embalaje'}
          label={"Tipo de embalaje"}
          error={error.type && error.type}
        />
      </div>

      <div
        className="edit-config-tag-form-field"
        data-automation-id="input-name"
      >
        <MainInput
          label={"Nombre"}
          required={true}
          id={"config-tag-name"}
          name="name"
          value={configTag ? configTag.name : ''}
          handleChange={handleChange}
          maxLength={100}
          error={error.name && error.name}
        />
      </div>
      <div
        className="edit-config-tag-form-field"
        data-automation-id="input-description"
      >
        <MainTextArea
            label={'Descripción (opcional)'}
            required={false}
            id={"config-tag-description"}
            name={'description'}
            value={configTag ? configTag.description : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error && error.description ? error.description : ''}
        ></MainTextArea>
      </div>

      <h4 className="subtitle">Medidas</h4>

      <div className="edit-config-tag-form-field add-config-tag-wizard-step-two-field" data-automation-id="input-height">
        <MainInput
          label={"Alto (mm)"}
          required={true}
          id={"configTag-height"}
          name="height"
          type="number"
          value={configTag ? configTag.height.toString() : ''}
          handleChange={handleChange}
          maxLength={100}
          error={error.height && error.height}
        />
      </div>
      <div className="edit-config-tag-form-field add-config-tag-wizard-step-two-field" data-automation-id="input-width">
        <MainInput
          label={"Ancho (mm)"}
          required={true}
          id={"configTag-width"}
          name="width"
          type="number"
          value={configTag ? configTag.width.toString() : ''}
          handleChange={handleChange}
          maxLength={100}
          error={error.width && error.width}

        />
      </div>
      <h4 className="subtitle">Memorias</h4>
      <div className="edit-config-tag-form-field add-config-tag-wizard-step-two-field" data-automation-id="input-mem_epc">
        <MainDropdownInput
          required={true}
          id={"configTag-mem-epc"}
          name="mem-epc"
          selectedOption={configTag ? configTag.mem_epc : ''}
          handleSelection={(selectedOption) => handleSelect(selectedOption, 'mem_epc')}
          maxLength={50}
          options={options.mem_epc}
          placeholder={'Memoria EPC'}
          label={"Memoria EPC"}
          error={error.mem_epc && error.mem_epc}
        />
      </div>
      <div className="edit-config-tag-form-field add-config-tag-wizard-step-two-field" data-automation-id="input-mem_user">
        <MainDropdownInput
          required={true}
          id={"configTag-mem-user"}
          name="mem-user"
          selectedOption={configTag ? configTag.mem_user : ''}
          handleSelection={(selectedOption) => handleSelect(selectedOption, 'mem_user')}
          maxLength={50}
          options={options.mem_user}
          placeholder={'Memoria de usuario'}
          label={"Memoria de usuario"}
          error={error.mem_user && error.mem_user}
        />
      </div>
    </div>
  );
};

export default EditConfigTagForm;
