import React from "react";

import Step from "components/MainApp/atoms/Wizard/Step";
import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";
import Icon from "../../../../../../components/MainApp/atoms/Icon/icon";

import ItemTags from "components/MainApp/organisms/OrdersV3/Item-Tags";
import LocationSummary from "components/MainApp/organisms/OrdersV3/LocationSummary";
import RoundedButton from "components/MainApp/atoms/RoundedButton";

import "./styles.scss";

export const LocateMovementItems = ({
  itemsLocateds,
  itemsNoLocateds,
  handleDelete,
  handleSelect,
  allSelecteds,
  handleSelectAll,
  addLocation,
  handleSubmit,
  goBack
}) => {
  const stepContent = (
    <div className="reception-order-step-three-content">
      
      <div className="check-all">
        <div className="all-selector" onClick={() => handleSelectAll()}>
          <Checkbox checked={allSelecteds} />
        </div>
        Todos los Productos
      </div>
      <div className="load-movement-items-list">
        {itemsNoLocateds &&
          itemsNoLocateds.map((item) => {
            return (
              <ItemTags
                key={item.sku}
                item={item}
                quantity={item.tags.length}
                expected={item.expectedUnits}
                handleSelect={handleSelect}
              />
            );
          })}
      </div>

      
    </div>
  );
  return (
    <div className="reception-order-step-three-outer">
      <div className="reception-order-step-three-container">
      <div className="locate-movement-items-title-wrapper" onClick={goBack}>
          <div className="locate-movement-items-title-icon">
            {" "}
            <Icon name="chevron" width={14} height={14} fill={"#62D862"} />
          </div>
          <h1 className="locate-movement-items-title">
            Ubicar productos
          </h1>
        </div>
        {itemsLocateds &&
          itemsLocateds.map((item) => {
            return (
              <div
                key={item.code}
                className={"reception-order-step-three-location"}
              >
                <LocationSummary item={item} handleDelete={handleDelete} />
              </div>
            );
          })}

        {itemsNoLocateds.length !== 0 && (
          <Step
            title="Seleccione los productos y agregue su ubicacion"
            content={stepContent}
          ></Step>
        )}
      </div>
     <div className="locate-movement-items-button last">
        <div>
          {itemsNoLocateds.length !== 0 && (
            <RoundedButton
              legend="AGREGAR UBICACION"
              state="enabled"
              handleClick={addLocation}
            ></RoundedButton>
          )}
          {itemsNoLocateds.length === 0 && (
            <RoundedButton
              legend="FINALIZAR ORDEN"
              state="enabled"
              handleClick={handleSubmit}
            ></RoundedButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocateMovementItems;
