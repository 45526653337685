import React from "react";
import OrderPills from "components/MainApp/atoms/Pills/OrderPills";
import { ParseDate, ParseDateTime } from "helpers/Parsers";

import "./styles.scss";
export const RequestOrderItemMobile = ({ requestOrder, handleClick }) => {
  return (
    <div
      className="request-order-table-item-mobile-container"
      data-automation-id="request-order-table-item"
      data-requestorder-id={requestOrder.id}
      data-requestorder-type={requestOrder.type}
      onClick={handleClick}
    >
      <div className="request-order-table-item-mobile-code">
        Nro {requestOrder.code}
      </div>

      <div className="request-order-table-item-mobile-date">
        Fecha: {ParseDate(requestOrder.created_at)}
      </div>

      <div className="request-order-table-item-mobile-bottom">
        <div className="request-order-table-item-mobile-owner">
          Responsable:{" "}
          {requestOrder.owner
            ? `${requestOrder.owner.first_name} ${requestOrder.owner.last_name}`
            : "-"}
        </div>
        <div className="request-order-table-item-mobile-state-inner">
          <OrderPills state={requestOrder.status}></OrderPills>
        </div>
      </div>
    </div>
  );
};

export default RequestOrderItemMobile;
