import Environment from "../environment"; 
import Api from './ApiService';

import { Business } from "../models/Business";


export const BusinessService = {

    businesses: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/business/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Business(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    businessesNoPage: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/business/`, 'GET', { no_page: 1 })
                .then(rta => {
                	rta = rta.map(item => new Business(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    business: (id) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/business/${id}/`, 'GET')
                .then(rta => {
                	rta.results = new Business(rta)
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },


    createBusiness: (data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/business/`, 'POST', data
                ).then(data => {
                    resolve(new Business(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    createBusinessFormData: (formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/business/`, 'POST', formData
                ).then(data => {
                    resolve(new Business(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateBusiness: (id, data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/business/${id}/`, 'PATCH', data
                ).then(data => {
                    resolve(new Business(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateBusinessFormData: (id, formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/business/${id}/`, 'PATCH', formData
                ).then(data => {
                    resolve(new Business(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    uploadBusinesss: (formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/business/upload/`, 'POST', formData
                ).then(data => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    // Companies name
    businessName: async (params) => {
        return Api.fetch(`${Environment.api}api/search/business/`, 'GET', params);
        return []
    },
}
