import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";


import "./styles.scss";

export class AddConfigTagStepThree extends React.Component {
  render() {
    const { configTag, options, handleSelect, goBack, handleFinish, error } = this.props;

    const stepContent = (
      <form onSubmit={handleFinish}>
      <div className="add-config-tag-wizard-step-three-content">
        <div className="add-config-tag-wizard-step-three-field" data-automation-id="input-mem_epc">
          <MainDropdownInput
            required={true}
            id={"configTag-mem-epc"}
            name="mem-epc"
            selectedOption={configTag ? configTag.mem_epc : ''}
            handleSelection={(selectedOption) => handleSelect(selectedOption, 'mem_epc')}
            maxLength={50}
            options={options.mem_epc}
            placeholder={'Memoria EPC'} 
            label={"Memoria EPC"}
            error={error.mem_epc && error.mem_epc}
          />
        </div>
        <div className="add-config-tag-wizard-step-three-field" data-automation-id="input-mem_user">
          <MainDropdownInput
            required={true}
            id={"configTag-mem-user"}
            name="mem-user"
            selectedOption={configTag ? configTag.mem_user : ''}
            handleSelection={(selectedOption) => handleSelect(selectedOption, 'mem_user')}
            maxLength={50}
            options={options.mem_user}
            placeholder={'Memoria de usuario'} 
            label={"Memoria de usuario"}
            error={error.mem_user && error.mem_user}
          />
        </div>
        <div className={"add-config-tag-wizard-step-three-action-wrapper"}>
          <button type="submit" className="add-config-tag-wizard-step-three-action-button">
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-config-tag-wizard-step-three-container">
        <Step title="Memoria" content={stepContent} goBackFunc={goBack}></Step>
      </div>
    );
  }
}

export default AddConfigTagStepThree;
