import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";
import Icon from "components/MainApp/atoms/Icon/icon";
import MenuItem from "@material-ui/core/MenuItem";
import CheckboxMUI from '@mui/material/Checkbox';
import ListItemText from "@material-ui/core/ListItemText";
import "./styles.scss";
import downArrowIcon from "assets/images/down-arrow-icon-select.svg";
import upArrowIcon from "assets/images/up-arrow-icon-select.svg";

const useStyles = makeStyles((theme) => ({
  formRoot: {
    width: "100%",
    borderBottomColor: "none",
  },
  container: {
    border: "1px solid #D4D5E8",
    paddingLeft: "17px",
    height: "48px",
    borderRadius: "4px",
  },
  containerOpend: {
    border: "1px solid #3C7AF5",
    borderRadius: "4px",
    paddingLeft: "17px",
    height: "48px",

    "&. MuiMenu-paper MuiPopover-paper": {},
  },
  select: {
    ".MuiPopover-paper": {
      marginTop: "3px",
    },
  }
}));

const MultiSelectInput = ({
  handleChange,
  handleChangeMobile,
  handleClose,
  handleOpen,
  items,
  selected = [],
  maxRenderValues = 2,
  label,
  required,
  maxDevicesSelected,
  classInput = "",
  classItems = "",
  enableSelectAll = false
}) => {
  const [_selected, setSelected] = useState(selected);
  const [opened, setOpened] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  const _handleChange = (event) => {
    let newDevices = event.target.value;

    let clickedElement = null;
    if (newDevices.length > 0) {
      const lastDevice = newDevices[newDevices.length - 1];

      if(lastDevice === "selectAll") {
        if(_selected.length === items.length) {
          setSelected([])
          handleChange && handleChange([]);
        } else {
          setSelected(items);
          handleChange && handleChange(items);
        }
        return;
      }

      clickedElement = lastDevice;
      const subarray = newDevices.slice(0, -1);
      const i = subarray.findIndex((d) => d.id === lastDevice.id);
      if (i >= 0) {
        newDevices.splice(i, 1);
        newDevices.pop();
      }

      if(newDevices.length > maxDevicesSelected){
        newDevices.splice(0, 1);
      }
    }
    setSelected(newDevices);
    if(handleChangeMobile ){
      handleChangeMobile(newDevices, clickedElement);
    }else{
      handleChange && handleChange(newDevices);
    }
  };

  const _handleOpen = () => {
    setOpened(true);
    handleOpen && handleOpen();
  };

  const _handleClose = () => {
    setOpened(false);
    handleClose && handleClose(_selected);
  };

  const getRenderValue = (sel, maxRenderValues = 2) => {
    let joinedValues = sel
      .map((s) => s.value)
      .slice(0, maxRenderValues)
      .join(", ");
    const rest =
      sel.length - maxRenderValues > 0
        ? `+ ${sel.length - maxRenderValues}`
        : "";
    let ret = `${joinedValues} ${rest}`;
    ret = ret.match(/^,[ ]+/) ? ret.replaceAll(/^,[ ]+/g, "") : ret;
    return ret;
  };


  const getSelectAllChecked = () => {
    if (items.length === 0) return false;
    if (_selected.length === 0) return false;
    if (_selected.length === items.length) return true;
    return "indeterminate";
  };

  return (
    <div
      className={`${
        opened ? classes.containerOpend : classes.container
      } multi-select-wrapper ${classInput}`}
    >
      <FormControl className={classes.formRoot}>
        <InputLabel id="demo-mutiple-checkbox-label">
          {label} {required ? <span className="required-span">*</span> : ""}
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple={true}
          value={_selected}
          onChange={_handleChange}
          input={<Input />}
          renderValue={(selected) => getRenderValue(selected, maxRenderValues)}
          onClose={_handleClose}
          onOpen={_handleOpen}z
          MenuProps={{
            anchorOrigin: {
              vertical: 34,
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "100px",
              horizontal: "left",
            },
            getContentAnchorEl: null
          }}
          className={classes.select}
          IconComponent={() => <img src={opened ? upArrowIcon : downArrowIcon} />}
        >
          {enableSelectAll && (
            <MenuItem className={classItems} value="selectAll">
              <CheckboxMUI
                checked={getSelectAllChecked()}
                indeterminate={getSelectAllChecked() === "indeterminate"}
                icon={<></>}
                checkedIcon={<i  data-value={label} className="icon icon-check-square" />}
                indeterminateIcon={<i><Icon name="indeterminate-check" /></i>}
                className={_selected.length > 0 ? "items-selected" : ""}
              />
              <ListItemText primary={`Todos los ${label.toLowerCase()}`} />
            </MenuItem>
          )}

          {items.map((item) => (
            <MenuItem
              key={item.id}
              value={item}
              className={classItems}
            >
              <Checkbox
                checked={_selected.map((s) => s.id).includes(item.id)}
              />
              <ListItemText primary={item.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultiSelectInput;
