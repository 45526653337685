import "./ModalDevicesActivation.scss";
import { useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import MainAppGenericModal from "components/MainApp/atoms/Modals/GenericModal";
import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";
import deviceIcon from "assets/images/device-icon-blue.svg";
import closeIcon from "assets/images/close-icon.svg";
import backArrow from "assets/images/back-arrow.svg";

const ModalDeviceActivation = ({
  isOpen,
  setIsOpen,
  devicesOptions,
  openRoom,
  closeRoom,
  activeDevices
}) => {
  const { isMobile } = useWindowSize();
  const [deviceSelected, setDeviceSelected] = useState([]);

  const handleSelect = device => {
    if (deviceSelected.includes(device.id)) {
      setDeviceSelected(prev =>
        prev.filter(deviceId => deviceId !== device.id)
      );
    } else {
      setDeviceSelected(prev => [...prev, device.id]);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setDeviceSelected(activeDevices);
  };

  const handleSubmit = () => {
    if (deviceSelected.length > 0) {
      openRoom(deviceSelected);
    } else {
      closeRoom();
    }

    setIsOpen(false);
  };

  const contentPopUp = (
    <section className="modal_device_activation">
      <img src={isMobile ? backArrow : closeIcon} onClick={handleClose} />
      <header>
        <img src={deviceIcon} />
        <div>
          <h4>Activación de dispositivos</h4>
          <p>
            Dispositivos activos <span>{activeDevices.length}</span>
          </p>
        </div>
      </header>
      <ul>
        {devicesOptions.map(device => (
          <li key={device.id} onClick={() => handleSelect(device)}>
            <Checkbox checked={deviceSelected.includes(device.id)} />
            {device.value}
          </li>
        ))}
      </ul>
      <footer>
        <button onClick={handleClose}>Cancelar</button>
        <button onClick={handleSubmit}>Guardar</button>
      </footer>
    </section>
  );

  return (
    <MainAppGenericModal
      open={isOpen}
      content={contentPopUp}
      handleClose={handleClose}
    />
  );
};

export default ModalDeviceActivation;
