import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
export class MainInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: this.props.value ? this.props.value : 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value
      })
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      value,
    });

    if (this.props.handleChange) this.props.handleChange(this.props.id, value);
  };

  handleBlur = (e) => {
    e.preventDefault();
    this.setState({
      focused: false,
    });
  };

  handleFocus = (e) => {
    e.preventDefault();
    this.setState({
      focused: true,
    });
  };

  showInputHanlder = () => {
    this.setState({
      focused: true,
    });
  };

  
  handleDecrease = () =>{
    let value = this.state.value - 1;

    this.setState({
        value,
      });
  
      if (this.props.handleChange) this.props.handleChange(this.props.id, value);
  }

  handleAdd = () =>{
    let value = Number(this.state.value) + 1;

    this.setState({
        value,
      });
  
    if (this.props.handleChange) this.props.handleChange(this.props.id, value);
  }

  render() {
    const {
      name,
      disabled,
      error,
      maxLength,
      id
    } = this.props;

    return (
      <div className="number-input-container">
       
        <div className={`number-input-wrapper ${this.state.focused ? 'focused' :''} ${disabled ? 'disabled' :''} ${error ? 'error' :''}`}>
          

            
        <div className={"number-input-handler number-input-handler-decrease"} onClick={this.handleDecrease}> -

         </div>
         <div className="number-input-handler-divider"></div>
            <input
              type={'Number'}
              value={this.state.value}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              id={id ? id : name}
              name={name}
              data-automation-id="input"
              maxLength={maxLength}
            ></input>
         <div className="number-input-handler-divider"></div>

        <div className={"number-input-handler number-input-handler-add"} onClick={this.handleAdd}> + </div>

        </div>
        <div className="number-input-error">
          {error}
        </div>

        {disabled && <div className="number-input-overlay"></div>}
      </div>
    );
  }
}

MainInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default MainInput;
