import { ParseDate } from "helpers/Parsers.js";

class Person {
  id = null;
  type = null;
  name = null;
  document = null;
  email = null;
  phone = null;
  address = null;

  locality = null;
  state = null;
  region = null;
  country = null;

  enabled = null;
  entry_date = null;

  created_at = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }
}

class Client {
  id = null;
  name = null;
  business_name = null;
  document = null;
  address = null;
  country = null;
  region = null;
  state = null;
  locality = null;
  date = null;
  phone = null;
  email = null;
  entry_date = null;
  created_at = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }

  getDescriptionPanelView() {
    return [
      {
        title: "Nombre",
        value: this.name,
      },
      {
        title: "Razon Social",
        value: this.business_name,
      },

      {
        title: "Documento",
        value: this.document,
      },

      {
        title: "Pais",
        value: this.country
          ? this.country.label
            ? this.country.label
            : this.country
          : "",
      },
      {
        title: "COMUNIDAD",
        value: this.region
          ? this.region.label
            ? this.region.label
            : this.region
          : "",
      },
      {
        title: "Provincia",
        value: this.state
          ? this.state.label
            ? this.state.label
            : this.state
          : "",
      },
      {
        title: "Municipio",
        value: this.locality
          ? this.locality.label
            ? this.locality.label
            : this.locality
          : "",
      },
      {
        title: "Fecha de Ingreso",
        value: ParseDate(this.entry_date),
      },
    ];
  }

  getContactPanelView() {
    return [
      {
        title: "Direccion",
        value: this.address,
      },
      {
        title: "Telefono",
        value: this.phone,
      },

      {
        title: "Email",
        value: this.email,
      },
    ];
  }

  getLocationPanelView() {
    return {
      id: this.id,
      name: this.name,
    };
  }

  getGenericTableView() {
    return {
      id: this.id,
      name: this.name,
      document: this.document,
      business_name: this.business_name,
      address: this.address,
      // country: this.country
      // ? this.country.label
      //   ? this.country.label
      //   : this.country
      // : "",
      // region: this.region
      // ? this.region.label
      //   ? this.region.label
      //   : this.region
      // : "",
      state: this.state
      ? this.state.label
        ? this.state.label
        : this.state
      : "",
      locality: this.locality
      ? this.locality.label
        ? this.locality.label
        : this.locality
      : "",

    }
  }
}

class Provider {
  id = null;
  name = null;
  business_name = null;
  document = null;
  address = null;
  country = null;
  region = null;
  state = null;
  locality = null;
  date = null;
  phone = null;
  email = null;
  entry_date = null;
  created_at = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }

  getDescriptionPanelView() {
    return [
      {
        title: "Nombre",
        value: this.name,
      },
      {
        title: "Razon Social",
        value: this.business_name,
      },

      {
        title: "Documento",
        value: this.document,
      },

      {
        title: "Pais",
        value: this.country
          ? this.country.label
            ? this.country.label
            : this.country
          : "",
      },
      {
        title: "COMUNIDAD",
        value: this.region
          ? this.region.label
            ? this.region.label
            : this.region
          : "",
      },
      {
        title: "Provincia",
        value: this.state
          ? this.state.label
            ? this.state.label
            : this.state
          : "",
      },
      {
        title: "Municipio",
        value: this.locality
          ? this.locality.label
            ? this.locality.label
            : this.locality
          : "",
      },
      {
        title: "Fecha de Ingreso",
        value: ParseDate(this.entry_date),
      },
    ];
  }

  getContactPanelView() {
    return [
      {
        title: "Direccion",
        value: this.address,
      },
      {
        title: "Telefono",
        value: this.phone,
      },

      {
        title: "Email",
        value: this.email,
      },
    ];
  }

  getLocationPanelView() {
    return {
      id: this.id,
      name: this.name,
    };
  }

  getGenericTableView(){
    return {
      id: this.id,
      name: this.name,
      document: this.document,
      country: this.country
      ? this.country.label
        ? this.country.label
        : this.country
      : "",
      region: this.region
      ? this.region.label
        ? this.region.label
        : this.region
      : "",
      state: this.state
      ? this.state.label
        ? this.state.label
        : this.state
      : "",
      locality: this.locality
      ? this.locality.label
        ? this.locality.label
        : this.locality
      : "",

    }
  }
}

export { Person, Client, Provider };
