import { toBigInt } from "helpers/utils/utils_general";
const getLocation = (itemStock) => {
  return itemStock.location ? itemStock.location.id : "-";
};

/**
 * Agrupa los stock por la locacion e item.
 * Requiere que el stock tenga asociado una locacion y un tag.
 * @param {Dict[]} stock Arreglo de items stock
 * @return {Dict[]} [ { title, sku, loc_id, locations, tags: [ {tag, barcode} ] } ]
 */
const stockByItemAndLocation = (stock) => {
  let newList = [];

  let items = stock.sort((a, b) => {
    if (getLocation(a) < getLocation(b)) return -1;
    if (getLocation(a) > getLocation(b)) return 1;
    if (a.item.sku < b.item.sku) return -1;
    if (a.item.sku > b.item.sku) return 1;
    return 0;
  });

  let ind = 0;
  const cant = items.length;
  while (ind < cant) {
    let actual = items[ind];
    let new_item = {
      title: actual.item.name,
      image: actual.item.image,
      sku: actual.item.sku,
      loc_id: actual.location ? actual.location.id : "-",
      location: actual.location,
      tags: [
        {
          tag: actual.tag.rfid,
          epc: convertHexRfidToBigInt(actual.tag?.rfid),
          barcode: actual.tag.barcode,
        },
      ],
    };

    ind += 1;
    while (
      ind < cant &&
      getLocation(actual) === new_item.loc_id &&
      actual.item.sku === new_item.sku
    ) {
      actual = items[ind];
      if (
        getLocation(actual) === new_item.loc_id &&
        actual.item.sku === new_item.sku
      ) {
        new_item.tags.push({
          tag: actual.tag.rfid,
          epc: convertHexRfidToBigInt(actual.tag?.rfid),
          barcode: actual.tag.barcode,
        });
        ind += 1;
      }
    }

    newList.push(new_item);
  }

  return newList;
};

const convertHexRfidToBigInt = (rfid) => {
  if(!rfid) return null;
  return BigInt('0x' + rfid).toString(10);
}

/**
 * Agrupa los stock por el item. Ademas guarda por cada tag si ya fue
 * marcado o no.
 * Requiere que el stock tenga asociado una locacion y un tag.
 * @param {Dict[]} stock Arreglo de items stock
 * @return {Dict[]} [ { title, sku, expectedUnits, tags: [ {tag, barcode, selected} ] } ]
 */
const stockByItem = (stock) => {
  let newList = [];

  let items = stock.sort((a, b) => {
    if (a.item.sku < b.item.sku) return -1;
    if (a.item.sku > b.item.sku) return 1;
    return 0;
  });

  let ind = 0;
  const cant = items.length;
  while (ind < cant) {
    let actual = items[ind];
    let new_item = {
      id: actual.item.id,
      title: actual.item.name,
      sku: actual.item.sku,
      image: actual.item.image,
      expectedUnits: null,
      readItems: 0,
      tags: [
        {
          tag: actual.tag?.rfid,
          epc: convertHexRfidToBigInt(actual.tag?.rfid),
          barcode: actual.tag?.barcode,
          selected: false,
          message: null,
        },
      ],
    };

    ind += 1;
    while (ind < cant && actual.item.sku === new_item.sku) {
      actual = items[ind];
      if (actual.item.sku === new_item.sku) {
        new_item.tags.push({
          tag: actual.tag?.rfid,
          epc: convertHexRfidToBigInt(actual.tag?.rfid),
          barcode: actual.tag?.barcode,
          selected: false,
          message: null,
        });
        ind += 1;
      }
    }

    new_item.expectedUnits = new_item.tags.length;

    newList.push(new_item);
  }

  return newList;
};

export { stockByItemAndLocation, convertHexRfidToBigInt, stockByItem };
