import React from 'react';

import AuthDesktop from '../AuthDesktop';
import AuthMobile from '../AuthMobile';

import './styles.scss';

export class AuthGeneric extends React.Component {
    
    render() {
        const {
            content,
            title,
            version,
            handleGoBack
        } = this.props;
        return (
            <div className="auth-generic-container">
                <AuthDesktop content={content}></AuthDesktop>
                <AuthMobile content={content} version={version} title={title} handleGoBack={handleGoBack}></AuthMobile>
            </div>
        )
    }

}


AuthGeneric.propTypes = {
    
};

export default AuthGeneric;