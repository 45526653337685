import "./styles.scss";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useState, useEffect } from "react";
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import deviceIcon from "assets/images/device-icon.svg";
import zoneIcon from "assets/images/zone-icon.svg";
import commentIcon from "assets/images/comment-icon.svg";
import Loader from "components/MainApp/atoms/Loader";
import { ProductService } from "services/ProductService";
import AlertDetailCard from "components/MainApp/atoms/AlertDetailCard/AlertDetailCard";
import AlertProductDetail from "components/MainApp/atoms/AlertProductDetail/AlertProductDetail";
import ModalProcessAlert from "components/MainApp/organisms/Modals/ModalProcessAlert/ModalProcessAlert";
import useWindowSize from "hooks/useWindowSize";

const GuardItemDetail = ({ alert, setAlert, reloadAlerts }) => {
  const { isMobile } = useWindowSize();
  const [product, setProduct] = useState(null);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);

  const tag = alert.tag;
  const device = alert.device;

  const alarmDetailContent = [
    { icon: deviceIcon, label: "Dispositivo", value: device?.name },
    { icon: zoneIcon, label: "Lugar", value: device?.location.path_name },
    { icon: commentIcon, label: "Comentario", value: alert?.comments }
  ];

  const handleBack = () => {
    setAlert(null);
  };

  useEffect(() => {
    if (alert?.tag) {
      ProductService.productBySKU(alert.tag.sku).then(prod =>
        setProduct(prod.results.results[0])
      );
    }

    if (alert && !alert?.tag) {
      setTimeout(() => {
        setAlert(null);
      }, 1000);
    }
  }, [alert]);

  return (
    <div className="guard-item-detail">
      {!product && <Loader />}

      <AppBar position="static" className="appBarMui" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleBack}
            aria-label="close"
          >
            <ArrowBackIcon />
          </IconButton>
          <div className="header-tittle">Detalle de alarma</div>
        </Toolbar>
      </AppBar>

      <AlertDetailCard content={alarmDetailContent} alert={alert} />

      <AlertProductDetail
        tag={tag}
        product={product}
        processAlert={() => setIsOpenPopUp(true)}
      />

      {isMobile && (
        <div className="alert-process-mobile">
          <button onClick={() => setIsOpenPopUp(true)}>Procesar alarma</button>
        </div>
      )}

      <ModalProcessAlert
        isOpen={isOpenPopUp}
        setIsOpen={setIsOpenPopUp}
        alert={alert}
        handleBack={handleBack}
        reloadAlerts={reloadAlerts}
      />
    </div>
  );
};

export default GuardItemDetail;
