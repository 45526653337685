const columns = [
    {
      name: 'name',
      pill: false,
      title: 'NOMBRE',
      mobileTag: false,
    },
    {
      name: 'description',
      pill: false,
      title: 'DESCRIPCION',
      mobileTag: false,
  
    },
    {
      name: 'type',
      title: 'TIPO',
      mobileTag: false,
      pill: true,
      pillMap: {
        BOX: 'Caja',
        PALLET: 'Palets',
        PRODUCT: 'Producto'
      },
      pillMapColor: {
        BOX: '#3C7AF5',
        PALLET: '#3C7AF5',
        PRODUCT: '#3C7AF5'
      },
    },
    {
      name: 'dimensions',
      pill: false,
      title: 'DIMENSIONES',
      mobileTag: "Dimensiones: ",
  
    },
    {
      name: 'memories',
      pill: false,
      title: 'MEMORIAS',
      mobileTag: "Memorias: ",
  
    },
  ];


  export default columns;