import React, { useState, useEffect, useRef } from "react";
import useFilters from "hooks/useFilters";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../store/context";
import i18n from "i18n";

import { ProductService } from "../../../services/ProductService";
import { PublicService } from "../../../services/PublicService";

import "./styles.scss";
// import GenericListContainer from "../../../helpers/GenericListContainer";
import AdminLayout from "../../../components/MainApp/layouts/DesktopLayout";
import ProductTable from "../../../components/MainApp/organisms/Product/Table";
import LoadButtonsContainer from "../../../components/MainApp/organisms/Product/LoadButtonsContainer";
import Button from "components/Auth/atoms/buttons/RoundedButton";

import Search from "../../../components/MainApp/atoms/Filters/Search";
import FilterPanel from "../../../components/MainApp/organisms/Filters/FilterPanel";
// import filterDataSource from "./filterData";
import headers from "./headers";
import Pagination from "../../../components/MainApp/atoms/Pagination";
import Loader from "../../../components/MainApp/atoms/Loader";

import MainNotification from "../../../components/MainApp/atoms/Notification";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";
import { NotFoundItems } from "../components/NotFoundItems";
import { isMobile } from "helpers/Mobile";
// import { FALSE } from "node-sass";

const initialFilters = {
  page_size: 10,
  search: "",
  ordering: "-created_at",
  enabled: undefined,
  categories: undefined,
  brand: undefined
};

const filterTypeMap = {
  Categoría: { name: "categories", type: "dropdown" },
  Marca: { name: "brand", type: "dropdown" },
  /*Stock: { name: "stock", type: "twoOption", default_value: undefined },*/
  "Estado del producto": {
    name: "enabled",
    type: "twoOption",
    default_value: undefined
  },
  "Ordenar por": {
    name: "ordering",
    type: "twoOption",
    default_value: "-created_at"
  }
};

const filterDataSource = [
  {
    type: "dropdown",
    options: [],
    label: "Categoría",
    firstOptionLabel: "Todas las categorías",
    name: "category"
  },

  {
    type: "dropdown",
    options: [],
    label: "Marca",
    firstOptionLabel: "Todas las marcas",
    name: "brand"
  },

  /*{
    type: "twoOption",
    options: [
      {
        label: "Con stock",
        selected: false,
        value: true
      },
      {
        selected: false,
        label: "Sin stock",
        value: false
      }
    ],
    label: "Stock",
    firstOptionLabel: "Todos",
    name: "stock"
  },*/
  {
    type: "twoOption",
    options: [
      {
        selected: false,
        label: "Habilitado",
        value: true
      },
      {
        selected: false,
        label: "Inhabilitado",
        value: false
      }
    ],
    label: "Estado del producto",
    firstOptionLabel: "Todos",
    name: "enabled"
  }
];

export const ProductsView = () => {
  const [productFilters, setProductFilters] = useState();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [noproducts, setNoProducts] = useState(false);
  const [filterData, setFilterData] = useState(filterDataSource);

  const [products_name, setProducts_name] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const myRef = useRef(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  
  const [loadingName, setLoadinNameg] = useState(false);


  let scrolling = false;

  const [
    filters,
    setFilters,
    selectedFiltersAll,
    selectedFiltersByType,
    handleApplyFilters,
    handleSearch,
    headerOrderingHandler,
    resetOrdering,
    handleFiltersChangeOrdering,
    applied,
    page,
    setPage,
    items,
    setItems,
    pager,
    setPager,
    header,
    setHeader,
    mobilePanelOpen,
    setMobilePanelOpen
  ] = useFilters(
    initialFilters,
    productFilters,
    filterTypeMap,
    isMobile,
    headers
  );

  useEffect(() => {
    fillFilterData();
  }, []);

  useEffect(() => {
    const first_load =
      JSON.stringify(initialFilters) === JSON.stringify(filters);
    filter(first_load);
    // Acomodar orden
    const keyOrdering =
      filters.ordering.charAt(0) === "-"
        ? filters.ordering.substr(1)
        : filters.ordering;
    if (keyOrdering in headers) {
      const newHeaders = { ...header };
      newHeaders[keyOrdering]["active"] = true;
      newHeaders[keyOrdering]["direction"] =
        filters.ordering === keyOrdering ? true : false;
      setHeader(newHeaders);
    }
  }, []);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }
    filter(false);
    setProductFilters({
      filters: filters,
      selectedFiltersAll: selectedFiltersAll,
      selectedFiltersByType: selectedFiltersByType
    });
  }, [filters, page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  const handleScroll = event => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || submitted || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const handlePagerChange = function (page) {
    setPage(page);
  };

  const filter = function (firstLoad = false) {
    let append = isMobile;
    if (!append) {
      setItems([]);
    }

    //BEFORE SUBMIT
    document.body.style.cursor = "wait";
    setSubmitted(true);

    const data = { ...filters, page: page };
    data["categories"] = data["categories"]
      ? data["categories"].join(",")
      : undefined;
    data["brand"] = data["brand"] ? data["brand"].join(",") : undefined;
    if (data.search) data.search = data.search.trim();
    PublicService.products_plain(data).then((response) => {
      setSubmitted(false);
      document.body.style.cursor = "default";
      scrolling = false;

      if (firstLoad && response.count === 0) {
        history.push("/admin/products/load");
        setNoProducts(true);
        return;
      }

      const newProducts = append
        ? [...items, ...response.results]
        : response.results;

      setLoading(false);
      setItems(newProducts);
      setPager({
        next: response.next,
        previous: response.previous,
        count: response.count,
        countPages: Math.ceil(response.count / filters.page_size)
      });
    }).catch(e => {
        setSubmitted(false);
        document.body.style.cursor = "default";
        scrolling = false;
    });
  };

  /*const filterProducts = () => {
       ProductService.productsName()
       .then(response => {
         setProducts_name(response);
          }).finally(() => setLoading(false));
  }*/

  const filterProducts = (value) => {
      if(value.length > 2) {
        setLoadinNameg(true);
        return new Promise((resolve, reject) => {
          var filter = [];
          filter["search"] = value;
    
          ProductService.productsName({ page_size: 999999, search: value }).then(
            response => {
              setProducts_name(response);
              resolve(response);
              setLoadinNameg(false);
            }
          ).catch(error => {
            resolve([]);
            setLoadinNameg(false);
          });
        }); 
      }
  }

  const fillFilterData = function () {
    // Usa hasta 2k categories/brands porque es un monton para renderizar

    let filterDataAux = [...filterData];
    PublicService.categories().then(response => {
      let results = response.slice(0, 2000).map(option => {
        option.label = option.name;
        option.selected = false;
        option.type = option.name;
        return option;
      });
      filterDataAux[0].options = results;
      // this.forceUpdate();
    });

    PublicService.brands().then(response => {
      let results = response.slice(0, 2000).map(option => {
        option.label = option.name;
        option.selected = false;
        return option;
      });
      filterDataAux[1].options = results;
      // this.forceUpdate();
    });

    filterDataAux[2].options = filterDataAux[2].options.map(option => {
      option.selected = false;
      return option;
    });
/*
    filterDataAux[3].options = filterDataAux[3].options.map(option => {
      option.selected = false;
      return option;
    });*/

    if (isMobile) {
      filterDataAux[4] = {
        type: "twoOption",
        options: [
          {
            selected: false,
            label: "Menos reciente",
            value: "created_at"
          }
        ],
        label: "Ordenar por",
        firstOptionLabel: "Más reciente",
        value: "-created_at"
      };
    }
    setFilterData(filterDataAux);
  };

  const goToProductDetailPage = event => {
    history.push(
      `/admin/products/details/${event.currentTarget.dataset.productId}`
    );
  };

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: () => {
        history.push("/admin/products/load");
      },
      tooltip: "Agregar productos"
    },
    {
      icon: "icon-iconos_categorìas",
      name: "categories",
      handler: () => {
        history.push("/admin/categories");
      },
      tooltip: "Categorías"
    }
  ];

  const headerOptionsIcons = [
    {
      icon: "embalaje",
      name: "embalajes",
      handler: () => {
        history.push(`/admin/embalajes`);
      },
      tooltip: "Embalajes"
    }
  ];

  const tableContentDesktop = (
    <React.Fragment>
      {/* <div className="report-button-wrapper">
        <div className="report-button">
          <Button legend="Reporte" state="enabled" />
        </div>
      </div> */}

      <FilterPanel
        resultList={products_name}
        placeholder="Busca por nombre o código"
        searchValue={filters.search}
        handleSubmitSearch={handleSearch}
        filters={filterData}
        selectedFiltersAll={selectedFiltersAll}
        selectedFiltersByType={selectedFiltersByType}
        handleApplyFilters={handleApplyFilters}
        filterProducts={filterProducts}
      ></FilterPanel>

      {items.length > 0 && (
        <div className="table-products-table">
          <div className="table-products-top-table">
            <div className="table-products-top-total">
              TOTAL{" "}
              <span className="table-products-top-total-bold">
                ({pager.count})
              </span>
            </div>
            <div className="table-products-top-sort">
              <div
                data-automation-id="table-products-sort"
                className={
                  filters.ordering.includes("created_at")
                    ? "table-products-top-sort table-products-active-filter"
                    : "table-products-top-sort"
                }
                onClick={() => {
                  handleFiltersChangeOrdering(
                    "ordering",
                    filters.ordering === "-created_at"
                      ? "created_at"
                      : "-created_at"
                  );
                  resetOrdering();
                }}
              >
                {filters.ordering === "created_at" ? (
                  <React.Fragment>
                    Menos recientes{" "}
                    <i className="icon-chevron table-products-top-icon"></i>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {" "}
                    Más recientes{" "}
                    <i className="icon-chevron-down table-products-chevron-down table-products-top-icon"></i>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <ProductTable
            products={items}
            headers={header}
            headerClickHandler={headerOrderingHandler}
            handleClickProduct={goToProductDetailPage}
          />
        </div>
      )}

      {items.length === 0 && !submitted && (
        <NotFoundItems />
      )}
      {items.length > 0 && (
        <div>
          <Pagination
            pageQuantity={pager.countPages}
            currentPage={page}
            pageChangeHandler={handlePagerChange}
          />
        </div>
      )}
    </React.Fragment>
  );

  const tableContentMobile = (
    <React.Fragment>
      <div className="table-products-filter-container">
        <SimpleSearchPanel
          placeholder="Busca por nombre o código"
          handleSubmit={handleSearch}
        ></SimpleSearchPanel>
      </div>
      {/* <div className="report-button-wrapper">
        <div className="report-button">
          <Button legend="Reporte" state="enabled" />
        </div>
      </div> */}
      <div
        className={`table-products-mobile-panel ${mobilePanelOpen ? "opened" : ""
          } `}
      >
        <FilterPanel
          resultList={products_name}
          placeholder="Busca por nombre o código"
          searchValue={filters.search}
          handleSubmitSearch={handleSearch}
          filters={filterData}
          selectedFiltersAll={selectedFiltersAll}
          selectedFiltersByType={selectedFiltersByType}
          handleApplyFilters={handleApplyFilters}
          closeMobilePanel={() => {
            setMobilePanelOpen(false);
          }}
        ></FilterPanel>
      </div>
      <div className="table-products-table">
        <div className="table-products-top-table">
          <div className="table-products-top-total">
            Total{" "}
            <span className="table-products-top-total-bold">
              ({pager.count})
            </span>
          </div>
          <div
            className={`table-products-top-sort ${applied ? "table-products-active-filter" : ""
              }`}
            onClick={() => {
              setMobilePanelOpen(true);
            }}
          >
            <i className="icon-iconos_filtros table-products-top-icon"></i>
          </div>
        </div>
        <ProductTable
          products={items}
          headers={header}
          handleClickProduct={goToProductDetailPage}
        />
      </div>

      {items.length === 0 && !submitted && (
        <NotFoundItems />
      )}
    </React.Fragment>
  );

  const tableContent = isMobile ? tableContentMobile : tableContentDesktop;

  const noproductcontent = (
    <div className="table-load-products-content">
      <div className="table-load-products-title">
        Aquí visualizarás el listado de tus productos y su stock disponible.
      </div>
      <LoadButtonsContainer
        handleSingleProductClick={() =>
          history.push("/admin/products/load-single-product")
        }
        handleMassiveProductClick={() =>
          history.push("/admin/products/load-massive-product")
        }
      ></LoadButtonsContainer>
    </div>
  );
  const productsElement = noproducts ? noproductcontent : tableContent;

  const content = (
    <div className="table-products-wrapper" ref={myRef}>
      {loading ? <Loader /> : productsElement}
      {loadingName &&  <Loader />}
    </div>
    
  );

  return (
    <div className="load-products-container">
      <AdminLayout
        headerTitle={i18n.t("Productos")}
        headerOptions={headerOptions}
        headerOptionsIcons={headerOptionsIcons}
        content={content}
        navHidden={false}
      ></AdminLayout>
      <MainNotification
        label={"Tu producto se actualizó de manera exitosa."}
        open={false}
      ></MainNotification>
    </div>
  );
};

export default ProductsView;
