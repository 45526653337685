import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import GenericFormContainer from "../../../../helpers/GenericFormContainer";
import AdminLayout from "../../../../components/MainApp/layouts/DesktopLayout";
import LoadButtonsContainer from "../../../../components/MainApp/organisms/Product/LoadButtonsContainer";

export class LoadProductsView extends GenericFormContainer {
  headerOptions = [
    {
        icon: 'icon-iconos_categorìas',
        name: 'categories',
        handler: () => { this.props.history.push('/admin/categories') },
        tooltip: 'Categorías'
    },
  ]

  goBack(){
      this.props.history.push('/admin/products')
  }

  render() {
    const content = <div className="load-products-content">
        <LoadButtonsContainer
          handleSingleProductClick={() => this.props.history.push('/admin/products/load-single-product')}
          handleMassiveProductClick={() => this.props.history.push('/admin/products/load-massive-product')}
        ></LoadButtonsContainer>
    </div>;

    return (
      <div className="load-products-container">
        <AdminLayout headerTitle={'Productos'} headerOptions={this.headerOptions}
            content={content} goBackFunc={this.goBack.bind(this)}>
        </AdminLayout>
      </div>
    );
  }
}

LoadProductsView.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string,
};

export default LoadProductsView;
