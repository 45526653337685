import React from "react";
import ProductModalWrapper from "../ProductModalWrapper";

import "./styles.scss";
export class WrongContentModal extends React.Component {
  render() {
    const {  open, invalidFilesQuantity, totalQuantity,
      handleClose, handleGoBack, handleDownloadTemplate,
      handleDownloadErrors, handleClick } = this.props;

    const content = (<div className="wrong-content-modal-wrapper">
        <div className="wrong-content-modal-title">Contenido erróneo</div>
        <div className="wrong-content-modal-text">Se encontraron <span className="wrong-content-modal-span"> {invalidFilesQuantity} productos inválidos</span> sobre un total de {totalQuantity}.</div>
        <div className="wrong-content-modal-download">
        <i className="wrong-content-modal-icon icon-iconos_descargar"></i>
            <p onClick={handleDownloadErrors} data-automation-id="download-summary">Descargar resumen</p>
        </div>
    </div>)
    return (
      <div className="wrong-content-modal-container">
          <ProductModalWrapper open={open} innerContent={content}
            buttonLegend={"SUBIR OTRO ARCHIVO"} handleGoBack={handleGoBack}
            handleDownloadTemplate={handleDownloadTemplate}
            handleClose={handleClose} handleClick={handleClick}
            ></ProductModalWrapper>
      </div>
    );
  }
}

WrongContentModal.propTypes = {
};

export default WrongContentModal;
