import Environment from "../environment";
import Api from "./ApiService";

import { Account } from "../models/Account";

export const AccountService = {
  /*
    ME
    */
  me: () => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/me/account/`, "GET")
        .then((data) => {
          resolve(new Account(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  meUpdate: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/me/account/`, "PUT", data)
        .then((data) => {
          resolve(new Account(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /*
    ADMIN
    */
  accounts: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/accounts/`, "GET", params)
        .then((rta) => {
          if ("results" in rta) {
            rta.results = rta.results.map((item) => new Account(item));
          } else {
            rta = rta.map((item) => new Account(item));
          }
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
