import React, { useState, useEffect, useRef } from "react";
import useFilters from "hooks/useFilters";
import { useHistory } from "react-router-dom";
import { useStore } from "store/context";
import i18n from "i18n";
import { isMobile } from "helpers/Mobile";

import { BusinessService } from "services/BusinessService";
import { PublicService } from "services/PublicService";

import "./styles.scss";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import CompanyTable from "./components/BusinessTable";

import Search from "components/MainApp/atoms/Filters/Search";
import FilterPanel from "components/MainApp/organisms/Filters/FilterPanel";
import headers from "./headers";
import Pagination from "components/MainApp/atoms/Pagination";
import Loader from "components/MainApp/atoms/Loader";

import { filterDataSource, filterTypeMap } from "./data/filterData";

import MainNotification from "components/MainApp/atoms/Notification";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";
import { NotFoundItems } from "../components/NotFoundItems";
import { AuthService } from "services/AuthService";

const initialFilters = {
  page_size: 10,
  search: "",
  ordering: "-created_at",
  enabled: undefined
};

export const BusinessesView = () => {
  const [companyFilters, setCompanyFilters] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [noCompanies, setNoCompanies] = useState(false);
  const [filterData, setFilterData] = useState(filterDataSource);

  const [companies_name, setCompanies_name] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  let scrolling = false;

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: () => {
        history.push("/admin/businesses/add");
      },
      tooltip: "Agregar empresa"
    }
  ];

  const [
    filters,
    setFilters,
    selectedFiltersAll,
    selectedFiltersByType,
    handleApplyFilters,
    handleSearch,
    headerOrderingHandler,
    resetOrdering,
    handleFiltersChangeOrdering,
    applied,
    page,
    setPage,
    items,
    setItems,
    pager,
    setPager,
    header,
    setHeader,
    mobilePanelOpen,
    setMobilePanelOpen
  ] = useFilters(
    initialFilters,
    companyFilters,
    filterTypeMap,
    isMobile,
    headers
  );

  useEffect(() => {
    fillFilterData();
  }, []);

  useEffect(() => {
    const first_load =
      JSON.stringify(initialFilters) === JSON.stringify(filters);

    filter(first_load);
    // Acomodar orden
    if (filters.ordering) {
      const keyOrdering =
        filters.ordering.charAt(0) === "-"
          ? filters.ordering.substr(1)
          : filters.ordering;
      if (keyOrdering in headers) {
        const newHeaders = { ...header };
        newHeaders[keyOrdering]["active"] = true;
        newHeaders[keyOrdering]["direction"] =
          filters.ordering === keyOrdering ? true : false;
        setHeader(newHeaders);
      }
    }
  }, [filters]);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }
    filter(false);
    setCompanyFilters({
      filters: filters,
      selectedFiltersAll: selectedFiltersAll,
      selectedFiltersByType: selectedFiltersByType
    });
  }, [filters, page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  const goBack = () => {
    history.push("/admin/more-options/1");
  };

  const goToCompanyDetailsPage = id => {
    history.push(`/admin/businesses/edit/${id}`);
  };

  const handleScroll = event => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || submitted || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const handlePagerChange = function(page) {
    setPage(page);
  };

  const filter = function(firstLoad = false) {
    let append = isMobile;
    if (!append) {
      setItems([]);
    }

    //BEFORE SUBMIT
    document.body.style.cursor = "wait";
    setSubmitted(true);

    const data = { ...filters, page: page };

    BusinessService.businesses(data)
      .then(response => {
        setSubmitted(false);
        document.body.style.cursor = "default";
        scrolling = false;

        if (firstLoad && response.count === 0) {
          // history.push("/admin/businesses/load");
          setNoCompanies(true);
          return;
        }

        const newItems = append
          ? [...items, ...response.results]
          : response.results;

        setLoading(false);
        setItems(newItems);
        setPager({
          next: response.next,
          previous: response.previous,
          count: response.count,
          countPages: Math.ceil(response.count / filters.page_size)
        });
      })
      .catch(e => {
        setSubmitted(false);
        document.body.style.cursor = "default";
        scrolling = false;
      });
  };

  const fillFilterData = function() {
    let filterDataAux = [...filterData];
    filterDataAux[0].options = filterDataAux[0].options.map(option => {
      option.selected = false;
      return option;
    });

    if (isMobile) {
      filterDataAux[0] = {
        type: "twoOption",
        options: [
          {
            selected: false,
            label: "Menos reciente",
            value: "created_at"
          }
        ],
        label: "Ordenar por",
        firstOptionLabel: "Más reciente",
        value: "-created_at"
      };
    }
    setFilterData(filterDataAux);
  };

  const desktopContent = (
    <div className="orders-request-content-wrapper">
      <SimpleSearchPanel placeholder="Buscar" handleSubmit={handleSearch} />

      {items.length > 0 && (
        <div className="business-table-table">
          <div className="business-table-top-table">
            <div className="business-table-top-total">
              TOTAL{" "}
              <span className="business-table-top-total-bold">
                ({pager.count})
              </span>
            </div>
            <div className="business-table-top-sort">
              <div
                data-automation-id="business-table-sort"
                className={
                  filters?.ordering?.includes("created_at")
                    ? "business-table-top-sort business-table-active-filter"
                    : "business-table-top-sort"
                }
                onClick={() => {
                  handleFiltersChangeOrdering(
                    "ordering",
                    filters.ordering === "-created_at"
                      ? "created_at"
                      : "-created_at"
                  );
                  resetOrdering();
                }}
              >
                {filters.ordering === "created_at" ? (
                  <React.Fragment>
                    Menos recientes{" "}
                    <i className="icon-chevron business-table-top-icon"></i>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {" "}
                    Más recientes{" "}
                    <i className="icon-chevron-down business-table-chevron-down business-table-top-icon"></i>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <CompanyTable
            businesses={items}
            headers={header}
            headerClickHandler={headerOrderingHandler}
            handleClick={goToCompanyDetailsPage}
          />
        </div>
      )}

      {items.length === 0 && !submitted && (
        <NotFoundItems />
      )}
      {items.length > 0 && (
        <Pagination
          pageQuantity={pager.countPages}
          currentPage={page}
          pageChangeHandler={handlePagerChange}
        />
      )}
    </div>
  );

  const mobileContent = (
    <>
      <SimpleSearchPanel placeholder="Buscar" handleSubmit={handleSearch} />

      {items.length > 0 && (
        <div className="business-table-table">
          <div className="business-table-top-table">
            <div className="business-table-top-total">
              TOTAL{" "}
              <span className="business-table-top-total-bold">
                ({pager.count})
              </span>
            </div>
            <div className="business-table-top-sort">
              <div
                data-automation-id="business-table-sort"
                className={
                  filters?.ordering?.includes("created_at")
                    ? "business-table-top-sort business-table-active-filter"
                    : "business-table-top-sort"
                }
                onClick={() => {
                  handleFiltersChangeOrdering(
                    "ordering",
                    filters.ordering === "-created_at"
                      ? "created_at"
                      : "-created_at"
                  );
                  resetOrdering();
                }}
              >
                {filters.ordering === "created_at" ? (
                  <React.Fragment>
                    Menos recientes{" "}
                    <i className="icon-chevron business-table-top-icon"></i>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {" "}
                    Más recientes{" "}
                    <i className="icon-chevron-down business-table-chevron-down business-table-top-icon"></i>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <CompanyTable
            businesses={items}
            filtersApplied={filters.search.length !== 0}
            headers={header}
            headerClickHandler={headerOrderingHandler}
            handleClick={goToCompanyDetailsPage}
          />
        </div>
      )}

      {items.length === 0 && !submitted && filters.search.length !== 0 && (
        <NotFoundItems />
      )}
    </>
  );

  const tableContent = isMobile ? mobileContent : desktopContent;

  const noproductcontent = (
    <div className="business-table-no-business-container">
      <p className="business-table-no-business-title">
        Aún no tienes empresas creadas.
      </p>
      <p className="business-table-no-business-subtitle">
        ¡Crea tu primera empresa!
      </p>

      <div
        className="business-table-no-business-button"
        onClick={() => history.push("/admin/businesses/add")}
      >
        CREAR
      </div>
    </div>
  );

  const companyContent = noCompanies ? noproductcontent : tableContent;

  const content = (
    <div className="business-content-container">
      {loading ? <Loader /> : companyContent}
    </div>
  );

  return (
    <div className="business-container">
      <AdminLayout
        headerTitle={i18n.t("Empresas")}
        headerOptions={headerOptions}
        content={content}
        goBackFunc={goBack}
        navHidden={false}
      ></AdminLayout>
      <MainNotification
        label={"Tu producto se actualizó de manera exitosa."}
        open={false}
      ></MainNotification>
    </div>
  );
};

export default BusinessesView;
