import i18n from "i18n";

class Business {
  id = null;
  name = null;
  business_name = null;
  nif = null;
  type = null;
  country = null;
  region = null;
  state = null;
  locality = null;
  currency = null;
  logo = null;
  address = null;
  phone = null;
  email = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }

 
}

export { Business };
