import React, { useState } from "react";
import RelocationTableItem from "./TableItem";
import RelocationTableItemMobile from "./TableItemMobile";
import { isMobile } from "../../../../../../helpers/Mobile";

import "./styles.scss";
const RelocationOrderTable = ({
  relocationOrders,
  headers,
  headerClickHandler,
  handleClickRelocationOrder,
  handleClickDraft
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState(null);


  const handleSelectOne = (selectedNew) => {
    if (selected === selectedNew) {
      setSelected(null);
    }
    setSelectAll(false);
  };

  // let isMobile = true
  const table = (
    <div
      className="relocation-order-table"
      data-automation-id="relocation-order-table"
    >
      <div className="relocation-order-table-header">
          {Object.keys(headers).map((headerKey, index) => {
            let header = headers[headerKey]
            return (
              <div
                key={header.name}
                data-automation-id={`sort-by-${header.name}`}
                onClick={() => {
                  headerClickHandler(header.name);
                }}
                data-filter-name={header.name}
                className={`relocation-order-table-header-item table-header-${header.name}`}
              >
                {header.title}{" "}
                <span
                  className={`relocation-order-table-header-icon-span ${
                    header.active ? "active" : ""
                  }  ${header.direction ? "" : "down"}`}
                >
                  {" "}
                  <i className="icon-iconos_flecha-ordenar"></i>
                </span>
              </div>
            );
          })}
          <div className="table-header-action"></div>
      </div>
      <div className="relocation-order-table-content">
        {relocationOrders.map((relocationOrder) => {
          return (
            <div className="relocation-order-table-content-item" key={relocationOrder.id}>
              <RelocationTableItem
                relocationOrder={relocationOrder}
                isSelected={
                  selected === relocationOrder.id || selectAll
                }
                handleClick={relocationOrder.status === 'DRA' ? handleClickDraft : handleClickRelocationOrder}
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  const tableMobile = (
    <div className="relocation-order-table-mobile" data-automation-id="product-relocation-order-table">
      <div className="relocation-order-table-content">
        {relocationOrders.map((relocationOrder) => {
          return (
            <div className="relocation-order-table-content-item" key={relocationOrder.id}>
              <RelocationTableItemMobile
                relocationOrder={relocationOrder}
                isSelected={
                  selected === relocationOrder.id || selectAll
                }
                handleSelect={handleSelectOne}
                handleClick={relocationOrder.status === 'DRA' ? handleClickDraft : handleClickRelocationOrder}
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div className="relocation-order-table-container">{isMobile ? tableMobile : table}</div>
  );
};

export default RelocationOrderTable;
