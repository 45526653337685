import "./GuardActivityViewMobile.scss";
import i18n from "i18n";
import InfiniteScroll from "react-infinite-scroll-component";
import { useStore } from "store/context";
import useWindowSize from "hooks/useWindowSize";
import { useAlerts } from "hooks/useAlerts";
import { CircularProgress, Container } from "@mui/material";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GuardItem from "shared/components/guard/guard-item/GuardItem";
import GuardItemDetail from "shared/components/guard/guard-item-detail/GuardItemDetail";
import DevicesActivationButton from "components/MainApp/organisms/DevicesActivationButton/DevicesActivationButton";
import ModalNewAlert from "components/MainApp/organisms/Modals/ModalNewAlert/ModalNewAlert";
import ModalAlerts from "components/MainApp/organisms/Modals/ModalAlerts/ModalAlerts";
import FilterAlerts from "components/MainApp/organisms/FiltersAlerts";
import alarmIcon from "assets/images/alarm-notification-icon.svg";
import alarmIconWhite from "assets/images/alarm-notification-icon-white.svg";
import notAlertsIlustration from "assets/images/not-alerts-ilustration.svg";

const GuardActivityViewMobile = () => {
  const { isMobile } = useWindowSize();
  const [{ alertList }] = useStore();
  const {  alerts, fetch, modals, filters, pagination } = useAlerts();

  const content = (
    <>
      <Container
        id="infinite-scroll-alerts"
        className="guard-view-mobile"
        style={{ display: alerts.selected ? "none" : "block" }}
      >
        <div className="guard-view-mobile__sub-header">
          <DevicesActivationButton />
          <FilterAlerts filters={filters.list} setFilters={filters.set} />
        </div>

        {alerts.list.length > 0 ? (
          <section>
            <p>Total de alertas ({alerts.total})</p>
            <InfiniteScroll
              dataLength={alerts.list.length}
              next={fetch.more}
              hasMore={pagination.hasMore}
              loader={<CircularProgress />}
              scrollableTarget="infinite-scroll-alerts"
              scrollThreshold={0.9}
            >
              {alerts.list.map(alert => (
                <GuardItem
                  key={alert.id}
                  alert={alert}
                  setAlertSelected={alerts.setSelected}
                />
              ))}
            </InfiniteScroll>
          </section>
        ) : (
          !fetch.isLoading && (
            <section className="not-alerts">
              <img src={notAlertsIlustration} />
              <p>No se han registrado nuevas alertas</p>
            </section>
          )
        )}
      </Container>

      {alerts.selected && (
        <GuardItemDetail
          alert={alerts.selected}
          setAlert={alerts.setSelected}
          reloadAlerts={fetch.reload}
        />
      )}
    </>
  );

  const headerOptions = [
    {
      icon: "",
      name: "notifications-alerts",
      handler: () => {
        if(alertList.length > 0) {
          modals.alerts.setView(true)
        }
      },
      tooltip: "Notificaciones de alertas",
      ic: isMobile ? alarmIconWhite : alarmIcon,
      count: alertList.length
    },
  ];

  return (
    <>
      <AdminLayout
        headerTitle={i18n.t("GUARD")}
        content={content}
        headerOptions={headerOptions}
        navHidden={false}
      />

      {modals.newAlert.view && (
        <ModalNewAlert
          isOpen={modals.newAlert.view}
          setIsOpen={modals.newAlert.setView}
          alert={alerts.new}
          setAlertSelected={alerts.setSelected}
        />
      )}

      {modals.alerts.view && (
        <ModalAlerts
          isOpen={modals.alerts.view}
          setIsOpen={modals.alerts.setView}
          setAlertSelected={alerts.setSelected}
        />
      )}

      {fetch.isLoading && <Loader />}
    </>
  );
};

export default GuardActivityViewMobile;
