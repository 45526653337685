import { isMobile } from "helpers/Mobile";
import React from "react";
import { Tooltip } from "react-tippy";

import default_image from "assets/images/default_image.png";


import "./styles.scss";

const tooltipStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
};

const statusMap = {
  INT: "Integrado",
  NOT: "No Integrado",
  PEN: "Pendiente de Integración",
};

const AppTableItem = ({ business, handleClick }) => {
  const columns = ["image", "name", 'document', 'sector', 'country', 'email', 'phone'];

  const renderColumn = (key) => {
    return (
      <div
        className={`business-table-item-${key} business-table-item`}
        data-business-id={key}
        onClick={() => handleClick(business.id)}
        key={key}
      >
        <Tooltip
          html={
            <div style={tooltipStyle}>
              {key === "status" ? statusMap[business[key]] : business[key]}
            </div>
          }
          followCursor={true}
          position="left"
          offset={25}
          disabled={["image"].includes(key)}
        >
          {key === "image" && <img src={business.image || default_image} />}
          {key !== "image" && <p>{business[key]}</p>}
        </Tooltip>
      </div>
    );
  };

  return (
    <div
      className="business-table-item-wrapper"
      data-automation-id="business-item-wrapper"
    >
      {!isMobile && (
        <div className="business-table-item-container" data-automation-id="business-item">
          {columns.map((column_key) => {
            return renderColumn(column_key);
          })}
          <div
            className={`business-table-item-button business-table-item`}
            data-automation-id="business-button"
            data-business-id={business.id}
            onClick={() => handleClick(business.id)}
            key={"button-2"}
          >
            <i className="icon-chevron"></i>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="business-table-item-container-mobile"  onClick={() => handleClick(business.id)} >
          <div className="business-table-item-column-mobile">
            <div className="business-logo">
              <img src={business.image || default_image} />
            </div>
          </div>
          <div className="business-table-item-column-mobile">

            <div className="business-table-item-row-mobile">
              <p className="header">{business.name} | <span>{business.rubro}</span></p>
            </div>

            <div className="business-table-item-row-mobile">
              <p className="body">Documento: <span>{business.document}</span></p>
            </div>
            <div className="business-table-item-row-mobile">
              <p className="body">Ubicación: <span>{business.location}</span></p>
            </div>
            <div className="business-table-item-row-mobile">
              <p className="body">Teléfono: <span>{business.phone}</span></p>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default AppTableItem;
