import React, { useEffect } from "react";
import GenericTableItem from "./GenericTableItem";
import Icon from "components/MainApp/atoms/Icon/icon";
import { isMobile } from "helpers/Mobile";

import "./styles.scss";

const GenericTable = ({ columns, items, totalItems, handleClick, showMessageNoneResult = true }) => {
  useEffect(() => {
    if(Array.isArray(columns)) return;
  }, [columns])

  const renderHeaderItem = (header) => {
    return (
      <div
        data-automation-id={`header-${header.name}`}
        key={`header-${header.name}`}
        className={`generic-table-header ${header.pill && "pill"}`}
      >
        {header.title}
      </div>
    );
  };

  const table = (
    <React.Fragment>
      {(totalItems || totalItems === 0) &&<div className="generic-table-total">
        Total <span>({totalItems})</span>
      </div>}
      <div
        className="generic-table-desktop generic-table"
        data-automation-id="table"
      >
        {!isMobile && (
          <div className="generic-table-header-wrapper">
            {Array.isArray(columns) && columns.map((header) => renderHeaderItem(header))}
            {!Array.isArray(columns) && Object.keys(columns).map((key) => renderHeaderItem(columns[key]))}

            <div className="generic-table-header-button"></div>
          </div>
        )}
        <div className="generic-table-content">
          {items?.map((item) => {
            return (
              <div className="generic-table-content-item" key={item.id}>
                <GenericTableItem
                  columns={Array.isArray(columns) ? columns : Object.keys(columns).map((key) => columns[key]) }
                  item={item}
                  handleClick={handleClick}
                />
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );

  const noitems = (
    <React.Fragment>
      {
        showMessageNoneResult &&
          <div className={"generic-table-no-items"}>
            <Icon name="apps" fill="#3C7AF5" width={87} height={65}></Icon>
            <h1>Aún no tienes resultados</h1>
          </div>
      }
    </React.Fragment>
  );

  return (
    <div className="generic-table-container">
      {items.length > 0 ? table : noitems}
    </div>
  );
};

export default GenericTable;
