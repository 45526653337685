import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";
import MainDragAndDropImg from "components/MainApp/atoms/Forms/DragAndDropImage";

import "./styles.scss";
import SelectAutocomplete from 'shared/components/autocomplete';

export class AddBusinessStep1 extends React.Component {
  render() {
    const { business, handleChange, handleSelect, handleContinue, handleChangeImg, handleDeleteImg, enableContinue, error, countries, regions, provinces, cities } = this.props;
    const stepContent = (
      <form onSubmit={handleContinue}>
      <div className="add-business-wizard-step-one-content">
        <div className="add-business-wizard-step-one-field" data-automation-id="input-name">
          <MainInput
            label={"Nombre"}
            required={true}
            id={"business-name"}
            name="name"
            value={business ? business.name : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.name && error.name}
          />
        </div>

        <div className="add-business-wizard-step-one-field" data-automation-id="input-business_name">
          <MainInput
            label={"Razon social"}
            required={true}
            id={"business-business_name"}
            name="business_name"
            value={business ? business.business_name : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.business_name && error.business_name}
          />
        </div>

        <div className="add-business-wizard-step-one-field" data-automation-id="input-dccument">
          <MainInput
            label={"Documento"}
            required={true}
            id={"business-document"}
            name="document"
            value={business ? business.document : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.document && error.document}
          />
        </div>

        <div className="add-business-wizard-step-one-field" data-automation-id="input-sector">
          <MainInput
            label={"Rubro"}
            required={false}
            id={"business-sector"}
            name="sector"
            value={business ? business.sector : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.sector && error.sector}
          />
        </div>
        <div className="add-business-wizard-step-one-field" data-automation-id="input-country">
          <SelectAutocomplete
            id="business-country"
            name="country"
            label={"País"}
            options={countries}
            placeholder={"País"}
            selected={business ? business.country : {}}
            required={true}
            error={error.country && error.country}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "country")
            }
          />
        </div>
        <div className="add-business-wizard-step-one-field" data-automation-id="input-region">
          {business?.country?.value === 'ES' && (
            <SelectAutocomplete
              id="business-region"
              name="region"
              label={"Comunidad"}
              options={regions}
              placeholder={"Comunidad"}
              selected={business ? business.region : {}}
              required={true}
              disabled={!business || !business.country || business.country === ""}
              error={error.region && error.region}
              onChange={(id, selectedOption, target) =>
                handleSelect(selectedOption, "region")
              }
            />
          )}
        </div>
        <div className="add-business-wizard-step-one-field" data-automation-id="input-state">
          {business?.country?.value === 'ES' && (
            <SelectAutocomplete
              id="business-state"
              name="state"
              label={"Provincia"}
              options={provinces}
              placeholder={"Provincia"}
              selected={business ? business.state : {}}
              disabled={!business || !business.region || business.region === ""}
              required={true}
              error={error.country && error.country}
              onChange={(id, selectedOption, target) =>
                handleSelect(selectedOption, "state")
              }
            />
          )}
        </div>
        <div className="add-business-wizard-step-one-field" data-automation-id="input-locality">
          {business?.country?.value === 'ES' && (
            <SelectAutocomplete
              id="business-locality"
              name="locality"
              label={"Municipio"}
              options={cities}
              placeholder={"Municipio"}
              selected={business ? business.locality : {}}
              disabled={!business || !business.state || business.state === ""}
              required={true}
              error={error.country && error.country}
              onChange={(id, selectedOption, target) =>
                handleSelect(selectedOption, "city")
              }
            />
          )}
        </div>
        <div className="add-business-wizard-step-one-field" data-automation-id="input-currency">
          <MainDropdownInput
            required={true}
            id={"business-currency"}
            name="Moneda"
            selectedOption={business ? business.currency : ''}
            handleSelection={(selectedOption) => handleSelect(selectedOption, 'currency')}
            maxLength={50}
            options={[
              {
                label: 'ARS',
                value: 'ARS',
                id: 'ARS'
              },
              {
                label: 'EUR',
                value: 'EUR',
                id: 'EUR'
              }
            ]}
            placeholder={'Moneda'}
            label={"Moneda"}
            error={error.currency && error.currency}
          />
        </div>

        <div className="add-business-wizard-step-one-field" data-automation-id="input-currency">
           <div className="add-business-wizard-image-label" data-automation-id="input-logo">
          Si lo deseas, agrega una imagen para identificar tu producto.
          </div>
          <MainDragAndDropImg
            name="image"
            handleChange={handleChangeImg}
            handleDelete={handleDeleteImg}
            file={business.image}
            error={error?.image}
            isSmall={true}
          />
        </div>

        <div className={"add-business-wizard-step-one-action-wrapper"}>
          <button type="submit" className={`add-business-wizard-step-one-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-business-wizard-step-one-container">
        <Step title="Ingresa información de la empresa" content={stepContent}></Step>
      </div>
    );
  }
}

export default AddBusinessStep1;
