import React from "react";
import PropTypes from "prop-types";
import i18n from "i18n";

import "./styles.scss";
export class MainMoneyInput extends React.Component {
  constructor(props) {
    super(props);
    this.formatter = new Intl.NumberFormat("es-AR");

    this.state = {
      focused: false,
      value: this.formatNumber(this.props.value.replace(".", ",")),
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    value = this.formatNumber(value);

    this.setState({
      value,
    });

    e.target.value = value.replace(/\./g, "").replace(",", ".");
    if (this.props.handleChange) this.props.handleChange(e);
  };

  formatNumber = (value) => {
    if (value.substr(-1) === ".") {
      value = value.slice(0, -1) + ",";
    }
    if (value) {
      if (
        !isNaN(value.replace(/\./g, "").replace(",", ".")) &&
        value.substr(-1) !== ","
      ) {
        const value_split = value
          .replace(/\./g, "")
          .replace(",", ".")
          .split(".");
        const decimal_part = value_split.length ? value_split[1] : null;
        value = this.formatter.format(
          Number(value.replace(/\./g, "").replace(",", ".")).toFixed(2)
        );
        if (decimal_part === "0" || decimal_part === "00") {
          value += "," + decimal_part;
        }
      }
    }

    return value;
  };

  handleBlur = (e) => {
    e.preventDefault();
    this.setState({
      focused: false,
    });
    if(this.props.handleBlur) this.props.handleBlur(e);
  };

  handleFocus = (e) => {
    e.preventDefault();
    this.setState({
      focused: true,
    });
  };

  showInputHanlder = () => {
    this.setState({
      focused: true,
    });
  };

  render() {
    const {
      label,
      required,
      type = "text",
      name,
      disabled,
      error,
      maxLength,
    } = this.props;

    return (
      <div className="main-money-input-container">
        {!this.state.focused &&
          (this.state.value === undefined || this.state.value === "") && (
            <div className="main-money-input-label-placeholder">
              {label}{" "}
              {required && (
                <span className="main-money-input-required-label">*</span>
              )}
            </div>
          )}
        <div
          className={`main-money-input-wrapper ${
            this.state.focused ? "focused" : ""
          } ${disabled ? "disabled" : ""} ${error ? "error" : ""}`}
        >
          <React.Fragment>
            {(this.state.focused ||
              (this.state.value !== undefined && this.state.value !== "")) && (
              <div
                className={`main-money-input-label ${
                  this.state.focused ? "focused" : ""
                }  ${error ? "error" : ""}`}
              >
                {label}{" "}
                {required && (
                  <span
                    className={`main-money-input-required-label ${
                      this.state.focused ? "focused" : ""
                    }  ${error ? "error" : ""}`}
                  >
                    *
                  </span>
                )}
              </div>
            )}
            <div className="main-money-input-input-wrapper">
              {(this.state.focused ||
                (this.state.value !== undefined &&
                  this.state.value !== "")) && (
                <div
                  className={`money-sign ${
                    this.state.focused ? "focused" : ""
                  }  ${error ? "error" : ""}`}
                >
                  {i18n.t("currencySymbol")}
                </div>
              )}
              <input
                inputMode="decimal"
                type={type}
                value={this.state.value}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                id={name}
                name={name}
                data-automation-id="input"
                maxLength={maxLength}
              ></input>
            </div>
          </React.Fragment>
        </div>

        <div className="main-money-input-error">{error}</div>

        {disabled && <div className="main-money-input-overlay"></div>}
      </div>
    );
  }
}

MainMoneyInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default MainMoneyInput;
