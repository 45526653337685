import React from "react";
import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";
import Icon from "components/MainApp/atoms/Icon/icon";
import BarcodeImg from "./CodigoBarras.svg";
import "./styles.scss";
import { isMobile } from "helpers/Mobile";
import { useHistory } from "react-router-dom";

const Item = ({
  tag,
  epc,
  handleDelete,
  handleSelect,
  selected,
  type,
  message = null,
  tagsInMov = [],
  reprint,
}) => {
  const history = useHistory();
  return (
    <div className="tag-container">
      <div className={"left-container"}>
        {handleSelect && (
          <div className="tag-selector" onClick={() => handleSelect(tag)}>
            <Checkbox checked={selected} />
          </div>
        )}
        <div className="tag">
          <div className={"tag-number"}>
            <div className="tag-info">
              {tag.tag} {tag.type}
            </div>
            <div className="icon-reprint" onClick={() => { history.push(`/admin/tags/reprint/${tag.tag}`); }}>
              {reprint && !isMobile &&(
                <Icon className="icon-reprint"
                  name={"dispositivos"}
                  fill={"#3C7AF5"}
                  width={23}
                  height={27}
                />
              )}
            </div>
          </div>
          {epc && (<div className={"tag-rfid"}>
            <div>
              {epc}
            </div>
            <div className="icon-reprint" onClick={() => {history.push(`/admin/tags/reprint/${tag.tag}`);}}>
              {reprint && isMobile && (
                <Icon className="icon-reprint"
                  name={"dispositivos"}
                  fill={"#3C7AF5"}
                  width={23}
                  height={27}
                />
              )}
            </div>
          </div>)}
          {tag.barcode && (
            <>
              <img src={BarcodeImg} />
              <div className={"barcode"}>{tag.barcode}</div>
            </>
          )}
          {tagsInMov.includes(tag.tag) && (
            <div className="note_process_order">Este tag está en una orden en proceso</div>
          )}
        </div>
      </div>

      {message && (
        <div>
          <p className="pmessage">{message}</p>
        </div>
      )}

      {handleDelete && (
        <div onClick={() => handleDelete(tag)}>
          <Icon name="trash_can" width={20} height={20} fill={"#3C7AF5"} />
        </div>
      )}
      {type === "verification" &&
        message === null &&
        selected &&
        !handleSelect &&
        !handleDelete && (
          <Icon name="check" width={20} height={20} fill={"#62d862"} />
        )}
      {type === "verification" &&
        message === null &&
        selected === false &&
        !handleSelect &&
        !handleDelete && (
          <Icon name="close" width={20} height={20} fill={"red"} />
        )}
    </div>
  );
};

export default Item;
