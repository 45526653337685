export const filterTypeMap = {
  "Estado del producto": {
    name: "enabled",
    type: "twoOption",
    default_value: undefined,
  },
  "Ordenar por": {
    name: "ordering",
    type: "twoOption",
    default_value: "-created_at",
  },
};

export const filterDataSource = [
  {
    type: "twoOption",
    options: [
      {
        selected: false,
        label: "Habilitado",
        value: true,
      },
      {
        selected: false,
        label: "Inhabilitado",
        value: false,
      },
    ],
    label: "Estado del producto",
    firstOptionLabel: "Todos",
    name: "enabled",
  },
];