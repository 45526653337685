import React from "react";
import PropTypes from "prop-types";
import MainButton from "../../atoms/RoundedButton";

import { Link } from "react-router-dom";

import "./styles.scss";

export const LoadFinishedGeneral = ({
  children,
  title,
  boldButtonLabel,
  buttonLabel,
  boldButtonLink = "/admin/products/load",
  buttonLink = "/admin/products",
  boldButtonFunc,
}) => {
  const checkRefresh = (link) => {
    if(window.location.pathname === link){
      window.location.reload()
    }
  };

  return (
    <div className="load-finished-general-container">
      <i className="load-finished-general-icon icon-iconos_succes"></i>
      <div className="load-finished-general-title">{title}</div>
      <div className="load-finished-general-subtitle">{children}</div>
      <div className="load-finished-general-button">
        {!boldButtonFunc && (
          <Link to={boldButtonLink} data-automation-id="load-more-button">
            <MainButton
              legend={boldButtonLabel}
              state="enabled"
              handleClick={boldButtonFunc}
            ></MainButton>
          </Link>
        )}

        {boldButtonFunc && (
          <MainButton
            legend={boldButtonLabel}
            state="enabled"
            handleClick={boldButtonFunc}
          ></MainButton>
        )}
      </div>
      <div className="load-finished-general-link">
        <Link to={buttonLink} onClick={() => checkRefresh(buttonLink)}>
          <div
            className="load-finished-general-link"
            data-automation-id="go-to-product-list"
          >
            {buttonLabel}
          </div>
        </Link>
      </div>
    </div>
  );
};

LoadFinishedGeneral.propTypes = {
  content: PropTypes.any,
  title: PropTypes.string,
  goBackFunc: PropTypes.func,
};

export default LoadFinishedGeneral;
