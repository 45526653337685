import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";


import "./styles.scss";
export class AddBusinessNodeStepTwo extends React.Component {
  render() {
    const { businessnode, handleChange, goBack, handleContinue, enableContinue, error } = this.props;
    const stepContent = (
      <form onSubmit={handleContinue}>
      <div className="add-businessnode-wizard-step-two-content">
        <div className="add-businessnode-wizard-step-two-field" data-automation-id="input-owner">
          <MainInput
            label={"Responsable"}
            required={true}
            id={"businessnode-owner"}
            name="owner"
            value={businessnode ? businessnode.owner : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.owner && error.owner}
            />
        </div>
        <div className="add-businessnode-wizard-step-two-field" data-automation-id="input-phone">
          <MainInput
              label={"Teléfono"}
              required={true}
              id={"businessnode-phone"}
              name="phone"
              value={businessnode ? businessnode.phone : ''}
              handleChange={handleChange}
              maxLength={100}
              error={error.phone && error.phone}

            />
        </div>
        <div className="add-businessnode-wizard-step-two-field" data-automation-id="input-email">
          <MainInput
              label={"Email"}
              required={true}
              id={"businessnode-email"}
              name="email"
              value={businessnode ? businessnode.email : ''}
              handleChange={handleChange}
              maxLength={100}
              error={error.email && error.email}
            />
        </div>
        <div className={"add-businessnode-wizard-step-two-action-wrapper"}>
          <button type="submit" className={`add-businessnode-wizard-step-two-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-businessnode-wizard-step-two-container">
        <Step title="Contacto" goBackFunc={goBack} content={stepContent}></Step>
      </div>
    );
  }
}

export default AddBusinessNodeStepTwo;
