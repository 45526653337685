import React from "react";
import CategoryTableItem from "./TableItem";

import "./styles.scss";
export class CategoryTable extends React.Component {
  render() {
    const { categories, readOnly } = this.props;
    return (
      <div className="category-table-container">
        <div className="category-table-content">
            {categories.map((category) => {
              return <div className="category-table-content-item" key={category.id}>
                        <CategoryTableItem category={category}
                          handleEdit={this.props.handleEdit} handleDelete={this.props.handleDelete}
                          readOnly={readOnly} handleClick={this.props.handleClick}
                        />
                    </div>;
            })}
        </div>
      </div>
    );
  }
}

CategoryTable.propTypes = {};

export default CategoryTable;