import React from "react";
import PropTypes from "prop-types";
import Step from "../../../../atoms/Wizard/Step";
import MainInput from "../../../../atoms/Forms/MainInput";
import MainTextArea from "../../../../atoms/Forms/MainTextArea";
import MainButtonInput from "../../../../atoms/Forms/MainButtonInput";

import "./styles.scss";
export class ProdStepOne extends React.Component {
  render() {
    const { product, handleChange, selectCategory, removeCategory, errors } = this.props;

    const stepContent = (
      <div className="product-step-one-content">
        <div className="prod-step-one-field" data-automation-id="input-name">
          <MainInput
            label={"Nombre del producto"}
            required={true}
            id={"product-name"}
            name="name"
            value={product.name}
            handleChange={handleChange}
            maxLength={100}
            error={errors.name}
          />
        </div>
        <div className="prod-step-one-field" data-automation-id="input-brand">
          <MainInput
            label={"Marca"}
            required={true}
            id={"product-brand"}
            name="brand"
            value={product.brand}
            handleChange={handleChange}
            maxLength={50}
            error={errors.brand}
          />
        </div>
        <div
          className="prod-step-one-field"
          data-automation-id="input-description"
        >
          <MainTextArea
            label={"Descripción"}
            id={"product-description"}
            name="description"
            value={product.description}
            handleChange={handleChange}
            error={errors.description}
          />
        </div>
        <div
          className="prod-step-one-field"
          data-automation-id="input-category"
        >
          <MainButtonInput
            label={"Categoría"}
            id={"product-category"}
            value={product.category ? product.category.name : null}
            handleClick={selectCategory}
            error={errors.category}
          />

          {product.category && (
            <span className="remove-product-category" onClick={removeCategory}>Remover categoría</span>
          )}
        </div>
      </div>
    );

    return (
      <div className="product-step-one-container">
        <Step title="Ingresa los datos" content={stepContent}></Step>
      </div>
    );
  }
}

ProdStepOne.propTypes = {
  handleChange: PropTypes.func
};

export default ProdStepOne;
