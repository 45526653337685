import { Button, styled } from "@material-ui/core";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import style from "./upload-button.module.scss"

const UploadButton = ({handleFileUpload}) => {

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });


    return (
        <div>
            <Button
                className={style["color-upload"]}
                component="label"
                role={undefined}
                tabIndex={-1}
                startIcon={<FileUploadOutlinedIcon />}
            >
                importar archivo
                <VisuallyHiddenInput
                    type="file"
                    accept=".xlsx,.ods"
                    onChange={handleFileUpload}
                    multiple
                />
            </Button>
        </div>
    );

}


export default UploadButton;
