import { FirebaseYaxDevicesService } from "services/FirebaseYaxDevicesService";
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState } from "react";
import { useEffect } from "react";
import { Tooltip } from "react-tippy";
import "./TabYaxDevices.scss";
import DeviceModal from "../DeviceModal/DeviceModal";

const TabYaxDevices = (props) => {
    const [device, setDevice] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);


    const tooltipStyle = {
        background: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029",
        height: "45px",
        padding: "0 15px",
        color: "#494F66",
        fontFamily: "Roboto-Light",
        fontSize: "18px",
        borderRadius: "15px",
        display: "flex",
        alignItems: "center",
    };

    const columns = [
        { id: 'id', label: 'Id' },
        { id: 'name', label: 'Name' },
        { id: 'url', label: 'Url' },
        { id: 'urlToken', label: 'Url Token' },
        { id: 'userEmail', label: 'User Email' },
        { id: 'userPass', label: 'User Pass' },
        { id: 'port', label: 'Port' },
        { id: 'ip', label: 'ip' },
        { id: 'serialReader', label: 'Serial Reader' },
    ];

    const [devices, setDevices] = useState([]);

    useEffect(() => {

        const fyds = FirebaseYaxDevicesService.getInstance();
        fyds.getDevices().then(data => {
            setDevices(data)
        }).catch(error => {

        });
        //setDevices(fyds.getDevices())
    }, [])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleOpenModal = (device) => {
      setDevice(device);
      setIsModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const handleSaveDevice = (editedDevice) => {
      const fyds = FirebaseYaxDevicesService.getInstance();
      const updateData = {
        ip: editedDevice.ip || null,
        name: editedDevice.name || null,
        url: editedDevice.url || null,
        port: editedDevice.port || null,
        serialReader: editedDevice.serialReader || null,
        urlToken: editedDevice.urlToken || null,
        userEmail: editedDevice.userEmail || null,
        userPass: editedDevice.userPass || null,
      };

      fyds.updateDevice(editedDevice.id,updateData)
          .then(() => {
            const newDevices = devices.map(d => {
              if (d.id === editedDevice.id) {
                return { ...d, ...updateData };
              }
              return d;
            });
            setDevices(newDevices);
          })
          .catch(error => console.error("Error al actualizar dispositivo", error));

      setIsModalOpen(false);
    };

    return (
      <>
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {devices.length > 0 && devices
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow onClick={() => handleOpenModal(row)} hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    className="celltable-devices"
                                                    sx={{
                                                        maxWidth: 100,
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis'
                                                    }} key={column.id} align={column.align}
                                                    title={value}
                                                    >

                                                      {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={devices?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
        <DeviceModal
            device={device}
            open={isModalOpen}
            onClose={handleCloseModal}
            onSave={handleSaveDevice}
        />
      </>
    )
}

export default TabYaxDevices;