import React from "react";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainTextArea from "components/MainApp/atoms/Forms/MainTextArea";
import MultiSelectInput from "components/MainApp/atoms/Forms/MultiSelectInput";

import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";
import permisos, {specificScopes} from "./permisos";

import "./styles.scss";

const RoleForm = ({
  role,
  handleSelect,
  handleSelectMobile,
  handleChange,
  handleClose,
  handleOpen,
  handleSubmit,
  errors,
  continueEnabled,
}) => {
  const selected = [];

  const mapPermissions = (permissions, initialValue) => {
    return permissions.map(permission => {
      let value = initialValue;
      if(initialValue === "Seleccionado") return { id: permission, value };

      if (permission.includes("read_")) {
        value = "Ver";
      }else if (permission.includes("create_")) {
        value = "Crear";
      } else if (permission.includes("update_")) {
        value = "Actualizar";
      } else if (permission.includes("delete_")) {
        value = "Eliminar";
      }
      return { id: permission, value };
    });
  }

  /**
   * Treatment special for roles with multiple permissions in mobile
   *
   * @param {*} scopeName name of the scope
   * @param {*} permissions permissions of the role
   * @param {*} perm permissions list (permisos | specificScopes)
   * @returns
   */
  const multiSelectPermissions = (scopeName, permissions, perm, initialValue = "") => {
    // si no es mobile devolvemos los permisos sin tratar
    if(!handleSelectMobile) return permissions;
    if(!permissions || permissions.length === 0) return [];

    const scope = perm?.find(p => p.name === scopeName);
    if(scope){
      // si es initialValue "" (permisos) filtramos los permisos que no sean de los scopes especificos
      if(initialValue === ""){
        permissions = permissions.filter(permission => !specificScopes.some(s => permission.includes(s.id)));
      }
      if(permissions.some(permission => permission.includes(scope.id))){
        permissions = permissions.filter(permission => permission.includes(scope.id));
        return mapPermissions(permissions, initialValue);
      }else{
        return [];
      }
    }else{
      return permissions;
    }
  }

  const renderMultiSelect = (scope) => {
    const items = [
      {
        id: `read_${scope.id}`,
        value: "Ver",
      },
      {
        id: `create_${scope.id}`,
        value: "Crear",
      },
      {
        id: `update_${scope.id}`,
        value: "Actualizar",
      },
      {
        id: `delete_${scope.id}`,
        value: "Eliminar",
      },
    ];
    return (
      <div
        className="role-form-field"
        data-automation-id={`input-scope-${scope.id}`}
        key={`input-scope-${scope.id}`}
      >
        <MultiSelectInput
          items={items.filter(i => {
            return !['read_business', 'create_business', 'create_stock',
                  'delete_stock', 'delete_orders'].includes(i.id)
          })}
          selected={multiSelectPermissions(scope.name, role.permissions, permisos)}
          label={scope.name}
          handleChange={handleSelect}
          handleChangeMobile={handleSelectMobile}
          handleOpen={handleOpen}
        ></MultiSelectInput>
      </div>
    );
  };

  const renderSpecificMultiSelect = (scope) => {
    const items = [
      {
        id: `${scope.id}`,
        value: "Seleccionado",
      },
    ];
    return (
      <div
        className="role-form-field"
        data-automation-id={`input-scope-${scope.id}`}
        key={`input-scope-${scope.id}`}
      >
        <MultiSelectInput
          items={items}
          selected={multiSelectPermissions(scope.name, role.permissions, specificScopes, "Seleccionado")}
          label={scope.name}
          handleChange={handleSelect}
          handleChangeMobile={handleSelectMobile}
          handleOpen={handleOpen}
        ></MultiSelectInput>
      </div>
    );
  };

  return (
    <div className="role-form-content">
      <h1 className="role-form-title">Ingresa los datos</h1>
      <div className="role-form-field" data-automation-id="input-name">
        <MainInput
          label={"Nombre del rol"}
          required={true}
          id={"role-name"}
          name="name"
          value={role ? role.name : ""}
          handleChange={handleChange}
          maxLength={100}
          error={errors.name && errors.name}
        />
      </div>
      <div className="role-form-field" data-automation-id="input-description">
        <MainTextArea
          label={"Descripción"}
          required={true}
          id={"role-description"}
          name="description"
          value={role ? role.description : ""}
          handleChange={handleChange}
          maxLength={100}
          error={errors.description && errors.description}
        />
      </div>
      <h1 className="role-form-title">Definir niveles de permisos *</h1>

      <table className="permisos">
        <thead>
          <tr>
            <th className="scope-th">SCOPES GENERALES</th>
            <th>LEER</th>
            <th>CREAR</th>
            <th>ACTUALIZAR</th>
            <th>ELIMINAR</th>
          </tr>
        </thead>
        <tbody>
          {permisos.map((permiso) => {
            return (
              <tr key={permiso.id} className="permiso-wrapper">
                <td className="left">
                  <p className="title">{permiso.name}</p>
                </td>
                <td className="option">
                  <Checkbox
                    handleChange={() => handleSelect(`read_${permiso.id}`)}
                    checked={role.permissions.includes(`read_${permiso.id}`)}
                    disabled={['business'].includes(permiso.id)}
                  ></Checkbox>
                </td>
                <td className="option">
                  <Checkbox
                    handleChange={() => handleSelect(`create_${permiso.id}`)}
                    checked={role.permissions.includes(`create_${permiso.id}`)}
                    disabled={['business', 'stock'].includes(permiso.id)}
                  ></Checkbox>
                </td>
                <td className="option">
                  <Checkbox
                    handleChange={() => handleSelect(`update_${permiso.id}`)}
                    checked={role.permissions.includes(`update_${permiso.id}`)}
                  ></Checkbox>
                </td>
                <td className="option">
                  <Checkbox
                    handleChange={() => handleSelect(`delete_${permiso.id}`)}
                    checked={role.permissions.includes(`delete_${permiso.id}`)}
                    disabled={['stock', 'orders'].includes(permiso.id)}
                  ></Checkbox>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <table className="permisos">
        <thead>
          <tr>
            <th className="scope-th">SCOPES ESPECIALES</th>
            <th>SELECCIONADO</th>
          </tr>
        </thead>
        <tbody>
          {specificScopes.map((permiso) => {
            return (
              <tr key={permiso.id} className="permiso-wrapper">
                <td className="left">
                  <p className="title">{permiso.name}</p>
                </td>
                <td className="option">
                  <Checkbox
                    handleChange={() => handleSelect(`${permiso.id}`)}
                    checked={role.permissions.includes(`${permiso.id}`)}
                  ></Checkbox>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>


      <div className="permisos-mobile">
       {permisos.map((permiso)=> {
           return renderMultiSelect(permiso)
       })}

      {specificScopes.map((permiso)=> {
           return renderSpecificMultiSelect(permiso)
       })}
      </div>
      <div className="role-form-submit">
        <div
          className={`role-form-submit-button ${
            continueEnabled ? "enabled" : ""
          }`}

          onClick={continueEnabled ? (e) => handleSubmit(e) : null}
        >
          {role.id ? "GUARDAR" : "CREAR ROL"}
        </div>
      </div>
    </div>
  );
};

export default RoleForm;
