import React from "react";
import PropTypes from "prop-types";
import Step from "../../../../atoms/Wizard/Step";
import DragAndDropImages from "../../../../atoms/Forms/DragAndDropImages";

import "./styles.scss";
export class ProdStepThree extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      file: null
    }
  }

  goBackFunc = () => {
    if(this.props.goBackFunc) this.props.goBackFunc(1)
  }

  render() {
    const { product, handleChangeImage, handleDelete, errors, files } = this.props;

    const stepContent = (
      <div className="product-step-three-content">
        <div className="product-step-three-title" data-automation-id="input-image">
        Si lo deseas, agrega imágenes para tu producto.
        </div>
        <DragAndDropImages
          name="image"
          handleChange={handleChangeImage}
          handleDelete={handleDelete}
          files={files}
          error={errors?.image}
          onHandleStarClick={this.props.onHandleStarClick}
          selectedStarIndex={this.props.selectedStarIndex}
          showSelectorFiles={true}
        />
      </div>
    );

    return (
      <div className="product-step-three-container">
        <Step title="Imagen" content={stepContent} goBackFunc={this.goBackFunc} ></Step>
      </div>
    );
  }
}

ProdStepThree.propTypes = {
  handleChange: PropTypes.func,
};

export default ProdStepThree;
