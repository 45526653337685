export const reducer = (state, action) => {
  switch (action.type) {
    case "setProductFilters":
      return { ...state, productFilters: action.payload };
    case "setOrderFilters":
      return { ...state, orderFilters: action.payload };
    case "setPrepOrderFilters":
      return { ...state, prepOrderFilters: action.payload };
    case "setStockFilters":
      return { ...state, stockFilters: action.payload };
    case "setCompanyFilters":
      return { ...state, companyFilters: action.payload };
    case "setRolesFilters":
      return { ...state, rolesFilters: action.payload };
    case "setAlert":
      return { ...state, alert: action.payload };
    case "setAlertList":
      return { ...state, alertList: action.payload };
    default:
      return state;
  }
};

export const reducerPersist = (state, action) => {
  switch (action.type) {
    // case 'setBusinessNode':
    //   return { ...state, businessNode: action.payload }
    default:
      return state;
  }
};
