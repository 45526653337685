import React from "react";
import MainAppGenericModal from "../../../../atoms/Modals/GenericModal";
import MainInput from "../../../../atoms/Forms/MainInput";

import "./styles.scss";

export class EditCategoryModal extends React.Component {
  render() {
    const {
      open,
      handleClose,
      category,
      handleChange
    } = this.props;

    const content = (<div className="edit-category-modal-wrapper">
      <i className="product-modal-wrapper-close icon-iconos_close2" onClick={this.props.cancel}></i>

      <div className="edit-category-modal-title">Editar categoría</div>
      <div className="edit-category-modal-input">
        <form onSubmit={this.props.confirm}>
          <MainInput value={category ? category.name : null} maxLength={50}
            handleChange={handleChange}>
          </MainInput>
        </form>
      </div>
      <div className="edit-category-modal-buttons">
        <div className="edit-category-modal-button" onClick={this.props.cancel} data-automation-id="cancel">CANCELAR</div>
        <div className="edit-category-modal-button" onClick={this.props.confirm} data-automation-id="confirm">GUARDAR</div>
      </div>
    </div>)
    return (
      <div className="edit-category-modal-container">
          <MainAppGenericModal open={open} content={content} handleClose={handleClose}></MainAppGenericModal>
      </div>
    );
  }
}

EditCategoryModal.propTypes = {
};

export default EditCategoryModal;