import React from "react";

import Step from "../../../../../../atoms/Wizard/Step";
import ProductSummary from "../../../ProductSummary";
import Icon from "../../../../../../atoms/Icon/icon"
import "./styles.scss";

export const RequestOrderStep3 = ({ dest, products, handleEditDest, handleEditProducts}) => {

    const stepContent = (
        <div className="request-order-step-three-content">
          <div className="request-order-step-three-field" data-automation-id="input-search">
            <div className="request-order-step-three-first-row">
            <div className={`request-order-step-title`}>
                <div className={`request-order-step-title-wrapper`}> ORIGEN DE ORDEN </div>
                <div className="request-order-step-three-icon-wrapper" onClick={handleEditDest}>
                  <Icon name="edit2" width={20} height={20} fill={"#3C7AF5"} />
                </div>
            </div>
            <div className={`request-order-step-dest`}>
               {dest}
            </div>
            </div>

            <div className="request-order-step-three-second-row">
              <ProductSummary
                products={products}
                edit={true}
                handleEdit={handleEditProducts}
              />
            </div>
          </div>

        </div>
      );
    return (
        <div className="request-order-step-three-container">
          <Step title="Verifica los datos" content={stepContent}></Step>
        </div>
      );
}

export default RequestOrderStep3