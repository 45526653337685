import Step from "components/MainApp/atoms/Wizard/Step";
import React from "react";

import "./styles.scss";
import SearchWithComponents from "components/MainApp/atoms/Filters/SearchWithComponents";
import ProductAddedList from "components/MainApp/organisms/Orders/RequestOrders/ProductAddedList";

export const RelOrderStep2 = ({
  resultList,
  searchValue,
  handleChange,
  products,
  totalProducts,
  goBackFunc,
  handleSelectToAddProduct,
  handleSelectedProductQuantityChange,
  handleAdd,
  handleAddProduct,
  handleDeleteAddedProduct,
  selectedProducts,
  changePage,
  pagination
}) => {
  const stepContent = (
    <div className="relocation-order-step-two-content">
      <div
        className="relocation-order-step-two-field"
        data-automation-id="input-search"
      >
        <SearchWithComponents
          placeholder={"Busca por código o nombre"}
          resultList={resultList}
          value={searchValue}
          handleChange={handleChange}
          handleSelectToAddProduct={handleSelectToAddProduct}
          handleSelectedProductQuantityChange={
            handleSelectedProductQuantityChange
          }
          handleAdd={handleAdd}
          selectedProducts={selectedProducts}
          onlyStockHidden={true}
        />

        {products.length > 0 && (
          <ProductAddedList
            total={totalProducts}
            products={products}
            edit={true}
            handleEdit={handleAddProduct}
            handleDelete={handleDeleteAddedProduct}
            changePage={changePage}
            pagination={pagination}
          ></ProductAddedList>
        )}
      </div>
    </div>
  );
  return (
    <div className="relocation-order-step-two-container">
      <Step
        goBackFunc={goBackFunc}
        title="¿Qué productos deseas reponer?"
        content={stepContent}
      ></Step>
    </div>
  );
};

export default RelOrderStep2;
