import React from "react";
import MainAppGenericModal from "../../../../atoms/Modals/GenericModal";

import "./styles.scss";

export class ErrorCategoryModal extends React.Component {

  render() {
    const { 
      open,
      handleClose,
      category
    } = this.props;

    const content = (<div className="error-category-modal-wrapper">
      <div className="error-category-modal-text"><span className="error-category-modal-text-bold">No se puede eliminar</span> <br></br> la categoría &ldquo;{category ? category.name : ''}&ldquo; ya que contiene productos asociados.</div>
      <div className="error-category-modal-buttons">
        <div className="error-category-modal-button" onClick={handleClose} data-automation-id="confirm">ENTENDIDO</div>
      </div>
    </div>)
    return (
      <div className="error-category-modal-container">
          <MainAppGenericModal open={open} content={content} handleClose={handleClose}></MainAppGenericModal>
      </div>
    );
  }
}

ErrorCategoryModal.propTypes = {
};

export default ErrorCategoryModal;
