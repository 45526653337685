import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import "./styles.scss";
import MainButton from "components/MainApp/atoms/RoundedButton";
import { Grid, Modal, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import Media from "../../Media";
import default_image from '../../../../../assets/images/default_product.png';
import HandSwipeAnimation from "shared/components/animation/hand-swipe-animation/HandSwipeAnimation";

const ProductInfo = ({
  product,
  productRelatedItems,
  imgCover,
  handleClickRecommended,
  historyProducts,
  onHistoryProductClick,
  showCover = false,
  videoImage,
  onClickCover,
}) => {
  // const mainFilesSmall = product?.item_files ?? (product?.image ? [...product.image] : [...default_image]);

  const [mainFilesSmall, setMainFilesSmall] = useState([])
  const [mainFileBig, setMainFileBig] = useState();
  const [open, setOpen] = React.useState(false);
  const [visibleHand, setVisibleHand] = useState(false);

  useEffect(() => {
    const divElement = document.getElementById('container-image');
    if (divElement) {
      if (divElement.scrollWidth > divElement.clientWidth) {
        setVisibleHand(true);
      }
    }
  }, [historyProducts])

  useEffect(() => {
    if (product) {
      if (product?.item_files.length > 0) {
        setMainFilesSmall(product?.item_files)
      } else {
        setMainFilesSmall(product?.image ? [product.image] : [default_image])
      }
      setMainFileBig(mainFilesSmall?.length > 0 ? mainFilesSmall[0] : null)
    } else {
      setMainFileBig(null);
      setMainFilesSmall([])
    }


  }, [product]);

  useEffect(() => {
    if (mainFilesSmall && mainFilesSmall.length > 0) {
      setMainFileBig(mainFilesSmall[0]);
    }
  }, [mainFilesSmall]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendEmail = () => {
    console.log("enviar email");
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const getImageProduct = (product) => {
    let image = product?.item_files[0]?.file_name;
    if (!image) {
      image = product?.image ? product.image : default_image;
    }
    return image;
  }

  return (
    <div className="container-product-info">

      {showCover && videoImage && (
        <div className="cover" onClick={onClickCover}>
          <video autoPlay loop>
            <source src={videoImage} type="video/mp4" />
            Tu navegador no soporta la etiqueta video.
          </video>
        </div>
      )}
      {product &&
        <Grid container>
          <Grid item lg={4} md={6} xs={6}>
            <div className="column-product-info">

              <Media file={mainFileBig} style="image-large" controls={true} />

              <div className="container-image" id="container-image" onClick={() => {setVisibleHand(false);}}>

                <div className="image-thumbnails" >
                  {historyProducts?.map((historyProduct, index) => (
                    <img
                      key={index}
                      src={getImageProduct(historyProduct)}
                      alt={`Thumbnail ${index + 1}`}
                      onClick={() => onHistoryProductClick(historyProduct)}
                    />
                  ))}
                </div>
                <div className="hand">
                  <HandSwipeAnimation visible={visibleHand}></HandSwipeAnimation>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={6} xs={6}>
            <div className="column-product-info">
              <h3>{product?.name}</h3>
              <p>{product?.description}</p>
              <p className="price">
                {product?.purchase_price ? `${product.purchase_price} €` : ""}
              </p>

              <div className="image-carousel">
                {mainFilesSmall?.map((file, index) => (

                  <Media
                    key={index}
                    file={file}
                    onMouseOver={() => setMainFileBig(file)}
                  />

                ))}
              </div>

              <div className="btnShare">
                <MainButton
                  legend="compartir"
                  handleClick={handleOpen}
                  state="enabled"
                />

                <Modal
                  open={open}
                  // onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <Box
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: "white",
                      padding: "1em",
                      maxWidth: "90%",
                      minWidth: "50%",
                      minHeight: "50%",
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "20px"
                    }}
                  >
                    <h2 id="simple-modal-title">Ingresa tu email</h2>

                    <MainInput
                      label={"Email"}
                      required={true}
                      id={"email"}
                      name="email"
                      maxLength={150}
                    />
                    <div className="btnContainer">
                      <span className="btnCancel">
                        <MainButton
                          legend="cancelar"
                          handleClick={handleClose}
                          state="enabled"
                        />
                      </span>
                      <span className="btnSend">
                        <MainButton
                          legend="enviar"
                          handleClick={handleSendEmail}
                          state="enabled"
                        />
                      </span>
                    </div>
                  </Box>
                </Modal>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={12} xs={12}>
            <div className="column-product-info recomendation">

              <h3>Productos recomendados</h3>
              <div className="images-related">
                {productRelatedItems?.length > 0 &&
                  productRelatedItems.map((item, index) => (
                    <div key={index} onClick={() => handleClickRecommended(item)}>
                      <img
                        src={item.item_files?.[0]?.file_name}
                        alt={item.item_files?.[0]?.file_name}
                      />
                      <h4>{item.name}</h4>
                    </div>
                  ))}
              </div>

            </div>
          </Grid>
        </Grid>
      }
    </div>
  );
};

export default ProductInfo;
