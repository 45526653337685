import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import { DeviceService } from "services/DeviceService";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";

import Panel from "components/MainApp/organisms/TogglePanel";

import "./styles.scss";

const AntennaDetailView = (props) => {
  const history = useHistory();
  const [deleteErrorModal, setdeleteErrorModal] = useState(false);
  const [deleteErrorMsg, setdeleteErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [device, setdevice] = useState(null);
  const [parentId, setparentId] = useState(null);

  useEffect(() => {
    if (!props.match.params.key) return;
    const id = props.match.params.key;
    setLoading(true);
    DeviceService.device(id).then((device) => {
      setdevice(device);
      setLoading(false);
    });

    const parent = props.location.state.parent;
    setparentId(parent);
  }, []);

  const deleteDevice = () => {
    setLoading(true);
    DeviceService.deleteDevice(device.id)
      .then((rta) => {
        history.push(`/admin/devices`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setdeleteErrorModal(true);
        setdeleteErrorMsg(i18n.t("No se puede eliminar el dispositivo"));
      });
  };

  const headerOptions = [
    {
      icon: "icon-iconos_eliminar",
      name: "add",
      handler: deleteDevice,
      tooltip: i18n.t("Eliminar Antena"),
    },
    {
      icon: "icon-iconos_editar",
      name: "editar",
      handler: () => {
        history.push({
          pathname: `/admin/devices/edit/${device.id}`,
          state: {
              type: 'antenna_edit',
              parent: parentId
          }
        });
      },
      tooltip: i18n.t("Editar Antena"),
    },
  ];

  const handleGoBack = () => {
    history.push(`/admin/devices/detail/${parentId}`);
  };

  const content = (
    <div className={"device-detail-content-wrapper"}>
      {device && (
        <Panel
          fields={device && device.getAntennaDescriptionPanelView()}
          title={i18n.t("Descripcion")}
        />
      )}
    </div>
  );
  return (
    <div className="device-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Dispositivo")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      <GenericErrorModal
        open={deleteErrorModal}
        handleClose={() => setdeleteErrorModal(false)}
        error={deleteErrorMsg}
      ></GenericErrorModal>

      {loading && <Loader />}
    </div>
  );
};

export default AntennaDetailView;
