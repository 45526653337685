import React from "react";
import Step from "components/MainApp/atoms/Wizard/Step";
import renderInput from "helpers/FormGenerator";

import "./styles.scss";

export const PrepOrderStep1 = ({
  handleChange,
  handleSelect,
  fields
}) => {


  const stepContent = (
    <div className="prep-order-step-one-content">
      {fields && fields.map(field => {
        return renderInput(field, handleSelect, handleChange, "prep-order-field")
      })}
    </div>
  );
  return (
    <div className="prep-order-step-one-container">
      <Step title="Ingresa los datos de la orden" content={stepContent}></Step>
    </div>
  );
};

export default PrepOrderStep1;
