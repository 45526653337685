import React from "react";

import Step from "components/MainApp/atoms/Wizard/Step";
import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";
import ItemTags from "components/MainApp/organisms/OrdersV3/Item-Tags";
import LocationSummary from "components/MainApp/organisms/OrdersV3/LocationSummary";

import "./styles.scss";

export const ReceptionOrderStep3 = ({
  itemsLocateds,
  itemsNoLocateds,
  handleDelete,
  handleSelect,
  allSelecteds,
  handleSelectAll,
}) => {
  const stepContent = (
    <div className="reception-order-step-three-content">
      <div className="check-all">
        <div className="all-selector" onClick={() => handleSelectAll()}>
          <Checkbox checked={allSelecteds} />
        </div>
        Todos los Productos
      </div>
      <div className="load-movement-items-list">
        {itemsNoLocateds &&
          itemsNoLocateds.map((item) => {
            return (
              <ItemTags
                key={item.sku}
                item={item}
                allSelecteds={allSelecteds}
                quantity={item.tags.length}
                expected={item.expectedUnits}
                handleSelect={handleSelect}
              />
            );
          })}
      </div>
    </div>
  );
  return (
    <div className="reception-order-step-three-container">
      {itemsLocateds &&
        itemsLocateds.map((item) => {
          return (
            <div
              key={item.code}
              className={"reception-order-step-three-location"}
            >
              <LocationSummary item={item} handleDelete={handleDelete} />
            </div>
          );
        })}

      {itemsNoLocateds.length !== 0 && (
        <Step
          title="Seleccione los productos y agregue su ubicacion"
          content={stepContent}
        ></Step>
      )}
    </div>
  );
};

export default ReceptionOrderStep3;
