const columns = [
    {
      name: 'name',
      pill: false,
      title: 'NOMBRE',
      mobileTag: false,
    },
    {
      name: 'country',
      pill: false,
      title: 'PAIS',
  
    },
    {
      name: 'locality',
      pill: false,
      title: 'MUNICIPIO',
  
    },
    {
      name: 'email',
      pill: false,
      title: 'EMAIL',
      mobileTag: 'Email:',
  
    },
    {
      name: 'phone',
      pill: false,
      title: 'TELEFONO',
      mobileTag: 'Telefono:',
  
    },
  ];


  export default columns;