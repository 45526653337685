import React from "react";
import {isMobile} from "../../../../helpers/Mobile";
// import i18n from "i18n";
import "./styles.scss";
import SVGIconComponent from '../../atoms/Icon/icon';

export const SummaryButton = ({icon, title, pending, processing, clickHandler}) => {
    return(
        <div className="summary-button-container" onClick={clickHandler}>
           <div className="summary-button-left">
                <SVGIconComponent
                className={icon}
                name={icon}
                width={'34'}
                height={'34'}
                fill={"#494F66"}
                ></SVGIconComponent>
                <div className="summary-button-title">
                    {title}
                </div>
           </div>
           <div className="summary-button-divider"></div>
           <div className="summary-button-right">
               <div className="summary-button-right-wrapper">
               {pending > 0 && <div className="summary-button-pending">
               <SVGIconComponent
                className={'alert'}
                name={'alert'}
                width={ isMobile ? '9' :'14'}
                height={isMobile ? '9' :'14'}
                fill={"#FFAF3A"}
                ></SVGIconComponent>
                <p className="summary-button-pending-text">{pending} pendiente</p>
               </div>}
               {processing > 0 && <div className="summary-button-processing">
               <SVGIconComponent
                className={'check_circle'}
                name={'check_circle'}
                width={isMobile ? '9' :'16'}
                height={isMobile ? '9' :'16'}
                fill={"#6F7DFF"}
                ></SVGIconComponent>
               <p className="summary-button-processing-text">{processing} En proceso</p>
               </div>}
               </div>
           </div>
           <div className="summary-button-chevron">
               <i className="icon-chevron-derecha"></i>
           </div>
        </div>
    )
}

export default SummaryButton;