import React from "react";
import Toggle from "../../../../atoms/Forms/Toggle";

import "./styles.scss";

export class FormEditProduct extends React.Component {
  render() {
    const { product, handleChange } = this.props;

    return (
      <div
        className="disable-product-container"
        data-automation-id="disable-product-container"
      >
          <p className="disable-product-label">
            Producto <span className="disable-product-label-span">{product.enabled ? 'habilitado' : 'inhabilitado'}</span>  para realizar operaciones
          </p>
          <div className="disable-product-toggle">
            <Toggle handleChange={handleChange} checked={product.enabled} name={'enabled'} ></Toggle>
          </div>          
      </div>
    );
  }
}

export default FormEditProduct;
