import React from "react";

import Step from "components/MainApp/atoms/Wizard/Step";
import Icon from "components/MainApp/atoms/Icon/icon";
import InputText from "components/MainApp/atoms/Forms/MainInput";
import ItemTags from "components/MainApp/organisms/OrdersV3/Item-Tags";
import DeviceSelector from "components/Functionals/DeviceSelector";

import "./styles.scss";

export const ReceptionOrderStep2 = ({
  enabledRead,
  typeRead,
  refRead,
  associateRead,
  priorityRead,
  receiveEvent,

  originalItems,
  selectedItems,
  searchValue,
  handleSearch,
  searchedValue,
  goBackFunc,
  handleDelete,
  handleDeleteAll,
  handleSend,
}) => {
  const stepContent = (
    <div className="reception-order-step-two-content">
      <DeviceSelector
        enabled={enabledRead}
        typeRead={typeRead}
        refRead={refRead}
        associateRead={associateRead}
        priorityRead={priorityRead}
        receiveEvent={receiveEvent}
        includeType={"MOB_READER"}
      />
      <div
        className="reception-order-step-two-field"
        data-automation-id="input-search"
      >
        {/* <LoadMovementItems selectedItems={selectedItems} handleSearch={handleSearch} handleDelete={handleDelete}
            handleDeleteAll={handleDeleteAll} handleSend={handleSend} goBack={goBackFunc}/> */}
        <div className="reception-order-title">
          Ingresa el nro. o escanea TagID
        </div>
        <form className="load-movement-items-input" onSubmit={handleSearch}>
          <InputText
            label="Ingresa número"
            handleChange={searchValue}
            value={searchedValue}
          />
          <Icon name="barcode" width={30} height={25} fill={"#3C7AF5"} />
        </form>
        <div className="load-movement-items-count">{originalItems.length}</div>
        <div className="load-movement-items-list">
          {selectedItems &&
            selectedItems.map((item) => {
              return (
                <ItemTags
                  key={item.sku}
                  item={item}
                  quantity={item.tags.length}
                  expected={item.expectedUnits}
                  handleDelete={handleDelete}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
  return (
    <div className="reception-order-step-two-container">
      <Step content={stepContent}></Step>
    </div>
  );
};

export default ReceptionOrderStep2;
