import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get, update } from "firebase/database";
import { getStorage, ref as refStorage, listAll, getDownloadURL, deleteObject, uploadBytes } from "firebase/storage";


export class FirebaseYaxDevicesService {

    db = null;
    app = null;

    constructor() {
        if (!FirebaseYaxDevicesService._instance) {
            FirebaseYaxDevicesService._instance = this;
            const firebaseConfig = {
                apiKey: "AIzaSyB_uR80mENfRoeruWLZZDol6vQUguh-yNs",
                authDomain: "devices-36287.firebaseapp.com",
                databaseURL: "https://devices-36287-default-rtdb.firebaseio.com",
                projectId: "devices-36287",
                storageBucket: "devices-36287.appspot.com",
                messagingSenderId: "254659555317",
                appId: "1:254659555317:web:e7da629cb0398d854ffabc"
            };

            const app = initializeApp(firebaseConfig);
            this.app = app;
        }
        return FirebaseYaxDevicesService._instance;
    }

    static getInstance() {
        return FirebaseYaxDevicesService._instance;
    }

    getDevices() {
        return new Promise((resolve, reject) => {
            const dbRef = ref(getDatabase());
            return get(child(dbRef, `devices`)).then((snapshot) => {
                if (snapshot.exists()) {

                    const array = Object.keys(snapshot.val()).map((key) => [key, snapshot.val()[key]]);
                    let rta = [];
                    array.forEach(a => {
                        a[1]["id"] = a[0];
                        rta.push(a[1]);
                    })
                    resolve(rta);
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    updateDevice(deviceId, updates) {
        return new Promise((resolve, reject) => {
            const db = getDatabase(this.app);
            const deviceRef = ref(db, `devices/${deviceId}`);

            update(deviceRef, updates)
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    console.error("Error al actualizar el dispositivo:", error);
                    reject(error);
                });
        });
    }

    getVersions() {
        return new Promise((resolve, reject) => {
            const storage = getStorage();
            const versionsRef = refStorage(storage, 'Versions/')
            return listAll(versionsRef)
                .then((res) => {
                    res.items.forEach((itemRef) => {
                        getDownloadURL(refStorage(storage, 'Versions/'+itemRef.name))
                        .then((url) => {
                            console.log(url);
                            itemRef["url"] = url;
                           // resolve(res.items);
                        })
                        .catch((error) => {
                            // Handle any errors
                        });
                    });
                    setTimeout(() => {
                        resolve(res.items);
                    }, 2000);

                }).catch((error) => {
                    reject(error);
                });
        });
    }

    deleteVersionFile(fileName) {
      return new Promise((resolve, reject) => {
          const storage = getStorage();
          const fileRef = refStorage(storage, `Versions/${fileName}`);

          deleteObject(fileRef).then(() => {
              console.log(`${fileName} has been successfully deleted`);
              resolve(`${fileName} deleted successfully`);
          }).catch((error) => {
              console.error(`Error deleting ${fileName}:`, error);
              reject(error);
          });
      });
  }

  updateFirebaseFile(folder, file) {
    return new Promise((resolve, reject) => {
        const storage = getStorage();
        const storageRef = refStorage(storage, `${folder}/${file.name}`);

        uploadBytes(storageRef, file).then((snapshot) => {
            console.log('File uploaded successfully');
            resolve('File uploaded successfully');
        }).catch((error) => {
            console.error("Error uploading file:", error);
            reject(error);
        });
    });
  }

}
