import React from 'react';
import './styles.scss';

export class ModalButton extends React.Component {
    render() {
        const {
            label,
            handleClick
        } = this.props;
        return (
            <button className="button" onClick={handleClick} data-autoamtion-id="modal-button">
                {label}
            </button>
        )
    }

}


ModalButton.propTypes = {};

export default ModalButton;