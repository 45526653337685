import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import "./styles.scss";
import { isObject } from "highcharts";

const useStyles = makeStyles((theme) => ({
  formRoot: {
    width: "100%",
    borderBottomColor: "none",
  },
  container: {
    border: "1px solid #D4D5E8",
    paddingLeft: "17px",
    height: "48px",
    borderRadius: "4px",
  },
  containerOpend: {
    border: "1px solid #D4D5E8",
    borderBottom: "none",
    paddingLeft: "17px",
    height: "48px",

    "&. MuiMenu-paper MuiPopover-paper": {},
  },
  containerDisabled: {
    border: "1px solid #EDEFF5",
    background: "#EDEFF5",
  },
  select: {
    ".MuiPopover-paper": {
      marginTop: "3px",
    },
  },
}));

const MultiSelectInput = ({
  handleChange,
  handleClose,
  handleOpen,
  items,
  selected,
  maxRenderValues = 2,
  label,
  required,
  multiple,
  disabled,
  id,
  error
}) => {
  const [_selected, setSelected] = useState(selected);
  const [opened, setOpened] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if(selected == _selected) return

    setSelected(selected);
  }, [selected]);

  const _handleOpen = () => {
    setOpened(true);
    handleOpen && handleOpen();
  };

  const _handleClose = () => {
    setOpened(false);
    handleClose && handleClose(_selected);
  };

  const getRenderValue = (sel, maxRenderValues = 2) => {
    if(!Array.isArray(sel)) {
      return sel.value
    }
    const joinedValues = sel
      .map((s) => s.value)
      .slice(0, maxRenderValues)
      .join(", ");
    const rest =
      sel.length - maxRenderValues > 0
        ? `+ ${sel.length - maxRenderValues}`
        : "";
    return `${joinedValues} ${rest}`;
  };

  return (
    <div
      className={`${
        opened ? classes.containerOpend : classes.container
      } ${
        disabled ? classes.containerDisabled : ''
      } multi-select-wrapper`}
    >
      <FormControl className={classes.formRoot}>
        <InputLabel id={`${id}-select-label`}>
          {label} {required ? <span className="required-span">*</span> : ""}
        </InputLabel>
        <Select
          labelId={`${id}-select-label`}
          id={`${id}-select`}
          multiple={multiple}
          value={_selected || ""}
          onChange={handleChange}
          // input={<Input />}
          renderValue={(selected) => getRenderValue(selected, maxRenderValues)}
          onClose={_handleClose}
          onOpen={_handleOpen}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          className={classes.select}
          disabled={disabled}
        >
          {items.map((item) => (
            <MenuItem key={item.id} value={item}>
              {multiple && <Checkbox
                checked={_selected.map((s) => s.id).includes(item.id)}
              />}
              <ListItemText primary={item.value} />
            </MenuItem>
          ))}
        </Select>
        <div className="main-input-error">
          {error}
        </div>
      </FormControl>
    </div>
  );
};

export default MultiSelectInput;
