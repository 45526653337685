import React, { useState, useEffect } from "react";
import { PublicService } from "services/PublicService";
import Search from "components/MainApp/atoms/Filters/Search";
import "./styles.scss";

const LocalizationProductFilter = ({handleApply}) => {
    const [searchedValue, setSearchedValue] = useState();
    const [products_name, setProducts_name] = useState();

    useEffect(() => {
        PublicService.itemsName({ include_key: 1 }).then((response) => {
            setProducts_name(response);
        });
    }, []);

    return (
        <div className={`localization-filter-panel-container`}>
            <div className="localization-filter-panel-row">
                <Search
                    placeholder="Buscar por código o nombre"
                    resultList={products_name}
                    value={searchedValue?.label}
                    handleSubmit={(value) => {
                        if (value === "") {
                            handleApply(null);
                        }
                    }}
                    handleSubmitKey={(item) => {
                        setSearchedValue(item);
                        handleApply(item);
                    }}
                />
            </div>
        </div>
    );
};

export default LocalizationProductFilter;
