const typeMap = {
  ZONE: "zona",
  AREA: "sector",
  SHELF: "estante",
  LEVEL: "Nivel",
  PLACE: "Ubicacion"
};

const typeTitleMap = {
  ZONE: "Detalle de zona",
  AREA: "Detalle de sector",
  SHELF: "estante",
  LEVEL: "Nivel",
  PLACE: "Ubicacion"
};

class Location {
  id = null;
  name = null;
  parent = null;
  type = null;
  measure_height = null;
  measure_width = null;
  measure_depth = null;
  capacity = null;
  dispatch_area = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }

  getDescriptionPanelView() {
    return [
      {
        title: `Nombre de ${typeMap[this.type]}`,
        value: this.name
      },
      {
        title: "ID",
        value: this.code
      },
      {
        title: "Produndidad (mts)",
        value: `${this.measure_depth / 1000} mts`
      },
      {
        title: "Alto (mts)",
        value: `${this.measure_height / 1000} mts`
      },
      {
        title: "Ancho (mts)",
        value: `${this.measure_width / 1000} mts`
      },
      {
        title: "Capacidad",
        value: `${this.capacity == null ? 'Sin informar' : this.capacity +' productos' }`
      },
      {
        title: "Ocupación",
        value: `${this.capacity_busy} productos`
      }
    ];
  }

  getLocationPanelsView(locations) {
    const areas = locations.filter(location => {
      return location.type === "AREA";
    });

    const shelves = locations.filter(location => {
      return location.type === "SHELF";
    });

    const levels = locations.filter(location => {
      return location.type === "LEVEL";
    });

    const places = locations.filter(location => {
      return location.type === "PLACE";
    });

    if (this.type === "ZONE") {
      return {
        title: "SECTOR",
        subtitle: "sector",
        type: "AREA",
        locations: areas
      };
    }

    if (this.type === "AREA") {
      return {
        title: "ESTANTERIA",
        subtitle: "estanteria",
        type: "SHELF",
        locations: shelves
      };
    }

    if (this.type === "SHELF") {
      return {
        title: "NIVEL",
        subtitle: "nivel",
        type: "LEVEL",
        locations: levels
      };
    }

    if (this.type === "LEVEL") {
      return {
        title: "UBICACION",
        subtitle: "ubicacion",
        type: "PLACE",
        locations: places
      };
    }

    return null;
  }

  getAddTitle() {
    return typeTitleMap[this.type];
  }
}
export { Location };
