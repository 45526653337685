import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";


import "./styles.scss";

export class AddDeviceStepOne extends React.Component {
  render() {
    const { device, handleSelect, handleContinue, types, enableContinue, error, device_types_child } = this.props;

    const stepContent = (
      <form onSubmit={handleContinue}>
      <div className="add-device-wizard-step-one-content">

        <div className="add-device-wizard-step-one-field" data-automation-id="input-types">
          <MainDropdownInput
            required={true}
            id={"device-type"}
            name="type"
            selectedOption={device ? device.type : ''}
            handleSelection={(selectedOption) => handleSelect(selectedOption, 'type')}
            maxLength={50}
            options={types}
            placeholder={'Selecciona'}
            label={"Selecciona"}
            error={error.type && error.type}
          />
        </div>

        <div className="add-device-wizard-step-one-field" data-automation-id="input-types" style={{ display: device_types_child.length ? "block" : "none" }}>
          <MainDropdownInput
            required={true}
            id={"device-type-child"}
            name="device-type-child"
            selectedOption={device ? device.device_type : ''}
            handleSelection={(selectedOption) => handleSelect(selectedOption, 'device_type')}
            maxLength={50}
            options={device_types_child}
            placeholder={'Selecciona'}
            label={"Selecciona"}
            error={error.type && error.type}
          />
        </div>

        <div className={"add-device-wizard-step-one-action-wrapper"}>
          <button type="submit" className={`add-device-wizard-step-one-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-device-wizard-step-one-container">
        <Step title="Seleciona el tipo de dispositivo" content={stepContent}></Step>
      </div>
    );
  }
}

export default AddDeviceStepOne;
