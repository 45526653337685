import React from "react";
import OrderPills from "../../../../../atoms/Pills/OrderPills";
import { ParseDate, ParseDateTime } from "../../../../../../../helpers/Parsers";

import "./styles.scss";
export const RequestOrderItemMobile = ({
  requestOrder,
  handleClick,
  mapTypes,
}) => {
  const prueba = (e) => {
    handleClick(e);
  };
  return (
    <div
      className="prep-order-table-item-mobile-container"
      data-automation-id="prep-order-table-item"
      data-requestorder-id={requestOrder.id}
      onClick={prueba}
    >
      <div className="prep-order-table-item-mobile-klass">
        {mapTypes && mapTypes[requestOrder.klass]}
      </div>

      <div className="prep-order-table-item-mobile-code">
        Nro {requestOrder.code}
      </div>

      <div className="prep-order-table-item-mobile-date">
        Fecha: {ParseDate(requestOrder.created_at)}
      </div>

      <div className="prep-order-table-item-mobile-bottom">
        <div className="prep-order-table-item-mobile-owner">
          Responsable:{" "}
          {requestOrder.owner
            ? `${requestOrder.owner.first_name} ${requestOrder.owner.last_name}`
            : "-"}
        </div>
        <div className="prep-order-table-item-mobile-state-inner">
          <OrderPills state={requestOrder.status}></OrderPills>
        </div>
      </div>
    </div>
  );
};

export default RequestOrderItemMobile;
