import React from 'react'
import PropTypes from 'prop-types'
import LoadFinishedGeneral from '../../LoadFinishedGeneral'

import './styles.scss'

export const TagAssociationFinished = ({ boldButtonFunc, buttonLink = '/admin/config_tags' }) => {
  return (
    <LoadFinishedGeneral
      title={'Asociación de Tags Externos'}
      boldButtonLabel={'ASOCIAR OTROS TAGS'}
      boldButtonFunc={boldButtonFunc}
      buttonLabel={'VER LISTADO DE TEMPLATES DE TAGS'}
      buttonLink={buttonLink}
    >
      <div className="tag-association--finished-container">
        Se asociaron los Tags de manera exitosa.
      </div>
    </LoadFinishedGeneral>
  )
}

export default TagAssociationFinished
