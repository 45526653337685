import React from "react";
import { Tooltip } from "react-tippy";
import { ParseDate, ParseDateTime } from "../../../../../../../helpers/Parsers";
import OrderPills from "../../../../../atoms/Pills/OrderPills";
import "./styles.scss";

const tooltipStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
};

const mapping = {
  DRA: "Borrador",
  PEN: "Pendiente",
  PRO: "En proceso",
  SUP: "Supervisar",
  DISP: "Despachada",
  REJ: "Rechazada",
  COM: "Completada",
};

export const RequestOrderTableItem = ({
  requestOrder,
  handleClick,
  mapTypes,
}) => {
  return (
    <div
      className="prep-order-table-item-container"
      data-automation-id="product-item"
    >
      <div
        className="table-item-klass"
        data-requestorder-id={requestOrder.id}
        onClick={handleClick}
      >
        <Tooltip
          html={<div style={tooltipStyle}>{requestOrder.klass}</div>}
          title={requestOrder.klass}
          followCursor={true}
          position="left"
          offset={25}
        >
          {" "}
          <p>{mapTypes && mapTypes[requestOrder.klass]}</p>
        </Tooltip>
      </div>
      <div
        className="table-item-code"
        data-requestorder-id={requestOrder.id}
        onClick={handleClick}
      >
        <Tooltip
          html={<div style={tooltipStyle}>{requestOrder.code}</div>}
          title={requestOrder.code}
          followCursor={true}
          position="left"
          offset={25}
        >
          {" "}
          <p>{requestOrder.code}</p>
        </Tooltip>
      </div>
      <div
        className="table-item-date"
        data-requestorder-id={requestOrder.id}
        onClick={handleClick}
      >
        <Tooltip
          html={
            <div style={tooltipStyle}>
              {requestOrder.created_at
                ? ParseDateTime(requestOrder.created_at)
                : null}
            </div>
          }
          followCursor={true}
          position="right"
          offset={25}
        >
          {" "}
          <p>
            {requestOrder.created_at ? ParseDate(requestOrder.created_at) : "-"}
          </p>
        </Tooltip>
      </div>
      <div
        className="table-item-owner"
        data-requestorder-id={requestOrder.id}
        onClick={handleClick}
      >
        <Tooltip
          html={
            <div style={tooltipStyle}>
              <p>
                {requestOrder.owner
                  ? `${requestOrder.owner.first_name} ${requestOrder.owner.last_name}`
                  : "-"}
              </p>
            </div>
          }
          followCursor={true}
          position="right"
          offset={25}
        >
          <p>
            {requestOrder.owner
              ? `${requestOrder.owner.first_name} ${requestOrder.owner.last_name}`
              : "-"}
          </p>
        </Tooltip>
      </div>
      <div
        className="table-item-state"
        data-requestorder-id={requestOrder.id}
        onClick={handleClick}
      >
        <Tooltip
          html={
            <div style={tooltipStyle}>
              <p>{mapping[requestOrder.status]}</p>
            </div>
          }
          followCursor={true}
          position="right"
          offset={25}
        >
          <OrderPills state={requestOrder.status} />
        </Tooltip>
      </div>
      <div
        className="table-item-button"
        data-automation-id="select-requestOrder"
        data-requestorder-id={requestOrder.id}
        onClick={handleClick}
      >
        <i className="icon-chevron-derecha"></i>
      </div>
    </div>
  );
};

export default RequestOrderTableItem;
