import React from "react";
import PropTypes from "prop-types";
import HeaderTipshelf from "../HeaderTipshelf";

import "./styles.scss";
export class DesktopLayoutTipshelf extends React.Component {
  render() {
    const {
      content,
      headerTitle,
      headerOptions,
      headerOptionsIcons,
      goBackFunc,
      headerButtonLabel,
      headerButtonHandler,
      headerButtonState,
      customButtons,
      onHandleMouseDown,
      onHandleClickClean,
    } = this.props;

    return (
      <div className="desktop-main-layout-tipshelf-container">
        <div className="right-layout-tipshelf-container">
          <HeaderTipshelf
            title={headerTitle}
            options={headerOptions}
            optionsIcons={headerOptionsIcons}
            goBackFunc={goBackFunc}
            buttonLabel={headerButtonLabel}
            buttonHandler={headerButtonHandler}
            buttonState={headerButtonState}
            content={customButtons}
            onHandleMouseDown={onHandleMouseDown}
            onHandleClickClean={onHandleClickClean}
          ></HeaderTipshelf>
          {content}
        </div>
      </div>
    );
  }
}

DesktopLayoutTipshelf.propTypes = {
  content: PropTypes.any,
};

export default DesktopLayoutTipshelf;
