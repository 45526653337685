import React, { useState, useEffect } from "react";

import { isMobile } from "helpers/Mobile";
import useRequest from "hooks/useRequest";

import i18n from "i18n";

import Loader from "components/MainApp/atoms/Loader";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";

import "./styles.scss";
import { TagService } from "services/TagService";
import FilterPanel from "components/MainApp/organisms/Filters/FilterPanel";
import { useHistory } from "react-router-dom";
import { DeviceService } from "services/DeviceService";
import Search from "components/MainApp/atoms/Filters/Search";
import { EventRepository } from "helpers/EventRepository";

const ReprintTagView = props => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();

  const [tags, setTags] = useState([]);
  const [sku, setSku] = useState("");
  const [productName, setProductName] = useState("");
  const [batch, setBatch] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [printers, setPrinters] = useState([]);
  const [printer, setPrinter] = useState(null);
  const [enableContinue, setEnableContinue] = useState(false);
  const [epc, setEpc] = useState();
  const [goToOrders, setGoToOrders] = useState(false);

  const headerOptions = [];

  useEffect(() => {
    if (props.match.params.epc) {
      setGoToOrders(true);
      setEpc(props.match.params.epc)
      handleSearch(props.match.params.epc);
    }
    beforeSubmit();
    TagService.getTagsByBusiness()
      .then(tags => {
        setTags(tags);
      })
      .catch(error => {
        handleError(error);
      })
      .finally(() => {
        afterSubmit();
      });

    DeviceService.devices({ device_type: "PRINTER" }).then(data => {
      data = data.map(device => {
        return { label: device.name, option: device.id };
      });
      setPrinters(data);
    });


  }, []);

  const handleSearch = epc => {
    console.log("handleSearch", epc);
    if (epc) {
      setEpc(epc.trim().toUpperCase());
      beforeSubmit();
      TagService.tag(epc)
        .then(response => {
          setSku(response?.sku);
          setProductName(response?.variables.name);
          setBatch(response?.variables?.batch);
          setExpirationDate(response?.variables?.expiration);
        })
        .catch(error => {
          handleError(error);
          setSku("");
        })
        .finally(() => {
          afterSubmit();
        });
    } else {
      setSku("");
    }
  };

  const handleError = error => {
    if (error?.status === 404) {
      EventRepository.notificationSend({
        label: i18n.t("No encontrado"),
        type: "error"
      });
    } else {
      dealWithError(error, "generic.error", (message, errors) => {
        if (Object.keys(errors).length === 0) {
          EventRepository.notificationSend({
            label: message,
            type: "error"
          });
        } else {
          EventRepository.notificationSend({
            label: i18n.t("Ha ocurrido un error."),
            type: "error"
          });
        }
      });
    }
  };

  const handleSelect = (selectedOption, type) => {
    setEnableContinue(true);
    setPrinter(selectedOption);
  };

  const handlePrint = e => {
    e.preventDefault();
    if (epc) {
      const data = {
        device: printer.option,
        rfids: [epc]
      };

      beforeSubmit();
      TagService.printTags(data)
        .then(tags => {
          EventRepository.notificationSend({
            label: i18n.t("Se imprimieron los tags de manera exitosa."),
            type: "success"
          });
        })
        .catch(error => {
          handleError(error);
        })
        .finally(() => {
          afterSubmit();
        });
    }
  };

  const tableContent = (
    <React.Fragment>
      <div className={`reprintTag-content`}>
        {!isMobile && (
          <div className="reprintTag-wrapper-search">
            <Search
              resultList={tags}
              placeholder="Buscar tag"
              handleSubmit={handleSearch}
              value={props.match.params.epc}
            ></Search>
          </div>
        )}
        {sku && (
          <div className="reprintTag-detail-container">
            <div className="reprintTag-detail-panel-1">
              <div className="reprintTag-detail-section-title1">
                {i18n.t("Detalles")}
              </div>

              <div className="reprintTag-detail-section-title2">
                {i18n.t("Sku")}
              </div>
              <div className="reprintTag-section-content">{sku}</div>

              <div className="reprintTag-detail-section-title2">
                {i18n.t("Nombre")}
              </div>
              <div className="reprintTag-section-content">{productName}</div>

              <div className="reprintTag-detail-section-title2">
                {i18n.t("Lote")}
              </div>
              <div className="reprintTag-section-content">{batch}</div>

              <div className="reprintTag-detail-section-title2">
                {i18n.t("Fecha de vencimiento")}
              </div>
              <div className="reprintTag-section-content">{expirationDate}</div>
            </div>
            <div className="reprintTag-detail-panel-2-container">
              <div className="reprintTag-detail-panel-2">
                <div className="reprintTag-detail-section-title1">
                  {i18n.t("Selecciona impresora")}
                </div>

                <MainDropdownInput
                  required={true}
                  id={"printer"}
                  name="printer"
                  selectedOption={printer ? printer : ""}
                  handleSelection={selectedOption =>
                    handleSelect(selectedOption, "printer")
                  }
                  maxLength={50}
                  options={printers}
                  placeholder={"Selecciona una impresora"}
                  label={"Selecciona una impresora"}
                // error={error.printer && error.printer}
                />
                <div className={"reprintTag-action-wrapper"}>
                  <button
                    type="submit"
                    className={`reprintTag-action-button ${!enableContinue &&
                      "disabled"}`}
                    onClick={enableContinue ? handlePrint : undefined}
                  >
                    {i18n.t("Imprimir")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );

  const contentTable = tableContent;
  const content = (
    <React.Fragment>
      <div className="table-reprintTag-outter-wrapper">
        {isMobile && (
          <Search
            placeholder="Buscar por tag"
            resultList={tags}
            handleSubmit={handleSearch}
        />
        )}
        <div className="table-reprintTag-wrapper">{contentTable}</div>
      </div>
    </React.Fragment>
  );

  const goTo = () => {
    if(goToOrders){
      history.goBack();
    }else {
      history.push(`/admin/config_tags`);
    }
  };

  const headerTitle = "Reimpresión de Tags";

  return (
    <div className="reprintTag-container">
      <AdminLayout
        headerTitle={i18n.t(headerTitle)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={goTo}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default ReprintTagView;
