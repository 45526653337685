import "./AlertProductDetail.scss";
import { Card, CardContent } from "@material-ui/core";
import Tooltip from "../Tooltip";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { convertHexRfidToBigInt } from "helpers/stock/StockHelper";
import epcIcon from "assets/images/epc-icon.svg";

const AlertProductDetail = ({ tag, product, processAlert }) => {
  return (
    <Card className="alert-product-detail-card" component="article">
      <header>
        <h5>Detalle de producto</h5>
        <button onClick={processAlert}>Procesar alarma</button>
      </header>

      <CardContent className="alert-product-detail-card__content">
        <section className="alert-product-detail-card__content--info">
          <picture>
            <LazyLoadImage
              width={"100%"}
              height={"100%"}
              src={product?.image}
              effect="blur"
            />
          </picture>
          <div>
            <h3>{product?.name}</h3>
            <h4>SKU {product?.sku}</h4>
            {product?.description.length > 228 ? (
              <Tooltip
                content={<p>{product?.description}</p>}
                text={product?.description}
              />
            ) : (
              <p>{product?.description}</p>
            )}
          </div>
        </section>

        <section className="alert-product-detail-card__content--properties">
          <div className="alert-product-detail-card__content--properties__prop">
            <picture>
              <img src={epcIcon} alt="EPC" />
            </picture>
            <div>
              <p>EPC</p>
              <h3>{convertHexRfidToBigInt(tag?.rfid || "")}</h3>
            </div>
          </div>
        </section>
      </CardContent>
    </Card>
  );
};

export default AlertProductDetail;
