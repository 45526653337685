import Step from "components/MainApp/atoms/Wizard/Step";
import React from "react";

import MainDropdown from "components/MainApp/atoms/Forms/MainDropdown";
import "./styles.scss";

export const RelOrderStep3 = ({ options, option, handleChange }) => {
  const stepContent = (
    <div className="relocation--order-step-three-content">

      <div
        className="relocation-order-step-three-field"
        data-automation-id="input-name"
      >
        <MainDropdown
          label={"Selecciona"}
          placeholder={"Selecciona"}
          required={true}
          id={"dest"}
          name="dest"
          value={option}
          selectedOption={option}
          options={options}
          handleChange={handleChange}
          handleSelection={handleChange}
        />
      </div>
    </div>
  );
  return (
    <div className="relocation--order-step-three-container">
      <Step
        title="Seleciona el destino del pedido"
        content={stepContent}
      ></Step>
    </div>
  );
};

export default RelOrderStep3;
