import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";


import "./styles.scss";

const keyTitleMap = {
  type: 'TIPO DE EMBALAJE',
  name: 'NOMBRE',
  measure_height: 'ALTO',
  measure_width: 'ANCHO',
  measure_depth: 'PROFUNDIDAD',
  volume: 'VOLUMEN',
  max_weight: 'PESO MÁXIMO',
  material: 'MATERIAL'
}


export class AddConfigTagStepThree extends React.Component {
  render() {
    const { embalaje, productsSelected, goBack, handleFinish, paletType } = this.props;
    const getProdtype = (quantity) => {
      if(embalaje.type.id === 'PALLET' && paletType === 'BOX'){
      
        if(quantity > 1) return 'cajas' 
        else{
          return 'caja'
        }
      }else{
        if(quantity > 1) return 'unidades' 
        else{
          return 'unidad'
        }
      }
    }
    const stepContent = (
      <form onSubmit={handleFinish}>
      <div className="add-embalaje-wizard-step-three-content">
        <div className="section">
          <div className="section-title">Descripción</div>
          {
            ['type', 'name'].map(
              key => {
                return embalaje[key] ? <div key={key}> 
                  <div className="title"> {keyTitleMap[key]} </div>
                  <div className="value"> {embalaje[key].value ? embalaje[key].value : embalaje[key]}</div>
                </div> : ''
              }
            )
          }
        </div>
        <div className="section">
          <div className="section-title">Productos</div>
          <div className="product-list">
            {
              productsSelected.map(p => <div className="product" key={p.sku}>
                <p className="product-name">{p.name}  <span className="quantity">({p.units} {getProdtype(p.units)})</span></p>
                <p className="product-brand">{p.brand?.name}</p>
                <p className="product-sku">Codigo {p.sku}</p>

                <p></p>
              </div>)
            }
          </div>
        </div>
        <div className="section">
          <div className="section-title">Dimensiones</div>
          {
            ['measure_height', 'measure_width', 'measure_depth', 'volume', 'max_weight', 'material'].map(
              key => {
                return embalaje[key] ? <div key={key}> 
                  <div className="title"> {keyTitleMap[key]} </div>
                  <div className="value"> {embalaje[key]}</div>
                </div> : ''
              }
            )
          }
        </div>
        <div className={"add-embalaje-wizard-step-three-action-wrapper"}>
          <button type="submit" className="add-embalaje-wizard-step-three-action-button">
            FINALIZAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-embalaje-wizard-step-three-container">
        <Step title="Confirmación" content={stepContent} goBackFunc={goBack}></Step>
      </div>
    );
  }
}

export default AddConfigTagStepThree;
