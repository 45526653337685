const columns = [
  {
    name: "name",
    pill: false,
    title: "NOMBRE",
    mobileTag: false,
  },
  {
    name: "type",
    pill: false,
    title: "Tipo",
    mobileTag: "Tipo:",
  },
  {
    name: "serial_number",
    pill: false,
    title: "NUMERO DE SERIE",
    mobileTag: "Nro:",
  },

  {
    name: "node",
    pill: false,
    title: "SUCURSAL",
    mobileTag: "Sucursal:",
  },

  {
    name: "location",
    pill: false,
    title: "UBICACION",
    mobileTag: "Ubicacion:",
  },
  {
    name: "status",
    title: "ESTADO",
    mobileTag: false,
    pill: true,
    pillMap: {
      DIS: "Inhabilitado",
      EN: "Habilitado",
    },
    pillMapColor: {
      DIS: "#3C7AF5",
      EN: "#62D862",
    },
  },
];

export default columns;
