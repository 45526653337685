/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FileSaver from "file-saver";

import { isMobile } from "helpers/Mobile";

import { AuthService } from "services/AuthService";
import { OrderService } from "../../../../../services/OrderService";
import { PublicService } from "../../../../../services/PublicService";
import { AccountService } from "services/AccountService";
import { PersonService } from "services/PersonService";
import { StockService } from "services/StockService";
import { stockByItem } from "helpers/stock/StockHelper";

import usePagination from "hooks/usePagination";
import useAddItems from "hooks/useAddItems";
import useRequest from "hooks/useRequest";

import "./styles.scss";
import AdminLayout from "../../../../../components/MainApp/layouts/DesktopLayout";
import StepOne from "./steps/Step1";
import StepTwo from "./steps/Step2";
import StepThree from "./steps/Step3";
import LoadFinished from "../../../../../components/MainApp/organisms/Orders/RequestOrders/LoadOrderFinished";
import Wizard from "../../../../../components/MainApp/atoms/Wizard/StepProgress";
import RoundedButton from "../../../../../components/MainApp/atoms/RoundedButton";
import Loader from "../../../../../components/MainApp/atoms/Loader";

import data from "./data";
import { PrepOrder } from "models/Orders";

import {
  parseItems,
  itemsToSend,
  parseItemsForSearch,
} from "helpers/orders/ItemHelper";

export const LoadPrepOrderView = (props) => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();
  const [activestep, setactivestep] = useState("0");
  // Step 0
  const [order, setorder] = useState(null);
  const [reasons, setReasons] = useState(data.reasons);
  const [accounts, setAccounts] = useState([]);
  const [clients, setClients] = useState([]);
  const [fields, setFields] = useState([]);

  const [
    list,
    currentPage,
    pageSize,
    pageList,
    pageQuantity,
    changePageSize,
    changePage,
    changeList,
  ] = usePagination([], 0, 3);

  const [
    productResultList,
    setproductResultList,
    selectedToAddProductList,
    setselectedToAddProductList,
    addedList,
    setaddedList,
    handleSelectToAddProduct,
    handleSelectedProductQuantityChange,
    handleAdd,
    handleAddProduct,
    handleDeleteAddedProduct,
    handleAddExtra,
    handleChangedLocations,
    handleDeleteAddedProductLocations,
  ] = useAddItems(changeList, currentPage, pageSize);

  useEffect(() => {
    if (!props.match.params.key) {
      setorder(new PrepOrder(data.initialOrder));
    } else {
      const id = props.match.params.key;
      beforeSubmit();
      OrderService.prepOrder(id)
        .then((order) => {
          afterSubmit();
          setorder(order);
          getItems(order.id, order.origin?.id);
        })
        .catch((_) => {
          afterSubmit();
        });
    }
  }, []);

  useEffect(() => {
    const node_id = AuthService.getCurrentNodeCode();

    AccountService.accounts({ nodes: node_id, no_page: 1 }).then((response) => {
      const accounts = response.map((a) => {
        return { id: a.id, label: `${a.first_name} ${a.last_name}` };
      });
      setAccounts(accounts);
    });

    PersonService.persons({ type: "CLIENT", no_page: 1 }).then((response) => {
      const clients = response.map((c) => {
        return { id: c.id, label: `${c.name}` };
      });
      setClients(clients);
    });
  }, []);

  useEffect(() => {
    if (!order) return;

    if (activestep === "0") {
      const newFields = order.loadStep1Fields(errors, accounts, clients);
      setFields(newFields);
    }
  }, [order, accounts, clients]);


  const filterProducts = (value) => {
    return getStockItems(value);
  }


  const getItems = (orderId, origin_node_id) => {
    OrderService.getItems(orderId).then((response) => {
      let addedListNew = parseItems(response);
      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
      getStockItems(origin_node_id);
    });
  };

  const getStockItems = (value) => {
    const node_id = AuthService.getCurrentNodeCode();
    if (node_id !== null) {
      beforeSubmit();
      return new Promise((resolve, reject) => {
        StockService.stock({
          no_page: 1,
          status: "LOC",
          location__node: node_id,
          "search": value,
        })
          .then((res) => {
            const _groupedStock = stockByItem(res);
            const _productResultList = _groupedStock.map((item) => {
              return {
                id: item.id,
                sku: item.sku,
                name: item.title,
                stock: item.tags?.length ?? 0,
                units: 1,
              };
            });
            setproductResultList(_productResultList);
            resolve(_productResultList)
            afterSubmit();
          })
          .catch((_) => {
            resolve([]);
            afterSubmit();
          });
      });

    }
  }

  const restart = () => {
    setactivestep("0");
    setorder(new PrepOrder(data.initialOrder));
    setaddedList([]);
    changeList([], 0, 3);
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let newOrder = order;
    newOrder[name] = e.target.value;
    const newFields = newOrder.loadStep1Fields(errors, accounts, clients);
    setFields(newFields);
    setorder(newOrder);
  };

  const handleSelect = (selectedOption, type) => {
    let orderAux = order;
    let newFields = {};
    switch (type) {
      case "reason":
        orderAux.klass = selectedOption.id;
        newFields = orderAux.loadStep1Fields(errors, accounts, clients);
        setFields(newFields);
        setorder(orderAux);
        break;
      case "owner":
        orderAux.owner = selectedOption;
        newFields = orderAux.loadStep1Fields(errors, accounts, clients);
        setFields(newFields);
        setorder(orderAux);
        break;
      case "client":
        orderAux.client = selectedOption;
        newFields = orderAux.loadStep1Fields(errors, accounts, clients);
        setFields(newFields);
        setorder(orderAux);
        break;
      default:
        break;
    }
  };

  const stepChangeHandler = (step) => {
    setactivestep(step);
  };

  const step0IsOk = () => {
    return (
      fields &&
      fields.findIndex((field) => {
        return (
          field.data.required && (field.data.value === "" || !field.data.value)
        );
      }) === -1
    );
  };

  const step1IsOk = () => {
    return addedList.length > 0;
  };

  const nextStep = (e) => {
    e.preventDefault();
    const func = {
      "0": (e) => stepOneSend(e),
      "1": (e) => stepTwoSend(e),
      "2": (e) => handleSubmit(e),
    };
    func[activestep](e);
  };

  const stepOneSend = (e) => {
    const data = order.dataToSend();
    beforeSubmit();
    if (order.id) {
      OrderService.updateOrder(order.id, data)
        .then((response) => {
          afterSubmit();
          setorder(new PrepOrder(response));
          stepChangeHandler("1");
        })
        .catch((error) => {
          afterSubmit();
          dealWithError(error, "generic.error");
        });
    } else {
      OrderService.createOrder(data)
        .then((response) => {
          afterSubmit();
          setorder(new PrepOrder(response));
          stepChangeHandler("1");
        })
        .catch((error) => {
          afterSubmit();
          dealWithError(error, "generic.error");
        });
    }
  };

  const stepTwoSend = (e) => {
    const items = itemsToSend(addedList);

    beforeSubmit();
    OrderService.addItems(order.id, items)
      .then((response) => {
        afterSubmit();
        stepChangeHandler("2");
      })
      .catch((error) => {
        afterSubmit();
      });
  };

  const handleSubmit = (e) => {
    beforeSubmit();
    OrderService.changeOrderStatus(order.id, "PRO", "Creation finished")
      .then((response) => {
        afterSubmit();
        setactivestep("finish");
      })
      .catch((error) => {
        afterSubmit();
        // Mostrar algo
      });
  };

  const downloadVoucher = () => {
    beforeSubmit();
    OrderService.prepDownloadVoucher(order.id)
      .then((blob) => {
        afterSubmit();
        FileSaver.saveAs(blob, "comprobante.pdf");
      })
      .catch((error) => {
        afterSubmit();
      });
  };

  const goBackFunc = () => {
    history.push("/admin/orders/preparation");
  };

  const content = (
    <div className="load-request-order-content">
      {activestep !== "finish" ? (
        <div className="load-request-order-wrapper">
          <div className="load-request-order-wizard">
            <Wizard
              steps={data.steps}
              activeStep={activestep}
              clickEnabled={false}
            ></Wizard>
          </div>

          <div className="load-request-order-step">
            {activestep === "0" && (
              <form onSubmit={nextStep} className="content">
                <StepOne
                  order={order}
                  options={reasons}
                  handleChange={handleChange}
                  handleSelect={handleSelect}
                  fields={fields}
                />
                <div className="load-request-order-button">
                  <div>
                    <RoundedButton
                      legend="CONTINUAR"
                      type="submit"
                      state={step0IsOk() ? "enabled" : "disabled"}
                      handleClick={nextStep}
                    ></RoundedButton>
                  </div>
                </div>
              </form>
            )}

            {activestep === "1" && (
              <div className="content">
                <StepTwo
                  handleChange={handleChange}
                  resultList={productResultList}
                  searchValue={""}
                  products={isMobile ? addedList : pageList}
                  totalProducts={addedList.length}
                  handleSelectToAddProduct={handleSelectToAddProduct}
                  handleChangedLocations={handleChangedLocations}
                  handleAdd={handleAddExtra}
                  handleAddProduct={handleAddProduct}
                  selectedProducts={selectedToAddProductList.map((p) => p.id)}
                  handleDeleteAddedProduct={handleDeleteAddedProductLocations}
                  changePage={changePage}
                  pagination={{
                    pageQuantity: pageQuantity,
                    currentPage: currentPage,
                  }}
                  filter={filterProducts}
                />
                <div className="load-request-order-button">
                  <div>
                    <RoundedButton
                      legend="CONTINUAR"
                      type="submit"
                      state={addedList.length > 0 ? "enabled" : "disabled"}
                      handleClick={(e) => nextStep(e)}
                    ></RoundedButton>
                  </div>
                </div>
              </div>
            )}

            {activestep === "2" && (
              <div className="content">
                <StepThree
                  products={addedList}
                  handleEditDest={() => stepChangeHandler("0")}
                  handleEditProducts={() => stepChangeHandler("1")}
                  order={order}
                />
                <div className="load-request-order-button last">
                  <div>
                    <RoundedButton
                      legend="CONFIRMAR"
                      state="enabled"
                      handleClick={handleSubmit}
                    ></RoundedButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="load-request-order-wrapper-finish">
          <div className="load-request-order-wrapper-finish-container">
            <LoadFinished
              orderNumber={order.code}
              boldButtonFunc={restart}
              downloadVoucher={downloadVoucher}
              buttonLink={"/admin/orders/preparation"}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="load-request-order-container">
      <AdminLayout
        headerTitle={"Nueva orden de salida"}
        headerOptions={data.headerOptions}
        content={content}
        goBackFunc={activestep !== "finish" ? goBackFunc : null}
        navHidden={true}
      ></AdminLayout>

      {loading && <Loader />}

      {/* <SingleLoadExitModal
          open={this.state.goBackConfirm}
          confirm={() => this.props.history.push("/admin/products/load")}
          cancel={() => this.setState({ goBackConfirm: false })}
          handleClose={() => this.setState({ goBackConfirm: false })}
        /> */}
    </div>
  );
};

export default LoadPrepOrderView;
