import React from "react";
import PropTypes from "prop-types";
import FileSaver from "file-saver";
import i18n from "i18n";

import Environment from "environment";

import { ProductService } from "../../../../services/ProductService";

import "./styles.scss";
import GenericFormContainer from "../../../../helpers/GenericFormContainer";
import AdminLayout from "../../../../components/MainApp/layouts/DesktopLayout";
import LoadFinished from "../../../../components/MainApp/organisms/Product/LoadFinished";
import WrongContentModal from "../../../../components/MainApp/organisms/Product/Modals/WrongContentModal";
import WrongFileModal from "../../../../components/MainApp/organisms/Product/Modals/WrongFileModal";

export class LoadMassiveProductView extends GenericFormContainer {
  constructor(props) {
    super(props);

    this.state = {
      wrongFileOpen: false,
      messageWrongFile: null,
      wrongContentOpen: false,

      activeStep: 0,

      file: null,
      detail_file: [],
      errors_file: [],
    };
  }

  handleChange = (e) => {
    this.setState(
      {
        wrongFileOpen: false,
        wrongContentOpen: false,
        file: e.target.files[0],
        activeStep: 0,
      },
      (_) => {
        this.handleSubmit(e);
      }
    );
  };

  handleSubmit = (e) => {
    this.nextStep();
    let formData = new FormData();
    formData.append("file", this.state.file);
    this.beforeSubmit();
    ProductService.uploadProducts(formData)
      .then((data) => {
        this.afterSubmit();
        this.nextStep();
      })
      .catch((error) => {
        this.afterSubmit();
        if ([400, 409].includes(error.status)) {
          error.json().then((data) => {
            if ("file" in data) {
              this.setState({
                wrongFileOpen: true,
                messageWrongFile:
                  "El archivo de importación debe ser en formato excel",
              });
            } else if ("general" in data) {
              this.setState({
                wrongFileOpen: true,
                messageWrongFile: data["general"],
              });
            } else if (Array.isArray(data)) {
              const errors_arr = data
                .filter((e) => {
                  return !e.ok;
                })
                .map((e) => {
                  const errors = Object.keys(e.errors)
                    .map((k) => {
                      return i18n.t(k) + ":" + e.errors[k];
                    })
                    .join(",");
                  return `${e.row},${errors}`;
                });
              this.setState({
                detail_file: data,
                errors_file: errors_arr,
                wrongContentOpen: true,
              });
            }
          });
        } else {
          // error de server
        }
      });
  };

  downloadTemplate = (e) => {
    window.open(Environment.statics + "files/templateproductos.xlsx", "_blank");
    // this.beforeSubmit();
    // ProductService.downloadTemplateProducts(this.state.filters)
    //   .then(blob => {
    //     this.afterSubmit();
    //     FileSaver.saveAs(blob, 'templateproductos.xlsx');
    //   }).catch((error) => {
    //     this.afterSubmit();
    //     this.dealWithError(error, 'Error descargando excel');
    //   });
  };

  downloadErrorsFile() {
    const csv_string = ["Fila,Errores"]
      .concat(this.state.errors_file)
      .join("\n");
    const blob = new Blob(["\ufeff" + csv_string], {
      type: "text/csv;charset=utf-8;",
    });
    FileSaver.saveAs(blob, "Errores.csv");
  }

  stepChangeHandler(step) {
    this.setState({
      activeStep: step,
    });
  }

  nextStep(e) {
    const func = {
      0: (e) => this.stepChangeHandler(1),
      1: (e) => this.stepChangeHandler(2),
      // 2: (e) => this.handleSubmit(e)
    };
    func[this.state.activeStep](e);
  }

  goBackStep(e) {
    const func = {
      1: (e) => this.stepChangeHandler(0),
    };
    func[this.state.activeStep](e);
  }

  goBack() {
    this.props.history.push("/admin/products/load");
  }

  headerOptions = [];

  render() {
    const content = (
      <div className="load-multiple-products-content">
        {this.state.activeStep === 0 && (
          <div className="load-multiple-products-wrapper1">
            <i className="icon-iconos_documento load-multiple-products-icon"></i>
            <div className="load-multiple-products-title">
              Deberás preparar tu archivo excel con algunas especificaciones.
            </div>
            <div className="load-multiple-products-links">
              <div className="load-multiple-products-link">
                <div className="load-multiple-products-link-number">1</div>
                <div className="load-multiple-products-link-text">
                  <span
                    className="load-multiple-products-link-span"
                    onClick={this.downloadTemplate}
                  >
                    Descarga aquí
                  </span>{" "}
                  el ejemplo para guiarte y luego súbelo.
                </div>
              </div>

              <div className="load-multiple-products-link">
                <div className="load-multiple-products-link-number">2</div>
                <div className="load-multiple-products-link-text">
                  <label className="load-multiple-products-link-span">
                    Sube aquí
                    <input
                      type="file"
                      name="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={this.handleChange}
                    ></input>
                  </label>{" "}
                  tu archivo con los productos.
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.activeStep === 1 && (
          <div className="load-multiple-products-wrapper2">
            <i className="icon-iconos_buscar-documento load-multiple-products-icon"></i>
            <div className="load-multiple-products-title">
              Cargando productos
            </div>
            <div className="load-multiple-products-subtitle">
              Por favor espera mientras procesamos tu archivo.
            </div>

            <div className="box">
              <div className="loader4"></div>
            </div>
          </div>
        )}

        {this.state.activeStep === 2 && (
          <div className="load-multiple-products-wrapper3">
            <div className="load-multiple-products-wrapper-finish-container">
              <LoadFinished />
            </div>
          </div>
        )}
      </div>
    );

    return (
      <div className="load-multiple-products-container">
        <AdminLayout
          headerTitle={"Carga masiva"}
          headerOptions={this.headerOptions}
          content={content}
          goBackFunc={this.goBack.bind(this)}
          navHidden={true}
        ></AdminLayout>

        <WrongContentModal
          open={this.state.wrongContentOpen}
          invalidFilesQuantity={this.state.errors_file.length}
          totalQuantity={this.state.detail_file.length}
          handleDownloadErrors={this.downloadErrorsFile.bind(this)}
          handleDownloadTemplate={this.downloadTemplate}
          handleGoBack={(e) => {
            this.setState({ wrongContentOpen: false });
            this.goBackStep();
          }}
          handleClose={(e) => {
            this.setState({ wrongContentOpen: false });
            this.goBackStep();
          }}
          handleClick={this.handleChange}
        />

        <WrongFileModal
          open={this.state.wrongFileOpen}
          message={this.state.messageWrongFile}
          handleDownloadTemplate={this.downloadTemplate}
          handleGoBack={(e) => {
            this.setState({ wrongFileOpen: false });
            this.goBackStep();
          }}
          handleClose={(e) => {
            this.setState({ wrongFileOpen: false });
            this.goBackStep();
          }}
          handleClick={this.handleChange}
        />
      </div>
    );
  }
}

LoadMassiveProductView.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string,
};

export default LoadMassiveProductView;
