import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";

import { AuthService } from "services/AuthService";
import Loader from "components/MainApp/atoms/Loader";
import AdminLayout from "../../../components/MainApp/layouts/DesktopLayout";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";
import useRequest from "hooks/useRequest";
import "./styles.scss";

const headerOptions = [];

export const ActiveNodeView = () => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [business, setBusiness] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [node, setnode] = useState(null);
  const [nodes, setnodes] = useState([]);

  useEffect(() => {
    const business = AuthService.currentBusinessValue();
    if (business) {
      setBusiness({ label: business.name, value: business.id });
    }

    const node = AuthService.currentNodeValue();
    if (node) {
      setnode({ label: node.name, value: node.code });
    }

    loadBusinesses();
    if (business) loadNodes(business.id);
  }, []);

  const loadBusinesses = () => {
    const businesses = AuthService.currentUserValue()
      .account.business.map((b) => {
        return { label: b.name, value: b.id };
      })
      .sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA > labelB) return 1;
        if (labelA < labelB) return -1;
        return 0;
      });
    setBusinesses(businesses);
  };

  const loadNodes = (business_id) => {
    if (!business_id) {
      setnodes([]);
    }

    const nodes = AuthService.currentUserValue()
      .account.nodes.map((n) => {
        return { label: n.name, value: n.code, business_id: n.business_id };
      })
      .sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA > labelB) return 1;
        if (labelA < labelB) return -1;
        return 0;
      });
    setnodes(nodes.filter((n) => n.business_id == business_id));
  };

  const handleSend = async (e) => {
    e.preventDefault();

    if (!business) {
      return;
    }

    beforeSubmit();
    
    let new_business = AuthService.currentUserValue().account.business.find(
      (b) => b.id === business.value
    );
    AuthService.setBusiness(new_business);

    if (node) {
      let new_node = AuthService.currentUserValue().account.nodes.find(
        (n) => n.code === node.value
      );
      AuthService.setBusinessNode(new_node);
    } else {
      AuthService.setBusinessNode(null);
    }

    AuthService.loadUser(true).then(()=> {
      history.push("/admin/more-options");
      afterSubmit();
    });

    
  };

  const handleSelect = (selectedOption, type) => {
    if (type == "node") {
      let nodeAux = { ...node };
      nodeAux = selectedOption;
      setnode(nodeAux);
    } else if (type == "business") {
      let businessAux = { ...business };
      businessAux = selectedOption;
      setBusiness(businessAux);
      setnode(null);
      loadNodes(businessAux.value);
    }
  };

  const goBackFunc = () => {
    history.push("/admin/more-options");
  };

  const content = (
    <div className="active-node-content">
      <form onSubmit={handleSend}>
        <div className="active-node-form-container">
          <div className="active-node-form-content">
            <div
              className="active-node-form-field"
              data-automation-id="input-language"
            >
              <MainDropdownInput
                required={false}
                id={"business"}
                name="business"
                selectedOption={business ? business : ""}
                handleSelection={(selectedOption) =>
                  handleSelect(selectedOption, "business")
                }
                maxLength={50}
                options={businesses}
                placeholder={i18n.t("Empresa")}
                label={i18n.t("Empresa")}
              />
            </div>

            <div
              className="active-node-form-field"
              data-automation-id="input-language"
            >
              <MainDropdownInput
                required={false}
                id={"node"}
                name="node"
                selectedOption={node ? node : ""}
                handleSelection={(selectedOption) =>
                  handleSelect(selectedOption, "node")
                }
                maxLength={50}
                options={nodes}
                placeholder={i18n.t("Sucursal")}
                label={i18n.t("Sucursal")}
              />
            </div>
          </div>
        </div>
        <div className={"active-node-button-wrapper"}>
          <button
            type="submit"
            className={`active-node-button`}
            onClick={handleSend}
          >
            GUARDAR
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <div className={"active-node-container"}>
      <AdminLayout
        headerTitle={i18n.t("Ubicación Activa")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={goBackFunc}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default ActiveNodeView;
