import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
export class LoadButtons extends React.Component {
  render() {
    const { title, icon, subtitle, handleFunc } = this.props;

    return (
      <div className="load-buttons-container" onClick={handleFunc} data-automation-id="load-button">
        <i className={"icon-load-button " + icon}></i>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
    );
  }
}

LoadButtons.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  handleFunc: PropTypes.func,
};

export default LoadButtons;
