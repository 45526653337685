import React from "react";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";
import DragAndDropImages from "components/MainApp/atoms/Forms/DragAndDropImages";

import "./styles.scss";

const AddLocationForm = ({
  device,
  handleSelect,
  handleChange,
  error,
  zones,
  sectors,
  nodes,
  type,
  disabledFields,
  handleChangeImage,
  handleDeleteImage,
  showSelectorFiles = true,
  msgInfoFiles= ''
}) => {
    return (
    <div className="edit-device-form-content">
      <div
        className="edit-device-form-field"
        data-automation-id="input-serial_number"
      >
        <MainInput
          label={"Número de serial"}
          required={true}
          id={"device-serial_number"}
          name="serial_number"
          value={device ? device.serial_number : ''}
          handleChange={handleChange}
          disabled={disabledFields && disabledFields.includes('serial_number')}
          maxLength={100}
          error={error.serial_number && error.serial_number}
        />
      </div>
      {type !== 'device' && <div
        className="edit-device-form-field"
        data-automation-id="input-serial_number"
      >
        <MainInput
          label={"Puerto"}
          required={true}
          id={"device-port"}
          name="port"
          value={device ? device.port : ""}
          handleChange={handleChange}
          maxLength={100}
          disabled={disabledFields && disabledFields.includes('port')}
          error={error.port && error.port}
        />
      </div>}

      <div
        className="edit-device-form-field"
        data-automation-id="input-name"
      >
        <MainInput
          label={"Nombre"}
          required={true}
          id={"device-name"}
          name="name"
          value={device ? device.name : ""}
          handleChange={handleChange}
          maxLength={100}
          disabled={disabledFields && disabledFields.includes('name')}
          error={error.name && error.name}
        />
      </div>
      <div
        className="edit-device-form-field"
        data-automation-id="input-node"
      >
        <MainDropdownInput
          required={true}
          id={"device-node"}
          name="node"
          selectedOption={device ? device.node : ""}
          handleSelection={(selectedOption) =>
            handleSelect(selectedOption, "node")
          }
          maxLength={50}
          options={nodes}
          placeholder={"Sucursal"}
          label={"Sucursal"}
          disabled={disabledFields && disabledFields.includes('node')}
          error={error.node && error.node}
        />
      </div>
      <div
        className="edit-device-form-field"
        data-automation-id="input-zone"
      >
        <MainDropdownInput
          id={"device-zone"}
          name="zone"
          selectedOption={device ? device.zone : ""}
          handleSelection={(selectedOption) => {
            handleSelect(selectedOption, "zone");
          }}
          maxLength={50}
          options={zones}
          placeholder={"Zona"}
          label={"Zona"}
          disabled={(!device || !device.node || device.node === "") || (disabledFields && disabledFields.includes('zone'))}
          error={error.zone && error.zone}
        />
      </div>
      <div
        className="edit-device-form-field"
        data-automation-id="input-sectors"
      >
        <MainDropdownInput
          required={false}
          id={"device-sectors"}
          name="sectors"
          selectedOption={device ? device.area : ""}
          handleSelection={(selectedOption) => {
            handleSelect(selectedOption, "sector");
          }}
          maxLength={50}
          options={sectors}
          placeholder={"Sector"}
          label={"Sector"}
          disabled={(!device || !device.zone || device.zone === "") || (disabledFields && disabledFields.includes('sector'))}
          error={error.area && error.area}
        />
      </div>

      {device && device?.device_type?.type === 'LIFT' &&
          <div className="edit-device-form-field" data-automation-id="input-image">
            <DragAndDropImages
              name="image"
              handleChange={handleChangeImage}
              handleDelete={handleDeleteImage}
              files={device.item_file ? [device.item_file] : []}
              showSelectorFiles={showSelectorFiles}
              msgInfoFiles={msgInfoFiles}
              msg={"Arrastra el vídeo aquí"}
              showStar={false}
              fileTypeAccepted={"video/mp4"}
            />
          </div>
        }
    </div>
  );
};

export default AddLocationForm;
