import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'

import useRequest from 'hooks/useRequest';
import usePagination from "hooks/usePagination";
import { isMobile } from "helpers/Mobile";

import i18n from "i18n";
import { EventRepository } from 'helpers/EventRepository';

import { steps } from './data/steps';
import Loader from "components/MainApp/atoms/Loader";
import AdminLayout from 'components/MainApp/layouts/DesktopLayout';
import StepProgress from 'components/MainApp/atoms/Wizard/StepProgress';
import StepOne from './Wizard/Step1';
import StepTwo from './Wizard/Step2';
import StepThree from './Wizard/Step3';

import './styles.scss';
import { PublicService } from 'services/PublicService.jsx';
import { ContainerService } from "services/ContainerService";

const initialEmbalaje = {
  id: '',
  type: '',
  sku: '',
  name: '',
  products: '',
  measure_height: '',
  measure_depth: '',
  measure_width: '',
  volume: '',
  max_weight: '',
  material: ''
}

const types = [
  {
    id: 'BOX',
    value: 'Caja'
  },
  {
    id: 'PALLET',
    value: 'Palet'
  }
]
const AddEmbalajeView = (props) => {
  const history = useHistory();
  const {
    loading, setLoading, beforeSubmit, afterSubmit,
    errors, dealWithError
  } = useRequest();
  const [activeStep, setActiveStep] = useState('0');
  const [embalaje, setEmbalaje] = useState(initialEmbalaje);

  const [productResultList, setproductResultList] = useState([]);
  const [containerResultList, setContainerResultList] = useState([]);
  const [resultList, setResultList] = useState([]);

  const [selectedToAddProductList, setselectedToAddProductList] = useState([]);
  const [addedList, setaddedList] = useState([]);
  const [paletType, setPaletType] = useState('BOX');
  const [editMode, setEditMode] = useState(false);

  const headerOptions = [];

  const [
    list,
    currentPage,
    pageSize,
    pageList,
    pageQuantity,
    changePageSize,
    changePage,
    changeList,
  ] = usePagination([], 0, 3);

  useEffect(() => {
    setLoading(true);
    if (props.match.params.key) {
      setEditMode(true)
    }
    PublicService.products({ page_size: 999999, ordering: "-created_at" }).then(
      (response) => {
        response.results = response.results.map((product) => {
          product.selected = false;
          product.units = 1;
          product.stock = 1;
          return product;
        });
        setproductResultList(response.results);
      }
    ).finally(() => setLoading(false));

    PublicService.containers({ page_size: 100, ordering: "-created_at", type: "BOX" }).then(
      (response) => {
        response.results = response.results.map((product) => {
          product.selected = false;
          product.units = 1;
          product.stock = 1;
          return product;
        });
        setContainerResultList(response.results);
      }
    );

  }, []);

  useEffect(() => {
    changeList([], currentPage, pageSize);

    if (embalaje.type.id === 'BOX') {
      setResultList(productResultList)
      setselectedToAddProductList([])
      setaddedList([])
    }

    if (embalaje.type.id === 'PALLET') {
      paletType === 'BOX' ? setResultList(containerResultList) : setResultList(productResultList)
      setselectedToAddProductList([])
      setaddedList([])
    }
  },
    [embalaje.type, paletType])


  useEffect(() => {
    if (!props.match.params.key) return;
    if (!productResultList.length || !containerResultList.length) return
    const id = props.match.params.key;
    let _resultList = [];



    ContainerService.container(id).then((response) => {

      if (!selectedToAddProductList.length) {
        response.type = types.find(t => t.id === response.type)
        setEmbalaje(response)

        if (response.type.id === 'BOX') {
          _resultList = productResultList
        }

        if (response.type.id === 'PALLET') {
          if (response.relations[0].type === 'PRODUCT') {
            setPaletType('UNITS');
            _resultList = productResultList;
          }
          if (response.relations[0].type === 'BOX') {
            setPaletType('BOX');
            _resultList = containerResultList;
          }
          //paletType === 'BOX' ? _resultList  = containerResultList :  _resultList = productResultList;
        }
      }
      let addedListNew = [...addedList];
      response.relations.forEach((product) => {
        const _product = _resultList.find(p => p.id === product.id)

        if (addedListNew.findIndex(p => p.id === product.id) === -1) {
          _product.units = product.quantity;
          addedListNew.push({ ..._product });
        } else {
          addedListNew.find(p => p.id === product.id).units = productResultList.find(p => p.id === product.id).units;
        }

        setaddedList(addedListNew);
        changeList(addedListNew, currentPage, pageSize);
      });

    });

  }, [paletType, productResultList, containerResultList])

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: 'error'
      });
    }

    if (['measure_height', 'measure_depth', 'measure_width', 'volume', 'max_weight', 'material'].includes(Object.keys(errors)[0])) {
      setActiveStep('1')
    }
    if (['name', 'type', 'products', 'sku'].includes(Object.keys(errors)[0])) {
      setActiveStep('0')
    }
  }

  const handleFinish = (e) => {
    e.preventDefault();
    beforeSubmit();
    embalaje.relations = addedList.map(item => {
      return {
        id: item.id,
        name: item.name,
        quantity: item.units,
        type: embalaje.type.id === 'PALLET' && paletType === 'BOX' ? 'BOX' : 'PRODUCT',
        sku: item.sku,
        brand: item.brand,
      }
    }
    )
    embalaje.type = embalaje.type.id
    if (!embalaje.material) {
      embalaje.material = null;
    }
    if (!embalaje.volume) {
      embalaje.volume = 0;
    }
    if (embalaje.id) {
      updateEmbalaje(embalaje)
    } else {
      createEmbalaje(embalaje)
    }
  }

  const createEmbalaje = (embalaje) => {

    ContainerService.createContainer(embalaje).then(embalaje => {
      afterSubmit();
      history.push(`/admin/embalajes`)
      EventRepository.notificationSend({
        label: i18n.t("Se creó embalaje de manera exitosa."),
        type: "success",
      });
    }).catch(error => {
      afterSubmit();
      embalaje.type = types.find(t => t.id === embalaje.type);
      dealWithError(error, 'generic.error', (message, errors) => {
        analizeErrors(message, errors);
      });
    })
  }


  const updateEmbalaje = (embalaje) => {

    delete embalaje.image
    ContainerService.updateContainer(embalaje.id, embalaje).then(embalaje => {
      afterSubmit();
      history.push(`/admin/embalajes`)
      EventRepository.notificationSend({
        label: i18n.t("Se actualizó el embalaje de manera exitosa."),
        type: "success",
      });
    }).catch(error => {
      afterSubmit();
      dealWithError(error, 'generic.error', (message, errors) => {
        analizeErrors(message, errors);
      });
    })
  }


  const goToList = () => {
    history.push(`/admin/embalajes`)
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let _embalaje = { ...embalaje }
    _embalaje[name] = value;

    setEmbalaje(_embalaje)
  }

  const handleSelect = (e, type) => {
    let _embalaje = { ...embalaje }
    _embalaje[type] = e.target.value;
    setEmbalaje(_embalaje);
  }



  const validateStep0 = () => {
    return ['name', 'type', 'relations', 'sku'].every(key => {
      return embalaje[key] !== ''
    }) && addedList.length > 0
  }

  const validateStep1 = () => {
    return ['measure_height', 'measure_width', 'measure_depth', 'max_weight'].every(key => {
      return embalaje[key] !== ''
    })
  }

  const goToStep = (step) => {
    setActiveStep(step)
  }

  const handleSelectToAddProduct = (product) => {
    let selectedToAddProductListNew = [...selectedToAddProductList];
    let ind = selectedToAddProductListNew.findIndex((p) => {
      return p.id === product;
    });
    if (ind !== -1) {
      selectedToAddProductListNew = selectedToAddProductListNew.filter((p) => {
        return p.id !== product;
      });
    } else {
      let newProduct = resultList.find((p) => {
        return p.id === product;
      });
      newProduct.selected = true;
      selectedToAddProductListNew.push(newProduct);
    }

    setselectedToAddProductList(selectedToAddProductListNew);
  };

  const handleSelectedProductQuantityChange = (productId, number) => {
    number = number === 0 ? 1 : number;
    let productResultListNew = [...resultList];
    let productNew = productResultListNew.find((p) => p.id === productId);

    productNew.units = number;
    setproductResultList(productResultListNew);
  };

  const handleAdd = () => {
    let addedListNew = [...addedList];
    selectedToAddProductList.forEach((product) => {
      let addedInd = addedList.findIndex((p) => {
        return p.id === product.id;
      });
      if (addedInd === -1) {
        addedListNew.push({ ...product });
      } else {
        let productSelected = {
          ...resultList.find((p) => p.id === product.id),
        };
        let productAddedIndex = addedListNew.findIndex(
          (p) => p.id === product.id
        );
        addedListNew[productAddedIndex].units =
          Number(addedListNew[productAddedIndex].units) +
          Number(productSelected.units);
      }

      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
    });

    let productResultListNew = resultList.map((p) => {
      p.units = 1;
      return p;
    });

    setResultList(productResultListNew);
    setselectedToAddProductList([]);
  };

  const handleAddProduct = (productId, number) => {
    number = number === 0 ? 1 : number;

    let addedListNew = [...addedList];
    let productAddedIndex = addedListNew.findIndex((p) => p.id === productId);
    addedListNew[productAddedIndex].units = Number(number);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  const handleDeleteAddedProduct = (productId) => {
    let addedListNew = [...addedList];

    addedListNew = addedListNew.filter((p) => p.id !== productId);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  const changePaletType = (typeLabel) => {

    if (typeLabel === 'Cajas') setPaletType('BOX')
    if (typeLabel === 'Unidades') setPaletType('UNITS')

  }

  const headerTitle = (editMode) ? 'Editar embalaje' : 'Nuevo embalaje';

  const step0 = <StepOne handleContinue={() => setActiveStep('1')}
    embalaje={embalaje}
    handleChange={handleChange}
    enableContinue={validateStep0()}
    error={errors}
    types={types}
    handleSelect={handleSelect}
    resultList={resultList}
    searchValue={""}
    products={isMobile ? addedList : pageList}
    totalProducts={addedList.length}
    handleSelectToAddProduct={handleSelectToAddProduct}
    handleSelectedProductQuantityChange={handleSelectedProductQuantityChange}
    handleAdd={handleAdd}
    handleAddProduct={handleAddProduct}
    selectedProducts={selectedToAddProductList.map((p) => p.id)}
    handleDeleteAddedProduct={handleDeleteAddedProduct}
    changePage={changePage}
    pagination={{
      pageQuantity: pageQuantity,
      currentPage: currentPage,
    }}
    changePaletType={changePaletType}
    paletType={paletType}
    editMode={editMode}
  >
  </StepOne>

  const step1 = (
    <StepTwo
      handleContinue={() => setActiveStep("2")}
      goBack={() => goToStep("0")}
      handleChange={handleChange}
      handleSelect={handleSelect}
      enableContinue={validateStep1()}
      embalaje={embalaje}
      error={errors}
    ></StepTwo>
  );

  const step2 = (
    <StepThree
      handleFinish={handleFinish}
      goBack={() => goToStep("1")}
      embalaje={embalaje}
      productsSelected={addedList}
      paletType={paletType}
    ></StepThree>
  );

  const stepList = {
    '0': step0,
    '1': step1,
    '2': step2,
  }

  const content = <div className={`add-embalaje-content`}>
    <div className={`add-embalaje-wrapper`}>
      <StepProgress steps={steps} activeStep={activeStep} />
      <div className={`add-embalaje-step-wrapper`}>
        {stepList[activeStep]}
      </div>
    </div>
  </div>

  return (
    <div className="add-embalaje-container">
      <AdminLayout
        headerTitle={i18n.t(headerTitle)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={goToList}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  )
}

export default AddEmbalajeView;