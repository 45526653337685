import i18n from "i18n";

const localizationsItems = [
  {
    title: i18n.t("Localización de Productos"),
    arrow: true,
    to: "/admin/localization-product",
    permission: 'read_stock',
  },
  {
    title: i18n.t("Localización de Tags"),
    arrow: true,
    to: "/admin/localization-tag",
    permission: 'read_stock',
  }
];

export const stepLocalizations = {
  items: localizationsItems,
  headerTitle: i18n.t("Localización"),
  navHidden: false,
  goBackTo: 0,
};
