import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback, useRef } from "react";

import { Card, CardContent } from "@material-ui/core";

import { ProductService } from "../../../../services/ProductService";

import ProductInfo from "components/MainApp/organisms/Product/ProductInfo";
import Loader from "../../../../components/MainApp/atoms/Loader";
import fondo from "../../../../assets/images/fondo.old.png";

import "./styles.scss";
import { Item } from "models/Items";
import { WSService } from "services/WSServices";
import DesktopLayoutTipshelf from "components/MainApp/layouts/DesktopLayoutTipshelf";
import DeviceSelector from "components/Functionals/DeviceSelector";
import MainButton from "components/MainApp/atoms/RoundedButton";
import { AuthService } from "services/AuthService";
import { DeviceService } from "services/DeviceService";
import useTokenExpirationCheck from "hooks/useTokenExpirationCheck";

export const LoadProductInfoView = () => {
  const [productRelatedItems, setProductRelatedItems] = useState(null);
  const [loadNewRelatedItems, setLoadNewRelatedItems] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [ws, setWs] = useState(null);
  let globalChannel = null;
  const [channel, setChannel] = useState(false);
  const [showCover, setShowCover] = useState(true);
  const [mainProduct, setMainProduct] = useState(null);
  const [historyProducts, setHistoryProducts] = useState([]);
  const [showSelectDevice, setShowSelectDevice] = useState(true);
  const [title, setTitle] = useState("Seleccionar Dispositivo");
  const [videoImage, setVideoImage] = useState(null);
  const [newProductFitting, setNewProductFitting] = useState();
  const [currentDevice, setCurrentDevice] = useState();
  const [hasInteracted, setHasInteracted] = useState(false);
  const timerId = useRef(null);
  const [lastDateRecived, setLastDateRecived] = useState(new Date());
  const minutesCheckTokenTime = 5; // 5 minutos

  useTokenExpirationCheck(minutesCheckTokenTime);

  useEffect(() => {
    var timerID = setInterval(() => {
      verifyClean()
    }, 2000);

    return () => clearInterval(timerID);
  });

  const verifyClean = () => {
    let now = new Date();

    const diffTime = Math.abs(now.getTime() - lastDateRecived.getTime());
    console.log(diffTime)
    if(currentDevice) {
      let timeClean = 30000;
      if(currentDevice.device_type.type == "FITTING"){
        timeClean = 10000;
      }
      if(diffTime > timeClean){
        handleClickClean();
      }
    }
  }

  useEffect(() => {
    if (mainProduct) {
      //if (mainProduct.item_files?.length > 0) {
      const existingHistoryProduct = historyProducts.find(
        product => product.id === mainProduct.id
      );
      if (!existingHistoryProduct) {
        setHistoryProducts([...historyProducts, mainProduct]);
      }
      //}

      if (loadNewRelatedItems) {
        ProductService.productRelatedItems(mainProduct.id).then(
          response => {
            setSubmitted(true);
            if (response.status === 304) {
            } else {
              setProductRelatedItems(response);
            }
            setSubmitted(false);
          },
          error => {
            setProductRelatedItems(null);
          }
        );
      }
    }
  }, [mainProduct]);

  useEffect(() => {
    if (mainProduct) {
      if (newProductFitting) {
        //  if (newProductFitting.item_files?.length > 0) {
        const existingHistoryProduct = historyProducts.find(
          product => product.id === newProductFitting.id
        );
        if (!existingHistoryProduct) {
          setHistoryProducts([...historyProducts, newProductFitting]);
        }
        // }
      }
    } else {
      setLoadNewRelatedItems(true);
      setMainProduct(newProductFitting);
    }


  }, [newProductFitting]);

  // -------- INIT socket operations ------------
  const canParseJson = str => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const receiveEvent = useCallback(payload => {
    const ws = WSService.getInstance();
    if (canParseJson(payload)) {
      payload = JSON.parse(payload);
    }

    if (payload.method == "SUBSCRIBE") {
      setChannel(payload.params.room);
    }

    if (
      payload.method === "TIPSHELF" &&
      payload.room &&
      ws.inRoom(payload.room)
    ) {
      if (payload?.params.length > 0) {
        setLastDateRecived(new Date());
        const item = new Item(payload.params[0]?.item);
        if (ws.devices) {
          let nameAtt = Object.keys(ws.devices)[0];
          if (ws.devices[nameAtt][0]["type"] == "FITTING") {
            setNewProductFitting(item)
            setShowCover(false);
          } else {
            setLoadNewRelatedItems(true);
            setMainProduct(item);
            setShowCover(false);
          }
        }
      }
    }
  }, []);
  // -------- END socket operations -------------

  const handleMouseDown = () => {
    if (!showSelectDevice) {
      setShowSelectDevice(true);
      setTitle("Seleccionar Dispositivo");
    }
  };

  const handleOpen = () => {
    setShowSelectDevice(false);
    setTitle("Detalle de Producto");
    setShowCover(true);
  };

  const handleClick = () => {
    // TODO implementar el envio de la notificacion
  };

  const onClickCover = () => {
    setShowCover(false);
  }

  const handleClickRecommended = item => {
    setMainProduct(item);
    setLoadNewRelatedItems(false);
  };

  const handleClickClean = item => {
    setShowCover(true);
    setMainProduct(null);
    setLoadNewRelatedItems(false);
    setHistoryProducts([]);
  };

  const handleHistoryProduct = historyProduct => {
    setMainProduct(historyProduct);
    setLoadNewRelatedItems(true);
  };

  const receiveDevicesSelected = (data) => {
    if (data != undefined) {
      let deviceID = data.devices[0];
      DeviceService.device(deviceID).then((device) => {
        setCurrentDevice(device);
        if (device.item_file?.file_name) {
          setVideoImage(device.item_file.file_name);
        }
      });
    }
  }

  useEffect(() => {
    globalChannel = channel;
  }, [channel]);

  const headerOptions = [];

  const content = (
    <>
      <Card
        mt={0}
        ml={0}
        style={{ display: showSelectDevice ? "block" : "none" }}
      >
        <CardContent>
          <DeviceSelector
            enabled={true}
            typeRead={"TIPSHELF"}
            refRead={""}
            receiveEvent={receiveEvent}
            maxDevicesSelected={1}
            receiveDevicesSelected={receiveDevicesSelected}
            includeType={"LIFT|FITTING"}
          // receiveDevicesSelected={receiveDevicesSelected}
          />
          <div className="btnContinue">
            <MainButton
              legend={"continuar"}
              state={"enabled"}
              handleClick={handleOpen}
            ></MainButton>
          </div>
        </CardContent>
      </Card>

      {!showSelectDevice && (
        <div className="load-single-product-content">
          <ProductInfo
            product={mainProduct}
            productRelatedItems={productRelatedItems}
            imgCover={
              mainProduct?.images?.length > 0
                ? mainProduct.images[0].url
                : fondo
            }
            showCover={showCover}
            videoImage={videoImage}
            handleClickRecommended={handleClickRecommended}
            historyProducts={historyProducts}
            onHistoryProductClick={handleHistoryProduct}
            onClickCover={onClickCover}
          />
        </div>
      )}
    </>
  );

  return (
    <div className="load-single-product-container">
      <DesktopLayoutTipshelf
        headerTitle={title}
        headerOptions={headerOptions}
        content={content}
        headerButtonLabel={"Solicitar Asistencia"}
        headerButtonHandler={handleClick}
        headerButtonState={"enabled"}
        navHidden={true}
        onHandleMouseDown={handleMouseDown}
        onHandleClickClean={handleClickClean}
      ></DesktopLayoutTipshelf>

      {submitted && <Loader />}
    </div>
  );
};

LoadProductInfoView.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string
};

export default LoadProductInfoView;
