import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import CustomInput from "components/MainApp/atoms/Forms/MainInput";
import "./styles.scss";
import { EventRepository } from "helpers/EventRepository";
import i18n from "i18n";

const dateLetter=[ 'D','L', 'M', 'M', 'J', 'V', 'S'];
const months=['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const DateInput = ({label, selectedDates=[null, null], handleChange}) => {
  const [selectedDateInteral, handleDateChange] = useState(selectedDates);
  const [focused, setFocused] = useState(false);
  const [fromDate, setFromDate] = useState(undefined);
  const [validDate, setValidDate] = useState(undefined);

  useEffect(() => {
    if(!focused){
      if(selectedDateInteral[0] > selectedDateInteral[1]){
        EventRepository.notificationSend({
          label: i18n.t("La fecha desde no puede ser superior a la fecha hasta."),
          type: "error",
        });
        handleDateChange(validDate);
        handleChange(validDate);
      }
    }

  }, [focused])

  const _handleChange = (_selectedDates) => {
    handleDateChange(_selectedDates)
    if(_selectedDates[0] < _selectedDates[1]){
      setValidDate(_selectedDates);
      handleChange(_selectedDates);
    }else {
      let valid = [..._selectedDates]
      valid[1] = _selectedDates[0];
      setValidDate(valid);
    }

  }

  return (
    <div className={`date-range-container ${focused ? 'focused' : ''}`}>
      <p className={'date-range-label'}>{label}</p>
      <DateRangePicker
        onChange={_handleChange}
        value={selectedDateInteral}
        dayPlaceholder=""
        monthPlaceholder=""
        yearPlaceholder=""
        calendarAriaLabel="open"
        locale="es-ES"

        monthAriaLabeL='Month'
        formatShortWeekday={(locale, date) => dateLetter[date.getDay()]}
        formatMonthYear={(locale, date) => months[date.getMonth()] + " " + date.getFullYear()}
        onCalendarOpen={() => setFocused(true)}
        onCalendarClose={() => setFocused(false)}
        calendarIcon={
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" className="react-daterange-picker__calendar-button__icon react-daterange-picker__button__icon" fill="black" >
            <path id="ic_date_range_24px" d="M9,11H7v2H9Zm4,0H11v2h2Zm4,0H15v2h2Zm2-7H18V2H16V4H8V2H6V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,19,4Zm0,16H5V9H19Z" transform="translate(-3 -2)" fill="currentColor"/>
          </svg>
        }
      />
    </div>
  );
};

export default DateInput;
