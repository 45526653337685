import React, { useState } from "react";
import { isMobile } from "helpers/Mobile";

import InputText from "components/MainApp/atoms/Forms/MainInput";

import DeviceSelector from "components/Functionals/DeviceSelector";

import "./styles.scss";

const ReadingPanel = ({
  enabledRead,
  typeRead,
  refRead,
  receiveEvent,

  handleSearch,
  handleClear,

  total,
  read,
}) => {
  const [searchedValue, setsearchedValue] = useState("");

  const searchValue = (e) => {
    setsearchedValue(e.target.value.trim());
  };

  const handleSubmit = function (e) {
    e.preventDefault();
    handleSearch(searchedValue);
    setsearchedValue("");
  };

  const toggle = (
    <DeviceSelector 
      enabled={enabledRead} 
      typeRead={typeRead} 
      refRead={refRead} 
      receiveEvent={receiveEvent} 
      includeType={"MOB_READER"}
    />
  );
  return (
    <div className={"reading-panel-outer"}>
      {isMobile && toggle}
      <div className={"reading-panel-container"}>
        <div className={"reading-panel-top"}>
          {!isMobile && toggle}
        </div>
        <div className="reading-panel-bottom">
          <div className="reading-panel-left">
            <div className={"reading-panel-left-top"}>

              <div className="reading-panel-label">
                Ingresa el nro. o escanea TagID
            </div>

            </div>
            <form className="reading-panel-input" onSubmit={handleSubmit}>
              <InputText
                label="Ingresa número"
                handleChange={searchValue}
                value={searchedValue}
              />
            </form>
          </div>
          <div className={"reading-panel-center"}>
            <p className={"reading-panel-count"}>
              <span>{read}</span> / {total}
            </p>
          </div>
          <div className={"reading-panel-right"}>
            <button className="reading-panel-reset" onClick={handleClear}>LIMPIAR</button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ReadingPanel;
