import React from "react";

import Icon from '../../atoms/Icon/icon';
import "./styles.scss";

const ExtraMenuItem = ({title, arrow}) => {
    return(
        <div className="extra-menu-item-container">
            <p className="extra-menu-item-title" >{title}</p>
            {arrow && <Icon name="chevron-right" width={8} height={16} fill={"#3C7AF5"}/>}
        </div>
    )
}

export default ExtraMenuItem