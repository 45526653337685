import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import GenericFormContainer from "../../../helpers/GenericFormContainer";
import { Validations } from "../../../helpers/validations";

import { UserService } from "../../../services/UserService";

import AuthGeneric from "../../../components/Auth/layouts/AuthGeneric";
import CostumInput from "../../../components/Auth/atoms/forms/InputCostum";
import RoundedButton from "../../../components/Auth/atoms/buttons/RoundedButton";
import Logo from "../../../components/Auth/atoms/images/Logo";

export class ForgottenPasswordView extends GenericFormContainer {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      userData: {
        email: null,
      },
      buttonState: "disabled",
    };
  }

  handleGoBack = () => {
    this.props.history.goBack();
  };

  handleChange = (e) => {
    let id = e.target.id;
    let userData = { ...this.state.userData };
    userData[id] = e.target.value;
    this.setState(
      {
        userData: userData
      }
    );
  };

  handleBlur = (event) => {
    let userData = { ...this.state.userData };
    this.validateFieldsAfterChange(userData);
  }

  validateFieldsAfterChange = (userData) => {
    let errors = { ...this.state.errors };

    if (!Validations.email(userData.email) && userData.email !== "") {
      errors.email = "Ingresa un email válido.";
    } else {
      errors.email = null;
    }

    this.setState(
      {
        errors,
      },
      (_) => {
        this.updateButtonState(userData);
      }
    );
  };

  updateButtonState = (userData) => {
    if (userData.email && !this.state.errors.email) {
      this.setState({
        buttonState: "enabled",
      });
    } else {
      this.setState({
        buttonState: "disabled",
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.beforeSubmit();
    const data = {
      email: this.state.userData.email,
    };
    UserService.resetPassword(data)
      .then((data) => {
        this.afterSubmit();
        this.props.history.push({
          pathname: "/email-verification",
          state: {
            email: this.state.userData.email,
            type: "forgot",
          },
        });
      })
      .catch((error) => {
        this.afterSubmit();
        this.dealWithError(error, "Hubo un error");
      });
  };

  render() {
    const content = (
      <div className="forgotten-password">
        <form onSubmit={this.handleSubmit}>
          <Logo></Logo>
          <p className="title">
            <i className="icon-back forgotten-password-back" onClick={this.handleGoBack}></i>Olvidé mi contraseña
          </p>
          <p className={"subtitle"}>
            Ingresa tu email para<br></br>reestablecer tu contraseña
          </p>
          <div className="form-container">
            <div className="inputs-wrapper">
              <CostumInput
                placeholder="Email"
                icon="icon-mail"
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                id={"email"}
                error={this.state.errors.email}
              ></CostumInput>
            </div>
          </div>
          <div className="rounded-button-wrapper">
            <RoundedButton
              legend="Enviar"
              handleClick={this.handleSubmit}
              state={this.state.buttonState}
            ></RoundedButton>
          </div>
        </form>
      </div>
    );

    return (
      <div className="forgotten-password-container">
        <AuthGeneric
          title={"Olvidé mi contraseña"}
          version={"header"}
          content={content}
          handleGoBack={this.handleGoBack}
        ></AuthGeneric>
      </div>
    );
  }
}

ForgottenPasswordView.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string,
};

export default ForgottenPasswordView;
