import React from "react";
import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";
import Icon from "components/MainApp/atoms/Icon/icon";
import BarcodeImg from "./CodigoBarras.svg";
import "./styles.scss";

const Item = ({
  tag,
  handleDelete,
  handleSelect,
  selected,
  type
}) => {
  return (
    <div className="tag-container">
      <div className={"left-container"}>
        {handleSelect && (
          <div className="tag-selector" onClick={() => handleSelect(tag)}>
            <Checkbox checked={selected} />
          </div>
        )}
        <div className="tag">
          <div className={type !== undefined ? "tag-rfid" : "tag-rfid tag-rfid-step2"}>{tag.rfid}</div>
          <div className={type !== undefined ? "tag-epc" : "tag-epc tag-epc-step2"}>{tag.epc}</div>
          {tag.barcode && (
            <>
              <img src={BarcodeImg} />
              <div className={"barcode"}>{tag.barcode}</div>
            </>
          )}
        </div>
      </div>

      {handleDelete && (
        <div onClick={() => handleDelete(tag)}>
          <Icon name="trash_can" width={20} height={20} fill={"#3C7AF5"} />
        </div>
      )}

    </div>
  );
};

export default Item;
