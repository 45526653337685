import React from "react";
import Icon from "../../atoms/Icon/icon.jsx";
import { isMobile } from "../../../../helpers/Mobile";
import "./styles.scss";

const LocationPanel = ({ title, type, items, handleAdd, handleClick }) => {
  return (
    <div className="location-panel">
      <div className="location-panel-header">
        <h1 className="location-panel-header-title">
          {title} ({items.length})
        </h1>
        {handleAdd && (
          <p className="location-panel-add-button" onClick={handleAdd}>
            <Icon name="add" heigth={12} width={12} fill={"#3C7AF5"} />
            Agregar {type}
          </p>
        )}
      </div>
      <div className="location-panel-items">
        {items &&
          items.length > 0 &&
          items.map((item) => {
            return (
              <div
                className="location-panel-item"
                key={item.id}
                onClick={() => handleClick(item.id, type)}
              >
                <div className="location-panel-item-name">{item.name}</div>
                <div className="location-panel-icon">
                  <Icon
                    name="chevron-right"
                    heigth={isMobile ? 5 : 10}
                    width={isMobile ? 5 : 10}
                    fill={"#3C7AF5"}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LocationPanel;
