import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
export class MainInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: this.props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value
      })
    }
  }

  /**
   * permitimos: números, teclas de borrado, Tab, flechas y shortkey de pegado en mac y windows
   * @param {*} e Evento
   */
  handleKeyDown = (e) => {
    const pass = e?.key.match('[0-9]')
                  || e?.key.match('(Backspace|Tab|Delete|Arrow).*')
                  || (e.ctrlKey && e.key === 'v')
                  || (e.metaKey && e.key === 'v');
    if(!pass)e.preventDefault();
  };

  handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      value,
    });
    if (this.props.handleChange) this.props.handleChange(e);
  };

  handleBlur = (e) => {
    e.preventDefault();
    this.setState({
      focused: false,
    });
    if(this.props.handleBlur) this.props.handleBlur(e);
  };

  handleFocus = (e) => {
    e.preventDefault();
    this.setState({
      focused: true,
    });
  };

  showInputHanlder = () => {
    this.setState({
      focused: true,
    });
  };

  render() {
    const {
      label,
      required,
      type = "text",
      name,
      disabled,
      error,
      maxLength,
      onlyNumbers = "",
    } = this.props;

    return (
      <div className="main-input-container">
          {!this.state.focused &&
            (this.state.value === undefined || this.state.value === "") && (
              <div className="main-input-label-placeholder">
                {label}
                {" "}
                {required && (
                  <span className="main-input-required-label">*</span>
                )}
              </div>
            )}
        <div className={`main-input-wrapper ${this.state.focused ? 'focused' :''} ${disabled ? 'disabled' :''} ${error ? 'error' :''}`}>


          <React.Fragment>
            {(this.state.focused ||
              (this.state.value !== undefined && this.state.value !== "")) && (
              <div className={`main-input-label ${this.state.focused ? 'focused' : ''}  ${error ? 'error' : ''}`}>
                {label}{" "}
                {required && (
                  <span className="main-input-required-label">*</span>
                )}
              </div>
            )}

            <input
              type={type}
              value={this.state.value || ""}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onKeyDown={onlyNumbers != "" ? this.handleKeyDown : undefined}
              id={name}
              name={name}
              data-automation-id="input"
              maxLength={maxLength}
            ></input>
          </React.Fragment>
        </div>
        <div className="main-input-error">
          {error}
        </div>

        {disabled && <div className="main-input-overlay"></div>}
      </div>
    );
  }
}

MainInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default MainInput;
