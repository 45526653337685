import React, { Component } from 'react';
import { EventRepository } from 'helpers/EventRepository';

class GenericFormContainer extends Component {
  timeoutShow = null;

  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      success: true,
      errors: {},
      message: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  loading() {
    document.body.style.cursor = 'wait';
  }

  stopLoading() {
    document.body.style.cursor = 'default';
  }

  beforeSubmit() {
    this.setState({
        submitted: true
    })
    this.resetAlerts();
    this.loading();
  }

  showSuccess(message) {
    this.setState({
        success: true,
        message: message
    });
    this.resetAlertsTimeOut();
  }

  showError(message, errors = {}) {
    EventRepository.notificationSend({
      label: message,
      type: 'error'
    });
    this.setState({
        success: false,
        message: message,
        errors: errors
    });
    this.resetAlertsTimeOut();
  }

  afterSubmit() {
    this.setState({
        submitted: false
    })
    this.stopLoading();
  }    

  resetAlerts() {
    this.setState({
        errors: {},
        message: null
    });
  }

  resetAlertsTimeOut() {
    // clearTimeout(this.timeoutShow);
    // this.timeoutShow = setTimeout(() => {
    //     this.resetAlerts()
    // }, 3000);    
  }

  changeValueState(code, name, value) {
    let data = Object.assign({}, this.state[code]);
    data[name] = value;
    this.setState({
      [code]: data
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  dealWithError(response, codeMsgError = "generic.error", callback=null) {
    if ([400, 401, 409].includes(response.status)) {
        response.json().then((data) => {
            if (Array.isArray(data)) {
                this.showError(data.join(", "));
            } else if ('detail' in data) {
                this.showError(data['detail']);
            } else if ('message' in data && 'errors' in data) {
                this.showError(data['message'], data['errors']);
            } else {
                this.showError(codeMsgError, data);
            }
            if (callback) callback();
        });
    } else {
        this.showError(codeMsgError);
        if (callback) callback();      
    }
  }

  render(){
    return(<div></div>)
  }
}

export default GenericFormContainer;
