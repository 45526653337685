import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";

import { ClientService } from "services/ClientService";

import useRequest from "hooks/useRequest";
import { isMobile } from "helpers/Mobile";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";

import MainNotification from "components/MainApp/atoms/Notification";
import GenericTable from "components/MainApp/organisms/GenericTable";
import Pagination from "components/MainApp/atoms/Pagination";

import NodeImg from "./storefront-24px.svg";
import columns from "./columns";

import "./styles.scss";
import { PublicService } from "../../../services/PublicService";
import { NotFoundItems } from "../components/NotFoundItems";
import useFilters from "hooks/useFilters";
import FilterPanel from "components/MainApp/organisms/Filters/FilterPanel";

let scrolling = false;

const initialFilters = {
  page_size: 10,
  search: "",
  ordering: "-entry_date",
  enabled: undefined
};
const filterTypeMap = {
  "Estado del cliente": {
    name: "enabled",
    type: "twoOption",
    default_value: undefined
  },
  "Ordenar por": {
    name: "ordering",
    type: "twoOption",
    default_value: "-entry_date"
  }
};
const filterDataSource = [
  {
    type: "twoOption",
    options: [
      {
        selected: false,
        label: "Habilitado",
        value: true
      },
      {
        selected: false,
        label: "Inhabilitado",
        value: false
      }
    ],
    label: "Estado del cliente",
    firstOptionLabel: "Todos",
    name: "enabled"
  },
  {
    type: "dropdown",
    label: "Ordenar por",
    firstOptionLabel: "Más reciente",
    value: "-entry_date",
    options: [
      {
        selected: false,
        label: "Menos reciente",
        value: "entry_date"
      }
    ],
  }
];


export const ClientListView = () => {
  const history = useHistory();
  const [noClients, setnoClients] = useState(false);
  const [isSearched, setSearched] = useState(false);
  const [clients, setclients] = useState([]);
  const [clientTable, setclientTable] = useState([]);
  const [filterData, setFilterData] = useState(filterDataSource);
  const [clientsFilters, setClientsFilters] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const isFirstRun = useRef(true);
  const [pager, setPager] = useState({
    next: null,
    previous: null,
    count: 0,
    countPages: 0
  });
  const [page, setPage] = useState(1);
  let pageSize = 10;

  const { loading, beforeSubmit, afterSubmit } = useRequest();

  const [
    filters,
    setFilters,
    selectedFiltersAll,
    selectedFiltersByType,
    handleApplyFilters,
    handleSearch,
    headerOrderingHandler,
    resetOrdering,
    handleFiltersChangeOrdering,
    applied,
    items,
    setItems,
    header,
    setHeader,
    mobilePanelOpen,
    setMobilePanelOpen
  ] = useFilters(
    initialFilters,
    clientsFilters,
    filterTypeMap,
    isMobile,
  );

  useEffect(() => {
    fillFilterData();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  useEffect(() => {
    if(isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }
    let firstRun = false;
    if (isFirstRun.current) {
      firstRun = true;
      isFirstRun.current = false;
    }
    filter({ page: page, page_size: pageSize }, firstRun);
  }, [page]);

  useEffect(() => {
    const page_number = isMobile ? 1 : page;
    setPage(page_number);
    filter({ page: page_number, page_size: pageSize }, false, true);

    setSearched(false);
    setClientsFilters({
      filters: filters,
      selectedFiltersAll: selectedFiltersAll,
      selectedFiltersByType: selectedFiltersByType
    });
    setMobilePanelOpen(false);
  }, [filters]);

  const filter = (params, first = false, cleanProviders = false) => {
    let append = isMobile;
    if (!append) {
      setItems([]);
    }

    beforeSubmit();
    const data = { ...filters, page: params.page };
    ClientService.clientsPaginated(data)
      .then(response => {
        let newClients =
          isMobile && page !== 1 && !cleanProviders
            ? [...clients, ...response.results]
            : response.results;

        if (first && newClients.length < 1) setnoClients(true);

        const _ = require('lodash');

        let countriesPromise = _.memoize(PublicService.countries);
        let regionsPromise = _.memoize(PublicService.regions);
        let statesPromise = _.memoize((country, region) => PublicService.states(country, region));
        let citiesPromise = _.memoize((country, region, state) => PublicService.cities(country, region, state));

        let clientLocationDataPromises = [];
        newClients = newClients.map(client => {
          clientLocationDataPromises.push(
            new Promise((resolve, reject) => {
              Promise.all([
                countriesPromise(),
                regionsPromise(client.country),
                statesPromise(client.country, client.region),
                citiesPromise(client.country, client.region, client.state),
              ]).then(responses => {
                client.country = responses[0].find(c => c.value === client.country);
                client.locality = responses[3].find(c => c.value === client.locality);
                client.region = responses[1].find(c => c.value === client.region);
                client.state = responses[2].find(c => c.value === client.state);
                resolve(client);
              });
            })
          );
        });
        Promise.all(clientLocationDataPromises).then(processedClients => {
          setclients(processedClients);
          const table = processedClients.map(client => client.getGenericTableView());
          setclientTable(table);
          setPager({
            next: response.next,
            previous: response.previous,
            count: response.count,
            countPages: Math.ceil(response.count / pageSize)
          });
          scrolling = false;
          afterSubmit();
          setSearched(true);
        });
      })
      .catch(_ => {
        afterSubmit();
        setSearched(true);
        scrolling = false;
      });
  };

  const handleScroll = event => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || loading || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const fillFilterData = function() {
    let filterDataAux = [...filterData];
    filterDataAux[0].options = filterDataAux[0].options.map(option => {
      option.selected = false;
      return option;
    });
    setFilterData(filterDataAux);
  };

  const goBack = () => {
    history.push("/admin/more-options/1");
  };

  const handlePagerChange = function(page) {
    setPage(page);
  };

  const createClient = () => {
    history.push(`/admin/clients/add`);
  };

  const goToClient = code => {
    history.push(`/admin/clients/detail/${code}`);
  };

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: createClient,
      tooltip: "Agregar clientes"
    }
  ];

  const tableContentDesktop = (
    <React.Fragment>
      <div className={`client-list-content`}>
        <FilterPanel
          resultList={[]}
          placeholder="Buscar"
          searchValue={filters.search}
          handleSubmitSearch={handleSearch}
          filters={filterData}
          selectedFiltersAll={selectedFiltersAll}
          selectedFiltersByType={selectedFiltersByType}
          handleApplyFilters={handleApplyFilters}
        ></FilterPanel>
        {clients.length > 0 && (
          <div className={`client-list-table`}>
            <GenericTable
              items={clientTable}
              totalItems={pager.count}
              columns={columns}
              handleClick={goToClient}
            />
            {!isMobile && (
              <div className="table-pagination">
                <Pagination
                  pageQuantity={pager.countPages}
                  currentPage={page}
                  pageChangeHandler={handlePagerChange}
                />
              </div>
            )}
          </div>
        )}
        {clients.length === 0 && isSearched && (
          <NotFoundItems />
        )}
      </div>
    </React.Fragment>
  );

  const tableContentMobile = (
    <React.Fragment>
      <div
        className={`client-table-mobile-panel ${
          mobilePanelOpen ? "opened" : ""
        } `}
      >
        <FilterPanel
          resultList={[]}
          placeholder="Buscar"
          searchValue={filters.search}
          handleSubmitSearch={handleSearch}
          filters={filterData}
          selectedFiltersAll={selectedFiltersAll}
          selectedFiltersByType={selectedFiltersByType}
          handleApplyFilters={handleApplyFilters}
          closeMobilePanel={() => {
            setMobilePanelOpen(false);
          }}
        ></FilterPanel>
        </div>
      <div className={`client-table-table`}>
        <div className="client-table-top-table">
          <div className="client-table-top-total">
            Total{" "}
            <span className="client-table-top-total-bold">({pager.count})</span>
          </div>
          <div
            className={`client-table-top-sort ${
              applied ? "client-table-active-filter" : ""
            }`}
            onClick={() => {
              setMobilePanelOpen(true);
            }}
          >
            <i className="icon-iconos_filtros client-table-top-icon"></i>
          </div>
        </div>
        {clients.length > 0 && (
          <div className={`client-list-table`}>
            <GenericTable
              items={clientTable}
              // totalItems={pager.count}
              columns={columns}
              handleClick={goToClient}
            />
            {!isMobile && (
              <div className="table-pagination">
                <Pagination
                  pageQuantity={pager.countPages}
                  currentPage={page}
                  pageChangeHandler={handlePagerChange}
                />
              </div>
            )}
          </div>
        )}
        {clients.length === 0 && isSearched && (
          <NotFoundItems />
        )}
      </div>
    </React.Fragment>
  );

  const tableContent = isMobile ? tableContentMobile : tableContentDesktop;

  const noClientContent = (
    <div className="client-no-client-content">
      <img src={NodeImg} />
      <div className="client-no-client-title">
        Aún no tienes clientes creados. ¡Crea tu primer cliente!
      </div>
      <div className="client-create-button" onClick={createClient}>
        CREAR
      </div>
    </div>
  );

  const clientsElement = noClients ? noClientContent : tableContent;

  const content = (
    <div className="outter-wrapper">
      {isMobile && (
        <SimpleSearchPanel placeholder="Buscar" handleSubmit={handleSearch} />
      )}

      <div className="table-client-wrapper">{clientsElement}</div>
    </div>
  );

  return (
    <div className="client-list-container">
      <AdminLayout
        headerTitle={i18n.t("Clientes")}
        headerOptions={noClients ? [] : headerOptions}
        content={content}
        goBackFunc={goBack}
        navHidden={false}
      ></AdminLayout>
      {loading && <Loader />}
      <MainNotification
        label={"Tu cliente se actualizó de manera exitosa."}
        open={false}
      ></MainNotification>
    </div>
  );
};

export default ClientListView;
