import React, { useState, useEffect, useRef } from "react";
import useFilters from "hooks/useFilters";
import { useHistory, useLocation } from "react-router-dom";
import { useStore } from "store/context";
import i18n from "i18n";
import { isMobile } from "helpers/Mobile";

import { UserService } from "services/UserService";

import "./styles.scss";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import GenericTable from "components/MainApp/organisms/GenericTable";
import Icon from 'components/MainApp/atoms/Icon/icon';

import Search from "components/MainApp/atoms/Filters/Search";
import FilterPanel from "components/MainApp/organisms/Filters/FilterPanel";
import headers from "./headers";
import Pagination from "components/MainApp/atoms/Pagination";
import Loader from "components/MainApp/atoms/Loader";
import { GenericTableItem } from "components/MainApp/organisms/GenericTable/GenericTableItem/GenericTableItem.stories";
import { NotFoundItems } from "views/Admin/components/NotFoundItems";


const initialFilters = {
  page_size: 10,
  search: "",
  ordering: "name",
  enabled: undefined,
};
const filterTypeMap = {
  "Estado del rol": {
    name: "enabled",
    type: "twoOption",
    default_value: undefined,
  },
  "Ordenar por": {
    name: "ordering",
    type: "twoOption",
    default_value: "name",
  },
};
const filterDataSource = [
  {
    type: "twoOption",
    options: [
      {
        selected: false,
        label: "Habilitado",
        value: true,
      },
      {
        selected: false,
        label: "Inhabilitado",
        value: false,
      },
    ],
    label: "Estado del rol",
    firstOptionLabel: "Todos",
    name: "enabled",
  },
];

export const UsersView = () => {
  const [{ rolesFilters }, dispatch] = useStore();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [noRoles, setNoRoles] = useState(false);
  const [filterData, setFilterData] = useState(filterDataSource);
  const [isSearched, setSearched] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const myRef = useRef(0);

  let scrolling = false;

  const [
    filters,
    setFilters,
    selectedFiltersAll,
    selectedFiltersByType,
    handleApplyFilters,
    handleSearch,
    headerOrderingHandler,
    resetOrdering,
    handleFiltersChangeOrdering,
    applied,
    page,
    setPage,
    items,
    setItems,
    pager,
    setPager,
    header,
    setHeader,
    mobilePanelOpen,
    setMobilePanelOpen,
  ] = useFilters(
    initialFilters,
    rolesFilters,
    filterTypeMap,
    isMobile,
    headers
  );

  useEffect(() => {
    fillFilterData();
  }, []);

  useEffect(() => {
    if (!location.search?.includes('from=edit')) {
      filters.search = '';
    }

    const first_load =
      JSON.stringify(initialFilters) === JSON.stringify(filters);
    filter(first_load);
    // Acomodar orden
    const keyOrdering =
      filters.ordering.charAt(0) === "-"
        ? filters.ordering.substr(1)
        : filters.ordering;
    if (keyOrdering in headers) {
      const newHeaders = { ...header };
      newHeaders[keyOrdering]["active"] = true;
      newHeaders[keyOrdering]["direction"] =
        filters.ordering === keyOrdering ? true : false;
      setHeader(newHeaders);
    }
  }, []);

  useEffect(() => {
    if(isFirstLoad){
      setIsFirstLoad(false);
      return;
    }
    filter(false);
    dispatch({
      type: "setRolesFilters",
      payload: {
        filters: filters,
        selectedFiltersAll: selectedFiltersAll,
        selectedFiltersByType: selectedFiltersByType,
      },
    });
  }, [filters, page]);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  const handleScroll = (event) => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || submitted || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const handlePagerChange = function (page) {
    setPage(page);
  };

  const handleKeyDown = function (e) {
    if(e.keyCode === 13){
      setSearched(false);
    }
  }

  const filter = function (firstLoad = false) {
    setSearched(false);
    let append = isMobile;
    if (!append) {
      setItems([]);
    }

    //BEFORE SUBMIT
    document.body.style.cursor = "wait";
    setSubmitted(true);

    const data = { ...filters, page: page };

    UserService.roles(data)
      .then((response) => {
        setSubmitted(false);
        document.body.style.cursor = "default";
        scrolling = false;

        if (firstLoad && response.count === 0) {
          //   history.push("/admin/users/add");
          setNoRoles(true);
          return;
        }

        const newItems = append
          ? [...items, ...response.results]
          : response.results;

        setLoading(false);
        setItems(newItems);
        setPager({
          next: response.next,
          previous: response.previous,
          count: response.count,
          countPages: Math.ceil(response.count / filters.page_size),
        });
        setSearched(true);
      })
      .catch((e) => {
        setSubmitted(false);
        setSearched(true);
        document.body.style.cursor = "default";
        scrolling = false;
      });
  };

  const fillFilterData = function () {
    let filterDataAux = [...filterData];
    filterDataAux[0].options = filterDataAux[0].options.map((option) => {
      option.selected = false;
      return option;
    });

    if (isMobile) {
      filterDataAux[1] = {
        type: "twoOption",
        options: [
          {
            selected: false,
            label: "Ascendente",
            value: "name",
          },
        ],
        label: "Ordenar por",
        firstOptionLabel: "Descendente",
        value: "-name",
      };
    }
    setFilterData(filterDataAux);
  };

  const handleSelect = (id) => {
    if (location.state?.readOnlyTable) {
      let user = { ...location.state.user }
      let selectedBusinesses = location.state.selectedBusinesses ? location.state.selectedBusinesses : [];
      location.state.user.roles = items.find(item => item.id === id)
      history.push({
        pathname: '/admin/users/' + (user.id ? `edit/${user.id}/` : 'add'),
        state: location.state
      });
    } else {
      history.push(
        `/admin/roles/edit/${id}`
      );
    }

  };

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: () => {
        history.push("/admin/roles/add");
      },
      tooltip: "Agregar rol",
    },

  ];


  const headerButtons = (
    <div
      className="header-button"
      onClick={() => history.push('/admin/users')}
      style={{
        width: "30px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon
          name={'account'}
          fill={isMobile ? "#0000003d" : "#0000003d"}
          width={isMobile ? 26 : 40}
          height={28}
        ></Icon>
    </div>
  );


  const tableContentDesktop = (
    <React.Fragment>
      <FilterPanel
        resultList={[]}
        placeholder="Busca por nombre o código"
        searchValue={filters.search}
        handleSubmitSearch={handleSearch}
        filters={filterData}
        selectedFiltersAll={selectedFiltersAll}
        selectedFiltersByType={selectedFiltersByType}
        handleApplyFilters={handleApplyFilters}
      ></FilterPanel>

      {items.length > 0 && (
        <div className="roles-table-table">
          <div className="roles-table-top-table">
            <div className="roles-table-top-total">
              TOTAL{" "}
              <span className="roles-table-top-total-bold">
                ({pager.count})
              </span>
            </div>
            <div className="roles-table-top-sort">

            </div>
          </div>
          <GenericTable
            items={items}
            columns={header}
            handleClick={handleSelect}
          />
        </div>
      )}

      {items.length === 0 && isSearched && (
        <NotFoundItems />
      )}
      {items.length > 0 && (
        <div>
          <Pagination
            pageQuantity={pager.countPages}
            currentPage={page}
            pageChangeHandler={handlePagerChange}
          />
        </div>
      )}
    </React.Fragment>
  );

  const tableContentMobile = (
    <React.Fragment>
      <div className="roles-table-filter-container">
        <Search
          resultList={[]}
          placeholder="Busca por nombre o código"
          searchValue={filters.search}
          handleSubmit={handleSearch}
          handleApplyFilters={handleApplyFilters}
          handleKeyDown={handleKeyDown}
        ></Search>
      </div>
      <div
        className={`roles-table-mobile-panel ${mobilePanelOpen ? "opened" : ""
          } `}
      >
        {/* <FilterPanel
          resultList={[]}
          placeholder="Busca por nombre o código"
          searchValue={filters.search}
          handleSubmitSearch={handleSearch}
          filters={filterData}
          selectedFiltersAll={selectedFiltersAll}
          selectedFiltersByType={selectedFiltersByType}
          handleApplyFilters={handleApplyFilters}
          closeMobilePanel={() => {
            setMobilePanelOpen(false);
          }}
        ></FilterPanel> */}
      </div>
      <div className="roles-table-table">
        <div className="roles-table-top-table">
          <div className="roles-table-top-total">
            Total{" "}
            <span className="roles-table-top-total-bold">
              ({pager.count})
            </span>
          </div>
        </div>
        <GenericTable
          showMessageNoneResult={false}
          items={items}
          columns={headers}
          handleClick={handleSelect}
        />
      </div>

      {items.length === 0 && isSearched && (
        <NotFoundItems />
      )}
    </React.Fragment>
  );

  const tableContent = isMobile ? tableContentMobile : tableContentDesktop;

  const noproductcontent = (
    <div className="table-roles-no-content">
      <p className="table-roles-title">
        Aún no tienes roles creados.
    </p>
      <p className="table-roles-title">
        ¡Crea tu primer rol!
    </p>

      <div className="table-roles-button" onClick={() => history.push("/admin/roles/add")}>CREAR</div>
    </div>
  );
  const rolesContent = noRoles ? noproductcontent : tableContent;

  const content = (
    <div className="roles-table-wrapper" ref={myRef}>
      <div className="roles-table-inner">
        {loading ? <Loader /> : rolesContent}
      </div>
    </div>
  );

  const goBack = () => {
    history.push("/admin/more-options/1");
  };

  return (
    <div className="roles-container">
      <AdminLayout
        headerTitle={i18n.t("Roles")}
        headerOptions={headerOptions}
        customButtons={headerButtons}
        content={content}
        goBackFunc={goBack}
        navHidden={false}
      ></AdminLayout>
    </div>
  );
};

export default UsersView;
