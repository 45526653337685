import React, { useState, useEffect } from "react";

import ItemLongSummaryItem from "components/MainApp/organisms/OrdersV2/Items/ItemLongSummary/ItemLongSummaryItem";
import LocationSelectorModal from "components/MainApp/atoms/Filters/SearchWithComponentsLocations/LocationSelectorModal";

import NumberInput from "components/MainApp/atoms/Forms/NumberInput";

import "./styles.scss";
const ProductLocationResult = ({
  product,
  locations,
  handleClick,
  handleChangedLocations,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [_locations, setlocations] = useState(locations);

  const openLocationSelector = () => {
    setModalOpen(true);
  };

  const handleModalClose = (newlocations) => {
    setModalOpen(false);
    setlocations(newlocations);
    handleChangedLocations && handleChangedLocations(product.id, newlocations);
  };

  const handleDeleteSelectedLocation = (id) => {
    const __locations = [..._locations];
    const locationIndex = __locations.findIndex(
      (loc) => loc.location_id === id
    );
    __locations[locationIndex].unitsSelected = 0;
    setlocations(__locations);
    handleChangedLocations && handleChangedLocations(product.id, __locations);
  };

  return (
    <div className="product-location-result-search-item-container">
      <div className="product-location-result-search-item-wrapper">
        <div
          onClick={handleClick}
          className="product-location-result-search-item"
        >
          <ItemLongSummaryItem item={product} />
        </div>
        <div className="product-location-result-search-item-right-col">
          <div
            className="product-location-add-wrapper"
            onClick={openLocationSelector}
          >
            <i className="icon-iconos_mas2" /> ubicación y cantidad
          </div>
          <div className="product-location-list">
            {_locations
              ?.filter((loc) => loc.unitsSelected > 0)
              .map((location) => {
                return (
                  <div
                    className="product-location-selected"
                    key={location.location_id}
                  >
                    <p>
                      {location.location_label}{" "}
                      <span>{location.unitsSelected} UNID.</span>
                      <i
                        className="icon-iconos_eliminar"
                        onClick={() =>
                          handleDeleteSelectedLocation(location.location_id)
                        }
                      />
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="product-location-result-search-item-divider"></div>
      {modalOpen && (
        <LocationSelectorModal
          item_id={product.id}
          locations={locations}
          handleClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default ProductLocationResult;
