import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";
import {isMobile} from "helpers/Mobile"

import { EventRepository } from "helpers/EventRepository";

import useRequest from "hooks/useRequest";
import { ApplicationService } from "services/ApplicationService";

import "./styles.scss";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import EditAppComponent from "../components/AppEdit";

const initialApp = {
  name: "",
  client_id: null,
  client_secret: null,
  connected: false,
  status: "NOT",
  scopes: [],
  scope: "",
  callback_url: "",
};

export const EditApplication = (props) => {
  const history = useHistory();

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();
  const [id, setId] = useState();
  const [app, setApp] = useState(initialApp);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const goBack = () => {
    history.push("/admin/applications");
  };

  useEffect(() => {
    if (!props.match.params.key) return;
    beforeSubmit();
    const id = props.match.params.key;
    setId(id);
    ApplicationService.application(id)
      .then((response) => {
        afterSubmit();
        response.scopes =
          response.scope === "" ? [] : response.scope.split(" ");
        setApp(response);
      })
      .catch((error) => {
        afterSubmit();
      });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const _app = { ...app };
    if (e.target.type !== "file") {
      _app[name] = e.target.value;
    } else {
      _app[name] = e.target.files[0];
    }

    setApp(_app);
    setButtonEnabled(true);
  };

  const handleChangeImage = (e) => {
    const _app = { ...app };
    _app.logo_file = e.target.files[0];

    setApp(_app);
    setButtonEnabled(true);
  };

  const handleDeleteImage = (e) => {
    const _app = { ...app };
    _app.logo_file = null;

    setApp(_app);
    setButtonEnabled(true);
  };

  const handleChangeEnabled = () => {
    const _app = { ...app };
    _app.enabled = !_app.enabled;

    setApp(_app);
    setButtonEnabled(true);
  };

  const handleSelect = (_scope) => {
    const _app = { ...app };
    if (_app.scopes.includes(_scope)) {
      _app.scopes = _app.scopes.filter((s) => s !== _scope);
    } else {
      _app.scopes.push(_scope);
    }
    setApp(_app);
    setButtonEnabled(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const _app = { ...app };
    _app.scope = _app.scopes.join(" ");

    let formData = new FormData();
    formData.append("name", _app.name);
    formData.append("callback_url", _app.callback_url);
    formData.append("scope", _app.scope);
    formData.append("enabled", _app.enabled);

    if (_app.logo_file !== undefined) {
      formData.append("logo", _app.logo_file ? _app.logo_file : "");
    }

    beforeSubmit();
    ApplicationService.updateApplication(id, formData)
      .then((application) => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("Tu aplicación se actualizó de manera exitosa."),
          type: "success",
        });
        history.push("/admin/applications");
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "Error creando la aplicacion");
        EventRepository.notificationSend({
          label: i18n.t("Hubo un error."),
          type: "error",
        });
      });
  };

  const customButtons = (
    <div className="submit-wrapper">
      {" "}
      <button
        onClick={handleSubmit}
        disabled={!buttonEnabled}
        className={`button-submit ${buttonEnabled && "enabled"}`}
      >
        GUARDAR
      </button>{" "}
    </div>
  );

  const content = (
    <div className="application-edit-wrapper">
      <EditAppComponent
        app={app}
        errors={errors}
        handleChange={handleChange}
        handleChangeImage={handleChangeImage}
        handleDeleteImage={handleDeleteImage}
        handleSelect={handleSelect}
        handleChangeEnabled={handleChangeEnabled}
      />
      {isMobile && customButtons}
    </div>
  );

  return (
    <div className="applications-edit-container">
      <AdminLayout
        headerTitle={i18n.t("Aplicaciones externas")}
        headerOptions={[]}
        content={app.id ? content : null}
        goBackFunc={goBack}
        navHidden={true}
        customButtons={!isMobile && customButtons}
      ></AdminLayout>

      {loading && <Loader />}
    </div>
  );
};

export default EditApplication;
