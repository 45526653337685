/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FileSaver from "file-saver";

import usePagination from "../../../../../hooks/usePagination";
import { isMobile } from "../../../../../helpers/Mobile";

import { AuthService } from "services/AuthService";
import { OrderService } from "../../../../../services/OrderService";
import { PublicService } from "../../../../../services/PublicService";
import { StockService } from "../../../../../services/StockService";
import { stockByItem } from "helpers/stock/StockHelper";

import { EventRepository } from 'helpers/EventRepository';

import "./styles.scss";
import AdminLayout from "../../../../../components/MainApp/layouts/DesktopLayout";
import StepOne from "../../../../../components/MainApp/organisms/Orders/RequestOrders/LoadRequestOrder/Steps/Step1";
import StepTwo from "../../../../../components/MainApp/organisms/Orders/RequestOrders/LoadRequestOrder/Steps/Step2";
import StepThree from "../../../../../components/MainApp/organisms/Orders/RequestOrders/LoadRequestOrder/Steps/Step3";
import LoadFinished from "../../../../../components/MainApp/organisms/Orders/RequestOrders/LoadOrderFinished";
import Wizard from "../../../../../components/MainApp/atoms/Wizard/StepProgress";
import RoundedButton from "../../../../../components/MainApp/atoms/RoundedButton";
import Loader from "../../../../../components/MainApp/atoms/Loader";

const initialOrder = {
  destination: "",
  type: "REQ",
};

const headerOptions = [];

const steps = [
  {
    id: "0",
    name: "Origen",
  },
  {
    id: "1",
    name: "Productos",
  },
  {
    id: "2",
    name: "Confirmación",
  },
];

export const LoadRequestOrderView = (props) => {
  const history = useHistory();
  const [activestep, setactivestep] = useState("0");
  const [order, setorder] = useState(initialOrder);
  const [selectedNode, setselectedNode] = useState(null);
  const [nodes, setnodes] = useState([]);
  const [submitted, setsubmitted] = useState(false);
  const [productResultList, setproductResultList] = useState([]);
  const [selectedToAddProductList, setselectedToAddProductList] = useState([]);
  const [addedList, setaddedList] = useState([]);
  const [outOfStockSelected, setOutOfStockSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const [
    list,
    currentPage,
    pageSize,
    pageList,
    pageQuantity,
    changePageSize,
    changePage,
    changeList,
  ] = usePagination([], 0, 3);

  useEffect(() => {
    PublicService.nodes().then((rta) => {
      let newNodes = rta.map((n) => {
        n.label = n.name;
        return n;
      });
      setnodes(
        newNodes.filter((n) => n.code !== AuthService.getCurrentNodeCode())
      );
    });
  }, []);

  useEffect(() => {
    if (!props.match.params.key) return;
    const id = props.match.params.key;
    OrderService.requestOrder(id).then((response) => {
      let destination = response.destination;
      destination.label = destination.name;
      setorder(response);
      setselectedNode(destination);
    });
  }, []);

  useEffect(() => {
    if (!props.match.params.key) return;
    const id = props.match.params.key;
    OrderService.getItems(id).then((response) => {
      let addedListNew = response.map((item) => {
        let newItem;
        newItem = item.item;
        newItem.units = item.quantity;
        newItem.code = item.item.sku;

        newItem.stock = 1;
        return item.item;
      });
      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
    });
  }, []);

useEffect(() => {
  if (selectedNode != null) {
    setLoading(true);
    StockService.stock({
      no_page: 1,
      status: "LOC",
      location__node: selectedNode.id,
    })
      .then((res) => {
        const _groupedStock = stockByItem(res);
        const _productResultList = _groupedStock.map((item) => {
          return {
            id: item.id,
            sku: item.sku,
            name: item.title,
            stock: item.tags?.length ?? 0,
            units: 1,
          };
        });
        setproductResultList(_productResultList);
        setLoading(false);
      })
      .catch((_) => {
        setLoading(false);
      });
  }
}, [selectedNode]);

  const restart = () => {
    setactivestep("0");
    setorder(initialOrder);
    setselectedNode(null);
  };

  const handleSelectDest = (dest) => {
    setselectedNode(dest);
    let newOrder = { ...order };
    newOrder.destination = dest.code;
    setorder(newOrder);
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let newOrder = { ...order };
    newOrder[name] = e.target.value;
    setorder(newOrder);
  };

  const stepChangeHandler = (step) => {
    setactivestep(step);
  };

  const step0IsOk = () => {
    return (
      selectedNode != "" && selectedNode !== null && selectedNode !== undefined
    );
  };

  const step1IsOk = () => {
    return addedList?.length > 0;
  };

  const nextStep = (e) => {
    e.preventDefault();
    const func = {
      "0": (e) => stepOneSend("1"),
      "1": (e) => stepTwoSend(e),
      "2": (e) => handleSubmit(e),
    };
    func[activestep](e);
  };

  const stepOneSend = (e) => {
    if (order.id) {
      OrderService.updateOrder(order.id, { destination: selectedNode.code })
        .then((response) => {
          setorder(response);
          stepChangeHandler("1");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      //CREATE ORDER
      let newOrder = { ...initialOrder };
      newOrder.destination = selectedNode.code;
      OrderService.createOrder(order)
        .then((response) => {
          setorder(response);
          stepChangeHandler("1");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const stepTwoSend = (e) => {
    e.preventDefault();
    const items = [...addedList].map((item) => {
      return {
        item_id: item.id,
        quantity: item.units,
      };
    });
    OrderService.addItems(order.id, items).then((response) => {
      stepChangeHandler("2");
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setsubmitted(true);
    OrderService.changeOrderStatus(order.id, "PEN", "Creation finished")
    .then((response) => {
        // setorder(response)
        setactivestep("finish");
        setsubmitted(false);
      })
      .catch((error) => {
        setsubmitted(false);
        console.log(error);
      });
  };

  const goBackFunc = () => {
    history.push("/admin/orders/request");
  };

  const handleSelectToAddProduct = (product) => {
    let selectedToAddProductListNew = [...selectedToAddProductList];
    let ind = selectedToAddProductListNew.findIndex((p) => {
      return p.id === product;
    });
    if (ind !== -1) {
      selectedToAddProductListNew = selectedToAddProductListNew.filter((p) => {
        return p.id !== product;
      });
    } else {
      let newProduct = productResultList.find((p) => {
        return p.id === product;
      });
      newProduct.selected = true;
      selectedToAddProductListNew.push(newProduct);
    }

    setselectedToAddProductList(selectedToAddProductListNew);
  };

  const handleSelectedProductQuantityChange = (productId, number) => {
    let productResultListNew = [...productResultList];
    let productNew = productResultListNew.find((p) => p.id === productId);

    if (number <= 0) {
      EventRepository.notificationSend({
        label: 'El valor no puede ser negativo o cero.',
        type: 'error'
      });
    } else {
      if (number > productNew.stock) {
        EventRepository.notificationSend({
          label: `El producto: ${productNew.name}, supera el stock disponible: ${productNew.stock}`,
          type: 'error'
        });
      } else {
        productNew.units = number;
        setproductResultList(productResultListNew);
      }
    }
  };

  const handleAdd = () => {
    let addedListNew = [...addedList];
    selectedToAddProductList.forEach((product) => {
      let addedInd = addedList.findIndex((p) => {
        return p.id === product.id;
      });
      if (addedInd === -1) {
        addedListNew.push({ ...product });
      } else {
        let productSelected = {
          ...productResultList.find((p) => p.id === product.id),
        };
        let productAddedIndex = addedListNew.findIndex(
          (p) => p.id === product.id
        );

        let cant = Number(addedListNew[productAddedIndex].units) + Number(productSelected.units) > Number(addedListNew[productAddedIndex].stock) ?
        Number(addedListNew[productAddedIndex].stock) :
        Number(addedListNew[productAddedIndex].units) + Number(productSelected.units);

        addedListNew[productAddedIndex].units = cant;
      }

      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
    });

    let productResultListNew = productResultList.map((p) => {
      p.units = 1;
      return p;
    });

    setproductResultList(productResultListNew);
    setselectedToAddProductList([]);
  };

  const handleAddProduct = (productId, number) => {
    //number = number === 0 ? 1 : number;
    let addedListNew = [...addedList];
    let productAddedIndex = addedListNew.findIndex((p) => p.id === productId);

    if (number <= 0) {
      EventRepository.notificationSend({
        label: 'El valor no puede ser negativo o cero.',
        type: 'error'
      });
    } else {
      if (number > addedListNew[productAddedIndex].stock) {
        EventRepository.notificationSend({
          label: `El producto: ${addedListNew[productAddedIndex].name}, supera el stock disponible: ${addedListNew[productAddedIndex].stock}`,
          type: 'error'
        });
      } else {
        addedListNew[productAddedIndex].units = Number(number);
        setaddedList(addedListNew);
        changeList(addedListNew, currentPage, pageSize);
      }
    }
  };

  const handleDeleteAddedProduct = (productId) => {
    let addedListNew = [...addedList];

    addedListNew = addedListNew.filter((p) => p.id !== productId);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  const handleSelectOutOfStock = () => {
    const value = !outOfStockSelected;
    setOutOfStockSelected(value);
  };

  const downloadVoucher = () => {
    setsubmitted(true);
    OrderService.requestDownloadVoucher(order.id)
      .then((blob) => {
        setsubmitted(false);
        FileSaver.saveAs(blob, "comprobante.pdf");
      })
      .catch((error) => {
        setsubmitted(false);
      });
  };

  const content = (
    <div className="load-request-order-content">
      {activestep !== "finish" ? (
        <div className="load-request-order-wrapper">
          <div className="load-request-order-wizard">
            <Wizard
              steps={steps}
              activeStep={activestep}
              clickEnabled={false}
            ></Wizard>
          </div>

          <div className="load-request-order-step">
            {activestep === "0" && (
              <form onSubmit={nextStep} className="content">
                <StepOne
                  handleChange={handleSelectDest}
                  options={nodes}
                  option={selectedNode}
                />
                <div className="load-request-order-button">
                  <div>
                    <RoundedButton
                      legend="CONTINUAR"
                      type="submit"
                      state={step0IsOk() ? "enabled" : "disabled"}
                      handleClick={nextStep}
                    ></RoundedButton>
                  </div>
                </div>
              </form>
            )}

            {activestep === "1" && (
              <div className="content">
                <StepTwo
                  handleChange={handleChange}
                  resultList={productResultList}
                  searchValue={""}
                  products={isMobile ? addedList : pageList}
                  totalProducts={addedList.length}
                  handleSelectToAddProduct={handleSelectToAddProduct}
                  handleSelectedProductQuantityChange={
                    handleSelectedProductQuantityChange
                  }
                  handleAdd={handleAdd}
                  handleAddProduct={handleAddProduct}
                  selectedProducts={selectedToAddProductList.map((p) => p.id)}
                  handleDeleteAddedProduct={handleDeleteAddedProduct}
                  changePage={changePage}
                  pagination={{
                    pageQuantity: pageQuantity,
                    currentPage: currentPage,
                  }}
                  handleSelectOutOfStock={handleSelectOutOfStock}
                  outOfStockSelected={outOfStockSelected}
                />
                <div className="load-request-order-button">
                  <div>
                    <RoundedButton
                      legend="CONTINUAR"
                      type="submit"
                      state={step1IsOk() ? "enabled" : "disabled"}
                      handleClick={(e) => nextStep(e)}
                    ></RoundedButton>
                  </div>
                </div>
              </div>
            )}
            {loading && <Loader />}

            {activestep === "2" && (
              <div className="content">
                <StepThree
                  dest={selectedNode.name}
                  products={addedList}
                  handleEditDest={() => stepChangeHandler("0")}
                  handleEditProducts={() => stepChangeHandler("1")}
                />
                <div className="load-request-order-button last">
                  <div>
                    <RoundedButton
                      legend="CONFIRMAR"
                      state="enabled"
                      handleClick={handleSubmit}
                    ></RoundedButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="load-request-order-wrapper-finish">
          <div className="load-request-order-wrapper-finish-container">
            <LoadFinished
              orderNumber={order.code}
              boldButtonFunc={restart}
              downloadVoucher={downloadVoucher}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="load-request-order-container">
      <AdminLayout
        headerTitle={"Nueva orden de reposición"}
        headerOptions={headerOptions}
        content={content}
        goBackFunc={activestep !== "finish" ? goBackFunc : null}
        navHidden={true}
      ></AdminLayout>

      {submitted && <Loader />}

      {/* <SingleLoadExitModal
          open={this.state.goBackConfirm}
          confirm={() => this.props.history.push("/admin/products/load")}
          cancel={() => this.setState({ goBackConfirm: false })}
          handleClose={() => this.setState({ goBackConfirm: false })}
        /> */}
    </div>
  );
};

export default LoadRequestOrderView;
