import Environment from "../environment";
import Api from "./ApiService";

import { Provider } from "../models/Persons";

export const ProviderService = {
  providers: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/persons/?type=PROVIDER`,
        "GET",
        params
      )
        .then((rta) => {
          rta = rta.map((item) => new Provider(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  providersPaginated: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/persons/?type=PROVIDER`,
        "GET",
        params
      )
        .then((rta) => {
          rta.results = rta.results.map((item) => new Provider(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  provider: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/persons/${id}/`, "GET")
        .then((rta) => {
          rta = new Provider(rta);
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createProvider: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/persons/`, "POST", data)
        .then((data) => {
          resolve(new Provider(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateProvider: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/persons/${id}/`, "PATCH", data)
        .then((data) => {
          resolve(new Provider(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteProvider: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/persons/${id}/`, "DELETE")
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
