export const steps = [
  {
    id: "0",
    name: "Variables",
  },
  {
    id: "1",
    name: "Tags",
  },
  {
    id: "2",
    name: "Impresión",
  },
];
