import React from 'react';

import ProductSummaryItem from '../ProductSummaryItem';
import NumberInput from '../../../../atoms/Forms/NumberInput';
import Icon from '../../../../atoms/Icon/icon';
import {isMobile} from  '../../../../../../helpers/Mobile';

import './styles.scss';

const ProductAddedItem = ({product, stock, units, handleEdit, handleDelete, subtitle}) => {

    return(
        <div className="product-added-item-container" key={product.id}>
            <div className="product-added-item-wrapper">

            <ProductSummaryItem product={product} units={units} add={true} stock={stock} subtitle={subtitle}/>
            <div className="product-added-item-right-col">
                <div className={'product-added-item-number-input'}>
                    <NumberInput value={units} name="quantity" handleChange={handleEdit} id={product.id} disabled={!stock || stock == 0}></NumberInput>
                </div>
                <div className="product-added-item-delete" onClick={() => handleDelete(product.id)}>
                    <Icon name="trash_can" fill={'#3C7AF5'} width={isMobile ? 12 : 14} height={isMobile ? 14 : 16} />
                </div>
            </div>
            </div>
            
            <div className="product-added-item-divider"></div>
        </div>
    )
}

export default ProductAddedItem;