import React, {useState, useEffect} from "react";
import {useHistory} from 'react-router-dom'

import useRequest from 'hooks/useRequest';

import i18n from "i18n";
import { EventRepository } from 'helpers/EventRepository';

import { steps } from './data/steps';
import Loader from "components/MainApp/atoms/Loader";
import AdminLayout from 'components/MainApp/layouts/DesktopLayout';
import StepProgress from 'components/MainApp/atoms/Wizard/StepProgress';
import StepOne from './Wizard/Step1';
import StepTwo from './Wizard/Step2';
import StepThree from  './Wizard/Step3';
import StepFour from  './Wizard/Step4';

import AddConfigTagSuccess from '../AddConfigTagSuccess';

import './styles.scss';
import { TagService } from "../../../../services/TagService";

const initialConfigTag = {
    id: '',
    name: '',
    description: '',
    height: '', 
    width: '',
    mem_epc: '',
    mem_user: '',
    variables: '',
}

const types = [
    {
        id: 'PRODUCT',
        label: 'Producto',
        value: 'PRODUCT'
    },
    {
      id: 'BOX',
      label: 'Caja',
      value: 'BOX'
    },
    {
      id: 'PALLET',
      label: 'Palet',
      value: 'PALLET'
    }
  ]

const options = {
  mem_epc: [
    {
      label: "96",
      value: "96",
    },
    {
      label: "128",
      value: "128",
    },
    {
      label: "240",
      value: "240",
    },
  ],
  mem_user: [
    {
        label: '32',
        value: '32'
    },
    {
        label: '64',
        value: '64'
    },
  ]
};

const AddConfigTagView = (props) => {
    const history = useHistory();
    const {
        loading, beforeSubmit, afterSubmit,
        errors, dealWithError
    } = useRequest();
    const [activeStep, setActiveStep] = useState('0');
    const [sentSuccess, setSentSuccess] = useState(false);
    const [configTag, setconfigTag] = useState(initialConfigTag);
    
    const headerOptions = [];
    
    const analizeErrors = (message, errors = {}) => {
        if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: 'error'
            });
        }

        if(['mem_epc', 'mem_user'].includes(Object.keys(errors)[0])){
            setActiveStep('2')
        }
        if(['width', 'height'].includes(Object.keys(errors)[0])){
            setActiveStep('1')
        }
        if(['name', 'description', 'variables'].includes(Object.keys(errors)[0])){
            setActiveStep('0')
        }
    }

    const handleFinish = (e) => {
        e.preventDefault();
        beforeSubmit();

        configTag.type = configTag.type.id;
        
        if(configTag.id){
            TagService.updateConfigTag(configTag.id, configTag).then(ct => {
                afterSubmit();
                history.push(`/admin/config_tags`)
            }).catch(error => {
                afterSubmit();
                dealWithError(error, 'generic.error', (message, errors) => {
                    analizeErrors(message, errors);
                });
            })
        }else{
            let configTagAux = {...configTag}
            configTagAux.variables = {}  // Auto crete in back
            configTagAux.mem_epc = configTagAux.mem_epc.value
            configTagAux.mem_user = configTagAux.mem_user.value
            TagService.createConfigTag(configTagAux).then( ct =>{
                setSentSuccess(true)
                setconfigTag(ct)
                afterSubmit();
            }).catch(error => {
                afterSubmit();
                dealWithError(error, 'generic.error', (message, errors) => {
                    analizeErrors(message, errors);
                });
            })
        }
    }

    const goToList = () => {
        history.push(`/admin/config_tags`)
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;   
        let configTagAux = {...configTag}
        configTagAux[name] = value;

        setconfigTag(configTagAux)
    }

    const handleSelect = (selectedOption, type) => {
        let configTagAux = {...configTag}
        configTagAux[type] = selectedOption
        setconfigTag(configTagAux)
    }

    const validateStep0 = () => {
        if(configTag){
            return configTag.name !== '' && configTag.type;
        }
         else{
             return false
         }
    }

    const validateStep1 = () => {
        if(configTag){
            return configTag.width !== '' &&
            configTag.height !== '' &&
            configTag.mem_epc !== '' &&
            configTag.mem_user !== ''
        }
         else{
             return false
         }
    }

    const goToStep = (step) => {
        setActiveStep(step)
    }

    const getConfirmationView = () => {
        return [
            {
                step: i18n.t('Descripcion'),
                data: [
                    {
                        title: 'Nombre',
                        value: configTag.name,
                    },
                    {
                        title: 'Descripcion',
                        value: configTag.description
                    },
                    {
                        title: 'Tipo de Embalaje',
                        value: configTag.type?.label
                    }
                ],
                handleClick: () => setActiveStep('0')
            },
            {
                step: i18n.t('Especificaciones'),
                data: [
                    
                    {
                        title: 'Medidas',
                        value:  "Alto " + configTag.height + " mm",
                        
                    },
                    
                    {
                        value: "Ancho " + configTag.width + " mm",
                    },
                    
                    {
                        title: 'Memoria',
                        value: configTag.mem_epc.value,
                    },
                    
                    {
                        value: configTag.mem_user.value,
                    },

                ],
                handleClick: () => setActiveStep('1')
            },
        ]
    }

    const headerTitle = sentSuccess ? 'Plantilla creada' : 'Nueva plantilla';

    const step0 = <StepOne handleContinue={() => setActiveStep('1')} 
        configTag={configTag} handleSelect={handleSelect} 
        types={types}
        handleChange={handleChange}
        enableContinue={validateStep0()} error={errors}></StepOne>

    const step1 = (
      <StepTwo
        handleContinue={() => setActiveStep("2")}
        goBack={() => goToStep("0")}
        handleChange={handleChange}
        enableContinue={validateStep1()}
        configTag={configTag}
        error={errors}
        options={options}
        handleSelect={handleSelect}
      ></StepTwo>
    );


    const step3 = (
      <StepFour
        handleFinish={handleFinish}
        goBack={() => goToStep("1")}
        configTagInfo={getConfirmationView()}
        handleChange={handleChange}
        error={errors}
      ></StepFour>
    );

    // const stepList = [step0, step1, step2];
    const stepList = {
        '0': step0,
        '1': step1,
        '2': step3,
    }

    const addSuccess = (
      <div className={`add-config-tag-success`}>
        <AddConfigTagSuccess
          id={configTag && configTag.id}
          name={configTag && configTag.name}
          boldButtonFunc={goToList}
        />
      </div>
    );

    const content = <div className={`add-config-tag-content`}>
        {!sentSuccess && <div className={`add-config-tag-wrapper`}>
            <StepProgress steps={steps} activeStep={activeStep}/>
            <div className={`add-config-tag-step-wrapper`}>
                {stepList[activeStep]}
            </div>
        </div>}
    </div>

    return(
        <div className="add-config-tag-container">
        <AdminLayout
          headerTitle={i18n.t(headerTitle)}
          headerOptions={headerOptions}
          content={sentSuccess ? addSuccess : content}
          navHidden={true}
          goBackFunc={goToList}
        ></AdminLayout>
        {loading && <Loader />}
      </div>
    )
}

export default AddConfigTagView;