import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
export class Wizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: this.props.activeStep,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeStep !== prevProps.activeStep) {
        this.setState({
          activeStep: this.props.activeStep,
        })
    }
  }

  onClickHandler = (e) => {
    if (! this.props.clickEnabled) {
      return;
    }

    e.preventDefault();
    this.setState({
      activeStep: e.target.id,
    });
    if (this.props.handler) this.props.handler(e.target.id);
  };

  render() {
    const { steps } = this.props;

    return (
      <div className="wizard-container">
        {/* <div className="step-line-general"></div> */}
        <div className="wizard-step-wrapper">
          {steps.map((step) => {
            return (
              <div className="step-wrapper" key={step.id}>
                  <div className={this.state.activeStep === step.id ? 'name active' : 'name'}>{step.name}</div>
                <div
                  className="step-point"
                  onClick={this.onClickHandler}
                  id={step.id}
                  data-automation-id="step"
                >
                    <div className="step-line step-line-left"></div>

                  <div
                    className={
                      this.state.activeStep === step.id
                        ? "outer-point-active"
                        : "outer-point"
                    }
                    onClick={this.onClickHandler}
                  data-automation-id="step"

                    id={step.id}
                  >
                    <div
                      className={
                        this.state.activeStep === step.id
                          ? "inner-point-active"
                          : "inner-point"
                      }
                      id={step.id}
                      onClick={this.onClickHandler}
                      data-automation-id="step"

                    ></div>
                  </div>

                  <div className="step-line step-line-right"></div>

                </div>
              </div>
            );
          })}
        </div>

      </div>
    );
  }
}

Wizard.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.string,
  handler: PropTypes.func,
};

export default Wizard;
