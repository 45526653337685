const data = {
  initialOrder: {
    destination: "",
    type: "PRE",
  },
  headerOptions: [],
  steps: [
    {
      id: "0",
      name: "Descripción",
    },
    {
      id: "1",
      name: "Productos",
    },
    {
      id: "2",
      name: "Confirmación",
    },
  ],
  reasons: [
    {
      label: "Venta",
      id: "venta",
    },
    {
      label: "Rotura",
      id: "rotura",
    },
    {
      label: "Sobrante de mercadería",
      id: "sobrante",
    },
    {
      label: "Ajuste de stock",
      id: "ajuste",
    },
  ],
 
};

export default data;