import React from "react";
import i18n from "i18n";
import "./styles.scss";
import Item from "../Item";
import Tag from "../Tag";

const ItemTags = ({
  item,
  type,
  quantity,
  expected,
  allSelecteds,
  handleDelete,
  handleSelect,
  reprint = false,
}) => {
  return (
    <div className="item-tag-container">
      <div className="item-tags-item">
        <Item
          title={item.title}
          sku={item.sku}
          tagsQuantity={quantity}
          expectedUnits={expected}
        />
      </div>
      <div className="item-tags-tags">
        {item.tags.length > 0 &&
          item.tags.map((tag) => {
            return (
              <Tag
                tag={tag}
                epc={tag.epc}
                key={tag.tag}
                selected={tag.selected || allSelecteds}
                handleDelete={handleDelete}
                handleSelect={handleSelect}
                type={type}
                reprint={reprint}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ItemTags;
