import React from "react";
import MainInput from "../../../../atoms/Forms/MainInput";
import MainTextArea from "../../../../atoms/Forms/MainTextArea";
import MainButtonInput from "../../../../atoms/Forms/MainButtonInput";
import MainMoneyInput from "../../../../atoms/Forms/MainMoneyInput";

import "./styles.scss";

export class FormEditProduct extends React.Component {
  render() {
    const { product, handleChange, handleBlur, selectCategory, stock, errors } = this.props;
    return (
      <div
        className={`form-edit-product-container ${!product.enabled ? 'disabled' : ''}`}
        data-automation-id="form-edit-product-container"
      >
        <div className="form-edit-product-header">
          <div className={`form-edit-product-stock-label ${stock > 0 ? 'with-stock' : 'without-stock'}`}>
              {stock > 0 ? 'Stock' : 'Sin Stock'}
            </div>
          {stock > 0 && <div className="form-edit-product-stock-quantity" data-automation-id="form-edit-product-stock-quantity">{stock} disponibles <span>
            {/* <i className="form-edit-product-icon icon-chevron-derecha"></i> */}
          </span></div>}
        </div>
        <div className="form-edit-product-separator"></div>
        <div className="form-edit-product-title">Descripción</div>
        <div className="form-edit-input-field">
          <MainInput
            label={"Código"}
            required={true}
            id={"product-codigo"}
            name="sku"
            value={product.sku}
            handleChange={handleChange}
            error={errors['sku']}
            disabled
          />
        </div>
        <div className="form-edit-input-field" data-automation-id="input-name">
          <MainInput
            label={"Nombre del producto"}
            required={true}
            id={"product-name"}
            name="name"
            value={product.name}
            handleChange={handleChange}
            error={errors['name']}
            maxLength={100}
          />
        </div>
        <div className="form-edit-input-field" data-automation-id="input-brand">
          <MainInput
            label={"Marca"}
            required={true}
            id={"product-brand"}
            name="brand"
            value={product.brand}
            handleChange={handleChange}
            error={errors['brand_name']}
            maxLength={50}
          />
        </div>
        <div className="form-edit-input-field" data-automation-id="input-description">
          <MainTextArea
            label={"Descripción"}
            id={"product-description"}
            name="description"
            value={product.description}
            handleChange={handleChange}
            error={errors['description']}
            maxLength={255}
          />
        </div>
        <div className="form-edit-input-field" data-automation-id="input-category">
          <MainButtonInput
            label={"Categoría"}
            id={"product-category"}
            value={product.category ? product.category.name : null}
            handleClick={selectCategory}
            error={errors['categories']}
            maxLength={50}
          />
        </div>
        <div className="form-edit-input-field" data-automation-id="input-purchase-price">
          <MainMoneyInput
            label={"Precio de costo"}
            id={"product-buyng-price"}
            name="purchase_price"
            value={product.purchase_price}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors['purchase_price']}
            maxLength={15}
            />
        </div>
        <div className="form-edit-input-field" data-automation-id="input-selling-price">
          <MainMoneyInput
            label={"Precio de venta"}
            id={"product-selling-price"}
            name="sell_price"
            value={product.sell_price}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors['sell_price']}
            maxLength={15}
            />
        </div>
        <div className="form-edit-input-field" data-automation-id="input-weight">
          <MainInput
            label={"Peso (kg)"}
            id={"product-weight"}
            name="weight"
            value={product.weight}
            handleChange={handleChange}
            handleBlur={handleBlur}
            maxLength={100}
            error={errors.weight}
            type="number"

          />
        </div>
        <div className="form-edit-input-field" data-automation-id="input-measure-depth">
          <MainInput
            label={"Profundidad (cm)"}
            id={"product-measure-depth"}
            name="measure_depth"
            value={product.measure_depth}
            handleChange={handleChange}
            handleBlur={handleBlur}
            maxLength={100}
            error={errors.measure_depth}
            type="number"

          />
        </div>
        <div className="form-edit-input-field" data-automation-id="input-measure-height">
          <MainInput
            label={"Alto (cm)"}
            id={"product-measure-height"}
            name="measure_height"
            value={product.measure_height}
            handleChange={handleChange}
            handleBlur={handleBlur}
            maxLength={100}
            error={errors.measure_height}
            type="number"

          />
        </div>
        <div className="form-edit-input-field" data-automation-id="input-measure-width">
          <MainInput
            label={"Ancho (cm)"}
            id={"product-measure-width"}
            name="measure_width"
            value={product.measure_width}
            handleChange={handleChange}
            handleBlur={handleBlur}
            maxLength={100}
            error={errors.measure_width}
            type="number"

          />
        </div>
        {!product.enabled && <div className="form-edit-overlay"></div>}
      </div>
    );
  }
}

export default FormEditProduct;
