import React from 'react';

import './styles.scss';
//state: enabled, disabled, waiting, temporary
export class RoundedButton extends React.Component {
  handleClick = (e) => {
    if(this.props.state==='enabled'){
      if (this.props.handleClick) this.props.handleClick(e);
    }
  }

  render() {
    const {
      id,
      state,
      legend
    } = this.props;

    return (
      <button id={id} className={'rounded-button ' + state} data-automation-id="button" onClick={this.handleClick} disabled={state === 'disabled'}>
        {state === 'temporary' &&
          <i className="icon-check icono"></i>
        }
        {state !== 'waiting' && legend}
        {state === 'waiting' &&
          <div className="loadingio-spinner-rolling-oq9o3ny4ta"><div className="ldio-zwyik6gooc">
            <div></div>
          </div></div>
        }
      </button>
    )
  }

}

RoundedButton.propTypes = {};

export default RoundedButton;