import { FirebaseYaxDevicesService } from "services/FirebaseYaxDevicesService";
import * as React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow } from '@mui/material';
import { useState } from "react";
import { useEffect } from "react";
import "./TabVersions.scss";
import VersionModalDelete from "../VersionModalDelete/VersionModalDelete";
import { Snackbar } from '@mui/material';
import TabModalFileUpload from "../TabModalFileUpload/TabModalFileUpload";
import Loader from "components/MainApp/atoms/Loader";


const TabVersions = (props) => {


    const tooltipStyle = {
        background: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029",
        height: "45px",
        padding: "0 15px",
        color: "#494F66",
        fontFamily: "Roboto-Light",
        fontSize: "18px",
        borderRadius: "15px",
        display: "flex",
        alignItems: "center",
    };

    const columns = [
        { id: 'name', label: 'Name' },
        { id: 'url', label: 'Url' },
    ];

    const [versions, setVersions] = useState([]);
    const [version, setVersion] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      fetchVersions();
    }, [])

    const fetchVersions = () => {
      const fyds = FirebaseYaxDevicesService.getInstance();
      fyds.getVersions()
          .then(versions => {
            setVersions(versions);
          })
          .catch(error => console.error("Error al recuperar versiones", error));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleOpenDeleteModalVersion = (version) => {
      setVersion(version);
      setIsModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const handleDeleteVersion = (deleteVersion) => {
      setLoading(true);
      const fyds = FirebaseYaxDevicesService.getInstance();
      fyds.deleteVersionFile(deleteVersion)
          .then(() => {
            const newVersions = versions.filter(v => v.name !== deleteVersion);
            setVersions(newVersions);
            setSuccessMessage(`Versión ${deleteVersion} borrada con éxito.`);
            setIsSuccessMessageOpen(true);
          })
          .catch(error => console.error("Error al borrar versión", error))
          .finally(() => setLoading(false));

      setIsModalOpen(false);
    };

    const handleCloseSuccessMessage = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setIsSuccessMessageOpen(false);
    };

    const handleUploadFile = (file) => {
      setLoading(true);
      const fyds = FirebaseYaxDevicesService.getInstance();
      fyds.updateFirebaseFile('Versions', file)
          .then(() => {
            setSuccessMessage(`Fichero ${file.name} subido con éxito.`);
            setIsSuccessMessageOpen(true);
            fetchVersions();
          })
          .catch(error => console.error("Error al subir archivo", error))
          .finally(() => setLoading(false));

      setIsUploadModalOpen(false);
    };

    const handleOpenUploadModal = () => {
      setIsUploadModalOpen(true);
    };

    const handleCloseUploadModal = () => {
      setIsUploadModalOpen(false);
    };

    return (
      <>
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <Button
              variant="contained"
              onClick={handleOpenUploadModal}
              sx={{ mb: 2, ml: 2 }}>
              Subir Archivo
            </Button>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {versions.length > 0 && versions
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow  hover role="checkbox" tabIndex={-1} key={row.name || new Date().getTime()}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    className="celltable-versions"
                                                    sx={{
                                                        maxWidth: 100,
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis'
                                                    }} key={column.id} align={column.align}>

                                                   <div  className="celltable-div" onClick={() => { handleOpenDeleteModalVersion(value) }}>
                                                      {value}
                                                   </div>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={versions?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
        <TabModalFileUpload
          isOpen={isUploadModalOpen}
          handleClose={handleCloseUploadModal}
          handleUploadFile={handleUploadFile}
        />
        <VersionModalDelete
          version={version}
          open={isModalOpen}
          onClose={handleCloseModal}
          onDelete={handleDeleteVersion}
        />
        <Snackbar
          open={isSuccessMessageOpen}
          autoHideDuration={6000}
          onClose={handleCloseSuccessMessage}
          message={successMessage}
        />
        {loading && <Loader />}
      </>
    )
}

export default TabVersions;