import React from "react";
import PropTypes from "prop-types";
import LoadFinishedGeneral from "../../../LoadFinishedGeneral";

import "./styles.scss";

export const LoadOrderFinished = ({
  orderNumber,
  boldButtonFunc,
  downloadVoucher,
  buttonLink = "/admin/orders/request",
}) => {
  return (
    <LoadFinishedGeneral
      title={"Tu orden se creó de manera exitosa."}
      boldButtonLabel={"CREAR OTRA ORDEN"}
      boldButtonFunc={boldButtonFunc}
      buttonLabel={"VER LISTADO DE ÓRDENES"}
      // boldButtonLink={"/admin/orders/request/load"}
      buttonLink={buttonLink}
    >
      <div className="load-order-finished-container">
        <div className="load-order-finished-number-label">Nro. de Orden</div>
        <div className="load-order-finished-number">{orderNumber}</div>
        <div
          className="load-order-finished-download-wrapper"
          onClick={downloadVoucher}
        >
          <div className="laod-order-finished-icon">
            <i className="icon-iconos_descargar" />
          </div>
          <div className="load-order-finished-download-label">
            Descargar comprobante
          </div>
        </div>
      </div>
    </LoadFinishedGeneral>
  );
};

export default LoadOrderFinished;
