import React from "react";
import "./styles.scss";
import ItemLocalizationSummary from "../ItemLocalizationSummary";
import Tag from "components/MainApp/organisms/OrdersV3/Tag";

const ItemLocalization = ({ item, type, quantity, handleDelete }) => {
  return (
    <div className="item-tag-container">
      <div className="item-tags-item">
        <ItemLocalizationSummary
          title={item.title}
          sku={item.sku}
          tagsQuantity={quantity}
          node={item.location ? item.location.node.name : "Sin Sucursal"}
          location={item.location ? item.location.path_name : "Sin Ubicacion"}
        />
      </div>
      <div className="item-tags-tags">
        {item.tags.length > 0 &&
          item.tags.map((tag) => {
            return (
              <Tag
                key={tag.tag}
                tag={tag}
                epc={tag.epc}
                handleDelete={handleDelete}
                type={type}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ItemLocalization;
