import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";


import "./styles.scss";
export class AddEmbalajeStepTwo extends React.Component {
  render() {
    const { embalaje, handleChange, goBack, handleContinue, enableContinue, error } = this.props;
    const stepContent = (
      <form onSubmit={handleContinue}>
      <div className="add-embalaje-wizard-step-two-content">
        <div className="add-embalaje-wizard-step-two-field" data-automation-id="input-measure_height">
          <MainInput
            label={"Alto (cm)"}
            required={true}
            id={"embalaje-measure_height"}
            name="measure_height"
            value={embalaje ? embalaje.measure_height : ''}
            type="number"
            handleChange={handleChange}
            onlyNumbers={"onlyNumbers"}
            maxLength={100}
            error={error.measure_height && error.measure_height}
            />
        </div>
        <div className="add-embalaje-wizard-step-two-field" data-automation-id="input-measure_width">
          <MainInput
              label={"Ancho (cm)"}
              required={true}
              id={"embalaje-measure_width"}
              name="measure_width"
              value={embalaje ? embalaje.measure_width : ''}
              type="number"
              handleChange={handleChange}
              onlyNumbers={"onlyNumbers"}
              maxLength={100}
              error={error.measure_width && error.measure_width}

            />
        </div>
        <div className="add-embalaje-wizard-step-two-field" data-automation-id="input-measure_depth">
          <MainInput
              label={"Profundidad (cm)"}
              required={true}
              id={"embalaje-measure_depth"}
              name="measure_depth"
              value={embalaje ? embalaje.measure_depth : ''}
              type="number"
              handleChange={handleChange}
              onlyNumbers={"onlyNumbers"}
              maxLength={100}
              error={error.measure_depth && error.measure_depth}

            />
        </div>
        <div className="add-embalaje-wizard-step-two-field" data-automation-id="input-volume">
          <MainInput
            label={"Volumen (cm3)"}
            required={false}
            id={"embalaje-volume"}
            name="volume"
            value={embalaje ? embalaje.volume : ''}
            type="number"
            handleChange={handleChange}
            onlyNumbers={"onlyNumbers"}
            maxLength={100}
            error={error.volume && error.volume}
            />
        </div>
        <div className="add-embalaje-wizard-step-two-field" data-automation-id="input-max_weight">
          <MainInput
              label={"Peso maximo (kg)"}
              required={true}
              id={"embalaje-max_weight"}
              name="max_weight"
              value={embalaje ? embalaje.max_weight : ''}
              type="number"
              handleChange={handleChange}
              maxLength={100}
              error={error.max_weight && error.max_weight}

            />
        </div>
        <div className="add-embalaje-wizard-step-two-field" data-automation-id="input-material">
          <MainInput
              label={"Material"}
              required={false}
              id={"embalaje-material"}
              name="material"
              value={embalaje ? embalaje.material : ''}
              handleChange={handleChange}
              maxLength={100}
              error={error.material && error.material}

            />
        </div>

        <div className={"add-embalaje-wizard-step-two-action-wrapper"}>
          <button type="button" className={`add-embalaje-wizard-step-two-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-embalaje-wizard-step-two-container">
        <Step title="Ingresa dimensiones del embalaje" goBackFunc={goBack} content={stepContent}></Step>
      </div>
    );
  }
}

export default AddEmbalajeStepTwo;
