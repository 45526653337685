import React from "react";
import MainInput from "components/MainApp/atoms/Forms/MainInput";

import "./styles.scss";

export class ContactForm extends React.Component {
  render() {
    const { provider, handleChange, error } = this.props;

    return (
      <>
        <div
          className="provider-contact-field"
          data-automation-id="input-address"
        >
          <MainInput
            label={"Direccion"}
            required={false}
            id={"provider-address"}
            name="address"
            value={provider ? provider.address : ""}
            handleChange={handleChange}
            maxLength={30}
            error={error.address && error.address}
          />
        </div>
        <div
          className="provider-contact-field"
          data-automation-id="input-phone"
        >
          <MainInput
            label={"Teléfono"}
            required={true}
            id={"provider-phone"}
            name="phone"
            value={provider ? provider.phone : ""}
            handleChange={handleChange}
            maxLength={30}
            error={error.phone && error.phone}
          />
        </div>
        <div
          className="provider-contact-field"
          data-automation-id="input-email"
        >
          <MainInput
            label={"Email"}
            required={false}
            id={"provider-email"}
            name="email"
            value={provider ? provider.email : ""}
            handleChange={handleChange}
            maxLength={30}
            error={error.email && error.email}
          />
        </div>
      </>
    );
  }
}

export default ContactForm;
