import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'

import i18n from "i18n";

import AdminLayout from 'components/MainApp/layouts/DesktopLayout';
import './styles.scss'

const SetupLocationsView = (props) => {
    const history = useHistory();
    const [id, setid] = useState(null);

    useEffect(() => {
        if(!props.match.params.key) return
        const id = props.match.params.key;
        setid(id)
    }, []);

    const goToAddLocation = () => {
        history.push({
            pathname: `/admin/locations/add`,
            state: {
                type: 'ZONE',
                node_code: id
            }
        })
    }

    const headerOptions = [];
    const content = <div className={"setup-locations-content-wrapper"}>
        <p className={"setup-locations-text"}>
            Podrás organizar diferentes zonas con sectores y niveles para asignar a cada producto. 
        </p>
        <p className="setup-locations-text">Te mostramos un ejemplo para que te guíes.</p>
        <p className="setup-locations-text">Zona: Almacén</p>
        <p className="setup-locations-text">Sector: Lácteos</p>
        <p className="setup-locations-text">Estantería: Nº1</p>
        <p className="setup-locations-text">Nivel 4: Ubicaciones</p>


        <div className="setup-locations-button" onClick={goToAddLocation}>COMENZAR</div>
    </div>
    return(
        <div className="setup-locations-container">
        <AdminLayout
          headerTitle={i18n.t('Sucursal Nodo 1')}
          headerOptions={headerOptions}
          content={content}
          navHidden={true}
        ></AdminLayout>
      </div>
    )
}

export default SetupLocationsView