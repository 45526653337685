import React, { Fragment } from 'react';
import onClickOutside from "react-onclickoutside";
import GenericModal from '../../modals/GenericModal';
import ModalButton from '../../modals/ModalButton';
import AccountSelection from '../../../organisms/AccountSelection';

import './styles.scss';


export class AccountDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleSelection: false
        }
    }

    toggleSelection = () => {
        const newValue = !this.state.toggleSelection
        this.setState({
            toggleSelection: newValue
        })
    }

    closeDropdown = () => {
        this.setState({
            toggleSelection: false
        })
    }

    handleClickOutside() {
        this.closeDropdown();
    }

    handleSelectAccount = (account) => {
        if (this.props.handleSelectAccount) {
            this.props.handleSelectAccount(account)
        }
        this.closeDropdown();
    }
    render() {
        const {
            email,
            accountList,
            handleAddEmail
        } = this.props;

        const accountSelectionElement = <AccountSelection handleAddEmail={handleAddEmail} handleSelectAccount={this.handleSelectAccount} accountList={accountList} ></AccountSelection>
        const modalContent = <div className='modal-wrapper-container'>{accountSelectionElement} <div className="cancel-button"><ModalButton label={'CANCELAR'} handleClick={this.closeDropdown}></ModalButton></div></div>
        return (
            <div className="account-dropdown-container">

                <div className="container" onClick={this.toggleSelection} data-automation-id="dropdown-account">
                    <div className="content-wrapper">
                        <i className="icon icon-avatar"></i>
                        <p className="email">{email}</p>
                    </div>
                    <i className={this.state.toggleSelection ? "chevron-up icon-chevron" : "chevron icon-chevron-down"}></i>
                    <div className="dropdown-container">
                    </div>
                </div>
                {this.state.toggleSelection &&
                    <Fragment>
                        <div className="desktop-dropdown">
                            {accountSelectionElement}
                        </div>
                        <div className="mobile-modal">
                            <GenericModal open={true} content={modalContent} handleClose={this.closeDropdown}></GenericModal>
                        </div>
                    </Fragment>
                }
            </div>
        )
    }

}


AccountDropdown.propTypes = {};

export default onClickOutside(AccountDropdown);