import React from 'react';
import './styles.scss';
import RoundedButton from '../../../../../components/Auth/atoms/buttons/RoundedButton'
import ModalButton from '../../../../../components/Auth/atoms/modals/ModalButton'
import PasswordInput from '../../../../../components/Auth/atoms/forms/InputPasswordAuth'
import AccountDropdown from '../../../../../components/Auth/atoms/forms/DropdownAccount';

import Logo from 'components/Auth/atoms/images/Logo'
import { Link } from 'react-router-dom';

export class HiAgain extends React.Component {
    render() {
        const {
            handleChange,
            handleSelectAccount,
            handleSubmit,
            buttonState = 'diabled',
            errors,
            errorMessage,
            accounts,
            handleAddEmail
        } = this.props;

        return (
            <div className='hi-again'>
                <form onSubmit={handleSubmit}>
                    <Logo></Logo>
                    <p className='title'>¡Hola de nuevo!</p>
                    <div className='form-container'>                    
                        <div className="inputs-wrapper">
                            <AccountDropdown accountList={accounts} handleAddEmail={handleAddEmail} handleSelectAccount={handleSelectAccount} id={'user'} email={this.props.selectedUser}></AccountDropdown>
                            <PasswordInput handleChange={handleChange} id={'password'} error={errorMessage ? errorMessage : errors.password}></PasswordInput>
                        </div>
                        <div className="remember-row">
                            <Link to="/forgotten" className="link"><button data-automation-id="forgot-password" type="button">Olvidé mi contraseña</button></Link>
                        </div>
                    </div>
                    <div className="rounded-button-wrapper">
                        <RoundedButton legend='Ingresar' handleClick={handleSubmit} state={buttonState}></RoundedButton>
                    </div>
                </form>
            </div>
        )
    }

}


HiAgain.propTypes = {};

export default HiAgain;