import React, { useState } from "react";
import { Modal, Box, Button, Typography, DialogActions } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

const TabModalFileUpload = ({ isOpen, handleClose, handleUploadFile }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      alert("Por favor, selecciona un archivo para subir.");
      return;
    }
    handleUploadFile(file);
  };

  return (
    <Modal
      className="dialog_file_upload"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Subir Archivo
        </Typography>
        <input
          accept="image/*,application/java-archive"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <Typography variant="subtitle1" component="div">
          {file?.name || "No se ha seleccionado ningún archivo"}
        </Typography>

        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            >
            Cancelar
          </Button>

          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span" style={{ whiteSpace: 'nowrap' }}>
              Seleccionar Archivo
            </Button>
          </label>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
          >
            Subir
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default TabModalFileUpload;
