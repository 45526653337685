import React, { useEffect } from 'react'

import Step from 'components/MainApp/atoms/Wizard/Step'
import Checkbox from 'components/Auth/atoms/forms/CheckboxCostum'
import Item from 'components/MainApp/organisms/Tags/Tag'
import TagAssociationSummary from 'components/MainApp/organisms/Tags/TagAssociationSummary'

import './styles.scss'
import { subWeeks } from 'date-fns'
import { unstable_renderSubtreeIntoContainer } from 'react-dom'

export const ThirdPartyTagAssociationStepTwo = ({
  tagsAssociated,
  tagsNotAssociated,
  handleDelete,
  handleSelect,
  allSelecteds,
  handleSelectAll,
  goBackFunc
}) => {

  const [newtagsAssociatedBySKU, setnewtagsAssociatedBySKU] = React.useState([])
  const [skus, setskus] = React.useState([])

  const uniqSKU = (array) => {
    return array.sort().filter(function(item, pos, ary) {
      return !pos || item.sku != ary[pos - 1].sku
    })
  }

  useEffect(() => {
    let newskus = uniqSKU(
      tagsAssociated.map((tag) => {
        return { sku: tag.sku, tags: [] }
      })
    )
    if (tagsAssociated) {
      setskus([...newskus])
      setnewtagsAssociatedBySKU([
        ...newskus.map((sku) => {
          return { tags: [...tagsAssociated].filter((p) => p.sku === sku.sku), sku: sku.sku }
        })
      ])
    }
  }, [tagsAssociated])


  const stepContent = (
    <div className="associate-tags-step-three-content">
      <div className="check-all">
        <div className="all-selector" onClick={() => handleSelectAll()}>
          <Checkbox checked={allSelecteds} />
        </div>
        Todos los Tags
      </div>
      <div className="load-movement-items-list">
        {tagsNotAssociated &&
          tagsNotAssociated.map((tag) => {
            return (
              <Item
                tag={tag}
                handleDelete={handleDelete}
                handleSelect={handleSelect}
                selected={tag.selected}
              />
            )
          })}
      </div>
    </div>
  )

  useEffect(() => {
    console.log([...newtagsAssociatedBySKU])
  }, [newtagsAssociatedBySKU])

  return (
    <div className="associate-tags-step-three-container">
      {tagsAssociated &&
        newtagsAssociatedBySKU &&
        skus.map((sku, index) => {
          return (
            <div key={`${sku}-${index}`} className={'associate-tags-step-three-location'}>
              <TagAssociationSummary
                sku={sku}
                tagsAssociatedBySKU={newtagsAssociatedBySKU}
                handleDelete={handleDelete}
              />
            </div>
          )
        })}

      {tagsNotAssociated.length !== 0 && (
        <Step title="Seleccione los Tags a asociar" goBackFunc={goBackFunc} content={stepContent}></Step>
      )}
    </div>
  )
}

export default ThirdPartyTagAssociationStepTwo
