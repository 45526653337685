import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import { DeviceService } from "services/DeviceService";

import useRequest from "hooks/useRequest";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import { Container, Box, Typography, FormControlLabel, Divider, Button, Grid, Switch, Card, CardContent } from '@material-ui/core';
import DeviceSelector from "components/Functionals/DeviceSelector";

import { WSService } from "services/WSServices";
import "./styles.scss";
import { ProductService } from "services/ProductService";
import ShelvingCard from "shared/components/shelving-card/ShelvingCard";
import { styled } from '@mui/material/styles';
import flowimage from './../../../../assets/images/AdvanFlow_200.jpg'

import SelfcheckoutCard from "shared/components/selfcheckout-card/SelfcheckoutCard";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";
import useTokenExpirationCheck from "hooks/useTokenExpirationCheck";

let globalChannel = null;

const FlowActivityView = () => {
  const history = useHistory();

  const [device, setdevice] = useState(null);
  const [channel, setChannel] = useState(false);
  const [arrayTags, setArrayTags] = useState([]);
  const [arrayTagsSaving, setArrayTagsSaving] = useState([]);
  const [mapTags, setMapTags] = useState(new Map());
  const [arrayProducts, setArrayProducts] = useState([]);
  const [myTime, setMyTime] = useState(new Date());
  const [queue, setQueue] = useState(false);
  const queueValue = useRef(false);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [emptyClass, setEmptyClass]  = useState("")
  const [classMoveRight, setClassMoveRight]  = useState("none")
  const [classMoveLeft, setClassMoveLeft]  = useState("none")
  var saving = false;
  const [antennas, setAntennas]  = useState([])
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [errorMsgModal, setErrorMsgModal] = useState("")
  const minutesCheckTokenTime = 5 ; // 5 minutos

  useTokenExpirationCheck(minutesCheckTokenTime);

  useEffect(() => {
    var timerID = setInterval(() => {
      tick();
      //console.log(arrayTags);
    }, 200);

    return () => clearInterval(timerID);
  });



  const tick = () => {
    var date = new Date();
    setMyTime(date);
    /*arrayTags.forEach((tag, index) => {
      var oldTagdate = new Date(date.getTime()-1000);
      if(tag.date < oldTagdate){
        arrayTags.splice(index, 1)
      }
    });*/
  }

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'block',
    height: '300%',
    marginLeft: '25vw',
    marginTop: '25vh',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  useEffect(() => {
    /*beforeSubmit();
    DeviceService.device(id).then((device) => {
      setdevice(device);
      afterSubmit();
    });*/
  }, []);



  const receiveEvent = useCallback((payload) => {
    //setTimeout(() => {
  // setClassMove("object van")
    setArrayTagsSaving([])
    const ws = WSService.getInstance();
    //console.log("Message from socket");
    console.log(payload);
    if (canParseJson(payload)) {
      payload = JSON.parse(payload);
    }

    if (payload.method == "SUBSCRIBE") {
      setChannel(payload.params.room);
    }

    if (payload.method === "SELFCHECKOUT" && payload.room && ws.inRoom(payload.room)) {
      if (payload.action === "resend") {
        arrayTags.splice(0, arrayTags.length);
      }
    }

    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {

      var antenna = 0;
      antenna = payload.params[0].antenna;
      setArrayTags(removeByAttr(arrayTags, "antenna", 2));
      setArrayTags(removeByAttr(arrayTags, "antenna", 1));
      payload.params.forEach(param => {
        antenna = param.antenna;
        // arrayTags.splice(0, arrayTags.length);
        if (param.epc != "ACTION:CLEAN") {
          var tag = {
            epc: param.epc,
            sku: param.sku,
            product: null,
            antenna: param.antenna
          }
          searchProduct(tag.sku, tag)
          arrayTags.push(tag)

        }
      });
      console.log("ANTENA: " + antenna);
      updateListTags(arrayTags);
      if(antenna == 1){
        setClassMoveRight("none")
        setClassMoveLeft("move-left")
      }else {
        setClassMoveLeft("none")
        setClassMoveRight("move-right")
      }

      setTimeout(() => {
        setClassMoveRight("none")
        setClassMoveLeft("none")
      }, 5000);

    }
    //}, 1000);
  }, []);

  const updateListTags = (arrayCompleto) => {
    console.log("UPDATEEEE");

    let uniqueTags = arrayCompleto.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.epc === value.epc
      )))

    setArrayTagsSaving([...uniqueTags]);
    saving = false;
    setArrayTags(arrayCompleto);
    setLastUpdate(new Date())
  }

  var removeByAttr = function (arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(attr)
        && (arguments.length > 2 && arr[i][attr] === value)) {

        arr.splice(i, 1);

      }
    }
    return arr;
  }

  const searchProduct = (sku, tag) => {
    if (arrayProducts && arrayProducts.length > 0) {
      const product = arrayProducts.find(prod => prod.sku == sku);
      tag.product = product;
    }

    if (!tag.product || tag.product == undefined) {
      ProductService.productBySKU(sku).then(prod => {
        tag.product = prod.results.results[0];
        arrayProducts.push(prod.results.results[0]);
      });
    }


  };

  useEffect(() => {
    console.log("SSSSSSss")
  }, [arrayTags])


  const refresh = () => {
    const ws = WSService.getInstance();
    console.log("refresh channel: " + channel)
    ws.resetTags(channel);
  }

  const canParseJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const receiveDevicesSelected = (data) => {
    if(data != undefined){
      console.log(data);
      let deviceID = data.devices[0];
      DeviceService.readersDevices({
       device_id: deviceID
      }).then((antennas) => {
        if(antennas.length == 2){
          antennas = antennas.sort((a, b) => {
            if (parseInt(a.port) < parseInt(b.port)) {
              return -1;
            }
            if (parseInt(a.port) > parseInt(b.port)) {
              return 1;
            }
            return 0;

          })
          console.log(antennas);
          setAntennas(antennas);
        }else{
          setAntennas([]);
          setErrorMsgModal("las antenas no estas correctamente configuradas");
          setOpenErrorModal(true);
        }

      });
    }
  }

  const content = (
    <Container className="content">
      <Card mt={0} ml={0} >
        <CardContent>

          <DeviceSelector
            enabled={true}
            typeRead={"CONTOCK"}
            refRead={""}
            receiveEvent={receiveEvent}
            maxDevicesSelected={1}
            receiveDevicesSelected={receiveDevicesSelected}
            includeType={"FLOW"}
          />

          <Grid container>
            <Grid xs={8} lg={8} xl={8}>
              <Typography className="textupdate" component="div" align='left'>
                Ultima Actualizacion: {lastUpdate.toJSON().slice(0, 19).replace(/-/g, '/').replace(/T/g, ' ')}
              </Typography>
            </Grid>
            <Grid xs={4} lg={4} xl={4}>
              <Button onClick={refresh} className="btnrefresh">ACTUALIZAR</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box mt={3} ml={0} >
        <Grid container>
          <Typography gutterBottom className="textTotal">
            Total de productos
          </Typography>
          <div className="txtcantidad">{arrayTagsSaving.length}</div>
        </Grid>
      </Box>



      <Box className="image-flow">
        <Grid container >
          <Grid xs={6}>
          <Box className="zona" pt={4}>
            {antennas.length == 2 ? antennas[0].name : "Port 1"}
          </Box>
          </Grid>
          <Grid xs={6}>
          <Box className="zona" pt={4}>
            {antennas.length == 2 ? antennas[1].name : "Port 2"}
          </Box>
          </Grid>
        </Grid>
      </Box>


       <div  className={`object van-left ${classMoveLeft}`}>
          <ShelvingCard  listItems={arrayTagsSaving && arrayTagsSaving.filter(tag => tag.antenna == 1)} antenna={1} mensaje={"Entrada " + (antennas.length == 2 ? antennas[0].name : "")}/>
        </div>

        <div className={`object van ${classMoveRight}`}>
          <ShelvingCard listItems={arrayTagsSaving && arrayTagsSaving.filter(tag => tag.antenna == 2)} antenna={2} mensaje={"Entrada " + (antennas.length == 2 ? antennas[1].name : "")}/>
        </div>

    </Container>
  );


  return (
    <div className="device-detail-container">
      <AdminLayout
        headerTitle={i18n.t("CONTOCK")}
        headerOptions={[]}
        content={content}
        navHidden={true}
      ></AdminLayout>

      {loading && <Loader />}
      <GenericErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        error={errorMsgModal}
      ></GenericErrorModal>
    </div>
  );
};

export default FlowActivityView;
