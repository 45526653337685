import React, { useEffect, useState } from "react";
import { ProductService } from "services/ProductService";
import "./styles.scss";
import imageFile from "../../../assets/images/default_product.png";

export const AssociationProductItem = (props) => {
    const [imageError, setImageError] = useState(false);

    return (
        <>
            {props.product && (
                <div className="association-product-item">
                    <img src={!props.product?.image || imageError ? imageFile : props.product.image}
                        onError={() => { setImageError(true) }} />
                    <div className="association-product-data">
                        <div>
                            {props.product?.name}
                        </div>
                        <div className="association-product-sku">
                            sku {props.product?.sku}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AssociationProductItem;