import socketIOClient from "socket.io-client";

import Environment from "environment";

import { AuthService } from "services/AuthService.js";

export class WSService {
  romm = null;
  devices = null;
  constructor() {
    if (!WSService._instance) {
      WSService._instance = this;
      this.connect();
    }
    return WSService._instance;
  }

  static getInstance() {
    return WSService._instance;
  }

  connect() {
    this.socket = socketIOClient(Environment.ws, {
      transports: ["websocket", "polling"],
      query: {
        token: AuthService.getCleanToken(),
      },
    });

    this.socket.on("connect", () => {
      console.log("Connected: " + this.socket.connected);
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected: " + this.socket.connected);
      this.updateToken();
    });

    this.socket.on("connect_error", () => {
      console.log("Connect Error");
      this.updateToken();
    });

    this.socket.on("reconnect_attempt", () => {
      console.log("Reconnect");
      this.updateToken();
    });
  }

  disconnect() {
    this.socket.disconnect();
    WSService._instance = null;
  }

  updateToken() {
    this.socket.io.opts.query = {
      token: AuthService.getCleanToken(),
    };
  }

  inRoom(room) {
    let channels = this.room.split("-");
    let rta = false
    channels.forEach((ch) => {
      if(ch == room){
        rta = true;
      }
    })
    return rta;
  }

  joinRoom(room) {
    this.room = room;
    this.socket.emit("message", {
      method: "SUBSCRIBE",
      params: {
        room: room,
      },
    });
  }

  setRoom(room) {
    this.room = room;
  }

  resetTags(room) {
    this.room = room;
    this.socket.emit("message", {
      method: "SELFCHECKOUT",
      params: {
        room: room,
        action: 'resend'
      },
    });
  }

  ping(room) {
    this.room = room;
    this.socket.emit("message", {
      method: "DEVICESTATE",
      params: {
        room: room,
        action: 'ping',
        open_channel: false
      },
    });
  }

  updateVersion(room, urlJar, nameJar) {
    this.room = room;
    this.socket.emit("message", {
      method: "UPDATEVERSION",
      params: {
        room: room,
        action: 'update',
        nameJar: nameJar,
        urlJar: urlJar 
      },
    });
  }

  find(room, tag, deviceHandheldIdentifier) {
    this.room = room;
    this.socket.emit("message", {
      method: "SELFCHECKOUT",
      params: {
        room: room,
        epc: tag,
        action: 'find:'+tag +':'+deviceHandheldIdentifier
      },
    });
  }

  leaveRoom(room) {
    this.room = null;
    this.socket.emit("message", {
      method: "UNSUBSCRIBE",
      params: {
        room: room,
      },
    });
  }

  listen(message, callback) {
    this.socket.on(message, callback);
  }

  removeListener(message) {
    this.socket.removeListener(message);
  }

  setDevices(devices) {
    let nodeCode = AuthService.getCurrentNodeCode();
    let storageDevices = JSON.parse(localStorage.getItem("devices"));
    if (!storageDevices) {
      storageDevices = {};
    }
    storageDevices[nodeCode] = devices;
    localStorage.setItem("devices", JSON.stringify(storageDevices));
    this.devices = storageDevices;
  }

  refreshDevices(enabledDevices) {
    let storageDevices = JSON.parse(localStorage.getItem("devices"));
    if (!storageDevices) {
      storageDevices = {};
    }
    let nodeCode = AuthService.getCurrentNodeCode();
    let actualDevices = storageDevices[nodeCode]
      ? storageDevices[nodeCode]
      : [];
    actualDevices = actualDevices.filter((d) => enabledDevices.includes(d.id));
    localStorage.setItem("devices", JSON.stringify(storageDevices));
    this.devices = actualDevices;
    return actualDevices;
  }
}

/*
Example

const ws = WSService.getInstance()
ws.joinRoom("ccd");

setTimeout(_ => {
    ws.listen("message", (payload) => {
        console.log("Ahora si");
        console.log(payload);
        console.debug(payload);
    })
}, 5000);

return () => {
    ws.leaveRoom("beb");
    ws.removeListener("message");
}
*/
