import "./ModalNewAlert.scss";
import { useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import MainAppGenericModal from "components/MainApp/atoms/Modals/GenericModal";
import closeIcon from "assets/images/close-icon.svg";
import alarmIcon from "assets/images/alarm-icon.svg";
import clockIcon from "assets/images/clock-icon.svg";
import deviceIcon from "assets/images/device-icon-blue.svg";
import locationIcon from "assets/images/location-icon.svg";
import skuIcon from "assets/images/sku-icon.svg";
import backArrow from "assets/images/back-arrow.svg";

const ModalNewAlert = ({ isOpen, setIsOpen, alert, setAlertSelected }) => {
  const { isMobile } = useWindowSize();
  const [notProcess, setNotProcess] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSeeDetail = () => {
    setIsOpen(false);
    setAlertSelected(alert);
  };

  const handleNotProcess = () => {
    if(isMobile) {
      handleClose();
    } else {
      setNotProcess(true);
    }
  };

  const detailItems = [
    { title: "Dispositivo", value: alert?.device?.name, icon: deviceIcon },
    {
      title: "Lugar",
      value: alert?.device?.location?.name,
      icon: locationIcon
    },
    { title: "SKU", value: alert?.tag?.sku, icon: skuIcon }
  ];

  const newAlertInfo = (
    <section className="modal-new-alert-info">
      <img src={isMobile ? backArrow : closeIcon} onClick={handleClose} />
      <header>
        <img src={alarmIcon} />
        <div>
          <h4>Alerta</h4>
          <p>
            <img src={clockIcon} /> 27/09/2024 11:15:01
          </p>
        </div>
      </header>
      <div className="modal-new-alert-info__detail">
        {detailItems.map((item, idx) => (
          <article key={idx}>
            <picture>
              <img src={item.icon} />
            </picture>
            <div>
              <h5>{item.title}</h5>
              <h3>{item.value}</h3>
            </div>
          </article>
        ))}
      </div>
      <footer>
        <button onClick={handleNotProcess}>No procesar</button>
        <button onClick={handleSeeDetail}>Ver detalle</button>
      </footer>
    </section>
  );

  const notProcessAlert = (
    <section className="modal-new-alert-not-process">
      <h3>¿Deseas no procesar esta alerta ahora?</h3>
      <p>Al no procesar, la alerta quedará en estado pendiente.</p>
      <div>
        <button onClick={handleSeeDetail}>Ver detalle</button>
        <button onClick={handleClose}>No procesar</button>
      </div>
    </section>
  );

  const contentPopUp = notProcess ? notProcessAlert : newAlertInfo;

  return (
    <>
      <MainAppGenericModal
        open={isOpen}
        content={contentPopUp}
        handleClose={handleClose}
      />
    </>
  );
};

export default ModalNewAlert;
