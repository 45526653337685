import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainTextArea from 'components/MainApp/atoms/Forms/MainTextArea'
import MultiSelectInput from "components/MainApp/atoms/Forms/MultiSelectInputGeneric";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";

import "./styles.scss";

export class AddConfigTagStepOne extends React.Component {
  render() {
    const { configTag, types, handleChange, handleSelect, handleContinue, enableContinue, error } = this.props;

    const stepContent = (
      <div className="add-config-tag-wizard-step-one-content">
        <div className="add-config-tag-wizard-step-one-field" data-automation-id="input-type">
          <MainDropdownInput
            required={true}
            id={"type"}
            name="type"
            selectedOption={configTag ? configTag.type : ''}
            handleSelection={(selectedOption) => handleSelect(selectedOption, 'type')}
            maxLength={50}
            options={types}
            placeholder={'Tipo de embalaje'} 
            label={"Tipo de embalaje"}
            error={error.type && error.type}
          />
            </div>
        <div className="add-config-tag-wizard-step-one-field" data-automation-id="input-name">
          <MainInput
            label={"Nombre"}
            required={true}
            type="text"
            id={"configTag-name"}
            name="name"
            value={configTag ? configTag.name : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.name && error.name}
          />
        </div>
        <div className="add-config-tag-wizard-step-one-field" data-automation-id="input-description">
          <MainTextArea
            label={"Descripcion (opcional)"}
            required={false}
            id={"configTag-description"}
            name="description"
            value={configTag ? configTag.description : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.description && error.description}
          />
        </div>
        
        <div className={"add-config-tag-wizard-step-one-action-wrapper"}>
          <button type="Button" className={`add-config-tag-wizard-step-one-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
    );

    return (
      <div className="add-config-tag-wizard-step-one-container">
        <Step title="" content={stepContent}></Step>
      </div>
    );
  }
}

export default AddConfigTagStepOne;
