import React from 'react';

import GenericFormContainer from './GenericFormContainer';


class GenericListContainer extends GenericFormContainer {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleFiltersChangeName = this.handleFiltersChangeName.bind(this);
    this.headerOrderingHandler = this.headerOrderingHandler.bind(this);
    this.handlePagerChange = this.handlePagerChange.bind(this);
  }

  filter() {
  }

  paramsUrl(url) {
    let params = url.substr(url.indexOf('?') + 1).split('&');
    let paramsValue = {};
    for (const p of params) {
      const s = p.split('=');
      paramsValue[s[0]] = s[1];
    }
    return paramsValue;
  }

  paramUrl(url, param) {
    if (! url) {
      return null;
    }
    const params = this.paramsUrl(url)
    if (param in params) {
      return params[param];
    }
    return null;
  }

  handleSearch(value) {
    const filters = this.state.filters;
    filters['page'] = 1;
    filters['search'] = value;
    this.setState({
      filters: filters
    }, _ => {
      this.filter();
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value || undefined;
    const name = target.name;
    const filters = this.state.filters;
    filters[name] = value;
    this.setState({
      filters: filters
    });
  }

  handleFiltersChange(event) {
    const target = event.target;
    const value = target.value || undefined;
    const name = target.name;
    const filters = this.state.filters;
    filters[name] = value;
    filters['page'] = 1;
    this.setState({
      filters: filters
    }, _ => {
      this.filter();
    });
  }

  handleFiltersChangeName(name, value, apply=true) {
    const filters = this.state.filters;
    filters[name] = value;
    filters['page'] = 1;
    this.setState({
      filters: filters
    }, _ => {
      if (apply) {
        this.filter();
      }
    });
  }

  headerOrderingHandler(key) {
    const headers = this.state.headers;
    if (headers[key]['active']) {
      headers[key]['direction'] = !headers[key]['direction'];
    } else {
      Object.keys(headers).forEach(k => {
        headers[k]['active'] = false;
        headers[k]['direction'] = false;
      });
      headers[key]['active'] = true;
    }
    const ordering = headers[key]['direction'] ? headers[key]['value'] : `-${headers[key]['value']}`; 

    const filters = this.state.filters;
    filters['page'] = 1;
    filters['ordering'] = ordering;
    this.setState({
      headers: headers,
      filters: filters
    }, _ => {
      this.filter();
    });
  }

  resetOrdering() {
    const headers = this.state.headers;
    Object.keys(headers).forEach(k => {
      headers[k]['active'] = false;
      headers[k]['direction'] = false;
    });
    this.setState({
      headers: headers
    });  
  }

  handlePagerChange(page) {
    const filters = this.state.filters;
    filters['page'] = page;
    this.setState({
      filters: filters
    });
    this.filter();
  }

  render(){
    return(<div></div>)
  }
}

export default GenericListContainer;
