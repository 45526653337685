import React from "react";
import ProductModalWrapper from "components/MainApp/organisms/Product/Modals/ProductModalWrapper";

import "./styles.scss";
export class ErrorModal extends React.Component {
  render() {
    const {
      open,
      message,

      handleClose,
      handleGoBack,
      handleAddLocalization,
      handleButtonClick, //Buscar nuevamente
    } = this.props;

    const content = (
      <div className="wrong-content-modal-wrapper">
        <div className="wrong-content-modal-title">Código inexistente</div>
        <div className="wrong-content-modal-text">{message}</div>
      </div>
    );

    const footer = (
      <div className="product-modal-wrapper-footer">
        En caso que el producto no exista puedes{" "}
        <span
          className="product-modal-wrapper-span"
          onClick={handleAddLocalization}
          data-automation-id="download-template"
        >
          Cargar una nueva localización
        </span>
      </div>
    );
    return (
      <div className="wrong-content-modal-container">
        <ProductModalWrapper
          open={open}
          innerContent={content}
          buttonLegend={"BUSCAR NUEVAMENTE"}
          handleGoBack={handleGoBack}
          handleClose={handleClose}
          handleButtonClick={handleButtonClick}
          footer={footer}
        ></ProductModalWrapper>
      </div>
    );
  }
}

ErrorModal.propTypes = {};

export default ErrorModal;
