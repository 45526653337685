import React from "react";
import onClickOutside from "react-onclickoutside";

import Checkbox from "../../../../Auth/atoms/forms/CheckboxCostum";

import "./styles.scss";
import { isMobile } from "helpers/Mobile";
export class TwoOptionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      open: false,
    };
  }

  handleClick = (e) => {
    const open = !this.state.open;
    this.setState({
      open,
      focused: open,
    });
  };

  handleSelect = (selection) => {

    if (selection === this.props.firstOptionLabel) {
      this.deleteAllSelectedItems();
      return;
    }

    let selectedOptionIndex = this.props.options.findIndex((o) => {
      return o.label === selection;
    });

    let selectedObject = this.props.options[selectedOptionIndex];
    
    let optionChecked = this.props.selectedOptions && this.props.selectedOptions.some( o => o.label === selection)

    let operation = optionChecked
      ? "delete"
      : "add";

    this.props.handleSelection(selectedObject, operation, this.props.label);
  };

  deleteSelectedItem = (e) => {
    let selectedItem = e.target.dataset.option;
    let selectedOptionIndex = this.props.options.findIndex((o) => {
      return o.label === selectedItem;
    });

    let selectedObject = this.props.options[selectedOptionIndex];

    this.props.handleSelection(selectedObject, "delete", this.props.label);
  };

  deleteAllSelectedItems = (e) => {
    this.props.handleSelection("", "deleteAll", this.props.label);
  };

  handleClickOutside() {
    this.setState({
      focused: false,
      open: false,
    });
  }

  
  render() {
    const {
      options,
      firstOptionLabel,
      label,
      selectedOptions = [],
    } = this.props;

    if (isMobile) {
      return (
        <div className="dropdown-filter-container">
          <div
            className={
              this.state.focused
                ? "dropdown-filter-input focused"
                : "dropdown-filter-input"
            }
            onClick={this.handleClick}
          >
            <div className="dropdown-filter-label">{label}</div>
            <div className="dropdown-filter-label-inner">
              {selectedOptions.length === 0 && (
                <div className="dropdown-filter-placeholder">
                  {firstOptionLabel}
                </div>
              )}

              {selectedOptions.length > 0 && (
                <div className="dropdown-filter-selection">
                  {isMobile &&
                    selectedOptions.map((selectedItem, i) => {
                      if (i !== selectedOptions.length - 1) {
                        return selectedItem.label + ", ";
                      } else {
                        return selectedItem.label;
                      }
                    })}
                </div>
              )}
              <i
                className={`icon-chevron dropdown-filter-chevron ${
                  this.state.open ? "open" : ""
                } `}
              ></i>
            </div>
          </div>
          {this.state.open && (
            <div className="dropdown-filter-option-container">
              <div className="dropdown-filter-option-wrapper">
                <div className="dropdown-filter-option">
                  <Checkbox
                    checked={selectedOptions.length === 0}
                    label={firstOptionLabel}
                    handleChange={this.handleSelect}
                  ></Checkbox>
                </div>
                {options.map((option) => {
                  let optionChecked = selectedOptions.some( o => o.label === option.label)

                  return (
                    <div className="dropdown-filter-option" key={option.label}>
                      <Checkbox
                        label={option.label}
                        handleChange={this.handleSelect}
                        checked={optionChecked}
                      ></Checkbox>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
    }

    if (!isMobile) {
      return (
        <div className="two-option-filter-container">
          <div className="two-option-filter-label">{label}</div>
          <div className="two-option-filter-options-wrapper">
            {this.props.options.map((option) => {
              let optionChecked = selectedOptions.some( o => o.label === option.label)

              return (
                <div className="two-option-filter-option" key={option.label}>
                  <Checkbox
                    label={option.label}
                    handleChange={this.handleSelect}
                    checked={optionChecked}
                  ></Checkbox>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
}

export default onClickOutside(TwoOptionFilter);
