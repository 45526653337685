import i18n from "i18n";

class Account {
  id = null;
  first_name = null;
  last_name = null;
  phone = null;
  email = null;
  country = null;
  language = null;
  nodes = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }

  getEditPanelView() {
    return [
      {
        title: i18n.t("Nombre"),
        value: this.first_name,
      },
      {
        title: i18n.t("Apellido"),
        value: this.last_name,
      },
      {
        title: i18n.t("Telefono"),
        value: this.phone,
      },
      {
        title: i18n.t("Email"),
        value: this.email,
      },
      {
        title: i18n.t("Lenguaje"),
        value: this.language,
      },
      {
        title: i18n.t("Sucursales"),
        value: this.nodes.map((n) => n.name).join(", "),
      },
    ];
  }
}

export { Account };
