import React, {useState} from "react";
import {isMobile} from "../../../../helpers/Mobile"
import "./styles.scss";


export const TogglePanel = ({fields, title="Descripción"}) => {
    const [ toggleOpen, setToggleOpen ] = useState(false)
 
    return (
        <div className="toggle-panel-container">

            <div className={`toggle-panel-title`} onClick={() => setToggleOpen(!toggleOpen)}>
            {title}
                {(isMobile) &&
                    <i name="icon-chevron" className={`icon-chevron toggle-panel-chevron ${toggleOpen ? "open" : ""} `}></i>
                }
            </div>

                {(!isMobile || toggleOpen) && <React.Fragment>
                    {fields.map( item => {
                        return (<div className="toggle-panel-section" key={item.title}>
                                    <div className="toggle-panel-section-title">{item.title}</div>
                                    <div className="toggle-panel-section-content">{item.value}</div>
                                </div>)
                    })}
                </React.Fragment>}
            
        </div>
    );
  
}

export default TogglePanel;