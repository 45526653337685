import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { AuthService } from "services/AuthService";

import MainButton from "../../atoms/RoundedButton";
import Tooltip from "../../atoms/Tooltip";
import Icon from "components/MainApp/atoms/Icon/icon";

import "./styles.scss";
import imageFileYax from "assets/images/icon-yax-text.png";
import imageFileIderoTech from "assets/images/iderotech.png";
import imageFileYaxMobile from "assets/images/icon-yax-text-Mobile.png";
import imageFileIderoTechMobile from "assets/images/iderotechMobile.png";
import iconHeadset from "assets/images/icon-headset.png";
import { Modal } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { isMobile } from "helpers/Mobile";

const HeaderTipshelf = ({
  goBackFunc,
  title,
  options,
  optionsIcons,
  buttonLabel,
  buttonHandler,
  buttonState,
  content,
  onHandleMouseDown,
  onHandleClickClean
}) => {
  const [node, _] = useState(AuthService.currentNodeValue());
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const [pressTimer, setPressTimer] = React.useState(null);
  const [pressTimer2, setPressTimer2] = React.useState(null);

  const handleInteractionStart = () => {
    setPressTimer(
      setTimeout(() => {
        history.push("/admin/devices");
      }, 3000)
    );
  };

  const handleInteractionEnd = () => {
    clearTimeout(pressTimer);
  };

  const handleInteractionIderoStart = () => {
    setPressTimer2(
      setTimeout(() => {
        onHandleMouseDown();
      }, 3000)
    );
  };

  const handleInteractionIderoEnd = () => {
    clearTimeout(pressTimer2);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="header-tipshelf-container">
      <div className="left-tipshelf-container">
        {goBackFunc && (
          <i
            className="icon-go-back-desktop icon-chevron-izquierda"
            onClick={goBackFunc}
            data-automation-id="go-back-header"
          ></i>
        )}
        <div className={goBackFunc ? "title-go-back" : "title"}>{title}</div>
      </div>
      <div className="middle-container">
        <img
          className="logo-yax"
          src={isMobile ? imageFileYaxMobile : imageFileYax}
          alt="logo YAX"
          onMouseDown={handleInteractionStart}
          onMouseUp={handleInteractionEnd}
          onMouseLeave={handleInteractionEnd}
          onTouchStart={handleInteractionStart}
          onTouchEnd={handleInteractionEnd}
        />

        <img
          className="logo-iderotech"
          src={isMobile ? imageFileIderoTechMobile : imageFileIderoTech}
          alt="logo iderotech"
          onMouseDown={handleInteractionIderoStart}
          onMouseUp={handleInteractionIderoEnd}
          onMouseLeave={handleInteractionIderoEnd}
          onTouchStart={handleInteractionIderoStart}
          onTouchEnd={handleInteractionIderoEnd}
        />
      </div>

      <div className="right-tipshelf-container">
        {buttonLabel && (
          <>
            <div className="header-button-tip">
              <MainButton
                legend={"Limpiar"}
                state={"enabled"}
                handleClick={onHandleClickClean}
              ></MainButton>
            </div>
            <div className="header-button">
              <MainButton
                legend={buttonLabel}
                state={buttonState}
                handleClick={handleOpen}
              ></MainButton>
              <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <Box
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    padding: "1em",
                    maxWidth: "90%",
                    minWidth: "30%",
                    minHeight: "30%",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px"
                  }}
                >
                  <img src={iconHeadset} alt="iconHeadset" />
                  <h2 id="simple-modal-title">Asistencia en camino</h2>
                  <p>Pronto recibirás asistencia personalizada.</p>
                  <p>Gracias por tu paciencia</p>

                  <div className="btnContainer">
                    <span className="btnCancel">
                      <MainButton
                        legend="cancelar asistencia"
                        handleClick={handleClose}
                        state="enabled"
                      />
                    </span>
                  </div>
                </Box>
              </Modal>
            </div>
          </>
        )}
        {optionsIcons?.map(option => {
          return (
            <div
              className="header-item"
              key={option.name}
              onClick={option.handler}
              data-automation-id="menu-option"
            >
              <Tooltip
                content={
                  <Icon
                    className={"icon " + option.icon}
                    name={option.icon}
                    fill={"#3C7AF5"}
                    width={23}
                    height={27}
                  />
                }
                text={option.tooltip}
              ></Tooltip>
            </div>
          );
        })}
        {options.map(option => {
          return (
            <div
              className="header-item"
              key={option.name}
              onClick={option.handler}
              data-automation-id="menu-option"
            >
              <Tooltip
                content={
                  option.icon ? (
                    <i className={"icon " + option.icon}></i>
                  ) : (
                    <img src={option.ic}></img>
                  )
                }
                text={option.tooltip}
              ></Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HeaderTipshelf;
