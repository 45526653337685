import React from "react";
// import i18n from "i18n";
import "./styles.scss";


export const ProductSummaryItem = ({product, stock, units, add, subtitle}) => {
    return(
        <div className="product-summary-container">
            <div className="product-summary-name">{product.name}</div>
            <div className="product-summary-brand">{product.brand && product.brand.name}</div>
            <div className="product-summary-code">{product.sku}</div>
            {!add && <div className="product-summary-units">{units} unidades</div>}
            {add  && <div className={`product-summary-units ${stock > 0 ? '' : 'disabled'} ${subtitle ? '' : 'notshown'}` }>{stock > 0 ? 'Con stock' : 'Sin stock'} </div>}
            <div className="product-summary-divider"></div>
        </div>
    )
}

export default ProductSummaryItem;