import React from "react";
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import ContactForm from "../../../components/ContactForm";

import "./styles.scss";
export class AddProviderStepTwo extends React.Component {
  render() {
    const {
      provider,
      handleChange,
      goBack,
      handleContinue,
      enableContinue,
      error,
    } = this.props;
    const stepContent = (
      <form onSubmit={handleContinue}>
        <div className="add-provider-wizard-step-two-content">
          <ContactForm
            provider={provider}
            handleChange={handleChange}
            error={error}
          />
          <div className={"add-provider-wizard-step-two-action-wrapper"}>
            <button
              type="submit"
              className={`add-provider-wizard-step-two-action-button ${!enableContinue && "disabled"}`}
              onClick={enableContinue ? handleContinue : undefined}
            >
              CONTINUAR
            </button>
          </div>
        </div>
      </form>
    );

    return (
      <div className="add-provider-wizard-step-two-container">
        <Step title="Contacto" goBackFunc={goBack} content={stepContent}></Step>
      </div>
    );
  }
}

export default AddProviderStepTwo;
