import React from "react";
import GenericModal from "components/MainApp/atoms/Modals/GenericModal";

import "./styles.scss";
const DeleteBusinessNodeModal = ({ open, handleClose, handleCancel, handleConfirm, businessNodeName}) => {
    const content = <div className="delete-business-node-modal-content">
        <div className="delete-business-node-modal-title">Al eliminar, se perderán los datos cargados.</div>
        <div className="delete-business-node-modal-buttons">
            <div onClick={handleCancel}>QUEDARME</div>
            <div onClick={handleConfirm}>SÍ, ELIMINAR</div>
        </div>
    </div>
    return (
      <div className="delete-business-node-modal-container">
          <GenericModal  title={`¿Está seguro que deseas eliminar la sucursal "${businessNodeName}"?`}
            open={open}
            content={content}
            handleClose={handleClose}></GenericModal>
      </div>
    );
}


export default DeleteBusinessNodeModal;