import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";

import { ApplicationService } from "services/ApplicationService";

import useRequest from "hooks/useRequest";
import { isMobile } from "helpers/Mobile";

import "./styles.scss";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import AppTable from "./components/AppTable";
import Pagination from "components/MainApp/atoms/Pagination";

const headers = [
  {
    name: "logo",
    title: "LOGO",
    active: false,
    direction: false,
  },
  {
    name: "name",
    title: "NOMBRE",
    active: false,
    direction: false,
  },
  {
    name: "client_id",
    title: "CLIENTE ID",
    active: false,
    direction: false,
  },
  {
    name: "client_secret",
    title: "CLIENTE SECRETO",
    active: false,
    direction: false,
  },
  {
    name: "status",
    title: "ESTADO",
    active: false,
    direction: false,
  },
  {
    name: "scopes",
    title: "SCOPES",
    active: false,
    direction: false,
  },
];

let scrolling = false;

export const ApplicationsView = () => {
  const history = useHistory();
  const [noApps, setNoApps] = useState();
  const [apps, setApps] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const myRef = useRef(0);
  let pageSize = 10;

  const [pager, setPager] = useState({
    next: null,
    previous: null,
    count: 0,
    countPages: 0,
  });
  const [page, setPage] = useState(1);

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  useEffect(() => {
    filter({ page: page, page_size: pageSize }, false);
  }, [page]);

  const filter = (params, first = false) => {
    beforeSubmit();
    ApplicationService.applications(params)
      .then((response) => {
        afterSubmit();
        if (first && response.results.length < 1) setNoApps(true);

        const newApps =
          isMobile && params.page !== 1
            ? [...apps, ...response.results]
            : response.results;

        setApps(newApps);
        setPager({
          next: response.next,
          previous: response.previous,
          count: response.count,
          countPages: Math.ceil(response.count / pageSize),
        });

        scrolling = false;
      })
      .catch((_) => {
        afterSubmit();
        scrolling = false;
      });
  };

  const handleScroll = (event) => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || submitted || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const handlePagerChange = function (page) {
    setPage(page);
  };

  const goBack = () => {
    history.push("/admin/more-options/1");
  };

  const createApp = () => {
    history.push("/admin/applications/add");
  };

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: createApp,
      tooltip: i18n.t("Agregar Aplicacion"),
    },
  ];

  const goToApp = (id) => {
    history.push(`/admin/applications/edit/${id}`);
  };

  const content = (
      <div className="application-table-wrapper">
        <AppTable
          apps={apps}
          totalItems={pager.count}
          headers={headers}
          handleClickApp={goToApp}
        />
        {apps.length > 0 && (
          <div className="application-table-pagination">
            <Pagination
              pageQuantity={pager.countPages}
              currentPage={page}
              pageChangeHandler={handlePagerChange}
            />
          </div>
        )}
      </div>
  );
  
  return (
    <div className="applications-container">
      <AdminLayout
        headerTitle={i18n.t("Aplicaciones externas")}
        headerOptions={headerOptions}
        content={content}
        goBackFunc={goBack}
        navHidden={false}
      ></AdminLayout>

      {loading && <Loader />}
    </div>
  );
};

export default ApplicationsView;
