import React from "react";
import { Link } from "react-router-dom";
import Card from "../Card";
import PercentageBard from "../PercentageBar";
import DropdownOptions from "../DropdownOptions";

import "./styles.scss";

const options = [
  {
    key: "name",
    value: "Ver por nombre",
  },
  {
    key: "sku",
    value: "Ver por sku",
  },
];

const MostSelledCard = ({ items, handleChangeFilter, handleSeeAll }) => {
  return (
    <div className={"delivered-orders-card-container"}>
      <Card>
        <div className={"title-container"}>
          <h1 className={"dashboard-card-title"}>Los 5 más vendidos últimos 30 días</h1>
          <DropdownOptions
            options={options}
            selectedOption={"name"}
            handleChange={handleChangeFilter}
          />
        </div>

        <div className={"most-selled-table"}>
          {items.map((item, i) => {
            return (
              <div key={i} className={"most-selled-item"}>
                <div
                  className={`${
                    i === 0
                      ? "most-selled-item-left-first"
                      : "most-selled-item-left"
                  }`}
                >
                  <div
                    className={`${
                      i === 0
                        ? "most-selled-item-name-first"
                        : "most-selled-item-name"
                    }`}
                  >
                    <span className={"most-selled-item-position"}>
                      {i + 1} -
                    </span>

                    {item.product}
                  </div>
                  <div className={"most-selled-item-bar"}>
                    <PercentageBard
                      percentage={item.selledPercentage}
                      color={i !== 0 ? "#3C7AF5" : "#62D862"}
                    />
                  </div>
                </div>
                <div className={"most-selled-item-right"}>
                  <div
                    className={`${
                      i === 0
                        ? "most-selled-item-number-first"
                        : "most-selled-item-number"
                    }`}
                  >
                    {item.selled}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={"most-selled-footer"}>
          <Link to={"/admin/products"}>
            <p>VER TODOS</p>
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default MostSelledCard;
