import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";

import { UserService } from "services/UserService";
import { BusinessService } from "services/BusinessService";
import { AuthService } from "services/AuthService";

import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";

import UserForm from "../components/UserForm";
import "./styles.scss";

const initialUser = {
  id: "",
  name: "",
  username: "",
  email: "",
  roles: [],
  image: ""
};

const initialSelectedBusinesses = {
  business: null,
  nodes: [],
  selectedNodes: []
};

const AddUserView = props => {
  const history = useHistory();
  const location = useLocation();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();

  const [user, setUser] = useState(initialUser);

  const [businesses, setBusinesses] = useState([]);
  const [selectedBusinesses, setSelectedBusiness] = useState([
    { ...initialSelectedBusinesses }
  ]);

  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    loadRoles();
    BusinessService.businessesNoPage({ no_page: 1 }).then(response => {
      let businesses = response
        .map(b => {
          return {
            ...b,
            value: b.name
          };
        })
        .sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (labelA > labelB) return 1;
          if (labelA < labelB) return -1;
          return 0;
        });
      setBusinesses(businesses);
    });
  }, []);

  const loadRoles = () => {
    UserService.roles().then(response => {
      let roles = response.results.map(r => {
        return { ...r, label: r.name, value: r.name, id: r.id };
      });
      setRoles(roles);
    });
  };

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error"
      });
    }
  };

  const handleCreate = e => {
    e.preventDefault();
    beforeSubmit();
    let _user = { ...user };
    _user.roles_id = [_user.roles.id];

    let nodes = [];
    selectedBusinesses.map(sb => {
      nodes.push(...sb.selectedNodes);
    });
    _user.nodes_id = nodes.map(node => node.code);

    UserService.createUser(_user)
      .then(response => {
        afterSubmit();
        history.push("/admin/users");
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const handleGoBack = () => {
    history.push("/admin/users");
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let _user = { ...user };
    _user[name] = value;

    setUser(_user);
  };

  const validateContinue = () => {
    return (
      user.username &&
      user.email &&
      user.roles &&
      selectedBusinesses?.length &&
      selectedBusinesses[0]?.selectedNodes?.length
    );
  };

  const handleDeleteImg = e => {
    let _user = { ...user };
    _user["image"] = null;
    setUser(_user);
  };

  const handleChangeImg = e => {
    let _user = { ...user };
    _user.image = e.target.files[0];
    setUser(_user);
  };

  const handleSelectRole = event => {
    const _role = event.target.value;
    let _user = { ...user };
    _user.roles = _role;
    setSelectedRole(_role);
    setUser(_user);
  };

  const handleChangeBusiness = (e, position) => {
    const business = e.target.value;
    let _sbs = [...selectedBusinesses];
    _sbs[position].business = business;

    const nodes = AuthService.currentUserValue()
      .account.nodes.map(n => {
        return { ...n, label: n.name, value: n.name, id: n.code };
      })
      .sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA > labelB) return 1;
        if (labelA < labelB) return -1;
        return 0;
      });

    _sbs[position].nodes = nodes.filter(n => n.business_id == business.id);
    _sbs[position].selectedNodes = [];
    setSelectedBusiness(_sbs);
  };

  const handleChangeNodes = (e, position) => {
    let _sbs = [...selectedBusinesses];

    _sbs[position].selectedNodes = e.target.value;
    setSelectedBusiness(_sbs);
  };

  const handleAddBusiness = () => {
    let _sbs = [...selectedBusinesses, { ...initialSelectedBusinesses }];
    setSelectedBusiness(_sbs);
  };

  const headerOptions = [];
  const content = (
    <div className={"add-user-content-wrapper"}>
      <div className={"add-user-inner"}>
        <UserForm
          errors={errors}
          user={user}
          roles={roles}
          selectedRole={selectedRole}
          handleSelectRole={handleSelectRole}
          handleChange={handleChange}
          handleSelectImage={handleChangeImg}
          handleDeleteImage={handleDeleteImg}
          handleSubmit={handleCreate}
          continueEnabled={validateContinue()}
          mode={"Add"}
          businesses={businesses}
          handleChangeBusiness={handleChangeBusiness}
          selectedBusinesses={selectedBusinesses}
          handleChangeNodes={handleChangeNodes}
          handleAddBusiness={handleAddBusiness}
        />
      </div>
    </div>
  );
  return (
    <div className="add-user-container">
      <AdminLayout
        headerTitle={i18n.t(`Nuevo usuario`)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default AddUserView;
