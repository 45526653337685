import React from "react";
import GenericModal from "components/MainApp/atoms/Modals/GenericModal";

import "./styles.scss";
const DeleteLocationModal = ({ open, handleClose, handleCancel, handleConfirm, locationName, locationType}) => {
    const content = <div className="delete-location-modal-content">
        <div className="delete-location-modal-title">Al eliminar, se perderán los datos cargados.</div>
        <div className="delete-location-modal-buttons">
            <div onClick={handleCancel}>QUEDARME</div>
            <div onClick={handleConfirm}>SÍ, ELIMINAR</div>
        </div>
    </div>
    return (
      <div className="delete-location-modal-container">
          <GenericModal  title={`¿Está seguro que deseas eliminar ${locationType} "${locationName}"?`}
            open={open}
            content={content}
            handleClose={handleClose}></GenericModal>
      </div>
    );
}


export default DeleteLocationModal;