import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import { BusinessNodeService } from "services/BusinessNodeService";
import { LocationService } from "services/LocationService";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";

import Panel from "components/MainApp/organisms/TogglePanel";
import LocationPanel from "components/MainApp/organisms/LocationPanel";

import "./styles.scss";
import { AuthService } from "services/AuthService";
import { handleErrors } from "helpers/utils/errors";
import DeleteBusinessNodeModal from "../DeleteBusinessNodeModal";

const BusinessNodeDetailView = props => {
  const history = useHistory();
  const [deleteErrorModal, setdeleteErrorModal] = useState(false);
  const [deleteErrorMsg, setdeleteErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [node, setnode] = useState(null);
  const [zones, setzones] = useState([]);
  const [areas, setareas] = useState([]);
  const [shelves, setshelves] = useState([]);
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);

  useEffect(() => {
    if (!props.match.params.key) return;
    const id = props.match.params.key;
    setLoading(true);
    BusinessNodeService.businessNode(id).then(node => {
      setnode(node);
      setLoading(false);
      LocationService.locationsAll({ node: id, no_parent: "true" }).then(
        locations => {
          mapLocations(locations);
        }
      );
    });
  }, []);

  const closeDeleteModal = () => {
    setdeleteModalOpen(false);
  };

  const deleteNode = () => {
    setLoading(true);
    setdeleteModalOpen(false);
    BusinessNodeService.deleteBusinessNode(node.code)
      .then(rta => {
        history.push(`/admin/businessnodes`);
        AuthService.loadUser(true);
        setLoading(false);
      })
      .catch(async error => {
        setLoading(false);
        setdeleteErrorModal(true);
        const errorMsg = await handleErrors(error);
        setdeleteErrorMsg(errorMsg);
      });
  };

  const headerOptions = [
    {
      icon: "icon-iconos_eliminar",
      name: "add",
      handler: () => {
        setdeleteModalOpen(true);
      },
      tooltip: "Eliminar Sucursal"
    },
    {
      icon: "icon-iconos_editar",
      name: "editar",
      handler: () => {
        history.push(`/admin/businessnodes/edit/${node.code}`);
      },
      tooltip: "Editar Sucursal"
    }
  ];

  const mapLocations = locations => {
    const zones = locations.filter(location => {
      return location.type === "ZONE";
    });

    const areas = locations.filter(location => {
      return location.type === "AREA";
    });

    const shelves = locations.filter(location => {
      return location.type === "SHELF";
    });

    setzones(zones);
    setareas(areas);
    setshelves(shelves);
  };

  const handleAddLocation = type => {
    history.push({
      pathname: `/admin/locations/add`,
      state: {
        type: type,
        node_code: node.code
      }
    });
  };

  const goToLocation = (locationId, type) => {
    history.push({
      pathname: `/admin/locations/detail/${locationId}`,
      state: {
        type: type,
        node_code: node.code,
        parent: null
      }
    });
  };

  const handleGoBack = () => {
    history.push(`/admin/businessnodes`);
  };

  const content = (
    <div className={"businessnode-detail-content-wrapper"}>
      {node && (
        <Panel
          fields={node && node.getDescriptionPanelView()}
          title={"Descripción"}
        />
      )}
      {node && (
        <Panel fields={node && node.getContactPanelView()} title={"Contacto"} />
      )}
      {node && (
        <Panel
          fields={node && node.getDimensionsPanelView()}
          title={"Dimensiones"}
        />
      )}
      <LocationPanel
        title={"ZONAS"}
        type={"zona"}
        items={zones}
        handleAdd={() => handleAddLocation("ZONE")}
        handleClick={goToLocation}
      />
    </div>
  );
  return (
    <div className="businessnode-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Detalle de sucursal")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      <DeleteBusinessNodeModal
        open={deleteModalOpen}
        handleClose={closeDeleteModal}
        handleCancel={closeDeleteModal}
        handleConfirm={deleteNode}
        businessNodeName={node && node.name}
      />
      <GenericErrorModal
        open={deleteErrorModal}
        handleClose={() => setdeleteErrorModal(false)}
        error={deleteErrorMsg}
      ></GenericErrorModal>

      {loading && <Loader />}
    </div>
  );
};

export default BusinessNodeDetailView;
