import "./NotificationAlert.scss";
import alertIcon from "assets/images/alert-icon.svg";

const NotificationAlert = ({ alert, expandedSidebar }) => {
  if (!alert?.viewNotification) return <></>;

  return (
    <div
      className="notification-alert"
      style={{
        width: `calc(100% - ${expandedSidebar ? "210px" : "97px"})`,
        left: expandedSidebar ? "210px" : "97px"
      }}
    >
      <div>
        <img src={alertIcon} />
        <p>Tienes <strong>1</strong> nueva alerta para procesar</p>
      </div>
      <button onClick={alert?.onClick}>Ver alerta</button>
    </div>
  );
};

export default NotificationAlert;
