import React, {useState} from "react";
// import i18n from "i18n";
import ProductAddedItem from "../ProductAddedItem";
import Pagination from "../../../../atoms/Pagination";
import "./styles.scss";


export const ProductAddedList = ({products, total, handleEdit, handleDelete, changePage, pagination, subtitle=true, title="Productos solicitados"}) => {
    const [ toggleOpen, setToggleOpen ] = useState(false);
    return(
        <div className="product-added-list-wrapper">
            <div className={`product-added-list-title`} onClick={() => setToggleOpen(!toggleOpen)}>
                <div className={`product-added-list-title-wrapper`}>
                    {title} ({total})
                </div>
            </div>
           
            { <div className="product-added-list-products">
                {products.map(product => {
                    return <div key={product.id}> <ProductAddedItem subtitle={subtitle} product={product} units={product.units} stock={product.stock} key={product.code} handleEdit={handleEdit} handleDelete={handleDelete}/> </div>
                })}
                <div className="product-added-list-pagination">
                 <Pagination pageQuantity={pagination.pageQuantity} currentPage={pagination.currentPage} pageChangeHandler={changePage} />
                </div>
            </div>}
        </div>
    )
}

export default ProductAddedList;