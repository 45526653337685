import React from 'react';
import PropTypes from 'prop-types';
import GenericFormContainer from '../../../helpers/GenericFormContainer';
import {AuthService} from '../../../services/AuthService';

import './styles.scss';
import AuthGeneric from '../../../components/Auth/layouts/AuthGeneric'


export class ConfirmMailView extends GenericFormContainer {
    componentDidMount(){
        this.handleSubmit();
    }

    resetSent = () => {
        this.setState({
            sent: false
        })
    }

    handleSubmit = () => {
        this.beforeSubmit();
        AuthService.confirm(this.props.match.params.user, this.props.match.params.key
        ).then(data => {
            this.afterSubmit();
            AuthService.loadUser().then(data => {
                this.props.history.push('/admin');
            }).catch(error => {
                this.showError('Lo lamentamos, tuvimos un error. No se pudo validar su cuenta.');
            });
        }).catch((error) => {
            this.afterSubmit();
            this.dealWithError(error, 'No pudimos validar su cuenta');
            this.setState({
                costumMessage: 'No pudimos validar su cuenta'
            })
        }
        );
    };

    render() {
        const {
            title
        } = this.props;
        const content = <div className='confirm-mail'>
            <h1>{this.state.costumMessage}</h1>
        </div>

        return (
            <div className='email-verification-container'>
                <AuthGeneric title={title} version={'header'} content={content}></AuthGeneric>
            </div>
        )
    }

}


ConfirmMailView.propTypes = {
    handleChange: PropTypes.func,
    label: PropTypes.string
};

export default ConfirmMailView;
