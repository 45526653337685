export const filterTypeMap = {
  "Estado de la orden": { name: "status", type: "dropdown" },
  "Origen de orden": { name: "node", type: "dropdown" },
  "Rango de fecha": { name: "date", type: "dateRange" },
  "Destino de orden": {
    name: "destination",
    type: "dropdown",
    default_value: undefined,
  },
  "Ordenar por": {
    name: "ordering",
    type: "twoOption",
    default_value: "-created_at",
  },
};

export const filterData = [
  {
    type: "dropdown",
    label: "Estado de la orden",
    firstOptionLabel: "Todos los estados",
    options: [
      {
        label: "Borrador",
        selected: "false",
        id: "DRA",
        type: "status",
      },
      {
        label: "Pendiente",
        selected: "false",
        id: "PEN",
        type: "status",
      },
      {
        label: "Procesando",
        selected: "false",
        id: "PRO",
        type: "status",
      },
      {
        label: "Despachada",
        selected: "false",
        id: "DISP",
        type: "status",
      },
      {
        label: "Rechazada",
        selected: "false",
        id: "REJ",
        type: "status",
      },
      {
        label: "Completada",
        selected: "false",
        id: "COM",
        type: "status",
      },
    ],
    name: "status",
  },
  {
    type: "dateRange",
    label: "Rango de fecha",
    name: "date",
  },
];
