const tableHeaders  = {
    code: {

        name: 'code',
        title: 'CODIGO',
        active: false,
        direction: false,
        value: "code",


    },
    date: {
        name: 'date',
        title: 'FECHA CREACIÓN',
        active: false,
        direction: false,
        value: "created_at"
    },
    owner: {
        name: 'owner',
      title: 'RESPONSABLE',
      active: false,
      direction: false,
      value: "owner",
    },
    state: {
        name: 'state',
        title: 'ESTADO',
        active: false,
        direction: false,
        value: "status"
    }
}



  export default tableHeaders;