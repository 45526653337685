import React, { useState } from "react";

import Step from "components/MainApp/atoms/Wizard/Step";
import ProductAddedList from "components/MainApp/organisms/Orders/RequestOrders/ProductAddedList";
import SearchWithComponents from "components/MainApp/atoms/Filters/SearchWithComponents";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import { isMobile } from "helpers/Mobile";
import TwoOptionModal from "components/MainApp/atoms/Modals/TwoOptionModal";

import "./styles.scss";
import useRequest from "hooks/useRequest";
import { ProductService } from "services/ProductService";
import Loader from "components/MainApp/atoms/Loader";
import { PublicService } from "services/PublicService";

export const AddTagStepOne = ({
  resultList,
  searchValue,
  handleChange,
  products,
  totalProducts,
  handleSelectToAddProduct,
  handleSelectedProductQuantityChange,
  handleAdd,
  handleAddProduct,
  handleDeleteAddedProduct,
  selectedProducts,
  changePage,
  pagination,
  type,
  tag,
  handleContinue,
  error,
  enableContinue,
  handleSelectAllSearchedItems,
  allSearchItemsSelected,
  handleClose,
  handlePersist,
  modalPersistOpen,
  onlyCloseModal,
  filter,
}) => {

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();

  const handleConfirm = () => {
    handlePersist();
  };

  const handleCloseIcon = () => {
    onlyCloseModal();
  }

  const mwidth = isMobile ? "100% " : "322px";
  const modalContent = "";

  const filterProducts = (value) => {
    
    return filter(value);
  }

  const stepContent = (
    <div className="add-tag-step-one-content">
      <div className="add-tag-step-one-field" data-automation-id="input-search">
        <SearchWithComponents
          onlyStockHidden={true}
          placeholder={"Busca por código o nombre"}
          resultList={resultList}
          value={searchValue}
          handleChange={handleChange}
          handleSelectToAddProduct={handleSelectToAddProduct}
          handleSelectedProductQuantityChange={
            handleSelectedProductQuantityChange
          }
          handleAdd={handleAdd}
          selectedProducts={selectedProducts}
          label={"Tags"}
          handleCheckbox={handleSelectAllSearchedItems}
          checkBoxChecked={allSearchItemsSelected}
          checkBoxLabel={"Todos los productos"}
          filterProducts={filterProducts}
        />

        {products.length > 0 && (
          <ProductAddedList
            subtitle={false}
            total={totalProducts}
            products={products}
            edit={true}
            handleEdit={handleAddProduct}
            handleDelete={handleDeleteAddedProduct}
            changePage={changePage}
            pagination={pagination}
            title={"Total cargados"}
          ></ProductAddedList>
        )}
      </div>
    </div>
  );

  const secondContent = (
    <form onSubmit={handleContinue}>
      <div className="add-tag-step-one-content">
        <div
          className="add-tag-step-one-field"
          data-automation-id="input-batch"
        >
          <MainInput
            label={"Lote"}
            id={"tag-batch"}
            required={true}
            name="batch"
            type="text"
            value={tag ? tag.batch : ""}
            handleChange={handleChange}
            maxLength={100}
            error={error.batch && error.batch}
          />
        </div>
        <div className="add-tag-step-one-field" data-automation-id="input-date">
          <MainInput
            label={"Fecha de vencimiento"}
            required={true}
            id={"tag-due_date"}
            name="due_date"
            type="date"
            value={tag && tag.due_date != "" ? tag.due_date : null}
            handleChange={handleChange}
            maxLength={100}
            error={error.due_date && error.due_date}
          />
        </div>
        {type !== "PRODUCT" && (
          <>
            <div
              className="add-tag-step-one-field"
              data-automation-id="input-sscc"
            >
              <MainInput
                label={"SSCC"}
                id={"tag-sscc"}
                name="sscc"
                type="text"
                value={tag ? tag.sscc : ""}
                handleChange={handleChange}
                maxLength={100}
                error={error.sscc && error.sscc}
              />
            </div>
            <div
              className="add-tag-step-one-field"
              data-automation-id="input-origin"
            >
              <MainInput
                label={"Lugar de origen"}
                id={"tag-origin"}
                name="origin"
                type="text"
                value={tag ? tag.origin : ""}
                handleChange={handleChange}
                maxLength={100}
                error={error.origin && error.origin}
              />
            </div>
            <div
              className="add-tag-step-one-field"
              data-automation-id="input-destination"
            >
              <MainInput
                label={"Lugar de destino"}
                id={"tag-destination"}
                name="destination"
                type="text"
                value={tag ? tag.destination : ""}
                handleChange={handleChange}
                maxLength={100}
                error={error.destination && error.destination}
              />
            </div>
          </>
        )}
        <div className={"add-tag-step-one-action-wrapper"}>
          <button
            type="submit"
            className={`add-tag-step-one-action-button ${!enableContinue &&
              "disabled"}`}
            onClick={enableContinue ? handleContinue : undefined}
            disabled={!enableContinue}
          >
            GENERAR TAGS
          </button>
        </div>
      </div>
    </form>
  );
  return (
    <div className="add-tag-step-one-container">
      <Step
        title="¿De qué productos desea imprimir tags?"
        content={stepContent}
      ></Step>
      <Step content={secondContent}></Step>
      <TwoOptionModal
        open={modalPersistOpen}
        title={"Interrumpir impresion de Tags"}
        subtitle={
          <span style={{ fontFamily: "Ubuntu-Medium", color: "#676F8F" }}>
            ¿Desea salir y guardar el progreso?
          </span>
        }
        closeLabel={"SALIR SIN GUARDAR"}
        confirmLabel={"GUARDAR"}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        modalContent={modalContent}
        confirmDisabled={false}
        closeable={true}
        handleCloseIcon={handleCloseIcon}
      ></TwoOptionModal>
      {loading && <Loader />}
    </div>
  );
};

export default AddTagStepOne;
