import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";

import { BusinessNodeService } from "services/BusinessNodeService";
import { LocationService } from "services/LocationService";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";

import AddLocationForm from "./AddLocationForm";
import "./styles.scss";

const initialLocation = {
  name: "",
  code: "",
  measure_height: "",
  measure_width: "",
  measure_depth: "",
  capacity: "",
  levels: [],
  places: []
};

const initialError = {
  success: true,
  message: "",
  errors: []
};

const locationTypeTitleMapping = {
  ZONE: "Nueva zona",
  AREA: "Nuevo sector",
  SHELF: "Nueva estanteria",
  LEVEL: "Nuevo nivel",
  PLACE: "Nueva ubicacion"
};

const subtitleMap = {
  ZONE: "La zona se creara en la Sucursal",
  AREA: "El sector se creara en la Sucursal",
  SHELF: "La estantería se creara en la Sucursal",
  LEVEL: "El nivel se creara en la Sucursal",
  PLACE: "La ubicacion se creara en la Sucursal"
};

const AddLocationView = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [location, setlocation] = useState(initialLocation);

  const [nodeId, setnodeId] = useState(null);
  const [type, settype] = useState(null);
  const [node, setnode] = useState(null);
  const [parentId, setparentId] = useState(null);
  const [error, seterror] = useState(initialError);

  useEffect(() => {
    if (props.match.params.key) {
      setLoading(true);
      const id = props.match.params.key;
      LocationService.location(id).then(response => {
        setLoading(false);
        response.measure_depth = response.measure_depth / 1000;
        response.measure_height = response.measure_height / 1000;
        response.measure_width = response.measure_width / 1000;
        setlocation(response);
      });
    }else{
      let auxLocation = location;
      auxLocation.levels = [];
      auxLocation.places = [];
      setlocation(auxLocation);
    }

    if (!props.location.state.node_code) return;
    if (!props.location.state.type) return;

    const id = props.location.state.node_code;
    setnodeId(id);

    const type = props.location.state.type;
    settype(type);

    const parent = props.location.state.parent;
    setparentId(parent);

    BusinessNodeService.businessNode(id).then(node => {
      setnode(node);
    });
  }, []);

  const isNumber = (str) => {
    return /^\d+$/.test(str);
  }

  const showError = (message, errors = {}) => {
    seterror({
      success: false,
      message: message,
      errors: errors
    });
  };

  const dealWithError = (
    response,
    codeMsgError = "generic.error",
    callback = null
  ) => {
    if ([400, 401, 409].includes(response.status)) {
      response.json().then(data => {
        if (Array.isArray(data)) {
          showError(data.join(", "));
        } else if ("detail" in data) {
          showError(data["detail"]);
        } else if ("message" in data && "errors" in data) {
          showError(data["message"], data["errors"]);
        } else {
          showError(codeMsgError, data);
        }
        if (callback) callback();
      });
    } else {
      showError(codeMsgError);
      if (callback) callback();
    }
  };

  const getMeasure = (measure) => {
    if(isNumber(measure)){
      return measure * 1000;
    }
    return measure;
  }

  const handleCreate = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = { ...location };

    data.type = type;
    data.node = node.code;
    data.parent = parentId;
    data.measure_width = getMeasure(data.measure_width);
    data.measure_depth = getMeasure(data.measure_depth);
    data.measure_height = getMeasure(data.measure_height);
    if(data.capacity == ''){
      data.capacity = null;
    }
    data.levels = [];
    data.places = [];

    if (props.match.params.key) {
      LocationService.updateLocation(props.match.params.key, data)
        .then(response => {
          history.push({
            pathname: `/admin/locations/detail/${props.match.params.key}`,
            state: {
              type: location.type,
              node_code: nodeId
            }
          });
        })
        .catch(error => {
          dealWithError(error);
          setLoading(false);
        });
    } else {
      LocationService.createLocation(data)
        .then(rta => {
          setlocation(false);
          if (parentId) {
            history.push({
              pathname: `/admin/locations/detail/${parentId}`,
              state: {
                type: location.type,
                node_code: nodeId
              }
            });
          } else {
            history.push(`/admin/businessnodes/detail/${nodeId}`);
          }
        })
        .catch(error => {
          dealWithError(error);
          setLoading(false);
        });
    }
  };

  const handleGoBack = () => {
    if (parentId) {
      history.push({
        pathname: `/admin/locations/detail/${parentId}`,
        state: {
          type: location.type,
          node_code: nodeId
        }
      });
    } else {
      history.push(`/admin/businessnodes/detail/${node.code}`);
    }
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let locationAux = { ...location };
    locationAux[name] = value;

    setlocation(locationAux);
  };

  const validateContinue = () => {
    return (
      location &&
      location.name !== "" &&
      location.code !== "" &&
      location.measure_height !== "" &&
      location.measure_width !== "" &&
      location.measure_depth !== "" &&
      location.name !== null &&
      location.code !== null &&
      location.measure_height !== null &&
      location.measure_width !== null &&
      location.measure_depth !== null
    );
  };

  const headerOptions = [];
  const content = (
    <div className={"add-location-content-wrapper"}>
      <div className={"add-location-inner"}>
        <h1 className={"add-location-title"}>
          {" "}
          {subtitleMap[type]} “{node && node.name}”
        </h1>
        <form onSubmit={handleCreate}>
          <AddLocationForm
            location={location}
            handleChange={handleChange}
            error={error.errors}
          />
          <div className={"add-location-button-wrapper"}>
            <button
              type="submit"
              className={`add-location-button ${!validateContinue() ? "disabled" : ""}`}
              onClick={handleCreate}
            >
              {location.id ? "MODIFICAR" : "CREAR"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  return (
    <div className="add-location-container">
      <AdminLayout
        headerTitle={i18n.t(`${locationTypeTitleMapping[type]}`)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default AddLocationView;
