import React from "react";
import Icon from "components/MainApp/atoms/Icon/icon";
import "./styles.scss";

const LocationSummary = ({ item, handleDelete }) => {
  return (
    <div className="location-summary-container">
      <div className="location-summary-left">
        <div className="path">{item.title}</div>
        {item.dispatch_area && (
          <div className="items">Crear orden de salida</div>
        )}
        <div className="items">Productos({item.items.length})</div>
      </div>
      <div className="location-summary-right">
        {handleDelete && (
          <div onClick={() => handleDelete(item)}>
            <Icon name="trash_can" width={20} height={20} fill={"#3C7AF5"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationSummary;
