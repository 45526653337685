import React from "react";
import Card from "../Card"
import LineChart from "../LineChart";
import "./styles.scss";

const MonthlySalesCard = ({ series, xCategories }) => {
  return (
    <div className={"monthly-sales-card-container"}>
      <Card>
        <div className={"title-container"}>
          <h1 className={"dashboard-card-title"}>Ventas mensuales</h1>
        </div>

        <div className={"most-selled-table"}>
          <LineChart series={series} xCategories={xCategories}/>
        </div>
      </Card>
    </div>
  );
};

export default MonthlySalesCard;
