import Environment from "../environment";
import Api from './ApiService';

import { Category, Brand } from "../models/Attributes";
import { BusinessNode } from "../models/BusinessNode";
import { Item, Product, Container } from "../models/Items";

export const PublicService = {
	categories: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/categories/`, 'GET', params)
                .then(rta => {
                	rta = rta.map(item => new Category(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    brands: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/brands/`, 'GET', params)
                .then(rta => {
                	rta = rta.map(item => new Brand(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    nodes: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/nodes/`, 'GET', params)
                .then(rta => {
                	rta = rta.map(item => new BusinessNode(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    // Items name
    itemsName: (params) => {
        return Api.fetch(`${Environment.api}api/search/items/`, 'GET', params);
    },

    items: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/items/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Item(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    products: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/products/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Product(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    products_plain: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/products-plain/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Product(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    containers: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/containers/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Container(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    countries: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/countries/`, 'GET', params)
                .then(rta => {
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    regions: (country) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/countries/${country}/regions/`, 'GET')
                .then(rta => {
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    states: (country, region) => {
    if (!region) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/countries/${country}/regions/${region}/states/`, 'GET')
                .then(rta => {
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    cities: (country, region, state) => {
      if (!region || !state) {
        return new Promise((resolve) => {
          resolve([]);
        });
      }
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/public/countries/${country}/regions/${region}/states/${state}/cities/`, 'GET')
                .then(rta => {
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

}
