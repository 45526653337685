import React from "react";
import DragAndDropImages from "../../../../atoms/Forms/DragAndDropImages";

import "./styles.scss";

export class FormEditProduct extends React.Component {
  render() {
    const {product, handleChange, handleDelete, onHandleStarClick, selectedStarIndex, error } = this.props;

    return (
      <div
        className={`form-edit-image-container ${!product.enabled ? 'disabled' : '' }`}
        data-automation-id="form-edit-image-container"
      >
        <div className="form-edit-image-title">
          Imagen
        </div>
        <DragAndDropImages
          name="image"
          handleChange={handleChange}
          handleDelete={handleDelete}
          files={product.item_files}
          error={error.image}
          onHandleStarClick={onHandleStarClick}
          selectedStarIndex={selectedStarIndex}
          msgInfoFiles={"Video o imagen seleccionada"}
          showSelectorFiles={true}
        />
        {!product.enabled && <div className="form-edit-image-overlay"></div>}
      </div>
    );
  }
}

export default FormEditProduct;
