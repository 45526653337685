import Environment from "../environment"; 
import Api from './ApiService';
import { AuthService } from './AuthService.js';

import { Container } from "../models/Items";


export const ContainerService = {
	containers: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/containers/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Container(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    containersByNode: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/containers/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Container(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    container: (id) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/containers/${id}/`, 'GET')
                .then(rta => {
                	rta.results = new Container(rta)
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    productByNode: (id) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/containers/${id}/`, 'GET')
                .then(rta => {
                	rta.results = new Container(rta)
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    createContainer: (data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/containers/`, 'POST', data
                ).then(data => {
                    resolve(new Container(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    createContainerFormData: (formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/containers/`, 'POST', formData
                ).then(data => {
                    resolve(new Container(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateContainer: (id, data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/containers/${id}/`, 'PATCH', data
                ).then(data => {
                    resolve(new Container(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateContainerFormData: (id, formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/containers/${id}/`, 'PATCH', formData
                ).then(data => {
                    resolve(new Container(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    uploadContainers: (formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/containers/upload/`, 'POST', formData
                ).then(data => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    downloadContainers: (params) => {
        return new Promise((resolve, reject) => {
            return Api.fetchDownload(`${Environment.api}api/containers/download/`, 'GET', params
                ).then(blob => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    downloadTemplateContainers: (params) => {
        return new Promise((resolve, reject) => {
            return Api.fetchDownload(`${Environment.api}api/containers/download_template/`, 'GET', params
                ).then(blob => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    // Containers name
    containersName: (params) => {
        return Api.fetch(`${Environment.api}api/search/containers/`, 'GET', params);
    },
}
