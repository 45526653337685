import React, { useState } from "react";
import { Tooltip } from "react-tippy";
import "./styles.scss";

const tooltipStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
};

const mapping = {
  DRA: "Borrador",
  PEN: "Pendiente",
  PRO: "En proceso",
  SUP: "Supervisar",
  DISP: "Despachada",
  REJ: "Rechazada",
  COM: "Completada",
};

export const OrderStatePill = ({ state, big }) => {
  return (
    <div className="order-state-pill-container">
      {state && (
        <div
          className={`order-state-pill-inner ${state.toLowerCase()} ${
            big ? "big" : ""
          }`}
          key={state}
        >
          {" "}
          <p>{mapping[state]}</p>
        </div>
      )}
    </div>
  );
};

export default OrderStatePill;
