import { useState } from "react";

export const useFilters = (
  initialFilters,
  productFilters,
  filterTypeMap,
  isMobile,
  headers
) => {
  const [filters, setFilters] = useState(
    productFilters && productFilters.filters
      ? productFilters.filters
      : initialFilters
  );
  const [selectedFiltersAll, setSelectedFiltersAll] = useState(
    productFilters && productFilters.selectedFiltersAll
      ? productFilters.selectedFiltersAll
      : []
  );
  const [selectedFiltersByType, setSelectedFiltersByType] = useState(
    productFilters && productFilters.selectedFiltersByType
      ? productFilters.selectedFiltersByType
      : []
  );
  const [applied, setApplied] = useState(false);
  const [page, setPage] = useState(1);
  const [pager, setPager] = useState({
    next: null,
    previous: null,
    count: 0,
    countPages: 0,
  });
  const [header, setHeader] = useState(headers);
  const [mobilePanelOpen, setMobilePanelOpen] = useState(false);

  const [items, setItems] = useState([]);

  const handleSearch = function(value) {
    const newFilters = { ...filters };
    newFilters["search"] = value;

    if (isMobile) {
      setItems([]);
    }
    setPage(1);
    setFilters(newFilters);
  };

  const handleApplyFilters = (filtersByType, selectedFiltersAll) => {
    let newFilters = { ...filters };

    for (const type in filterTypeMap) {
      if (filterTypeMap[type].type === "dropdown") {
        newFilters = applyMultipleFilter(
          type,
          filtersByType,
          filterTypeMap,
          newFilters
        );
      }
      if (filterTypeMap[type].type === "twoOption") {
        newFilters = applyTwoOptionFilter(
          type,
          filtersByType,
          filterTypeMap,
          newFilters
        );
      }
      if (filterTypeMap[type].type === "dateRange") {
        newFilters[filterTypeMap[type].name] = filtersByType[type] && filtersByType[type][0]
          ? filtersByType[type][0].selectedDates
          : undefined;
      }
    }

    if (
      !newFilters.enabled &&
      !newFilters.brand &&
      !newFilters.stock &&
      !newFilters.categories
    ) {
      setApplied(false);
    } else {
      setApplied(true);
    }

    if (isMobile) {
      setItems([]);
    }
    setPage(1);
    setFilters(newFilters);
    setSelectedFiltersAll(selectedFiltersAll);
    setSelectedFiltersByType(filtersByType);
    setMobilePanelOpen(false);
  };

  const applyMultipleFilter = (
    type,
    filtersByType,
    typeMapping,
    newFilters
  ) => {
    const mappedType = typeMapping[type].name;

    if (filtersByType[type]) {
      let filterByType = filtersByType[type].map((f) => {
        return f.id;
      });
      newFilters[mappedType] = filterByType;
    } else {
      newFilters[mappedType] = undefined;
    }

    return newFilters;
  };

  const applyTwoOptionFilter = (
    type,
    filtersByType,
    typeMapping,
    newFilters
  ) => {
    let ft = filtersByType[type];
    const mappedType = typeMapping[type].name;

    if (ft && ft[0] && ft.length !== 2) {
      newFilters[mappedType] = ft[0].value;
    } else {
      newFilters[mappedType] = typeMapping[type].default_value;
    }

    return newFilters;
  };

  const headerOrderingHandler = function(key) {
    const newHeaders = { ...header };
    if (newHeaders[key]["active"]) {
      newHeaders[key]["direction"] = !newHeaders[key]["direction"];
    } else {
      Object.keys(newHeaders).forEach((k) => {
        newHeaders[k]["active"] = false;
        newHeaders[k]["direction"] = false;
      });
      newHeaders[key]["active"] = true;
    }
    const ordering = newHeaders[key]["direction"]
      ? newHeaders[key]["value"]
      : `-${newHeaders[key]["value"]}`;

    const newFilters = { ...filters };
    newFilters["ordering"] = ordering;
    setPage(1);
    setFilters(newFilters);
    setHeader(newHeaders);
  };

  const resetOrdering = function() {
    const newHeaders = { ...headers };
    Object.keys(newHeaders).forEach((k) => {
      newHeaders[k]["active"] = false;
      newHeaders[k]["direction"] = false;
    });
    setHeader(newHeaders);
  };

  const handleFiltersChangeOrdering = function(name, value) {
    const newFilters = { ...filters };
    newFilters[name] = value;

    if (isMobile) {
      setItems([]);
    }
    setPage(1);
    setFilters(newFilters);
  };

  return [
    filters,
    setFilters,
    selectedFiltersAll,
    selectedFiltersByType,
    handleApplyFilters,
    handleSearch,
    headerOrderingHandler,
    resetOrdering,
    handleFiltersChangeOrdering,
    applied,
    page,
    setPage,
    items,
    setItems,
    pager,
    setPager,
    header,
    setHeader,
    mobilePanelOpen,
    setMobilePanelOpen,
  ];
};

export default useFilters;
