import React from "react";
import GenericFormContainer from "../../../../helpers/GenericFormContainer";
import AdminLayout from "../../../../components/MainApp/layouts/DesktopLayout";
import MainInput from "../../../../components/MainApp/atoms/Forms/MainInput";
import MainButton from "../../../../components/MainApp/atoms/RoundedButton";

// import Loader from "../../../components/MainApp/atoms/loader";

import "./styles.scss";
import { CategoryService } from "../../../../services/CategoryService";

export class AddCategoryView extends GenericFormContainer {
  headerOptions = [];

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      goBackConfirm: false,

      category: {
        name: "",
        enabled: true
      }
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.product) {
      this.setState({
        product: { ...this.props.location.state.product }
      });
    }
  }

  goBack() {
    const { product, firstLoad } = this.props.location.state;
    let path = "";

    if (firstLoad) {
      path = `/admin/products/${
        product.id ? `details/${product.id}/` : "load-single-product"
      }`;
    } else {
      path = "/admin/categories";
    }
    this.props.history.push({
      pathname: path,
      state: {
        product: this.state.product
      }
    });
  }

  handleChange = e => {
    let name = e.target.name;
    let category = { ...this.state.category };
    category[name] = e.target.value;
    this.setState({
      category: category
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      loading: true
    });

    this.beforeSubmit();
    CategoryService.createCategory(this.state.category)
      .then((category) => {        
        this.afterSubmit();
        this.showSuccess("Categoria creada");
        if (!this.state.product) {
          this.props.history.push("/admin/categories");
        } else {
          let product = { ...this.state.product };
          product.category = category;
          this.props.history.push({
            pathname:
              "/admin/products/" +
              (product.id ? `details/${product.id}/` : "load-single-product"),
            state: {
              product: product
            }
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        this.afterSubmit();
        this.dealWithError(error, "Error creando la categoria");
      });
  };

  render() {
    const content = (
      <div className="add-category-wrapper">
        <div className="add-category-content">
          {(this.props.location.state && this.props.location.state.firstCategory) && (
            <>
              <i className="icon-iconos_categorìas add-category-icon"></i>
              <p className="add-category-subtitle"> Aún no tienes categorías creadas. ¡Crea tu primer categoría! </p>
            </>
          )}
          <form onSubmit={this.handleSubmit}>
            <div className="add-category-input">
              <MainInput
                label="Categoría"
                type="text"
                name="name"
                value={this.state.category.name}
                handleChange={this.handleChange}
                error={this.state.errors.name}
                maxLength={50}
              ></MainInput>
              {!this.state.errors.name && (
                <p className="add-category-input-help">Máximo 50 caracteres</p>
              )}
            </div>
            <div className="add-category-button">
              <MainButton
                state="enabled"
                legend="CREAR CATEGORÍA"
                type="submit"
              ></MainButton>
            </div>
          </form>
        </div>
      </div>
    );
    return (
      <div className="add-category-container">
        <AdminLayout
          headerTitle={"Categorías"}
          headerOptions={this.headerOptions}
          content={content}
          navHidden={true}
          goBackFunc={this.goBack.bind(this)}
        ></AdminLayout>
      </div>
    );
  }
}

export default AddCategoryView;
