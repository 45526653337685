import React, {useState} from "react";
import {isMobile} from "../../../../../../../helpers/Mobile"
import "./styles.scss";


export const OrderDetailDescriptionPanel = (props) => {
    const [ toggleOpen, setToggleOpen ] = useState(false)
 
    if (!props.order){
        return <div></div>;
    }
    return (
        <div className="order-detail-description-panel-container">

            <div className={`order-detail-description-panel-title`} onClick={() => setToggleOpen(!toggleOpen)}>
            Descripción
                {(isMobile) &&
                    <i name="icon-chevron" className={`icon-chevron order-detail-description-panel-chevron ${toggleOpen ? "open" : ""} `}></i>
                }
            </div>

                {(!isMobile || toggleOpen) && <React.Fragment>
                    {props.order.map( item => {
                        return item.value && (<div className="order-detail-description-panel-section" key={item.title}>
                                    <div className="order-detail-description-panel-section-title">{item.title}</div>
                                    <div className="order-detail-description-panel-section-content">{item.value}</div>
                                </div>)
                    })}
                </React.Fragment>}
            
        </div>
    );
  
}

export default OrderDetailDescriptionPanel;