import React from "react";
import "./styles.scss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const getOptions = (series, xCategories) => {
  return {
    chart: {
        type: 'line',
        height: '250px'
    },
    title: {
        text: null
    },
    yAxis: {
        title: {
            text:  null
        }
    },
    xAxis: {
        categories: xCategories
    },
    legend: {
        enabled: false,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
    },

    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            },
        }
    },

    series: series,

    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }

};
};
const LineChart = ({
  series = [{
    name: 'Ventas',
    data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
  }],
  xCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
}) => {
  return (
    <div style={{position: 'relative', height: '100%'}}>
          <HighchartsReact
            highcharts={Highcharts}
            options={getOptions(series, xCategories)}
          />
        </div>
  );
};

export default LineChart;
