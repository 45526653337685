import React from 'react';
// import imageFile from '../../../../assets/images/fondo.png';
import imageFile from '../../../../assets/images/Pattern-yax.png';

import './styles.scss';

export class AuthDesktop extends React.Component {
    
    render() {
        const {
            content
        } = this.props;
        return (
            <div className="auth-desktop-container">
                <div className="img-container">
                    <img className="image" src={imageFile} alt="fondo"/>
                </div>
                <div className="content-container">
                    {content}
                </div>
            </div>
        )
    }

}


AuthDesktop.propTypes = {
    
};

export default AuthDesktop;