import Environment from "../environment"; 
import Api from './ApiService';

import { BusinessNode } from "../models/BusinessNode";


export const BusinessNodeService = {
	businessNodes: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/`, 'GET', params)
                .then(rta => {
                	rta = rta.map(item => new BusinessNode(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
	},

    businessNodesPaginated: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new BusinessNode(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },
    
    businessNode: (id) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${id}/`, 'GET')
                .then(rta => {
                	rta = new BusinessNode(rta)
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    createBusinessNode: (data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/`, 'POST', data
                ).then(data => {
                    resolve(new BusinessNode(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateBusinessNode: (id, data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${id}/`, 'PATCH', data
                ).then(data => {
                    resolve(new BusinessNode(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    deleteBusinessNode: (id) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${id}/`, 'DELETE'
                ).then(data => {
                    resolve();
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },
}
