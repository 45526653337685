import Environment from "../environment";
import Api from "./ApiService";

export const GuardStatusService = {

  getAlarmGuardStatusById: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/guard_status/${id}`,
        "GET"
      )
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

    getAlarmsGuardStatus: (params) => {
        return new Promise((resolve, reject) => {
          return Api.fetch(
            `${Environment.api}api/guard_status/?`,
            "GET",
            params
          )
            .then((rta) => {
              resolve(rta);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },

      updateGuardStatus: (id, data) => {
        return new Promise((resolve, reject) => {
          return Api.fetch(
            `${Environment.api}api/guard_status/${id}/`,
            "PATCH", data
          )
            .then((rta) => {
              resolve(rta);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
}