import React, { useState } from "react";
import Step from "components/MainApp/atoms/Wizard/Step";
import Icon from "components/MainApp/atoms/Icon/icon";
import ProductSummaryItem from "components/MainApp/organisms/Orders/RequestOrders/ProductSummaryItem";
import { isMobile } from 'helpers/Mobile';
import TwoOptionModal from "components/MainApp/atoms/Modals/TwoOptionModal";

import "./styles.scss";
export const AddConfigTagStepTwo = ({tag,
  addedProducts,
  handleEdit,
  handleContinue,
  enableContinue,
  error,
  handleClose, 
  handlePersist, 
  modalPersistOpen,
  onlyCloseModal,
  handleFinishStepTwo}
) =>
  {

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const handleConfirm = () => {
        let data = '';
        handlePersist()
    }
    const handleCloseIntern = () => {
        handleClose();
    }

    const handleCloseIcon = () => {
      onlyCloseModal();
    }

    const handleFinish = () => {
      setOpenConfirmModal(true);
    }

    const handleConfirmModal = () => {
      handleFinishStepTwo();
    }

    const handleCloseIconConfirm = () => {
      setOpenConfirmModal(false);
    }

    const mwidth = isMobile ? '100% ': '322px';
    const modalContent = '';

    const stepContent = (
      <div className="add-tag-wizard-step-two-content">
        <div className="info-group">
          <div className="config-tag-confirmation-header">
            <h2 className="config-tag-confirmation-title">Lote</h2>
              <div onClick={handleEdit}>
              <Icon name="edit2" height={20} width={20} fill={"#3C7AF5"} />
              </div>
          </div>
          <div className="value">{tag.batch}</div>
        </div>

        <div className="info-group">
          <div className="config-tag-confirmation-header">
            <h2 className="config-tag-confirmation-title">
              Fecha de vencimiento
            </h2>
          </div>
          <div className="value">{tag.due_date}</div>
        </div>

        <div className="info-group">
          <div className="config-tag-confirmation-header">
            <h2 className="config-tag-confirmation-title">
              TAGS (
              {addedProducts.reduce(function(a, prod) {
                return a + (prod.quantity || prod.units);
              }, 0)}
              )
            </h2>
            <div onClick={handleEdit}>
              <Icon name="edit2" height={20} width={20} fill={"#3C7AF5"} />
            </div>
          </div>

          {addedProducts.map((item, i) => {
            return (
              <div key={i} className={`item-tags-group`}>
                <ProductSummaryItem
                  key={item.name}
                  product={item}
                  units={item.units}
                />
                <div className={`tags-quantity`}>{item.tags.length} tags</div>
                <div className={`add-tag-wizard-step-two-generated-tags`}> 
                {item.tags.map((tag, i) => {
                  return <div className="tag" key={i}>Codigo {tag.rfid}</div>
                })}
                </div>
              </div>
            );
          })}
        </div>

        <div className={"add-tag-wizard-step-two-action-wrapper"}>
        <button
            className={`add-tag-wizard-step-two-action-button ${!enableContinue &&
              "disabled"} finish`}
            onClick={handleFinish}
          >
            FINALIZAR
          </button>

          <button
            type="submit"
            className={`add-tag-wizard-step-two-action-button ${!enableContinue &&
              "disabled"}`}
            onClick={enableContinue ? handleContinue : undefined}
          >
            CONTINUAR
          </button>
          
        </div>
      </div>
    );

    

    return (
      <div className="add-tag-wizard-step-two-container">
        <Step title="Verifica los datos" content={stepContent}></Step>
        <TwoOptionModal open={modalPersistOpen} 
            title={'Interrumpir impresion de Tags'}
            subtitle={<span style={{fontFamily: 'Ubuntu-Medium', color: '#676F8F'}}>¿Desea salir y guardar el progreso?</span>}
            closeLabel={'SALIR SIN GUARDAR'}
            confirmLabel={'GUARDAR'}
            handleClose={handleCloseIntern}
            handleConfirm={handleConfirm}
            modalContent={modalContent}
            confirmDisabled={false}
            closeable={true}
            handleCloseIcon = {handleCloseIcon}
        ></TwoOptionModal>

        <TwoOptionModal open={openConfirmModal} 
            title={'Finalizar proceso'}
            subtitle={<span style={{fontFamily: 'Ubuntu-Medium', color: '#676F8F'}}>¿Desea finalizar el proceso? No se imprimirán los tags generados</span>}
            closeLabel={'CANCELAR'}
            handleClose={handleCloseIconConfirm}
            confirmLabel={'CONFIRMAR'}
            handleConfirm={handleConfirmModal}
            modalContent={modalContent}
            confirmDisabled={false}
          />

      </div>
    );
  }

export default AddConfigTagStepTwo;
