import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";

import { BusinessNodeService } from "services/BusinessNodeService";

import useRequest from "hooks/useRequest";
import { isMobile } from "helpers/Mobile";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";

import MainNotification from "components/MainApp/atoms/Notification";
import GenericTable from "components/MainApp/organisms/GenericTable";
import Pagination from "components/MainApp/atoms/Pagination";

import columns from "./columns";

import NodeImg from "./storefront-24px.svg";
import "./styles.scss";
import {PublicService} from "../../../../services/PublicService";
import { NotFoundItems } from "views/Admin/components/NotFoundItems";

let scrolling = false;

export const BusinessNodeListView = () => {
  const history = useHistory();
  const [noNodes, setnoNodes] = useState(false);
  const [nodes, setnodes] = useState([]);
  const [nodesTable, setnodesTable] = useState([]);
  const [isSearched, setSearched] = useState(false);

  const isFirstRun = useRef(true);
  const [pager, setPager] = useState({
    next: null,
    previous: null,
    count: 0,
    countPages: 0,
  });
  const [page, setPage] = useState(1);
  let pageSize = 10;

  const { loading, beforeSubmit, afterSubmit } = useRequest();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  useEffect(() => {
    let firstRun = false;
    if (isFirstRun.current) {
      firstRun = true;
      isFirstRun.current = false;
    }

    filter({ page: page, page_size: pageSize }, firstRun);
  }, [page]);

  const goBack = () => {
    history.push("/admin/more-options/1");
  };

  const filter = (params, first = false) => {
    beforeSubmit();
    BusinessNodeService.businessNodesPaginated(params)
      .then((response) => {
        afterSubmit();

        let newNodes =
          isMobile && params.page !== 1
            ? [...nodes, ...response.results]
            : response.results;

        if (first && newNodes.length < 1) setnoNodes(true);

        const _ = require('lodash');

        let countriesPromise = _.memoize(() => PublicService.countries());
        let regionsPromise = _.memoize((country) => PublicService.regions(country));
        let statesPromise = _.memoize((country, region) => PublicService.states(country, region));
        let citiesPromise = _.memoize((country, region, state) => PublicService.cities(country, region, state));

        let nodesLocationDataPromises = [];
        newNodes = newNodes.map((node) => {
          nodesLocationDataPromises.push(
            new Promise((resolve, reject) => {
              Promise.all([
                countriesPromise(),
                regionsPromise(node.country),
                statesPromise(node.country, node.region),
                citiesPromise(node.country, node.region, node.state),
              ]).then((responses) => {
                node.country = responses[0].find((c) => c.value === node.country);
                node.locality = responses[3].find((c) => c.value === node.locality);
                node.region = responses[1].find((c) => c.value === node.region);
                node.state = responses[2].find((c) => c.value === node.state);
                resolve(node);
              });
            })
          );
        });
        Promise.all(nodesLocationDataPromises).then((processedNodes) => {
          setnodes(processedNodes);
          const table = processedNodes.map((provider) => provider.getGenericTableView());
          setnodesTable(table);
          setPager({
            next: response.next,
            previous: response.previous,
            count: response.count,
            countPages: Math.ceil(response.count / pageSize),
          });
          scrolling = false;
          setSearched(true);
        });
      })
      .catch((_) => {
        afterSubmit();
        scrolling = false;
        setSearched(true);
      });
  };

  const handleScroll = (event) => {
    if (!isMobile) return;

    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    )
      return;

    if (!pager.next || loading || scrolling) return;
    scrolling = true;

    setPage(page + 1);
  };

  const handlePagerChange = function(page) {
    setPage(page);
  };

  const handleSearch = (value) => {
    setSearched(false);
    filter({ page: 1, page_size: pageSize, search: value });
  };

  const createBusinessNode = () => {
    history.push(`/admin/businessnodes/add`);
  };

  const goToNode = (code) => {
    history.push(`/admin/businessnodes/detail/${code}`);
  };

  const headerOptions = [
    {
      icon: "icon-iconos_mas2",
      name: "add",
      handler: createBusinessNode,
      tooltip: "Agregar sucursales",
    },
  ];

  const tableContent = (
    <div className={`businessnode-list-content`}>
      {!isMobile && <SimpleSearchPanel placeholder="Buscar" handleSubmit={handleSearch} />}

      {nodes.length > 0 && (
        <div className={`businessnode-list-table`}>
          <GenericTable
            items={nodesTable}
            totalItems={pager.count}
            columns={columns}
            handleClick={goToNode}
          />
          {!isMobile && (
            <div className="table-pagination">
              <Pagination
                pageQuantity={pager.countPages}
                currentPage={page}
                pageChangeHandler={handlePagerChange}
              />
            </div>
          )}
        </div>
      )}

      {nodes.length === 0 && isSearched && (
        <NotFoundItems />
      )}
    </div>
  );

  const nonodescontent = (
    <div className="businessnode-no-nodes-content">
      <img src={NodeImg} />
      <div className="businessnode-no-nodes-title">
        Aún no tienes sucursales creadas. ¡Crea tu primer sucursal!
      </div>
      <div className="businessnode-create-button" onClick={createBusinessNode}>
        CREAR
      </div>
    </div>
  );
  const productsElement = noNodes ? nonodescontent : tableContent;
  // const productsElement =  nonodescontent;

  const content = <div className="outter-wrapper">
      {isMobile && <SimpleSearchPanel placeholder="Buscar" handleSubmit={handleSearch} />}

      <div className="table-nodes-wrapper">{productsElement}</div>

  </div>

  return (
    <div className="businessnode-list-container">
      <AdminLayout
        headerTitle={i18n.t("Sucursales")}
        headerOptions={noNodes ? [] : headerOptions}
        content={content}
        goBackFunc={goBack}
        navHidden={false}
      ></AdminLayout>
      {loading && <Loader />}
      <MainNotification
        label={"Tu producto se actualizó de manera exitosa."}
        open={false}
      ></MainNotification>
    </div>
  );
};

export default BusinessNodeListView;
