import React from "react";
import LoadFinishedGeneral from "components/MainApp/organisms/LoadFinishedGeneral"
import CredentialBoxes from "../CredentialsBoxes";

import "./styles.scss";

 const AppAddSuccess = ({app, boldButtonFunc}) => {
    return (
      <LoadFinishedGeneral
        title={`Aplicación registrada`}
        boldButtonLabel={"REGISTRAR NUEVA APLICACIÓN"}
        buttonLabel={'VER LISTADO DE APLICACIONES'}
        boldButtonLink={"/admin/applications/add"}
        buttonLink={`/admin/applications`}
        boldButtonFunc={boldButtonFunc}
      > 
        <div className="app-add-success-container">
          <div className="subtitle">La aplicación se ha registrado exitosamente.</div>
          <div className="credentials-title">Credenciales de producción</div>
          <CredentialBoxes app={app}></CredentialBoxes>
        </div>
      </LoadFinishedGeneral>
    )
  
}

export default AppAddSuccess;