import React from "react";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import DragAndDropImage from "components/MainApp/atoms/Forms/DragAndDropImage";
import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";
import Toggle from "components/MainApp/atoms/Forms/Toggle";
import Icon from "components/MainApp/atoms/Icon/icon";
import permisos from "./permisos";
import CredentialBoxes from "../CredentialsBoxes";

import "./styles.scss";

const AppEdit = ({
  app,
  handleChange,
  errors,
  handleChangeImage,
  handleDeleteImage,
  handleSelect,
  handleChangeEnabled,
}) => {
  console.log(app);

  return (
    <div className="app-edit-container">
      <div className="left">
        <div className="group">
          <div className="title">Ingresa los datos</div>
          <div className="inputs">
            <MainInput
              label={"Nombre de la App"}
              required={true}
              id={"app-name"}
              name="name"
              value={app.name}
              handleChange={handleChange}
              error={errors["name"]}
              // disabled
            />
            <MainInput
              label={"Callback URL"}
              required={true}
              id={"app-callback_url"}
              name="callback_url"
              value={app.callback_url}
              handleChange={handleChange}
              error={errors["callback_url"]}
              // disabled
            />
          </div>
        </div>
        <div className="group">
          <div className="title">Defina niveles de permisos</div>
          <table className="permisos">
            <thead>
              <tr>
                <th className="scope-th">SCOPES</th>
                <th>LEER</th>
                <th>CREAR</th>
                <th>ACTUALIZAR</th>
              </tr>
            </thead>
            <tbody>
              {permisos.map((permiso) => {
                return (
                  <tr key={permiso.id} className="permiso-wrapper">
                    <td className="left">
                      <p className="title-table">{permiso.name}</p>
                    </td>
                    <td className="option">
                      <Checkbox
                        handleChange={() => handleSelect(`${permiso.id}_read`)}
                        checked={app.scopes.includes(`${permiso.id}_read`)}
                      ></Checkbox>
                    </td>
                    <td className="option">
                      <Checkbox
                        handleChange={() =>
                          handleSelect(`${permiso.id}_create`)
                        }
                        checked={app.scopes.includes(`${permiso.id}_create`)}
                      ></Checkbox>
                    </td>
                    <td className="option">
                      <Checkbox
                        handleChange={() =>
                          handleSelect(`${permiso.id}_update`)
                        }
                        checked={app.scopes.includes(`${permiso.id}_update`)}
                      ></Checkbox>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="right">
        <div className="group logo">
          <div className="title">Logo</div>
          <div className="image-input">
            <DragAndDropImage
              name="logo"
              handleChange={handleChangeImage}
              handleDelete={handleDeleteImage}
              file={app.logo}
              error={errors.logo}
            />
          </div>
        </div>
        <div className="group void">
          <div className="enable-toggle">
            <p className="toggle-label">Aplicación habilitada</p>
            <Toggle
              handleChange={handleChangeEnabled}
              checked={app.enabled}
            ></Toggle>
          </div>
        </div>
        <div className="group void credentials">
          <div className="title">Credenciales de producción</div>
          <CredentialBoxes app={app} />
        </div>
      </div>
    </div>
  );
};

export default AppEdit;
