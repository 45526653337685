import React from "react";
import { Tooltip } from "react-tippy";
import { isMobile } from "helpers/Mobile";

import "./styles.scss";

const tooltipStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
};

const statusMap = {
  INT: "Integrado",
  NOT: "No Integrado",
  PEN: "Pendiente de Integración",
  ACT: "Activo",
  INA: 'Inactivo'
};

const UserTableItem = ({ user, handleClick }) => {
  // const columns = ["name", "email", "role"];
  const columns = ["image", "name", "email", "role", "status"];


  const renderPill = (status) => {
    return (
      <div className="user-table-pill-container">
        <div className={`user-table-pill ${status.toLowerCase()}`}>
          <p>{statusMap[status]}</p>
        </div>
      </div>
    );
  };

  const renderColumn = (key) => {
    return (
      <div
        className={`user-table-item-${key} user-table-item`}
        data-user-id={key}
        onClick={() => handleClick(user.id)}
        key={key}
      >
        <Tooltip
          html={
            <div style={tooltipStyle}>
              {key === "status" ? statusMap[user[key]] : user[key]}
            </div>
          }
          followCursor={true}
          position="left"
          offset={25}
          disabled={["image"].includes(key)}
        >
          {key === "image" && user.image && <img src={user.image} />}
          {key === "status" && <>{renderPill(user[key])}</>}
          {!["image", "status"].includes(key) && <p>{user[key]}</p>}

        </Tooltip>
      </div>
    );
  };

  return (
    <div
      className="user-table-item-wrapper"
      data-automation-id="user-item-wrapper"
    >
      {!isMobile && (
        <div className="user-table-item-container" data-automation-id="user-item">
          {columns.map((column_key) => {
            return renderColumn(column_key);
          })}
          <div
            className={`user-table-item-button user-table-item`}
            data-automation-id="user-button"
            data-user-id={user.id}
            onClick={() => handleClick(user.id)}
            key={"button-2"}
          >
            <i className="icon-chevron"></i>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="user-table-item-container-mobile"  onClick={() => handleClick(user.id)} >
           <div className="user-table-item-column-mobile">
            <div className="user-image">
              <img src={user.image} />
            </div>
          </div>
          <div className="user-table-item-column-mobile">

            <div className="user-table-item-row-mobile">
              <p className="header">{user.name}</p>
            </div>

            <div className="user-table-item-row-mobile">
              <p className="body">Email: <span>{user.email}</span></p>
            </div>
            <div className="user-table-item-row-mobile">
              <p className="body">Rol: <span>{user.role}</span></p>
            </div>

          </div>

          <div className="user-table-item-column-mobile">
            <div className="user-pill-mobile-container">
              {renderPill(user.status)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserTableItem;
