import i18n from "i18n";

class Application {
  id = null;
  authorization_grant_type = null;
  callback_url = null;
  client_type = null;
  connected = false;
  created = null;
  enabled = null;
  name = null;
  redirect_uris = null;
  scope = null;
  skip_authorization = null;
  updated = null;
  user = null;
  client_id = null;
  client_secret = null;
  logo = null;
  status = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];

    if(obj.connected){
      this.status = 'INT'
    }else{
      this.status = 'NOT'
    }
  }

}

export { Application };
