import React, { useState, useEffect } from "react";

import "./styles.scss";
const SearchSimple = ({ placeholder, handleSubmit, valueSearch }) => {
  const [value, setValue] = useState(valueSearch);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const clearInput = () => {
    setValue("");
    handleSubmit("");
  };

  const _handleSubmit = (e) => {
    e.preventDefault();
    handleSubmit(value);
  };

  return (
    <div className="simple-search-wrapper">
      <form onSubmit={_handleSubmit}>
        <i className="search-icon-desktop icon-iconos_buscar"></i>
        <input
          className="search-input"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          data-automation-id="search-input"
        ></input>
        {value && value.length > 0 && (
          <div
            className="search-close-wrapper"
            onClick={clearInput}
            data-automation-id="search-cancel"
          >
            <i className="search-icon-close icon-iconos_close2"></i>
          </div>
        )}
      </form>
    </div>
  );
};

export default SearchSimple;
