const prepTypeMapping = {
  BAS: "Recepción",
  REQ: "Pedido",
  ADJ: "Ajuste de Inventario",
  SALE: "Venta",
  PUR: "Por compra",
  REF: "Devolución del cliente",
  PRO: "Produccion",
};

export default prepTypeMapping;
