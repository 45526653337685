import React, { useEffect, useState } from "react";
import './styles.scss'
import Checkbox from '@mui/material/Checkbox';
import default_image from 'assets/images/default_image.png';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const SimplifedRelocationRow = ({ groupedItem, updateTagsSelected }) => {

    const [listTags, setListTags] = useState([])
    const [imageError, setImageError] = useState(false);
    const [collapse, setCollapse] = useState(false);

    const handleChangeCheckbox = (event) => {
        let tagsSelected = event.target.value.split(',');
        let listTagsState = [...listTags];
        if (event.target.checked) {
            tagsSelected.forEach(tag => {
                listTagsState.push(tag);
            });
        } else {
            tagsSelected.forEach(tag => {
                let index = listTagsState.indexOf(tag);
                if (index !== -1) {
                    listTagsState.splice(index, 1);
                }
            });
        }
        setListTags(listTagsState);
        updateTagsSelected(listTagsState, groupedItem?.sku);
    }

    return (
        <div className="container">
            <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                    <div className="item-detail">
                        <div className="item">
                            <div className="item-img">
                                <img
                                    alt="product"
                                    src={(imageError || !groupedItem?.image) ? default_image : groupedItem?.image}
                                    onError={() => { setImageError(true) }}
                                />
                            </div>
                            <div className="item-sku">
                                sku : {groupedItem?.sku}
                            </div>
                        </div>
                        <div className="item-name">
                            {groupedItem?.listGrouped[0].title}
                        </div>
                        <div className="item-tags-selected">
                         {listTags.length} tags seleccionados
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {
                            groupedItem?.listGrouped?.map((groupedLocations) => {
                                let tagsSize = groupedLocations.tags.length;
                                return (
                                    <div className="item-location" key={groupedLocations.location.path_name + groupedItem?.sku}>
                                        <div className="location-tags-count">
                                        <div>
                                            <Checkbox value={[groupedLocations.tags.map(tag => tag.tag)]} onChange={handleChangeCheckbox} />
                                        </div>
                                            <div className="location-name">{groupedLocations.location.path_name}</div>
                                            <div className="tags-count">{tagsSize} {tagsSize > 1 ? " tags" : "tag"} </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default SimplifedRelocationRow;