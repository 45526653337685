import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import FileSaver from "file-saver";
import i18n from "i18n";

import { OrderService } from "services/OrderService";
import { MovementService } from "services/MovementServkce";
import { StockService } from "services/StockService";
import { TagService } from "services/TagService";
import { LocationService } from "services/LocationService";

import { WSService } from "services/WSServices";
import useRequest from "hooks/useRequest";

import { EventRepository } from "helpers/EventRepository";
import {
  itemsByItem,
  itemsByLocation,
  getSelectedItems,
} from "helpers/orders/MovementHelper";
import { parseItems } from "helpers/orders/ItemHelper";

import "./styles.scss";
import AdminLayout from "../../../../../components/MainApp/layouts/DesktopLayout";
import OrderDetailDescriptionPanel from "../../../../../components/MainApp/organisms/Orders/RequestOrders/Detail/Description";
import OrderProductSummary from "../../../../../components/MainApp/organisms/Orders/RequestOrders/ProductSummary";
import OrderPills from "../../../../../components/MainApp/atoms/Pills/OrderPills";
import MainButton from "../../../../../components/MainApp/atoms/RoundedButton";
import RejectModal from "../../../../../components/MainApp/organisms/Orders/RequestOrders/Modals/RejectModal";
import TwoOptionModal from "../../../../../components/MainApp/atoms/Modals/TwoOptionModal";
import Loader from "../../../../../components/MainApp/atoms/Loader";
import { isMobile } from "../../../../../helpers/Mobile";
import ItemTags from "components/MainApp/organisms/OrdersV3/Item-Tags";
import LocationModal from "components/MainApp/organisms/OrdersV3/LocationModal";

import LoadMovementItems from "./LoadMovementItems";
import ValidateMovementItems from "./ValidateMovementItems";
import LocateMovementItems from "./LocateMovementItems";

let globalChannel = null;
let globalVerifyMovementItems = [];

export const RecOrderDetailsView = (props) => {
  const history = useHistory();

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [step, setStep] = useState(0);
  // Step 0
  const [order, setOrder] = useState({});
  const [orderDescription, setorderDescription] = useState([]);
  const [modalOpen, setmodalOpen] = useState(false);
  const [movCloseMovalOpen, setMovCloseModalOpen] = useState(false);
  const [products, setproducts] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [movement, setmovement] = useState({});
  const [movementItems, setmovementItems] = useState([]);
  // Step 1
  const [groupMovementItems, setGroupMovementItems] = useState([]);
  // Step 2
  const [locateds, setlocateds] = useState([]);
  const [noLocateds, setnoLocateds] = useState([]);
  const [allSelecteds, setallSelecteds] = useState(false);
  const [openLocationForm, setopenLocationForm] = useState(false);
  const [fieldsLocation, setfieldsLocation] = useState([]);
  const [locations, setlocations] = useState([]); // Store the selected locations
  const [locationsOptions, setlocationsOptions] = useState([[]]); // Store the choices
  // Step 3
  const [verifyMovementItems, setVerifyMovementItems] = useState([]);
  const [validatedItems, setValidatedItems] = useState(0);
  const [validLocation, setValidLocation] = useState(false);

  const headerOptions = isMobile
    ? [
      {
        icon: "icon-iconos_descargar",
        name: "Descargar",
        handler: downloadVoucher,
        tooltip: "Descargar",
      },
    ]
    : [];

  const goBackModal = () => {
    history.push("/admin/orders/reception");
  };

  useEffect(() => {
    globalChannel = null;
    const id = props.match.params.key;
    const node = props.match.params.node ?? "";
    beforeSubmit();
    OrderService.recOrder(id, node)
      .then((order) => {
        afterSubmit();
        setOrder(order);
        let orderDescriptionNew = order.getDescriptionPanelView();
        OrderService.getOrderStatus(order.id).then((response) => {
          const orders = Object.values(response).filter(item => typeof item === 'object' && item !== null);
          let reason = orders.reduce((r, order) => order?.reason || r, '');
          orderDescriptionNew = orderDescriptionNew.map((item) => {
            if (item.title === "Motivo") {
              return { ...item, value: reason || "" };
            }
            return item;
          });
        setorderDescription(orderDescriptionNew);
        getItems(order.id, node);
        });
      })
      .catch((_) => {
        afterSubmit();
      });
  }, []);

  useEffect(() => {
    globalVerifyMovementItems = verifyMovementItems;
  }, [verifyMovementItems]);

  useEffect(() => {
    if (!movement.id) return;

    if (movementItems.length === 0) {
      getMovementItems();
    }
  }, [movement]);

  useEffect(() => {
    setGroupMovementItems(itemsByItem(movementItems, products));

    const verifyItems = itemsByItem(movementItems, products);
    setVerifyMovementItems(verifyItems);
    setValidatedItems(0); // Por defecto nada validado

    const itemsLocateds = movementItems.filter((m) => m.location);
    setlocateds(itemsByLocation(itemsLocateds));

    const itemsNoLocateds = movementItems.filter((m) => !m.location);
    setnoLocateds(itemsByItem(itemsNoLocateds));
  }, [movementItems]);

  useEffect(()=> {
    setValidLocation(false)
  }, [openLocationForm])

  const getItems = (orderId, node = '') => {
    OrderService.getItems(orderId, node).then((response) => {
      const productList = parseItems(response);
      const total = [...productList].reduce(function (a, prod) {
        return a + prod.units;
      }, 0);
      setproducts(productList);
      setTotalUnits(total);
      // Now get movement items
      getMovements(orderId, node);
    });
  };

  const getMovements = (orderId, node) => {
    OrderService.roadMap(orderId, node).then((response) => {
      response = response.filter((result) => {
        return result.movement.status !== "CAN";
      });
      if (response.length !== 0) {
        const movement = response[response.length - 1].movement;
        setmovement(movement);
      }
    });
  };

  const getMovementItems = () => {
    MovementService.items(movement.id).then((response) => {
      setmovementItems(response);
    });
  };

  const receiveEvent = useCallback((payload) => {
    const ws = WSService.getInstance();
    console.log("Message from socket");
    console.log(payload);

    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {
      if ("items" in payload.params) {
        setmovementItems(payload.params.items);
      } else {
        handleTagSearch(payload.params.epc);
      }
    }
  }, []);

  const handleRejectModalOpen = () => {
    setmodalOpen(true);
  };

  const handleRejectMovCloseModalOpen = () => {
    setMovCloseModalOpen(true);
  };

  const handleCloseModal = () => {
    setmodalOpen(false);
    setMovCloseModalOpen(false);
  };

  const handleReject = (reason) => {
    beforeSubmit();
    OrderService.changeOrderStatus(order.id, "REJ", reason)
      .then((response) => {
        setmodalOpen(false);
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("La orden se rechazó de manera exitosa."),
          type: "success",
        });
        props.history.push("/admin/orders/reception");
      })
      .catch((error) => {
        setmodalOpen(false);
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: "error",
            });
          } else {
            EventRepository.notificationSend({
              label: i18n.t("La orden no se puede rechazar."),
              type: "error",
            });
          }
        });
      });
  };

  const handleApprove = () => {
    beforeSubmit();
    OrderService.changeOrderStatus(order.id, "PRO", "apporoved")
      .then((response) => {
        afterSubmit();
        setmodalOpen(false);

        EventRepository.notificationSend({
          label: i18n.t("La orden se aprobó de manera exitosa."),
          type: "success",
        });
        props.history.push("/admin/orders/reception");
      })
      .catch((error) => {
        afterSubmit();
        setmodalOpen(false);
        dealWithError(error, "generic.error", (message, errors) => {
          if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: "error",
            });
          } else {
            EventRepository.notificationSend({
              label: i18n.t("La orden no se puede aprobar."),
              type: "error",
            });
          }
        });
      });
  };

  const handleAddMovementItem = () => {
    if (movement.status === "PRO") {
      setStep(1);
      return;
    }

    handleInitVerify();
  };

  const handleDeleteMovementItems = (tag) => {
    beforeSubmit();
    MovementService.deleteItems(movement.id, [{ id: tag.movement_item_id }])
      .then((response) => {
        afterSubmit();
        setmovementItems(response);
      })
      .catch((error) => {
        afterSubmit();
      });
  };

  const handleDeleteAllMovementItems = (e) => {
    const ids = movementItems.map((m) => {
      return { id: m.id };
    });
    beforeSubmit();
    MovementService.deleteItems(movement.id, ids)
      .then((response) => {
        afterSubmit();
        setmovementItems(response);
      })
      .catch((error) => {
        afterSubmit();
      });
  };

  const handleSearch = (cod) => {
    cod = cod?.trim();
    if (movement.type === "IN") {
      // Search in tags
      handleTagForStockSearch(cod);
    } else {
      // Search in stock
      handleStockSearch(cod);
    }
  };

  const handleStockSearch = (cod) => {
    if (!cod) return;

    const filter = {
      location__node: movement.node.code,
      status: "TEMP_OUT", // Para recepciones de nodo internos
      tag__rfid: cod,
    };
    beforeSubmit();
    StockService.stock(filter)
      .then((response) => {
        if (response.count === 0) {
          afterSubmit();
          return;
        }
        const stock = response.results[0];
        MovementService.addItems(movement.id, [
          {
            item_stock: stock.id,
          },
        ])
          .then((response) => {
            afterSubmit();
            setmovementItems(response);
          })
          .catch((error) => {
            afterSubmit();
            dealWithError(error, "generic.error", (message, _) => {
              EventRepository.notificationSend({
                label: message,
                type: "error",
              });
            });
          });
      })
      .catch((_) => {
        afterSubmit();
      });
  };

  const handleTagForStockSearch = (cod) => {
    cod = cod?.trim();
    beforeSubmit();
    TagService.itemFromTag(cod)
      .then((item) => {
        MovementService.addItems(movement.id, [
          {
            item: item.id,
            tag: cod,
          },
        ])
          .then((response) => {
            afterSubmit();
            setmovementItems(response);
          })
          .catch((error) => {
            afterSubmit();
          });
      })
      .catch((_) => {
        afterSubmit();
      });
  };

  const handleConfirmMovement = () => {
    const status = movement.requires_revision ? "CLO" : "COM";
    if (!movement.requires_revision) {
      handleLocalization();
      return;
    }
    beforeSubmit();
    MovementService.changeStatus(movement.id, "CLO")
      .then((_) => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("La orden se preparó de manera exitosa."),
          type: "success",
        });
        props.history.push("/admin/orders/reception");
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "generic.error", (message) => {
          EventRepository.notificationSend({
            label: message,
            type: "success",
          });
        });
      });
  };

  const handleInitVerify = () => {
    setStep(2);
  };

  const handleTagSearch = (cod) => {
    if (!cod) return;

    let verifyItems = [...globalVerifyMovementItems];

    // Buscar EPC
    verifyItems.forEach((i, index, arr) => {
      i.tags.forEach((t, indexTag) => {
        if (t.tag === cod) {
          arr[index]["tags"][indexTag]["selected"] = true;
        }
      });
    });
    setVerifyMovementItems(verifyItems);

    // Calcular cantidad validados
    let cant = 0;
    verifyItems.forEach((i) => {
      i.tags.forEach((t) => {
        if (t.selected) cant++;
      });
    });
    setValidatedItems(cant);
  };

  const handleDeleteLocation = (itemLoc) => {
    const data = itemLoc.items.map((m) => {
      return {
        id: m.movement_item_id,
        location: null,
      };
    });
    beforeSubmit();
    MovementService.updateItems(movement.id, data)
      .then((response) => {
        afterSubmit();
        setmovementItems(response);
      })
      .catch((error) => {
        afterSubmit();
      });
  };

  const handleSelectItem = (tag) => {
    const items = [...noLocateds];

    for (let item of items) {
      for (let item_tag of item.tags) {
        if (item_tag.tag === tag.tag) {
          item_tag.selected = !item_tag.selected;
          setnoLocateds(items);
          return;
        }
      }
    }
  };

  const handleSelectAllItem = () => {
    const items = [...noLocateds];

    for (let item of items) {
      for (let item_tag of item.tags) {
        item_tag.selected = !allSelecteds;
      }
    }

    setallSelecteds(!allSelecteds);
    setnoLocateds(items);
  };

  const addLocation = () => {
    if (getSelectedItems(noLocateds).length === 0) return;

    let locationsOptions = [[]]; // No options load
    let fields = [
      {
        type: "dropdown",
        data: {
          label: "Zona Inicial",
          placeholder: "Zona Inicial",
          name: "0",
          selectedOption: "",
          value: "",
          options: [],
        },
      },
    ];

    beforeSubmit();
    LocationService.locations_node({ no_parent: "true", no_page: 1 })
      .then((locations) => {
        afterSubmit();
        locations = locations.map((l) => {
          return {
            id: l.id,
            label: `${l.name}`,
            type: `${l.type}`,
            capacity: l.capacity,
            capacity_busy: l.capacity_busy,
          };
        });
        locationsOptions[0] = locations;
        fields[0]["data"]["options"] = locations;

        setlocations([]);
        setlocationsOptions(locationsOptions);
        setfieldsLocation(fields);
        setopenLocationForm(true);
      })
      .catch((e) => {
        afterSubmit();
      });
  };

  const handleSelectLocation = (loc, name) => {
    const pos = parseInt(name);
    const newPos = pos + 1;
    let fields = fieldsLocation.slice(0, pos + 1);
    let locationsNew = locations.slice(0, pos + 1);
    let locationsOptionsNew = locationsOptions.slice(0, pos + 1);

    locationsNew[pos] = loc;
    fields[pos]["data"]["selectedOption"] = loc;
    fields[pos]["data"]["value"] = loc.id;

    beforeSubmit();
    LocationService.locations_node({ parent: loc.id, no_page: 1 })
      .then((locations) => {
        afterSubmit();
        setValidLocation(locations.length === 0);
        if (locations.length === 0) throw "Empty";

        locations = locations.map((l) => {
          return {
            id: l.id,
            label: `${l.name}`,
            type: `${l.type}`,
            capacity: l.capacity,
            capacity_busy: l.capacity_busy,
          };
        });
        locationsOptionsNew[newPos] = locations;
        fields.push({
          type: "dropdown",
          data: {
            label: `${loc.label}`,
            placeholder: `${loc.label}`,
            name: String(newPos),
            selectedOption: "",
            value: "",
            options: locations,
          },
        });

        setlocations(locationsNew);
        setlocationsOptions(locationsOptionsNew);
        setfieldsLocation(fields);
      })
      .catch((e) => {
        afterSubmit();

        setlocations(locationsNew);
        setlocationsOptions(locationsOptionsNew);
        setfieldsLocation(fields);
      });
  };

  async function getCounters(data) {
    let auxData = [...data];
    for (let d of auxData) {
      d["count"] = 1;
      if(d.type !== "PRODUCT"){
        let resp = await TagService.countByTag(d.tag);
        d["count"] = resp.count;
      }
    }
    return auxData;
  }

  const handleAddLocation = () => {
    if(!validLocation){
      EventRepository.notificationSend({
        label: i18n.t("locations.invalid_location"),
        type: "error",
      });
      return;
    }

    const loc = locations[locations.length - 1];
    /* TODO: Verificar porque se bloqueaba la opcion de seleccionar estanterias o niveles.
    if (["SHELF", "LEVEL"].includes(loc.type)) {
      EventRepository.notificationSend({
        label: i18n.t("locations.invalid_location"),
        type: "error",
      });
      return;
    }*/

    const data = getSelectedItems(noLocateds).map((i) => {
      return {
        id: i.movement_item_id,
        location: loc.id,
        tag: i.tag,
        type: i.type,
      };
    });
    beforeSubmit();

    getCounters(data).then(data => {
      console.log(data);

      let totalCount = data.reduce((partialSum, a) => partialSum + a.count, 0);

      const busies = locations
        .filter((l) => {
          if (l.capacity === null) {
            return false;
          }

          let cuantityLocated = 0;
          movementItems.forEach(item => {
            if(item.location && item.location.id == l.id){
              cuantityLocated += item.count;
            }
          });

          l.capacity_busy = l.capacity_busy +cuantityLocated;

          return l.capacity_busy + totalCount > l.capacity;
        })
        .reverse();

      if (busies.length) {
        EventRepository.notificationSend({
          label: i18n.t("locations.max_capacity", {
            overflow: busies[0].capacity_busy + totalCount - busies[0].capacity,
            capacity: busies[0].capacity,
            type: i18n.t(`locations.type.${busies[0].type}`),
            name: busies[0].label,
          }),
          type: "error",
        });
        afterSubmit();
        return;
      }

      MovementService.updateItems(movement.id, data)
        .then((response) => {
          afterSubmit();
          data.forEach(itemData => {
            let item = response.filter(i => i.id == itemData.id)
            item[0]["count"] = itemData.count;
            console.log(item);

          });
          setmovementItems(response);
          setopenLocationForm(false);
        })
        .catch((error) => {
          afterSubmit();
          setopenLocationForm(false);
          dealWithError(error, "generic.error", (message, errors) => {
            if (Object.keys(errors).length === 0) {
              EventRepository.notificationSend({
                label: message,
                type: "error",
              });
            } else {
              EventRepository.notificationSend({
                label: i18n.t("No se pudo actualiza la ubicacion."),
                type: "error",
              });
            }
          });
        });
    });

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Completo la orden
    beforeSubmit();
    MovementService.changeStatus(
      movement.id,
      movement.status === "PRO" ? "COM" : "VER_COM"
    )
      .then((response) => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("La orden se completo de manera exitosa."),
          type: "success",
        });
        props.history.push("/admin/orders/reception");
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: "error",
            });
          } else {
            EventRepository.notificationSend({
              label: i18n.t("Hubo un error completando la orden."),
              type: "error",
            });
          }
        });
      });
  };

  const handleLocalization = () => {
    setStep(3);
  };

  const handleChangeStep = (step) => {
    setStep(step);
  };

  function downloadVoucher () {
    beforeSubmit();
    OrderService.recDownloadVoucher(order.id)
      .then((blob) => {
        afterSubmit();
        FileSaver.saveAs(blob, "comprobante.pdf");
      })
      .catch((error) => {
        afterSubmit();
      });
  };

  const customButtons = (
    <div className="reception-order-details-custom-buttons">
      <div className="reception-order-details-reject">
        <MainButton
          handleClick={handleRejectModalOpen}
          state={"enabled"}
          legend="RECHAZAR"
        ></MainButton>
      </div>

      <div className="reception-order-details-approve">
        <MainButton
          handleClick={handleApprove}
          state={"enabled"}
          legend="INGRESAR"
        ></MainButton>
      </div>
    </div>
  );

  const loadedContentStep0 = (
    <div className="reception-order-details-wrapper">
      <div className="reception-order-details-panels">
        <div className="reception-order-details-left">
          {!isMobile && (
            <div className="reception-order-details-left-title">
              <div className="reception-order-details-title">
                Orden de recepción
              </div>
              <div className="pill-wrapper">
                {order && (
                  <OrderPills big={true} state={order.status}></OrderPills>
                )}
              </div>
            </div>
          )}
          <div className="reception-order-details-panel">
            <OrderDetailDescriptionPanel
              order={orderDescription}
            ></OrderDetailDescriptionPanel>
          </div>
        </div>
        <div className="reception-order-details-right">
          {!isMobile && (
            <div
              className={`reception-order-details-right-title ${!isMobile &&
                order.status?.match("PRO|PEN|SUP") &&
                "two-elements"}`}
            >
              <div
                className="reception-order-details-download"
                onClick={downloadVoucher}
              >
                <i className="reception-order-details-download-icon icon-iconos_descargar"></i>
                <div className="reception-order-details-download-text">
                  {" "}
                  Descargar detalle{" "}
                </div>
              </div>
              {!isMobile && order && order.status === "PRO" && (
                <div
                  className="reception-order-details-init-movement"
                  onClick={handleAddMovementItem}
                >
                  RECIBIR PEDIDO
                </div>
              )}
              {!isMobile && order && order.status === "SUP" && (
                <div
                  className="reception-order-details-init-movement"
                  onClick={handleInitVerify}
                >
                  VERIFICAR MOVIMIENTO
                </div>
              )}
              {!isMobile && order && order.status === "PEN" && (
                <div className="reception-order-details-button">  {customButtons}
                </div>
              )}
              {isMobile &&
                order &&
                order.status === "PRO" &&
                order.klass !== "REQ" && (
                  <div
                    className="reception-order-details-init-movement"
                    onClick={() => {
                      history.push(`/admin/orders/reception/edit/${order.id}`);
                    }}
                  >
                    EDITAR PEDIDO
                  </div>
                )}
            </div>
          )}
          <div className="reception-order-details-panel">
            {order.status == "PEN" && (order?.initial_order?.type == "REL" || order?.initial_order?.type == "REQ") && (
              <div className="reception-order-details-panel-wrapper">
                <OrderProductSummary
                  products={products}
                  edit={false}
                  paginationSize={5}
                ></OrderProductSummary>
              </div>
            )}
            <div className="reception-order-details-panel-wrapper">
              {["PRO", "SUP", "DISP", "COM"].includes(order.status) &&
                groupMovementItems.map((item) => {
                  return (
                    <ItemTags
                      key={item.sku}
                      item={item}
                      quantity={item.tags.length}
                      expected={products.length > 0 ? item.expectedUnits : null}
                      reprint={order.klass == "REF" && order.type == "REC"}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {isMobile && order && order.status === "PEN" && (
        <div className="reception-order-details-button">{customButtons}</div>
      )}
      {isMobile && order && order.status === "PRO" && (
        <div
          className="reception-order-details-button"
          onClick={handleAddMovementItem}
        >
          <div className="reception-order-details-init-movement">
            RECIBIR PEDIDO
          </div>
        </div>
      )}
      {isMobile && order && order.status === "SUP" && (
        <div
          className="reception-order-details-button"
          onClick={handleInitVerify}
        >
          <div className="reception-order-details-init-movement">
            VERIFICAR MOVIMIENTO
          </div>
        </div>
      )}
      {isMobile && order && order.status === "PRO" && order.klass !== "REQ" && (
        <div
          className="reception-order-details-button"
          onClick={() => {
            history.push(`/admin/orders/reception/edit/${order.id}`);
          }}
        >
          <div className="reception-order-details-init-movement">
            EDITAR PEDIDO
          </div>
        </div>
      )}
    </div>
  );

  const loadedContentStep1 = (
    <LoadMovementItems
      showExpected={products.length > 0}
      total={totalUnits}
      originalItems={movementItems}
      selectedItems={groupMovementItems}
      handleDelete={handleDeleteMovementItems}
      handleSearch={handleSearch}
      handleDeleteAll={handleDeleteAllMovementItems}
      handleSend={handleConfirmMovement}
      goBack={() => handleChangeStep(0)}
      enabledRead={true}
      typeRead={"ORDERS"}
      refRead={order.id}
      associateRead={true}
      priorityRead={"BARCODE"}
      receiveEvent={receiveEvent}
    />
  );

  const loadedContentStep2 = (
    <ValidateMovementItems
      total={movementItems.length}
      totalValidate={validatedItems}
      selectedItems={verifyMovementItems}
      handleSearch={handleTagSearch}
      handleReject={handleRejectMovCloseModalOpen}
      handleApprove={handleLocalization}
      goBack={() => handleChangeStep(0)}
      enabledRead={true}
      typeRead={"READ"}
      refRead={""}
      receiveEvent={receiveEvent}
    />
  );

  const loadedContentStep3 = (
    <LocateMovementItems
      itemsLocateds={locateds}
      itemsNoLocateds={noLocateds}
      handleDelete={handleDeleteLocation}
      handleSelect={handleSelectItem}
      allSelecteds={allSelecteds}
      handleSelectAll={handleSelectAllItem}
      addLocation={addLocation}
      handleSubmit={handleSubmit}
      goBack={() => handleChangeStep(2)}
    />
  );

  const loadedContent = [
    loadedContentStep0,
    loadedContentStep1,
    loadedContentStep2,
    loadedContentStep3,
  ];
  const content = order.id ? loadedContent[step] : <></>;

  return (
    <div className="reception-order-details-container">
      <AdminLayout
        headerTitle={"Detalle de orden"}
        headerOptions={headerOptions}
        content={content}
        goBackFunc={goBackModal}
        navHidden={true}
      ></AdminLayout>

      {/*Para rechazar orden*/}
      <RejectModal
        open={modalOpen}
        handleClose={handleCloseModal}
        handleReject={handleReject}
      ></RejectModal>

      {/*Para rechazar movimiento cerrado a procesando*/}
      <RejectModal
        open={movCloseMovalOpen}
        handleClose={handleCloseModal}
        handleReject={handleReject}
      ></RejectModal>

      {openLocationForm && (
        <LocationModal
          fields={fieldsLocation}
          handleClose={() => setopenLocationForm(false)}
          handleSelect={handleSelectLocation}
          handleAdd={handleAddLocation}
        />
      )}

      {loading && <Loader />}
    </div>
  );
};

export default RecOrderDetailsView;
