import Environment from "../environment";
import Api from "./ApiService";

import { DeviceType } from "models/DeviceType";

export const DeviceTypeService = {

  device_types: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/devices_type/?no_page=1`,
        "GET",
        params
      )
        .then((rta) => {
          rta = rta.results.map((item) => new DeviceType (item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  device_type: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/devices_type/${id}/`, "GET")
        .then((rta) => {
          rta = new DeviceType(rta);
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
