import { select } from "@storybook/addon-knobs";
import React from "react";
import onClickOutside from "react-onclickoutside";

import "./styles.scss";
import { isMobile } from "helpers/Mobile";
export class SelectFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      open: false,
    };
  }

  handleClick = (e) => {
    const open = !this.state.open;
    this.setState({
      open,
      focused: open,
    });
  };

  handleSelect = (selection) => {
    const option = this.props.options.find(
      (o) => o.id === selection.currentTarget.dataset.id
    );

    this.props.handleSelection(option);
    this.setState({
      focused: false,
      open: false,
    });
  };

  handleClickOutside() {
    this.setState({
      focused: false,
      open: false,
    });
  }
  
  render() {
    const { options, label, selectedOption, placeholder } = this.props;
    return (
      <div className="dropdown-filter-container">
        <div
          className={
            this.state.focused
              ? "dropdown-filter-input focused"
              : "dropdown-filter-input"
          }
          onClick={this.handleClick}
        >
          <div className="dropdown-filter-label">{label}</div>
          <div className="dropdown-filter-label-inner">
            <div className="dropdown-filter-placeholder">
              {selectedOption?.label || placeholder}
            </div>

            <i
              className={`icon-chevron dropdown-filter-chevron ${
                this.state.open ? "open" : ""
              } `}
            ></i>
          </div>
        </div>
        {this.state.open && (
          <div className="dropdown-filter-option-container">
            <div className="dropdown-filter-option-wrapper">
              {options.map((option, index) => {
                let optionChecked = selectedOption?.id === option.id;
                return (
                  <div
                    className={`dropdown-filter-option ${optionChecked &&
                      "selected-option"}`}
                    key={index}
                    data-id={option.id}
                    onClick={this.handleSelect}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default onClickOutside(SelectFilter);
