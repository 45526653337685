import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
export const Tooltip = ({content, text, position}) => {


    return (
     <div className={`main-app-tooltip ${position}`}>
         {content}
         {text && <div className={`tooltip-text ${position}`}>
             {text}
         </div>}

     </div>
    )
  }



Tooltip.propTypes = {
  handleClick: PropTypes.func,
  legend: PropTypes.string
};

export default Tooltip;