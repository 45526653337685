import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";
import useFilters from "hooks/useFilters";
import { isMobile } from "helpers/Mobile";
import { parseDateToServer } from "helpers/utils/dates";
import { getUrl } from "helpers/orders/OrderHelper";

import { BiService } from "services/BiService";
import { OrderService } from "services/OrderService";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import MostSelledCard from "components/MainApp/organisms/Dashboard/MostSelledCard";
import OrderStatesCard from "components/MainApp/organisms/Dashboard/OrderStatesCard";
import DeliveredOrdersCard from "components/MainApp/organisms/Dashboard/DeliveredOrdersCard";
import MonthlySalesCard from "components/MainApp/organisms/Dashboard/MonthlySalesCard";
import DashboardTable from "components/MainApp/organisms/Dashboard/Table";
import DropdownOptions from "components/MainApp/organisms/Dashboard/DropdownOptions";

import "./styles.scss";

const colorsOrdersStatus = ["#62D862", "#3C7AF5", "#FFAF3A", "#F54B5A"];

const tableHeaders = {
  type: {
    name: "type",
    title: "TIPO",
    active: false,
    direction: false,
    value: "type",
  },
  code: {
    name: "code",
    title: "CODIGO",
    active: false,
    direction: false,
    value: "code",
  },
  date: {
    name: "date",
    title: "FECHA CREACIÓN",
    active: false,
    direction: false,
    value: "created_at",
  },
  owner: {
    name: "owner",
    title: "RESPONSABLE",
    active: false,
    direction: false,
    value: "owner__name",
  },
  state: {
    name: "state",
    title: "ESTADO",
    active: false,
    direction: false,
    value: "status",
  },
};

const initialFilters = {
  // page_size: 5,
  ordering: "-created_at",
  from_date: null,
};

const options = [
  {
    key: "1",
    value: "Ultimas 24 horas",
  },
  {
    key: "7",
    value: "Ultimos 7 dias",
  },
  {
    key: "30",
    value: "Ultimos 30 dias",
  },
];

const Dashboard = () => {
  const history = useHistory();
  const { beforeSubmit, afterSubmit } = useRequest();

  const [data, setData] = useState(null);

  const [itemsSell, setItemsSell] = useState([]);
  const [clientsRanking, setClientsRanking] = useState([]);

  const [ordersStatus, setOrdersStatus] = useState([]);
  const [ordersStatusFilter, setOrdersStatusFilter] = useState([]);
  const [totalOrdersStatus, setTotalOrdersStatus] = useState(0);

  const [salesByMonthX, setSalesByMonthX] = useState([]);
  const [salesByMonthV, setSalesByMonthV] = useState([]);

  const [
    filters,
    setFilters,
    selectedFiltersAll,
    selectedFiltersByType,
    handleApplyFilters,
    handleSearch,
    headerOrderingHandler,
    resetOrdering,
    handleFiltersChangeOrdering,
    applied,
    page,
    setPage,
    items,
    setItems,
    pager,
    setPager,
    header,
    setHeader,
    mobilePanelOpen,
    setMobilePanelOpen,
  ] = useFilters(initialFilters, [], [], isMobile, tableHeaders);

  useEffect(() => {
    BiService.dashboard().then((data) => {
      setData(data);

      mapItemsSell(data["SALE_PRODUCTS"]["payload"]);
      mapOrdersStatus(data["ORDERS_STATUS"]["payload"]);
      mapClientsRanking(data["CLIENT_RANKING"]["payload"]);
      mapSalesByMonth(data["SALES_MONTH"]["payload"]);
    });
  }, []);

  useEffect(() => {
    if (filters["from_date"] == null) {
      setFromDateFilter(1);
    } else {
      filter(false);
    }
  }, [filters]);

  const setFromDateFilter = (days) => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - days);
    const newFilters = { ...filters };
    newFilters["page_size"] = 600;
    newFilters["from_date"] = parseDateToServer(fromDate, false, false);
    setFilters(newFilters);
  };

  const filter = () => {
    const data = { ...filters };
    beforeSubmit();
    OrderService.orders(data)
      .then((response) => {
        afterSubmit();
        setItems(response.results);
      })
      .catch((e) => {
        afterSubmit();
      });
  };

  const handleChangeFilterItemsSell = (key) => {
    const key_to_field = {
      name: "item_val_name",
      sku: "item_val_sku",
    };
    mapItemsSell(data["SALE_PRODUCTS"]["payload"], key_to_field[key]);
  };

  const mapItemsSell = (items, filed_label = "item_val_name") => {
    let total = 0;
    items.forEach((i) => {
      total += i.total;
    });
    items = items.map((i) => {
      return {
        product: i[filed_label],
        selled: i.total,
        selledPercentage: (i.total * 100) / total,
      };
    });
    setItemsSell(items);
  };

  const mapClientsRanking = (items) => {
    items = items.map((c) => {
      return {
        client: c.client__name,
        orders: c.total,
      };
    });
    setClientsRanking(items);
  };

  const handleChangeFilterOrdersStatus = (type) => {
    mapOrdersStatus(data["ORDERS_STATUS"]["payload"], type);
  };

  const mapOrdersStatus = (items, type = "all") => {
    const series = { COM: 0, PRO: 0, PEN: 0, REJ: 0};
    let total = 0;

    items
      .filter((i) => {
        return type === "all" || i.type === type;
      })
      .forEach((i) => {
        if (i.status in series) {
          series[i.status] += i.total;
          total += i.total;
        }
      });

    setOrdersStatusFilter(
      Object.keys(series).map((key) => {
        return [i18n.t(`orders.status.${key}`), series[key]];
      })
    );
    setTotalOrdersStatus(total);
  };

  const mapSalesByMonth = (items) => {
    setSalesByMonthX(items.map((s) => i18n.t(`months.numeric.${s.month}`)));

    const values = [
      {
        name: "Ventas",
      },
    ];
    values[0]["data"] = items.map((s) => s.total);
    setSalesByMonthV(values);
  };

  const goToDetailPage = (event) => {
    let url = getUrl(
      event.currentTarget.dataset.requestorderId,
      event.currentTarget.dataset.requestorderType
    );
    if (url) {
      history.push(url);
    }
  };

  const handleChangeDate = (key) => {
    setFromDateFilter(parseInt(key));
  };

  const content = (
    <div className={"dashboard-wrapper"}>
      <div className={"dashboard-wrapper-inner"}>
        <div className={"dashboard-charts"}>
          <div className={"dashboard-charts-first-row"}>
            <div className={"dashboard-charts-most-selled"}>
              <MostSelledCard
                items={itemsSell}
                handleChangeFilter={handleChangeFilterItemsSell}
              />
            </div>
            <div className={"dashboard-charts-order-states"}>
              <OrderStatesCard
                series={ordersStatusFilter}
                colors={colorsOrdersStatus}
                totalNumber={totalOrdersStatus}
                handleChangeFilter={handleChangeFilterOrdersStatus}
              />
            </div>
          </div>
          <div className={"dashboard-charts-second-row"}>
            <div className={"dashboard-charts-delivered"}>
              <DeliveredOrdersCard items={clientsRanking} />
            </div>
            <div className={"dashboard-charts-monthly-sales"}>
              <MonthlySalesCard
                series={salesByMonthV}
                xCategories={salesByMonthX}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"dashboard-table-wrapper"}>
        <div className={"dashboard-table-header"}>
          <h1 className={"dashboard-table-title"}>Últimas órdenes</h1>
          <DropdownOptions
            options={options}
            selectedOption={"1"}
            handleChange={setFromDateFilter}
          />
        </div>
        <DashboardTable
          orders={items}
          headers={tableHeaders}
          headerClickOrder={headerOrderingHandler}
          handleClickOrder={goToDetailPage}
        />
      </div>
    </div>
  );

  return (
    <div className={"dashboard-container"}>
      <AdminLayout
        headerTitle={i18n.t("Inicio")}
        headerOptions={[]}
        content={content}
        navHidden={false}
      ></AdminLayout>
    </div>
  );
};

export default Dashboard;
