import React, { useEffect, useRef, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";
import FileSaver from "file-saver";

import { StockService } from "services/StockService";

import { stockByItem } from "helpers/stock/StockHelper";

import { WSService } from "services/WSServices";

import useRequest from "hooks/useRequest";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import AuditoriaFilterPanel from "./AuditoriaFilterPanel";
import ReadingPanel from "./ReadingPanel";
import ItemStockReadingTable from "./ItemStockReadingTable";
import Loader from "components/MainApp/atoms/Loader";
import ErrorModal from "components/MainApp/organisms/Stock/ErrorModalAuditory";

import excel from "assets/images/excel.svg";
import excelwhite from "assets/images/excelwhite.svg";

import "./styles.scss";
import Tooltip from "components/MainApp/atoms/Tooltip";
import { LocationService } from "services/LocationService";
import { isMobile } from "helpers/Mobile";
import { CategoryService } from "services/CategoryService";
import { NotFoundItems } from "views/Admin/components/NotFoundItems";

const headers = {
  product: {
    title: "PRODUCTO",
    active: false,
    direction: false,
  },
  sku: {
    title: "SKU",
    active: false,
    direction: false,
  },
  tags: {
    title: "TAGS",
    active: false,
    direction: false,
  },
};

let globalStock = [];


const AuditoriaDeStock = () => {
  const history = useHistory();
  const headerOptions = [];

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();
  const [enabledRead, setEnabledRead] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [mobilePanelOpened, setMobilePanelOpened] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [originalStock, setOrginalStock] = useState([]);

  const [groupedStock, setGroupedStock] = useState([]);
  const [groupedStockAll, setGroupedStockAll] = useState([]);
  const [tagsInMov, setTagsInMov] = useState([]);
  const [validatedItems, setValidatedItems] = useState(0);
  const [invalidTagsCount, setInvalidTagsCount] = useState(0);
  const [msgTooltip, setMsgToolTip] = useState("Selecciona los filtros");
  const [selectedCombo, setSelectedCombo] = useState(null);
  const [tooltipStyle, setTooltipStyle] = useState("0.5");
  const [productFiltered, setProductFiltered] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filterCategories, setFilterCategories] = useState(null);
  const [filterProductsCombo, setFilterProductsCombo] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [stockCountTotal, setStockCountTotal] = useState(0);
  const tagNotAssociated = "No tiene ubicación asignada";
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    const newStockCount = originalStock.length;
    setStockCountTotal(newStockCount);
  }, [originalStock]);

  useEffect(() => {
    const newStockCount = groupedStock.reduce((total, item) => total + item.expectedUnits, 0);
    setStockCountTotal(newStockCount);
  }, [groupedStock]);

  const search = (selectedLoc) => {
    if (!selectedLoc) return;

    if (selectedLoc.count == 0) {
      setSearched(true);
      clearGrid();
      return;
    }

    setEnabledRead(false);

    const params = {
      no_page: 1,
      status: "LOC",
    };
    if (selectedLoc.count > 1) {
      params.locations = selectedLoc.results.map((n) => n.id);
    }else{
      params.location = selectedLoc?.id ?? selectedLoc.results[0]?.id
    };

    // añadimos categorias y productos a los filtros si hay
    if(selectedProducts?.length > 0){
      params.skus = selectedProducts.map(product => product.sku);

    }else if(selectedCategories?.length > 0){
      params.categories = selectedCategories.map(category => category.id);
    }

    beforeSubmit();
    StockService.stock(params)
      .then((res) => {
        setData(res);
        setSearched(true);
      })
      .catch((_) => {
        setSearched(true);
        afterSubmit();
      });
  }

  const setData = (res) => {
    afterSubmit();
    const _groupedStock = stockByItem(res);
    const products = _groupedStock.map(item => ({
      key: item.sku,
      label: item.title,
    }));
    // setShowFilters(products?.length > 0);
    setProductFiltered([]);

    const tags_in_mov = res.filter((stock) => stock.status === "IN_MOV").map((stock) => stock.tag.rfid);
    setTagsInMov(tags_in_mov);
    setOrginalStock(res);
    setGroupedStock(_groupedStock);
    setProductsStock(_groupedStock);
    setValidatedItems(0);
    setInvalidTagsCount(0);
    globalStock = _groupedStock;
    setEnabledRead(true);
    if (res.length > 0) {
      setMsgToolTip("Generar reporte");
      setTooltipStyle("100");
    }else{
      setMsgToolTip("No hay stock disponible para generar reporte");
      setTooltipStyle("0.5");
    }
  }

  const validatedItemsCount = () => {
    if (productFiltered?.length > 0){
      const count = groupedStock.reduce((total, item) => {
        const validTagsCount = item.tags.reduce((tagTotal, tag) => {
          if (tag.selected === true && tag.message === null) {
            return tagTotal + 1;
          }
          return tagTotal;
        }, 0);
        return total + validTagsCount;
      }, 0);
      return count;
    }
    return validatedItems;
  };

  const filterProducts = (productFilteredAux, groupedStockAllAux = null, comboCategory = null, comboProducts) => {
    const groupedStockAllAux2 = groupedStockAllAux ?? groupedStockAll;
    setProductFiltered(productFilteredAux);
    let filteredGroupedStock = groupedStockAllAux2;
    if (productFilteredAux.length > 0){
      filteredGroupedStock = groupedStockAllAux2.filter((item) => {
        return productFilteredAux.some((product) => product.key === item.sku);
      });
    }

    if(comboCategory){
      filteredGroupedStock = filteredGroupedStock.filter((item) => {
        return comboCategory.some((category) => category.id === item.category_id);
      });
    }
    if(comboProducts){
      filteredGroupedStock = filteredGroupedStock.filter((item) => {
        return comboProducts.some((product) => product.sku === item.sku);
      });
    }
    setGroupedStock(filteredGroupedStock);
    globalStock = filteredGroupedStock;
    reloadTotal();
  }

  const receiveEvent = useCallback((payload) => {
    const ws = WSService.getInstance();
    console.log("Message from socket");
    console.log(payload);
    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {
      handleTagSearch(payload.params.epc, payload.params);
    }
  }, []);

  const setErrorModal = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const clearGrid = () => {
    setOrginalStock([]);
    setGroupedStock([]);
    setInvalidTagsCount(0);
    setValidatedItems(0);
  }

  const handleApplyFilters = (selectedOptionsNew) => {
    setSelectedOptions(selectedOptionsNew);

    handleClear();

    if (selectedOptionsNew[1] &&  Object.keys(selectedOptionsNew[1]).length > 0) {

      if (selectedOptionsNew.length > 0) {
        setMobilePanelOpened(false);
      }
      var location = {};
      if (Object.keys(selectedOptionsNew[2]).length > 0 ) {
        setSelectedLocation(selectedOptionsNew[2]);
        location = selectedOptionsNew[2];
      } else if (Object.keys(selectedOptionsNew[1]).length > 0 ) {
        setSelectedLocation(selectedOptionsNew[1]);
        location = selectedOptionsNew[1];
      }

      search(location);
    } else {
      if(selectedOptionsNew.length === 0) return;
      // only branch office
      LocationService.locations({
        code: selectedOptionsNew[0].id,
        no_parent: "true",
        parent: null,
      }).then((locations) => {
        if (locations.count > 0) {
          setMobilePanelOpened(false);
        }
        setSelectedLocation(locations);
        search(locations);
      });
    }
  };

  const clearProductsStock = () => {
    setGroupedStockAll([]);
    setProductFiltered([]);
  }

  const setProductsStock = (_groupedStock) => {
    setGroupedStockAll(_groupedStock)
    const products = _groupedStock.map(item => ({
      key: item.sku,
      label: item.title,
    }));
  }

  const handleClear = (event) => {
    const _groupedStock = stockByItem(originalStock);
    setGroupedStock(_groupedStock);
    setGroupedStockAll(_groupedStock);
    filterProducts(productFiltered, _groupedStock)
    setValidatedItems(0);
    setInvalidTagsCount(0);
    globalStock = _groupedStock;
  };

  const handleDelete = (event) => {
    clearGrid();
    clearProductsStock();
    setSelectedCombo(null);
    setShowFilters(false);
    setSelectedCategories([]);
    setSelectedProducts([]);
    setSearched(false);
  };

  const handleTagSearch = (cod, data = null) => {
    cod = cod?.trim();
    if (!cod) return;

    let found = false;
    // Buscar EPC
    globalStock.forEach((i, index, arr) => {
      i.tags.forEach((t, indexTag) => {
        if (t.tag.toUpperCase() === cod.toUpperCase()) {
          if (i.expectedUnits > 0){
            arr[index]["tags"][indexTag]["selected"] = true;
          }
          found = true;
        }
      });
      if(i.expectedUnits > 0){
        i.readItems = i.tags.filter((t) => ((t.selected === true) && (t.message === null))).length;
      }
    });

    if (found) {
      setGroupedStock(globalStock);
    }

    if (!found) {
      if(data){
        addInvalidTag(data);
      }else {
        StockService.localization(cod).then((response) => {
            data = {};
            data["stock"] = response.stock;
            data["tag"] = response.tag;
            addInvalidTag(data);
            reloadTotal()
          }).catch((error) => {
            data = {};
            data["epc"] = cod;
            addInvalidTag(data);
            reloadTotal()
          });
      }
    }

    // Calcular cantidad validados
    reloadTotal();
  };

  const reloadTotal = () => {
    let cant = 0;
    globalStock.forEach((i) => {
      i.tags.forEach((t) => {
        if ((t.selected) && (t.message === null)) cant++;
      });
    });
    setValidatedItems(cant);
    reorderStock();
    setInvalidTagsCount(getTotalWrongTags());
  }

  /**
   * reordenación del stock para mostrarlos así
   * 1) Auditorías incorrectas
   * 2) Auditorías correcatas
   * 3) Tags erróneos
   */
  const reorderStock = () => {
    moveNotAssociatedTagsToProduct();
    quitDuplicateWrongTagsInGlobalStock();

    const sortedStock = [...globalStock].sort((a, b) => {
      if (a.readItems === a.expectedUnits && b.readItems !== b.expectedUnits) return 1;
      if (a.expectedUnits === 0) return 1;
      if (b.expectedUnits === 0) return -1;
      if (a.readItems !== a.expectedUnits && b.readItems === b.expectedUnits) return -1;
      return 0;
    });
    setGroupedStock(sortedStock);
  }

  /**
   * quita los tags erróneos duplicados en el stock global
   */
  const quitDuplicateWrongTagsInGlobalStock = () => {
    const notWrongItems = [];
    const wrongItemsNotDuplicate = [];
    globalStock.forEach((item) => {
      if(item.expectedUnits > 0){
        notWrongItems.push(item);
      }
    });
    globalStock.forEach((item) => {
      if(item.expectedUnits === 0){
        const existingItem = wrongItemsNotDuplicate.find((newItem) => newItem.sku === item.sku);
        if (!existingItem) {
          wrongItemsNotDuplicate.push(item);
        }
      }
    });
    globalStock = [...notWrongItems, ...wrongItemsNotDuplicate];
  };

  /**
   * Mueve los items erróneos que no están asociados a ningún producto al final de la lista
   */
  const moveNotAssociatedTagsToProduct = () => {
    const newGlobalStock = [...globalStock];
    const newItems = [];

    newGlobalStock.forEach((item, index) => {
      item.tags = item.tags.filter((tag, tagIndex) => {
        if (item.expectedUnits > 0 && tag.message === tagNotAssociated) {
          const existingItem = newItems.find((newItem) => newItem.sku === item.sku);
          if (existingItem) {
            existingItem.tags.push(tag);
          } else {
            const newItem = {...item, expectedUnits: 0, readItems: 0, tags: [tag]};
            newItem.id = index + newItems.length;
            newItems.push(newItem);
          }
          return false;
        }
        return true;
      });
    });
    globalStock = [...newGlobalStock, ...newItems];
  };

  const getTotalWrongTags = () => {
    return globalStock.reduce((total, item) => {
      if (item.expectedUnits === 0) {
        return total + item.tags.filter((t) => (t.message !== null)).length;
      }
      return total;
    }, 0);
  }

  const addInvalidTag = (data) => {
    let total = 0;
    let tag = {
      tag: "",
      epc: "",
      barcode: "",
      selected: true,
      message: null,
    };
    let item = {
      title: "-- Solo Tag --",
      sku: "",
      image: null,
    };
    if (data.stock) {
      item.title = data.stock.item.name;
      item.sku = data.stock.item.sku;
      item.image = data.stock.item.image;
      tag.tag = data.stock.tag.rfid;
      tag.epc = data.stock.tag.rfid;
      tag.barcode = data.stock.tag.barcode;
      if ([].includes(data.stock.status)) {
        tag.message = i18n.t("El stock se encuentra en transición");
      } else {
        const location = data.stock.location.node.name + " | " + data.stock.location.path_name;
        tag.message = i18n.t("El stock se encuentra en ") + location;
      }
    } else if (data.tag) {
      item.sku = data.tag.sku;
      item.title = data.tag.variables["name"];
      tag.tag = data.tag.rfid;
      tag.epc = data.tag.rfid;
      tag.barcode = data.tag.barcode;
      tag.message = i18n.t(tagNotAssociated);
    } else {
      item.title = "-- Solo EPC --";
      item.sku = "NO_ASOCIADO";
      tag.tag = data.epc;
      tag.epc = data.epc;
      tag.message = i18n.t("El tag no se encuentra en el sistema");
    }

    let found = false;
    globalStock.forEach((i, index, arr) => {
      if (i.sku === item.sku) {
        i.tags.push(tag);
        if(i.expectedUnits > 0){
          i.readItems = i.tags.filter((t) => (t.selected && t.message === null)).length;
          total += i.tags.filter((t) => ((t.selected === true) && (t.message !== null))).length;
          if (i.title === "-- Solo Tag --") {
            i.title = item.title;
          }
        }else {
          i.readItems = i.tags.length;
        }
        found = true;
      }
    });

    if (!found) {
      total++;
      globalStock.push({
        title: item.title,
        sku: item.sku,
        image: item.image,
        expectedUnits: 0,
        readItems: 1,
        tags: [tag],
      });
    }
    setInvalidTagsCount(total);
    setGroupedStock(globalStock);
  };


  const downloadReport = () => {
    if (!selectedLocation || tooltipStyle == '0.5') {
      return;
    }

    const items = globalStock.map((i) => {
      return {
        sku: i.sku,
        stock: i.expectedUnits,
        readed: i.tags.filter((s) => s.selected).length,
      };
    });

    beforeSubmit();

    let loc = selectedLocation.id;
    if (selectedLocation.count > 1) {
      loc = selectedLocation.results.map((n) => n.id);
    }else{
      loc = selectedLocation.id;
    }

    StockService.downloadAuditoryReport(loc, items)
      .then((blob) => {
        afterSubmit();
        FileSaver.saveAs(blob, "reporte.xls");
      })
      .catch((error) => {
        afterSubmit();
      });
  };

  const createFilterCategory = (categories) => {
    const filterCat = {
      label: "Categorías",
      selectedOption: null,
      options: categories.map(category => {
        return {
          id: category.category_id,
          label: category.category_name,
          options: {
            label: "Productos",
            selectedOption: null,
            options: category.products.map(product => {
              return {
                id: product.id,
                label: product.name,
                sku: product.sku,
              }
            })
          }
        }
      })
    };
    return filterCat;
  }

  const loadCategoriesProducts = () => {
    if(!filterCategories) {
      beforeSubmit();
      CategoryService.categories_products()
        .then((res) => {
          const filterCat = createFilterCategory(res);
          setFilterCategories(filterCat);
          afterSubmit();
        })
        .catch((_) => {
          afterSubmit();
        }
      );
    }
  }

  const handleSelectionChange = (selected) => {
    setSelectedCombo(selected);
    loadCategoriesProducts();
    setSelectedCategories([]);
    setSelectedProducts([]);
    setFilterProductsCombo(null);
    setSearched(false);
    clearGrid();
    if(selectedCombo === selected) return;
    setShowFilters(true);
  }

  const handleSelectionChangeCategory = (e) => {
    const {
      target: { value },
    } = e;
    const newSelectedCategories = Array.isArray(value) ? value : [value];
    setSelectedCategories(newSelectedCategories);
    setFilterProductsCombo(e.options);
    setSelectedProducts([]);
    const selectedProducts = newSelectedCategories.flatMap(category => category.options.options);
    setFilterProductsCombo(selectedProducts);

  }

  const handleSelectionChangeProduct = (e: SelectChangeEvent) => {
    const {
      target: { value },
    } = e;
    const newSelectedProducts = Array.isArray(value) ? value : [value];
    setSelectedProducts(newSelectedProducts);
  }

  const handleOpen = () => {
    setMobilePanelOpened(!mobilePanelOpened);
    if(isMobile && !mobilePanelOpened){
      setShowFilters(false);
    }
  }

  const content = (
    <div className={"auditoria-de-stock-content"}>
      <div className={"auditoria-de-stock-filter-panel"}>
        <AuditoriaFilterPanel
          handleApplyProp={handleApplyFilters}
          handleDeleteProp={handleDelete}
          opened={
            mobilePanelOpened ||
            !selectedOptions ||
            selectedOptions.length === 0
          }
          handleOpen={handleOpen}
          handleSelectionChange={handleSelectionChange}
          stock={stockCountTotal}
          showFilters={showFilters}
          filterCategories={filterCategories}
          filterProducts={filterProductsCombo}
          handleSelectionChangeCategory={handleSelectionChangeCategory}
          handleSelectionChangeProduct={handleSelectionChangeProduct}
          selectedCategories={selectedCategories}
          selectedProducts={selectedProducts}
        />
      </div>
      {(!isMobile || !mobilePanelOpened) && (
        <>
          <div className={"auditoria-de-stock-reading-panel"}>
            <ReadingPanel
              read={validatedItemsCount()}
              total={stockCountTotal}
              handleSearch={handleTagSearch}
              handleClear={handleClear}
              enabledRead={enabledRead}
              typeRead={"STOCK"}
              refRead={""}
              receiveEvent={receiveEvent}
            />
          </div>
          <div className={"auditoria-de-stock-table"}>
            <div className="auditoria-de-stock-table-header">
              {!isMobile && originalStock.length > 0 && (
                <div className={"auditoria-de-stock-table-header-count"}>
                  Stock disponible <span>({stockCountTotal})</span>
                </div>
              )}
              {searched && originalStock.length == 0 && !isMobile && (
                <div className={"auditoria-de-stock-table-header-count"}>
                  <NotFoundItems />
                </div>
              )}
              {invalidTagsCount > 0 && (
                <div className={"auditoria-de-stock-table-header-invalid-tags"}>
                  Tags erróneos <span>({invalidTagsCount})</span>
                </div>
              )}
            </div>
            {groupedStock.length > 0 && (
              <ItemStockReadingTable
                stock={groupedStock}
                headers={headers}
                tagsInMov={tagsInMov}
              />
            )}
          </div>{" "}
        </>
      )}
      {isMobile && searched && stockCountTotal == 0  && (
        <div className={"auditoria-de-stock-table-header-count"}>
          <NotFoundItems />
        </div>
      )}
    </div>
  );

  const headerButtons = (
    <Tooltip
      content={
        <div
          className="excel-button"
          onClick={downloadReport}
          style={{
            width: "30px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            opacity: tooltipStyle,
          }}
        >
          <img style={{ height: "30px" }} src={isMobile ? excelwhite : excel} />
        </div>
      }
      text={msgTooltip}
    ></Tooltip>
  );

  return (
    <div className="auditoria-de-stock-container">
      <AdminLayout
        headerTitle={i18n.t("Auditoría de Stock")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={() => history.push("/admin/stock")}
        customButtons={headerButtons}
      ></AdminLayout>

      <ErrorModal
        open={openErrorModal}
        message={errorMessage}
        handleGoBack={(e) => {
          setOpenErrorModal(false);
        }}
        handleClose={(e) => {
          setOpenErrorModal(false);
        }}
        handleButtonClick={(e) => {
          setOpenErrorModal(false);
        }}
      />

      {loading && <Loader />}
    </div>
  );
};

export default AuditoriaDeStock;
