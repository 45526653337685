import { useEffect, useRef } from 'react';
import { AuthService } from 'services/AuthService';

const useTokenExpirationCheck = (checkIntervalMinutes) => {
  const intervalId = useRef(null);

  const recurringTask = () => {
    const accessSaved = localStorage.getItem('access_saved');
    if (accessSaved) {
      const accessSavedTime = Number(accessSaved);
      const currentTimePlusCheck = new Date().getTime() + (checkIntervalMinutes * 60 * 1000);
      const tokenExpiryTime = accessSavedTime + (AuthService.expirationTime * 1000);
      if (currentTimePlusCheck > tokenExpiryTime) {
        AuthService.resetIdle(true);
      }
    }
  };

  useEffect(() => {
    intervalId.current = setInterval(recurringTask, checkIntervalMinutes * 60 * 1000);
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, []);
};

export default useTokenExpirationCheck;