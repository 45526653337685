import React from "react";
import "./styles.scss";
import Icon from "components/MainApp/atoms/Icon/icon";
import CloseIcon from '@mui/icons-material/Close';

const MainNotification = ({ open, label, type, closeEvent }) => {
  const icon = type === "success" ? "icon-check-circle" : "";
  return (
    <div>
      {open && (
        <div className={`main-notification-container ${type}`}>
          <div className="main-notification-content-wrapper">
            {type === "success" && (
              <i className={`${icon} main-notification-icon`}></i>
            )}
            {type === "error" && (
              <div className={"main-notification-icon"}>
                <Icon name={"info"} width={30} height={30} fill={"#F55E69"} />
              </div>
            )}
            <p className="main-notification-label">{label}</p>
            <CloseIcon className="main-notification-icon-close" onClick={closeEvent}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainNotification;
