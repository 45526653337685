import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
export class MainButtonInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  render() {
    const {
      label,
      value,
      handleClick,
      required,
    } = this.props;

    return (
      <div className="main-button-input-container" onClick={handleClick}>
        {!value && <div className="main-button-input-label-placeholder">
          {label}{" "}
          {required && <span className="main-button-input-required-label">*</span>}
        </div>}

        {value && 
        <div className="main-button-input-wrapper">
          <div className="main-input-label">
                {label}{" "}
                {required && (
                  <span className="main-input-required-label">*</span>
                )}
              </div>
          <div className="main-button-input-value">{value}</div>

        </div>
          }
          
        <i className="icon icon-chevron-derecha" data-automation-id="input-button-click"></i>
      </div>
    );
  }
}

MainButtonInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default MainButtonInput;
