import React from "react";
import i18n from "i18n";
import "./styles.scss";

const ItemLocalizationSummary = ({
  title,
  sku,
  tagsQuantity,
  node,
  location,
}) => {
  return (
    <div className="item-container">
      <div className="item-title">{title} </div>
      <div className="item-sku">SKU {sku}</div>
      <div className="item-bottom">
        <div className="item-quantity">
          {tagsQuantity} {tagsQuantity > 1 ? i18n.t("Tags") : i18n.t("Tag")}
        </div>
        <div className="item-path">
          <div className="item-sucursal">
            <span>SUCURSAL</span> {node}{" "}
            <span className="item-separator">|</span>
          </div>
          <div className="item-location">{location}</div>
        </div>
      </div>
    </div>
  );
};

export default ItemLocalizationSummary;
