import React from "react";
import i18n from "i18n";
import "./styles.scss";

const Item = ({ title, sku, tagsQuantity, expectedUnits }) => {
  return (
    <div className="item-container">
      <div className="item-title">
        {title}{" "}
        {expectedUnits !== null ? `(${tagsQuantity} / ${expectedUnits})` : ""}
      </div>
      <div className="item-sku">SKU {sku}</div>
      <div className="item-quantity">
        {tagsQuantity} {tagsQuantity > 1 ? i18n.t("Tags") : i18n.t("Tag")}
      </div>
    </div>
  );
};

export default Item;
