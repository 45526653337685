class User
{
	id = null;
    username = null;
    email = null;
    is_active = null;
    first_name = null;
    last_name = null;
    is_confirmed = null;
    is_staff = null;
    account = null;
    groups = [];
    roles = [];
    language = "es";

    constructor(obj) {    
	    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    	for (var prop in obj) this[prop] = obj[prop];
  	}
    
    fullName () {
    	return `${this.first_name} ${this.last_name}`;
    }

    getTableView(){
      return {
        id: this.id,
        name: this.username,
        email: this.email,
        role: this.roles[0]?.name,
        image: this.image,
        status: this.getStatus(this.is_active, this.is_confirmed)
      }
    }

    getStatus(active, confirmed){
      if(confirmed){
        if(active){
          return 'ACT';
        } else {
          return 'INA';
        }
      } else {
        return 'PEN';
      }
    } 
}

export class Role
{
	id = null;
  name = null;
  description = '';
  permissions = null;

    constructor(obj) {    
	    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    	for (var prop in obj) this[prop] = obj[prop];
    }
    
}


export default User