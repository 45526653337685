import React from "react";
import LoadFinishedGeneral from "components/MainApp/organisms/LoadFinishedGeneral"


import "./styles.scss";

 const AddBusinessNodeSuccess = ({name, id, boldButtonFunc}) => {
 
    return (
      <LoadFinishedGeneral
        title={`Se creó el tag “${name}” de manera exitosa.`}
        boldButtonLabel={"IR AL LISTADO"}
        buttonLabel={'CREAR OTRA PLANTILLA'}
        boldButtonLink={"/admin/config_tags"}
        buttonLink={`/admin/config_tags/add`}
        boldButtonFunc={boldButtonFunc}
      > 
        <div className="add-businessnode-success-container">
            <div className="add-businessnode-success-id">ID {id}</div>
        </div>
      </LoadFinishedGeneral>
    )
  
}

export default AddBusinessNodeSuccess;