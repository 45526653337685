import React from "react";

import Step from "../../../../../../atoms/Wizard/Step";
import MainDropdown from "../../../../../../atoms/Forms/MainDropdown";

import "./styles.scss";

export const RequestOrderStep1 = ({ options, option, handleChange}) => {

    const stepContent = (
        <div className="request-order-step-one-content">
          <div className="request-order-step-one-field" data-automation-id="input-name">
            <MainDropdown
              label={"Selecciona"}
              placeholder = {"Selecciona"}
              required={true}
              id={"dest"}
              name="dest"
              value={option}
              selectedOption={option}
              options={options}
              handleChange={handleChange}
              handleSelection={handleChange}
            />
          </div>

        </div>
      );
    return (
        <div className="request-order-step-one-container">
          <Step title="Selecciona el origen del pedido" content={stepContent}></Step>
        </div>
      );
}

export default RequestOrderStep1