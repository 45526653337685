import React, {useState, useEffect} from "react";
import usePagination from "../../../../../../hooks/usePagination";

// import i18n from "i18n";
import Pagination from "../../../../atoms/Pagination"
import ProductSummaryItem from "../ProductSummaryItem"
import Icon from "../../../../atoms/Icon/icon"
import {isMobile} from "../../../../../../helpers/Mobile";
import "./styles.scss";


export const ProductSummary = ({products, edit, handleEdit, paginationSize = 3}) => {
    const [ toggleOpen, setToggleOpen ] = useState(false)
    const [totalUnits, settotalUnits] = useState(0);
    const [list, currentPage, pageSize, pageList, pageQuantity, changePageSize, changePage, changeList] = usePagination(products, 0, paginationSize);

    useEffect(() => {
        settotalUnits([...products].reduce(function(a, prod) {
          return a + Number(prod.quantity || 0) + Number(prod.units || 0);
        }, 0));
        changeList(products, 0, paginationSize);
    }, [products]);
    return(
        <div className="product-summary-wrapper">
            <div className={`product-summary-title ${edit ? 'edit' : ''}`} onClick={() => setToggleOpen(!toggleOpen)}>
                <div className={`product-summary-title-wrapper ${edit ? 'edit' : ''}`}>
                    Productos solicitados ({products.length})
                </div>
                {edit && <div className={`product-summary-edit-icon`} onClick={handleEdit}>
                    <Icon name="edit2" width={20} height={20} fill={"#3C7AF5"}/>
                </div>}
                {(isMobile && !edit) &&
                    <i className={`icon-chevron product-summary-chevron ${toggleOpen ? "open" : ""} `}></i>
                }
            </div>
            <div className={`product-summary-total-units ${edit ? 'edit' : ''}`}>
                UNIDADES ({totalUnits})
            </div>
            {(!isMobile || toggleOpen) && <div className="product-summary-products">
                {pageList.map(product => {
                    return <ProductSummaryItem product={product} stock={product.stock} units={product.units} key={product?.code ?? product?.id}/>
                })}
            </div>}
            {!isMobile && <div className="product-summary-pagination"> <Pagination pageQuantity={pageQuantity} currentPage={currentPage} pageChangeHandler={changePage} /> </div>}
        </div>
    )
}

export default ProductSummary;