import "./styles.scss";

const HandSwipeAnimation = ({
    visible = false
}) => {
    return (
         visible &&
            <div class="swipe">
                <div class="path-hand"></div>
                <div class="hand-icon"></div>
            </div>
        
    )
}


export default HandSwipeAnimation;