import React, { useState } from "react";
import Icon from "components/MainApp/atoms/Icon/icon";
import CopyIcon from "assets/images/copy.png";

import { copyToClipboard } from "helpers/utils/copy";

import "./styles.scss";

const CredentialBoxes = ({ app, copyClient = false }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <div className="credentials-wrap">
      <div className="credential">
        <div className="title-credential">Cliente ID</div>
        <div className="value-credential">
          <p className="value">{app.client_id}</p>

          {!copyClient && (
            <div className="buttons-credentials">
              <div
                className="icon"
                onClick={() => copyToClipboard(app.client_id)}
              >
                <img src={CopyIcon} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="credential">
        <div className="title-credential">Client secret</div>
        <div className="value-credential">
          <p className="value">
            {visible
              ? app.client_secret
              : "**********************************"}
          </p>

          <div className="buttons-credentials">
            <div
              className="icon"
              onClick={() => copyToClipboard(app.client_secret)}
            >
              <img src={CopyIcon} />
            </div>
            <i
              onClick={toggleVisibility}
              className="icon-ojo visibility-icon"
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CredentialBoxes;
