import React from "react";
import PropTypes from "prop-types";
import MainButton from "../../../atoms/RoundedButton";

import { Link } from "react-router-dom";

import "./styles.scss";

export class LoadFinished extends React.Component {
  render() {
    return (
      <div className="load-finished-container">
        <i className="load-finished-icon icon-iconos_succes"></i>
        <div className="load-finished-title">Carga finalizada</div>
        <div className="load-finished-subtitle">
          Los productos se han cargado exitosamente.
        </div>
        <div className="load-finished-button">
          <Link to="/admin/products/load" data-automation-id="load-more-button">
            <MainButton
              legend="AÑADIR MÁS PRODUCTOS"
              state="enabled"
            ></MainButton>
          </Link>
        </div>
        <div className="load-finished-link">
          <Link to="/admin/products">
            <div className="load-finished-link" data-automation-id="go-to-product-list">VER LISTADO DE PRODUCTOS</div>
          </Link>
        </div>
      </div>
    );
  }
}

LoadFinished.propTypes = {
  content: PropTypes.any,
  title: PropTypes.string,
  goBackFunc: PropTypes.func,
};

export default LoadFinished;
