class Item
{
	id = null;
    sku = null;
    type = null;
    name = null;
    description = null;
    image = null;

    purchase_price = null;
    weight = null;
    measure_height = null;
    measure_width = null;
    measure_depth = null;

    enabled = null;

    categories = [];
    brand = null;

    stock = 0;

    constructor(obj) {
	    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    	for (var prop in obj) this[prop] = obj[prop];
  	}
}

class Product extends Item
{
    ean = null;
    sell_price = null;

    constructor(obj) {
        super(obj);
        // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
        for (var prop in obj) this[prop] = obj[prop];
    }
}

class Container extends Item
{
    max_weight = null;
    material = null;
    volume = null;
    relations = null;

    constructor(obj) {
        super(obj);
        // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
        for (var prop in obj) this[prop] = obj[prop];
    }

    getTableView(){
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            quantity: `${this.relations.reduce((acc, current) => acc + current.quantity, 0)} ${this.type === 'BOX' ? 'Unidades' : this.relations[0].type === 'BOX' ?  'Cajas' : 'Unidades'}`,
            product: `${this.relations.map(r => r.name + " " + r.quantity).join(' | ')}`
        }
    }
}

export {
    Item,
    Product,
    Container
}
