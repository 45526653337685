import React from "react";
import MainAppGenericModal from "../../../../atoms/Modals/GenericModal";

import "./styles.scss";
export class ProductModalWrapper extends React.Component {
  render() {
    const {
      innerContent,
      open,
      buttonLegend,
      handleGoBack,
      handleDownloadTemplate,
      handleClose,
      handleClick,
      handleButtonClick,
      footer,
    } = this.props;

    const content = (
      <div className="product-modal-wrapper">
        <i
          className="product-modal-wrapper-close icon-iconos_close2"
          onClick={handleGoBack}
          data-automation-id="go-back"
        ></i>
        <i className="product-modal-wrapper-icon icon-iconos_alerta"></i>

        <div className="product-modal-wrapper-content">{innerContent}</div>
        <div
          className="product-modal-wrapper-button"
          onClick={handleButtonClick && handleButtonClick}
        >
          <label className="product-modal-wrapper-button-label">
            {buttonLegend}
            {!handleButtonClick && (
              <input
                type="file"
                name="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleClick}
              ></input>
            )}
          </label>
        </div>
        {footer && footer}
        {!footer && (
          <div className="product-modal-wrapper-footer">
            ¿Tienes dudas de cómo armar el archivo?{" "}
            <span
              className="product-modal-wrapper-span"
              onClick={handleDownloadTemplate}
              data-automation-id="download-template"
            >
              Descarga aquí{" "}
            </span>
            el ejemplo para guiarte.
          </div>
        )}
      </div>
    );
    return (
      <div className="product-modal-container">
        <MainAppGenericModal
          open={open}
          content={content}
          handleClose={handleClose}
        />
      </div>
    );
  }
}

ProductModalWrapper.propTypes = {};

export default ProductModalWrapper;
