import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";

import { UserService } from "services/UserService";
import { BusinessService } from "services/BusinessService";
import { AuthService } from "services/AuthService";

import { EventRepository } from "helpers/EventRepository";
import TwoOptionModal from "components/MainApp/atoms/Modals/TwoOptionModal";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import Toggle from "components/MainApp/atoms/Forms/Toggle";
import UserForm from "../components/UserForm";
import "./styles.scss";
import { boolean } from "@storybook/addon-knobs";

const initialUser = {
  id: "",
  name: "",
  username: "",
  email: "",
  roles: [],
  image: ""
};

const initialSelectedBusinesses = {
  business: null,
  nodes: [],
  selectedNodes: []
};

const initialSelectedRole = null;

const EditUserView = props => {
  const history = useHistory();
  const location = useLocation();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();

  const [user, setUser] = useState(initialUser);
  const [businesses, setBusinesses] = useState([]);
  const [roles, setRoles] = useState([]);
  const [id, setId] = useState();
  const [userNodes, setUserNodes] = useState();
  const [selectedBusinesses, setSelectedBusiness] = useState([
    { ...initialSelectedBusinesses }
  ]);
  const [selectedRole, setSelectedRole] = useState([
    { ...initialSelectedRole }
  ]);

  const [modalInfo, setModalInfo] = useState();
  const [enableModalOpen, setEnableModalOpen] = useState(false);

  useEffect(() => {
    if (!props.match.params.key) return;
    setBusinesses(null);
    setSelectedBusiness([{ ...initialSelectedBusinesses }]);

    setRoles(null);
    setSelectedRole([{ ...initialSelectedRole }]);

    const id = props.match.params.key;
    setId(id);

    loadBusinesses();
    loadRoles();

    beforeSubmit();

    UserService.user(id)
      .then(user => {
        setUserNodes(user.account.nodes);
        afterSubmit();
        let _user = { ...user };
        _user.roles = _user.roles[0];

        loadSelectedRole(_user.roles);
        setUser(_user);
      })
      .catch(error => {
        afterSubmit();
      });
  }, []);

  useEffect(() => {
    if (!userNodes || !businesses) return;
    let nodes = [...userNodes];
    let selectedBusinessId = nodes
      .filter(
        (node, index, nodes) =>
          index === nodes.findIndex(e => e.business_id === node.business_id)
      )
      .map(n => n.business_id);
    loadSelectedBusinesses(
      selectedBusinessId,
      nodes.map(n => n.code)
    );
  }, [userNodes, businesses]);

  const loadBusinesses = () => {
    BusinessService.businessesNoPage({ no_page: 1 }).then(response => {
      let businesses = response
        .map(b => {
          return {
            ...b,
            value: b.name
          };
        })
        .sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (labelA > labelB) return 1;
          if (labelA < labelB) return -1;
          return 0;
        });
      setBusinesses(businesses);
    });
  };

  const loadSelectedBusinesses = (businessIdsSelected, selectedNodesIds) => {
    const _selectedBusinesses = businessIdsSelected.map(id => {
      const nodes = nodesForBusiness(id);
      return {
        business: businesses.find(b => b.id === id),
        selectedNodes: nodes.filter(n => selectedNodesIds.includes(n.code)),
        nodes
      };
    });

    setSelectedBusiness(_selectedBusinesses);
  };

  const nodesForBusiness = businessId => {
    const nodes = AuthService.currentUserValue()
      .account.nodes.map(n => {
        return { ...n, label: n.name, value: n.name, id: n.code };
      })
      .sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA > labelB) return 1;
        if (labelA < labelB) return -1;
        return 0;
      });

    return nodes.filter(n => n.business_id == businessId);
  };

  const loadRoles = async () => {
    UserService.roles().then(response => {
      let roles = response.results.map(r => {
        return { ...r, label: r.name, value: r.name, id: r.id };
      });
      setRoles(roles);
    });
  };

  const loadSelectedRole = async role => {
    const _selectedRole = {
      ...role,
      label: role.name,
      value: role.name,
      id: role.id
    };
    setSelectedRole(_selectedRole);
  };

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error"
      });
    }
  };

  const handleCreate = e => {
    e.preventDefault();
    beforeSubmit();
    let _user = { ...user };
    _user.roles_id = [_user.roles.id];

    let nodes = [];
    selectedBusinesses.map(sb => {
      nodes.push(...sb.selectedNodes);
    });
    _user.nodes_id = nodes.map(node => node.code);
    let formData = new FormData();

    formData.append("id", _user.id);
    formData.append("name", _user.name);
    formData.append("username", _user.username);
    formData.append("email", _user.email);
    formData.append("roles", _user.roles);
    formData.append("is_active", _user.is_active);
    formData.append("image", _user.image);

    UserService.updateUser(user.id, _user)
      .then(response => {
        afterSubmit();
        history.push("/admin/users");
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const handleGoBack = () => {
    history.push("/admin/users");
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let _user = { ...user };
    _user[name] = value;

    setUser(_user);
  };

  const validateContinue = () => {
    return true;
  };

  const handleDeleteImg = e => {
    let _user = { ...user };
    _user["image"] = null;
    setUser(_user);
  };

  const handleChangeImg = e => {
    let _user = { ...user };
    if (e.target.type !== "file") {
      _user.image = e.target.value;
    } else {
      _user.image = e.target.files[0];
    }
    setUser(_user);
  };

  const handleSelectRole = event => {
    let _user = { ...user };
    const _role = event.target.value;
    _user.roles = _role;
    setUser(_user);
    setSelectedRole(_role);
  };

  const handleChangeBusiness = (e, position) => {
    const business = e.target.value;
    let _sbs = [...selectedBusinesses];
    _sbs[position].business = business;

    _sbs[position].nodes = nodesForBusiness(business.id);
    _sbs[position].selectedNodes = [];
    setSelectedBusiness(_sbs);
  };

  const handleChangeNodes = (e, position) => {
    let _sbs = [...selectedBusinesses];

    _sbs[position].selectedNodes = e.target.value;
    setSelectedBusiness(_sbs);
  };

  const handleAddBusiness = () => {
    let _sbs = [...selectedBusinesses, { ...initialSelectedBusinesses }];
    setSelectedBusiness(_sbs);
  };

  const handleEnable = () => {
    setEnableModalOpen(false);
    let e = { target: { name: String, value: boolean } };
    e.target.name = "is_active";
    e.target.value = true;
    handleChange(e);
  };

  const handleDisable = () => {
    setEnableModalOpen(false);
    let e = { target: { name: String, value: boolean } };
    e.target.name = "is_active";
    e.target.value = false;
    handleChange(e);
  };

  const handleToggleModal = e => {
    let _modalInfo;
    if (user.is_active) {
      _modalInfo = disableModal;
    } else {
      _modalInfo = enableModal;
    }
    setModalInfo(_modalInfo);
    setEnableModalOpen(true);
  };

  const disableModal = {
    title: "¿Quieres inhabilitar este usuario?",
    subtitle: (
      <div>
        Al inhabilitarlo,{" "}
        <span style={{ fontFamily: "Ubuntu-Medium" }}>
          no podrás realizar operaciones.
        </span>
      </div>
    ),
    closeLabel: "CANCELAR",
    confirmLabel: "INHABILITAR",
    handleClose: () => setEnableModalOpen(false),
    handleConfirm: handleDisable
  };

  const enableModal = {
    title: "¿Quieres habilitar este usuario?",
    subtitle: (
      <div>
        Al habilitarlo podrás{" "}
        <span style={{ fontFamily: "Ubuntu-Medium" }}>
          realizar operaciones.
        </span>
      </div>
    ),
    closeLabel: "CANCELAR",
    confirmLabel: "HABILITAR",
    handleClose: () => setEnableModalOpen(false),
    handleConfirm: handleEnable
  };

  const headerOptions = [];
  const content = (
    <div className={"edit-user-content-wrapper"}>
      <div className={"edit-user-inner"}>
        <div
          className="disable-user-container"
          data-automation-id="disable-user-container"
        >
          <p className="disable-user-label">
            Usuario{" "}
            <span className="disable-user-label-span">
              {user?.is_active ? "habilitado" : "inhabilitado"}
            </span>{" "}
            para realizar operaciones
          </p>
          <div className="disable-user-toggle">
            <Toggle
              handleChange={handleToggleModal}
              checked={user?.is_active}
              name={"is_active"}
            ></Toggle>
          </div>
        </div>

        <UserForm
          errors={errors}
          user={user}
          handleSelectRole={handleSelectRole}
          handleChange={handleChange}
          handleSelectImage={handleChangeImg}
          handleDeleteImage={handleDeleteImg}
          handleSubmit={handleCreate}
          continueEnabled={validateContinue}
          mode={"edit"}
          businesses={businesses || []}
          roles={roles || []}
          selectedRole={selectedRole}
          handleChangeBusiness={handleChangeBusiness}
          selectedBusinesses={selectedBusinesses || []}
          handleChangeNodes={handleChangeNodes}
          handleAddBusiness={handleAddBusiness}
        />
      </div>
    </div>
  );
  return (
    <div className="edit-user-container">
      <AdminLayout
        headerTitle={i18n.t(`Detalle del usuario`)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      <TwoOptionModal open={enableModalOpen} {...modalInfo}></TwoOptionModal>
      {loading && <Loader />}
    </div>
  );
};

export default EditUserView;
