import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useRequest from "hooks/useRequest";

import i18n from "i18n";
import { ClientService } from "services/ClientService";
import { PublicService } from "services/PublicService";

import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";

import Panel from "components/MainApp/organisms/TogglePanel";
import Toggle from "components/MainApp/atoms/Forms/Toggle";

import "./styles.scss";

const ClientDetail = (props) => {
  const history = useHistory();
  const [deleteErrorModal, setdeleteErrorModal] = useState(false);
  const [deleteErrorMsg, setdeleteErrorMsg] = useState("");
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [client, setClient] = useState(null);

  useEffect(() => {
    if (!props.match.params.key) return;
    const id = props.match.params.key;

    beforeSubmit();
    ClientService.client(id).then((client) => {
      let countries;
      let regions;
      let states;
      let localities;

      let countriesPromises = PublicService.countries();
      let regionsPromises = PublicService.regions(client.country);
      let statesPromises = PublicService.states(client.country, client.region);
      let citiesPromises = PublicService.cities(
        client.country,
        client.region,
        client.state
      );

      Promise.all([
        countriesPromises,
        regionsPromises,
        statesPromises,
        citiesPromises,
      ]).then((responses) => {
        afterSubmit();

        countries = responses[0];
        regions = responses[1];
        states = responses[2];
        localities = responses[3];

        client.country = countries.find((c) => c.value === client.country);
        client.locality = localities.find((c) => c.value === client.locality);
        client.region = regions.find((c) => c.value === client.region);
        client.state = states.find((c) => c.value === client.state);

        setClient(client);
      });
    });
  }, []);

  const deleteClient = () => {
    beforeSubmit();
    ClientService.deleteClient(client.id)
      .then((rta) => {
        history.push(`/admin/clients`);
        afterSubmit();
      })
      .catch((error) => {
        afterSubmit();
        setdeleteErrorModal(true);
        setdeleteErrorMsg(i18n.t("No se puede eliminar el cliente"));
      });
  };

  const handleChangeEnabled = (e) => {
    e.preventDefault();
    beforeSubmit();

    const data = { enabled: !client.enabled };
    ClientService.updateClient(props.match.params.key, data)
      .then((client) => {
        afterSubmit();
        setClient(client);
        EventRepository.notificationSend({
          label: i18n.t(
            `Tu cliente se ${
              client.enabled ? "habilitó" : "deshabilitó"
            } de manera exitosa.`
          ),
          type: "success",
        });
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }
  };

  const headerOptions = [
    {
      icon: "icon-iconos_editar",
      name: "editar",
      handler: () => {
        history.push({
          pathname: `/admin/clients/edit/${client.id}`,
          state: {
            type: "client",
          },
        });
      },
      tooltip: i18n.t("Editar Cliente"),
    },
  ];

  const handleGoBack = () => {
    history.push(`/admin/clients`);
  };

  const content = (
    <div className={"client-detail-content-wrapper"}>
      <div className={"edit-client-toggle"}>
        <p>
          Cliente{" "}
          <span>{client?.enabled ? "habilitado" : "deshabilitado"}</span> para
          realizar operaciones.
        </p>
        <Toggle
          checked={client && client.enabled}
          handleChange={handleChangeEnabled}
          name="enabled"
        />
      </div>

      {client && (
        <div className={"client-detail-panels"}>
          <Panel
            fields={client && client.getDescriptionPanelView()}
            title={i18n.t("Descripción")}
          />
          <Panel
            fields={client && client.getContactPanelView()}
            title={i18n.t("Contacto")}
          />
        </div>
      )}
    </div>
  );
  return (
    <div className="client-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Detalle de cliente")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      <GenericErrorModal
        open={deleteErrorModal}
        handleClose={() => setdeleteErrorModal(false)}
        error={deleteErrorMsg}
      ></GenericErrorModal>

      {loading && <Loader />}
    </div>
  );
};

export default ClientDetail;
