import React from "react";
import Search from "../../../atoms/Filters/Search";
import DropdownFilter from "../../../atoms/Filters/DropdownFilter";
import TwoOptionFilter from "../../../atoms/Filters/TwoOptionFilter";
import DateInputFilter from "components/MainApp/atoms/Filters/DateInputFilter"

import "./styles.scss";
import { isMobile } from "helpers/Mobile";

const inputTypes = {
  'dropdown': DropdownFilter,
  'twoOption': TwoOptionFilter,
  'dateRange': DateInputFilter,
}

export class FilterPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      edit: false,
      buttonEnabled: false,
      filters: this.props.filters,
      selectedFiltersAll: this.props.selectedFiltersAll,
      selectedFiltersByType: this.props.selectedFiltersByType,
      appliedFilters: {
        filters: this.props.filters,
        selectedFiltersAll: this.props.selectedFiltersAll,
        selectedFiltersByType: this.props.selectedFiltersByType,
      },
    };
  }

  handleOpenPanel = () => {
    const open = !this.state.open;
    this.setState({
      open,
    });
  };

  handleSelection = (option, operation, type) => {
    if (operation === "deleteAll") {
      this.deleteAll(type);
      return;
    }

    let selectedFiltersAll = [...this.state.selectedFiltersAll];
    let selectedFiltersByType = { ...this.state.selectedFiltersByType };



    if (operation === "add") {
      option.type = type;
      selectedFiltersAll = [...selectedFiltersAll, option];
      selectedFiltersByType[type] = selectedFiltersByType[type]
        ? [...selectedFiltersByType[type], option]
        : [option];
    } else {
      if(operation === "replace") {
        option.type = type;

        selectedFiltersAll = selectedFiltersAll.filter(s => s.type !== option.type)
        selectedFiltersAll = [...selectedFiltersAll, option];
        selectedFiltersByType[type] = [option];

      }
      else{
        selectedFiltersAll = this.state.selectedFiltersAll.filter((s) => {
          return s.label !== option.label;
        });

        selectedFiltersByType[type] = this.state.selectedFiltersByType[
          type
        ].filter((s) => {
          return s.label !== option.label;
        });
      }

    }

    this.setState({
      selectedFiltersAll,
      selectedFiltersByType,
      buttonEnabled: true,
    });
  };

  handleApply = () => {
    if (!this.state.buttonEnabled) return;
    this.setState({
      open: false,
      buttonEnabled: false,
    });
    //handler externo

    this.props.handleApplyFilters(
      this.state.selectedFiltersByType,
      this.state.selectedFiltersAll
    );
  };

  deleteAll = (type = null) => {
    if (type) {
      this.deleteFromType(type);
      return;
    }
    let filters = [...this.state.filters];
    filters = filters.map((f) => {
      if (f?.options){
        f.options = f.options.map((o) => {
          o.selected = false;
          return o;
        });
      }

      return f;
    });

    this.setState({
      filters: filters,
      selectedFiltersAll: [],
      selectedFiltersByType: [],
      buttonEnabled: true,
    });
  };

  deleteFromType = (type) => {
    let filters = [...this.state.filters];
    filters = filters.map((f) => {
      f.options = f.options.map((o) => {
        if (f.label === type) {
          o.selected = false;
        }
        return o;
      });
      return f;
    });

    let selectedFiltersAll = [...this.state.selectedFiltersAll];
    selectedFiltersAll = selectedFiltersAll.filter((o) => {
      return o.type !== type;
    });

    let selectedFiltersByType = { ...this.state.selectedFiltersByType };
    selectedFiltersByType[type] = [];

    this.setState({
      filters: filters,
      selectedFiltersAll: selectedFiltersAll,
      selectedFiltersByType: selectedFiltersByType,
      buttonEnabled: true,
    });
  };

  handleDelete = () => {
    this.deleteAll();

    this.setState({
      open: false,
      buttonEnabled: false,
    });

    this.props.handleApplyFilters([], []);
  };

  handleDeletePill = (option) => {
    // let selectedItem = e.target.dataset.option;

    let type = option.type;
    let selectedFiltersByType = {
      ...this.state.selectedFiltersByType,
    };
    selectedFiltersByType[type] = selectedFiltersByType[type].filter((f) => {
      return f.label !== option.label;
    });

    let selectedFiltersAll = [...this.state.selectedFiltersAll];
    selectedFiltersAll = selectedFiltersAll.filter((f) => {
      return f.label !== option.label;
    });

    this.setState({
      open: false,
      buttonEnabled: false,
      filters: this.state.filters,
      selectedFiltersAll: selectedFiltersAll,
      selectedFiltersByType: selectedFiltersByType,
    });

    this.props.handleApplyFilters(selectedFiltersByType, selectedFiltersAll);
  };

  handleDeletePillMobile = (option) => {
    // let selectedItem = e.target.dataset.option;

    let type = option.type;
    let selectedFiltersByType = {
      ...this.state.selectedFiltersByType,
    };

    if(type === 'date'){
      selectedFiltersByType[type].filter((f) => {
        return f.label !== option.label;
      });
    }
    selectedFiltersByType[type] = selectedFiltersByType[type].filter((f) => {
      return f.label !== option.label;
    });

    let selectedFiltersAll = [...this.state.selectedFiltersAll];
    selectedFiltersAll = selectedFiltersAll.filter((f) => {
      return f.label !== option.label;
    });

    this.setState({
      open: false,
      filters: this.state.filters,
      selectedFiltersAll: selectedFiltersAll,
      selectedFiltersByType: selectedFiltersByType,
    });

    this.props.handleApplyFilters(selectedFiltersByType, selectedFiltersAll);

  };

  render() {
    const {
      resultList,
      placeholder,
      searchValue = "",
      handleSubmitSearch,
      handleSubmitSearchKey,
      // filters,
      closeMobilePanel,
      filterProducts,
    } = this.props;
    return (
      <div className="filter-panel-container">
        <div className="filter-panel-wrapper" data-automation-id="filter-panel">
          {!isMobile && (
            <React.Fragment>
              <Search
                resultList={resultList}
                placeholder={placeholder}
                value={searchValue}
                handleSubmit={handleSubmitSearch}
                handleSubmitKey={handleSubmitSearchKey}
                filterProducts={filterProducts}
              ></Search>
              <button
                className="filter-panels-button"
                data-automation-id="open-filters"
                onClick={this.handleOpenPanel}
              >
                <i
                  className={`${
                    this.state.open ? "icon-iconos_menos" : "icon-iconos_mas"
                  } filter-panel-icon`}
                ></i>
                Filtros
              </button>
            </React.Fragment>
          )}

          {isMobile && (
            <div className="filter-panel-mobile-title">
              <p className="filter-panel-mobile-title-text">
                Filtra tu búsqueda
              </p>
              <i
                className="icon-iconos_close2 filter-panel-mobile-close"
                onClick={closeMobilePanel}
              ></i>
            </div>
          )}
        </div>
        {!this.state.open &&
          !isMobile &&
          this.props.selectedFiltersAll?.length > 0 && (
            <React.Fragment>
              {isMobile && (
                <p className="filter-panels-pills-title">Filtros aplicados</p>
              )}

              <div className="filter-panels-pills">
                {this.props.selectedFiltersAll.map((s) => {
                  return (
                    <div
                      className="filter-panels-pill"
                      onClick={() => this.handleDeletePill(s)}
                      key={s.label}
                    >
                      <span>{s.label}</span>
                      <i className="icon-iconos_close2"></i>
                    </div>
                  );
                })}
                {!isMobile && (
                  <div
                    className="filter-panel-pills-delete-all"
                    onClick={this.handleDelete}
                  >
                    BORRAR TODO
                  </div>
                )}
              </div>
            </React.Fragment>
          )}

        {!this.state.open &&
          isMobile &&
          this.state.selectedFiltersAll?.length > 0 && (
            <React.Fragment>
              {isMobile && (
                <p className="filter-panels-pills-title">Filtros aplicados</p>
              )}

              <div className="filter-panels-pills">
                {this.state.selectedFiltersAll.map((s) => {
                  return (
                    <div
                      className="filter-panels-pill"
                      onClick={() => this.handleDeletePillMobile(s)}
                      key={s.label}
                    >
                      <span>{s.label}</span>
                      <i className="icon-iconos_close2"></i>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}

        {(this.state.open || isMobile) && (
          <div className="filter-panel-opened">
            <div className="filter-panel-filter-wrapper">
              {this.props.filters
                .filter((f) => f.enabled !== false)
                .map((filter) => {
                  let FilterComponent = inputTypes[filter.type]
                  return (
                    <div className="filter-panel-filter" key={filter.label}>
                      <FilterComponent
                        label={filter.label}
                        options={filter.options}
                        selectedOptions={
                          this.state.selectedFiltersByType[filter.label]
                        }
                        firstOptionLabel={filter.firstOptionLabel}
                        handleSelection={this.handleSelection}
                        name={filter.name}
                      ></FilterComponent>
                    </div>
                  );
                })}
            </div>

            <div className="filter-panel-filter-buttons">
              <button
                className={`filter-panel-filter-apply ${
                  this.state.buttonEnabled ? "" : "disabled"
                }`}
                onClick={this.handleApply}
              >
                APLICAR
              </button>
              <button
                className="filter-panel-filter-delete"
                onClick={this.handleDelete}
              >
                BORRAR
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FilterPanel;
