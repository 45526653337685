import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tippy";
import { isMobile } from "helpers/Mobile";
import Icon from "components/MainApp/atoms/Icon/icon.jsx";
import CopyIcon from "assets/images/copy.png";

import { copyToClipboard } from "helpers/utils/copy";

import "./styles.scss";

const tooltipStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
};

const statusMap = {
  INT: "Integrado",
  NOT: "No Integrado",
  PEN: "Pendiente de Integración",
};

const AppTableItem = ({ app, handleClick }) => {
  const columns = ["logo", "name", "client_id", "client_secret", "status"];

  const copyValue = (value) => {};

  const renderPill = (status) => {
    return (
      <div className="app-table-pill-container">
        <div className={`app-table-pill ${status.toLowerCase()}`}>
          <p>{statusMap[status]}</p>
        </div>
      </div>
    );
  };

  const renderColumn = (key) => {
    return (
      <div
        className={`app-table-item-${key} app-table-item`}
        data-app-id={key}
        onClick={() => handleClick(app.id)}
        key={key}
      >
        <Tooltip
          html={
            <div style={tooltipStyle}>
              {key === "status" ? statusMap[app[key]] : app[key]}
            </div>
          }
          followCursor={true}
          position="left"
          offset={25}
          disabled={["logo", "client_secret"].includes(key)}
        >
          {key === "logo" && <img src={app.logo} />}
          {key === "name" && <p>{app[key]}</p>}
          {key === "client_id" && <p>{app[key]}</p>}
          {key === "client_secret" && <p>**********************************</p>}
          {key === "status" && <>{renderPill(app[key])}</>}
        </Tooltip>
        {/* {key === "client_id" && <div className="icon"><Icon name="copy" heigth={20} width={20} fill={"#3C7AF5"} /></div> }
        {key === "client_secret" && <div className="icon"><Icon name="copy" heigth={20} width={20} fill={"#3C7AF5"} /></div> } */}

        {key === "client_id" && (
          <div
            className="icon"
            onClick={(e) => {
              e.stopPropagation();
              copyToClipboard(app[key]);
            }}
          >
            <img src={CopyIcon} />
          </div>
        )}
        {key === "client_secret" && (
          <div
            className="icon"
            onClick={(e) => {
              e.stopPropagation();
              copyToClipboard(app[key]);
            }}
          >
            <img src={CopyIcon} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="app-table-item-wrapper"
      data-automation-id="app-item-wrapper"
    >
      {!isMobile && (
        <div className="app-table-item-container" data-automation-id="app-item">
          {columns.map((column_key) => {
            return renderColumn(column_key);
          })}

          <div
            className={`app-table-item-scopes app-table-item`}
            data-automation-id="app-scopes"
            data-app-id={app.id}
            onClick={() => handleClick(app.id)}
            key={"button"}
          >
            <Icon name="edit2" heigth={20} width={20} fill={"#3C7AF5"} />
          </div>
          <div
            className={`app-table-item-button app-table-item`}
            data-automation-id="app-button"
            data-app-id={app.id}
            onClick={() => handleClick(app.id)}
            key={"button-2"}
          >
            <i className="icon-chevron"></i>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="app-table-item-container-mobile"  onClick={() => handleClick(app.id)} >
          <div className="app-table-item-row-mobile">
            <div className="app-logo">
              <img src={app.logo} />
            </div>
            <div className="app-pill">{renderPill(app.status)}</div>
          </div>
          <div className="app-table-item-row-mobile">
            <div className="app-name">{app.name}</div>
            <div className="app-edit">
              <div className="icon" onClick={() => handleClick(app.id)} >
                <Icon name="edit2" heigth={15} width={15} fill={"#3C7AF5"} />
              </div>
            </div>
          </div>
          <div className="app-table-item-row-mobile">
            <div className="app-client_id app-table-item-col">
              <p className="title">Client Id</p>
              <p className="value">{app.client_id}</p>
            </div>
            <div className="app-copy">
              <img
                src={CopyIcon} 
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(app.client_id);
                }}
              />
            </div>
          </div>
          <div className="app-table-item-row-mobile">
            <div className="app-client-secret app-table-item-col">
              <p className="title">Client Secret</p>
              <p className="value">**********************************</p>
            </div>
            <div className="app-copy">
            <img
                src={CopyIcon} 
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(app.client_secret);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppTableItem;
