import React, { useState } from "react";
import ItemLongSummaryItem from "components/MainApp/organisms/OrdersV2/Items/ItemLongSummary/ItemLongSummaryItem";

import "./styles.scss";

const ProductAddedItemLocation = ({ product, locations, handleDelete }) => {
  return (
    <div className="product-location-result-search-item-container">
      <div className="product-location-result-search-item-wrapper">
        <div
          //   onClick={handleClick}
          className="product-location-result-search-item"
        >
          <ItemLongSummaryItem item={product} />
        </div>
        <div className="product-location-result-search-item-right-col">
          <div className="product-location-list">
            {locations
              ?.filter((loc) => loc.unitsSelected > 0)
              .map((location) => {
                return (
                  <div
                    className="product-location-selected"
                    key={location.location_id}
                  >
                    <p>
                      {location.location_label}{" "}
                      <span>{location.unitsSelected} UNID.</span>
                      <i
                        className="icon-iconos_eliminar"
                        onClick={() => handleDelete(product.id, location)}
                      />
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="product-location-result-search-item-divider"></div>
    </div>
  );
};

export default ProductAddedItemLocation;
