import React from "react";

import Step from "components/MainApp/atoms/Wizard/Step";
import ProductAddedListLocation from "components/MainApp/organisms/Orders/RequestOrders/ProductAddedListLocation";
import SearchWithComponentsLocations from "components/MainApp/atoms/Filters/SearchWithComponentsLocations";

import "./styles.scss";

export const RequestOrderStep2 = ({
  resultList,
  searchValue,
  handleChange,
  products,
  totalProducts,
  goBackFunc,
  handleSelectToAddProduct,
  handleChangedLocations,
  handleAdd,
  handleAddProduct,
  handleDeleteAddedProduct,
  selectedProducts,
  changePage,
  pagination,
  filter,
}) => {

  const filterProducts = (value) => {
    return filter(value);
  }

  const stepContent = (
    <div className="request-order-step-two-content">
      <div
        className="request-order-step-two-field"
        data-automation-id="input-search"
      >
        <SearchWithComponentsLocations
          placeholder={"Busca por código o nombre"}
          resultList={resultList}
          value={searchValue}
          handleChange={handleChange}
          handleSelectToAddProduct={handleSelectToAddProduct}
          handleChangedLocations={handleChangedLocations}
          handleAdd={handleAdd}
          selectedProducts={selectedProducts}
          onlyStockHidden={true}
          filterProducts={filterProducts}
        />

        {products.length > 0 && (
          <ProductAddedListLocation
            total={totalProducts}
            products={products}
            handleDelete={handleDeleteAddedProduct}
            changePage={changePage}
            pagination={pagination}
          ></ProductAddedListLocation>
        )}
      </div>
    </div>
  );
  return (
    <div className="request-order-step-two-container">
      <Step
        goBackFunc={goBackFunc}
        title="¿Qué productos deseas reponer?"
        content={stepContent}
      ></Step>
    </div>
  );
};

export default RequestOrderStep2;
