const columns = [
    {
      name: 'name',
      pill: false,
      title: 'NOMBRE',
      mobileTag: false,
    },
    {
      name: 'description',
      pill: false,
      title: 'DESCRIPCIÓN',
      mobileTag: 'Descripción:',
  
    },
  ];

  export default columns