import React from "react";
import TwoOptionModal from "components/MainApp/atoms/Modals/TwoOptionModal";

const EditExitModal = ({ open, handleClose, handleConfirm }) => {
  const title = "¿Quieres guardar la edición del proveedor antes de salir?";
  const subtitle = "Si no lo guardas, se perderán los cambios.";

  return (
    <TwoOptionModal
      open={open}
      title={title}
      subtitle={subtitle}
      closeLabel="NO GUARDAR"
      confirmLabel="GUARDAR"
      handleClose={handleClose}
      handleConfirm={handleConfirm}
    />
  );
};

export default EditExitModal;
