/* eslint-disable eqeqeq */
import FileSaver from "file-saver";
import i18n from "i18n";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { AuthService } from "services/AuthService";
import { LocationService } from "services/LocationService";
import { MovementService } from "services/MovementServkce";
import { OrderService } from "services/OrderService";
import { TagService } from "services/TagService";

import { EventRepository } from "helpers/EventRepository";
import { WSService } from "services/WSServices";

import useRequest from "hooks/useRequest";
import { StockService } from "services/StockService";
import { stockByItem } from "helpers/stock/StockHelper";

import AdminLayout from "../../../../../components/MainApp/layouts/DesktopLayout";
import "./styles.scss";

import RoundedButton from "../../../../../components/MainApp/atoms/RoundedButton";
import Wizard from "../../../../../components/MainApp/atoms/Wizard/StepProgress";
import LoadFinished from "../../../../../components/MainApp/organisms/Orders/RequestOrders/LoadOrderFinished";

import StepOne from "./Steps/Step1";
import StepTwo from "./Steps/Step2";
import StepThree from "./Steps/Step3";
import StepFour from "./Steps/Step4";

import { Movement } from "models/Movements";
import { ReceptionOrder, RelocationOrder } from "models/Orders";
import { isMobile } from "helpers/Mobile";

import {
  getSelectedItems,
  itemsByItem,
  itemsByLocation
} from "helpers/orders/MovementHelper";

import { PublicService } from "services/PublicService";
import data from "./data";
import usePagination from "hooks/usePagination";
import useAddItems from "hooks/useAddItems";
import { parseItems, parseItemsForSearch } from "helpers/orders/ItemHelper";
import Loader from "components/MainApp/atoms/Loader";

export const LoadRelocationOrderView = props => {
  const history = useHistory();

  const [activestep, setactivestep] = useState("0");
  const [order, setOrder] = useState(new RelocationOrder(data.initialOrder));
  const [selectedNode, setselectedNode] = useState(null);
  const [nodes, setnodes] = useState([]);
  const [submitted, setsubmitted] = useState(false);
  const [productResultList, setproductResultList] = useState([]);
  const [selectedToAddProductList, setselectedToAddProductList] = useState([]);
  const [addedList, setaddedList] = useState([]);

  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);

  const [
    list,
    currentPage,
    pageSize,
    pageList,
    pageQuantity,
    changePageSize,
    changePage,
    changeList
  ] = usePagination([], 0, 3);

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();

  useEffect(() => {
    if (!props.match.params.key) {
      setOrder(new RelocationOrder(data.initialOrder));
    } else {
      beforeSubmit();
      const id = props.match.params.key;
      OrderService.relocationOrder(id)
        .then(order => {
          afterSubmit();
          setOrder(new RelocationOrder(order));
          setOrigin({ ...order.origin, label: order.origin.name });
          setDestination({
            ...order.destination,
            label: order.destination.name
          });
          getItems(order.id, order.origin?.code);
        })
        .catch(error => {
          afterSubmit();
        });
    }
  }, []);

  useEffect(() => {
    PublicService.nodes().then(response => {
      const userNodes = AuthService.currentUserValue().account.nodes.map(
        node => {
          return node.code;
        }
      );

      let newNodes = response
        .map(node => {
          if (userNodes.includes(node.code)) {
            node.label = node.name;
            return node;
          }
        })
        .filter(function(element) {
          return element !== undefined && element !== null;
        });
      setnodes(newNodes);
    });
  }, []);

  useEffect(() => {
    if (!props.match.params.key) return;
    const id = props.match.params.key;
    OrderService.getItems(id).then(response => {
      let addedListNew = response.map(item => {
        let newItem;
        newItem = item.item;
        newItem.units = item.quantity;
        newItem.code = item.item.sku;

        newItem.stock = 1;
        return item.item;
      });
      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
    });
  }, []);

  const getItems = (orderId, origin_node_id)=> {
    OrderService.getItems(orderId).then(response => {
      let addedListNew = parseItems(response);
      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
      getStockItems(origin_node_id);
    });
  };

  const handleChange = e => {
    let name = e.target.name;
    let newOrder = { ...order };
    newOrder[name] = e.target.value;
    setOrder(newOrder);
  };

  const stepChangeHandler = step => {
    setactivestep(step);
  };

  const step0IsOk = () => {
    return origin !== null;
  };

  const step1IsOk = () => {
    return addedList.length > 0;
  };

  const step2IsOk = () => {
    return destination !== null;
  };

  const nextStep = e => {
    e.preventDefault();
    const func = {
      "0": e => stepOneSend(e),
      "1": e => stepTwoSend(e),
      "2": e => stepThreeSend(e),
      "3": e => handleSubmit(e)
    };
    func[activestep](e);
  };

  const stepOneSend = e => {
    e.preventDefault();
    const data = order.dataToSend();
    beforeSubmit();
    if (order.id) {
      OrderService.updateOrder(order.id, data)
        .then(order => {
          afterSubmit();
          setOrder(new RelocationOrder(order));
          stepChangeHandler("1");
        })
        .catch(error => {
          afterSubmit();
          dealWithError(error, "generic.error");
        });
    } else {
      data.destination = AuthService.getCurrentNodeCode();
      OrderService.createOrder(data)
        .then(order => {
          afterSubmit();
          setOrder(new RelocationOrder(order));
          stepChangeHandler("1");
        })
        .catch(error => {
          afterSubmit();
          dealWithError(error, "generic.error");
        });
    }
  };

  const stepTwoSend = e => {
    e.preventDefault();
    const items = [...addedList].map(item => {
      return {
        item_id: item.id,
        quantity: item.units
      };
    });
    OrderService.addItems(order.id, items).then(response => {
      stepChangeHandler("2");
    });
  };

  const stepThreeSend = e => {
    e.preventDefault();
    const data = order.dataToSend();
    beforeSubmit();
    if (order.id) {
      OrderService.updateOrder(order.id, data)
        .then(order => {
          afterSubmit();
          setOrder(new RelocationOrder(order));
          stepChangeHandler("3");
        })
        .catch(error => {
          afterSubmit();
          dealWithError(error, "generic.error");
        });
    } else {
      OrderService.createOrder(data)
        .then(order => {
          afterSubmit();
          setOrder(new RelocationOrder(order));
          stepChangeHandler("3");
        })
        .catch(error => {
          afterSubmit();
          dealWithError(error, "generic.error");
        });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setsubmitted(true);
    OrderService.changeOrderStatus(order.id, "PEN", "Creation finished")
      .then(response => {
        // setorder(response)
        setactivestep("finish");
        setsubmitted(false);
      })
      .catch(error => {
        setsubmitted(false);
        dealWithError(error, "generic.error");
      });
  };

  const getStockItems = (selectedNodeId) => {
    if(selectedNodeId !== null) {
      beforeSubmit();
      StockService.stock({
        no_page: 1,
        status: "LOC",
        location__node: selectedNodeId,
      })
        .then((res) => {
          const _groupedStock = stockByItem(res);
          const _productResultList = _groupedStock.map((item) => {
            return {
              id: item.id,
              sku: item.sku,
              name: item.title,
              stock: item.tags?.length ?? 0,
              units: 1,
            };
          });

          setproductResultList(_productResultList);
          afterSubmit();
        })
        .catch((_) => {
          afterSubmit();
        });
    }
  }

  const handleSelectOrigin = selected => {
    setOrigin(selected);
    getStockItems(selected?.id);
    let _order = { ...order };
    _order.origin = selected;
    setOrder(new RelocationOrder(_order));
  };

  const handleSelectDestination = selected => {
    setDestination(selected);
    let _order = { ...order };
    _order.destination = selected;
    setOrder(new RelocationOrder(_order));
  };

  const goBackFunc = () => {
    history.push("/admin/orders/relocation");
  };

  const restart = () => {
    setactivestep("0");
    setOrder(initialOrder);
    setOrigin(null);
    setDestination(null);
  };

  const downloadVoucher = () => {
    setsubmitted(true);
    OrderService.relocationDownloadVoucher(order.id)
      .then(blob => {
        setsubmitted(false);
        FileSaver.saveAs(blob, "comprobante.pdf");
      })
      .catch(error => {
        setsubmitted(false);
      });
  };

  //TODO: Ver porque el hook no funciona!
  //#region For testing

  const handleSelectToAddProduct = product => {
    let selectedToAddProductListNew = [...selectedToAddProductList];
    let ind = selectedToAddProductListNew.findIndex(p => {
      return p.id === product;
    });
    if (ind !== -1) {
      selectedToAddProductListNew = selectedToAddProductListNew.filter(p => {
        return p.id !== product;
      });
    } else {
      let newProduct = productResultList.find(p => {
        return p.id === product;
      });
      newProduct.selected = true;
      selectedToAddProductListNew.push(newProduct);
    }

    setselectedToAddProductList(selectedToAddProductListNew);
  };

  const handleSelectedProductQuantityChange = (productId, number) => {
    let productResultListNew = [...productResultList];
    let productNew = productResultListNew.find(p => p.id === productId);

    if (number <= 0) {
      EventRepository.notificationSend({
        label: 'El valor no puede ser negativo o cero.',
        type: 'error'
      });
    } else {
      if (number > productNew.stock) {
        EventRepository.notificationSend({
          label: `El producto: ${productNew.name}, supera el stock disponible: ${productNew.stock}`,
          type: 'error'
        });
      } else {
        productNew.units = number;
        setproductResultList(productResultListNew);
      }
    }
  };

  const handleAdd = () => {
    let addedListNew = [...addedList];
    selectedToAddProductList.forEach(product => {
      let addedInd = addedList.findIndex(p => {
        return p.id === product.id;
      });
      if (addedInd === -1) {
        addedListNew.push({ ...product });
      } else {
        let productSelected = {
          ...productResultList.find(p => p.id === product.id)
        };
        let productAddedIndex = addedListNew.findIndex(
          p => p.id === product.id
        );
        addedListNew[productAddedIndex].units =
          Number(addedListNew[productAddedIndex].units) +
          Number(productSelected.units);
      }

      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
    });

    let productResultListNew = productResultList.map(p => {
      p.units = 1;
      return p;
    });

    setproductResultList(productResultListNew);
    setselectedToAddProductList([]);
  };

  const handleAddProduct = (productId, number) => {
    number = number === 0 ? 1 : number;

    let addedListNew = [...addedList];
    let productAddedIndex = addedListNew.findIndex(p => p.id === productId);
    addedListNew[productAddedIndex].units = Number(number);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  const handleDeleteAddedProduct = productId => {
    let addedListNew = [...addedList];

    addedListNew = addedListNew.filter(p => p.id !== productId);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  //#endregion

  const content = (
    <div className="load-relocation-order-content">
      {activestep !== "finish" ? (
        <div className="load-relocation-order-wrapper">
          <div className="load-relocation-order-wizard">
            <Wizard
              steps={data.steps}
              activeStep={activestep}
              clickEnabled={false}
            ></Wizard>
          </div>

          <div className="load-relocation-order-step">
            {activestep === "0" && (
              <form onSubmit={nextStep} className="content">
                <StepOne
                  handleChange={handleSelectOrigin}
                  options={nodes}
                  option={origin}
                />
                <div className="load-relocation-order-button">
                  <div>
                    <RoundedButton
                      legend="CONTINUAR"
                      type="submit"
                      state={step0IsOk() ? "enabled" : "disabled"}
                      handleClick={nextStep}
                    ></RoundedButton>
                  </div>
                </div>
              </form>
            )}
            {activestep === "1" && (
              <div className="content">
                <StepTwo
                  handleChange={handleChange}
                  resultList={productResultList}
                  searchValue={""}
                  products={isMobile ? addedList : pageList}
                  totalProducts={addedList.length}
                  handleSelectToAddProduct={handleSelectToAddProduct}
                  handleSelectedProductQuantityChange={
                    handleSelectedProductQuantityChange
                  }
                  handleAdd={handleAdd}
                  handleAddProduct={handleAddProduct}
                  selectedProducts={selectedToAddProductList.map(p => p.id)}
                  handleDeleteAddedProduct={handleDeleteAddedProduct}
                  changePage={changePage}
                  pagination={{
                    pageQuantity: pageQuantity,
                    currentPage: currentPage
                  }}
                />
                <div className="load-relocation-order-button">
                  <div>
                    <RoundedButton
                      legend="CONTINUAR"
                      type="submit"
                      state={step1IsOk() ? "enabled" : "disabled"}
                      handleClick={nextStep}
                    ></RoundedButton>
                  </div>
                </div>
              </div>
            )}
            {activestep === "2" && (
              <form onSubmit={nextStep} className="content">
                <StepThree
                  handleChange={handleSelectDestination}
                  options={nodes}
                  option={destination}
                />
                <div className="load-relocation-order-button">
                  <div>
                    <RoundedButton
                      legend="CONTINUAR"
                      type="submit"
                      state={step2IsOk() ? "enabled" : "disabled"}
                      handleClick={nextStep}
                    ></RoundedButton>
                  </div>
                </div>
              </form>
            )}
            {activestep === "3" && (
              <form onSubmit={nextStep} className="content">
                <StepFour
                  destination={destination.name}
                  origin={origin.name}
                  products={addedList}
                  handleEditDestination={() => stepChangeHandler("2")}
                  handleEditOrigin={() => stepChangeHandler("0")}
                  handleEditProducts={() => stepChangeHandler("1")}
                />
                <div className="load-relocation-order-button">
                  <div>
                    <RoundedButton
                      legend="CONFIRMAR"
                      type="submit"
                      state={"enabled"}
                      handleClick={nextStep}
                    ></RoundedButton>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      ) : (
        <div className="load-relocation-order-wrapper-finish">
          <div className="load-relocation-order-wrapper-finish-container">
            <LoadFinished
              orderNumber={order.code}
              boldButtonFunc={restart}
              downloadVoucher={downloadVoucher}
              buttonLink={"/admin/orders/relocation"}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="load-relocation-order-container">
      <AdminLayout
        headerTitle={"Nueva orden de reubicación"}
        headerOptions={data.headerOptions}
        content={content}
        goBackFunc={activestep !== "finish" ? goBackFunc : null}
        navHidden={true}
      ></AdminLayout>

      {(loading || submitted) && <Loader />}
    </div>
  );
};

export default LoadRelocationOrderView;
