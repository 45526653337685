import React from "react";
import onClickOutside from "react-onclickoutside";

import "./styles.scss";
import { isMobile } from "helpers/Mobile";
export class DropdownFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      open: false,
    };
  }

  handleClick = (e) => {
    const open = !this.state.open;
    this.setState({
      open,
      focused: open,
    });
  };

  handleSelect = (e) => {
    let optionLabel = e.target.dataset.optionLabel

    let selectedOptionIndex = this.props.options.findIndex((o) => {
      return o.label === optionLabel;
    });

    let selectedObject = this.props.options[selectedOptionIndex];

    this.setState({
      open: false,
      focused: false
    })
    this.props.handleSelection(selectedObject);
  };

  handleClickOutside() {
    this.setState({
      focused: false,
      open: false,
    });
  }

  // searchKey(e){
  //   const reg = new RegExp('^[A-Za-z0-9_-]*$')
  //   const letter = e.key
  //   if(letter.length === 1 && reg.test(letter)){
  //     console.log('leter')
  //     this.searchLetter(letter)
  //     this.highlightedItemPosition = this.renderTeams.findIndex(team => team.name.toLocaleLowerCase().startsWith(letter.toLocaleLowerCase()))
  //   }
  // }

  render() {
    const {
      options,
      placeholder,
      label,
      selectedOption = null,
      required,
      disabled,
      error
    } = this.props;

    return (
      <div className={ `main-dropdown-container ${disabled && 'disabled'}`}>
        <div
          className={
            this.state.focused
              ? "main-dropdown-input focused"
              : "main-dropdown-input"
          }
          onClick={disabled ? null : this.handleClick}
        >
          {selectedOption && <div className="main-dropdown-label">{label}{"  "}{required ? '*' : null}</div>}
          <div className="main-dropdown-label-inner">
            {!selectedOption && (
              <div className="main-dropdown-placeholder">
                {placeholder} {"  "}
                {required ? '*' : null}
              </div>
            )}

            {selectedOption && (
              <div className="main-dropdown-selection">
                {selectedOption.label}
              </div>
            )}
            <i
              className={`icon-chevron main-dropdown-chevron ${
                this.state.open ? "open" : ""
              } `}
            ></i>
          </div>
        </div>
        {this.state.open && (
          <div className="main-dropdown-option-container">
            <div className="main-dropdown-option-divider"></div>
            <div className="main-dropdown-option-wrapper">
              {options.map((option, index) => {
                return (
                  <div className="main-dropdown-option" key={index} onClick={this.handleSelect} data-option-label={option.label} >
                      {option.label}
                  </div>
                );
              })}
            </div>
          </div>
        )}
         <div className="main-input-error">
          {error}
        </div>
      </div>
    );
  }
}

export default onClickOutside(DropdownFilter);
