import React from "react";
import BlueSummaryItem from "../../atoms/BlueSummaryItem";
// import i18n from "i18n";
import "./styles.scss";


export const BlueSummary = ({items}) => {
    return(
        <div className="blue-summary-container">
            {items.map( item => {
                return(
                    <BlueSummaryItem number={item.number} label={item.label} key={item.label} />
                )
            })}
        </div>
    )
}

export default BlueSummary;