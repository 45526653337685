import i18n from "i18n";

const typesMapping = {
    BOX: 'Caja',
    PALLET: 'Palets',
    PRODUCT: 'Producto'
}
class ConfigTag
{
    id = null;
    template_id = null;
    name = null;
    description = null;
    height = null; 
    width = null;
    mem_epc = null;
    mem_user = null;
    variables = null;
    enabled = null;


    constructor(obj) {    
	    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    	for (var prop in obj) this[prop] = obj[prop];
      }
    
    getLocationPanelView(){
        return {
            id: this.id,
            name: this.name,
        }
    }

    getDescriptionPanelView() {
        return [
            {
                title: i18n.t('Tipo de embalaje'),
                value: typesMapping[this.type]
            },
            {
                title: i18n.t("Número de plantilla"),
                value: this.name
            },
            {
                title: i18n.t("Descripcion"),
                value: this.description
            },
        ]
    }

    getPrintPanelView() {
        return [
            {
                title: i18n.t('Alto'),
                value: this.height
            },
            {
                title: i18n.t('Ancho'),
                value: this.width
            },
            {
                title: i18n.t('Memoria EPC'),
                value: this.mem_epc
            },
            {
                title: i18n.t('Memoria Usuario'),
                value: this.mem_user
            },
        ]
    }

    getConfirmationView() {
        return [
            {
                step: i18n.t('Descripcion'),
                data: [
                    this.name,
                    this.description
                ]
            },
            {
                step: i18n.t('Medidas'),
                data: [
                    "Alto " + this.height + " inches",
                    "Ancho " + this.width + " inches"
                ]
            },
            {
                step: i18n.t('Memoria'),
                data: [
                    this.mem_epc,
                    this.mem_user
                ]
            },
        ]
    }

    getGenericTableView() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            dimensions: "Alto " + this.height + "mm | Ancho " + this.width + "mm",
            memories: `${this.mem_epc}gb | ${this.mem_user}gb`,
            type: this.type
        }
    }
   
}

class Tag 
{
    //epc
    rfid = null;
    bar_code = null;
    serial_number = null;
    sku = null;
    variables = null;
    printed = null;
    enabled = null;
    config_tag = null; 

    constructor(obj) {    
	    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    	for (var prop in obj) this[prop] = obj[prop];
      }

}
export {
    ConfigTag,
    Tag
}
