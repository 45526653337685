import "./styles.scss";

export const NotFoundItems = () => {
  return (
    <div className="notFound-container">
      <div className="notFound-table-no-notFound-container">
        <i className="icon-iconos_buscar notFound-table-no-notFound-icon"></i>

        <p className="notFound-table-no-notFound-title">
          No se encontraron resultados para tu búsqueda
        </p>
        <p className="notFound-table-no-notFound-subtitle">
          Intenta cambiando los filtros aplicados
        </p>
      </div>
    </div>
  );
};
