import React from "react";
import { isMobile } from "helpers/Mobile";
import UserTableItem from "./UserTableItem";
import Icon from "components/MainApp/atoms/Icon/icon";

import "./styles.scss";

const UserTable = ({ users, headers, handleClick, headerClickHandler, showMessageNoneResult = true }) => {
  const renderHeaderItem = (header) => {
    return (
      <>
        {
          header.hideOrdering ? 
          <div
            className={`user-table-header-item user-table-header-${header.name}`}
            key={header.name}
          >{header.title}</div>
          : 
          <div
            data-automation-id={`sort-by-${header.name}`}
            onClick={() => {
              headerClickHandler(header.name);
            }}
            data-filter-name={header.name}
            className={`user-table-header-item user-table-header-${header.name}`}
            key={header.name}
          >
            {header.title}
              <span className={`user-table-header-icon-span ${header.active ? "active" : ""}  
                ${header.direction ? "" : "down"}`}> 
                  <i className="icon-iconos_flecha-ordenar"></i>
              </span>
          </div>
        }      
      </>
    );
  };
  const table = (
    <React.Fragment>
     
      <div className="user-table-desktop" data-automation-id="users-table">
        {!isMobile && (
          <div className="user-table-header">
            {Array.isArray(headers) && headers.map((header) => renderHeaderItem(header))}
            {!Array.isArray(headers) && Object.keys(headers).map((key) => renderHeaderItem(headers[key]))}
            <div className="user-table-header-item user-table-header-button"></div>
          </div>
        )}
        <div className="user-table-content">
          {users?.map((user) => {
            return (
              <div className="user-table-content-item" key={user.id}>
                <UserTableItem user={user.getTableView()} handleClick={handleClick} />
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );

  const noitems = (
    <React.Fragment>
      { showMessageNoneResult &&
        <div className={"user-table-no-items"}>
          <Icon name="users" fill="#3C7AF5" width={87} height={65}></Icon>
          <h1>Aún no tienes resultado</h1>
        </div>
      }
    </React.Fragment>
  );

  return (
    <div className="user-table-container">
      {users.length > 0 ? table : noitems}
    </div>
  );
};

export default UserTable;
