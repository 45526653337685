const columns = [
  {
    // Razón social
    name: "business_name",
    pill: false,
    title: "RAZÓN SOCIAL",
    mobileTag: false
  },
  {
    // Nombre
    name: "name",
    pill: false,
    title: "NOMBRE",
    mobileTag: false,
    mobileTag: "Nombre:"
  },
  {
    // Dirección
    name: "address",
    pill: false,
    title: "DIRECCIÓN",
    mobileTag: "Dirección:"
  },
  {
    // Municipio
    name: "locality",
    pill: false,
    title: "MUNICIPIO",
    mobileTag: false,
    mobileTag: "Municipio:"
  },
  {
    // Provincia
    name: "state",
    pill: false,
    title: "PROVINCIA",
    mobileTag: false,
    mobileTag: "Provincia:"
  }
];

export default columns;
