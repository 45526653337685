import React from 'react';
import './styles.scss';

export class GenericModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: this.props.open
        }
    }

    close = () => {
        this.setState({
            open: false
        })
        if(this.props.handleClose) this.props.handleClose();
    }
    render() {
        const {
            title,
            content,
        } = this.props;
        return (
            <div className="generic-modal-container">
                {this.state.open && 
                    <div className="wrapper">
                        <div className="overlay" onClick={this.close} data-automation-id="mmodal-overlay"></div>
                        <div className="modal-container">
                            {title && <h1 className="title"> {title} </h1>}
                            {content}
                        </div>
                    </div>
                }
            </div>
        )
    }

}


GenericModal.propTypes = {
    
};

export default GenericModal;