import React from "react";
import ProductModalWrapper from "components/MainApp/organisms/Product/Modals/ProductModalWrapper";

import "./styles.scss";
export class ErrorModal extends React.Component {
  render() {
    const {
      open,
      message,

      handleClose,
      handleGoBack,
      handleButtonClick, //Buscar nuevamente
    } = this.props;

    const content = (
      <div className="wrong-content-modal-wrapper">
        <div className="wrong-content-modal-title">Error</div>
        <div className="wrong-content-modal-text">{message}</div>
      </div>
    );

    const footer = (
      <div className="product-modal-wrapper-footer">
        En caso de error actualice la busqueda.
      </div>
    );
    return (
      <div className="wrong-content-modal-container">
        <ProductModalWrapper
          open={open}
          innerContent={content}
          buttonLegend={"ACEPTAR"}
          handleGoBack={handleGoBack}
          handleClose={handleClose}
          handleButtonClick={handleButtonClick}
          footer={footer}
        ></ProductModalWrapper>
      </div>
    );
  }
}

ErrorModal.propTypes = {};

export default ErrorModal;
