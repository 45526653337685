import React from "react";
import PropTypes from "prop-types";
import Step from "../../../../atoms/Wizard/Step";
import MainInput from "../../../../atoms/Forms/MainInput";
import MainMoneyInput from "../../../../atoms/Forms/MainMoneyInput"

import "./styles.scss";
export class ProdStepTwo extends React.Component {

  goBackFunc = () => {
    if(this.props.goBackFunc) this.props.goBackFunc(1)
  }

  render() {
    const { product, handleChange, handleBlur, errors } = this.props;

    const stepContent = (
      <div className="product-step-two-content" data-automation-id="input-sku">
        <div className="prod-step-two-field">
          <MainInput
            label={"Código"}
            required={true}
            id={"product-codigo"}
            name="sku"
            value={product.sku}
            handleChange={handleChange}
            maxLength={30}
            error={errors.sku}
          />
        </div>
        <div className="prod-step-two-field" data-automation-id="input-purchase-price">
          <MainMoneyInput
            label={"Precio de costo"}
            id={"product-buyng-price"}
            name="purchase_price"
            value={product.purchase_price}
            handleChange={handleChange}
            handleBlur={handleBlur}
            maxLength={18}
            error={errors.purchase_price}
            />
        </div>
        <div className="prod-step-two-field" data-automation-id="input-selling-price">
          <MainMoneyInput
            label={"Precio de venta"}
            id={"product-selling-price"}
            name="sell_price"
            value={product.sell_price}
            handleChange={handleChange}
            handleBlur={handleBlur}
            maxLength={18}
            error={errors.sell_price}
            />
        </div>
      </div>
    );

    return (
      <div className="product-step-two-container">
        <Step title="Código y precio" content={stepContent} goBackFunc={this.goBackFunc} ></Step>
      </div>
    );
  }
}

ProdStepTwo.propTypes = {
  handleChange: PropTypes.func,
};

export default ProdStepTwo;
