import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";


import "./styles.scss";

const countryOptions = [
    {
      label: "Almacen 1"
    },
    {
      label: "Bodega 1"
    },
    {
      label: "Deposito Central"
    },
    {
      label: "Deposito 2"
    }
  ]
export class AddBusinessNodeStepThree extends React.Component {
  render() {
    const { businessnode, handleChange, selectCategory, goBack, handleFinish, error } = this.props;

    const stepContent = (
      <form onSubmit={handleFinish}>
      <div className="add-businessnode-wizard-step-three-content">
        <div className="add-businessnode-wizard-step-three-field" data-automation-id="input-length">
          <MainInput
            label={"Profundidad (mts)"}
            id={"businessnode-length"}
            name="measure_depth"
            type="number"
            value={businessnode ? businessnode.measure_depth : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.measure_depth && error.measure_depth}
          />
        </div>
        <div className="add-businessnode-wizard-step-three-field" data-automation-id="input-height">
          <MainInput
            label={"Alto (mts)"}
            id={"businessnode-height"}
            name="measure_height"
            type="number"
            value={businessnode ? businessnode.measure_height : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.measure_height && error.measure_height}
          />
        </div>
        <div className="add-businessnode-wizard-step-three-field" data-automation-id="input-wide">
          <MainInput
            label={"Ancho (mts)"}
            id={"businessnode-wide"}
            name="measure_width"
            type="number"
            value={businessnode ? businessnode.measure_width : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.measure_width && error.measure_width}
          />
        </div>
        <div className="add-businessnode-wizard-step-three-field" data-automation-id="input-capacity">
          <MainInput
            label={"Capacidad"}
            id={"businessnode-capacity"}
            name="capacity"
            type="number"
            value={businessnode ? businessnode.capacity : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.capacity && error.capacity}
          />
        </div>
        <div className={"add-businessnode-wizard-step-three-action-wrapper"}>
          <button type="submit" className="add-businessnode-wizard-step-three-action-button">
            FINALIZAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-businessnode-wizard-step-three-container">
        <Step title="Dimensiones (opcional)" content={stepContent} goBackFunc={goBack}></Step>
      </div>
    );
  }
}

export default AddBusinessNodeStepThree;
