import React from "react";
import MainAppGenericModal from "../../../../atoms/Modals/GenericModal";

import "./styles.scss";

export class DeleteCategoryModal extends React.Component {
  render() {
    const { 
      open,
      category,
      handleClose
    } = this.props;

    const content = (<div className="delete-category-modal-wrapper">
      <div className="delete-category-modal-title">Eliminar categoría</div>
  <div className="delete-category-modal-text">¿Está seguro que desea eliminar la categoría &ldquo;{category ? category.name : ''}&ldquo;?</div>
      <div className="delete-category-modal-buttons">
        <div className="delete-category-modal-button" onClick={this.props.cancel} data-automation-id="cancel">CANCELAR</div>
        <div className="delete-category-modal-button" onClick={this.props.confirm} data-automation-id="confirm">SÍ, ELIMINAR</div>
      </div>
    </div>)
    return (
      <div className="delete-category-modal-container">
          <MainAppGenericModal open={open} content={content} handleClose={handleClose}></MainAppGenericModal>
      </div>
    );
  }
}

DeleteCategoryModal.propTypes = {
};

export default DeleteCategoryModal;
