import React from 'react';
import i18n from "i18n";

import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";

import "./styles.scss";

const EditAccountForm = ({account, handleChange, handleSelect, countries, languages}) => {
    return (
      <div className="edit-account-form-container">
        <div className="edit-account-form-content">
            <div className="edit-account-form-field" data-automation-id="input-first_name">
                <MainInput
                    label={"Nombre"}
                    required={false}
                    id={"account-first_name"}
                    name="first_name"
                    value={account ? account.first_name : ''}
                    handleChange={handleChange}
                    maxLength={100}
                />
            </div>
            <div className="edit-account-form-field" data-automation-id="input-last_name">
                <MainInput
                    label={i18n.t('Apellido')}
                    required={false}
                    id={"account-last_name"}
                    name="last_name"
                    value={account ? account.last_name : ''}
                    handleChange={handleChange}
                    maxLength={100}
                />
            </div><div className="edit-account-form-field" data-automation-id="input-phone">
                <MainInput
                    label={i18n.t('Teléfono')}
                    required={false}
                    id={"account-phone"}
                    name="phone"
                    value={account ? account.phone : ''}
                    handleChange={handleChange}
                    maxLength={100}
                />
            </div><div className="edit-account-form-field" data-automation-id="input-email">
                <MainInput
                    label={i18n.t('Email')}
                    required={false}
                    id={"account-email"}
                    name="email"
                    value={account ? account.email : ''}
                    handleChange={handleChange}
                    maxLength={100}
                />
            </div>
            <div className="edit-account-form-field" data-automation-id="input-country">
                <MainDropdownInput
                    required={false}
                    id={"account-country"}
                    name="country"
                    selectedOption={account ? account.country : ''}
                    handleSelection={(selectedOption) => handleSelect(selectedOption, 'country')}
                    maxLength={50}
                    options={countries}
                    placeholder={i18n.t('País')} 
                    label={i18n.t('País')}
                />
            </div>
            <div className="edit-account-form-field" data-automation-id="input-language">
                <MainDropdownInput
                    required={false}
                    id={"account-language"}
                    name="language"
                    selectedOption={account ? account.language : ''}
                    handleSelection={(selectedOption) => handleSelect(selectedOption, 'language')}
                    maxLength={50}
                    options={languages}
                    placeholder={i18n.t('Idioma')} 
                    label={i18n.t('Idioma')}
                />
            </div>
        </div>
      </div>
    );
  
}

export default EditAccountForm;
