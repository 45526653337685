import React from "react";
import { useState } from "react";

import "./styles.scss";
const ItemStock = ({ stockItem, handleClick }) => {
  return (
    <div
      className="table-item-stock-mobile-container"
      onClick={() => {handleClick(stockItem)}}
    >
      <div className="table-item-stock-mobile-col-2">
        <div className="table-item-stock-mobile-header">
          <div className="table-item-stock-mobile-name"> {stockItem.date} </div>
          <div className="table-item-stock-mobile-header-separator"></div>
          <div className="table-item-stock-mobile-brand">{stockItem.type}</div>
        </div>
        <div className="table-item-stock-mobile-code">Orden: {stockItem.order}</div>
        <div className="table-item-stock-mobile-stock">
          {stockItem.in !== "" && (
            <span className="table-item-stock-mobile-stock-title">
              Entradas {stockItem.in}{" "}
            </span>
          )}
          {stockItem.out !== "" && (
            <span className="table-item-stock-mobile-stock-title-out">
              Salidas {stockItem.out}
            </span>
          )}
        </div>
      </div>
      <div className="table-item-stock-mobile-col-3">
        <div className="table-item-stock-mobile-category">
          <div className="table-item-stock-mobile-category-inner">
            {stockItem.reason}
          </div>
        </div>
        <div className="table-item-stock-mobile-total">
          Saldo {stockItem.total} UNID
        </div>
      </div>
    </div>
  );
};

export default ItemStock;
