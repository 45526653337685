import React from "react";

import Step from "../../../../../../atoms/Wizard/Step";
import ProductAddedList from "../../../ProductAddedList";
import SearchWithComponents from "../../../../../../atoms/Filters/SearchWithComponents";

import "./styles.scss";

export const RequestOrderStep2 = ({ resultList, searchValue, handleChange, products, totalProducts, goBackFunc, handleSelectToAddProduct, handleSelectedProductQuantityChange,
  handleAdd, handleAddProduct, handleDeleteAddedProduct, selectedProducts, changePage, pagination, handleSelectOutOfStock, outOfStockSelected}) => {

    const stepContent = (
        <div className="request-order-step-two-content">
          <div className="request-order-step-two-field" data-automation-id="input-search">
            <SearchWithComponents
              placeholder = {"Busca por código o nombre"}
              resultList = {resultList}
              value = {searchValue}
              handleChange={handleChange}
              handleSelectToAddProduct={handleSelectToAddProduct} handleSelectedProductQuantityChange={handleSelectedProductQuantityChange} handleAdd={handleAdd}
              selectedProducts={selectedProducts}
              onlyStockHidden={true}
            />

            {products.length > 0 && <ProductAddedList total={totalProducts} products={products} edit={true} handleEdit={handleAddProduct} handleDelete={handleDeleteAddedProduct}
              changePage={changePage}
              pagination={pagination}
            ></ProductAddedList>}
          </div>
        </div>
      );
    return (
        <div className="request-order-step-two-container">
          <Step goBackFunc={goBackFunc} title="¿Qué productos deseas reponer?" content={stepContent}></Step>
        </div>
      );
}

export default RequestOrderStep2