import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";

import { PublicService } from "services/PublicService";
import { BusinessService } from "services/BusinessService";

import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";
import MainDragAndDropImg from "components/MainApp/atoms/Forms/DragAndDropImage";
import SelectAutocomplete from 'shared/components/autocomplete';
import "./styles.scss";

const initialBusiness = {
    id: '',
    name: '',
    business_name: '',
    document: '',
    sector: '',
    country: '',
    region: '',
    state: '',
    locality: '',
    currency: '',
    image: '',
    address: '',
    phone: '',
    email: '',
}

const currencyOptions = [
  {
    label: 'ARS',
    value: 'ARS',
    id: 'ARS'
  },
  {
    label: 'EUR',
    value: 'EUR',
    id: 'EUR'
  }
]


const EditBusinessView = (props) => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [business, setBusiness] = useState(initialBusiness);
  const [imageDeleted, setImageDeleted] = useState(false);

  const [countries, setcountries] = useState([]);
  const [regions, setregions] = useState([]);
  const [provinces, setprovinces] = useState([]);
  const [cities, setcities] = useState([]);


  useEffect(() => {
    if (props.match.params.key) {
      beforeSubmit();
      const id = props.match.params.key;
        BusinessService.business(id)
        .then((response) => {
            const business = response
            let countries;
            let regions;
            let states;
            let localities;

            let countriesPromises = PublicService.countries()

            let regionsPromises = PublicService.regions(business.country)
            let statesPromises = PublicService.states(business.country, business.region)
            let citiesPromises = PublicService.cities(business.country, business.region, business.state)

            Promise.all([countriesPromises, regionsPromises, statesPromises, citiesPromises]).then(responses => {
                countries = responses[0]
                setcountries(countries)

                regions = responses[1]
                setregions(regions)

                states = responses[2]
                setprovinces(states)

                localities = responses[3]
                setcities(localities)

                business.country = countries.find(c => c.value === business.country);
                business.locality = localities.find(c => c.value === business.locality);
                business.region = regions.find(c => c.value === business.region);
                business.state = states.find(c => c.value === business.state);

                business.measure_depth = business.measure_depth/100;
                business.measure_height = business.measure_height/100;
                business.measure_width = business.measure_width/100;

                business.currency = currencyOptions.find(o => o.id === business.currency)
                setBusiness(business)
                afterSubmit()
            })
        })
        .catch((error) => {
          afterSubmit();
        });
    }
  }, []);

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    beforeSubmit();
    let formData = new FormData();
    ['name', 'business_name', 'document', 'type', 'sector','address', 'phone', 'email'].map(key =>
        formData.append(key, business[key])
    )
    formData.append("country", business.country ? business.country.value : '')
    formData.append("state", business.state ? business.state.value : '')
    formData.append("region", business.region ? business.region.value : '')
    formData.append("locality", business.locality ? business.locality.value : '')
    formData.append("currency", business.currency ? business.currency.value : '')
    // formData.append("instance_id", AuthService.currentUserValue().account.instances[0].id)
    if (business.image_file) {
      formData.append("image", business.image_file);
    } else if(imageDeleted) {
      formData.append("image", "");
    }

    BusinessService.updateBusinessFormData(props.match.params.key, formData)
        .then((response) => {
          afterSubmit();
          history.push('/admin/businesses')
        })
        .catch((error) => {
          afterSubmit();
          dealWithError(error, "generic.error", (message, errors) => {
            message = message.replace('""', '')
            analizeErrors(message, errors);
          });
        });
  };

  const handleGoBack = () => {
    history.push('/admin/businesses')
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let _businness = { ...business };
    _businness[name] = value;

    setBusiness(_businness);
  };

  const validateContinue = () => {
    if (business.country?.value === 'ES') {
      return ['name', 'business_name', 'document', 'country', 'region', 'state', 'locality', 'currency'].every(key => {
        if (key === 'country' || key === 'region' || key === 'locality' || key === 'state' || key === 'currency') {
          return business[key] && business[key].value && business[key].value.trim() !== ''
        } else {
          return business[key] && business[key].trim() !== ''
        }
      })
    }
    return ['name', 'business_name', 'document', 'country', 'currency' ].every(key => {
      if (key === 'country' || key === 'region' || key === 'currency') {
        return business[key] && business[key].value && business[key].value.trim() !== ''
      } else {
        return business[key] && business[key].trim() !== ''
      }
    })
  }

  const handleSelect = (selectedOption, type) => {
    let _business = {...business}
    switch (type) {
        case 'country':
            _business.country = selectedOption;
            _business.region = ''
            _business.state = ''
            _business.locality = ''
            PublicService.regions(_business.country.value).then(response => {
                setregions(response)
            });
            setBusiness(_business);
            break;
        case 'region':
            _business.region = selectedOption;
            _business.state = ''
            _business.locality = ''
            PublicService.states(_business.country.value, _business.region.value).then(response => {
                setprovinces(response)
            });
            setBusiness(_business);
            break;
        case 'state':
            _business.state = selectedOption;
            _business.locality = ''
            PublicService.cities(_business.country.value, _business.region.value, _business.state.value).then(response => {
                setcities(response)
            });
            setBusiness(_business);
            break;
        case 'city':
            _business.locality = selectedOption;
            setBusiness(_business);
            break;

        default:
          _business[type] = selectedOption;
          setBusiness(_business);

                break;
    }
}

const handleDeleteImg = (e) => {
    let _business = {...business};
    _business["image_file"] = null;
    setBusiness(_business);
    setImageDeleted(true);
  };

  const handleChangeImg = (e) => {
    let _business = {...business};
    _business.image_file = e.target.files[0];
    setBusiness(_business)

  };

  const headerOptions = [];
  const content = (
    <div className={"edit-business-content-wrapper"}>
      <div className={"edit-business-inner"}>
        <form onSubmit={handleCreate}>
        <div className="edit-business-wizard-step-one-content">
        <div className="edit-business-wizard-step-one-field" data-automation-id="input-name">
          <MainInput
            label={"Nombre"}
            required={true}
            id={"business-name"}
            name="name"
            value={business ? business.name : ''}
            handleChange={handleChange}
            maxLength={100}
            error={errors.name && errors.name}
          />
        </div>

        <div className="edit-business-wizard-step-one-field" data-automation-id="input-business_name">
          <MainInput
            label={"Razon social"}
            required={true}
            id={"business-business_name"}
            name="business_name"
            value={business ? business.business_name : ''}
            handleChange={handleChange}
            maxLength={100}
            error={errors.business_name && errors.business_name}
          />
        </div>

        <div className="edit-business-wizard-step-one-field" data-automation-id="input-document">
          <MainInput
            label={"Documento"}
            required={true}
            id={"business-document"}
            name="document"
            value={business ? business.document : ''}
            handleChange={handleChange}
            maxLength={100}
            error={errors.document && errors.document}
          />
        </div>

        <div className="edit-business-wizard-step-one-field" data-automation-id="input-sector">
          <MainInput
            label={"Rubro"}
            required={false}
            id={"business-sector"}
            name="sector"
            value={business ? business.sector : ''}
            handleChange={handleChange}
            maxLength={100}
            error={errors.sector && errors.sector}
          />
        </div>

        <div className="edit-business-wizard-step-one-field" data-automation-id="input-country">
          <SelectAutocomplete
            id="business-country"
            name="country"
            label={"País"}
            options={countries}
            placeholder={"País"}
            selected={business ? business.country : {}}
            required={true}
            error={errors.country && errors.country}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "country")
            }
          />
        </div>
        <div className="edit-business-wizard-step-one-field" data-automation-id="input-region">
        {business?.country?.value === 'ES' && (
            <SelectAutocomplete
              id="business-region"
              name="region"
              label={"Comunidad"}
              options={regions}
              placeholder={"Comunidad"}
              selected={business ? business.region : {}}
              required={true}
              disabled={!business || !business.country || business.country === ""}
              error={errors.region && errors.region}
              onChange={(id, selectedOption, target) =>
                handleSelect(selectedOption, "region")
              }
            />
          )}
        </div>
        <div className="edit-business-wizard-step-one-field" data-automation-id="input-state">
        {business?.country?.value === 'ES' && (
          <SelectAutocomplete
            id="business-state"
            name="state"
            label={"Provincia"}
            options={provinces}
            placeholder={"Provincia"}
            selected={business ? business.state : {}}
            disabled={!business || !business.region || business.region === ""}
            required={true}
            error={errors.country && errors.country}
            onChange={(id, selectedOption, target) =>
              handleSelect(selectedOption, "state")
            }
          />
        )}
        </div>
        <div className="edit-business-wizard-step-one-field" data-automation-id="input-locality">
          {business?.country?.value === 'ES' && (
            <SelectAutocomplete
              id="business-locality"
              name="locality"
              label={"Municipio"}
              options={cities}
              placeholder={"Municipio"}
              selected={business ? business.locality : {}}
              disabled={!business || !business.state || business.state === ""}
              required={true}
              error={errors.country && errors.country}
              onChange={(id, selectedOption, target) =>
                handleSelect(selectedOption, "city")
              }
            />
          )}
        </div>
        <div className="edit-business-wizard-step-one-field" data-automation-id="input-currency">
          <MainDropdownInput
            required={true}
            id={"business-currency"}
            name="Moneda"
            selectedOption={business ? business.currency : ''}
            handleSelection={(selectedOption) => handleSelect(selectedOption, 'currency')}
            maxLength={50}
            options={currencyOptions}
            placeholder={'Moneda'}
            label={"Moneda"}
            error={errors.currency && errors.currency}
          />
        </div>

        {business.id && <div className="edit-business-wizard-step-one-field" data-automation-id="input-currency">
           <div className="product-step-three-title" data-automation-id="input-image">
          Si lo deseas, agrega una imagen para identificar tu producto.
          </div>
          <MainDragAndDropImg
            name="image"
            handleChange={handleChangeImg}
            handleDelete={handleDeleteImg}
            file={business && business.image}
            error={errors?.image}
            isSmall={true}
          />
        </div>}
        <div className="edit-business-wizard-step-one-field" data-automation-id="input-address">
          <MainInput
            label={"Direccion"}
            required={false}
            id={"business-address"}
            name="address"
            value={business ? business.address : ''}
            handleChange={handleChange}
            maxLength={100}
            error={errors.address && errors.address}
            />
        </div>
        <div className="edit-business-wizard-step-one-field" data-automation-id="input-phone">
          <MainInput
              label={"Telefono"}
              required={true}
              id={"business-phone"}
              name="phone"
              value={business ? business.phone : ''}
              handleChange={handleChange}
              maxLength={100}
              error={errors.phone && errors.phone}

            />
        </div>
        <div className="edit-business-wizard-step-one-field" data-automation-id="input-email">
          <MainInput
              label={"Email"}
              required={true}
              id={"business-email"}
              name="email"
              value={business ? business.email : ''}
              handleChange={handleChange}
              maxLength={100}
              error={errors.email && errors.email}

            />
        </div>
         </div>
          <div className={"edit-business-button-wrapper"}>
            <button
              type="submit"
              className={`edit-business-button ${!validateContinue() &&
                "disabled"}`}
              onClick={handleCreate}
            >
              GUARDAR
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  return (
    <div className="edit-business-container">
      <AdminLayout
        headerTitle={i18n.t(`Editar empresa`)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default EditBusinessView;
