import React from 'react';
import Logo from '../../atoms/images/Logo'
import './styles.scss';

export class AuthMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            version: this.props.version
        }
    }

    render() {
        const {
            content,
            version,
            title,
            handleGoBack
        } = this.props;
        const containerNameLogo = version === 'logo' ? 'auth-mobile-container-logo' : "";
        const containerNameHeader = version === 'header' ? 'auth-mobile-container-header' : ''
        return (
            <div className={"auth-mobile-container " + containerNameLogo + containerNameHeader  }>
                <div className="header-container">
                    
                </div>
                <div className="main">
                    <div className='placeholder' onClick={handleGoBack} data-automation-id="go-back">
                    {this.state.version === 'logo' && <div className="logo">
                        <Logo white={true}></Logo>
                        {/* <img className="image" src={imageFile}/> */}
                    </div>}

                    {this.state.version === 'header' && <div className="header">
                    {handleGoBack && <i className="icon icon-back"></i>}

                        <p className='title'>{title}</p>
                    </div>}
                    </div>
                    <div className="content-container">
                        {content}
                    </div>
                </div>
            </div>
        )
    }

}


AuthMobile.propTypes = {
    
};

export default AuthMobile;