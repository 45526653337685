import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
//state: enabled, disabled, waiting, temporary
export class Loader extends React.Component {
  render() {
    const { title } = this.props;

    return (
      <div className="loader-container">
        <div className="content-wrapper">
          <div className="spinner">
            <div className="loadingio-spinner-rolling-lktnp05eknk">
              <div className="ldio-oktnutaa7e">
                <div></div>
              </div>
            </div>
          </div>
          <div className="title">
            <div>{title}</div>
          </div>
        </div>
      </div>
    );
  }
}

Loader.propTypes = {
  title: PropTypes.string,
};

export default Loader;
