import {
    ListItem, ListItemText, Box,
    Card,
    CardContent,
    Typography,
    CardActions,
    Button,
    Avatar,
    CardHeader,
    IconButton,
    CardMedia,
} from '@material-ui/core';
import Zoom from '@mui/material/Zoom';

import style from "./ShelvingItem.module.scss";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState } from 'react';
import { useEffect } from 'react';
import default_image from "../../../assets/images/default_product.png";

const ShelvingItem = ({ item, index, handleRemove, expiration}) => {

    const [showVto, setShowVto] = useState();
    const [daysVto, setDaysVto] = useState(100);

    useEffect(() => {
        try {
            var dateParts = expiration.split("/");
            var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
            const diffTime = dateObject - new Date() ;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            console.log(diffDays);
            setShowVto("pass");
            if(diffDays <= 7){
                setShowVto("Yellow")
                setDaysVto(diffDays)
            }

            if(diffDays <= 1){
                setShowVto("Orange")
            }
            
            if(diffDays < 0){
                setShowVto("Red")
            }
        } catch (error) {
            console.log("error al obtener el vencimiento: " + expiration)
            setShowVto(null)
        }
    }, [])
    const [view, setView] = useState(true);

    const remove = () => {
        handleRemove(index);
    }


    return (
        <Box sx={{ display: 'flex' }}>
            {item &&
                <Zoom
                    in={view}>
                    <Card className={style["card"]} >
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                               
                                <img
                                    className={style['image-est1']}
                                    src={item && item.image ? item.image : default_image}
                                />
                               
                            </Box>
                                <Box sx={{ alignItems: 'top', pl: 15}}>
                                    <Typography  className={style["name"]}>{item.name} | {item.brand.name}</Typography>
                                    {daysVto <= 7 && 
                                        <div style={{display:"flex"}}>
                                            <img className={[style["alert-icon"]].join(' ')} src={require(`../../../assets/images/AlertExpiration${showVto}.png`)} alt="alert" />
                                        </div>
                                    }
                                    
                                    <Typography className={style["sku"]} >Codigo: {item.sku}</Typography>
                                    <div style={{display:"flex"}}>
                                    <Typography className={style["price"]} >Precio: ${item.sell_price}</Typography>
                                    {showVto && 
                                         <div style={{marginLeft:"auto"}} >
                                         <div className={style["price"]}>Vto: {expiration}</div>
                                     </div>
                                    }
                                    </div>
                                </Box>
                            
                        </CardContent>
                    </Card>
                </Zoom>
            }
        </Box>

    );
}

export default ShelvingItem;