import React, { useState, useEffect } from "react";
import onClickOutside from "react-onclickoutside";
import ProductResultSearch from "components/MainApp/atoms/Filters/SearchWithComponentsLocations/ProductLocationResult";
import Checkbox from "../../../../Auth/atoms/forms/CheckboxCostum";
import { EventRepository } from 'helpers/EventRepository';
import "./styles.scss";

export class SearchWithComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: this.props.value ? this.props.value : "",
      submitted: false,
      filteredResultList: [],
      value: '',
    };
    this.typingTimer = null;
    this.initEmpty = false;
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }


  handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    let valueFilter = value.trim();

    if (this.props.resultList.length > 0 && !this.initEmpty) {
      let newFilteredResultList;
      if (valueFilter !== "") {
        newFilteredResultList = this.props.resultList.filter((w) => {
          return (
            w.name.toLowerCase().includes(valueFilter.toLowerCase()) ||
            w.id.toLowerCase().includes(valueFilter.toLowerCase()) ||
            w?.sku?.toLowerCase().includes(valueFilter.toLowerCase())
          );
        });
      } else {
        newFilteredResultList = [];
      }
  
      this.setState({
        value,
        submitted: false,
        focused: true,
        filteredResultList: newFilteredResultList.splice(0, 20),
      });
    }else {
      this.initEmpty = true;
      this.setState({
        value,
        submitted: false,
        focused: true,
      });

      if (value.length > 2) {
        if (this.typingTimer) {
          clearTimeout(this.typingTimer);
        }

        this.typingTimer = setTimeout(() => {
          this.props.filterProducts(value).then((resultList) => {
            this.setState({
              filteredResultList: resultList,
            });
          });
        }, 500);
      }

    }
   
  };

  handleSelect = (e) => {
    const value = e.target.dataset.value;
    this.setState({
      value,
      submitted: true,
      focused: false,
    });
    this.props.handleSubmit(value);
  };

  handleClickOutside() {
    this.setState({
      focused: false,
    });
  }

  filterResults = () => {};


  cleanInput = () => {
    this.setState(
      {
        value: "",
        submitted: false,
        focused: false,
      },
      (_) => {
        this.props.handleSubmit(this.state.value);
      }
    );
  };

  handleFocus = () => {
    this.setState({
      focused: true,
    });
  };

  handleBlur = () => {
    this.setState({
      focused: false,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submitted: true,
      focused: false,
    });
    this.props.handleSubmit(this.state.value);
  };

  handleAdd = () => {
    let valid = true;
    if(this.props.selectedProducts.length > 0) {
      this.props.selectedProducts.forEach(selectedId => {
        let selectedProduct = this.props.resultList.filter((item) => item.id  == selectedId);
        if(selectedProduct[0].extra?.length == 0){
          valid = false;
        }
      });

    }

    if(valid){
      this.setState({
        submitted: true,
        focused: false,
        value: "",
      });
      this.props.handleAdd();
    }else {
      EventRepository.notificationSend({
        label: 'Existen productos seleccionados sin ubicación',
        type: 'error'
      });
    }
  };



  render() {
    const {
      placeholder,
      // value,
      handleSelectToAddProduct,
      handleChangedLocations,
      selectedProducts,
      onlyStockHidden,
      checkBoxLabel = "Solo productos sin stock",
      handleCheckbox,
      checkBoxChecked,
    } = this.props;

    return (
      <div
        className={
          this.state.focused
            ? "search-with-components-container focused"
            : "search-with-components-container"
        }
      >
        <div className="search-with-components-input-wrapper">
          <form
            className="search-with-components-input-form"
            onSubmit={this.handleSubmit}
          >
            <i className="search-with-components-icon-desktop icon-iconos_buscar"></i>
            <i
              onClick={this.handleBlur}
              className={
                this.state.focused
                  ? "search-with-components-icon-mobile-focused icon-back"
                  : "search-with-components-icon-mobile icon-iconos_buscar"
              }
            ></i>
            <input
              className="search-with-components-input"
              placeholder={placeholder}
              value={this.state.value}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              data-automation-id="search-with-components-input"
            ></input>
            {!onlyStockHidden && (
              <>
                <div className="divider"></div>
                <div className="search-with-component-only-stock">
                  <Checkbox
                    handleChange={handleCheckbox}
                    checked={checkBoxChecked}
                  />{" "}
                  <div className="search-with-component-checkboxl-label">
                    {checkBoxLabel}
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
        {this.state.value.length > 2 &&
          this.state.focused &&
          this.state.filteredResultList.length > 0 &&
          !this.state.submitted && (
            <div className="search-with-components-results-desktop">
              <ul
                className="search-with-components-results-desktop-inner"
                data-automation-id="search-with-components-result-list"
              >
                {this.state.filteredResultList.map((item) => {
                  return (
                    <div
                      className="search-with-components-result-item"
                      key={item.id}
                    >
                      <Checkbox
                        handleChange={handleSelectToAddProduct}
                        checked={selectedProducts.includes(item.id)}
                        label={item.id}
                        hideLabel={true}
                      />
                      <ProductResultSearch
                        handleClick={() => {
                          handleSelectToAddProduct(item.id);
                        }}
                        key={item.id}
                        product={item}
                        locations={item.extra}
                        handleChangedLocations={handleChangedLocations}
                      ></ProductResultSearch>
                    </div>
                  );
                })}
              </ul>
              <div className={`search-with-components-results-add-wrapper`}>
                <div
                  className={`search-with-components-results-add-button`}
                  onClick={this.handleAdd}
                >
                  Agregar
                </div>
              </div>
            </div>
          )}

        {this.state.value.length > 2 &&
          this.state.focused &&
          this.state.filteredResultList.length > 0 &&
          !this.state.submitted && (
            <ul
              className="search-with-components-results-mobile"
              data-automation-id="search-with-components-result-list"
            >
              <div className="search-with-component-only-stock">
                <Checkbox
                  handleChange={handleCheckbox}
                  checked={checkBoxChecked}
                />
                <div className="search-with-component-checkboxl-label">
                  {checkBoxLabel}
                </div>
              </div>
              {this.state.filteredResultList.map((item) => {
                return (
                  <div
                    className="search-with-components-result-item"
                    key={item.id}
                  >
                    {/* <Checkbox /> */}
                    {/* <ProductResultSearch key={item.code} product={item} units={item.units}></ProductResultSearch> */}
                    <div className="search-with-components-result-item-inner">
                      <Checkbox
                        handleChange={handleSelectToAddProduct}
                        checked={item.selected}
                        label={item.id}
                        hideLabel={true}
                      />
                      <ProductResultSearch
                        handleClick={() => {
                          handleSelectToAddProduct(item.id);
                        }}
                        key={item.code}
                        product={item}
                        locations={item.extra}
                        disabled={!selectedProducts.includes(item.id)}
                        handleChangedLocations={handleChangedLocations}
                      ></ProductResultSearch>
                    </div>
                    <div className="search-with-components-result-item-divider"></div>
                  </div>
                );
              })}
              <div className={`search-with-components-results-add-wrapper`}>
                <div
                  className={`search-with-components-results-add-button`}
                  onClick={this.handleAdd}
                >
                  Agregar
                </div>
              </div>
            </ul>
          )}
        {/* <ul className="search-with-components-results">
              {this.state.filteredResultList.map(item =>{
                  return <li key={item}>{item}</li>
              })}
            </ul> */}
      </div>
    );
  }
}

export default onClickOutside(SearchWithComponents);
