import React from "react";
import default_image from "../../../../../../assets/images/default_product.png";

import "./styles.scss";
export class ProductTableItemMobile extends React.Component {
  constructor(props){
    super(props);
    this.state={
      imgError: false
    }
  }
  render() {
    const {product, handleClick} = this.props
    return (
      <div className="table-item-mobile-container" data-automation-id="product-item" data-product-id={product.id} onClick={handleClick}>
        <div className="table-item-mobile-col-1">
          <div className="table-item-mobile-img">
          <img
              alt="product"
              src={(this.state.imgError || !product.image) ? default_image : product.image}
              onError={() => { this.setState({imgError: true}) }}
            />
          </div>
        </div>
        <div className="table-item-mobile-col-2">
          <div className="table-item-mobile-header">
            <div className="table-item-mobile-name"> {product.name} </div>
            <div className="table-item-mobile-header-separator"></div>
            <div className="table-item-mobile-brand"> {product.brand ? product.brand.name : '-'} </div>
          </div>
          <div className="table-item-mobile-code">Código: {product.sku}</div>
        </div>
        <div className="table-item-mobile-col-3">
          <div className="table-item-mobile-category">
              { product.categories.length > 0 ?
                  product.categories.map((category) => {
                      return <div className="table-item-mobile-category-inner" key={category.id}>
                          {category.name}
                      </div>
                  })
              :
                  <span>-</span>    
              }
          </div>
        </div>
      </div>
    );
  }
}

ProductTableItemMobile.propTypes = {
};

export default ProductTableItemMobile;