import Step from "components/MainApp/atoms/Wizard/Step";
import React from "react";

import MainDropdown from "components/MainApp/atoms/Forms/MainDropdown";
import "./styles.scss";

export const RelOrderStep1 = ({ options, option, handleChange }) => {
  const stepContent = (
    <div className="relocation-order-step-one-content">
      <div
        className="relocation-order-step-one-field"
        data-automation-id="input-name"
      >
        <MainDropdown
          label={"Selecciona"}
          placeholder={"Selecciona"}
          required={true}
          id={"dest"}
          name="dest"
          value={option}
          selectedOption={option}
          options={options}
          handleChange={handleChange}
          handleSelection={handleChange}
        />
      </div>
    </div>
  );
  return (
    <div className="relocation-order-step-one-container">
      <Step
        title="Selecciona el origen del pedido"
        content={stepContent}
      ></Step>
    </div>
  );
};

export default RelOrderStep1;
