import React from 'react';
import SearchInput from "components/MainApp/atoms/Filters/SearchSimple";

import "./styles.scss";

const SimpleSearchPanel = ({ placeholder, handleSubmit, valueSearch = "" }) => {

    return <div className="simple-search-panel">
            <SearchInput placeholder={placeholder} handleSubmit={handleSubmit} valueSearch={valueSearch}/>
        </div>
}

export default SimpleSearchPanel;