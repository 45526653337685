import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
//state: enabled, disabled, waiting, temporary
export class MainButton extends React.Component {
  handleClick = (e) => {
    if(this.props.state==='enabled'){
      if (this.props.handleClick) this.props.handleClick(e);
    }
  }

  render() {
    const {
      state,
      legend,
      type
    } = this.props;

    return (
      <button className={'main-button ' + state} onClick={this.handleClick} 
        type={type ? type : 'button'} disabled={state === 'disabled'}>
        {state === 'temporary' &&
          <i className="icon-check main-button-icon"></i>
        }
        {state !== 'waiting' && legend}
        {state === 'waiting' &&
          <div className="loadingio-spinner-rolling-oq9o3ny4ta"><div className="ldio-zwyik6gooc">
            <div></div>
          </div></div>
        }
      </button>
    )
  }

}

MainButton.propTypes = {
  handleClick: PropTypes.func,
  legend: PropTypes.string
};

export default MainButton;