import React from "react";
import Step from "../../../../atoms/Wizard/Step";
import MainInput from "../../../../atoms/Forms/MainInput";

import "./styles.scss";
export class ProdStepDimensions extends React.Component {
  goBackFunc = () => {
    if(this.props.goBackFunc) this.props.goBackFunc(1)
  }

  render() {
    const { product, handleChange, handleBlur, errors } = this.props;

    const stepContent = (
      <div className="product-step-one-content">
        <div className="prod-step-one-field" data-automation-id="input-weight">
          <MainInput
            label={"Peso (kg)"}
            id={"product-weight"}
            name="weight"
            value={product.weight ?? 0}
            handleChange={handleChange}
            handleBlur={handleBlur}
            maxLength={100}
            error={errors.weight}
            type="number"
          />
        </div>
        <div className="prod-step-one-field" data-automation-id="input-measure-depth">
          <MainInput
            label={"Profundidad (cm)"}
            id={"product-measure-depth"}
            name="measure_depth"
            value={product.measure_depth ?? 0}
            handleChange={handleChange}
            handleBlur={handleBlur}
            maxLength={100}
            error={errors.measure_depth}
            type="number"

          />
        </div>
        <div className="prod-step-one-field" data-automation-id="input-measure-height">
          <MainInput
            label={"Alto (cm)"}
            id={"product-measure-height"}
            name="measure_height"
            value={product.measure_height ?? 0}
            handleChange={handleChange}
            handleBlur={handleBlur}
            maxLength={100}
            error={errors.measure_height}
            type="number"

          />
        </div>
        <div className="prod-step-one-field" data-automation-id="input-measure-width">
          <MainInput
            label={"Ancho (cm)"}
            id={"product-measure-width"}
            name="measure_width"
            value={product.measure_width ?? 0}
            handleChange={handleChange}
            handleBlur={handleBlur}
            maxLength={100}
            error={errors.measure_width}
            type="number"

          />
        </div>
      </div>
    );

    return (
      <div className="product-step-one-container">
        <Step title="Peso y dimensiones (opcional)" content={stepContent}  goBackFunc={this.goBackFunc}></Step>
      </div>
    );
  }
}

export default ProdStepDimensions;
