const filters = [
    {
      label: "Zona",
      selectedOptions: null,
      placeholder: "Selecciona",
    },
    {
      label: "Sector",
      selectedOptions: null,
      placeholder: "Selecciona",
    },
    {
        label: "Estanteria",
        selectedOptions: null,
        placeholder: "Selecciona",
    },
    {
        label: "Nivel",
        selectedOptions: null,
        placeholder: "Selecciona",
    },
  ];
  
  export default filters;
  