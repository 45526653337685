import React, { Fragment }  from 'react';
import CustomInput from '../InputCostum';
import './styles.scss';

export class PasswordInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visibility: 'password',
            showValidation: false
        }
    }

    onVisibilityChange = (event) => {
        event.preventDefault();
        if(this.state.visibility === 'password'){
            this.setState({
                visibility: 'text'
            })
        }else{
            this.setState({
                visibility: 'password'
            })
        }
    }

    handleFocus = (e) => {
        this.setState({
            showValidation: true
        })
    }

    handleBlur = (e) => {
        this.setState({
            showValidation: false
        })
    }

    render() {
        const {
            id,
            error,
            validationResults = [],
            validate
        } = this.props;

        const validationContent = validationResults.every((v) => v === true) ? <div className="safe-password">Contraseña segura</div> :
                                    <Fragment>
                                         <p className="validation-title">Deberá contener</p>
                                        <div className={"validation-items"}>
                                            <p className={validationResults[0] ? "validation-item valid" : "validation-item"}><i className={validationResults[0] ? "icon icon-check" : "icon icon-cruz"}/>Al menos 8 caracteres</p>
                                            <p className={validationResults[1] ? "validation-item valid" : "validation-item"}><i className={validationResults[1] ? "icon icon-check" : "icon icon-cruz"}/>Al menos un número</p>
                                            <p className={validationResults[2] ? "validation-item valid" : "validation-item"}><i className={validationResults[2] ? "icon icon-check" : "icon icon-cruz"}/>Al menos una letra</p>
                                        </div>
                                    </Fragment>
        return (
            <div className="password-group">
                <CustomInput controlVisibility={true} handleFocus={this.handleFocus} handleBlur={this.handleBlur} handleChange={this.props.handleChange} id={id} type={this.state.visibility} error={error} placeholder="Contraseña" icon="icon-candado" handleVisibility={this.onVisibilityChange}></CustomInput>
                {(this.state.showValidation && validate && !error) && <div className="validation">
                    {validationContent}
                </div>}
            </div>
        )
    }

}


PasswordInput.propTypes = {
    
};

export default PasswordInput;