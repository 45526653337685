export const steps = [
    {
        id: '0',
        name: 'Descripción'
    },
    {
        id: '1',
        name: 'Dimensiones'
    },
    {
        id: '2',
        name: 'Confirmación'
    },
]