import "./AlertDetailCard.scss";
import { Card, CardContent, Chip } from "@material-ui/core";
import useWindowSize from "hooks/useWindowSize";
import { getAlarmType } from "shared/components/guard/Parser";

const AlertDetailCard = ({ content, alert }) => {
  const { isMobile } = useWindowSize();

  const alertCommentClass = label =>
    label === "Comentario" && alert.comments === "Sin comentario"
      ? "without-comment"
      : "";

  return (
    <Card className="alert-detail-card" component="article">
      <CardContent className="alert-detail-card__content">
        {isMobile && (
          <header className="alert-detail-card__status-mobile">
            <Chip label={getAlarmType(alert.status)} className={alert.status} />
          </header>
        )}

        {content.map(({ icon, label, value }) => (
          <div key={label}>
            <picture>
              <img src={icon} alt={label.toLowerCase()} />
            </picture>
            <div>
              <p>{label}</p>
              {label === "Comentario" && value.length > 34 ? (
                <Tooltip content={<h3>{value}</h3>} text={value} />
              ) : (
                <h3 className={alertCommentClass(label)}>{value}</h3>
              )}
            </div>
          </div>
        ))}

        {!isMobile && (
          <footer className="alert-detail-card__status-desk">
            <Chip label={getAlarmType(alert.status)} className={alert.status} />
          </footer>
        )}
      </CardContent>
    </Card>
  );
};

export default AlertDetailCard;
