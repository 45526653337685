import i18n from "i18n";

class Device {
  id = null;
  serial_number = null;
  port = null;
  device_type = null;
  name = null;
  enabled = null;
  device = null;
  node = null;
  location = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }

  getLocationPanelView() {
    return {
      id: this.id,
      name: this.name,
    };
  }

  getDescriptionPanelView() {
    return [
      {
        title: i18n.t("Nombre"),
        value: this.name,
      },
      {
        title: i18n.t("Tipo"),
        value: i18n.t(`devices.type.${this.device_type?.parent?.type ?? this.device_type?.type}`),
      },
      {
        title: i18n.t("Numero de Serial"),
        value: this.serial_number,
      },
      {
        title: i18n.t("Sucursal"),
        value: this.node.name,
      },
      {
        title: i18n.t("Ubicacion"),
        value: this.location
          ? this.location.path_name
          : i18n.t("Sin Ubicacion"),
      },
    ];
  }

  getAntennaDescriptionPanelView() {
    return [
      {
        title: i18n.t("Nombre"),
        value: this.name,
      },
      {
        title: i18n.t("Puerto"),
        value: this.port,
      },
      {
        title: i18n.t("Sucursal"),
        value: this.node.name,
      },
      {
        title: i18n.t("Ubicacion"),
        value: this.location
          ? this.location.path_name
          : i18n.t("Sin Ubicacion"),
      },
    ];
  }

  getGenericTableView(){
    return {
      id: this.id,
      name: this.name,
      type: i18n.t(`${this.device_type?.name}`),
      serial_number: this.serial_number,
      node: this.node.name,
      location: this.location
      ? this.location.path_name
      : i18n.t("Sin Ubicacion"),
      status: this.enabled ? 'EN' : 'DIS'
    }
  }
}

export { Device };
