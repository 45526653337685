import i18n from "i18n";

const mainMenuItems = [
  {
    title: i18n.t("Ficha de stock"),
    arrow: true,
    to: "/admin/ficha-de-stock",
    permission: 'read_orders',
  },
  {
    title: i18n.t("Auditoría de stock"),
    arrow: true,
    to: "/admin/auditoria-de-stock",
    permission: 'auditory_stock',
  },
  {
    title: i18n.t("Localización"),
    arrow: true,
    toStep: 1,
    permission: 'localization_stock',
  },
];

export const step0 = {
  items: mainMenuItems,
  headerTitle: "Stock",
  navHidden: false,
  goBackTo: undefined,
};
