const scopes = [
    {
        name: 'Items',
        id: 'item',
    },
    {
        name: 'Marcas',
        id: 'brand'
    },
    {
        name: 'Categorías',
        id: 'category'
    },
    {
        name: 'Personas',
        id: 'person'
    },
    {
        name: 'Sucursales',
        id: 'node'
    },
    {
        name: 'Locaciones',
        id: 'location'
    },
    {
        name: 'Órdenes',
        id: 'order'
    },
    {
        name: 'Movimiento de stock',
        id: 'movement'
    }
]

export default scopes;