import { ListItem, ListItemText } from '@material-ui/core';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import { Grid } from '@mui/material';
import style from "./ItemTraceability.module.scss";

const ItemTraceability = ({ date, motive, orderNumber, responsible, node }) => {

    return (
        <ListItemText sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, backgroundColor: 'white' }} className={style["item-traceability"]}>
            <Grid container sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }}} className={style["item-traceability-content"]} >
                <Grid xs={1} sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }} />
                <Grid xs={1} className={[style["item-icon"]].join(' ')} sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }}}>
                  <div className="table-icon">
                    <span className="circle">
                      <div></div>
                    </span>
                  </div>
                </Grid>

                <Grid xs={9} sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, marginLeft: "10px" }}>
                    <Grid sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }} className={[style["item-traceability-grid"]].join(' ')}>
                            <ListItemText primary={date} className={style["item-traceability-md"]} />
                            <ListItemText className={[style["item-traceability-orden"]].join(' ')}>Orden {orderNumber}</ListItemText>
                            <ListItemText className={style["item-traceability-sm"]}>Motivo: {motive}</ListItemText>
                            <ListItemText className={style["item-traceability-sm"]}>Responsable: {responsible}</ListItemText>
                            <ListItemText className={style["item-traceability-sm"]}>Sucursal: {node}</ListItemText>
                    </Grid>
                </Grid>
            </Grid>
        </ListItemText>
    );
}

export default ItemTraceability;