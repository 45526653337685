import React, { useState, useEffect } from "react";

import { LocationService } from "services/LocationService";

import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";
import NumberInput from "components/MainApp/atoms/Forms/NumberInput";

import { EventRepository } from 'helpers/EventRepository';

import "./styles.scss";

const LocationSelectorModal = ({ item_id, locations, handleClose }) => {
  // Tiene lo actual y lo del item
  const [_locations, setLocations] = useState([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    LocationService.locations_node_by_item(item_id).then((response) => {
      response.map((loc_item) => {
        loc_item.unitsSelected = 0;
        if (locations) {
          let loc_cant = locations.find(
            (l) => l.location_id === loc_item.location_id
          );
          if (loc_cant) {
            loc_item.unitsSelected = loc_cant.unitsSelected;
          }
        }

        return loc_item;
      });
      setLocations(response);
    });
  }, []);

  useEffect(() => {
    validDisabled();
  });

  // useEffect(() => {
  //     setLocations([...locations])
  // }, locations)

  const validDisabled = () => {
    let error = _locations.filter((loc) => loc.error != '' && loc.error != undefined);
    let disabled = true;
    let selected = _locations.filter((loc) => loc.unitsSelected > 0);
    if(selected.length > 0){
      if(error.length == 0){
        disabled = false;
      }
    }
    
    setDisabled(disabled);
  }

  const handleChangeUnitsSelected = (id, value) => {
    if (value) {
      value = parseInt(value);
    }

    if(value < 0){
      EventRepository.notificationSend({
        label: 'El valor no puede ser negativo',
        type: 'error'
      });
    }else {
      const __locations = [..._locations];
      const locationIndex = __locations.findIndex(
        (loc) => loc.location_id === id
      );
    
      if(value > __locations[locationIndex].total){
        let count = value - __locations[locationIndex].total;
        __locations[locationIndex].error = `* Supera por ${count} la cantidad disponible`;
      }else {
        __locations[locationIndex].error = "";
      }
      __locations[locationIndex].unitsSelected = value;
      
      setLocations(__locations);
      validDisabled();
    }
  };

  const handleAdd = () => {
    if(!disabled){
      const locationsSelected = _locations.filter((loc) => loc.unitsSelected > 0);
      handleClose && handleClose(locationsSelected);
    }
  };

  const handleErase = () => {
    const locationsSelected = [];
    handleClose && handleClose(locationsSelected);
  };

  return (
    <div className="location-selector-modal-wrapper">
      <div className="location-selector-modal-modal">
        {/* <div className="location-selector-modal-header"></div> */}
        <div className="location-selector-modal-table">
          <div className="location-selector-table-header">
            <h3 className="table-header-title">Ubicación</h3>
            <h3 className="table-header-title">Cantidad</h3>
          </div>
          <div className="location-selector-table-rows">
            {_locations?.map((location) => {
              return (
                <div key={location.location_id} className="location-row">
                  <div className="location-row-left">
                    {/* <div> 
                                    <Checkbox />
                                </div> */}
                    <div>
                      <p className="location-label">
                        {location.location_label}
                      </p>
                      <p className="location-stock">
                        Stock {location.total} UNID.
                      </p>
                    </div>
                  </div>
                  <div className="location-row-right">
                    <NumberInput
                      value={location.unitsSelected}
                      id={location.location_id}
                      handleChange={handleChangeUnitsSelected}
                    />
                    <div className="error">
                      {location.error}
                    </div>
                    
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="location-selector-buttons">
          <div className="location-selector-erase-button" onClick={handleErase}>
            BORRAR
          </div>
          <div className={`location-selector-add-button ${disabled && 'disabled'}`} onClick={handleAdd}>
            AGREGAR
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationSelectorModal;
