import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";


import "./styles.scss";
export class AddConfigTagStepTwo extends React.Component {
  render() {
    const { business, handleChange, goBack, handleContinue, enableContinue, error } = this.props;
    const stepContent = (
      <form onSubmit={handleContinue}>
      <div className="add-config-tag-wizard-step-two-content">
        <div className="add-config-tag-wizard-step-two-field" data-automation-id="input-address">
          <MainInput
            label={"Direccion"}
            required={false}
            id={"business-address"}
            name="address"
            value={business ? business.address : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.address && error.address}
            />
        </div>
        <div className="add-config-tag-wizard-step-two-field" data-automation-id="input-phone">
          <MainInput
              label={"Telefono"}
              required={true}
              id={"business-phone"}
              name="phone"
              value={business ? business.phone : ''}
              handleChange={handleChange}
              maxLength={100}
              error={error.phone && error.phone}

            />
        </div>
        <div className="add-config-tag-wizard-step-two-field" data-automation-id="input-email">
          <MainInput
              label={"Email"}
              required={true}
              id={"business-email"}
              name="email"
              value={business ? business.email : ''}
              handleChange={handleChange}
              maxLength={100}
              error={error.email && error.email}

            />
        </div>
        <div className={"add-config-tag-wizard-step-two-action-wrapper"}>
          <button type="submit" className={`add-config-tag-wizard-step-two-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-config-tag-wizard-step-two-container">
        <Step title="Ingresa información de contacto" goBackFunc={goBack} content={stepContent}></Step>
      </div>
    );
  }
}

export default AddConfigTagStepTwo;
