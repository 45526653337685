import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";


import "./styles.scss";
export class AddConfigTagStepTwo extends React.Component {
  render() {
    const { configTag, handleChange, options, handleSelect, goBack, handleContinue, enableContinue, error } = this.props;
    const stepContent = (
      <form onSubmit={handleContinue}>
      <div className="add-config-tag-wizard-step-two-content">
        <div className="add-config-tag-wizard-step-two-field" data-automation-id="input-height">
          <MainInput
            label={"Alto (mm)"}
            required={true}
            id={"configTag-height"}
            name="height"
            type="number"
            value={configTag ? configTag.height : ''}
            handleChange={handleChange}
            maxLength={100}
            error={error.height && error.height}
            />
        </div>
        <div className="add-config-tag-wizard-step-two-field" data-automation-id="input-width">
          <MainInput
              label={"Ancho (mm)"}
              required={true}
              id={"configTag-width"}
              name="width"
              type="number"
              value={configTag ? configTag.width : ''}
              handleChange={handleChange}
              maxLength={100}
              error={error.width && error.width}

            />
        </div>
        <h2 className="subtitle">Memorias</h2>
        <div className="add-config-tag-wizard-step-two-field" data-automation-id="input-mem_epc">
          <MainDropdownInput
            required={true}
            id={"configTag-mem-epc"}
            name="mem-epc"
            selectedOption={configTag ? configTag.mem_epc : ''}
            handleSelection={(selectedOption) => handleSelect(selectedOption, 'mem_epc')}
            maxLength={50}
            options={options.mem_epc}
            placeholder={'Memoria EPC'} 
            label={"Memoria EPC"}
            error={error.mem_epc && error.mem_epc}
          />
        </div>
        <div className="add-config-tag-wizard-step-two-field" data-automation-id="input-mem_user">
          <MainDropdownInput
            required={true}
            id={"configTag-mem-user"}
            name="mem-user"
            selectedOption={configTag ? configTag.mem_user : ''}
            handleSelection={(selectedOption) => handleSelect(selectedOption, 'mem_user')}
            maxLength={50}
            options={options.mem_user}
            placeholder={'Memoria de usuario'} 
            label={"Memoria de usuario"}
            error={error.mem_user && error.mem_user}
          />
        </div>
        <div className={"add-config-tag-wizard-step-two-action-wrapper"}>
          <button type="button" className={`add-config-tag-wizard-step-two-action-button ${!enableContinue && 'disabled'}`} onClick={enableContinue ? handleContinue : undefined} >
            CONTINUAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-config-tag-wizard-step-two-container">
        <Step title="Medidas" goBackFunc={goBack} content={stepContent}></Step>
      </div>
    );
  }
}

export default AddConfigTagStepTwo;
