import React from "react";
// import i18n from "i18n";
import "./styles.scss";


export const ItemLongSummaryItem = ({item, stock, units, add}) => {
    return(
        <div className="item-summary-container">
            <div className="item-summary-name">{item.name}</div>
            <div className="item-summary-brand">{item.brand && item.brand.name}</div>
            <div className="item-summary-code">{item.sku}</div>
            {!add && units && <div className="item-summary-units">{units} unidades</div>}
            {add && <div className={`item-summary-units ${stock > 0 ? '' : 'disabled'}`}>{stock > 0 ? 'Con stock' : 'Sin stock'} </div>}
            <div className="item-summary-divider"></div>
        </div>
    )
}

export default ItemLongSummaryItem;