import DeviceSelector from "components/Functionals/DeviceSelector";
import Icon from "components/MainApp/atoms/Icon/icon";
import InputText from "components/MainApp/atoms/Forms/MainInput";
import "./styles.scss";
import { useState, useCallback } from "react";
import { WSService } from "services/WSServices";

export const TagsReader = ({
  enabledRead,
  typeRead,
  refRead,
  associateRead,
  priorityRead,
  handleSearch,
  auxContent
}) => {
  const [searchedValue, setsearchedValue] = useState("");

  const searchValue = e => {
    setsearchedValue(e.target.value.trim());
  };

  const handleSubmit = function(e) {
    e.preventDefault();
    handleSearch(searchedValue);
    setsearchedValue("");
  };

  const receiveEvent = useCallback(payload => {
    const ws = WSService.getInstance();
    console.log("Message from socket");
    console.log(payload);

    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {
      if ("items" in payload.params) {
        //setmovementItems(payload.params.items);
      } else {
        handleSearch(payload.params.epc);
        setsearchedValue("");
      }
    }
  }, []);

  return (
    <div className="tags-reader-container">
      <div className="tags-reader-content">
        <DeviceSelector
          enabled={enabledRead}
          typeRead={typeRead}
          refRead={refRead}
          associateRead={associateRead}
          priorityRead={priorityRead}
          receiveEvent={receiveEvent}
          includeType={"MOB_READER"}
        />
        <div className="tags-reader-field" data-automation-id="input-search">
          <div className="associate-tags-title">
            Ingresa el nro. o escanea TagID
          </div>
          <form className="load-movement-items-input" onSubmit={handleSubmit}>
            <InputText
              label="Ingresa número"
              handleChange={searchValue}
              value={searchedValue}
            />
            <Icon name="barcode" width={30} height={25} fill={"#3C7AF5"} />
          </form>

          {auxContent && <div className="auxiliar-container">{auxContent}</div>}
        </div>
      </div>
    </div>
  );
};

export default TagsReader;
