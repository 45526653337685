const getUrl = (orderId, orderType, node = '') => {
  let url = null;
  switch (orderType) {
    case "REQ":
      url = `/admin/orders/request/details/${orderId}`;
      break;
    case "PRE":
      url = `/admin/orders/preparation/details/${orderId}${node ? '/' + node : ''}`;
      break;
    case "REC":
      url = `/admin/orders/reception/details/${orderId}${node ? '/' + node : ''}`;
      break;
    // default:
    //   url = null;
  }
  return url;
};

export { getUrl };
