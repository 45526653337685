import React, {useState} from 'react'

import TwoOptionModal from "../../../../../atoms/Modals/TwoOptionModal";
import MainTextArea from "../../../../../atoms/Forms/MainTextArea"
import "./styles.scss"
import { isMobile } from '../../../../../../../helpers/Mobile';

const RejectModal = ({open, handleClose, handleReject}) => {
    const [reason, setreason] = useState('');

    const handleChange = (e) => {
        setreason(e.target.value);
    }

    const handleConfirm = () => {
        handleReject(reason)
        setreason('')
    }

    const handleCloseIntern = () => {
        setreason('')
        handleClose()
    }

    const mwidth = isMobile ? '100% ': '322px';
    const modalContent = <div className="reject-modal-text-area" style={{width: mwidth, justifyContent: 'center', display: 'flex'}}>
        <MainTextArea
            label={'Descripción'}
            required={false}
            name={'reason'}
            disabled={false}
            handleChange={handleChange}
            value={reason}
        ></MainTextArea>
    </div>
    return(
        <TwoOptionModal open={open} 
            title={'Rechazar orden'}
            subtitle={<span style={{fontFamily: 'Ubuntu-Medium', color: '#676F8F'}}>Ingresa el motivo del rechazo</span>}
            closeLabel={'CANCELAR'}
            confirmLabel={'RECHAZAR'}
            handleClose={handleCloseIntern}
            handleConfirm={handleConfirm}
            modalContent={modalContent}
            confirmDisabled={reason.length <= 0}
        ></TwoOptionModal>
    )

}


export default RejectModal