import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";

import Environment from "environment";

import { StockService } from "services/StockService";
import { EventRepository } from "helpers/EventRepository";

import { WSService } from "services/WSServices";

import useRequest from "hooks/useRequest";

import "./styles.scss";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import ErrorModal from "components/MainApp/organisms/Stock/ErrorModal";

import LocalizationItems from "./LocalizationItems";

import { stockByItemAndLocation } from "helpers/stock/StockHelper";
import { stepLocalizations } from "views/Admin/ExtraMenu/data/stepLocalizations";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";

let globalStock = [];
let globalSimilar = false;

export const LocalizationTagView = props => {
  const history = useHistory();

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [deviceErrorModal, setDeviceErrorModal] = useState(false);
  const [deviceErrorMessage, setDeviceErrorMessage] = useState("");

  const [similar, setSimilar] = useState(false);
  const [stock, setStock] = useState([]);
  const [groupStock, setGroupStock] = useState([]);
  const [groupSimilarStock, setGroupSimilarStock] = useState([]);

  const goBackModal = () => {
    history.push({
      pathname: "/admin/stock",
      state: { step: stepLocalizations }
    });
  };

  useEffect(() => {
    globalStock = stock;

    const groupStock = stockByItemAndLocation(stock);
    setGroupStock(groupStock);
  }, [stock]);

  const setErrorModal = message => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const handleSimilarStatus = () => {
    handleDeleteAll();
    globalSimilar = !similar;
    setSimilar(!similar);
  };

  const receiveEvent = useCallback(payload => {
    const ws = WSService.getInstance();
    console.log("Message from socket");
    console.log(payload);

    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {
      newRead(globalStock, payload.params);
    }
  }, []);

  const newRead = (stock, data) => {
    if (globalSimilar && stock.length > 0) {
      EventRepository.notificationSend({
        label: "Debe limpiar antes de leer otro tag",
        type: "error"
      });
      return;
    }

    if (data.stock) {
      let newStock = [...stock];
      if (!newStock.find(s => s.id === data.stock.id)) {
        newStock.push(data.stock);
      }
      setStock(newStock);
      getSimilares(data.stock);
      return;
    }
    if (data.tag) {
      // Aviso: Existe el tag pero no el producto
      setErrorModal(
        `El tag ${data.tag.rfid} no se encuentra asociado a ningun producto`
      );
      return;
    }

    // Aviso: No existe el epc
    setErrorModal(
      `El tag ${data.epc} que se leyó no se encuentra cargado en el sistema`
    );
  };

  const handleStockSearch = cod => {
    cod = cod?.trim();
    if (!cod) return;

    if (similar && stock.length > 0) {
      EventRepository.notificationSend({
        label: "Debe limpiar antes de leer otro tag",
        type: "error"
      });
      return;
    }

    beforeSubmit();
    StockService.localization(cod)
      .then(response => {
        afterSubmit();
        if (response.stock) {
          let newStock = [...stock];
          if (!newStock.find(s => s.id === response.stock.id)) {
            newStock.push(response.stock);
          }
          setStock(newStock);
          getSimilares(response.stock);
          return;
        }
        if (response.tag) {
          // Aviso: Existe el tag pero no el producto
          setErrorModal(
            `El tag ${response.tag.rfid} no se encuentra asociado a ningun producto`
          );
          return;
        }

        // Aviso: No existe el epc
        setErrorModal(
          `El tag ${cod} que se leyó no se encuentra cargado en el sistema`
        );
      })
      .catch(_ => {
        afterSubmit();
        // Aviso: No existe el epc
        setErrorModal(
          `El tag ${cod} que se leyó no se encuentra cargado en el sistema`
        );
      });
  };

  const getSimilares = itemStock => {
    if (!globalSimilar) {
      return;
    }

    beforeSubmit();
    StockService.similars(itemStock.id, {
      no_page: 1,
      status: "LOC"
      // location__node: itemStock.location.node.code, Buscar en todos los nodos
    })
      .then(stock => {
        afterSubmit();
        const groupSimilarStock = stockByItemAndLocation(stock);
        setGroupSimilarStock(groupSimilarStock);
      })
      .catch(_ => {
        afterSubmit();
      });
  };

  const handleDelete = item => {
    let newStock = [...stock];
    setStock(newStock.filter(s => s.tag.rfid != item.tag));
    setGroupSimilarStock([]);
  };

  const handleDeleteAll = e => {
    setStock([]);
    setGroupSimilarStock([]);
  };

  const handleDeviceError = message => {
    setDeviceErrorMessage(message);
    setDeviceErrorModal(true);
  };

  const content = (
    <LocalizationItems
      total={stock.length}
      groupItems={groupStock}
      groupSimilarItems={groupSimilarStock}
      similar={similar}
      handleSimilarStatus={handleSimilarStatus}
      handleSearch={handleStockSearch}
      handleDelete={handleDelete}
      handleDeleteAll={handleDeleteAll}
      enabledRead={true}
      typeRead={"STOCK"}
      refRead={""}
      receiveEvent={receiveEvent}
      onDeviceError={handleDeviceError}
    />
  );

  return (
    <div className="prep-order-details-container">
      <AdminLayout
        headerTitle={i18n.t("Localización de tags")}
        headerOptions={[]}
        content={content}
        goBackFunc={goBackModal}
        navHidden={true}
      ></AdminLayout>

      <GenericErrorModal
        open={deviceErrorModal}
        handleClose={() => setDeviceErrorModal(false)}
        error={deviceErrorMessage}
      ></GenericErrorModal>

      <ErrorModal
        open={openErrorModal}
        message={errorMessage}
        handleGoBack={e => {
          setOpenErrorModal(false);
        }}
        handleClose={e => {
          setOpenErrorModal(false);
        }}
        handleAddLocalization={e => {
          history.push("/admin/businessnodes");
        }}
        handleButtonClick={e => {
          setOpenErrorModal(false);
        }}
      />

      {loading && <Loader />}
    </div>
  );
};

export default LocalizationTagView;
