const headers = [
  {
    id: "date",
    label: "FECHA",
    active: true,
    numeric: false,
    disablePadding: true,
    type: "Tooltip",
    style: {
      color: "#676f8f"
    }
  },
  {
    id: "type",
    label: "TIPO DE ORDEN",
    active: true,
    numeric: false,
    disablePadding: true,
    type: "Tooltip",
    style: {
      color: "#494f66",
      fontSize: "19px",
      fontFamily: "Ubuntu-Medium"
    }
  },
  {
    id: "order",
    label: "ORDEN",
    active: true,
    numeric: false,
    disablePadding: true,
    type: "Tooltip",
    style: {
      color: "#676f8f"
    }
  },
  {
    id: "reason",
    label: "MOTIVO",
    active: true,
    numeric: false,
    disablePadding: true,
    type: "Tooltip",
    style: {
      color: "#676f8f"
    }
  },
  {
    id: "in",
    label: "ENTRADAS",
    active: true,
    numeric: false,
    disablePadding: true,
    style: {
      color: "green"
    }
  },
  {
    id: "out",
    label: "SALIDAS",
    numeric: false,
    disablePadding: true,
    style: {
      color: "red"
    }
  },
  {
    id: "total",
    label: "SALDO",
    active: false,
    numeric: false,
    disablePadding: true,
    style: {
      color: "#494f66",
      fontSize: "19px",
      fontFamily: "Ubuntu-Medium"
    }
  }
];

export default headers;
