import React from "react";

import Step from "components/MainApp/atoms/Wizard/Step";
import ProductSummary from "components/MainApp/organisms/Orders/RequestOrders/ProductSummary";
import Icon from "components/MainApp/atoms/Icon/icon";
import "./styles.scss";

export const RelocationOrderStep4 = ({
  destination,
  origin,
  products,
  handleEditDestination,
  handleEditOrigin,
  handleEditProducts
}) => {
  const stepContent = (
    <div className="relocation-order-step-four-content">
      <div
        className="relocation-order-step-four-field"
        data-automation-id="input-search"
      >
        <div className="relocation-order-step-four-row">
          <div className={`relocation-order-step-title`}>
            <div className={`relocation-order-step-title-wrapper`}>
              ORIGEN DE ORDEN{" "}
            </div>
            <div
              className="relocation-order-step-four-icon-wrapper"
              onClick={handleEditOrigin}
            >
              <Icon name="edit2" width={20} height={20} fill={"#3C7AF5"} />
            </div>
          </div>
          <div className={`relocation-order-step-destination`}>{origin}</div>
        </div>
        <div className="relocation-order-step-four-row">
          <div className={`relocation-order-step-title`}>
            <div className={`relocation-order-step-title-wrapper`}>
              DESTINO DE ORDEN{" "}
            </div>
            <div
              className="relocation-order-step-four-icon-wrapper"
              onClick={handleEditDestination}
            >
              <Icon name="edit2" width={20} height={20} fill={"#3C7AF5"} />
            </div>
          </div>
          <div className={`relocation-order-step-destination`}>{destination}</div>
        </div>

        <div className="relocation-order-step-four-row">
          <ProductSummary
            products={products}
            edit={true}
            handleEdit={handleEditProducts}
          />
        </div>
      </div>
    </div>
  );
  return (
    <div className="relocation-order-step-four-container">
      <Step title="Verifica los datos" content={stepContent}></Step>
    </div>
  );
};

export default RelocationOrderStep4;
