import React from 'react';
// import imageFile from '../../../../../assets/images/logo.png';
import imageFile from '../../../../../assets/images/logo color.png';

// import imageFileWhite from '../../../../../assets/images/marca_puntoverde.png';
import imageFileWhite from '../../../../../assets/images/logo blanco.png';


import './styles.scss';

export class Logo extends React.Component {
    
    render() {
        const {
           white = false 
        } = this.props;
        return (
            <div className="logo-container">
                {!white && <img src={imageFile} alt="logo"/>}
                {white && <img src={imageFileWhite} alt="logo"/>}
            </div>
        )
    }

}


Logo.propTypes = {
    
};

export default Logo;