const data = {
  initialOrder: {
    origin: "",
    destination: "",
    type: "REL",
  },
  headerOptions: [],
  steps: [
    {
      id: "0",
      name: "Origen",
    },
    {
      id: "1",
      name: "Productos",
    },
    {
      id: "2",
      name: "Destino",
    },
    {
      id: "3",
      name: "Confirmación",
    },
  ],
};

export default data;
