import React from "react";
import ProductTableItem from "./TableItem";
import ProductTableItemMobile from "./TableItemMobile";
import CheckboxCostumAuth from "components/Auth/atoms/forms/CheckboxCostum";

import "./styles.scss";
export class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      selected: null,
    };
  }

  handleSelectAll = () => {
    let selectAll = this.state.selectAll;
    this.setState({
      selectAll,
    });
    if (this.props.handleSelectAll) this.props.handleSelectAll();
  };

  handleSelectOne = (selected) => {
    if (this.state.selected === selected) {
      selected = null;
    }
    this.setState({
      selectAll: false,
      selected,
    });
  };

  render() {
    const { products, headers, headerClickHandler, handleClickProduct } = this.props;
    const table = (
      <React.Fragment>
        <div className="table-desktop" data-automation-id="product-table">
          <div className="table-header">
            {/* <div className="table-header-item table-header-checkbox">
              <CheckboxCostumAuth
                handleChange={this.handleSelectAll}
                checked={this.state.selectAll}
              ></CheckboxCostumAuth>
            </div> */}
            <div className="table-header-item table-header-image"></div>
            <div data-automation-id="sort-by-name" onClick={() => {headerClickHandler('name')}} data-filter-name='name' className="table-header-item table-header-name">
              NOMBRE <span className={`table-header-icon-span ${headers.name.active ? "active" : ""}  ${headers.name.direction ? "" : "down"}`}> <i className="icon-iconos_flecha-ordenar"></i></span>
            </div>
            <div data-automation-id="sort-by-brand" onClick={() => {headerClickHandler('brand__name')}} data-filter-name='brand__name' className="table-header-item table-header-brand">
              MARCA <span className={`table-header-icon-span ${headers.brand__name.active ? "active" : ""}  ${headers.brand__name.direction ? "" : "down"}`}> <i className="icon-iconos_flecha-ordenar"></i></span>
            </div>
            <div data-automation-id="sort-by-sku" onClick={() => {headerClickHandler('sku')}} data-filter-name='sku' className="table-header-item table-header-code">
              CÓDIGO <span className={`table-header-icon-span ${headers.sku.active ? "active" : ""}  ${headers.sku.direction ? "" : "down"}`}> <i className="icon-iconos_flecha-ordenar"></i></span>
            </div>
            <div data-automation-id="sort-by-categoria" onClick={() => {headerClickHandler('categories__name')}} data-filter-name='categories__name' className="table-header-item table-header-category">
              CATEGORÍA <span className={`table-header-icon-span ${headers.categories__name.active ? "active" : ""}  ${headers.categories__name.direction ? "" : "down"}`}> <i className="icon-iconos_flecha-ordenar"></i></span>
            </div>
            {/* <div data-automation-id="sort-by-stock" onClick={() => {headerClickHandler('stock')}} data-filter-name='stock' className="table-header-item table-header-stock">
              STOCK <span className={`table-header-icon-span ${headers.stock.active ? "active" : ""}  ${headers.stock.direction ? "" : "down"}`}> <i className="icon-iconos_flecha-ordenar"></i></span>
            </div> */}
            <div className="table-header-item table-header-button"></div>
          </div>
          <div className="table-content">
            {products.map((product) => {
              return (
                <div className="table-content-item" key={product.id}>
                  <ProductTableItem
                    product={product}
                    isSelected={
                      this.state.selected === product.id || this.state.selectAll
                    }
                    handleSelect={this.handleSelectOne}
                    handleClick={handleClickProduct}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="table-mobile" data-automation-id="product-table">
          <div className="table-content">
            {products.map((product) => {
              return (
                <div className="table-content-item" key={product.id}>
                  <ProductTableItemMobile
                    product={product}
                    isSelected={
                      this.state.selected === product.id || this.state.selectAll
                    }
                    handleSelect={this.handleSelectOne}
                    handleClick={handleClickProduct}

                  />
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );

    return <div className="table-container">{table}</div>;
  }
}

ProductTable.propTypes = {};

export default ProductTable;

