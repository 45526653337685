import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
import RoundedButton from "../../../../../components/Auth/atoms/buttons/RoundedButton";
import ModalButton from "../../../../../components/Auth/atoms/modals/ModalButton";
import CostumInput from "../../../../../components/Auth/atoms/forms/InputCostum";
import PasswordInput from "../../../../../components/Auth/atoms/forms/InputPasswordAuth";

import CostumCheckbox from "../../../../../components/Auth/atoms/forms/CheckboxCostum";
import Logo from "../../../../../components/Auth/atoms/images/Logo";

export class Login extends React.Component {
  render() {
    const {
      handleChange,
      handleBlur,
      rememberUser,
      rememberUserChange,
      handleSubmit,
      buttonState = "disabled",
      errors,
      errorMessage,
    } = this.props;

    return (
      <div className="sign-in">
        <form onSubmit={handleSubmit}>
          <Logo></Logo>
          <p className="title">Ingresa a tu cuenta</p>
          <div className="form-container">
            <div className="inputs-wrapper">
              <CostumInput
                id={"email"}
                placeholder="Email"
                icon="icon-mail"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errorMessage ? " " : errors.email}
              ></CostumInput>
              <PasswordInput
                handleChange={handleChange}
                id={"password"}
                error={errorMessage ? errorMessage : errors.password}
              ></PasswordInput>
            </div>
            <div className="remember-row">
              <CostumCheckbox
                label={"Recordarme"}
                checked={rememberUser}
                handleChange={rememberUserChange}
              ></CostumCheckbox>
              <Link to="/forgotten" className="link">
                <button data-automation-id="forgot password" type="button">
                  Olvidé mi contraseña
                </button>
              </Link>
            </div>
          </div>
          <RoundedButton
            id="button-login"
            legend="Ingresar"
            handleClick={handleSubmit}
            state={buttonState}
          ></RoundedButton>
        </form>
      </div>
    );
  }
}

Login.propTypes = {};

export default Login;
