class DeviceType {
  id = null;
  type = null;
  name = null;
  parent_id = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }

}

export { DeviceType };
