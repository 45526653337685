import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import "./styles.scss";

const DropdownOptions = ({ options, selectedOption, label, handleChange }) => {
  const [opened, setOpened] = useState(false);
  const [internalSelectedOption, setInternalSelectedOption] = useState("");
  useEffect(() => {
    const op = options.find((option) => option.key === selectedOption);

    setInternalSelectedOption(op);
  }, [selectedOption, options]);

  const selectOption = (event) => {
    const key = event.target.dataset.key;
    const op = options.find((option) => option.key === key);

    setInternalSelectedOption(op);
    if(handleChange) handleChange(key);
    
    closeOptions();
  };

  const toggleOpen = () => {
    const openedNew = !opened;
    setOpened(openedNew);
  };

  const closeOptions = () => {
    setOpened(false);
  };

  return (
    <div className={"dropdown-options-container"}>
      <p className={"dropdown-options-header"} onClick={toggleOpen}>
        {label && <span className={"dropdown-options-label"}>{label}</span>}{" "}
        {internalSelectedOption.value}
        <i className="icon-chevron-down dropdown-options-icons"></i>
      </p>
      {opened && (
        <div className={"dropdown-options-options"}>
          {options.map((option) => {
            return (
              <div
                key={option.key}
                className={"dropdown-options-option"}
                data-key={option.key}
                onClick={selectOption}
              >
                {" "}
                {option.value}{" "}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropdownOptions;
