import {
  TableRow,
  TableHead,
  TableCell,
  TableSortLabel,
  Box
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import style from "./TableHeadComponent.module.scss";

const TableHeadComponent = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  LeftIcon,
  RightIcon
}) => {
  const createSortHandler = property => event => {
    const isDisabled = headCells.find(cell => cell.id === property)?.active === false;
    if (!isDisabled) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {LeftIcon && <TableCell />}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={style["head-container"]}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className={style["head-item"]}
              hideSortIcon={headCell?.active === false}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {RightIcon && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadComponent;
