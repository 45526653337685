import React from "react";
import GenericModal from "components/MainApp/atoms/Modals/GenericModal";
import { Button } from "@mui/material"

import "./styles.scss";
const DeleteReceptionTagsModal = ({ open, handleClose, handleCancel, handleConfirm}) => {
    const content = <div className="delete-reception-tags-modal-content">
        <div className="title">Quitar tags agregados</div>
        <div className="subtitle">¿está seguro de querer eliminar los tags leídos?</div>
        <div className="delete-reception-tags-modal-buttons">
          <div
              onClick={handleCancel}
              data-automation-id="cancel"
            >
              CANCELAR
            </div>
          <Button className="btn-continue" onClick={handleConfirm}>CONTINUAR</Button>
        </div>
    </div>
    return (
      <div className="delete-reception-tags-modal-container">
          <GenericModal
            open={open}
            content={content}
            handleClose={handleClose}></GenericModal>
      </div>
    );
}


export default DeleteReceptionTagsModal;