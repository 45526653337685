/**
 * Parsea los items de orden de forma que queden para manipularle
 * la cantidad a seleccionar.
 * @param {Dict[]} items Arreglo de items de orden
 * @return {Dict[]} [ { item, sku, quantity ] } ]
 */
const parseItems = (items) => {
  let newList = items.map((item) => {
    let newItem;
    newItem = item.item;
    newItem.units = item.quantity;
    newItem.code = item.item.sku; // Integrar con el campo de abajo
    newItem.sku = item.item.sku;
    newItem.stock = 1;
    newItem.extra = item.extra;
    return item.item;
  });

  return newList;
};

/**
 * Parsea items de forma que sirvan para ser manipulados en el search
 * y seleccionar cantidad para agregar items.
 * @param {Dict[]} items Arreglo de items de orden
 * @return {Dict[]} [ { ...item, selected, units, stock ] } ]
 */
const parseItemsForSearch = (items, actualItems = []) => {
  let newList = items.map((item) => {
    item.selected = false;
    item.units = 1;
    item.stock = 1;
    item.extra = [];
    const locItem = actualItems.find((i) => i.id === item.id);
    if (locItem) {
      item.extra = locItem.extra || [];
    }
    return item;
  });

  return newList;
};

/**
 * Resive un arreglo de item y lo parsea para enviar al servicio.
 * @param {Dict[]} items Arreglo generado por parseItem()
 * @return {Dict[]} [ { item_id, quantity, extra ] } ]
 */
const itemsToSend = (items) => {
  return items.map((item) => {
    return {
      item_id: item.id,
      quantity: item.units,
      extra: item.extra,
    };
  });
};

export { parseItems, parseItemsForSearch, itemsToSend };
