const headers = {
  name: {
    name: 'name',
    title: 'NOMBRE',
    active: false,
    direction: false
  },
  type: {
    name: 'type',
    title: 'TIPO',
    mobileTag: false,
    pill: true,
    pillMap: {
      BOX: 'Caja',
      PALLET: 'Palets',
    },
    pillMapColor: {
      BOX: '#3C7AF5',
      PALLET: '#3C7AF5',
    },
  },
  quantity: {
    name: 'quantity',
    title: 'CANTIDAD',
    active: false,
    direction: false
  },
  product: {
    name: 'product',
    title: 'PRODUCTO',
    active: false,
    direction: false
  },
}

export default headers;