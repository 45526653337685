import React, {useEffect} from "react";
import { isMobile } from "helpers/Mobile";
import BusinessTableItem from "./BusinessTableItem";
import Icon from "components/MainApp/atoms/Icon/icon";

import "./styles.scss";

const BusinessTable = ({ businesses, headers, filtersApplied, handleClick, headerClickHandler }) => {

  const renderHeaderItem = (header) => {
    return (
      <div
        data-automation-id={`sort-by-${header.name}`}
        onClick={() => {
          headerClickHandler(header.name);
        }}
        data-filter-name={header.name}
        className={`business-table-header-item business-table-header-${header.name}`}
        key={header.name}
      >
        {header.title}
        {header.name !== 'image' && <span className={`business-table-header-icon-span ${header.active ? "active" : ""}  ${header.direction ? "" : "down"}`}> <i className="icon-iconos_flecha-ordenar"></i></span>}
      </div>
    );
  };
  const table = (
    <React.Fragment>
      <div className="business-table-desktop" data-automation-id="apps-table">
        {!isMobile && (
          <div className="business-table-header">
            {Array.isArray(headers) && headers.map((header) => renderHeaderItem(header))}
            {!Array.isArray(headers) && Object.keys(headers).map((key) => renderHeaderItem(headers[key]))}
            <div className="business-table-header-item business-table-header-button"></div>
          </div>
        )}
        <div className="business-table-content">
          {businesses?.map((app) => {
            return (
              <div className="business-table-content-item" key={app.id}>
                <BusinessTableItem business={app} handleClick={handleClick} />
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );

  const noitems = (
    <div className={"business-table-no-items"}>
      <Icon name="apps" fill="#3C7AF5" width={87} height={65}></Icon>
      <h1>Aún no tienes resultado</h1>
    </div>
  );

  return (
    <div className="business-table-container">
      {businesses?.length > 0 ? table : !filtersApplied ? noitems : ""}
    </div>
  );
};

export default BusinessTable;
