import React from "react";

import "./styles.scss";
export class Pagination extends React.Component {

  setPageArray = (currentPage, pageQuantity) => {
    let firstIndex;
    if (pageQuantity <= 4) {
      return Array(pageQuantity)
        .fill(0)
        .map((v, i) => i + 1);
    }

    if (currentPage - 1 <= 0) {
      firstIndex = 1;
    } else {
      if (currentPage + 2 > pageQuantity) {
        firstIndex = pageQuantity - 3;
      } else {
        firstIndex = currentPage - 1;
      }
      
    }
    return Array(4)
        .fill(0)
        .map((v, i) => i + firstIndex);
  };

  render() {
    const { pageQuantity, currentPage, pageChangeHandler } = this.props;

    
    const arr = this.setPageArray(currentPage, pageQuantity);
    const last = arr[arr.length - 1];
    const first = arr[0];

    return (
      <div className="pagination-container">
        <div className="pagination-numbers">
          {first !== 1 && (
            <div
              className="pagination-item pagination-dots izq"
              onClick={() => pageChangeHandler(1)}
              data-automation-id="pagination-first-page"
            >
              <i className="icon-chevron-izquierda pagination-icon"></i> ...{" "}
            </div>
          )}
          {
            arr.map((i) => {
              return (
                <div
               data-automation-id={"pagination-page-"+i}

                  key={i}
                  className={
                    currentPage === i
                      ? "pagination-item pagination-number active"
                      : "pagination-item pagination-number"
                  }
                  onClick={() => pageChangeHandler(i)}
                >
                  {i}
                </div>
              );
            })}
        </div>

        {pageQuantity !== last && (
          <div
            data-automation-id="pagination-last-page"

            className="pagination-item pagination-dots"
            onClick={() => pageChangeHandler(pageQuantity)}
          >
            ...<i className="icon-chevron-derecha pagination-icon"></i>
          </div>
        )}
      </div>
    );
  }
}

Pagination.propTypes = {};

export default Pagination;
