import React, { useEffect, useState } from "react";
import renderInput from "helpers/FormGenerator";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";

import "./styles.scss";
import FilterPanel from "../../Filters/FilterPanel";
import SimpleSearchPanel from "../../Filters/SimpleSearchPanel";
import useFilters from "hooks/useFilters";
import AssociationProductFilter from "./AssociationProductFilter";
import { useHistory } from "react-router";
import useRequest from "hooks/useRequest";
import { PublicService } from "services/PublicService";
import { StockService } from "services/StockService";
import LocalizationProductItem from "shared/components/LocalizationProductItem";
import AssociationProductItem from "shared/components/AssociationProductItem";
import { ProductService } from "services/ProductService";

export const TagAssociationModal = ({
  fields,
  handleClose,
  handleSelect,
  handleAssociateTag
}) => {
  const [itemId, setItemId] = useState(null);

  const [product, setProduct] = useState(null);
  const { loading, afterSubmit } = useRequest();

  const handleSelectedItem = item => {

    if (item && item.key) {
      ProductService.product(item.key).then(
        response => {
          setProduct(response);
          handleSelect(response);
        },
        error => {
          setProduct(null);
          handleSelect(null);
        }
      );
    } else {
      setProduct(null);
      handleSelect(null);
    }
  };

  const content = (
    <div className="location-modal-content-wrapper">
      <div className="location-modal-content">
        <AssociationProductFilter handleApply={handleSelectedItem} />
        {product && <AssociationProductItem product={product} />}
      </div>
      <div className="load-movement-items-buttons-wrapper">
        <div
          className={`load-movement-items-button ${product ? "" : "disabled"}`}
          onClick={handleAssociateTag}
        >
          ASOCIAR
        </div>
      </div>
    </div>
  );
  return (
    <div className="location-modal-container">
      <AdminLayout
        headerTitle={"Asociar a Producto"}
        headerOptions={[]}
        content={content}
        closeFunc={handleClose}
        navHidden={true}
      ></AdminLayout>
    </div>
  );
};

export default TagAssociationModal;
