import React, { useState } from "react";
import RequestTableItem from "./TableItem";
import RequestTableItemMobile from "./TableItemMobile";
import { isMobile } from "helpers/Mobile";

import "./styles.scss";
const RequestOrderTable = ({
  orders,
  headers,
  headerClickOrder,
  handleClickOrder,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleSelectOne = (selectedNew) => {
    if (selected === selectedNew) {
      setSelected(null);
    }
    setSelectAll(false);
  };

  // let isMobile = true
  const table = (
    <div className="dashboard-table" data-automation-id="dashboard-table">
      <div className="dashboard-table-header">
        {Object.keys(headers).map((headerKey, index) => {
          let header = headers[headerKey];
          return (
            <div
              key={header.name}
              data-automation-id={`sort-by-${header.name}`}
              onClick={() => {
                headerClickOrder(header.name);
              }}
              data-filter-name={header.name}
              className={`dashboard-table-header-item table-header-${header.name}`}
            >
              {header.title}{" "}
              <span
                className={`dashboard-table-header-icon-span ${
                  header.active ? "active" : ""
                }  ${header.direction ? "" : "down"}`}
              >
                {" "}
                <i className="icon-iconos_flecha-ordenar"></i>
              </span>
            </div>
          );
        })}
        <div className="table-header-action"></div>
      </div>
      <div className="dashboard-table-content">
        {orders.map((requestOrder) => {
          return (
            <div className="dashboard-table-content-item" key={requestOrder.id}>
              <RequestTableItem
                requestOrder={requestOrder}
                isSelected={selected === requestOrder.id || selectAll}
                handleSelect={handleSelectOne}
                handleClick={handleClickOrder}
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  const tableMobile = (
    <div
      className="dashboard-table-mobile"
      data-automation-id="product-dashboard-table"
    >
      <div className="dashboard-table-content">
        {orders.map((requestOrder) => {
          return (
            <div className="dashboard-table-content-item" key={requestOrder.id}>
              <RequestTableItemMobile
                requestOrder={requestOrder}
                isSelected={selected === requestOrder.id || selectAll}
                handleSelect={handleSelectOne}
                handleClick={handleClickOrder}
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div className="dashboard-table-container">
      {isMobile ? tableMobile : table}
    </div>
  );
};

export default RequestOrderTable;
