import React from "react";
import GenericModal from "components/MainApp/atoms/Modals/GenericModal";
import { Button } from "@material-ui/core";

import "./styles.scss";
const BatchTagsErrorModal = ({ open, handleClose, handleDownload}) => {
    const content = <div className="batch-tags-error-content">
        <div className="icon-div"><i className="icon-error icon-iconos_alerta"></i></div>
        <div className="batch-tags-error-title">Contenido erróneo</div>
        <div className="batch-tags-error-subtitle">Hay errores en el archivo subido. Puede verlos en el siguiente fichero</div>
        <div className="batch-tags-error-buttons">
            <div onClick={handleDownload}>
              <i className="wrong-content-modal-icon icon-iconos_descargar"></i>
              Descargar resumen
            </div>
        </div>
        <div className="btn-close-wrapper">
               <Button className="btn-close" onClick={handleClose}>CERRAR</Button>
            </div>
    </div>
    return (
      <div className="batch-tags-error-container">
          <GenericModal
            open={open}
            content={content}
            handleClose={handleClose}></GenericModal>
      </div>
    );
}


export default BatchTagsErrorModal;