import React from "react";
import PropTypes from "prop-types";
import imageFile from "../../../../../assets/images/image-placeholder.png";

import "./styles.scss";

export class DragAndDropImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: this.props.file ? this.getUrl(this.props.file) : null,
      drag: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  dropRef = React.createRef();

  getUrl(file) {
    if (this.props.error) return;
    if (typeof(file) === 'string' && file.startsWith("http")) return file;
    return URL.createObjectURL(file);
  }

  handleChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });

    if (this.props.handleChange) this.props.handleChange(event);
  }

  handleDelete(event) {
    this.setState({
      file: null,
    });

    if (this.props.handleDelete) this.props.handleDelete(event);
  }

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      drag: false,
      file: URL.createObjectURL(e.dataTransfer.files[0]),
    });

    if (this.props.handleChange)
      this.props.handleChange({
        target: {
          name: this.props.name,
          type: "file",
          files: e.dataTransfer.files,
        },
      });

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  render() {
    const { name, isSmall } = this.props;

    return (
      <div className={`drag-and-drop-image-container ${isSmall ? 'small' : ''}`}>
        <div className="drag-and-drop-image-wrapper" ref={this.dropRef}>
          {!this.state.file && (
            <div className="drag-and-drop-image-input-wrapper">
              <img src={imageFile} alt="product" />

              <div className="drag-and-drop-image-text-wrapper">
                <div className="drag-and-drop-image-text">
                  Arrastra la imagen aquí
                </div>
                <div className="drag-and-drop-image-text">ó</div>
              </div>

              <label className="custom-file-upload">
                Selecciona aquí
                <input
                  type="file"
                  name={name}
                  accept="image/png, image/jpeg"
                  onChange={this.handleChange}
                ></input>
              </label>
              <label className="drag-and-drop-image-help">
                (Tamaño máx 1MB)
              </label>
            </div>
          )}

          {!this.state.file && (
            <div className="drag-and-drop-image-input-wrapper-mobile">
              <label className="custom-file-upload" htmlFor="mobile-input-file">
                <div className="custom-file-wrapper">
                  <img src={imageFile} alt="product" />
                </div>
              </label>
              <input
                type="file"
                id="mobile-input-file"
                name={name}
                accept="image/png, image/jpeg"
                onChange={this.handleChange}
              ></input>

              <div className="drag-and-drop-bottom-label">
                <label
                  className="drag-and-drop-bottom-label-add"
                  htmlFor="mobile-input-file"
                >
                  Agregar imagen
                </label>
                <label className="drag-and-drop-image-help">
                  (Tamaño máx 1MB)
                </label>
              </div>
            </div>
          )}

          {this.state.file && (
            <div className="drag-and-drop-image-input-preview">
              <img src={this.state.file} alt="product" />
              <div
                className="drag-and-drop-image-delete"
                onClick={this.handleDelete}
                data-automation-id="delete-image"
              >
                <i className="icon-iconos_eliminar drag-and-drop-image-icon"></i>
                Eliminar
              </div>
            </div>
          )}

          {this.props.error && (
            <div className="drag-and-drop-image-error">{this.props.error}</div>
          )}
        </div>
      </div>
    );
  }
}

DragAndDropImage.propTypes = {
  handleChange: PropTypes.func,
};

export default DragAndDropImage;
