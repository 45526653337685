import React, { useState } from "react";
import Icon from "../../../../../../components/MainApp/atoms/Icon/icon";
import InputText from "../../../../../../components/MainApp/atoms/Forms/MainInput";
import ItemTags from "components/MainApp/organisms/OrdersV3/Item-Tags";
import DeviceSelector from "components/Functionals/DeviceSelector";

import "./styles.scss";

export const LoadMovementItems = ({
  enabledRead,
  typeRead,
  refRead,
  receiveEvent,

  originalItems = [],
  selectedItems = [],
  total,
  handleSearch,
  handleDelete,
  handleDeleteAll,
  handleSend,
  handleReject,
  goBack,
}) => {
  const [searchedValue, setsearchedValue] = useState("");

  const searchValue = (e) => {
    setsearchedValue(e.target.value.trim());
  };

  const handleSubmit = function(e) {
    e.preventDefault();
    handleSearch(searchedValue);
    setsearchedValue("");
  };

  return (
    <div className={"load-movement-items-outter"}>
      <div className="load-movement-items-wrapper">
        <div className="load-movement-items-title-wrapper" onClick={goBack}>
          <div className="load-movement-items-title-icon">
            {" "}
            <Icon name="chevron" width={14} height={14} fill={"#62D862"} />
          </div>
          <h1 className="load-movement-items-title">Armado de pedido</h1>
        </div>
        <div className="device-selector-wrapper">

          <DeviceSelector
            enabled={enabledRead}
            typeRead={typeRead}
            refRead={refRead}
            receiveEvent={receiveEvent}
            includeType={"MOB_READER"}
          />
        </div>
        <div className="load-movement-items-content">
          <div className="load-movement-items-input-wrapper">
            <div className="load-movement-items-input-label">
              Ingresa el nro. o escanea TagID
            </div>
            <form className="load-movement-items-input" onSubmit={handleSubmit}>
              <InputText
                label="Ingresa número"
                handleChange={searchValue}
                value={searchedValue}
              />
              <Icon name="barcode" width={30} height={25} fill={"#3C7AF5"} />
            </form>
          </div>
          <div className="load-movement-items-count">
            {originalItems.length}/{total}
          </div>
          <div className="load-movement-items-list">
            {selectedItems.map((item) => {
              return (
                <ItemTags
                  key={item.sku}
                  item={item}
                  quantity={item.tags.length}
                  expected={item.expectedUnits}
                  handleDelete={handleDelete}
                />
              );
            })}
          </div>
          <div className="load-movement-items-buttons-wrapper">
            <div
              className="load-movement-items-button load-movement-items-clean"
              onClick={handleDeleteAll}
            >
              {" "}
              LIMPIAR{" "}
            </div>
            <div
              className="load-movement-items-button load-movement-items-send"
              onClick={handleSend}
            >
              CONTINUAR
            </div>
            <div
              className="load-movement-items-button load-movement-items-reject"
              onClick={handleReject}
            >
              {" "}
              RECHAZAR{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadMovementItems;
