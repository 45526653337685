export const headers = {
    name: {
      active: false,
      direction: false,
      value: "name",
    },
    brand__name: {
      active: false,
      direction: false,
      value: "brand__name",
    },
    sku: {
      active: false,
      direction: false,
      value: "sku",
    },
    categories__name: {
      active: false,
      direction: false,
      value: "categories__name",
    },
    stock: {
      active: false,
      direction: false,
      value: "stock",
    },
};

export default headers;