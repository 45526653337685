const getAlarmType = (state) => {
    switch (state) {
        case "THEFT":
            return "Robo";
        case "ATTEMPTED_THEFT":
            return "Intento de robo";
        case "FALSE_ALARM":
            return "Falsa Alarma";               
        case "ALARM":
            return "Nueva Alerta";               
        default:
            return '';
    }
}

export { getAlarmType };
