import usePagination from "hooks/usePagination";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tippy";
import Paginator from "./paginator";
import TableHeadComponent from "./table-head/TableHeadComponent";
import style from "./TableComponent.module.scss";
import {parseStringToDate} from "../../../helpers/Parsers"

const {
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Chip
} = require("@mui/material");

const defaultCellStyle = {
  height: "72px",
  fontFamily: "Ubuntu-Medium",
  color: "#676f8f",
  letterSpacing: "0.28px",
  fontSize: "1rem"
};

const tooltipStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center"
};

const TableComponent = ({
  headCells,
  rows,
  current_page,
  rows_per_page,
  LeftIcon,
  RightIcon,
  sx,
  showPaginator,
  onRowClick,
  changeRequestSort,
  backendOrdering = false,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [dense, setDense] = useState(false);

  const [page, setPage] = useState(current_page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(rows_per_page || 1000);

  const [pageQuantity, setPageQuantity] = useState(0);

  const getPageQuantity = (l, ps) => {
    const listSize = l.length;
    const pageQ = Math.ceil(listSize / ps);
    setPageQuantity(pageQ);
    return pageQ;
  };

  useEffect(() => {
    getPageQuantity(rows, rowsPerPage);
  }, [rows, rowsPerPage]);

  const descendingComparator = (a, b, orderBy) => {
    var bValue = b[orderBy];
    var aValue = a[orderBy];
    if (orderBy == "date"){
      aValue = parseStringToDate(aValue);
      bValue = parseStringToDate(bValue);
    }

    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  };

  const pageChangeHandler = pageNumber => {
    setPage(pageNumber);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    // si backendOrdering es true, no se ordena en el frontend
    if (backendOrdering) return array;
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (changeRequestSort) {
      const ordering = property + (isAsc ? " desc" : " asc");
      changeRequestSort(ordering);
    }
  };

  const getRowByType = (type, children) => {
    switch (type) {
      case "Chip":
        return (
          <Chip
            label={children}
            color="primary"
            className={style["item-chip"]}
          ></Chip>
        );
      case "Tooltip":
        return (
          <Tooltip
            html={<div style={tooltipStyle}>{children ? children : null}</div>}
            followCursor={true}
            position="right"
            offset={25}
          >
            <p>{children ? children : "-"}</p>
          </Tooltip>
        );
      default:
        return children;
    }
  };

  const emptyRows =
    page - 1 > 0
      ? Math.max(0, (1 + (page - 1)) * rowsPerPage - rows.length)
      : 0;

  return (
    <>
      <TableContainer className={style["muiTableContainer-root"]} sx={sx}>
        <Table
          sx={{ minWidth: 600, height: "auto" }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <TableHeadComponent
            headCells={headCells}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            LeftIcon={LeftIcon}
            RightIcon={RightIcon}
          />

          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((row, index) => {
                const labelId = `table-component-${index}`;

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    className={style["row"]}
                    key={index}
                    onClick={() => {
                      if (onRowClick) onRowClick(row);
                    }}
                  >
                    {LeftIcon && (
                      <TableCell align="left" className={style["cell"]}>
                        {LeftIcon}
                      </TableCell>
                    )}
                    {headCells.map(cell => {
                      return (
                        <TableCell
                          align="left"
                          style={{ ...defaultCellStyle, ...cell.style }}
                        >
                          {getRowByType(cell.type, Reflect.get(row, cell.id))}
                        </TableCell>
                      );
                    })}
                    {RightIcon && (
                      <TableCell align="left" className={style["cell"]}>
                        {RightIcon}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showPaginator && (
        <Paginator
          pageQuantity={pageQuantity}
          currentPage={page}
          pageChangeHandler={pageChangeHandler}
        />
      )}
    </>
  );
};

export default TableComponent;
