export const Validations = {
    email: (email) => {
        // eslint-disable-next-line no-useless-escape
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        // eslint-disable-next-line eqeqeq
        if (reg.test(email) == false) 
        {
            return false;
        }

        return true;
    },
    password: (password) => {
        let results = [false, false, false];

       let regs = [
           "",
            /.*[0-9]+.*/,
            /.*[a-zA-Z].*/,
        ]   

        if (password && password.length >= 8) {
            results[0] = true
        }

        if(password && regs[1].test(password)){
            results[1] = true
        }

        if(password && regs[2].test(password)){
            results[2] = true
        }

        return results;
    }
}
