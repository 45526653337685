import React from "react";
import OrderPills from "../../../../../atoms/Pills/OrderPills";
import { ParseDate, ParseDateTime } from "../../../../../../../helpers/Parsers";

import "./styles.scss";
export const RelocationOrderItemMobile = ({ relocationOrder, handleClick }) => {
  const prueba = e => {
    handleClick(e);
  };
  return (
    <div
      className="relocation-order-table-item-mobile-container"
      data-automation-id="relocation-order-table-item"
      data-relocation-order-id={relocationOrder.id}
      onClick={prueba}
    >
      <div className="relocation-order-table-item-mobile-code">
        Nro {relocationOrder.code}
      </div>

      <div className="relocation-order-table-item-mobile-date">
        Fecha: {ParseDate(relocationOrder.created_at)}
      </div>

      <div className="relocation-order-table-item-mobile-bottom">
        <div className="relocation-order-table-item-mobile-owner">
          Responsable:{" "}
          {relocationOrder.owner
            ? `${relocationOrder.owner.first_name} ${relocationOrder.owner.last_name}`
            : "-"}
        </div>
        <div className="relocation-order-table-item-mobile-state-inner">
          <OrderPills state={relocationOrder.status}></OrderPills>
        </div>
      </div>
    </div>
  );
};

export default RelocationOrderItemMobile;
