import Environment from "../environment"; 
import Api from './ApiService';
import { AuthService } from './AuthService.js';

import { Location } from "../models/Location";


export const LocationService = {
    // Deprecado: dejar de usarlo
	locations: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/locations/?node=${params.code}&no_parent=${params.no_parent}${params.parent ? `&parent=${params.parent}` : ''}`, 'GET')
                .then(rta => {
                	rta.results = rta.results.map(item => new Location(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },
    
    locationsAll: (params) => {
        return new Promise((resolve, reject) => {
          return Api.fetch(
            `${Environment.api}api/locations/?no_page=1`,
            "GET",
            params
          )
            .then((rta) => {
              rta = rta.map((item) => new Location(item));
              resolve(rta);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },

    locations_node: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/locations/`, 'GET', params)
                .then(rta => {
                	if ("results" in rta) {
                        rta.results = rta.results.map((item) => new Location(item));
                    } else {
                        rta = rta.map((item) => new Location(item));
                    }
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
	},

    locations_node_by_item: (item_id) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/locations/by-item/?item=${item_id}`, 'GET')
                .then(rta => {
                    // Retorna un diccionario con location y cantidad disponible
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
	},

    location: (id) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/locations/${id}/`, 'GET')
                .then(rta => {
                	rta = new Location(rta)
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    createLocation: (data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/locations/`, 'POST', data
                ).then(data => {
                    resolve(new Location(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateLocation: (id, data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/locations/${id}/`, 'PATCH', data
                ).then(data => {
                    resolve(new Location(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    deleteLocation: (id) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/locations/${id}/`, 'DELETE'
                ).then(data => {
                    resolve();
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },
}
