function parseDateToServer(date, appendT = true, initialT = true) {
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let parse = `${date.getFullYear()}-${month}-${day}`;

  if (appendT) {
    parse += initialT ? " 00:00:00" : " 23:59:59";
  }

  return parse;
}

export { parseDateToServer };
