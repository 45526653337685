import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
export class Step extends React.Component {
  render() {
    const { content, title, goBackFunc } = this.props;

    return (
      <div className="step-container">
        <div className={goBackFunc ? "step-title-wrapper go-back" : "step-title-wrapper"} onClick={goBackFunc} data-automation-id="go-back-step">
          {goBackFunc && <i className="step-back-icon icon-chevron-izquierda"></i>}
          <div className="step-title">{title}</div>
        </div>
        <div className="step-content-wrapper">
          {content}
        </div>
      </div>
    );
  }
}

Step.propTypes = {
  content: PropTypes.any,
  title: PropTypes.string,
  goBackFunc: PropTypes.func,
};

export default Step;
