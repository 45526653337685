import React from "react";
import ProductModalWrapper from "../ProductModalWrapper";

import "./styles.scss";
export class WrongFileModal extends React.Component {
  render() {
    const {
      open,
      message,
      handleClose,
      handleGoBack,
      handleDownloadTemplate,
      handleClick,
    } = this.props;

    const content = (
      <div className="wrong-file-modal-wrapper">
        <div className="wrong-file-modal-title">Archivo erróneo</div>
        <div className="wrong-file-modal-text">{message}</div>
      </div>
    );
    return (
      <div className="wrong-file-modal-container">
        <ProductModalWrapper
          open={open}
          innerContent={content}
          buttonLegend={"SUBIR OTRO ARCHIVO"}
          handleGoBack={handleGoBack}
          handleDownloadTemplate={handleDownloadTemplate}
          handleClose={handleClose}
          handleClick={handleClick}
        ></ProductModalWrapper>
      </div>
    );
  }
}

WrongFileModal.propTypes = {};

export default WrongFileModal;
