import React from "react";
import Card from "../Card"
import DonutChart from '../DonutChart';
import DropdownOptions from "../DropdownOptions";

import "./styles.scss";

const options = [
  {
    key: "all",
    value: "Todas",
  },
  {
    key: "REQ",
    value: "Reposición",
  },
  {
    key: "PRE",
    value: "Salida",
  },
  {
    key: "REC",
    value: "Entrada",
  },
  {
    key: "REL",
    value: "Reubicación",
  },
];


const OrderStatesCard = ({series, colors, totalNumber, handleChangeFilter}) => {
  return (
    <div className={"delivered-orders-card-container"}>
      <Card>
        <div className={"title-container"}>
          <h1 className={"dashboard-card-title"}>Estado de órdenes últimos 30 días</h1>
          <DropdownOptions
            options={options}
            selectedOption={'all'}
            label={"Tipo de orden"}
            handleChange={handleChangeFilter}
          />
        </div>

        <div className={"most-selled-table"}>
          <DonutChart series={series} colors={colors} totalNumber={totalNumber} itemName={'Ordenes'}/>
        </div>
      </Card>
    </div>
  );
};

export default OrderStatesCard;
