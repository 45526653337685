import React from "react";
import LoadButtons from "../LoadButtons"
import "./styles.scss";

export class LoadButtonsContainer extends React.Component {
  render() {
    return (
      <div className="load-products-buttons-wrapper">
        <div className="load-products-button">
          <LoadButtons
            title="CARGA INDIVIDUAL"
            subtitle="Podras cargar un producto a la vez"
            icon="icon-iconos_producto"
            handleFunc={this.props.handleSingleProductClick}
            
          />
        </div>
        <div className="load-products-button">
          <LoadButtons
            title="CARGA MASIVA"
            subtitle="Podrás cargar varios productos desde un archivo."
            icon="icon-iconos_varios_productos"
            handleFunc={this.props.handleMassiveProductClick}
          />
        </div>
      </div>
    );
  }
}

LoadButtonsContainer.propTypes = {};

export default LoadButtonsContainer;
