import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from "@mui/material";
import "./VersionModalDelete.scss";

const VersionModalDelete = ({ version, open, onClose, onDelete }) => {

  const handleDelete = () => {
    onDelete(version);
    onClose();
  };

  return (
    <Dialog className="dialog_version" open={open} onClose={onClose}>
      <DialogTitle>Borrado de Versión</DialogTitle>
      <DialogContent>
      <Typography>¿Estás seguro de que quieres borrar la versión "{version || ''}"?</Typography>

      </DialogContent>
      <DialogActions>
        <Button className="btn_cancel" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          className="btn_delete"
          onClick={handleDelete}
        >
          Borrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VersionModalDelete;
