import React from "react";
import "./styles.scss";

export class Toggle extends React.Component {
  render() {
    const {
      handleChange,
      checked = false,
      disabled = false,
      name
    } = this.props;

    return (
      <div className="main-input-toggle">
        <label className="switch">
          <input
            disabled={disabled}
            type="checkbox"
            data-automation-id="toggle"
            onChange={handleChange}
            checked={checked}
            name={name}
          />
          <span className="slider round"></span>
        </label>
      </div>
    );
  }
}

export default Toggle;
