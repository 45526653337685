import React from "react";
import PropTypes from "prop-types";

import Environment from "../../../environment";

import { Validations } from "../../../helpers/validations";

import "./styles.scss";
import GenericFormContainer from "../../../helpers/GenericFormContainer";

import { AuthService } from "../../../services/AuthService";
import AuthGeneric from "../../../components/Auth/layouts/AuthGeneric";
import Login from "../../../components/Auth/organisms/Content/Login";
import HiAgain from "../../../components/Auth/organisms/Content/HiAgain";

export class SignInView extends GenericFormContainer {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      userData: {
        email: null,
        password: null,
      },
      rememberUser: false,
      buttonState: "disabled",
      login: true,
      accounts: [],
    };
  }

  componentDidMount() {
    const storedAccounts = AuthService.getStoragedAccounts();

    if (storedAccounts.length > 0) {
      let userData = { ...this.state.userData };
      userData.email = storedAccounts[0];
      this.setState({
        login: false,
        accounts: storedAccounts,
        userData,
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.beforeSubmit();
    AuthService.login(this.state.userData.email, this.state.userData.password)
      .then((data) => {
        this.afterSubmit();
        this.showSuccess("Login correcto");
        AuthService.loadUser()
          .then((data) => {
            if (this.state.rememberUser) {
              let rememberedAccounts = AuthService.getStoragedAccounts();
              rememberedAccounts.push(this.state.userData.email);
              AuthService.setStoragedAccounts(rememberedAccounts);
            }
            if (data.is_confirmed === false && Environment.validate_email) {
              this.props.history.push({
                pathname: "/email-verification",
                state: {
                  email: data.email,
                  type: "register",
                },
              });
            } else {
              this.props.history.push("/admin");
            }
          })
          .catch((error) => {
            this.showError("Usuario invalido");
          });
      })
      .catch((error) => {
        this.afterSubmit();
        this.dealWithError(error, "Login invalido");
      });
  };

  handleSelectAccount = (email) => {
    let userData = { ...this.state.userData };
    userData.email = email;
    this.setState({
      userData: userData,
    });
    this.updateButtonState(userData);
  };

  handleChange = (e) => {
    let id = e.target.id;
    let userData = { ...this.state.userData };
    userData[id] = e.target.value;
    this.setState({
      userData: userData,
    });

    this.updateButtonState(userData);
  };

  handleBlur = (e) => {
    let userData = { ...this.state.userData };
    this.validateFieldsAfterChange(userData);
  }

  validateFieldsAfterChange = (userData) => {
    let errors = { ...this.state.errors };

    if (!Validations.email(userData.email) && userData.email !== "") {
      errors.email = "Ingresa un email válido.";
    } else {
      errors.email = null;
    }

    this.setState({
      errors,
    });
  };

  addEmail = () => {
    let userData = { ...this.state.userData };
    userData.email = null;
    this.setState({
      userData,
      login: true,
    });
  };

  rememberUserChange = (_) => {
    this.setState({
      rememberUser: !this.state.rememberUser,
    });
  };

  updateButtonState = (userData) => {
    if (userData.email && userData.password && !this.state.errors.email) {
      this.setState({
        buttonState: "enabled",
      });
    } else {
      this.setState({
        buttonState: "disabled",
      });
    }
  };
  handleGoBack = () => {
    return true;
  };

  render() {
    const loginContent = (
      <Login
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        handleSubmit={this.handleSubmit}
        rememberUser={this.state.rememberUser}
        rememberUserChange={this.rememberUserChange}
        buttonState={this.state.buttonState}
        errors={this.state.errors}
        errorMessage={this.state.success ? null : this.state.message}
      ></Login>
    );

    const hiAgainContent = (
      <HiAgain
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleSelectAccount={this.handleSelectAccount}
        rememberUserChange={this.rememberUserChange}
        buttonState={this.state.buttonState}
        errors={this.state.errors}
        errorMessage={this.state.success ? null : this.state.message}
        selectedUser={this.state.userData.email}
        accounts={this.state.accounts}
        handleAddEmail={this.addEmail}
      ></HiAgain>
    );

    const content = this.state.login ? loginContent : hiAgainContent;

    return (
      <div className="sign-in-container">
        <AuthGeneric
          title={""}
          version={"logo"}
          content={content}
          handleGoBack={this.handleGoBack}
        ></AuthGeneric>
      </div>
    );
  }
}

SignInView.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string,
};

export default SignInView;
