import "./PaginationAlert.scss";
import { useEffect, useState } from "react";
import useDebounce from "hooks/useDebounce";
import arrowIcon from "assets/images/arrow-icon.svg";

const PaginationAlert = ({ currentPage, setCurrentPage, totalPages }) => {
  const [searchPage, setSearchPage] = useState(currentPage);
  const debouncedChangePage = useDebounce(searchPage, 500);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setSearchPage(newPage);
      setCurrentPage(newPage);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setSearchPage(newPage);
      setCurrentPage(newPage);
    }
  };

  const handleChangePage = e => {
    if (/^\d*$/.test(e.target.value)) {
        setSearchPage(Number(e.target.value));
    }
  };

  useEffect(() => {
    if (debouncedChangePage > totalPages || debouncedChangePage <= 0) {
      setSearchPage(currentPage || 1);
    } else {
      setCurrentPage(searchPage);
    }
  }, [debouncedChangePage]);

  useEffect(() => {
    setSearchPage(currentPage)
  }, [currentPage])

  return (
    <footer className="pagination-alert">
      <p>
        Página
        <input type="text" value={searchPage} onChange={handleChangePage} />
        de
        <strong>{totalPages}</strong>
      </p>
      <nav>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          <img src={arrowIcon} alt="prev" />
        </button>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <img src={arrowIcon} alt="next" />
        </button>
      </nav>
    </footer>
  );
};

export default PaginationAlert;
