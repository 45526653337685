/* eslint-disable eqeqeq */
import React from "react";
import PropTypes from "prop-types";

import { ProductService } from "../../../../services/ProductService";

import "./styles.scss";
import GenericFormContainer from "../../../../helpers/GenericFormContainer";
import AdminLayout from "../../../../components/MainApp/layouts/DesktopLayout";
import ProdStepOne from "../../../../components/MainApp/organisms/Product/ProductSigleLoadWizard/ProductStep1";
import ProdStepTwo from "../../../../components/MainApp/organisms/Product/ProductSigleLoadWizard/ProductStep2";
import ProdStepDimensions from "../../../../components/MainApp/organisms/Product/ProductSigleLoadWizard/ProductStepDimensions";
import ProdStepThree from "../../../../components/MainApp/organisms/Product/ProductSigleLoadWizard/ProductStep3";
import LoadFinished from "../../../../components/MainApp/organisms/Product/LoadFinished";
import Wizard from "../../../../components/MainApp/atoms/Wizard/StepProgress";
import SingleLoadExitModal from "../../../../components/MainApp/organisms/Product/Modals/SingleLoadExitModal";
import RoundedButton from "../../../../components/MainApp/atoms/RoundedButton";
import Loader from "../../../../components/MainApp/atoms/Loader";

export class LoadSingleProductView extends GenericFormContainer {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      goBackConfirm: false,

      activeStep: "0",
      product: {
        sku: "",
        name: "",
        description: "",
        brand: "",
        category: null,
        purchase_price: "",
        sell_price: "",
        image: null,
        measure_height: null,
        measure_width: null,
        measure_depth: null,
        weight: null
      },
      item_files: [],
      selectedStarIndex: 0,
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.product) {
      this.setState({
        product: {
          ...this.state.product,
          ...this.props.location.state.product
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors === this.state.errors) return;

    if (this.state.errors && Object.keys(this.state.errors).length > 0) {
      const errorKeys = Object.keys(this.state.errors);
      if (
        ["name", "brand", "description", "category"].some(r =>
          errorKeys.includes(r)
        )
      ) {
        this.setState({
          activeStep: "0"
        });
        return;
      }

      if (
        ["sku", "sell_price", "purchase_price"].some(r => errorKeys.includes(r))
      ) {
        this.setState({
          activeStep: "1"
        });
        return;
      }

      if (
        ["weight", "measure_height", "measure_width", "measure_depth"].some(r =>
          errorKeys.includes(r)
        )
      ) {
        this.setState({
          activeStep: "2"
        });
        return;
      }

      if (["image"].some(r => errorKeys.includes(r))) {
        this.setState({
          activeStep: "3",
          product: {
            ...this.state.product,
            image: null
          }
        });
        return;
      }
    }
  }

  handleChangeImage = (e, err) => {
    if (err) {
      this.setState({ errors: { image: err } });
      return;
    }
    const item_files = e.target?.files?.length
      ? Array.from(e.target.files)
      : [];
    this.setState({
      image_file: item_files ? item_files[0] : null,
      item_files: [...this.state.item_files, ...item_files],
      changed: true
    });
  };

  handleChange = e => {
    let name = e.target.name;
    let product = { ...this.state.product };
    if (e.target.type !== "file") {
      product[name] = e.target.value;
    } else {
      product[name] = e.target.files[0];
    }
    this.setState({
      product: product
    });
  };

  handleBlur = e => {
    const { errors } = this.state;
    const { name, value } = e.target;
    if (isNaN(value) || value < 0)
      errors[name] = "El valor ingresado es inválido";
    else errors[name] = null;
    this.setState({
      errors: errors
    });
  };

  handleDeleteImage = (e) => {
    let item_files = e?.length ? Array.from(e) : [];
    this.setState({
      image_file: item_files ? item_files[0] : null,
      item_files: item_files,
      changed: true
    });
  };

  handleStarClick = idx => {
    this.setState({
      selectedStarIndex: idx,
      changed: true
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("sku", this.state.product.sku);
    formData.append("name", this.state.product.name);
    formData.append("description", this.state.product.description);
    if (this.state.product.category) {
      formData.append(
        "categories_id",
        this.state.product.category ? [this.state.product.category.id] : []
      );
    }
    formData.append("brand_name", this.state.product.brand);
    formData.append("purchase_price", this.state.product.purchase_price);
    formData.append("sell_price", this.state.product.sell_price);
    if (this.state.product.image) {
      formData.append("image", this.state.product.image);
    }
    formData.append("enabled", true);

    formData.append("weight", this.state.product.weight ?? 0);
    formData.append("measure_height", this.state.product.measure_height ?? 0 * 10);
    formData.append("measure_depth", this.state.product.measure_depth ?? 0 * 10);
    formData.append("measure_width", this.state.product.measure_width ?? 0 * 10);

    let filePromises = [];
    if (this.state.item_files.length > 0) {
      const selectedStarIndex = this.state.selectedStarIndex;
      const selectedItem = this.state.item_files.splice(
        selectedStarIndex,
        1
      )[0];
      this.state.item_files.unshift(selectedItem);

      filePromises = this.state.item_files.map(async (item_file, index) => {
        if (item_file instanceof File) {
          if(index === 0) formData.append("image", item_file);
          formData.append(`item_files[${index}].file_name`, item_file);
          return Promise.resolve();
        } else if (item_file?.file_name) {
          const response = await fetch(item_file.file_name);
          const blob = await response.blob();
          const type = this.getFileType(item_file.file_name);
          const file = new File([blob], item_file.file_name, { type: type });
          if (index === 0) formData.append("image", file);
          formData.append(`item_files[${index}].file_name`, file);
        }
      });
    };

    Promise.all(filePromises)
    .then(() => {
      this.beforeSubmit();
      ProductService.createProductFormData(formData)
        .then(account => {
          this.afterSubmit();
          this.showSuccess("Producto creado");
          this.setState({
            activeStep: "finish"
          });
        })
        .catch(error => {
          this.afterSubmit();
          this.dealWithError(error, "Error creando el producto");
        });
    })
    .catch(error => console.error(error));
  };

  selectCategory() {
    this.props.history.push({
      pathname: "/admin/categories",
      state: {
        product: this.state.product
      }
    });
  }

  removeCategory() {
    const { product } = this.state;
    product.category = null;
    this.setState({ product: product });
  }

  stepChangeHandler(step) {
    this.setState({
      activeStep: step
    });
  }

  step0IsOk() {
    return this.state.product.name != "" && this.state.product.brand != "";
  }

  step1IsOk() {
    return (
      this.state.product.sku != "" &&
      !isNaN(this.state.product.purchase_price) &&
      !isNaN(this.state.product.sell_price) &&
      this.state.product.purchase_price >= 0 &&
      this.state.product.sell_price >= 0
    );
  }

  step2IsOk() {
    return (
      !isNaN(this.state.product.measure_height) &&
      !isNaN(this.state.product.measure_width) &&
      !isNaN(this.state.product.measure_depth) &&
      !isNaN(this.state.product.weight) &&
      this.state.product.measure_height >= 0 &&
      this.state.product.measure_width >= 0 &&
      this.state.product.measure_depth >= 0 &&
      this.state.product.weight >= 0
    );
  }

  nextStep(e) {
    e.preventDefault();

    const func = {
      "0": e => this.stepChangeHandler("1"),
      "1": e => this.stepChangeHandler("2"),
      "2": e => this.stepChangeHandler("3"),
      "3": e => this.handleSubmit(e)
    };
    func[this.state.activeStep](e);
  }

  goBackStep(e) {
    const func = {
      "1": e => this.stepChangeHandler("0"),
      "2": e => this.stepChangeHandler("1"),
      "3": e => this.stepChangeHandler("2")
    };
    func[this.state.activeStep](e);
  }

  goBack() {
    if (this.state.activeStep === "finish") {
      this.props.history.push("/admin/products/load");
      return;
    }
    this.setState({
      goBackConfirm: true
    });
  }

  headerOptions = [];

  steps = [
    {
      id: "0",
      name: "Descripción"
    },
    {
      id: "1",
      name: "Código y precio"
    },
    {
      id: "2",
      name: "Peso y dimensiones"
    },
    {
      id: "3",
      name: "Imagen"
    }
  ];

  render() {
    const content = (
      <div className="load-single-product-content">
        {this.state.activeStep !== "finish" ? (
          <div className="load-single-product-wrapper">
            <div className="load-single-product-wizard">
              <Wizard
                steps={this.steps}
                activeStep={this.state.activeStep}
                clickEnabled={false}
              ></Wizard>
            </div>

            <div className="load-single-product-step">
              {this.state.activeStep === "0" && (
                <form onSubmit={this.nextStep.bind(this)}>
                  <ProdStepOne
                    product={this.state.product}
                    handleChange={this.handleChange}
                    selectCategory={this.selectCategory.bind(this)}
                    removeCategory={this.removeCategory.bind(this)}
                    errors={this.state.errors}
                  />
                  <div className="load-single-product-button">
                    <div>
                      <RoundedButton
                        legend="CONTINUAR"
                        type="submit"
                        state={this.step0IsOk() ? "enabled" : "disabled"}
                        handleClick={this.nextStep.bind(this)}
                      ></RoundedButton>
                    </div>
                  </div>
                </form>
              )}

              {this.state.activeStep === "1" && (
                <form onSubmit={this.nextStep.bind(this)}>
                  <ProdStepTwo
                    product={this.state.product}
                    handleChange={this.handleChange}
                    goBackFunc={this.goBackStep.bind(this)}
                    errors={this.state.errors}
                  />
                  <div className="load-single-product-button">
                    <div>
                      <RoundedButton
                        legend="CONTINUAR"
                        type="submit"
                        state={this.step1IsOk() ? "enabled" : "disabled"}
                        handleClick={this.nextStep.bind(this)}
                      ></RoundedButton>
                    </div>
                  </div>
                </form>
              )}

              {this.state.activeStep === "2" && (
                <form onSubmit={this.nextStep.bind(this)}>
                  <ProdStepDimensions
                    product={this.state.product}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                    goBackFunc={this.goBackStep.bind(this)}
                    errors={this.state.errors}
                  />
                  <div className="load-single-product-button">
                    <div>
                      <RoundedButton
                        legend="CONTINUAR"
                        type="submit"
                        state={this.step2IsOk() ? "enabled" : "disabled"}
                        handleClick={this.nextStep.bind(this)}
                      ></RoundedButton>
                    </div>
                  </div>
                </form>
              )}

              {this.state.activeStep === "3" && (
                <form onSubmit={this.nextStep.bind(this)}>
                  <ProdStepThree
                    product={this.state.product}
                    type="submit"
                    handleChangeImage={this.handleChangeImage}
                    handleDelete={this.handleDeleteImage}
                    goBackFunc={this.goBackStep.bind(this)}
                    errors={this.state.errors}
                    files={this.state.item_files}
                    onHandleStarClick={this.handleStarClick}
                    selectedStarIndex={this.state.selectedStarIndex}
                  />
                  <div className="load-single-product-button last">
                    <div>
                      <RoundedButton
                        legend="FINALIZAR"
                        state="enabled"
                        handleClick={this.nextStep.bind(this)}
                      ></RoundedButton>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        ) : (
          <div className="load-single-product-wrapper-finish">
            <div className="load-single-product-wrapper-finish-container">
              <LoadFinished />
            </div>
          </div>
        )}
      </div>
    );

    return (
      <div className="load-single-product-container">
        <AdminLayout
          headerTitle={"Nuevo Producto"}
          headerOptions={this.headerOptions}
          content={content}
          goBackFunc={
            this.state.activeStep !== "finish" ? this.goBack.bind(this) : null
          }
          navHidden={true}
        ></AdminLayout>

        {this.state.submitted && <Loader />}

        <SingleLoadExitModal
          open={this.state.goBackConfirm}
          confirm={() => this.props.history.push("/admin/products/load")}
          cancel={() => this.setState({ goBackConfirm: false })}
          handleClose={() => this.setState({ goBackConfirm: false })}
        />
      </div>
    );
  }
}

LoadSingleProductView.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string
};

export default LoadSingleProductView;
