import React from 'react';
import Step from "components/MainApp/atoms/Wizard/Step";


import "./styles.scss";

export class AddDeviceStepThree extends React.Component {
  render() {
    const {deviceInfo, handleFinish } = this.props;

    const stepContent = (
      <form onSubmit={handleFinish}>
      <div className="add-device-wizard-step-three-content">
        {deviceInfo.map( (item, index) => {
          return <div key={index} className={"add-device-wizard-step-three-item"}> 
            <div className={"add-device-wizard-step-three-title"}>{item.title}</div>
            <div className={"add-device-wizard-step-three-value"}>{item.value}</div>
          </div>
        })}
        <div className={"add-device-wizard-step-three-action-wrapper"}>
          <button type="submit" className="add-device-wizard-step-three-action-button">
            FINALIZAR
          </button>
        </div>
      </div>
      </form>
    );

    return (
      <div className="add-device-wizard-step-three-container">
        <Step title="Dispositivo" content={stepContent} ></Step>
      </div>
    );
  }
}

export default AddDeviceStepThree;
