import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import FileSaver from "file-saver";
import i18n from "i18n";

import { OrderService } from "../../../../../services/OrderService";
import { MovementService } from "../../../../../services/MovementServkce";
import { StockService } from "../../../../../services/StockService";

import { EventRepository } from "helpers/EventRepository";

import { WSService } from "services/WSServices";
import useRequest from "hooks/useRequest";

import { itemsByItem } from "helpers/orders/MovementHelper";
import { parseItems } from "helpers/orders/ItemHelper";

import "./styles.scss";
import AdminLayout from "../../../../../components/MainApp/layouts/DesktopLayout";
import OrderDetailDescriptionPanel from "../../../../../components/MainApp/organisms/Orders/RequestOrders/Detail/Description";
import OrderProductSummary from "../../../../../components/MainApp/organisms/Orders/RequestOrders/ProductSummary";

import OrderPills from "../../../../../components/MainApp/atoms/Pills/OrderPills";
import MainButton from "../../../../../components/MainApp/atoms/RoundedButton";
import RejectModal from "../../../../../components/MainApp/organisms/Orders/RequestOrders/Modals/RejectModal";
import Loader from "../../../../../components/MainApp/atoms/Loader";
import { isMobile } from "../../../../../helpers/Mobile";
import ItemTags from "components/MainApp/organisms/OrdersV3/Item-Tags";

import LoadMovementItems from "./LoadMovementItems";
import VerifyMovementItems from "./VerifyMovementItems";
import ValidateMovementItems from "./ValidateMovementItems";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";
import isFirstDayOfMonth from "date-fns/isFirstDayOfMonth/index.js";

let globalVerifyMovementItems = [];

export const PrepOrderDetailsView = props => {
  const history = useHistory();

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();

  const [step, setStep] = useState(0);
  // Step 0
  const [order, setOrder] = useState({});
  const [orderDescription, setorderDescription] = useState([]);
  const [modalOpen, setmodalOpen] = useState(false);
  const [products, setproducts] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [movement, setmovement] = useState({});
  const [movementItems, setmovementItems] = useState([]);
  // Step 1
  const [groupMovementItems, setGroupMovementItems] = useState([]);
  // Step 3
  const [verifyMovementItems, setVerifyMovementItems] = useState([]);
  const [validatedItems, setValidatedItems] = useState(0);

  const [errorModalOpen, seterrorModalOpen] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const movementRef = useRef(movement);
  const orderRef = useRef(order);

  const headerOptions = isMobile
    ? [
        {
          icon: "icon-iconos_descargar",
          name: "Descargar",
          handler: () => {
            downloadVoucher();
          },
          tooltip: "Descargar"
        }
      ]
    : [];

  const goBackModal = () => {
    history.push("/admin/orders/preparation");
  };

  useEffect(() => {
    const id = props.match.params.key;
    const node = props.match.params.node ?? "";
    beforeSubmit();
    OrderService.prepOrder(id, node)
      .then(order => {
        afterSubmit();
        setOrder(order);
        const orderDescriptionNew = order.getDescriptionPanelView();
        setorderDescription(orderDescriptionNew);
        getItems(order.id, node);
        // Esta llamada la hace getItems porque para hacer el parseo se
        // requiere tener ambos.
        // getMovements();
      })
      .catch(_ => {
        afterSubmit();
      });
  }, []);

  useEffect(() => {
    if (!order || !order.id) return;
    // si orderDescription tiene un motivo de rechazo con valor no vacío, no hacemos nada
    if (
      orderDescription &&
      orderDescription.find(
        item => item.title === "Motivo rechazo" && item.value
      )
    )
      return;
    OrderService.getOrderStatus(order.id)
      .then(response => {
        const orders = Object.values(response);
        const rejectedOrder = orders.find(order => order.status === "REJ");
        if (rejectedOrder && rejectedOrder?.reason) {
          const orderDescriptionNew2 = [
            ...orderDescription,
            { title: "Motivo rechazo", value: rejectedOrder.reason }
          ];
          setorderDescription(orderDescriptionNew2);
        } else if (order?.order) {
          // buscamos en la orden de la que depende esta el motivo
          OrderService.getOrderStatus(order?.order?.id)
            .then(response => {
              const orders = Object.values(response).filter(
                item => item && typeof item === "object"
              );
              let reason = orders.reduce((r, order) => order?.reason || r, "");
              if (orderDescription) {
                const orderDescriptionNew2 = [
                  ...orderDescription,
                  { title: "Motivo rechazo", value: reason }
                ];
                setorderDescription(orderDescriptionNew2);
              }
            })
            .catch(_ => {
              afterSubmit();
            });
        }
      })
      .catch(_ => {
        afterSubmit();
      });
  }, [orderDescription]);

  useEffect(() => {
    globalVerifyMovementItems = verifyMovementItems;
  }, [verifyMovementItems]);

  useEffect(() => {
    if (!movement.id) return;

    movementRef.current = movement;
    if (movementItems.length === 0) {
      getMovementItems();
    }
  }, [movement]);

  useEffect(() => {
    orderRef.current = order;
    if (movementItems.length === 0) {
      getMovementItems();
    }
  }, [order]);

  useEffect(() => {
    setGroupMovementItems(itemsByItem(movementItems, products));

    const verifyItems = itemsByItem(movementItems, products);
    setVerifyMovementItems(verifyItems);
    setValidatedItems(0); // Por defecto nada validado
  }, [movementItems]);

  const getItems = (orderId, node = "") => {
    OrderService.getItems(orderId, node).then(response => {
      const productList = parseItems(response);
      const total = [...productList].reduce(function(a, prod) {
        return a + prod.units;
      }, 0);
      setproducts(productList);
      setTotalUnits(total);
      // Now get movement items
      getMovements(orderId, node);
    });
  };

  const getMovements = (orderId, node = "") => {
    OrderService.roadMap(orderId, node).then(response => {
      response = response.filter(result => {
        return result.movement.status !== "CAN";
      });
      if (response.length !== 0) {
        const movement = response[response.length - 1].movement;
        setmovement(movement);
      }
    });
  };

  const getMovementItems = () => {
    MovementService.items(movement.id).then(response => {
      setmovementItems(response);
    });
  };

  const receiveEvent = useCallback(payload => {
    const ws = WSService.getInstance();
    console.log("Message from socket");
    console.log(payload);

    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {
      if ("items" in payload.params 
        && payload.params["items"] != null 
        &&payload.params["items"].length > 0 )  {

        setmovementItems(payload.params.items);
      } else {
        if(orderRef.current && orderRef.current.status === "PRO" ){
          handleStockSearch(payload.params.epc ? payload.params.epc : payload.params.tag["rfid"])
        }
        if(orderRef.current && orderRef.current.status === "SUP"){
          handleTagSearch(payload.params.epc ? payload.params.epc : payload.params.tag["rfid"]);
        }
      }
    }
  }, []);

  const handleRejectModalOpen = () => {
    setmodalOpen(true);
  };

  const handleCloseModal = () => {
    setmodalOpen(false);
  };

  const handleReject = reason => {
    beforeSubmit();
    if (order.status === "PEN") handleRejectFromPen(reason);
    if (order.status === "PRO") handleRejectFromSup(reason);
    if (order.status === "SUP") handleRejectFromSup(reason);
    setmodalOpen(false);
  };

  const handleRejectFromPen = reason => {
    OrderService.changeOrderStatus(order.id, "REJ", reason)
      .then(response => {
        setmodalOpen(false);
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("La orden se rechazó de manera exitosa."),
          type: "success"
        });
        props.history.push("/admin/orders/preparation");
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: "error"
            });
          } else {
            EventRepository.notificationSend({
              label: i18n.t("La orden no se puede rechazar."),
              type: "error"
            });
          }
        });
      });
  };

  const handleRejectFromSup = reason => {
    if (!movement.id) {
      handleRejectFromPen(reason);
      return;
    }
    MovementService.changeStatus(movement.id, "REJ", reason)
      .then(response => {
        afterSubmit();
        if(order.id) {
          handleRejectFromPen(reason);
        }
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: "error"
            });
          } else {
            EventRepository.notificationSend({
              label: i18n.t("El movimiento no se puede rechazar."),
              type: "error"
            });
          }
        });
      });
  };

  const handleApprove = () => {
    if (order.status !== "PEN" && order.status === "SUP") {
      if (validatedItems < totalUnits) {
        seterrorModalOpen(true);
        seterrorMsg("Faltan realizar todas las lecturas de Tags");
        return;
      }
    }
    beforeSubmit();
    if (order.status === "PEN") handleAppoveFromPen();
    if (order.status === "SUP") handleApproveFromSup();
  };

  const handleAppoveFromPen = () => {
    OrderService.changeOrderStatus(order.id, "PRO", "apporoved")
      .then(response => {
        afterSubmit();
        setmodalOpen(false);

        EventRepository.notificationSend({
          label: i18n.t("La orden se aprobó de manera exitosa."),
          type: "success"
        });
        props.history.push("/admin/orders/preparation");
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: "error"
            });
          } else {
            EventRepository.notificationSend({
              label: i18n.t("La orden no se puede rechazar."),
              type: "error"
            });
          }
        });
      });
  };

  const handleApproveFromSup = () => {
    const tagsList = [];
    verifyMovementItems?.forEach(item => item.tags?.forEach(tag => tagsList.push(tag.tag)));

    MovementService.changeStatus(movement.id, "VER_COM", tagsList)
      .then(response => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("La orden se despachó de manera exitosa."),
          type: "success"
        });
        props.history.push("/admin/orders/preparation");
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: "error"
            });
          } else {
            EventRepository.notificationSend({
              label: i18n.t("La orden no se puede despachar."),
              type: "error"
            });
          }
        });
      });
  };

  const handleInitMovement = () => {
    if (!movement.id) {
      const data = order.dataCreateMovement();
      OrderService.addMovement(order.id, data)
        .then(response => {
          setmovement(response.movement);
          setStep(1);
        })
        .catch(error => {
          afterSubmit();
          dealWithError(error, "generic.error", (message, errors) => {
            if (Object.keys(errors).length === 0) {
              EventRepository.notificationSend({
                label: message,
                type: "error"
              });
            } else {
              EventRepository.notificationSend({
                label: i18n.t("No se puede iniciar el movimiento."),
                type: "error"
              });
            }
          });
        });
    } else {
      setStep(1);
    }
  };

  const handleDeleteMovementItems = tag => {
    beforeSubmit();
    MovementService.deleteItems(movement.id, [{ id: tag.movement_item_id }])
      .then(response => {
        afterSubmit();
        setmovementItems(response);
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const handleDeleteAllMovementItems = e => {
    const ids = movementItems.map(m => {
      return { id: m.id };
    });
    beforeSubmit();
    MovementService.deleteItems(movement.id, ids)
      .then(response => {
        afterSubmit();
        setmovementItems(response);
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const handleContinueInitMovement = () => {
    let expectedUnits = true;
    let exceededUnits = false;

    groupMovementItems.forEach(item => {
      if (item.expectedUnits == 0) {
        expectedUnits = false;
      } else {
        if (item.expectedUnits < item.tags.length) {
          exceededUnits = true;
        }
      }
    });

    if (movementItems.length < totalUnits) {
      seterrorModalOpen(true);
      seterrorMsg("Faltan realizar todas las lecturas de Tags");
      return;
    }

    if (!expectedUnits) {
      seterrorModalOpen(true);
      seterrorMsg("Existen productos que no fueron solicitados");
      return;
    }

    if (exceededUnits) {
      seterrorModalOpen(true);
      seterrorMsg("La cantidad de productos agregados supera lo solicitado");
      return;
    }
    setStep(2);
  };

  const handleStockSearch = cod => { // valida el item en otras ordenes
    cod = cod?.trim();
    if (!cod) return;

    const filter = {
      location__node: movementRef.current.node.code,
      status: "LOC",
      tag__rfid: cod,
      check_rfid_other_orders: orderRef.current?.code ?? ""
    };
    beforeSubmit();
    StockService.stock(filter)
      .then(response => {
        if (response.count === 0) {
          afterSubmit();
          handleTagSearch(cod);
          return;
        }
        const stock = response.results[0];
        MovementService.addItems(movement.id, [
          {
            item_stock: stock.id
          }
        ])
          .then(response => {
            afterSubmit();
            setmovementItems(response);
          })
          .catch(error => {
            afterSubmit();
            dealWithError(error, "generic.error", (message, _) => {
              EventRepository.notificationSend({
                label: message,
                type: "error"
              });
            });
          });
      })
      .catch(_ => {
        afterSubmit();
        dealWithError(_, "generic.error", (message, _) => {
          EventRepository.notificationSend({
            label: message,
            type: "error"
          });
        });
      });
  };

  const handleConfirmMovement = () => {
    const status = movement.requires_revision ? "CLO" : "COM";
    beforeSubmit();
    MovementService.changeStatus(movement.id, status)
      .then(_ => {
        afterSubmit();
        EventRepository.notificationSend({
          label: i18n.t("La orden se preparó de manera exitosa."),
          type: "success"
        });
        props.history.push("/admin/orders/preparation");
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", message => {
          EventRepository.notificationSend({
            label: message,
            type: "success"
          });
        });
      });
  };

  const handleInitVerify = () => {
    setStep(3);
  };

  const handleTagSearch = cod => {
    cod = cod?.trim();
    if (!cod) return;

    let verifyItems = [...globalVerifyMovementItems];
    let exist = false;
    // Buscar EPC
    verifyItems.forEach((i, index, arr) => {
      i.tags.forEach((t, indexTag) => {
        if (t.tag === cod) {
          exist = true;
          arr[index]["tags"][indexTag]["selected"] = true;
        }
      });
    });

    if(exist) {
      setVerifyMovementItems(verifyItems);

      // Calcular cantidad validados
      let cant = 0;
      verifyItems.forEach(i => {
        i.tags.forEach(t => {
          if (t.selected) cant++;
        });
      });
      setValidatedItems(cant);
    }else {
      seterrorModalOpen(true);
      if(orderRef.current && orderRef.current.status === "SUP"){
        seterrorMsg(`El tag ${cod} que se leyó no se encuentra cargado en la orden`);
      }else {
        seterrorMsg(`El tag ${cod} que se leyó no se encuentra cargado en el sistema`);
      }
    }
    
  };

  const handleChangeStep = step => {
    setStep(step);
  };

  const downloadVoucher = () => {
    beforeSubmit();
    OrderService.prepDownloadVoucher(order.id)
      .then(blob => {
        afterSubmit();
        FileSaver.saveAs(blob, "comprobante.pdf");
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const handleClickMobileReject = (step) => {
    handleRejectModalOpen();
    if (step != -1) setStep(step);
  };

  const customButtonsMobile = (step = -1) => {
    return (
      <>
        <div className="prep-order-details-reject">
          <MainButton
            handleClick={() => handleClickMobileReject(step)}
            state={"enabled"}
            legend="RECHAZAR"
          ></MainButton>
        </div>

        {!order?.status?.match(/^(PRO|SUP)$/i) && (
          <div className="prep-order-details-approve">
            <MainButton
              handleClick={handleApprove}
              state={"enabled"}
              legend="APROBAR"
            ></MainButton>
          </div>
        )}
      </>
    );
  };

  const customButtons = () => {
    return (
      <div className="prep-order-details-custom-buttons">
        <div className="prep-order-details-reject">
          <MainButton
            handleClick={handleRejectModalOpen}
            state={"enabled"}
            legend="RECHAZAR"
          ></MainButton>
        </div>

        {!order?.status?.match(/^(PRO|SUP)$/i) && (
          <div className="prep-order-details-approve">
            <MainButton
              handleClick={handleApprove}
              state={"enabled"}
              legend="APROBAR"
            ></MainButton>
          </div>
        )}
      </div>
    );
  };

  const btnReject = () => (
    <div className="prep-order-details-custom-buttons">
      <div className="prep-order-details-reject">
        <MainButton
          handleClick={handleRejectModalOpen}
          state={"enabled"}
          legend="RECHAZAR"
        ></MainButton>
      </div>
    </div>
  );

  const loadedContentStep0 = (
    <div className="prep-order-details-wrapper">
      <div className="prep-order-details-panels">
        <div className="prep-order-details-left">
          <div className="prep-order-details-left-title">
            <div className="prep-order-details-title">Orden de preparación</div>
            <div className="pill-wrapper">
              {order && (
                <OrderPills big={true} state={order.status}></OrderPills>
              )}
            </div>
          </div>
          <div className="prep-order-details-panel">
            <OrderDetailDescriptionPanel
              order={orderDescription}
            ></OrderDetailDescriptionPanel>
          </div>
        </div>
        <div className="prep-order-details-right">
          {!isMobile && (
            <div
              className={`prep-order-details-right-title ${!isMobile &&
                order &&
                order.status?.match("PRO|PEN") &&
                "two-elements"}`}
            >
              <div
                className="prep-order-details-download"
                onClick={downloadVoucher}
              >
                <i className="prep-order-details-download-icon icon-iconos_descargar"></i>
                <div className="prep-order-details-download-text">
                  {" "}
                  Descargar detalle{" "}
                </div>
              </div>
              {!isMobile && order && ["PEN"].includes(order.status) && (
                <div className="prep-order-order-details-button">
                  {customButtons()}
                </div>
              )}
              {!isMobile && order && order.status === "PRO" && (
                <>
                  <div
                    className="prep-order-details-init-movement"
                    onClick={handleInitMovement}
                  >
                    ARMAR PEDIDO
                  </div>
                  {btnReject()}
                </>
              )}
              {!isMobile && order && order.status === "SUP" && (
                <>
                  <div
                    className="prep-order-details-init-movement"
                    onClick={handleInitVerify}
                  >
                    VERIFICAR MOVIMIENTO
                  </div>
                  {btnReject()}
                </>
              )}
            </div>
          )}
          <div className="prep-order-details-panel">
            <div className="prep-order-details-panel-wrapper">
              <OrderProductSummary
                products={products}
                edit={false}
                paginationSize={5}
              ></OrderProductSummary>
            </div>
            <div className="prep-order-details-panel-wrapper">
              {["PRO", "SUP", "DISP", "COM"].includes(order.status) &&
                groupMovementItems.map(item => {
                  return (
                    <ItemTags
                      key={item.sku}
                      item={item}
                      quantity={item.tags.length}
                      expected={item.expectedUnits}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {isMobile && order && order.status === "PEN" && (
        <div className="prep-order-details-button">
          {customButtons()}
        </div>
      )}
      {isMobile && order && order.status === "PRO" && (
        <div className="prep-order-details-button">
          <div className="prep-order-details-init-movement" onClick={handleInitMovement}>ARMAR PEDIDO</div>
          {customButtonsMobile(0)}
        </div>
      )}
      {isMobile && order && order.status === "SUP" && (
        <div className="prep-order-details-button">
          <div className="prep-order-details-init-movement" onClick={handleInitVerify}>
            VERIFICAR MOVIMIENTO
          </div>
          {customButtonsMobile()}
        </div>
      )}
    </div>
  );

  const loadedContentStep1 = (
    <LoadMovementItems
      total={totalUnits}
      originalItems={movementItems}
      selectedItems={groupMovementItems}
      handleDelete={handleDeleteMovementItems}
      handleSearch={handleStockSearch}
      handleDeleteAll={handleDeleteAllMovementItems}
      handleSend={handleContinueInitMovement}
      handleReject={handleRejectModalOpen}
      goBack={() => handleChangeStep(0)}
      enabledRead={true}
      typeRead={"ORDERS"}
      refRead={order.id}
      receiveEvent={receiveEvent}
    />
  );

  const loadedContentStep2 = (
    <VerifyMovementItems
      originalItems={movementItems}
      selectedItems={groupMovementItems}
      totalSolicited={totalUnits}
      handleEdit={() => handleChangeStep(1)}
      handleConfirm={handleConfirmMovement}
      handleBack={() => handleChangeStep(1)}
    />
  );

  const loadedContentStep3 = (
    <ValidateMovementItems
      total={movementItems.length}
      totalValidate={validatedItems}
      selectedItems={verifyMovementItems}
      handleSearch={handleTagSearch}
      handleReject={handleRejectModalOpen}
      handleApprove={handleApprove}
      goBack={() => handleChangeStep(0)}
      enabledRead={true}
      typeRead={"READ"}
      refRead={""}
      receiveEvent={receiveEvent}
    />
  );

  const loadedContent = [
    loadedContentStep0,
    loadedContentStep1,
    loadedContentStep2,
    loadedContentStep3
  ];
  const content = order.id ? loadedContent[step] : <></>;

  return (
    <div className="prep-order-details-container">
      <AdminLayout
        headerTitle={"Detalle de orden"}
        headerOptions={headerOptions}
        content={content}
        goBackFunc={goBackModal}
        navHidden={true}
      ></AdminLayout>

      <RejectModal
        open={modalOpen}
        handleClose={handleCloseModal}
        handleReject={handleReject}
      ></RejectModal>

      <GenericErrorModal
        open={errorModalOpen}
        handleClose={() => seterrorModalOpen(false)}
        error={errorMsg}
      ></GenericErrorModal>

      {loading && <Loader />}
    </div>
  );
};

export default PrepOrderDetailsView;
