import { useCallback, useEffect, useState } from "react";
import { DeviceService } from "services/DeviceService";
import { WSService } from "services/WSServices";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from "react-tippy";
import SignalWifiConnectedNoInternet4OutlinedIcon from '@mui/icons-material/SignalWifiConnectedNoInternet4Outlined';
import SignalWifiStatusbar4BarOutlinedIcon from '@mui/icons-material/SignalWifiStatusbar4BarOutlined';
import "./TabConnections.scss";
import { SettingsInputAntennaSharp } from "@mui/icons-material";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import Button from '@mui/material/Button';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import useRequest from "hooks/useRequest";
import Loader from "components/MainApp/atoms/Loader";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Dialog, DialogTitle, List, ListItem, ListItemText } from "@material-ui/core";
import { ListItemButton } from "@mui/material";
import { FirebaseYaxDevicesService } from "services/FirebaseYaxDevicesService";

const TabConnections = (props) => {

    var globalChannel;
    const [devices, setDevices] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [openDialog, setOpenDialog] = useState(false);
    const [versions, setVersions] = useState([]);
    const [roomSelected, setRoomSelected] = useState("");
    const [currentVersion, setCurrentVersion] = useState("");
    const {
        loading,
        beforeSubmit,
        afterSubmit,
        errors,
        dealWithError,
    } = useRequest();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const tooltipStyle = {
        background: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029",
        height: "45px",
        padding: "0 15px",
        color: "#494F66",
        fontFamily: "Roboto-Light",
        fontSize: "18px",
        borderRadius: "15px",
        display: "flex",
        alignItems: "center",
    };

    const columns = [
        { id: 'id', label: 'Id' },
        { id: 'name', label: 'Name' },
        { id: 'serial_number', label: 'Serial Number' },
        { id: 'state', label: 'State' },
        { id: 'channel', label: 'Channel' },
        { id: 'version', label: 'Version' },
        { id: 'reload', label: '' },
        { id: 'update', label: '' },
    ];

    useEffect(() => {
        globalChannel = null;
        const ws = WSService.getInstance();
        ws.listen("message", receiveEvent);

        let deviceOptions = {
            no_page: 1,
            enabled: true
        };
        deviceOptions.includeType = "LIFT|FITTING|SHELVING|LOSS_PREVENTION|FLOW|AUTONOMOUS|SEMI_ASSISTED|FIX_READER";
        beforeSubmit();
        DeviceService.readersDevices(deviceOptions).then(devicesResponse => {
            setDevices(devicesResponse);
            afterSubmit();
        }).catch(_ => {
            afterSubmit();
        });

        const fyds = FirebaseYaxDevicesService.getInstance();
        fyds.getVersions().then(data => {
            setVersions(data)
        }).catch(error => {

        });

        return () => {
            if (globalChannel) {
                closeRoom();
            }
            ws.removeListener("message");
        };
    }, []);


    const reloadStates = (rowId) => {
        var channel = devices.filter(d => d.id == rowId)[0]["serial_number"];
        let data = { "serial_number": channel };
        beforeSubmit();
        DeviceService.deviceState(data).then(_ => {
            afterSubmit();
            setTimeout(() => {
                let state = devices.filter(d => d.id == rowId)[0]["state"]
                if (!state) {
                    setDevices(prevDevices => {
                        const devicesNew = [...prevDevices];
                        devicesNew.filter(d => d.id == rowId)[0]["state"] = "disconnected"
                        setDevices(devicesNew);
                        return devicesNew;
                    });

                }
            }, 2000);
        }).catch(_ => {
            afterSubmit();
        });

    }

    const receiveEvent = useCallback(payload => {
        if (canParseJson(payload)) {
            payload = JSON.parse(payload);
        }
        console.log("payload event tabConections");
        let method = payload.method;
        if (method == "DEVICESTATE") {
            let action = payload.params.action;
            if (action == "pingResponse") {
                let serialNumber = payload.params.room;
                let openChannel = payload.params.openChannel;
                let version = payload.params.version ? payload.params.version : '';
                console.log(payload);
                console.log(version);
                setDevices(prevDevices => {
                    const devicesNew = [...prevDevices];
                    devicesNew.filter(d => d.serial_number == serialNumber)[0]["state"] = "connected";
                    devicesNew.filter(d => d.serial_number == serialNumber)[0]["channel"] = openChannel ? "open" : "close";
                    devicesNew.filter(d => d.serial_number == serialNumber)[0]["version"] = version.toString();
                    //devicesNew[0]["state"] = "connected";
                    setDevices(devicesNew);
                    return devicesNew;
                });
            }
        }


    }, []);

    const canParseJson = str => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const openVersions = (rowId) => {
        if (devices.filter(d => d.id == rowId)[0]["state"] == "connected") {
            setRoomSelected(devices.filter(d => d.id == rowId)[0]["serial_number"])
            setCurrentVersion(devices.filter(d => d.id == rowId)[0]["version"])
            setOpenDialog(true);
        } else {
            alert("false")
        }
    }

    const handleVersionClick = (url, name) => {
        const ws = WSService.getInstance();
        ws.updateVersion(roomSelected, url, name);
        setOpenDialog(false);

    }

    return (
        <div>
            <Paper sx={{ width: '100%', overflow: 'auto' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {devices.length > 0 && devices
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (

                                                    <TableCell
                                                        className={`celltable-connections ${(column.id == 'state' ||
                                                            column.id == 'channel' ||
                                                            column.id == 'version' ||
                                                            column.id == 'reload' ||
                                                            column.id == 'update'
                                                        ) ? 'icons' : ''}`}
                                                        sx={{
                                                            maxWidth: 100,
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis'
                                                        }} key={column.id} align={column.align}>

                                                        {column.id == "state" ?
                                                            (value == "connected" ?
                                                                <SignalWifiStatusbar4BarOutlinedIcon sx={{ color: "green" }} />
                                                                :
                                                                (value == "disconnected" ?
                                                                    <SignalWifiConnectedNoInternet4OutlinedIcon sx={{ color: "red" }} />
                                                                    :
                                                                    <HelpCenterOutlinedIcon />)
                                                            )
                                                            :
                                                            (column.id == "channel" ?
                                                                (value == "open" ?
                                                                    <LockOpenOutlinedIcon sx={{ color: "green" }} />
                                                                    :
                                                                    (value == "close" ?
                                                                        <HttpsOutlinedIcon sx={{ color: "red" }} />
                                                                        :
                                                                        <HelpCenterOutlinedIcon />
                                                                    )
                                                                )
                                                                :
                                                                (column.id == "reload" ?
                                                                    <div>
                                                                        <Button variant="contained" onClick={() => { reloadStates(row["id"]) }}>
                                                                            <CachedOutlinedIcon sx={{ color: "white" }} />
                                                                        </Button>
                                                                    </div>
                                                                    :
                                                                    (column.id == "update" ?
                                                                        <div>
                                                                            <Button disabled={!(row.state == "connected")} variant="contained" onClick={() => { openVersions(row["id"]) }}>
                                                                                <FileDownloadOutlinedIcon sx={{ color: "white" }} />
                                                                            </Button>
                                                                        </div> :
                                                                        <div onClick={() => alert(value)}>
                                                                            {value}
                                                                        </div>
                                                                    )
                                                                )
                                                            )
                                                        }


                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={devices?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {loading && <Loader />}
            <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
                <DialogTitle>Seleccionar versión</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {versions.map((version) => (
                        <ListItem disableGutters key={version}>
                            <ListItemButton disabled={version.name.includes(currentVersion)} onClick={() => handleVersionClick(version.url, version.name)}>
                                <ListItemText primary={version.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </div>

    )
}

export default TabConnections;