export const filterTypeMap = {
  "Estado de la orden": { name: "status", type: "dropdown" },
  "Rango de fecha": { name: "date", type: "dateRange" },
  "Origen de orden": {
    name: "origin",
    type: "dropdown",
    default_value: undefined
  },
  "Destino de orden": {
    name: "destination",
    type: "dropdown",
    default_value: undefined
  },
  "Ordenar por": {
    name: "ordering",
    type: "twoOption",
    default_value: "-created_at"
  }
};

export const filterData = [
  {
    type: "dropdown",
    label: "Estado de la orden",
    firstOptionLabel: "Todos los estados",
    options: [
      {
        label: "Borrador",
        selected: "false",
        id: "DRA",
        type: "status"
      },
      {
        label: "Pendiente",
        selected: "false",
        id: "PEN",
        type: "status"
      },
      {
        label: "En proceso",
        selected: "false",
        id: "PRO",
        type: "status"
      },
      {
        label: "Despachada",
        selected: "false",
        id: "DISP",
        type: "status"
      },
      {
        label: "Rechazada",
        selected: "false",
        id: "REJ",
        type: "status"
      },
      {
        label: "Completada",
        selected: "false",
        id: "COM",
        type: "status"
      }
    ],
    name: "status"
  },
  {
    type: "dropdown",
    options: [],
    label: "Origen de orden",
    firstOptionLabel: "Todos los origenes",
    name: "orin"
  },
  {
    type: "dropdown",
    options: [],
    label: "Destino de orden",
    firstOptionLabel: "Todos los destinos",
    name: "dest"
  },
  {
    type: "dateRange",
    label: "Rango de fecha",
    name: "date"
  }
];
