import React from "react";

import { CategoryService } from "../../../services/CategoryService";

import "./styles.scss";
import GenericListContainer from "../../../helpers/GenericListContainer";
import AdminLayout from "../../../components/MainApp/layouts/DesktopLayout";
import CategoryTable from "../../../components/MainApp/organisms/Category/Table";

import Search from "../../../components/MainApp/atoms/Filters/Search";
import MainButton from "../../../components/MainApp/atoms/RoundedButton";
import Pagination from "../../../components/MainApp/atoms/Pagination";
import Loader from "../../../components/MainApp/atoms/Loader";
import EditCategoryModal from "../../../components/MainApp/organisms/Category/Modals/EditCategoryModal";
import DeleteCategoryModal from "../../../components/MainApp/organisms/Category/Modals/DeleteCategoryModal";
import ErrorDeleteCategoryModal from "../../../components/MainApp/organisms/Category/Modals/ErrorCategoryModal";
import SimpleSearchPanel from "components/MainApp/organisms/Filters/SimpleSearchPanel";
import { NotFoundItems } from "../components/NotFoundItems";
import { isMobile } from "helpers/Mobile";
const initialFilters = {
  page: 1,
  page_size: 10,
  search: "",
  enabled: undefined,
  ordering: "name",
};

export class CategoriesView extends GenericListContainer {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      loading: true,

      filters: { ...initialFilters },
      pager: {
        next: null,
        previous: null,
        count: 0,
        countPages: 0,
      },

      categories: [],
      categories_name: [],

      openEditModal: false,
      openDeleteModal: false,
      openErrorModal: false,

      readOnlyTable: false,
      product: null,  // Redirect to product form

      deleteErrorModal: false,

      //categories_name: []
    };

    this.myRef = React.createRef();
  }



  componentDidMount() {
    if(this.props.location.state && this.props.location.state.product) {
      const filters = this.state.filters;
      filters['enabled'] = 1;
      this.setState({
        readOnlyTable: true,
        product: {...this.props.location.state.product},
        filters: filters
      }, _ => {
        this.filter(false, true);
      });
    } else {
      this.filter(false, true);
    }

    CategoryService.publicCategories().then((response) => {
      let categories_name = response.map( category => {
        return category.name
      })
      this.setState({categories_name: categories_name});
    });

    document.addEventListener("scroll", this.handleScroll, true);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  filter(append = false, firstLoad = false) {

    this.beforeSubmit();
    CategoryService.categories(this.state.filters)
      .then((response) => {
        this.afterSubmit();
        if (firstLoad && response.count === 0) {
          return this.handleAddCategoryRedirect(true);
        }
        const categories = append
          ? [...this.state.categories, ...response.results]
          : response.results;
        this.setState({
          loading: false,
          categories,
          pager: {
            next: response.next,
            previous:
              this.state.filters.page > 1 ? this.state.filters.page - 1 : null,
            count: response.count,
            countPages: Math.ceil(
              response.count / this.state.filters.page_size
            ),
          },
        });
      })
      .catch((e) => {
        this.afterSubmit();
      });
  }

  goBack() {
    if(this.state.product) {
      this.props.history.push({
        pathname: '/admin/products/' + (this.state.product.id ? `details/${this.state.product.id}/` : 'load-single-product'),
        state: {
          product: this.state.product,
        },
      });
    }else{
      this.props.history.push("/admin/products");
    }
  }

  selectCategory(category) {
    if (this.state.readOnlyTable) {
      let product = {...this.state.product}
      product.category = category
      this.props.history.push({
        pathname: '/admin/products/' + (product.id ? `details/${product.id}/` : 'load-single-product'),
        state: {
            product: product,
        }
      });
    }
  }

  handleScroll = (event) => {
    if (
      !isMobile
    ) {
      return;
    }
    if (
      event.srcElement.offsetHeight + event.target.scrollTop + 100 <
      event.srcElement.scrollHeight
    ) {
      return;
    }

    if (!this.state.pager.next || this.state.submitted) {
      return;
    }

    const filters = this.state.filters;
    filters["page"] = filters.page + 1;
    this.setState(
      {
        filters: filters,
      },
      (_) => {
        this.filter(true);
      }
    );
  };

  handleEdit = (id) => {
    let category = this.state.categories.find((category) => category.id === id);
    this.setState({
      openEditModal: true,
      editCategory: category,
    });
  };

  handleDelete = (category) => {
    this.setState({
      openDeleteModal: true,
      deleteCategory: category,
    });
  };

  handleCondirmDelete = () => {
    const filters = this.state.filters;
    filters["page"] = 1;

    this.beforeSubmit();
    CategoryService.deleteCategory(this.state.deleteCategory.id)
    .then(() => {
      this.afterSubmit();
      this.setState(
        {
          loading: true,
          openDeleteModal: false,
          filters,
        },
        (_) => this.filter()
      );
    })
    .catch((error) => {
      this.setState({
        openDeleteModal: false,
        deleteErrorModal: true
      })
      this.afterSubmit();
      // this.dealWithError(error);
    });
  };

  handleChange = (e) => {
    let editCategory = { ...this.state.editCategory };
    editCategory.name = e.target.value;
    this.setState({
      editCategory,
    });
  };

  handleConfirmEdit = (e) => {
    e.preventDefault();

    this.beforeSubmit();
    CategoryService.updateCategory(
      this.state.editCategory.id,
      this.state.editCategory
    ).then(() => {
      this.setState({
        openEditModal: false,
      }, _ => this.filter())
    })
    .catch((error) => {
      this.afterSubmit();
    });
  };

  handleAddCategoryButton = ({firstLoad = false}) => {
    this.props.history.push({
      pathname: '/admin/categories/add-category',
      state: {
          product: this.state.product,
          firstCategory: false
      }
    });
  };

  handleAddCategoryRedirect = (firstCategory = false) => {
    this.props.history.push({
      pathname: '/admin/categories/add-category',
      state: {
          product: this.state.product,
          firstCategory: firstCategory
      }
    });
  }

  headerOptions = isMobile
    ? [
        {
          icon: "icon-iconos_mas",
          name: "add",
          handler: this.handleAddCategoryButton,
          tooltip: 'Categorías'

        },
      ]
    : [];

  render() {
    const tableContentDesktop = (
      <React.Fragment>
        <div className="table-categories-filter-container">
          <div className="table-categories-filter-wrapper">
            <div className="table-categories-search-wrapper">
              <Search
                resultList={this.state.categories_name}
                placeholder="Busca por nombre"
                value={this.state.filters.search}
                handleSubmit={this.handleSearch}
              ></Search>
            </div>

            <div className="table-categories-add-button">
              <MainButton
                legend="NUEVA CATEGORÍA"
                state="enabled"
                handleClick={this.handleAddCategoryButton}
              ></MainButton>
            </div>
          </div>
        </div>

        <div className="table-categories-table">
          <div className="table-categories-top-table">
            <div className="table-categories-top-total">
              TOTAL{" "}
              <span className="table-categories-top-total-bold">
                ({this.state.pager.count})
              </span>
            </div>
          </div>
          {this.state.categories.length > 0 && (
            <CategoryTable
              categories={this.state.categories}
              handleEdit={this.handleEdit}
              handleDelete={this.handleDelete}
              readOnly={this.state.readOnlyTable}
              handleClick={this.selectCategory.bind(this)}
            />
          )}
          {this.state.categories.length === 0 && (
            <NotFoundItems />
          )}
        </div>

        <div className="table-categories-pagination">
          <Pagination
            pageQuantity={this.state.pager.countPages}
            currentPage={this.state.filters.page}
            pageChangeHandler={this.handlePagerChange}
          />
        </div>
      </React.Fragment>
    );

    const tableContentMobile = (
      <React.Fragment>
        <div className="table-categories-filter-container">
          <SimpleSearchPanel
            placeholder="Busca por nombre"
            handleSubmit={this.handleSearch}
          />
          {/* <Search
            resultList={this.state.categories_name}
            placeholder="Busca por nombre"
            value={this.state.filters.search}
            handleSubmit={this.handleSearch}
          ></Search> */}
        </div>
        <div className="table-categories-table">
          <div className="table-categories-top-table">
            <div className="table-categories-top-total">
              Total{" "}
              <span className="table-categories-top-total-bold">
                ({this.state.pager.count})
              </span>
            </div>
          </div>
          <CategoryTable
            categories={this.state.categories}
            handleEdit={this.handleEdit}
            handleDelete={this.handleDelete}
            readOnly={this.state.readOnlyTable}
            handleClick={this.selectCategory.bind(this)}
          />
           {this.state.categories.length === 0 && (
            <NotFoundItems />
          )}
        </div>
      </React.Fragment>
    );

    const tableContent = isMobile
      ? tableContentMobile
      : tableContentDesktop;

    const content = (
      <div className="categories-wrapper" ref={this.myRef}>
        {this.state.loading ? <Loader /> : tableContent}
      </div>
    );

    return (
      <div className="categories-container">
        <AdminLayout
          headerTitle={"Categorías"}
          headerOptions={this.headerOptions}
          content={content}
          navHidden={false}
          goBackFunc={this.goBack.bind(this)}
        ></AdminLayout>
        <EditCategoryModal
          open={this.state.openEditModal}
          category={this.state.editCategory}
          handleChange={this.handleChange}
          confirm={this.handleConfirmEdit}
          cancel={() => this.setState({ openEditModal: false })}
        ></EditCategoryModal>
        <DeleteCategoryModal
          open={this.state.openDeleteModal}
          confirm={this.handleCondirmDelete}
          category={this.state.deleteCategory}
          cancel={() => {
            this.setState({ openDeleteModal: false });
          }}
        ></DeleteCategoryModal>
        <ErrorDeleteCategoryModal
          category={this.state.deleteCategory}
          open={this.state.deleteErrorModal}
          handleClose={() => {
            this.setState({ deleteErrorModal: false });
          }}
        ></ErrorDeleteCategoryModal>
      </div>
    );
  }
}

export default CategoriesView;
