import React from "react";
import { FILE_TYPE_VIDEO } from "constants";
import "./styles.scss";

export const Media = ({ file, style, onMouseOver, controls = false }) => {
  let isVideo = false;
  let src = "";

  if (typeof file === "string" && file.startsWith("blob:")) {
    src = file;
  } else if (file?.file_name) {
    isVideo = file?.file_name?.match(FILE_TYPE_VIDEO);
    src = file?.file_name;
  } else if (file?.name) {
    isVideo = file?.name?.match(FILE_TYPE_VIDEO);
    src = URL.createObjectURL(file);
  } else {
    isVideo = file?.match(FILE_TYPE_VIDEO);
    src = file;
  }

  return (
    <div className={style}>
      {isVideo ? (
        <video controls={controls} onMouseOver={onMouseOver}>
          <source src={src} type="video/mp4" />
          Tu navegador no soporta la etiqueta video.
        </video>
      ) : (
        <img src={src} alt={src} onMouseOver={onMouseOver} />
      )}
    </div>
  );
};

export default Media;
