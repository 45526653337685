import React from 'react';
import GenericModal from '../../../atoms/modals/GenericModal';
import ModalButton from '../../../atoms/modals/ModalButton';
import { Link } from 'react-router-dom';

import './styles.scss';


export class ExistingAccountModal extends React.Component {

    render() {
        const {
            handleIngresar,
            handleCancel,
            open
        } = this.props;

        const content = <div className='existing-modal-container-content'>
                            <p className="description">Ya existe una cuenta creada con este email.</p>
                            <Link style={{textAlign: 'center'}} to="/login" className="link"><ModalButton label={'INGRESAR A MI CUENTA'} handleClick={handleIngresar}></ModalButton></Link>
                            <ModalButton label={'CANCELAR'} handleClick={handleCancel}></ModalButton>

                        </div>
        return (
            <div>
                <GenericModal handleClose={this.handleCancel} content={content} open={open} title={'Cuenta existente'}></GenericModal>
            </div>
        )
    }

}


ExistingAccountModal.propTypes = {};

export default ExistingAccountModal;