const headers = {
  image: {
    name: 'image',
    title: '',
    active: false,
    direction: false,
  },
  name: {
    name: 'name',
    title: 'NOMBRE',
    active: false,
    direction: false,
    value: 'name',
  },
  document: {
    name: 'document',
    title: 'DOCUMENTO',
    active: false,
    direction: false,
    value: 'document',
  },
  sector: {
    name: 'sector',
    title: 'RUBRO',
    active: false,
    direction: false,
    value: 'sector',
  },
  country: {
    name: 'country',
    title: 'PAÍS',
    active: false,
    direction: false,
    value: 'country',
  },
  email: {
    name: 'email',
    title: 'EMAIL',
    active: false,
    direction: false,
    value: 'email',
  },
  phone: {
    name: 'phone',
    title: 'TELÉFONO',
    active: false,
    direction: false,
    value: 'phone',
  },
}

export default headers;