import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import { DeviceService } from "services/DeviceService";

import useRequest from "hooks/useRequest";
import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";

import Panel from "components/MainApp/organisms/TogglePanel";
import LocationPanel from "components/MainApp/organisms/LocationPanel";
import TwoOptionModal from "components/MainApp/atoms/Modals/TwoOptionModal";
import Toggle from "components/MainApp/atoms/Forms/Toggle";

import "./styles.scss";
import { isAfter } from "date-fns";

const DeviceDetailView = (props) => {
  const history = useHistory();
  const [deleteErrorModal, setdeleteErrorModal] = useState(false);
  const [deleteErrorMsg, setdeleteErrorMsg] = useState("");
  // const [loading, setLoading] = useState(false);

  const [device, setdevice] = useState(null);
  const [antennas, setantennas] = useState([]);

  const [modalInfo, setModalInfo] = useState();
  const [enableModalOpen, setEnableModalOpen] = useState(false);

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  useEffect(() => {
    if (!props.match.params.key) return;
    const id = props.match.params.key;
    beforeSubmit();
    DeviceService.device(id).then((device) => {
      setdevice(device);
      afterSubmit();
    });
  }, []);

  useEffect(() => {
    if (device && device?.device_type?.parent?.type == "FIX_READER") {
      DeviceService.devices({ device: device.id }).then((antennas) => {
        // mapAntennas(locations.results)
        setantennas(antennas);
      });
    }
  }, [device]);

  const deleteDevice = () => {
    beforeSubmit();
    DeviceService.deleteDevice(device.id)
      .then((rta) => {
        history.push(`/admin/devices`);
        afterSubmit();
      })
      .catch((error) => {
        afterSubmit();
        setdeleteErrorModal(true);
        setdeleteErrorMsg(i18n.t("No se puede eliminar el dispositivo"));
      });
  };

  const headerOptions = [
    {
      icon: "icon-iconos_eliminar",
      name: "add",
      handler: deleteDevice,
      tooltip: i18n.t("Eliminar dispositivo"),
    },
    {
      icon: "icon-iconos_editar",
      name: "editar",
      handler: () => {
        history.push({
          pathname: `/admin/devices/edit/${device.id}`,
          state: {
            type: "device",
          },
        });
      },
      tooltip: i18n.t("Editar Dispositivo"),
    },
  ];


  const goToActivity = () => {
    history.push({
      pathname: `/admin/devices/activity/${device.id}`,
      state: {
        type: "device",
      },
    });
  }


  const mapAntennas = (locations) => {
    // const antennas = locations.filter( location => {
    //     return location.type === 'ZONE'
    // })
    // const areas = locations.filter( location => {
    //     return location.type === 'AREA'
    // })
    // const shelves = locations.filter( location => {
    //     return location.type === 'SHELF'
    // })
    // setantennas(antennas)
    // setareas(areas)
    // setshelves(shelves)
  };

  const handleAddAntenna = () => {
    history.push({
      pathname: `/admin/devices/add/antenna`,
      state: {
        type: "antenna_add",
        parent: device.id,
      },
    });
  };

  const goToAntenna = (antennaId) => {
    history.push({
      pathname: `/admin/devices/detail/antenna/${antennaId}`,
      state: {
        parent: device.id,
      },
    });
  };

  const handleGoBack = () => {
    history.push(`/admin/devices`);
  };

  const updateDevice = (_device) => {
    const data = { enabled: !_device.enabled };
    beforeSubmit();
    DeviceService.enableDevice(props.match.params.key, data)
      .then((device) => {
        afterSubmit();
        setdevice(device);

        EventRepository.notificationSend({
          label: i18n.t(
            `Se ${
              device.enabled ? "habilitó" : "deshabilitó"
            } el dispositivo con éxito`
          ),
          type: "success",
        });
      })
      .catch((error) => {
        afterSubmit();

        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }
  };

  const handleEnable = () => {
    setEnableModalOpen(false);
    updateDevice(device);
  };

  const handleDisable = () => {
    setEnableModalOpen(false);
    updateDevice(device);
  };

  const handleToggleModal = () => {
    let _modalInfo;
    if (device.enabled) {
      _modalInfo = disableModal;
    } else {
      _modalInfo = enableModal;
    }
    setModalInfo(_modalInfo);
    setEnableModalOpen(true);
  };

  const disableModal = {
    title: "¿Quieres inhabilitar este dispositivo?",
    subtitle: (
      <div>
        Al inhabilitarlo,{" "}
        <span style={{ fontFamily: "Ubuntu-Medium" }}>
          no podrás realizar operaciones.
        </span>
      </div>
    ),
    closeLabel: "CANCELAR",
    confirmLabel: "INHABILITAR",
    handleClose: () => setEnableModalOpen(false),
    handleConfirm: handleDisable,
  };

  const enableModal = {
    title: "¿Quieres habilitar este dispositivo?",
    subtitle: (
      <div>
        Al habilitarlo podrás{" "}
        <span style={{ fontFamily: "Ubuntu-Medium" }}>
          realizar operaciones.
        </span>
      </div>
    ),
    closeLabel: "CANCELAR",
    confirmLabel: "HABILITAR",
    handleClose: () => setEnableModalOpen(false),
    handleConfirm: handleEnable,
  };

  const content = (
    <div className={"device-detail-content-wrapper"}>
      <div
        className="disable-device-container"
        data-automation-id="disable-device-container"
      >
        <p className="disable-device-label">
          Dispositivo{" "}
          <span className="disable-device-label-span">
            {device?.enabled ? "habilitado" : "inhabilitado"}
          </span>{" "}
          para realizar operaciones
        </p>
        <div className="disable-device-toggle">
          <Toggle
            handleChange={handleToggleModal}
            checked={device?.enabled}
            name={"enabled"}
          ></Toggle>
        </div>
      </div>
      {device && (
        <>
          <Panel
            fields={device && device.getDescriptionPanelView()}
            title={i18n.t("Dispositivo")}
          />
          {device.device_type?.type === 'LIFT' && device?.item_file?.file_name && (
            <>
              <div className="text-panel">Video o imagen seleccionada</div>
              <div className="media-panel">
                <video width="320" height="240" controls>
                <source
                  src={device.item_file.file_name}
                  type="video/mp4"
                ></source>
                Tu navegador no soporta el elemento de video.
              </video>
              </div>
            </>
          )}
        </>
      )}
     {/* <button onClick={goToActivity} >ver actividad</button> */}
      {device && device?.device_type?.parent?.type == "FIX_READER" && (
        <LocationPanel
          title={i18n.t("Antenas")}
          type={"antenna"}
          items={antennas}
          handleAdd={() => handleAddAntenna()}
          handleClick={goToAntenna}
        />

      )}
    </div>
  );
  return (
    <div className="device-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Dispositivo")}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      <GenericErrorModal
        open={deleteErrorModal}
        handleClose={() => setdeleteErrorModal(false)}
        error={deleteErrorMsg}
      ></GenericErrorModal>
      <TwoOptionModal open={enableModalOpen} {...modalInfo}></TwoOptionModal>
      {loading && <Loader />}
    </div>
  );
};

export default DeviceDetailView;
