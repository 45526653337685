import React, { useState } from "react";
// import i18n from "i18n";
import ProductAddedItemLocation from "../ProductAddedItemLocation";
import Pagination from "../../../../atoms/Pagination";
import "./styles.scss";

export const ProductAddedListLocation = ({
  products,
  total,
  handleDelete,
  changePage,
  pagination,
  title = "Productos solicitados",
}) => {
  const [toggleOpen, setToggleOpen] = useState(false);
  return (
    <div className="product-added-list-wrapper">
      <div
        className={`product-added-list-title`}
        onClick={() => setToggleOpen(!toggleOpen)}
      >
        <div className={`product-added-list-title-wrapper`}>
          {title} ({total})
        </div>
      </div>

      {
        <div className="product-added-list-products">
          {products.map((product) => {
            return (
              <div key={product.id}>
                {" "}
                <ProductAddedItemLocation
                  product={product}
                  locations={product.extra}
                  handleDelete={handleDelete}
                />{" "}
              </div>
            );
          })}
          <div className="product-added-list-pagination">
            <Pagination
              pageQuantity={pagination.pageQuantity}
              currentPage={pagination.currentPage}
              pageChangeHandler={changePage}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default ProductAddedListLocation;
