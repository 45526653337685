import Environment from "../environment";
import Api from "./ApiService";
import { AuthService } from "./AuthService.js";

export const BiService = {
  dashboard: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${
          Environment.api
        }api/bi/nodes/${AuthService.getCurrentNodeCode()}/dashboard/`,
        "GET",
        params
      )
        .then((rta) => {
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
