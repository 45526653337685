import React, {useState, useEffect} from "react";
import {useHistory} from 'react-router-dom'

import i18n from "i18n";

import { AuthService } from "services/AuthService";
import { PublicService } from 'services/PublicService.jsx';
import { AccountService } from "services/AccountService";

import Loader from "components/MainApp/atoms/Loader";
import AdminLayout from "../../../components/MainApp/layouts/DesktopLayout";
import EditPanel from "../../../components/MainApp/organisms/EditPanel";
import EditAccountForm from './EditAccount'
import "./styles.scss";

//TODO DELETE WITH SERVICE
import {Account} from 'models/Account'
const headerOptions = []



export const AccountView = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [mode, setmode] = useState('view');
    const [account, setaccount] = useState(null);
    const [newAccount, setnewAccount] = useState(null);
    const [countries, setcountries] = useState([]);
    const [languages, setlanguages] = useState([]);

    useEffect(() => {
        const languages = [{label: "Español", value: 'es'}, {label: "Español - Argentina", value: 'es-ar'}]
        setlanguages(languages)

        AccountService.me().then((account) => {
            setaccount(account);
            setnewAccount({
                ...account,
                language: languages.find(l => l.value === account.language),
            });
        });
        
    }, []);

    useEffect(() => {
        if (newAccount && typeof newAccount.country === 'string') {
            PublicService.countries().then((countries) => {
                setcountries(countries);
                if (newAccount) {
                    setnewAccount({
                        ...newAccount,
                        country: countries.find(c => c.value === newAccount.country),
                    });
                }
            });
        }
    }, [newAccount])

    const editAccount = () => {
        setmode('edit');
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;   
        let newAcc = {...newAccount}
        newAcc[name] = value;

        setnewAccount(newAcc)
    }

    const handleSelect = (selectedOption, type) => {
        let newAcc = {...newAccount}
        switch (type) {
            case 'country':
                newAcc.country = selectedOption;
                setnewAccount(newAcc);
                break;
            case 'language':
                newAcc.language = selectedOption;
                setnewAccount(newAcc);
                break;
            default:
                    break;
        }
    }

    const handleSend = (e) => {
        e.preventDefault();

        setLoading(true)
        let accountAux = {...newAccount}
        
        accountAux.first_name = accountAux.first_name;
        accountAux.last_name = accountAux.last_name;
        accountAux.email = accountAux.email;
        accountAux.phone = accountAux.phone;
        accountAux.country = accountAux.country ? accountAux.country.value : null;
        accountAux.language = accountAux.language ? accountAux.language.value : null;

        AccountService.meUpdate(accountAux).then(account =>{
            setaccount(account);
            setLoading(false);
            setmode('view');

            AuthService.loadUser();
        }).catch(error => {
            setLoading(false)
        })
    }

    const layoutProps = {
        view: {
            title: i18n.t('Mi Cuenta'),
            goBackFunc: () => history.push(`/admin/more-options`)
        },
        edit: {
            title: i18n.t('Datos Personales'),
            goBackFunc: () => setmode('view')
        }
    }

    const content = <div className={'account-content'} >
        {account && mode === 'view' && <EditPanel fields={account.getEditPanelView()} title={i18n.t('Datos Personales')} handleEdit={editAccount}> </EditPanel>}
        {mode == 'edit' && 
            <form onSubmit={handleSend}>
            <EditAccountForm account={newAccount} handleChange={handleChange} handleSelect={handleSelect} countries={countries} languages={languages}></EditAccountForm>
            <div className={"account-button-wrapper"}>
                <button type="submit" className={`account-button`} onClick={handleSend}>GUARDAR</button>
                </div>
            </form>
        }
    </div>

    return <div className={"account-container"}>
        <AdminLayout
          headerTitle={layoutProps[mode].title}
          headerOptions={headerOptions}
          content={content}
          navHidden={true}
          goBackFunc={layoutProps[mode].goBackFunc}
        ></AdminLayout>
        {loading && <Loader />}
    </div>
}

export default AccountView;