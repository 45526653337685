import React from "react";
import LoadFinishedGeneral from "components/MainApp/organisms/LoadFinishedGeneral"


import "./styles.scss";

 const AddBusinessNodeSuccess = ({name, id, boldButtonFunc}) => {
 
    return (
      <LoadFinishedGeneral
        title={`Se creó la sucursal “${name}” de manera exitosa.`}
        boldButtonLabel={"IR AL LISTADO"}
        buttonLabel={'CONFIGURAR UBICACIONES'}
        boldButtonLink={"/admin/businessnodes"}
        buttonLink={`/admin/businessnodes/locationssetup/${id}`}
        boldButtonFunc={boldButtonFunc}
      > 
        <div className="add-businessnode-success-container">
            <div className="add-businessnode-success-text">Si lo deseas puedes configurar las ubicaciones de almacenamiento</div>
        </div>
      </LoadFinishedGeneral>
    )
  
}

export default AddBusinessNodeSuccess;