import Environment from "../environment";

import Api from './ApiService';

import User, { Role } from "../models/User";


export const UserService = {
  register: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetchNoToken(`${Environment.api}api/register/`, 'POST', data
      ).then(data => {
        resolve(new User(data));
      }).catch((error) => {
        reject(error);
      }
      );
    });
  },

  resendMail: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetchNoToken(`${Environment.api}api/resend_email/`, 'POST', data
      ).then(data => {
        resolve();
      }).catch((error) => {
        reject(error);
      }
      );
    });
  },

  resetPassword: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetchNoToken(`${Environment.api}api/password_reset/`, 'POST', data
      ).then(data => {
        resolve();
      }).catch((error) => {
        reject(error);
      }
      );
    });
  },

  resetConfirm: (password, key) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password, token: key })
    };

    return fetch(`${Environment.api}api/password_reset/confirm/`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json().then(data => {
            localStorage.setItem('access', data.access);
            localStorage.setItem('access_saved', new Date().getTime());
            localStorage.setItem('refresh', data.refresh);
            return data;
          });
        }
        throw response;
      });
  },

  user: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/users/${id}/`, "GET")
        .then((rta) => {
          rta = new User(rta);
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  users: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/users/`,
        "GET",
        params
      ).then((rta) => {
          rta.results = rta.results.map((item) => new User(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createUser: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/users/`, "POST", data)
        .then((data) => {
          resolve(new User(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateUser: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/users/${id}/`, "PATCH", data)
        .then((data) => {
          resolve(new User(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  role: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/roles/${id}/`, "GET")
        .then((rta) => {
          rta = new Role(rta);
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  roles: (params) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/roles/`,
        "GET",
        params
      )
        .then((rta) => {
          rta.results = rta.results.map((item) => new Role(item));
          resolve(rta);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createRole: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/roles/`, "POST", data)
        .then((data) => {
          resolve(new Role(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateRole: (id, data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/roles/${id}/`, "PATCH", data)
        .then((data) => {
          resolve(new Role(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteRole: (id) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/roles/${id}/`, "DELETE")
        .then((data) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

}
